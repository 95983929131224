//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { execExpression, execExpressionDeep } from '../../../../layout/expression';
import tooltip from './tooltip';
import previewImg from '../../preview-img-dialog';

export default {
  name: 'LayoutHintConfig',
  components: { tooltip, detailLayout: () => import('../detail/index'), previewImg },
  props: {
    json: {
      type: Object,
      required: true,
    },
    expParamsObj: {
      type: Object,
      default: () => ({}),
    },
    icon: {
      type: String,
      default: 'icon-tishi',
    },
  },
  data() {
    return {
      id: '',
    };
  },
  computed: {
    showHintConfig() {
      if (this.json?.hidden) {
        return !execExpression(this.json?.hidden, this.expParamsObj);
      }
      return true;
    },
    popperClass() {
      if (this.contentList.length) return 'nges-common-layout-tooltip-detail list';
      return 'nges-common-layout-tooltip-detail';
    },
    contentList() {
      return execExpressionDeep(this.json?.list || [], this.expParamsObj);
    },
    imgList() {
      return this.contentList.filter(({ type }) => type === 'image').map((i) => i.image_url);
    },
    detailJson() {
      return this.json?.json;
    },
    detailStyle() {
      return {
        width: `${this.json?.style?.detail?.width || 630}px`,
        height: `${this.json?.style?.detail?.height || 350}px`,
      };
    },
    dialogStyle() {
      if (this.contentList.length) return {};
      return {
        width: `${this.json?.style?.dialog?.width || 640}px`,
        height: `${this.json?.style?.dialog?.height || 360}px`,
      };
    },
    showIcon() {
      return this.json?.icon || this.icon;
    },
  },
  mounted() {
    if (this.detailJson) {
      this.json.json.render.type = 'tooltip-detail';
    }
  },
  methods: {
    openPop() {
      this.id = execExpression(this.json?.id, this.expParamsObj);
      this.$nextTick(() => {
        this.$nextTick(() => {
          const popel = this.$refs.tooltip.$refs.popper;
          // 避免被其他弹出型组件覆盖 已知在页面打开抽屉的次数大于打开tooltip的次数时打开tooltip就会被覆盖
          setTimeout(() => {
            // 只处理所有body下的节点 拿其中最大的zIndex
            const list = Array.from(document.getElementsByTagName('body')[0].children).filter(
              ({ tagName }) => tagName === 'DIV',
            );
            const indexList = list.map((i) => {
              const index = window.getComputedStyle(i).zIndex;
              if (isNaN(index)) return 2000;
              return Number(index);
            });
            popel.style.zIndex = Math.max(...indexList) + 1;
          }, 100);
          popel.style.height = this.dialogStyle.height;
          popel.style.width = this.dialogStyle.width;
        });
      });
    },
    preview() {
      this.$refs.preview.open(this.imgList);
      this.$refs.tooltip.hide();
    },
  },
};
