// 发布名单接口
import {
  ImportRecordList,
  ImportRecordDetail,
  publishList,
  publishInfo,
  publishDetail,
  DeleteRoster,
  PlanSendCount,
  RealSendCount,
} from '@/graphql/gql/publishList';
import { calVariables } from '@/graphql/util';
import { DataService } from 'nges-common/src/web/utils/api';

export function queryImportRecordList(data) {
  return DataService({
    query: calVariables(ImportRecordList, data),
  });
}
export function queryImportRecordDetail(data) {
  return DataService({
    query: calVariables(ImportRecordDetail, data),
  });
}
export function querypublishList(data) {
  return DataService({
    query: calVariables(publishList, data),
  });
}
export function queryPublishInfo() {
  return DataService({
    query: publishInfo,
  });
}
export function querypublishDetail(data) {
  return DataService({
    query: calVariables(publishDetail, data),
  });
}
export function queryDeleteRoster(data) {
  return DataService({
    query: calVariables(DeleteRoster, data),
  });
}
export function queryPlanSendCount(data) {
  return DataService({
    query: calVariables(PlanSendCount, data),
  });
}
export function queryRealSendCount(data) {
  return DataService({
    query: calVariables(RealSendCount, data),
  });
}
