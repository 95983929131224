const SMS_COUNTDOWN = 60;
const IMG_SRC = {
  userAvatar:
    'https://nges-dev-1251316161.cos.ap-guangzhou.myqcloud.com/nges/91202202/034b872a-d688-46c6-b1bc-9dc184cfeadf',
  rightArrow:
    'https://baike-med-1256891581.file.myqcloud.com/2021031/994b95a0-8608-11eb-87da-a34c0caadeed_0.png',
  selected:
    'https://baike-med-1256891581.file.myqcloud.com/2021033/9b6b4150-8608-11eb-947c-032fa08bea6e_0.png',
  unSelected:
    'https://baike-med-1256891581.file.myqcloud.com/2021030/a1815480-8608-11eb-87da-a34c0caadeed_0.png',
  delete:
    'https://baike-med-1256891581.file.myqcloud.com/2021030/c1ee6aa0-8608-11eb-87da-a34c0caadeed_0.png',
  webSelected:
    'https://baike-med-1256891581.file.myqcloud.com/2021030/a5802070-8608-11eb-947c-032fa08bea6e_0.png',
  webUnSelected:
    'https://baike-med-1256891581.file.myqcloud.com/2021030/a5a77e90-8608-11eb-947c-032fa08bea6e_0.png',
  bgWhite:
    'https://baike-med-1256891581.file.myqcloud.com/2021034/c03485a0-8608-11eb-947c-032fa08bea6e_0.png',
  export:
    'https://baike-med-1256891581.file.myqcloud.com/2021030/c28e0600-8608-11eb-947c-032fa08bea6e_0.png',
  back: 'https://baike-med-1256891581.file.myqcloud.com/2021032/b9387f40-8608-11eb-947c-032fa08bea6e_0.png',
  logo: 'https://baike-med-1256891581.file.myqcloud.com/2022070/0e294310-0bfd-11ed-89ce-4ff3ab5f0cf9_0.png',
  bottomArrow:
    'https://baike-med-1256891581.file.myqcloud.com/2021034/beb47550-8608-11eb-947c-032fa08bea6e_0.png',
  publishSelected:
    'https://baike-med-1256891581.file.myqcloud.com/2021037/99481330-8608-11eb-947c-032fa08bea6e_0.png',
  dataSelected:
    'https://baike-med-1256891581.file.myqcloud.com/2021039/c1c02eb0-8608-11eb-87da-a34c0caadeed_0.png',
  userSelected:
    'https://baike-med-1256891581.file.myqcloud.com/2021036/a5246be0-8608-11eb-87da-a34c0caadeed_0.png',
  setSelected:
    'https://baike-med-1256891581.file.myqcloud.com/2021034/9c6f1cc0-8608-11eb-947c-032fa08bea6e_0.png',
  dataUnSelected:
    'https://baike-med-1256891581.file.myqcloud.com/2021034/c1821440-8608-11eb-87da-a34c0caadeed_0.png',
  publishUnSelected:
    'https://baike-med-1256891581.file.myqcloud.com/2021039/994d9170-8608-11eb-947c-032fa08bea6e_0.png',
  setUnSelected:
    'https://baike-med-1256891581.file.myqcloud.com/2021034/9f2c6800-8608-11eb-947c-032fa08bea6e_0.png',
  userUnSelected:
    'https://baike-med-1256891581.file.myqcloud.com/2021035/a524ba00-8608-11eb-87da-a34c0caadeed_0.png',
  up: 'https://baike-med-1256891581.file.myqcloud.com/2021031/a1804310-8608-11eb-87da-a34c0caadeed_0.png',
  down: 'https://baike-med-1256891581.file.myqcloud.com/2021034/c20c2bd0-8608-11eb-87da-a34c0caadeed_0.png',
  ashcan:
    'https://baike-med-1256891581.file.myqcloud.com/2021035/bdded5d0-8608-11eb-947c-032fa08bea6e_0.png',
  loginBg:
    'https://baike-med-1256891581.file.myqcloud.com/2021030/c6378600-8608-11eb-947c-032fa08bea6e_0.jpg',
  loginPage:
    'https://baike-med-1256891581.file.myqcloud.com/2022024/8e23dbb0-913d-11ec-a3f4-354087f4d05d_0.png',
  setPage:
    'https://baike-med-1256891581.file.myqcloud.com/2021030/a08c4620-8608-11eb-947c-032fa08bea6e_0.png',
  adminSetPage:
    'https://baike-med-1256891581.file.myqcloud.com/2021035/bfc8cb80-8608-11eb-947c-032fa08bea6e_0.png',
  strategyPage:
    'https://baike-med-1256891581.file.myqcloud.com/2022021/a5cebf80-913f-11ec-a3f4-354087f4d05d_0.png',
  publishPage:
    'https://baike-med-1256891581.file.myqcloud.com/2022029/cc384650-913f-11ec-a3f4-354087f4d05d_0.png',
  publicPage:
    'https://baike-med-1256891581.file.myqcloud.com/2021032/c62b7810-8608-11eb-947c-032fa08bea6e_0.png',
  dataCenterPage:
    'https://baike-med-1256891581.file.myqcloud.com/2021038/c416a1d0-8608-11eb-87da-a34c0caadeed_0.png',
  userPage:
    'https://baike-med-1256891581.file.myqcloud.com/2021032/a6b0d840-8608-11eb-87da-a34c0caadeed_0.png',
  bg: 'https://baike-med-1256891581.file.myqcloud.com/2021034/c00c8b40-8608-11eb-947c-032fa08bea6e_0.png',
  roundSelected:
    'https://baike-med-1256891581.file.myqcloud.com/2021031/9a58bfe0-8608-11eb-947c-032fa08bea6e_0.png',
  roundUnSelected:
    'https://baike-med-1256891581.file.myqcloud.com/2021033/99996780-8608-11eb-87da-a34c0caadeed_0.png',
  line: 'https://baike-med-1256891581.file.myqcloud.com/2021033/c2a3b0e0-8608-11eb-947c-032fa08bea6e_0.png',
  blockLine:
    'https://baike-med-1256891581.file.myqcloud.com/2021032/b8733370-8608-11eb-87da-a34c0caadeed_0.png',
  exampleImg:
    'https://baike-med-1256891581.file.myqcloud.com/2021036/c79fa180-8608-11eb-87da-a34c0caadeed_0.png',
  noDataPurple:
    'https://baike-med-1256891581.file.myqcloud.com/2021034/c62b5100-8608-11eb-947c-032fa08bea6e_0.png',
  redMark:
    'https://baike-med-1256891581.file.myqcloud.com/2021036/99599f60-8608-11eb-87da-a34c0caadeed_0.png',
  selectBlue:
    'https://baike-med-1256891581.file.myqcloud.com/2021030/999a2ad0-8608-11eb-87da-a34c0caadeed_0.png',
  previewImgDoctor:
    'https://baike-med-1256891581.file.myqcloud.com/2021040/61407370-980e-11eb-a92f-f3736434eb7a_0.png',
  previewImgDeputy:
    'https://baike-med-1256891581.file.myqcloud.com/2021044/6104f110-980e-11eb-9e88-91a002bdcd2f_0.png',
  doctorDefault:
    'https://baike-med-1256891581.file.myqcloud.com/2021043/416babd0-9d97-11eb-87f5-87f8260230cc_0.png',
  // 企业微信设置
  agentSet: {
    one: 'https://baike-med-1256891581.file.myqcloud.com/2021048/4be9a170-a283-11eb-ae0e-21d0e54b35ac_0.png',
    two: 'https://baike-med-1256891581.file.myqcloud.com/2021043/4c053fc0-a283-11eb-ae0e-21d0e54b35ac_0.png',
    three:
      'https://baike-med-1256891581.file.myqcloud.com/2021044/472ba430-a283-11eb-87f5-87f8260230cc_0.png',
    four: 'https://baike-med-1256891581.file.myqcloud.com/2021041/243795c0-a8bd-11eb-9f82-2f36b21da993_0.png',
  },
  applicationManagement:
    'https://baike-med-1256891581.file.myqcloud.com/2021044/441d52c0-a283-11eb-87f5-87f8260230cc_0.png',
  dialogueIcon:
    'https://baike-med-1256891581.file.myqcloud.com/2021041/7c9be710-a701-11eb-ae0e-21d0e54b35ac_0.png',
  audio:
    'https://baike-med-1256891581.file.myqcloud.com/2021044/1fb0ae10-a8bd-11eb-9f82-2f36b21da993_0.png',
  question:
    'https://baike-med-1256891581.file.myqcloud.com/2021053/cc3c50c0-b48f-11eb-9f82-2f36b21da993_0.png',
  docTypeImg: {
    // doc: 'https://baike-med-1256891581.file.myqcloud.com/2021073/1ff01730-f12e-11eb-956b-c525d6c0c8cb_0.svg',
    // pdf: 'https://baike-med-1256891581.file.myqcloud.com/2021070/2b7946d0-f12e-11eb-b727-2966ebe1cd85_0.svg',
    // ppt: 'https://baike-med-1256891581.file.myqcloud.com/2021076/309b8420-f12e-11eb-956b-c525d6c0c8cb_0.svg',
    doc: 'https://baike-med-1256891581.file.myqcloud.com/2022064/2e5fa4c0-ede0-11ec-96ee-df97b001450a_0.svg',
    docx: 'https://baike-med-1256891581.file.myqcloud.com/2022066/3958a250-ede0-11ec-96ee-df97b001450a_0.svg',
    pdf: 'https://baike-med-1256891581.file.myqcloud.com/2022060/5f0e68c0-ed4c-11ec-96ee-df97b001450a_0.svg',
    ppt: 'https://baike-med-1256891581.file.myqcloud.com/2022060/39068ab0-ede0-11ec-96ee-df97b001450a_0.svg',
    pptx: 'https://baike-med-1256891581.file.myqcloud.com/2022067/390f6450-ede0-11ec-96ee-df97b001450a_0.svg',
    zip: 'https://baike-med-1256891581.file.myqcloud.com/2022061/3906d8d0-ede0-11ec-96ee-df97b001450a_0.svg',
    rar: 'https://baike-med-1256891581.file.myqcloud.com/2022064/392ab480-ede0-11ec-96ee-df97b001450a_0.svg',
    xls: 'https://baike-med-1256891581.file.myqcloud.com/2022064/3915f400-ede0-11ec-96ee-df97b001450a_0.svg',
    xlsx: 'https://baike-med-1256891581.file.myqcloud.com/2022069/390fb270-ede0-11ec-96ee-df97b001450a_0.svg',
    default:
      'https://baike-med-1256891581.file.myqcloud.com/2021072/355821d0-f12e-11eb-956b-c525d6c0c8cb_0.svg',
  },
};
// 横幅banner类型
const BannerEntryType = {
  doctor: {
    name: 1,
    text: '医生端',
  },
  deputy: {
    name: 2,
    text: '代表端',
  },
  h5: {
    name: 3,
    text: 'H5渠道',
  },
};
const APP_TYPE = {
  officialAccounts: 1, // 公众号
  miniprogram: 2, // 小程序
  H5: 3,
  other: 4,
};
const PHONE_REG = /^1[3456789]\d{9}$/;
const PAGE_SIZE = 10;
const PAGE_SIZES = [10, 20, 50, 100];
/*
const MENU_ROUTE = {
  meetingData: ['meetingDataDetail', 'meetingOrganizersDetail'],
  operationManagement: ['operationManagementEdit'],
  externalContactManagement: ['externalContactManagementRecords'],
  doctorManagement: ['doctorInfo', 'doctorRegist'],
  representManagement: ['representDetaill'],
  hospitalManagement: ['hospitalDetail'],
  MessageList: ['userMessageList'],
  artilceList: ['addArtilce'],
  contentList: ['editContent'],
  rosterList: ['rosterDetail'],
  strategySetting: ['strategyView'],
  voteManagement: ['votingDetail', 'addVoting'],
  applicationManagement: ['applicationDetail'],
  chattingRecords: ['conversationView', 'sensitiveWords'],
  materialManagement: [
    'material-img-upload',
    'material-audio-upload',
    'material-video-upload',
    'docDetail',
  ],
  mccp: ['mccp'],
};*/

// 屏蔽代码内控制的路由父子关系
const MENU_ROUTE = {};
const DOCTOR_LEVEL_LIST = [
  { value: 'A', key: '1' },
  { value: 'B', key: '2' },
  { value: 'C', key: '3' },
  { value: 'D', key: '4' },
  { value: 'E', key: '5' },
];
const ARTICLE_STATUS = {
  0: '未发布',
  1: '已发布到公众号',
  2: '发布成功',
  3: '发布失败',
};
const ARTICLE_LIST_STATUS = {
  0: '未发布',
  1: '已发布',
  2: '禁用',
};
const ARTICLE_LIST_PERMISSION = {
  0: '公开',
  1: '登录可查看',
};
const ERROR_BIZCODE_LIST = {
  31009: '该名称已被占用，请更改名称',
};
const VOTE_STATUS = {
  0: '可用',
  1: '已被引用',
};
const GQL_SPECIAL_CHAR = ['\\\\', '%'];
const CUSTOMIZED_VERIFY = {
  a: 'RPA未认证',
  b: 'RPA已认证',
  c: 'RPA认证失败',
  d: '管理员已认证',
  e: 'CRM未认证',
  f: 'CRM认证成功',
  g: 'CRM认证失败',
};
const VERIFY_STATUS = {
  1: '未认证',
  2: '认证成功',
  3: '认证失败',
};
const ARTICLE_LIST_PROJECT = [
  { color: '#F56E64' },
  { color: '#FF8547' },
  { color: '#FFB300' },
  { color: '#7CCC21' },
  { color: '#46C433' },
  { color: '#1FC48D' },
  { color: '#23CDD9' },
  { color: '#1F8FFF' },
  { color: '#AB88F2' },
  { color: '#E65ED3' },
];
const ADTAG_LIST = {
  WEB: 'WEB',
  H5: 'H5',
  H5_list: 'H5列表',
  mini_Program: '小程序',
  official_Account: '公众号',
};
const APPROVAL_STATUS_OPTION = [
  { value: 'AllProcessStatus', label: '全部' },
  { value: 'ProcessPending', label: '审批中' },
  { value: 'ProcessPass', label: '已同意' },
  { value: 'ProcessRefuse', label: '已拒绝' },
  { value: 'ProcessPartialRefuse', label: '部分拒绝' },
  { value: 'ProcessCancel', label: '已撤回' },
];
const TASK_STATUS_OPTION = [
  { value: 'AllTaskStatus', label: '全部' },
  { value: 'TaskApproval', label: '待审批' },
  { value: 'TaskPass', label: '已同意' },
  { value: 'TaskRefuse', label: '已拒绝' },
  { value: 'TaskBackTo', label: '被退回' },
  { value: 'TaskHandled', label: '已处理' },
  { value: 'TaskBack', label: '已退回' },
  { value: 'TaskPartialRefuse', label: '部分拒绝' },
  { value: 'TaskSkip', label: '自动跳过' },
];
const PROCESS_START_TYPE = {
  apply: '提交申请',
  reapply: '重新提交申请',
  appeal: '发起申诉',
};
const EXPORT_FILE_TYPE_LIST = [
  {
    label: 'CSV',
    value: '1',
  },
  {
    label: 'EXCEL',
    value: '2',
  },
];

const IMPORT_TRIGGER = {
  createAndUpdate: 1,
};

const DCR_FIELD = {
  product_id: '产品',
  hco_code: '医院编码',
  num_bed: '病床数',
  address: '地址',
  telephone: '电话',
  num_doctor: '医生数',
  type: '机构类型',
  level: '医院等级',
  name: '医生姓名',
  major_title: '职称',
  hco_id: '医院',
  department: '科室',
  gender: '性别',
  email: '邮箱',
  hcp_id: '医生姓名',
  grade_id: '讲者分级标准',
  sign: '签署状态',
  certification_type: '证件类型',
  certification_no: '证件号码',
  bank_card: '银行卡号',
  bank: '开户行',
  appendix: '附件',
  swift_code: 'Swift Code',
  address: '住址',
};
// 定时审批
const APPROVAL_AUTO_TIMER = {
  AUTO_UNSPECIFIED: 0,
  AUTO_APPROVAL_PASS: '系统自动同意',
  AUTO_APPROVAL_REJECT: '系统自动拒绝',
};
// 登录日志
const LOGIN_LOG_STATUS = {
  1: '成功',
  2: '失败',
};
const LOGIN_LOG_PLATFORM = {
  1: '微信小程序',
  2: 'PC Web',
  3: 'H5',
  4: '企微小程序',
  5: '腾会app',
};

// 公告等级
const ANNOUNCEMENT_LEVEL_LIST = [
  { label: '紧急', value: 1 },
  { label: '重要', value: 2 },
  { label: '提醒', value: 3 },
  { label: '通知', value: 4 },
];

// 公告发送范围
const ANNOUNCEMENT_SEND_RANGE_LIST = [
  { label: '全部员工', value: 1 },
  { label: '指定医生', value: 2 },
  { label: '指定员工', value: 3 },
  { label: '指定组织', value: 5 },
  { label: '指定公共用户组', value: 4 },
];

const MCCP_STATUS = {
  craft: 0,
  waiting: 4,
  publish: 1,
  working: 2,
  timeout: 3,
};

// sso注销类型
const SSO_LOGOUT_TYPE = {
  WITHOUT_LOGOUT_URL: '1', // 没有注销后跳转的url，默认跳单点登录页
  WITH_LOGOUT_URL: '0', // 有注销后跳转的url，直接跳转
  UNKNOWN: '-1', // 初始值，未定义意义
};

const MCCP_COUNT_TYPE = [
  /*
  {
    label: '总次数',
    value: 'total',
  },
  */
  {
    label: '每人/次',
    value: 'hcp_count',
  },
];

const CONNECTOR_TYPES = {
  AND: '且',
  OR: '或',
};

const COMMON_LAYOUT_LIST = ['layoutList', 'layoutTab', 'layoutMutation', 'layoutDetail'];

export {
  SMS_COUNTDOWN,
  IMG_SRC,
  BannerEntryType,
  APP_TYPE,
  PHONE_REG,
  PAGE_SIZE,
  PAGE_SIZES,
  MENU_ROUTE,
  DOCTOR_LEVEL_LIST,
  ARTICLE_STATUS,
  ARTICLE_LIST_STATUS,
  ARTICLE_LIST_PERMISSION,
  ERROR_BIZCODE_LIST,
  VOTE_STATUS,
  GQL_SPECIAL_CHAR,
  CUSTOMIZED_VERIFY,
  VERIFY_STATUS,
  ARTICLE_LIST_PROJECT,
  ADTAG_LIST,
  APPROVAL_STATUS_OPTION,
  TASK_STATUS_OPTION,
  IMPORT_TRIGGER,
  EXPORT_FILE_TYPE_LIST,
  DCR_FIELD,
  APPROVAL_AUTO_TIMER,
  LOGIN_LOG_STATUS,
  LOGIN_LOG_PLATFORM,
  ANNOUNCEMENT_LEVEL_LIST,
  ANNOUNCEMENT_SEND_RANGE_LIST,
  MCCP_STATUS,
  PROCESS_START_TYPE,
  SSO_LOGOUT_TYPE,
  MCCP_COUNT_TYPE,
  CONNECTOR_TYPES,
  COMMON_LAYOUT_LIST,
};
