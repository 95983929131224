import { getCurUrl, throttle, weblstore, nativeStore, doLoginUrlBlack, isWxwork } from '../util';
import { GetAppConfig } from '../api';
import { gotoUrl } from '../common';
import { isArray, includes } from 'lodash';
// eslint-disable-next-line
let lstore = {};
lstore = weblstore;




// type - staff doctor
// status - doctor:'1'-正常 '2'-未注册     staff:'1'-正常 '2'-账密登录
// tokentype - 'weixin','wxwork','tourist','account','url'
// loginas = 'LOGIN_OTHER'-管理员登录其他账号  'LOGIN_SELF'-本人登录
// 用来授权登录的 corpid
const LoginInfos = ['type', 'status', 'tokentype', 'corpid', 'loginas'];
const storeKeyPrefix = 'nges-login-';
const storeData = {};
function setLoginInfo(key, val) {
  const storeKey = `${storeKeyPrefix}${key}`;
  storeData[storeKey] = val;
  val ? lstore.setItem(storeKey, val) : lstore.removeItem(storeKey);
}
function getLoginInfo(key) {
  const storeKey = `${storeKeyPrefix}${key}`;
  // 不能存储在内存中，会导致多个子应用不共享
  // if (storeData[storeKey]) return storeData[storeKey];
  const storeVal = lstore.getItem(storeKey);
  storeData[storeKey] = storeVal;
  return storeVal;
}
function clearLoginInfo() {
  LoginInfos.forEach((key) => {
    setLoginInfo(key, '');
  });
}

const gotoLogin = throttle(
  (url) => {
    const wxaurl = `/pages/main/entry/index?redirect_uri=${encodeURIComponent(url)}`;
    let options = {};
    options = {
      wxaurl,
      force: true,
    };
    console.log('gotoLogin in');
    gotoUrl(options, true);
  },
  1000,
  { trailing: false },
);
// 除了特殊页面不做跳转，其他页面重定向到微信登录页面，登录后会重定向回原来页面
function toLogin(curUrl = '', noblack = false) {
  // console.log('toLogin Func: curUrl', curUrl);
  // console.log('toLogin Func: getCurUrl', getCurUrl());
  const currentUrl = curUrl || getCurUrl();
  if (!currentUrl) {
    // GetMenuTree会触发，导致提示了onlaunch no cururl，不友好
    // return Promise.reject(new Error('onlaunch no cururl'));
    return Promise.reject(new Error('系统需要登录'));
  }
  if (!noblack && doLoginUrlBlack(currentUrl)) {
    return Promise.reject(new Error('当前处于特殊页'));
  }
  console.log('gotoLogin');
  gotoLogin(currentUrl);
  return Promise.reject(new Error('系统需要登录'));
}

async function isCloseLoginWithWeixin(type) {
  let closeLoginWithWX = false;
  try {
    const appConfig = await GetAppConfig();
    const closeLoginWithWxJson = appConfig?.page_config?.close_login_with_wx;
    if (closeLoginWithWxJson) {
      const arr = JSON.parse(closeLoginWithWxJson);
      if (isArray(arr) && arr.length) {
        closeLoginWithWX = includes(arr, type);
      }
    }
  } catch (e) {}
  return closeLoginWithWX;
}

async function isDenyLoginFromWx() {
  let denyLoginFromWx = false;
  
  return denyLoginFromWx;
}

async function checkNeedBindWx() {
  
  
}

export {
  setLoginInfo,
  getLoginInfo,
  clearLoginInfo,
  toLogin,
  lstore,
  isCloseLoginWithWeixin,
  isDenyLoginFromWx,
  checkNeedBindWx,
};
