//
//
//
//
//
//
//
//

const BenzAMRRecorder = require('benz-amr-recorder');
export default {
  name: 'MAudio',
  props: {
    src: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '轻触播放',
    },
    showduration: {
      type: Boolean,
      default: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      audio: null,
      animate: false,
      timer: null,
      duration: null,
    };
  },
  mounted() {
    this.audio = new BenzAMRRecorder();
    this.audio.initWithUrl(this.src).then(() => {
      this.duration = this.format(this.audio.getDuration());
    });
    this.audio.onResume(() => {
      this.handleAnimation();
    });
    this.audio.onPlay(() => {
      this.handleAnimation();
    });
    this.audio.onPause(() => {
      this.animate = false;
      this.timer && clearInterval(this.timer);
    });
    this.audio.onEnded(() => {
      this.animate = false;
      this.timer && clearInterval(this.timer);
    });
    if (window.audioList) {
      window.audioList.push(this.audio);
    } else {
      window.audioList = [this.audio];
    }
  },
  methods: {
    handleAnimation() {
      this.animate = true;
      this.timer = setInterval(() => {
        this.animate = false;
        setTimeout(() => {
          this.animate = true;
        }, 50);
      }, 1250);
    },
    play() {
      if (!this.audio.isInit()) {
        return;
      }
      if (this.audio.isPlaying()) {
        this.audio.pause();
      } else {
        window.audioList.forEach((audio) => {
          // 开始前先关闭所有可能正在运行的实例
          audio.pause();
        });
        this.audio.play();
      }
    },
    format(s) {
      let t = '';
      if (s > -1) {
        const min = Math.floor(s / 60) % 60;
        const sec = s % 60;

        if (min < 10) {
          t += '0';
        }
        // t += min + "'";
        t = `${t}${min}'`;
        if (sec < 10) {
          t += '0';
        }
        t += sec.toFixed(2);
      }
      t = t.replace('.', '"');
      return t;
    },
  },
};
