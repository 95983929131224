const HTTP_REG = /^https?:\/\//i;
const HTTPS_REG = /^https:\/\//i;
const URL_REG = /^(https?:\/\/)|\//i;
const PAGE_SIZE = 10;
// 表格类型的列表默认页数
const TABLE_DEFAULT_PAGE_SIZE = 15;
const PROP_SPLIT = '-';
const PROP_LABEL = `${PROP_SPLIT}label`;
const PROP_CLASS = `${PROP_SPLIT}class`;
const FORMAT_TIME = 'YYYY-MM-dd HH:ii:SS';
const PREFIX_CLASS = 'is-';
const CASCADER_SPLIT = '@@';
const APP_CONFIG = {
  applets_logo_url:
    'https://baike-med-1256891581.file.myqcloud.com/2022020/e98334f0-92ec-11ec-a3f4-354087f4d05d_0.png',
  applets_name: '智慧医药',
  loading_img_url:
    'https://baike-med-1256891581.file.myqcloud.com/2022073/0400ede0-0bfb-11ed-89ce-4ff3ab5f0cf9_0.png',
  home_path: '/pages/main/home/index',
  wecom_corpid: '210850812',
};
const INIT_PAGE_PARAMS = {
  index: 0,
  offset: 0,
  size: 15,
};
const MEETING_TYPE = {
  online: 0, // 线上会
  visit1V1: 1, // 1v1拜访
  offline: 2, // 线下会
  webinar: 3, // 网络研讨会
  onlineAndOffline: 4, // 线上线下会
};
const NORMAL_TIP_COLOR = 'var(--Text-Neutral-placeholder)';
const ERROR_TIP_COLOR = 'var(--Text-Accent-Error-default)';
const DISABLED_COLOR = NORMAL_TIP_COLOR;
const NORMAL_FONT_COLOR = 'var(--Text-Neutral-default)';
const GQL_RESERVED_WROD = ['$_uin', '$_territory'];
const MUTATION_GLOBAL_ACTION_EVENT_KEY = 'MUTATION_GLOBAL_ACTION';
// 特定action不进行规则校验
const ACTION_SHOULD_NOT_CHECK_RULES = [
  'CANCEL',
  'RELOAD',
  'CONFIRM',
  'CLOSE',
  'GET_DRAFT',
  'PRE',
  'NEXT',
];
// 特定action不执行beforeAction钩子
const ACTION_WITHOUT_BEFORE_ACTION = ACTION_SHOULD_NOT_CHECK_RULES;
// 每个布局都会有的action
const COMMON_ACTIONS = ['RELOAD'];

const COLUMN_PERMISSION = {
  readonly: 1,
  editable: 2,
};
const LOGIN_STEP = {
  phone: 'phone',
  territory: 'territory',
  account: 'account',
};

// 归属类级联组件的checkbox模式
const MULTI_FILTER_MODE = [
  'select-many', // 多选
  'select-one', // 单选
  'select-one-includes-children', // 单选且选择子孙节点
  'select-one-includes-direct-children', // 单选且选择直属节点
  'select-many-includes-children', // 多选且选择子孙节点
  'select-many-includes-direct-children', // 多选且选择直属节点
];
const APPROVAL_STATUS_OPTIONS = {
  AllProcessStatus: '全部',
  ProcessPending: '审批中',
  ProcessPass: '已同意',
  ProcessRefuse: '已拒绝',
  ProcessBackedTo: '已退回',
  ProcessCancel: '已撤回',
  ProcessPartialRefuse: '部分拒绝',
};
// 列表布局中单选多选的特殊内置action
const LIST_SELECT_ACTIONS = {
  // 全选
  LIST_SELECT_ALL: 'LIST_SELECT_ALL',
  // 全部移除
  LIST_SELECT_REMOVE_ALL: 'LIST_SELECT_REMOVE_ALL',
  // 确认保存
  LIST_SELECT_SAVE: 'LIST_SELECT_SAVE',
};
const LOGIN_AS = {
  // 管理员登录其他人账号
  LOGIN_OTHER: 'LOGIN_OTHER',
  // 本人登录, 默认本人登录
  LOGIN_SELF: 'LOGIN_SELF',
};
// 附件的mime分类
const MINE_MAP = {
  image: ['heic', 'heif', 'svg', 'png', 'webp', 'jpg', 'jpeg', 'bmp', 'gif'],
  pdf: ['pdf'],
  word: ['doc', 'docx', 'docm', 'dot', 'dotx', 'dotm'],
  video: ['3gp', 'avi', 'mov', 'm4v', 'mp4'],
  audio: ['wav', 'mp3'],
  xls: ['xls', 'xlsx'],
};
const ALL_EXT_ALLOW_TYPE = [
  'heic',
  'heif',
  '3gp',
  'm3u8',
  'avi',
  'mov',
  'm4v',
  'svg',
  'gif',
  'docx',
  'doc',
  'wav',
  'jpg',
  'jpeg',
  'png',
  'bmp',
  'webp',
  'mp3',
  'mp4',
  'pdf',
  'csv',
  'xlsx',
  'xls',
  'zip',
  'rar',
  'ppt',
  'pptx',
  'txt',
  'md',
];

const MINE_MAP_TEXT = {
  image: '图片',
  pdf: 'PDF',
  word: 'Word',
  video: '视频',
  audio: '音频',
};
const SSO_MIDDLE_PATH = '/callback/login';

const WATERMARK_COLOR = {
  COLOR_LIGHT: 'rgba(0, 0, 0, 0.02)',
  COLOR_STANDARD: 'rgba(0, 0, 0, 0.1)',
  COLOR_DARK: 'rgba(0, 0, 0, 0.15)',
};

const ORC_TYPE = {
  BANK_CARD: 2,
  ID_CARD_FRONT: 0,
  ID_CARD_BACK: 1,
};

const WATERMARK_DENSITY = {
  DENSITY_DENSE: 40,
  DENSITY_STANDARD: 80,
  DENSITY_SPARSE: 120,
};

const WEB_SIDEMENU_COLLAPSE_EVENT = 'ngescustomMenuCollapse';

const HELP_GUIDE_STORE_KEY = 'NGES_HELP_GUIDE_HISTORY';

export {
  HTTP_REG,
  HTTPS_REG,
  URL_REG,
  PAGE_SIZE,
  TABLE_DEFAULT_PAGE_SIZE,
  PROP_SPLIT,
  PROP_LABEL,
  PROP_CLASS,
  FORMAT_TIME,
  PREFIX_CLASS,
  APP_CONFIG,
  INIT_PAGE_PARAMS,
  NORMAL_TIP_COLOR,
  ERROR_TIP_COLOR,
  DISABLED_COLOR,
  NORMAL_FONT_COLOR,
  GQL_RESERVED_WROD,
  MUTATION_GLOBAL_ACTION_EVENT_KEY,
  ACTION_SHOULD_NOT_CHECK_RULES,
  ACTION_WITHOUT_BEFORE_ACTION,
  COLUMN_PERMISSION,
  LOGIN_STEP,
  MULTI_FILTER_MODE,
  CASCADER_SPLIT,
  APPROVAL_STATUS_OPTIONS,
  LIST_SELECT_ACTIONS,
  LOGIN_AS,
  MINE_MAP,
  MINE_MAP_TEXT,
  ALL_EXT_ALLOW_TYPE,
  MEETING_TYPE,
  SSO_MIDDLE_PATH,
  WATERMARK_COLOR,
  WATERMARK_DENSITY,
  ORC_TYPE,
  COMMON_ACTIONS,
  WEB_SIDEMENU_COLLAPSE_EVENT,
  HELP_GUIDE_STORE_KEY,
};
