//
//
//
//
//
//
//
//
//
//

import changeItemTable from './change-item-table.vue';
export default {
  components: { changeItemTable },
  props: {
    changeLog: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    handleFold() {
      this.isOpen = !this.isOpen;
    },
  },
};
