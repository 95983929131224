// 元数据对象跳转action
export const NAVIGATION_ACTION = ['TO_CREATE', 'TO_EDIT', 'TO_LIST', 'TO_DETAIL'];

// 元数据对象操作
export const COMMON_GRAPHQL_ACTION = [
  'GRAPHQL_CREATE',
  'GRAPHQL_UPDATE',
  'GRAPHQL_DELETE',
  'GRAPHQL_EXEC',
  'GRAPHQL_DCR_CREATE',
  'GRAPHQL_DCR_UPDATE',
];

export const COMMON_GRAPHQL_ACTION_NAME_ENUM = {
  GRAPHQL_CREATE: '新建',
  GRAPHQL_UPDATE: '更新',
  GRAPHQL_DELETE: '删除',
  GRAPHQL_EXEC: '执行',
  // 新增dcr数据
  GRAPHQL_DCR_CREATE: '保存DCR',
  // 更新dcr数据
  GRAPHQL_DCR_UPDATE: '保存DCR',
};

// 前置ACTION支持的标准回调ACTION
export const CONFIRM_ACTION = ['ALERT', 'CONFIRM'];

// action统一能力内可处理的action
export const INNER_SYSTEM_ACTION = [
  ...CONFIRM_ACTION,
  ...NAVIGATION_ACTION,
  ...COMMON_GRAPHQL_ACTION,
];

// 通知action
export const NOTICE_ACTION = ['NOTICE'];

// Message action 前缀
export const ACTION_MSG_PREFIX = 'MSG:';

// 抛给外部布局组件自己去实现的action
export const EMIT_SYSTEM_ACTION = [
  'CREATE',
  'SAVE',
  'CANCEL',
  'BACK',
  'EDIT',
  'APPLY',
  'RELOAD',
  'RELOAD_CUR_PAGE',
  'WINDOW_CLOSE',
];

// 系统默认action
export const SYSTEM_ACTION = [...INNER_SYSTEM_ACTION, ...EMIT_SYSTEM_ACTION, ...NOTICE_ACTION];

// Action cmd默认前缀
export const ACTION_CMD_PREFIX = 'FaasGatewayServer/func/';

// 元数据对象跳转action对应的标准布局页面路径
export const NAVIGATION_ACTION_MAP_TEMPLATE_LAYOUT_PATH = {
  edit: '/saas/layoutMutation.html',
  create: '/saas/layoutMutation.html',
  list: '/saas/layoutList.html',
  detail: '/saas/layoutDetail.html',
};

// 审批中心action
export const APPROVAL_ACTIONS = [
  'APPROVE_AGREE',
  'APPROVE_REJECT',
  'APPROVE_REFUND',
  'APPROVE_NODE_DETAIL',
  'APPLY',
  'APPEAL',
];

// 自定义选择和提交action
export const SELECT_CONFIRM = ['SELECT_CONFIRM'];

export const VARIATE_ACTION = ['SET_VARIATE', 'GET_VARIATE'];

//  不复制的字段
export const NOT_COPY_FIELDS = [
  'id',
  'belong_territory', // 岗位自动生成
  'owner',
  'create_by',
  'create_time',
  'update_by',
  'update_time',
  'delete_time',
  'version',
];
