//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import List from '../list';
import Action from '../../action';
import { get,isFunction,debounce } from 'lodash';
import ngesDialog from 'nges-common/src/web/components/layout/dialog';

const LIST_LAYOUT_KEY = 'list-layout';
const CREATE_LAYOUT_KEY = 'create-layout';
const EDIT_LAYOUT_KEY = 'edit-layout';
const DETAIL_LAYOUT_KEY = 'detail-layout';

export default {
  name: 'LayoutFullList',
  components: {
    List,
    ngesDialog,
  },
  mixins: [Action],
  props: {
    json: {
      type: Object,
      default: null,
    },
    layout: {
      type: String,
      default: '',
    },
    selection: {
      type: Boolean,
      default: true,
    },
    // 弹框使用的参数
    dialogTitle: {
      type: String,
      default: '',
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogWidth: {
      type: String,
      default: '960px',
    },
    dialogHeight: {
      type: String,
      default: '460px',
    },
    dialogTop: {
      type: String,
      default: 'max(0px, calc((100vh - 610px) / 2))',
    },
    // 组件的额外参数，可以在表达式中通过p访问
    params: {
      type: [Array, String, Number, Object, null],
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      targetId: '',
      detailJson: null,
      mutationJson: null,
      selectionData: [],
      dialogFooterTop: null
    };
  },
  computed: {
    ...mapGetters('user', {
      layoutJson: 'getLayoutJson',
    }),
    listJson() {
      return this.getJson(this.layout || LIST_LAYOUT_KEY);
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(()=>{
        this.dialogFooterTop = this.$refs?.dialogFooter?.getBoundingClientRect().top;
      })
    })
    this.addWinListener();
  },
  beforeDestroy() {
    this.removeListenWinResize()
  },
  methods: {
    initSearch(payload) {
      this.$refs.list.initSearch(payload);
    },
    getJson(key) {
      const json = this.json ? this.json : this.layoutJson;
      return get(json, key) || json || null;
    },
    m_customAction(action) {
      switch (action?.action) {
        case 'CREATE':
        case 'TO_CREATE':
          setTimeout(() => {
            if (!action.__process__.processed) {
              this.mutationJson = this.getJson(this.mutationLayout || CREATE_LAYOUT_KEY);
              this.$nextTick(() => {
                this.$refs.mutation.open({
                  listRef: this.$refs.list,
                  id: action?.action_params?.id,
                });
              });
            }
          });
          break;
        case 'EDIT':
        case 'TO_EDIT':
          setTimeout(() => {
            if (!action.__process__.processed) {
              this.mutationJson = this.getJson(this.mutationLayout || EDIT_LAYOUT_KEY);
              this.$nextTick(() => {
                this.$refs.mutation.open({
                  listRef: this.$refs.list,
                  id: action?.action_params?.id,
                });
              });
            }
          });
          break;
        case 'DETAIL':
        case 'TO_DETAIL':
          setTimeout(() => {
            if (!action.__process__.processed) {
              this.targetId = '';
              this.$nextTick(() => {
                this.targetId = action?.action_params?.id;
                this.detailJson = this.getJson(this.detailLayout || DETAIL_LAYOUT_KEY);
                this.$nextTick(() => {
                  this.$refs.detail.open();
                });
              });
            }
          });
          break;
        default:
          break;
      }
    },
    // 透传列表方法
    setOtherConditionStr(val) {
      this.$refs.list?.setOtherConditionStr(val);
    },
    filterOnSubmit() {
      this.$refs.list?.filterOnSubmit();
    },
    async getTableData(isFirstPage) {
      await this.$refs.list?.getTableData(isFirstPage);
    },
    hideFilter() {
      this.$refs.list?.hideFilter();
    },
    resetAll() {
      this.$refs.list?.resetAll();
    },
    handleSelectionChange(val) {
      this.selectionData = val;
      this.$emit('selection-change', val);
    },
    clearSelection() {
      this.$refs.list?.clearSelection();
    },
    dialogClose() {
      this.$emit('close');
    },
    dialogSuccess() {
      this.$emit('success', this.selectionData);
    },
    addWinListener(){
      if (isFunction(this.removeListenWinResize)) {
        this.removeListenWinResize();
      }
      const func = debounce(() => {
        this.$nextTick(() => {
          this.dialogFooterTop = this.$refs?.dialogFooter?.getBoundingClientRect().top;
        });
      }, 100);
      // const mainEl = document.querySelector('#app>.container>.container-body>main');
      window.addEventListener('resize', func);
      // mainEl.addEventListener('scroll', func);
      this.removeListenWinResize = () => {
        window.removeEventListener('resize', func);
        // mainEl.removeEventListener('scroll', func);
      };
    },
  }

};
