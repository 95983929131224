//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex';
import { NeedReadMessage } from '@/api/notice';
import { layoutRequest } from 'nges-common/src/layout/layoutRequest';
import { easyQueryGql } from 'nges-common/src/web/utils/query';
import { DataService, GetKvConfigs } from 'nges-common/src/web/utils/api';
import { isString, cloneDeep, isNil, get } from 'lodash';
import { getNewLayoutJson } from 'nges-common/src/layout/queryUtil';

const defaultItem = {
  count: 0,
  show: false,
};

const DefaultList = ['todo', 'notice'];

export default {
  name: 'TopQuickEntry',
  data() {
    return {
      list: [],
      notice: {
        ...defaultItem,
        text: '站内信',
        icon: 'icon-notification',
        path: 'noticeCenter',
        request: 'getNotice',
        goto: 'gotoNotice',
      },
      todo: {
        ...defaultItem,
        text: '待办任务',
        icon: 'icon-todo',
        path: 'todoTask',
        request: 'getTodo',
        goto: 'gotoTodo',
      },
      newQuickEntrys: null,
    };
  },
  watch: {
    $route() {
      this.updateData();
    },
    messageCount(val) {
      this.notice.count = val;
    },
  },
  async mounted() {
    await this.checkShow();
    this.updateData();
    this.$emit('load-finish');
  },
  computed: {
    ...mapState('user', ['messageCount']),
  },
  methods: {
    ...mapGetters('user', ['getLayoutJsonWithPath']),
    isString,
    get,
    scopeGet(obj, name) {
      return this[obj][name];
    },
    async checkShow() {
      // 系统首先判断是否存在个人中心布局关联的上述配置入口，如果不存在「个人中心」的布局配置，则使用默认的KV配置进行呈现，当系统无法找到该KV默认配置时，才会使用当前系统中当前版本已存在的固定逻辑。
      const newLayoutJsonQuickEntrys = (await getNewLayoutJson({ usage: 'personal' }))?.find(
        (i) => i.key === 'quickEntrys',
      )?.menus;
      const kvConfigs = (await GetKvConfigs('["personal_center_menu_configuration"]')).kv;
      const kvQuickEntrys =
        kvConfigs &&
        JSON.parse(get(kvConfigs, '[0].value'))?.find((i) => i.key === 'quickEntrys')?.menus;
      this.newQuickEntrys = (newLayoutJsonQuickEntrys || kvQuickEntrys)?.filter(
        (i) => i.key !== 'user_feedback',
      );
      let quickEntrys = this.getLayoutJsonWithPath()('globalConfigWeb')?.quick_entrys;
      let list = [];
      if (this.newQuickEntrys) {
        list = this.newQuickEntrys.map((item) => {
          // 兼容老逻辑，站内信和待办任务为系统保留的快速入口
          if (
            item.type === 'system_component' &&
            DefaultList.find((compName) => compName === item.name)
          ) {
            return item.name;
          }
          return {
            ...item,
            ...defaultItem,
            show: true,
          };
        });
      } else if (quickEntrys) {
        quickEntrys = cloneDeep(quickEntrys);
        list = quickEntrys.map((item) => {
          // 兼容老逻辑，站内信和待办任务为系统保留的快速入口
          if (
            item.type === 'system_component' &&
            DefaultList.find((compName) => compName === item.name)
          ) {
            return item.name;
          }
          return {
            ...item,
            ...defaultItem,
            show: true,
          };
        });
      } else {
        list = DefaultList;
      }
      list.forEach((item) => {
        if (isString(item)) {
          if (this.getLayoutJsonWithPath()(this[item].path)) {
            this[item].show = true;
          }
        }
      });
      this.list = list;
    },
    updateData() {
      this.list.forEach((item) => {
        if (isString(item)) {
          if (!this[item].show) return;
          this[this[item].request]();
        } else {
          if (!item.show) return;
          this.getCount(item);
        }
      });
    },
    getNotice() {
      NeedReadMessage().then(({ message }) => {
        this.notice.count = message._aggregate.count;
      });
    },
    getTodo() {
      const json = this.getLayoutJsonWithPath()('todoTask');
      json.fields = [];
      try {
        // 去掉所有的field 只获取数量
        const query = easyQueryGql(json);
        const regexp = /\{(.*?)\}/g;
        const resultList = [...query.matchAll(regexp)];
        const fieldStr = resultList[resultList.length - 1][1];
        const newQuery = query.replace(fieldStr, '_aggregate{_count}');
        DataService({ query: newQuery })
          .then((res) => {
            this.todo.count = res?.todo_task?._aggregate?.count || 0;
          })
          .catch((e) => {
            console.log(e);
            // 出错时按原定逻辑获取
            layoutRequest(json).then((res) => {
              this.todo.count = res.remoteValues?.length || 0;
            });
          });
      } catch (e) {
        console.log(e);
        layoutRequest(json).then((res) => {
          this.todo.count = res.remoteValues?.length || 0;
        });
      }
    },
    goto(item) {
      this.$emit('jump');
      this[this[item].goto]();
    },
    gotoNotice() {
      if (this.$route.name !== 'noticeCenter') {
        this.g_tourl('/saas/noticeCenter.html');
      }
    },
    gotoTodo() {
      if (!(this.$route.name === 'layoutList' && this.$route.query?.path === 'todoTask')) {
        this.g_tourl('/saas/layoutList.html?path=todoTask');
      }
    },
    // 远程获取数量
    getCount(entryConfig = {}) {
      const json = entryConfig.json || {};
      if (json.object || json.cmd) {
        let extraCondition = {};
        if (json.object) {
          extraCondition = { [json.object]: { _limit: 1 } };
        }
        layoutRequest(json, null, extraCondition).then((res) => {
          let count;
          const aggregateCount = res.aggregate.count;
          const responseCount = res.remoteValues.count;
          if (!isNil(aggregateCount)) {
            count = aggregateCount;
          } else if (!isNil(responseCount)) {
            count = responseCount;
          } else {
            count = res.remoteValues.length;
          }
          this.$set(entryConfig, 'count', count);
        });
      }
    },
    gotoUrl(item) {
      if (item?.url) {
        this.$emit('jump');
        this.g_tourl(item.url);
      }
    },
  },
};
