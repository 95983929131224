var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('el-radio-group',{model:{value:(_vm.radioIndex),callback:function ($$v) {_vm.radioIndex=$$v},expression:"radioIndex"}},_vm._l((_vm.textList),function(item,index){return _c('div',{key:index,staticClass:"radio-item_radio-dialog-content"},[_c('el-radio',{attrs:{"label":index}},[_vm._v(_vm._s(item.text || ''))]),(
            _vm.otherInputMaxLen &&
            _vm.otherRadioIndex !== -1 &&
            _vm.radioIndex === _vm.otherRadioIndex &&
            _vm.otherRadioIndex === index
          )?_c('el-input',{staticClass:"content-input",staticStyle:{"margin-top":"10px"},attrs:{"type":_vm.type,"maxlength":_vm.otherInputMaxLen,"validate-event":_vm.validateEventOpen,"show-word-limit":!!_vm.otherInputMaxLen,"placeholder":_vm.otherInputPlaceholder},model:{value:(_vm.otherReason),callback:function ($$v) {_vm.otherReason=$$v},expression:"otherReason"}}):_vm._e(),(
            !_vm.otherInputMaxLen &&
            _vm.otherRadioIndex !== -1 &&
            _vm.radioIndex === _vm.otherRadioIndex &&
            _vm.otherRadioIndex === index
          )?_c('el-input',{staticClass:"content-input",staticStyle:{"margin-top":"10px"},attrs:{"type":_vm.type,"validate-event":_vm.validateEventOpen,"placeholder":_vm.otherInputPlaceholder},model:{value:(_vm.otherReason),callback:function ($$v) {_vm.otherReason=$$v},expression:"otherReason"}}):_vm._e()],1)}),0)],1),_c('span',{staticClass:"dialog-footer_radio-dialog-content",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.onCancelButton}},[_vm._v(_vm._s(_vm.cancelButtonText))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onConfirmButton}},[_vm._v(_vm._s(_vm.confirmButtonText))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }