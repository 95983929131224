// 标签相关接口
import {
  TagCategoryList,
  TagCategoryListVersion0,
  TagList,
  queryBuInfoListGql,
  queryDomainInfoListGql,
  queryDomainMaxIndexGql,
  queryProductInfoListGql,
  updateBuInfoGql,
  updateDomainInfoGql,
  updateProductInfoGql,
  createBuInfoGql,
  createDomainInfoGql,
  createProductInfoGql,
  updateTagCategoryGql,
  delateTagCategoryGql,
  createTagCategoryGql,
  queryTagLabelListGql,
  createTagLabelInfoGql,
  updateTagLabelInfoGql,
  updateDomainOrderInfoGql,
  updateDomainStatusGql,
  updateBuStatusGql,
  updateProductStatusGql,
  deleteBuGql,
  deleteDomainGql,
  deleteProductGql,
  deleteTagGql,
  deleteTagGqlRel,
  deleteTagBatchGql,
  updateTagBatchGql,
  tagCategories,
  tagCategoriesVersion1,
  tagCategoryTrees,
  categoryTags,
  categoryTagsAll,
  tagDuplicate,
  tagTree,
  tagCategoryTreesByCategoryId,
} from '@/graphql/gql/tag';
import { calVariables } from '@/graphql/util';
import { DataService } from 'nges-common/src/web/utils/api';

export function queryTagCategoryList(data) {
  return DataService({
    query: calVariables(TagCategoryList, data),
  });
}
export function queryTagCategoryListVersion0(data) {
  return DataService({
    query: calVariables(TagCategoryListVersion0, data),
  });
}
export function queryTagTree(data) {
  return DataService({
    query: calVariables(tagTree, data),
  });
}
export function createTagCategory(data) {
  return DataService({
    query: calVariables(createTagCategoryGql, data),
  });
}
export function updateTagCategory(data) {
  return DataService({
    query: calVariables(updateTagCategoryGql, data),
  });
}
export function delateTagCategory(data) {
  return DataService({
    query: calVariables(delateTagCategoryGql, data),
  });
}
export function queryTagLabelList(data) {
  return DataService({
    query: calVariables(queryTagLabelListGql, data),
  });
}
export function createTagLabelInfo(data) {
  return DataService({
    query: calVariables(createTagLabelInfoGql, data),
  });
}
export function updateTagLabelInfo(data) {
  return DataService({
    query: calVariables(updateTagLabelInfoGql, data),
  });
}
export function deleteTag(data) {
  return DataService({
    query: calVariables(deleteTagGql, data),
  });
}
export function deleteTagRel(data) {
  return DataService({
    query: calVariables(deleteTagGqlRel, data),
  });
}
export function queryTagList(data) {
  return DataService({
    query: calVariables(TagList, data),
  });
}
export function queryBuInfoList(data) {
  return DataService({
    query: calVariables(queryBuInfoListGql, data),
  });
}
export function queryDomainInfoList(data) {
  return DataService({
    query: calVariables(queryDomainInfoListGql, data),
  });
}
export function queryDomainMaxIndex() {
  return DataService({
    query: queryDomainMaxIndexGql,
  });
}
export function queryProductInfoList(data) {
  return DataService({
    query: calVariables(queryProductInfoListGql, data),
  });
}
export function updateBuInfo(data) {
  return DataService({
    query: calVariables(updateBuInfoGql, data),
  });
}
export function updateDomainInfo(data) {
  return DataService({
    query: calVariables(updateDomainInfoGql, data),
  });
}
export function updateBuStatus(data) {
  return DataService({
    query: calVariables(updateBuStatusGql, data),
  });
}
export function updateDomainStatus(data) {
  return DataService({
    query: calVariables(updateDomainStatusGql, data),
  });
}
export function updateProductStatus(data) {
  return DataService({
    query: calVariables(updateProductStatusGql, data),
  });
}
export function deleteBu(data) {
  return DataService({
    query: calVariables(deleteBuGql, data),
  });
}
export function deleteDomain(data) {
  return DataService({
    query: calVariables(deleteDomainGql, data),
  });
}
export function deleteProduct(data) {
  return DataService({
    query: calVariables(deleteProductGql, data),
  });
}
export function updateDomainOrderInfo(data) {
  return DataService({
    query: calVariables(updateDomainOrderInfoGql, data),
  });
}
export function updateProductInfo(data) {
  return DataService({
    query: calVariables(updateProductInfoGql, data),
  });
}
export function createBuInfo(data) {
  return DataService({
    query: calVariables(createBuInfoGql, data),
  });
}
export function createDomainInfo(data) {
  return DataService({
    query: calVariables(createDomainInfoGql, data),
  });
}
export function createProductInfo(data) {
  return DataService({
    query: calVariables(createProductInfoGql, data),
  });
}
// 获取所有标签组
export function queryTagCategories() {
  return DataService({
    query: tagCategories,
  });
}
// 查询标签组树结构
export function queryTagCategoryTrees() {
  return DataService({
    query: tagCategoryTrees,
  });
}

// 获取 version 1 的标签组
export function queryTagCategoriesVersion1() {
  return DataService({
    query: tagCategoriesVersion1,
  });
}

export function queryCategoryTags(data) {
  return DataService({
    query: calVariables(categoryTags(data), data),
  });
}

export function queryCategoryTagsAll(data) {
  return DataService({
    query: calVariables(categoryTagsAll(data), data),
  });
}

export function checkTagDuplicate(data) {
  return DataService({
    query: calVariables(tagDuplicate, data),
  });
}

export function updateTagBatch(data) {
  return DataService({
    query: calVariables(updateTagBatchGql, data),
  });
}

export function deleteTagBatch(data) {
  return DataService({
    query: calVariables(deleteTagBatchGql, data),
  });
}

// 查询标签组树结构根据标签组id
export function queryTagCategoryTreesByCategoryId(data) {
  return DataService({
    query: calVariables(tagCategoryTreesByCategoryId(data), data),
  });
}
