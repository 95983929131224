//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import Action from '../action';
import { execExpressionDeep } from '../../../layout/expression';
import tooltipDetail from '../layout/tooltip-detail';

export default {
  name: 'LayoutSubtitle',
  components: { tooltipDetail },
  mixins: [Action],
  props: {
    json: {
      type: Object,
      default: null,
    },
    expParamsObj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      visible: true,
      descsVisible: false,
    };
  },
  computed: {
    alert() {
      if (!this.json) return null;
      return execExpressionDeep(_.cloneDeep(this.json), this.expParamsObj, true, false, {}, '', [
        'hint_config',
      ]);
    },
  },
  methods: {
    getAlert() {
      const result = [];
      (this.alert?.content || []).forEach((item) => {
        if (result.length === 0) result.push(item);
        else {
          // 合并相邻字符串 最终得到的返回值内必定不包含相邻字符串
          const lastType = typeof result[result.length - 1];
          if (lastType === 'object') result.push(item);
          else if (lastType === 'string' && typeof item === 'object') {
            result.push(item);
          } else if (lastType === 'string' && typeof item === 'string') {
            result[result.length - 1] += item;
          }
        }
      });

      const row = [];
      let rowData = [];
      result.forEach((i) => {
        // 如果字符串内有换行符
        if (typeof i === 'string' && i.includes('\n')) {
          const rowSplit = i.split('\n');
          // 按换行符切分
          rowSplit.forEach((j, index) => {
            // 把换行符前的数据交给这一行
            rowData.push(j);
            // 关闭这一行 开启新行 但不为最后一段开启新行
            if (index !== rowSplit.length - 1) {
              row.push(rowData);
              rowData = [];
            }
          });
        } else {
          rowData.push(i);
        }
      });
      if (rowData.length) row.push(rowData);
      return row;
    },
  },
};
