//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import materialCard from './materialCard.vue';
import filterCascader from './filterCascader.vue';
import { isNumber } from 'lodash';
import { arrUnique } from '@/utils/util';

export default {
  components: { materialCard, filterCascader },
  props: {
    // 可选布局
    json: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: 'image', // image, audio, video
    },
    limit: {
      type: Number,
      default: 0, // 0 不限制
    },
    selected: {
      type: Array,
      default: () => [],
    },
    isRadio: {
      type: Boolean,
      default: false,
    },
    // 列表上的操作,  select选择selected数组, remove剔除掉已选selected的数据
    selectedType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // showData: [],
      disabled: false,
      selectedDataList: [],
      filterCondition: '',
    };
  },
  computed: {
    layoutJSON() {
      const resetActions = this.json?.actions?.filter(
        (item) => item.action === 'RESET_CUSTOM_FILTERS', // 最新版本不需要定开了
      );
      if (resetActions?.length) {
        resetActions.forEach((item) => {
          //  配置太多了 租户太多 老版本兼容下
          item.action = 'RESET';
        });
      }
      return this.json;
    },
    // 禁用筛选器样式(优化过, 默认使用自定义样式)
    disabledFilterStyle() {
      return this.layoutJSON.disabledFilterStyle || false;
    },
    // 卡片列表样式
    cardStyle() {
      const maxHeight = this.json?.render?.max_height;
      if (!maxHeight) {
        return;
      }
      return `max-height: ${maxHeight}${isNumber(maxHeight) ? 'px' : ''}`;
    },
    // 自定义筛选
    tagIdFilter() {
      return this.json?.fields?.find((i) => i.filter?.slot === 'filter-tag_ids')?.filter;
    },
    // 自定义查询条件
    customCondition() {
      // 目前单选就是插入文档, 如果后续单选有用于其他逻辑,请增加参数判断
      let otherCondition = '';
      if (this.type === 'doc') {
        otherCondition = this.isRadio
          ? `_where:{ _and:[{insert_content: 1}, {eda_doc.id:{_is_null:false}}] }`
          : '';
      }
      if (this.selectedType === 'remove' && this.selected?.length) {
        const ids = this.selected.map((i) => `"${i.id}"`);
        otherCondition += ` _where:{ _not:{ id: { _in: [${ids.join(',')}] } } }`;
      }
      return `${otherCondition} ${this.filterCondition}`;
    },
    selectedIds() {
      if (this.selectedType === 'select' && this.selectedDataList.length) {
        return this.selectedDataList.map((i) => i.id);
      }
      return [];
    },
  },
  watch: {
    // selected: {
    //   handler(newVal) {
    //     this.selectedDataList = [...(newVal || [])];
    //   },
    //   immediate: true,
    // },
  },
  mounted() {
    // this.resetFilters();
    // this.getDataList();
    // 默认勾选
    if (this.selectedType === 'select' && this.selected?.length) {
      this.selectedDataList = [...this.selected];
    }
  },
  methods: {
    // 父组件使用
    getData() {
      const materialCard = this.$refs?.materialCard;
      if (materialCard) {
        return materialCard.getData();
      }
      return { data: [...this.selectedDataList] };
    },
    // 父组件使用
    clearData() {
      this.resetFilters();
      this.selectedDataList = [];
      this.$refs.materialCard?.clearData();
    },
    handleResetFilter() {
      this.filterCondition = '';
      this.$refs?.materialFilter?.resetFilters?.();
    },
    resetFilters() {
      // this.$refs?.materialFilter?.resetFilters?.();
      // this.filterVals.whereGql = '';
      // if (refreshDataList) {
      //   this.getDataList(1);
      // }
      this.filterCondition = '';
      this.$refs?.materialFilter?.resetFilters?.();
      this.$nextTick(() => {
        this.$refs?.list?.resetAll?.();
      });
    },
    handleFilterChange(data) {
      this.filterCondition = data.whereGql;
      this.$nextTick(() => {
        this.$refs.list?.getTableData();
      });
    },
    // handleGetDataSuccess(data) {
    //   const showData = data || [];
    //   // 暂时支持doc吧, 其他没有测试过
    //   if (this.type === 'doc' && showData.length && this.selected?.length) {
    //     const checkedWithoutCurrentPage = [];
    //     this.selected.forEach((item) => {
    //       if (!showData.some((i) => i.id === item.id)) {
    //         // 剔除当页数据 checkedWithoutCurrentPage
    //         checkedWithoutCurrentPage.push(item);
    //       }
    //     });
    //     this.selected = checkedWithoutCurrentPage;
    //   }
    // },
    handleSelectionChange(val, isRadioEvent) {
      if (this.isRadio !== isRadioEvent) {
        return;
      }

      const checkedWithoutCurrentPage = [];
      const showData = this.$refs?.list?.getListRef()?.showData || [];
      if (this.selectedType === 'select' && showData.length && this.selectedDataList.length) {
        this.selectedDataList.forEach((item) => {
          if (!showData.some((i) => i.id === item.id)) {
            // 剔除当页数据
            checkedWithoutCurrentPage.push(item);
          }
        });
      }
      this.selectedDataList = arrUnique([...checkedWithoutCurrentPage, ...val], 'id');
      this.emitErrorTip(val);
    },
    emitErrorTip(selectedList) {
      let errorTip = '';
      if (this.limit) {
        const preSelectedLength = this.selected?.length || 0;
        const maxlength = selectedList.length;
        if (maxlength > this.limit) {
          // let typeName = '个';
          // if (this.type === 'image') {
          //   typeName = '张图片';
          // } else if (this.type === 'video') {
          //   typeName = '个视频';
          // } else if (this.type === 'aduio') {
          //   typeName = '个音频';
          // } else if (this.type === 'doc') {
          //   typeName = '个文档';
          // } else if (this.type === 'vote') {
          //   typeName = '个投票';
          // }
          errorTip = `当前可选数量: ${this.limit - preSelectedLength}, 已超过最大数量`;
        }
      }

      this.$emit('error', { error: errorTip });
    },
    actionHandle({ action /* , value*/ }) {
      if (action === 'UPLOAD') {
        // 上传 内置吧(也可以配置出来)
        let url;
        if (this.type === 'image') {
          url = `/saas/materialManagement/material-img-upload.html`;
        } else if (this.type === 'audio') {
          url = `/saas/materialManagement/material-audio-upload.html`;
        } else if (this.type === 'video') {
          url = `/saas/materialManagement/material-video-upload.html`;
        }
        if (!url) {
          return;
        }
        this.g_tourl(url, {
          open: true,
        });
      } else if (action === 'RESET_CUSTOM_FILTERS') {
        // this.resetFilters();
      } else if (action === 'SELECTED_MODE') {
        // this.isCheckedAllowSelectedMode = value;
      }
    },
  },
};
