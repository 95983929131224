import loginConfig, { login, touristLogin, webLogin } from '@tencent/eyao-login-web-sdk';
import { TouristLogin, GetAppConfig } from '../api';
import {
  weblstore,
  query,
  getCurUrl,
  loginUrlBlack,
  isWxwork,
  isWechat,
  arrBlack,
  isPreview,
  storeCheck,
  cookie,
} from '../util';
import { setLoginInfo, toLogin, getLoginInfo } from './common';
import { checkUpdate } from '../update';
import config from '../config';
import { clearLogin } from './index';
import aegis from 'nges-common/src/aegis';

const { VUE_APP_TOKEN } = process.env;
const lstoreAppid = 'nges-appid';

function getUrlAppid() {
  // 兼容企业微信登录 appid 被覆盖场景, transfer 页面的 appid 从 redirect_uri 获取
  // 对于登录场景，appid已经存储，无需再去取
  if (isWxwork() && query('code')) {
    return '';
  }
  return query('appid') || '';
}

function setAppid() {
  const appid = getUrlAppid();
  if (appid) {
    weblstore.setItem(lstoreAppid, appid);
  }
}
function getAppid() {
  return getUrlAppid() || weblstore.getItem(lstoreAppid);
}

function getToken() {
  const urlAppid = getUrlAppid();
  const storeAppid = weblstore.getItem(lstoreAppid);
  if (urlAppid && urlAppid !== storeAppid) {
    // debugger;
    // login.clearLogin();
    // clearLoginInfo();
    // 需要切换appid
    setAppid();
    // return '';
  }
  const type = query('type');
  const tokentype = getLoginInfo('tokentype');
  const token = login.getLoginInfo('token');
  if (
    tokentype === 'tourist'
    && !(type === 'tourist' || isPreview() || arrBlack(getCurUrl(), config.touristPages))
  ) {
    console.log('游客不可访问', token);
    return '';
  }
  return token;
}

function setConfig(option = {}) {
  const { VUE_APP_MINI_ENV: vueAppMiniEnv } = process.env;
  let defaultLoginCodePath = '';
  if (config.loginCodePath !== undefined) {
    defaultLoginCodePath = config.loginCodePath;
  } else if (vueAppMiniEnv !== 'production') {
    defaultLoginCodePath = 'https://h5.nges.tencent.com/login-code.html?redirect_uri=';
  }
  loginConfig({
    loginKey: 'nges-h5-token',
    appid: getAppid(),
    store: weblstore,
    // 开发、测试环境 授权公众号 中转页 建议：https://eyao.qq.com/login-code.html?redirect_uri=
    loginCodePath: defaultLoginCodePath,
    ...option,
  });
}

// 兼容灰度功能
function saasLoginKey(name) {
  const key = {
    token: 'saas_token',
    uin: 'saas_uin',
    domain: null,
    storage: 'saas_storage',
  };
  return key[name];
}

function setSaasLogin({ token, uin, time }) {
  const timeMinute = time > 60 ? (time - 60) / 60 : 60;
  const tokenKey = saasLoginKey('token');
  const uinKey = saasLoginKey('uin');
  const domain = saasLoginKey('domain');
  const path = '/';
  token
    ? cookie.setCookie(tokenKey, token, timeMinute, path, domain)
    : cookie.delCookie(tokenKey, path, domain);
  uin
    ? cookie.setCookie(uinKey, uin, timeMinute, path, domain)
    : cookie.delCookie(uinKey, path, domain);
}

function doTouristLogin() {
  const appid = getAppid();
  if (appid) {
    setConfig({
      touristLoginApi: () => {
        return TouristLogin({ appid });
      },
    });
    return touristLogin
      .touristLogin()
      .then((res) => {
        setSaasLogin({
          token: res.token,
          uin: res.uin,
          time: res.expires_in,
        });
        setLoginInfo('tokentype', 'tourist');
        setLoginInfo('status', '1');
        return res;
      })
      .catch((e) => {
        // 回到登录页
        if (!checkUpdate(e)) {
          return Promise.reject(e);
        }
      });
  }
  return Promise.reject(new Error('no appid'));
}

function doLogin() {
  const token = getToken();
  if (token) {
    return Promise.resolve(token);
  }
  if (VUE_APP_TOKEN) {
    login.setLogin({
      token: VUE_APP_TOKEN,
      time: 3600,
      type: 'VUE_APP_TOKEN',
    });
    setLoginInfo('type', 'staff');
    setLoginInfo('tokentype', 'VUE_APP_TOKEN');
    setLoginInfo('status', '1');
    return Promise.resolve(VUE_APP_TOKEN);
  }
  const appid = getAppid();
  const curUrl = getCurUrl();
  if (!curUrl || !appid || loginUrlBlack(curUrl)) {
    return Promise.reject(new Error('系统需要登录'));
  }

  return storeCheck({
    key: 'doLogin',
    promise: () => {
      return autoLogin().catch((e) => {
        console.error('autoLogin fail: ', e);
        return toLogin();
      });
    },
  });
}

function doWxworkLogin({ loginPath }) {
  return GetAppConfig().then((companyConfig) => {
    aegis.report({
      msg: 'autoLogin-doWxworkLogin',
      level: 'log',
      ext1: `corpid: ${companyConfig.wecom_corp_id}`,
    });
    // 自动授权登录根据环境判断授权方式
    const corpid = companyConfig?.wecom_corp_id;
    if (!corpid) return Promise.reject(new Error('no corpid'));
    setLoginInfo('corpid', corpid);
    const scope = 'snsapi_base';
    const options = {
      appid: corpid,
      loginPath,
    };
    // 兼容辉瑞未使用第三方应用场景
    if (companyConfig.wecom_auth_url) {
      options.loginCodePath = companyConfig.wecom_auth_url;
    }
    setConfig(options);
    return webLogin.wxLogin(scope, true);
  });
}

function doWeixinLogin({ loginPath }) {
  return GetAppConfig().then((companyConfig) => {
    const componentAppid = companyConfig?.component_appid;
    if (!componentAppid) return Promise.reject(new Error('no component_appid'));
    const scope = 'snsapi_userinfo';
    setConfig({
      component_appid: componentAppid,
      loginPath,
    });
    return webLogin.wxLogin(scope, true);
  });
}

function autoLogin(notourist = false) {
  const appid = getAppid();
  const type = query('type');
  aegis.report({
    msg: 'autoLogin-',
    level: 'log',
    ext1: `appid: ${appid}, type: ${type}, isWxwork: ${isWxwork()}, isWechat: ${isWechat()}`,
  });
  if (appid) {
    // 链接参数，支持游客
    if (!notourist) {
      if (type === 'tourist' || isPreview()) {
        return doTouristLogin().then(() => {
          return getToken();
        });
      }
    }

    // 企业微信，默认是staff
    if (isWxwork()) {
      clearLogin();
      setLoginInfo('type', type === 'doctor' ? 'doctor' : 'staff');
      return doWxworkLogin({ loginPath: `/pages/login/transfer/index?type=wxwork` });
    }

    // 微信环境，需要type参数，确定登录方式
    if (isWechat() && ['staff', 'doctor'].includes(type)) {
      clearLogin();
      setLoginInfo('type', type);
      return doWeixinLogin({ loginPath: `/pages/login/transfer/index?type=wx` });
    }
  }

  return Promise.reject(new Error('系统需要登录'));
}

const customLogin = {
  autoLogin,
  getToken,
  getAppid,
  setAppid,
  doLogin,
  doWxworkLogin,
  doWeixinLogin,
  setSaasLogin,
  doTouristLogin,
};

export { setConfig, customLogin, login, webLogin };
