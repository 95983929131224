import Dexie from 'dexie';

const IndexedDbStore = function (dbName, tableName, indexs = []) {
  this.db = null;
  this.tableName = tableName;
  this.dbName = dbName;
  this.indexs = indexs;
  this.db = new Dexie(dbName);
  this.db.version(1).stores({
    [tableName]: indexs,
  });
};

IndexedDbStore.prototype.add = function (newItem) {
  return this.db[this.tableName].add(newItem);
};

IndexedDbStore.prototype.delete = function (keyToDelete) {
  return this.db[this.tableName].delete(keyToDelete);
};

IndexedDbStore.prototype.upsert = function (item) {
  return this.db[this.tableName].put(item);
};

IndexedDbStore.prototype.get = function (key, value) {
  return this.db[this.tableName].where({ [key]: value }).first();
};

IndexedDbStore.prototype.where = function (arg) {
  return this.db[this.tableName].where(arg);
};

IndexedDbStore.prototype.findAllAndProcessConfig = async function () {
  return this.db[this.tableName].toArray();
};

export default IndexedDbStore;
