export const APPROVAL_STATUS_OPTION = [
  { value: 'AllProcessStatus', label: '全部' },
  { value: 'ProcessPending', label: '审批中' },
  { value: 'ProcessPass', label: '已同意' },
  { value: 'ProcessRefuse', label: '已拒绝' },
  { value: 'ProcessPartialRefuse', label: '部分拒绝' },
  { value: 'ProcessCancel', label: '已撤回' },
];
export const TASK_STATUS_OPTION = [
  { value: 'AllTaskStatus', label: '全部' },
  { value: 'TaskApproval', label: '待审批' },
  { value: 'TaskPass', label: '已同意' },
  { value: 'TaskRefuse', label: '已拒绝' },
  { value: 'TaskPartialRefuse', label: '部分拒绝' },
  { value: 'TaskBackTo', label: '被退回' },
  { value: 'TaskHandled', label: '已处理' },
  { value: 'TaskBack', label: '已退回' },
  { value: 'TaskSkip', label: '自动跳过' },
];
// 定时审批
export const APPROVAL_AUTO_TIMER = {
  AUTO_UNSPECIFIED: 0,
  AUTO_APPROVAL_PASS: '系统自动同意',
  AUTO_APPROVAL_REJECT: '系统自动拒绝',
};
// 流程发起类型
export const PROCESS_START_TYPE = {
  apply: '提交申请',
  reapply: '重新提交申请',
  appeal: '发起申诉',
};
