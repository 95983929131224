import { isArray } from 'lodash';
import {
  WRITE_PERMISSION_KEY,
  WRITE_PERMISSION_CONFIG_TYPE,
} from '../../../layout/writePermission';
import { GetUserElementList, GetTenantObjectsPermission } from '../../utils/api';

function isWritePermissionControlType(item, controlType) {
  if (item.permission && isArray(item.permission)) {
    const permission = item.permission.find(({ type }) => type === WRITE_PERMISSION_CONFIG_TYPE);
    if (
      permission
      && (permission.control_type === controlType
        || (!permission.control_type && controlType === 'disabled'))
    ) {
      return true;
    }
  }
  return false;
}

export default {
  data() {
    return {
      // 元素与数据权限
      mixinPermissionElement: {
        element: [],
        data: [],
      },
      mixinPermissionLoading: true,
      WRITE_PERMISSION_CONFIG_TYPE,
      WRITE_PERMISSION_KEY,
    };
  },
  created() {
    Promise.all([GetUserElementList(), GetTenantObjectsPermission()]).then(([{ data }, list]) => {
      this.mixinPermissionElement.element = data.map(({ code }) => code);
      this.mixinPermissionElement.data = list;
      this.mixinPermissionLoad();
    });
  },
  methods: {
    // 需要等待权限加载完成后再执行的操作放在此方法内
    mixinPermissionLoad() {
      this.mixinPermissionLoading = false;
    },
    // 按权限进行筛选
    mixinFilterPermission(item) {
      if (item.permission && isArray(item.permission)) {
        const elementPermission = item.permission
          .filter(({ type }) => type === 'element')
          .map(({ code }) => code);
        const dataPermission = item.permission
          .filter(({ type }) => type === 'data')
          .map(({ code }) => code);
        if (elementPermission.some((code) => !this.mixinPermissionElement.element.includes(code))) {
          return false;
        }
        if (dataPermission.some((code) => !this.mixinPermissionElement.data.includes(code))) {
          return false;
        }
      }
      return true;
    },
    mixinFilterWritePermission(data) {
      return (item) => {
        if (isWritePermissionControlType(item, 'hidden')) {
          return data?.[WRITE_PERMISSION_KEY] || false;
        }
        return true;
      };
    },
    mixinDisabledWritePermission(item, data) {
      if (isWritePermissionControlType(item, 'disabled')) {
        return data?.[WRITE_PERMISSION_KEY] !== true;
      }
      return false;
    },
  },
};
