const { VUE_APP_ENV: vueAPPEnv, VUE_APP_TENCENT_MAP_KEY: tencentMapKey } = process.env;

function setConfig(config, { development, test, preview, production }) {
  const domain = window.location.host;
  if (/^localhost/.test(domain) || /^saas.local.qq.com/.test(domain)) {
    config = {
      ...config,
      ...development,
      tencentMapKey,
    };
    return config;
  }
  const configs = {
    development,
    test,
    preview,
    production,
  };
  for (const key in configs) {
    if (Object.prototype.hasOwnProperty.call(configs, key)) {
      const configItem = configs[key];
      if (configItem.length) {
        const domainConfig = configItem.find((i) => i.domain.includes(domain));
        config = {
          ...config,
          ...domainConfig,
        };
        return config;
      }
      if (configItem.env === vueAPPEnv) {
        config = {
          ...config,
          ...configItem,
        };
        return config;
      }
    }
  }
  
  config = {
    ...config,
    ...production,
  };
  return config;
}
module.exports = {
  setConfig,
};
