//
//
//
//
//
//
//
//
//
//
//

import { getDataLockStatus, unlockData, lockData } from '../../utils/api';
import { cookie } from 'nges-common/src/web/utils/util';
export default {
  props: {
    object: {
      type: String,
      default: '',
    },
    targetId: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '对记录加锁',
    },
  },
  data() {
    return {
      isLock: false,
      disabled: false,
      uin: '',
    };
  },
  // 部分布局下 mounted 生命周期拿不到 object；
  watch: {
    object() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async handleCheckLockStatus() {
      // 先回到点击状态之前
      this.isLock = !this.isLock;
      const errorMessageText = this.isLock ? '解锁失败' : '加锁失败';
      try {
        if (this.disabled) return;
        const payload = {
          data: [
            {
              object: this.object,
              ids: [this.targetId],
            },
          ],
        };
        let res;
        if (this.isLock) {
          res = await unlockData(payload);
        } else {
          res = await lockData(payload);
        }
        console.log('------res.data------', res.data?.[0]?.ids);
        if (res.data?.[0]?.ids?.length > 0) {
          // 调用成功再改变状态
          this.isLock = !this.isLock;
        } else {
          this.$message.error(`${errorMessageText}`);
        }
      } catch (e) {
        this.$message.error(`${errorMessageText}:${JSON.stringify(e)}`);
      }
    },
    async init() {
      const {
        data,
        enable_lock: enableLock,
        is_admin_user: isADminUser,
      } = await getDataLockStatus({
        object: this.object,
        ids: [this.targetId],
      });
      console.log('------enableLock------', enableLock);
      const { owner } = data[0] || {};
      if (!enableLock) {
        this.disabled = true;
        return;
      }
      const currentUin = cookie.getCookie('saas_uin');
      if (!isADminUser && owner !== currentUin) {
        this.disabled = true;
      }
      this.isLock = data[0].is_locked;
    },
  },
};
