/* eslint-disable */
import { Select } from 'element-ui';
function override() {
  const resetInputHeight = Select?.methods.resetInputHeight;
  if(resetInputHeight) {
    Select.methods.resetInputHeight = function(debug) {
      if (this.collapseTags && !this.filterable) return;
      this.$nextTick(() => {
        if (!this.$refs.reference) return;
        let inputChildNodes = this.$refs.reference.$el.childNodes;
        let input = [].filter.call(inputChildNodes, item => item.tagName === 'INPUT')[0];
        const tags = this.$refs.tags;
        const tagsHeight = tags ? Math.round(tags.getBoundingClientRect().height) : 0;
        const sizeInMap = this.initialInputHeight || 32; // override: 默认用32px设置高度
        input.style.height = this.selected.length === 0
          ? sizeInMap + 'px'
          : Math.max(
          tags ? (tagsHeight + (tagsHeight > sizeInMap ? 6 : 0)) : 0,
          sizeInMap
        ) + 'px';
        if (this.visible && this.emptyText !== false) {
          this.broadcast('ElSelectDropdown', 'updatePopper');
        }
      });
    };
  }
}
override();
