//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAreaList } from '@/api/setUp.js';
// 区域编码,查区域时不传；查省时传空为查所有省，传选中的区域编码为查当前区域下的省；查市时传空为查所有市，传选中的省编码为查当前省下的市
export default {
  name: 'ProvinveCityArea',
  components: {},
  props: {},
  data() {
    return {
      // 数据
      province: [],
      city: [],
      area: [],
      // 操作返回的参数
      provinceVal: '',
      provinceLabel: '',
      cityVal: '',
      cityLabel: '',
      areaVal: '',
      areaLabel: '',
      // 配置
      text: '无数据',
    };
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {
    this.getData({ type: 'Province' });
    // this.getData({ type: 'City' });
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    // type-查询类型，Area为区域，Province为省，City为市
    async getData(query) {
      this.text = '加载中';
      const _this = this;
      if (query.type === 'City' && query.area_code) {
        // 选择省份, 重置市数据
        _this.city = [];
        _this.area = [];
        _this.cityVal = '';
        _this.areaVal = '';
      } else if (query.type === 'Area' && query.area_code) {
        // 选择市, 重置区数据
        _this.area = [];
        _this.areaVal = '';
      }
      try {
        const res = await getAreaList(query);
        const { data } = res;
        // data.unshift({ areaName: '全部', areaCode: '' });

        _this[query.type.toLowerCase()] = data;
      } catch (error) {
        console.log(error);
        this.text = '网络异常';
        _this.$message('网络请求失败, 请稍后再试');
      }
      this.text = '无数据';
    },
    // 省改变
    provinceChange(code) {
      if (!code) return; // 清空
      // 请求
      this.getData({ area_code: code, type: 'City' });

      // 通过value获得label名
      const filter = this.province.filter((e) => e.area_code === code);
      // 保存label名
      this.provinceLabel = filter[0].area_name;
      // 回调
      this.$emit('provinceCityAreaEmit', {
        province: filter[0].area_name === '全部' ? '' : filter[0].area_name,
        city: '',
        area: '',
      });
    },
    // 市改变
    cityChange(code) {
      if (!code) return; // 清空

      // 请求
      // this.getData({ areaCode:code, type: 'Area' });

      // 通过value获得label名
      console.log(this.city);
      const filter = this.city.filter((e) => e.area_code === code);
      // 保存label名
      this.cityLabel = filter[0].area_name;
      // 回调
      this.$emit('provinceCityAreaEmit', {
        province: this.provinceLabel,
        city: filter[0].area_name === '全部' ? '' : filter[0].area_name,
        area: '',
      });
    },
    // 省清空icon
    provinceClear() {
      this.city = [];
      this.area = [];
      this.cityVal = '';
      this.areaVal = '';
      this.provinceVal = '';
      this.$emit('provinceCityAreaEmit', {
        province: '',
        city: '',
        area: '',
      });
    },
    // 市清空icon
    cityClear() {
      // 选择市, 重置区数据
      this.area = [];
      this.areaVal = '';

      this.$emit('provinceCityAreaEmit', {
        province: this.provinceLabel,
        city: '',
        area: '',
      });
    },
  },
};
