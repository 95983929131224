const storeFiles = require.context('.', true, /index\.js$/);
const modules = {};
storeFiles.keys().forEach((fileName) => {
  if (fileName.includes('/store/index.js')) {
    const module = require(`${fileName}`);
    const moduleName = fileName.slice(2).split('/')[0];
    const moduleDefault = module.default;
    Object.keys(moduleDefault).forEach((key) => {
      modules[`${moduleName}/${key}`] = moduleDefault[key];
    });
  }
});
console.log(modules);
export default modules;
