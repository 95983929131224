//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cloneDeep } from 'lodash';
import { ResetPassword } from '@/api/login';

const DEFAULT_FORM = {
  old_password: '',
  new_password: '',
  new_password_confirm: '',
};

const VALIDATE_PWD_CHAR_TYPE = (value) => {
  const hasCapital = /[A-Z]/g.test(value);
  const hasLowerCase = /[a-z]/g.test(value);
  const hasNum = /[0-9]/g.test(value);
  const hasSymbol = /[~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/? ]/g.test(value);
  const ok = /^([A-Za-z0-9]|[~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/? ])+$/g.test(value);
  return ok && hasCapital + hasLowerCase + hasNum + hasSymbol >= 3;
};

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: cloneDeep(DEFAULT_FORM),
      rules: {
        old_password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        new_password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { validator: this.validatePwdRule(), trigger: 'blur' },
        ],
        new_password_confirm: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (this.form?.new_password !== value) {
                callback('密码不一致');
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    open() {
      this.form = cloneDeep(DEFAULT_FORM);
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    validatePwdRule() {
      return (rule, value, callback) => {
        if (value === '') {
          callback(new Error('密码不能为空'));
        } else if (value === this.form?.old_password) {
          callback(new Error('新密码与旧密码不能相同，请重新填写'));
        } else if (value.length < 8 || value.length > 30 || !VALIDATE_PWD_CHAR_TYPE(value)) {
          callback(
            new Error('密码长度应为8-30字，必须为大写字母，小写字母，数字或符号，且至少需包含3种'),
          );
        } else {
          callback();
        }
      };
    },
    async submit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        try {
          this.loading = true;
          await ResetPassword(this.form);
          this.loading = false;
          this.$message({
            message: '密码修改成功',
            type: 'success',
          });
          setTimeout(() => {
            this.$emit('success');
          }, 1000);
        } catch (e) {
          this.loading = false;
          this.$message.error(e.message || '修改失败');
        }
      });
    },
    handleClose() {
      this.$refs.form.clearValidate();
    },
  },
};
