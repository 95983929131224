//
//
//
//
//
//
//
//
//
//

import detailLayout from '../../layout/detail';
import { globalConfig } from './util';

export default {
  name: 'DetailDialog',
  components: { detailLayout },
  data() {
    return {
      loading: false,
      id: '',
      json: null,
      parentData: {},
    };
  },
  mounted() {
    globalConfig.list.push(this.open);
  },
  beforeDestroy() {
    globalConfig.list = globalConfig.list.filter((i) => i !== this.open);
  },
  methods: {
    open(action) {
      const params = action?.action_params || {};
      const { id, detail_dialog_json: json } = params;
      const ex = { ...params };
      delete ex.detail_dialog_json;
      this.parentData = ex;

      this.id = id;
      if (!json.render) json.render = {};
      json.render.type = json.render.type || 'dialog';
      this.json = json;
      this.loading = true;
      this.$nextTick(() => {
        this.$refs.detail.open();
      });
    },
  },
};
