//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Clipboard from '@/components/Clipboard';

export default {
  name: 'AsyncImage',
  components: {
    Clipboard,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: true,
      isImage: true,
      previewImg: {
        visible: false,
        src: '',
      },
    };
  },
  watch: {
    url() {
      this.isImageAsync();
    },
  },
  created() {
    this.isImageAsync();
  },
  methods: {
    imgPreview() {
      this.previewImg.src = this.url;
      this.previewImg.visible = true;
    },
    isImageAsync() {
      this.isLoading = true;
      const { url } = this;
      let image = new Image();
      const clearVar = () => {
        image.onload = null;
        image.onerror = null;
        image = null;
      };
      image.onload = () => {
        this.isLoading = false;
        this.isImage = true;
        clearVar();
      };
      image.onerror = () => {
        this.isLoading = false;
        this.isImage = false;
        clearVar();
      };
      image.src = url;
    },
  },
};
