import request from '@/libs/request';
import {
  queryQueryHospitalDepartment,
  queryQueryTitleList,
  queryGetLocationList,
  queryGetCityList,
  queryFindCityList,
  queryFindProvinceList,
  queryGetLocation,
  queryGetHospitalList,
  queryGetHospitalName,
} from '@/graphql/api/other';

export const unBindingDoctorApi = (data) =>
  request('api/doctorPortrait/manager/unBindingDoctor', data);
export const exportUnBindingDoctorApi = (data) =>
  request('api/doctorPortrait/manager/exportUnBindingDoctor', data);
// 获取医生列表
export const getDoctorListApi = (data) => request('GetDoctorList', data);
// 获取代表搜索列表
export const getRepresentativeListApi = (data) => request('ListRepresentative', data);
// 获取代表详情信息
export const getRepresentativeDetailInfoApi = (data) => request('GetRepresentativeDetail', data);
// 代表详情管理医生列表
export const ListRepresentativeDoctorApi = (data) => request('ListRepresentativeDoctor', data);

// 医生关系链
export const relationChainPagesApi = (data) => request('DoctorRelation', data);
// 医生参会详情
export const meetingInfoPagesApi = (data) => request('DoctorMeetingInfo', data);
// 医生阅读详情
export const readInfoPagesApi = (data) => request('DoctorReadingInfo', data);

// 获取医院列表
export const queryPagesApi = (data = {}) => queryGetHospitalList(data);
export const queryHospitalListApi = (data = {}) => queryGetHospitalList(data);
export const queryHospitalNameApi = (data = {}) => queryGetHospitalName(data);

// 获取区域列表
export const getAreaList = (data = {}) => request('GetAreaList', data);
export const GetLocationList = (data = {}) => queryGetLocationList(data);
export const GetCityList = (data = {}) => queryGetCityList(data);
export const FindCityList = (data = {}) => queryFindCityList(data);
export const FindProvinceList = (data = {}) => queryFindProvinceList(data);

export const GetLocationName = (data = {}) => queryGetLocation(data);
// 获取科室与职称
// 不要用下面这个QueryHospitalDepartment方法！不要盲目复制使用该接口的代码！
export const QueryHospitalDepartment = (url, data = {}) => request(url, data);
export const QueryTitleList = (data = {}) => queryQueryTitleList(data);
export const getHospitalDepartmentListApi = (data = {}) => queryQueryHospitalDepartment(data);
// 获取医生等级或性别
export const GetKvList = (data = {}) => request('GetKvList', data);
// 获取问卷信息
export const GetVoteDetail = (data = {}) => request('FaasGatewayServer/func/GetVoteDetail', data);
// 获取零售问卷信息
export const GetVisitVoteDetail = (data = {}) =>
  request('FaasGatewayServer/func/GetVisitVoteDetail', data);
// 获取审批时问卷填写信息，后端：“审批这里的问卷，要看到当时代表填写的答案和历史答案，上面的GetVoteDetail一直是拿最新的数据”
export const GetApprovalVoteDetail = (data = {}) =>
  request('FaasGatewayServer/func/GetApprovalVoteDetail', data);

export const GetVoteDetailForKee = (data = {}) =>
  request('FaasGatewayServer/func/GetVoteDetailForKee', data);

export const GetCanApproval = (data = {}) => request('FaasGatewayServer/func/KeeCanApprove', data);
