import filters from './filters';
import Cos from './cos/cos';
import localSetTrigger from './localSetTrigger';
import { directives } from './directive';
import mutationLayout from 'nges-common/src/web/components/layout/mutation';
import listLayout from '@/components/list-layout';
import DetailLayout from 'nges-common/src/web/components/layout/detail';
import TabLayout from 'nges-common/src/web/components/layout/tab';
import FullListLayout from 'nges-common/src/web/components/layout/full-list';
import DialogListLayout from 'nges-common/src/web/components/layout/dialog-list';
import DialogTabLayout from 'nges-common/src/web/components/layout/dialog-tab';
// 获取 @/components/ 下的组件
const rComponents = require.context('@/components/', true, /\.vue$/);
function setColumnWidths(Vue, el, vnode, resetWidths = false) {
  Vue.nextTick(() => {
    requestAnimationFrame(() => {
      const tableWrapper = el.querySelector('.el-table__header-wrapper');
      if (!tableWrapper) return;

      const headers = tableWrapper.querySelectorAll('th');
      const { columns } = vnode.componentInstance;
      headers.forEach((header, index) => {
        const column = columns[index];
        if (column?.label) {
          let minWidth = getMinWidth(header);
          if (!Object.prototype.hasOwnProperty.call(column, 'width') || resetWidths) {
            // 针对fix列单独处理，否则列宽计算会出错
            // console.log('column', resetWidths, column, column.label, column.minWidth, minWidth);
            if (column?.fixed) {
              minWidth = 250;
            }
            Vue.set(column, 'minWidth', Math.max(minWidth, column.minWidth));
          }
        }
      });
      // 延迟调用 doLayout
      setTimeout(() => {
        vnode.componentInstance.doLayout(); // 重新布局表格
      }, 0);
    });
  });
}
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
function getMinWidth(element) {
  // 获取图标等固定元素的宽度
  const icons = element.querySelectorAll('i');
  let iconsWidth = 0;
  icons.forEach((icon) => {
    iconsWidth += icon.offsetWidth;
  });
  // 获取表头内容的宽度
  const contentWidth = element.offsetWidth;
  // 获取元素的样式
  const style = getComputedStyle(element);
  const paddingLeft = parseFloat(style.paddingLeft);
  const paddingRight = parseFloat(style.paddingRight);
  const minWidth = parseFloat(style.minWidth);
  const columnWidth = Math.max(contentWidth, minWidth);
  // console.log('contentWidth: ', contentWidth, 'iconsWidth: ');
  // 返回图标宽度包含padding宽度和内容宽度的总和，作为最小宽度
  return Math.max(iconsWidth + paddingLeft + paddingRight, columnWidth);
}
export default {
  install(Vue) {
    Object.keys(filters).forEach((key) => {
      Vue.filter(key, filters[key]);
    });
    Object.keys(directives).forEach((key) => {
      Vue.directive(key, {
        // 当被绑定的元素插入到 DOM 中时……
        inserted: directives[key],
      });
    });
    localSetTrigger();
    Vue.prototype.$cos = Cos;
    Vue.component('mutation-layout', mutationLayout);
    Vue.component('list-layout', listLayout);
    Vue.component('nges-layout-detail', DetailLayout);
    Vue.component('nges-layout-tab', TabLayout);
    Vue.component('nges-layout-dialog-tab', DialogTabLayout);
    Vue.component('nges-layout-list', FullListLayout);
    Vue.component('nges-layout-dialog-list', DialogListLayout);
    Vue.component('nges-layout-mutation', mutationLayout);

    rComponents.keys().forEach((fileName) => {
      const depth = fileName?.split('/')?.length;
      if (depth && (depth === 2 || (depth === 3 && fileName?.split('/')[2] === 'index.vue'))) {
        // 仅支持 @/components/comp1.vue 或 @/components/comp2/index.vue 的格式
        // eslint-disable-next-line prettier/prettier
        const compName = fileName?.split('/')[1]?.split('.')?.[0]?.replace(/([A-Z])/g, '-$1');
        const lowerCaseCompName = compName?.slice(compName?.[0] === '-' ? 1 : 0)?.toLowerCase();
        if (lowerCaseCompName) {
          const comp = rComponents(fileName);
          Vue.component(`r-${lowerCaseCompName}`, comp.default || comp);
        }
      }
    });
    Vue.directive('fit-columns-width', {
      bind(el, binding, vnode) {
        const setWidths = debounce(
          (resetWidths = false) => setColumnWidths(Vue, el, vnode, resetWidths),
          400,
        );
        // 初始调用
        Vue.nextTick(() => {
          setTimeout(() => setWidths(false), 0);
        });
        // 绑定 resize 事件
        // let lastWidth = window.innerWidth;
        // const onResize = () => {
        //   // 当窗口宽度实际发生变化时才调用 setColumnWidths
        //   requestAnimationFrame(() => setWidths(true));
        //   if (window.innerWidth !== lastWidth) {
        //     lastWidth = window.innerWidth;
        //     requestAnimationFrame(() => setWidths(true));
        //   }
        // };
        const onResize = () => setWidths(true);
        window.addEventListener('resize', onResize);
        // 将函数存储在元素的 dataset 中，以便在 unbind 时移除监听器
        el.__setWidths__ = setWidths;
        el.__onResize__ = onResize;
      },
      componentUpdated(el, binding, vnode) {
        const setWidths = debounce(
          (resetWidths = false) => setColumnWidths(Vue, el, vnode, resetWidths),
          400,
        );
        Vue.nextTick(() => {
          setTimeout(() => setWidths(false), 0);
        });
      },
      unbind(el) {
        // 移除 resize 事件监听器
        window.removeEventListener('resize', el.__onResize__);
        delete el.__setWidths__;
        delete el.__onResize__;
      },
    });
  },
};
