//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cloneDeep } from 'lodash';
import { execExpressionDeep } from 'nges-common/src/layout/expression';
import { layoutRequest } from 'nges-common/src/layout/layoutRequest';
import MProgress from '../progress';

const _ = { cloneDeep };

export default {
  name: 'MiniDashboardCardChild',
  components: {
    MProgress,
  },
  props: {
    json: {
      type: Object,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => {},
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataSource: {},
      item: {},
    };
  },
  computed: {
    expParamsObj() {
      return {
        t: {
          ...this.dataSource,
        },
        p: this.params,
      };
    },
  },
  methods: {
    init() {
      this.json.ignore_id = true;
      if (this.json.cmd || this.json.object) {
        return layoutRequest(_.cloneDeep(this.json), this.expParamsObj)
          .then((value) => {
            if (this.json.object) {
              const data = {
                list: value.formattedValues,
                aggregate: value.aggregate,
              };
              this.dataSource = data;
              if (this.json.key) {
                return {
                  key: this.json.key,
                  value: data,
                };
              }
              return undefined;
            }
            this.dataSource = value.remoteValues;
            if (this.json.key) {
              return {
                key: this.json.key,
                value: value.remoteValues,
              };
            }
            return undefined;
          })
          .catch((e) => {
            console.log(e);
            return undefined;
          });
      }
      this.dataSource = {};
      return Promise.resolve(undefined);
    },
    render() {
      this.item = execExpressionDeep(_.cloneDeep(this.json.render), this.expParamsObj);
    },
    split(number) {
      if (typeof number !== 'number') return number;
      const str = number.toString();
      if (str.length < 4) return str;
      const first = str.length % 3;
      let result = str.slice(0, first);
      for (let i = first; i < str.length; i += 3) {
        if (i !== 0) result += ',';
        result += str.slice(i, i + 3);
      }
      return result;
    },
  },
};
