//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as echarts from 'echarts';
import * as config from './config.js';
import NoData from './components/no-data';
export default {
  name: 'Chart',
  components: { NoData },
  props: {
    type: {
      type: String,
      default: 'pie',
    },
    name: {
      type: String,
      default: '',
    },
    // 标题 (如: 文章阅读排行)
    title: {
      type: String,
      default: '',
    },
    // 标题单位补充 (如: 文章阅读排行（次）)
    titleUnit: {
      type: String,
      default: '',
    },
    // 悬浮弹窗提示文字
    tooltip: {
      type: String,
      default: '',
    },
    styleObj: {
      type: Object,
      default: () => {
        return { width: '100%', height: '345px' };
      },
    },
    dataList: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      // echart实例
      echartsInstance: undefined,
      noData: true,
    };
  },
  watch: {
    dataList: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },
  created() {
    this.stringInsert();
  },
  async mounted() {
    this.echartsInstance = await this.init();
    this.renderChart();
    this.resize();
  },
  //   beforeDestroy() {
  //     if (!this.echartsInstance) return;
  //     this.echartsInstance.dispose();
  //     this.echartsInstance = undefined;
  //   },
  methods: {
    // 初始化
    init() {
      //   echarts.registerTheme('walden', JSON.parse(styleJson));
      return Promise.resolve(echarts.init(this.$refs.myChart));
    },
    // 通过类型分别进行无数据判断
    judgeByType() {
      const dataList = JSON.parse(JSON.stringify(this.dataList));
      this.noData = true;
      switch (this.type) {
        case 'pie':
          // 格式类型: [{ name: '', value: '' }]
          dataList.forEach((e) => {
            console.log({ e });
            if (e.value) {
              console.log(e.value);
              this.noData = false;
            }
          });
          return;
        case 'bar':
          // 格式类型: { key: [], value: [] }
          if (dataList.value.length > 0) {
            this.noData = false;
          }
          return;
        default:
          return;
      }
    },
    // 绘制图表
    renderChart() {
      const instance = this.echartsInstance;
      if (!instance || Object.keys(instance).length === 0) return;
      instance.showLoading();
      const { option } = config[this.name]({
        data: this.dataList,
        tooltip: this.tooltip,
      });
      instance.setOption(option);
      this.judgeByType();
      if (this.tooltip) this.onAxiasTooltip();
      instance.hideLoading();
    },
    // 坐标系提示框
    onAxiasTooltip() {
      const refNode = this.$refs.axiasTooltip;
      this.echartsInstance.on('mouseover', function (params) {
        if (params.componentType === 'yAxis') {
          refNode.innerHTML = params.value;
          refNode.setAttribute(
            'style',
            `left:${params.event.event.layerX + 0}px;top:${
              params.event.event.layerY + 20
            }px;display:inline-block`,
          );
        }
      });
      this.echartsInstance.on('mouseout', function () {
        refNode.setAttribute('style', `display:none`);
      });
    },
    // 响应屏幕重绘canvas
    resize() {
      window.addEventListener('resize', () => {
        this.echartsInstance.resize();
      });
    },
    // 原型注入
    stringInsert() {
      // 计算字节
      String.prototype.byteLength = function () {
        let length = 0;
        Array.from(this).map((char) => {
          // 字符编码大于255是双字节字符
          if (char.charCodeAt(0) > 255) {
            length += 2;
          } else {
            length += 1;
          }
        });
        return length;
      };
      // 计算字体宽度
      String.prototype.pxWidth = function (font) {
        const canvas =
          String.prototype.pxWidth.canvas ||
          (String.prototype.pxWidth.canvas = document.createElement('canvas'));
        const context = canvas.getContext('2d');
        font && (context.font = font);
        const metrics = context.measureText(this);
        return metrics.width;
      };
    },
  },
};
