import { getPageParams, toThousands } from '../../web-mobile/util';
import { includes, round, trim, get, set, join, concat, merge, random, split } from 'lodash';
import dayjs from 'dayjs';

const SysCmds = {
  // 系统方法

  GET() {
    const args = Array.prototype.slice.call(arguments);
    if (args.length === 1) {
      return args[0];
    }
    return SysCmds.GET_OBJ.apply(this, args);
  },

  SET() {
    const args = Array.prototype.slice.call(arguments);
    if (args.length === 2) {
      SysCmds.SET_SCOPE.apply(this, args);
    }
    if (args.length === 3) {
      SysCmds.SET_OBJ.apply(this, args);
    }
    return undefined;
  },

  SET_SCOPE(key, value) {
    return set(this, key, value);
  },

  // 逻辑类
  IF(cond, a, b) {
    return cond ? a : b;
  },

  IFS() {
    const args = Array.prototype.slice.call(arguments);
    const len = args.length;
    if (len < 3 || len % 2 === 0) {
      throw new Error('执行指令错误');
    }
    for (let i = 0; i < len - 1; i += 2) {
      if (args[i]) {
        return args[i + 1];
      }
    }
    return args[len - 1];
  },

  AND() {
    const args = Array.prototype.slice.call(arguments);
    return (args || []).reduce((r, v) => {
      return r && v;
    });
  },

  OR() {
    const args = Array.prototype.slice.call(arguments);
    return (args || []).reduce((r, v) => {
      return r || v;
    });
  },

  NOT(a) {
    return !a;
  },

  // 类型转换类
  NUM(v) {
    return Number(v);
  },

  BOOLEAN(v) {
    return Boolean(v);
  },

  STRING(v) {
    return String(v);
  },

  // 条件计算类
  EQ(a, b) {
    return a === b;
  },

  LO_EQ(a, b) {
    return a == b;
  },

  LO_NEQ(a, b) {
    return a != b;
  },

  NEQ(a, b) {
    return a !== b;
  },

  GT(a, b) {
    return a > b;
  },

  GTE(a, b) {
    return a >= b;
  },

  LT(a, b) {
    return a < b;
  },

  LTE(a, b) {
    return a <= b;
  },

  IN(a, b) {
    return includes(a, b);
  },

  NOT_IN(a, b) {
    return !includes(a, b);
  },

  IS_EMPTY(a) {
    return !a;
  },

  IS_NAN(a) {
    return isNaN(+a);
  },

  // 数字计算类
  ADD() {
    const args = Array.prototype.slice.call(arguments);
    return (args || []).reduce((r, v) => {
      return r + v;
    });
  },

  SUB() {
    const args = Array.prototype.slice.call(arguments);
    return (args || []).reduce((r, v, i) => {
      return i === 0 ? r + v : r - v;
    });
  },

  MULTIPLY() {
    const args = Array.prototype.slice.call(arguments);
    return (args || []).reduce((r, v) => {
      return r * v;
    });
  },

  DIVIDE() {
    const args = Array.prototype.slice.call(arguments);
    return (args || []).reduce((r, v, i) => {
      return i === 0 ? r + v : r / v;
    });
  },

  ROUND(a, b) {
    return round(a, b);
  },

  AVG() {
    return SysCmds.ADD.apply(this, arguments) / arguments.length;
  },

  RANDOM(lower, upper, floating) {
    return random(lower, upper, floating);
  },

  MOD(a, b) {
    return a % b;
  },

  // JSON操作
  JSON_STR(v) {
    return JSON.stringify(v);
  },

  JSON_PARSE(v) {
    try {
      return JSON.parse(v);
    } catch (e) {}
  },

  ENCODE_URI_COMPONENT(uri) {
    return encodeURIComponent(uri);
  },

  DECODE_URI_COMPONENT(uri) {
    return decodeURIComponent(uri);
  },

  // 字符串
  TMPL() {
    const args = Array.prototype.slice.call(arguments);
    let str = args[0];
    for (let i = 1; i < args.length; i++) {
      str = str.replace(`\${${i - 1}}`, args[i]);
    }
    return str;
  },

  TRIM(str) {
    return trim(str);
  },

  SUB_STR(str, start, len) {
    return str.substr(start, len);
  },

  LEN(str) {
    if (!str) {
      return 0;
    }
    return str.length;
  },

  // 对象
  GET_OBJ(obj, path) {
    return get(obj, path);
  },

  SET_OBJ(obj, path, value) {
    return set(obj, path, value);
  },

  MERGE(a, b) {
    return merge(a, b);
  },

  // 数组
  JOIN(arr, separator) {
    return join(arr, separator);
  },
  SPLIT(str, separator = ',') {
    return split(str, separator);
  },

  CONCAT() {
    const args = Array.prototype.slice.call(arguments);
    return concat.apply(this, args);
  },

  // 时间
  NOW() {
    return new Date();
  },

  TIMESTAMP(type, t) {
    const ts = (t ? new Date(t) : new Date()).getTime();
    return type === 's' ? parseInt(ts / 1000) : ts;
  },
  ADD_DATETIME(time, offset, type) {
    let newTime = time || new Date();
    newTime = dayjs(newTime).startOf(type);
    return dayjs(newTime).add(offset, type).valueOf();
  },

  SUBTRACT_DATETIME(time, offset, type) {
    let newTime = time || new Date();
    newTime = dayjs(newTime).startOf(type);
    return dayjs(newTime).add(-offset, type).valueOf();
  },

  DIFF_DATETIME(time1, time2, type = 'd') {
    return dayjs(time1 || new Date()).diff(time2 || new Date(), type);
  },

  DATE(t) {
    return new Date(t);
  },

  DATE_GET(unit, time = new Date()) {
    const ret = dayjs(time).get(unit);
    return ret;
  },

  DATE_SET(unit, value, time = new Date()) {
    const ret = dayjs(time).set(unit, value).valueOf();
    return ret;
  },

  INCLUDES(arr, target) {
    if (Array.isArray(arr)) {
      return arr.includes(target);
    }
    return false;
  },

  PARSE_INT(...params) {
    return parseInt(...params);
  },

  GET_PAGE_PARAMS() {
    return getPageParams();
  },

  // 金额
  TO_LOCAL_STRING(num) {
    // same from util
    return toThousands(num);
  },
};

export default SysCmds;
