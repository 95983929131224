import request from '@/libs/request';
import { queryDepartment, queryUserOrDepartment } from '@/graphql/api/addressList';
import { queryAllUserCount } from '@/graphql/api/user';

export const DownloadWeComUserListByDepartmentApi = (data) =>
  request('DownloadWeComUserListByDepartment', data);
export const UpdateWeComUserStatusApi = (data) => request('UpdateWeComUserStatus', data);
export const SearchWeComUserListApi = (data) => request('SearchWeComUserList', data);
export const GetWeComAllUsers = (data) => queryAllUserCount(data);
export const GetWeComDepartmentListApi = (data) => request('GetWeComDepartmentList', data);
export const GetWeComDepartmentById = (data) => queryDepartment(data);
export const GetWeComUserByName = (data) => queryUserOrDepartment(data);
export const GetWeComDetailNameById = (data) => request('GetWeComDetailNameById', data);
export const GetWeComUserDetail = (data) => request('GetWeComUserDetail', data);
export const GetWeComDepartmentUserListApi = (data) => request('GetWeComDepartmentUserList', data);
export const GetWeComUserDetailApi = (data) => request('GetWeComUserDetail', data);
// 通讯录导入文件校验
export const StaffImportFileCheck = (data) => request('StaffImportFileCheck', data);
// 通讯录导入记录查询
export const ListImportStaffRecord = (data) => request('ListImportStaffRecord', data);
// 通讯录导入记录详情
export const GetImportStaffDetail = (data) => request('GetImportStaffDetail', data);
// 通讯录导入
export const ImportStaff = (data) => request('ImportStaff', data);
// 模板下载
export const GetStaffImportExcelStencil = (data) => request('GetStaffImportExcelStencil', data);
export const GetStaffExtendField = (data) => request('GetStaffExtendField', data);
// 查询导入状态
export const GetImportStaffRecord = (data) => request('GetImportStaffRecord', data);

/**
 * 标签接口
 */
// 创建标签
export const CreateContactTag = (data) => request('CreateContactTag', data);
// 更新标签名字
export const UpdateContactTag = (data) => request('UpdateContactTag', data);
// 删除标签
export const DeleteContactTag = (data) => request('DeleteContactTag', data);
// 获取标签成员
export const GetContactTagUserList = (data) => request('GetContactTagUserList', data);
// 增加标签成员
export const AddContactTagUsers = (data) => request('AddContactTagUsers', data);
// 删除标签成员
export const DelContactTagUsers = (data) => request('DelContactTagUsers', data);
// 获取标签列表
export const GetContactTagList = (data) => request('GetContactTagList', data);
// 获取标签导入记录
export const GetImportTagRecordList = (data) => request('GetImportTagRecordList', data);
// 校验导入标签文件GetImportTagRecord
export const CheckTagImportFile = (data) => request('CheckTagImportFile', data);
// 查询导入具体信息
export const GetImportTagRecordById = (data) => request('GetImportTagRecordById', data);
// 导入标签
export const ImportTag = (data) => request('ImportTag', data);
