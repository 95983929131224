const routerFiles = require.context('.', true, /index\.js$/);
let routers = [];
const transRouter = function (arr, name) {
  if (!arr?.length) return [];
  return arr.map((item) => {
    const path = item.path.split('/');
    path.splice(2, 0, name);
    return {
      ...item,
      name: `${name}_${item.name}`,
      path: path.join('/'),
      children: item.children?.length > 0 ? transRouter(item.children, name) : [],
    };
  });
};
routerFiles.keys().forEach((fileName) => {
  if (fileName.includes('/router/')) {
    const router = require(`${fileName}`);
    const routerName = fileName.slice(2).split('/')[0];
    const routerDefault = router.default;
    routers = [...routers, ...transRouter(routerDefault, routerName)];
  }
});
console.log(routers);
export default routers;
