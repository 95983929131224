//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CountNumberInput',
  props: {
    propsValue: {
      type: Number,
      default: 0,
    },
    canMaxNum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      value: this.propsValue,
      disabledSubtract: true,
      disabledAdd: false,
    };
  },
  watch: {
    value(value) {
      this.$emit('emitInput', value);
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.value = this.propsValue;
      if (this.propsValue > 0) {
        this.disabledSubtract = false;
      }
      if (this.propsValue == this.canMaxNum) {
        this.disabledAdd = true;
      }
    },
    inputMe(e) {
      this.value = e.target.value;
    },
    up() {
      if (this.value == this.canMaxNum - 1) {
        this.disabledAdd = true;
      }
      if (this.value == 0) {
        this.disabledSubtract = false;
      }
      if (this.value == this.canMaxNum) {
        return false;
      }
      this.value += 1;
    },
    down() {
      if (this.value > 0) {
        if (this.value == 1) {
          this.disabledSubtract = true;
        }
        if (this.value == this.canMaxNum - 1) {
          this.disabledAdd = false;
        }
        this.value -= 1;
      }
    },
  },
};
