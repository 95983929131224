//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MixinTab from '../mixin-tab';
import Detail from './components/detail';

export default {
  name: 'LayoutDetail',
  components: {
    MixinTab,
    Detail,
  },
  // 接收来自父详情布局provide的数据，由父详情布局传来的数据，共同决定当前详情布局是否用tab打开
  inject: {
    inDetailTabLayout: {
      default: false,
    },
    inLayoutMixinTab: {
      default: false,
    },
  },
  // 将详情布局是否用tab打开透传到子布局中
  provide() {
    return {
      inDetailTabLayout: this.enableTab, // 旧逻辑兼容
    };
  },
  props: {
    json: {
      type: Object,
      default() {
        return null;
      },
    },
    layout: {
      type: String,
      default: '',
    },
    targetId: {
      type: String,
      default() {
        return '';
      },
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    parentData: {
      type: Object,
      default() {
        return {};
      },
    },
    disabledTab: {
      type: Boolean,
      default: false,
    },
    btnLoading: {
      type: Boolean,
      default: false,
    },
    hideLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabTitle: '',
    };
  },
  computed: {
    enableTab() {
      return (
        !this.inDetailTabLayout
        && this.json?.render?.type !== 'drawer'
        && this.json?.render?.type !== 'dialog'
        && !this.disabledTab
      );
    },
    // enableTab的情况下，是否强制展示成tab展示
    showTabs() {
      return this.json?.render?.show_tabs || false;
    },
  },
  methods: {
    reload() {
      this.$refs?.detail.reload();
      if (this.enableTab) {
        this.$refs?.tab?.reload();
      }
    },
    setTitle(title) {
      this.tabTitle = title;
    },
    actionHandle(action) {
      if (this.$refs.tab?.m_actionHandle) {
        this.$refs.tab.m_actionHandle(action);
      } else {
        this.$emit('action-handle', action);
      }
    },
    actionHandleEmit(action) {
      this.$emit('action-handle', action);
    },
    open() {
      this.$refs.detail.open();
    },
    close() {
      this.$refs.detail.visible = false;
    },
    handleFetchData(value) {
      console.log('handleFetchData2', value);
    },
    handleSetValidate(obj) {
      if (this.$refs.detail) {
        if (obj?.queryTipsConf?.length) {
          this.$refs.detail.validateObj = obj.validateObj;
        }
      }
    },
  },
};
