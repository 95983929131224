// import { getMeetingTypeListApi, getProductListApi } from '@/api/dataCenter.js';
import {
  GetMeetingProvince,
  GetMeetingProduct,
  GetMeetingBu,
  GetMeetingConfig,
  getKvConfigs,
} from '@/graphql/api/meeting';
import { GetObjectsByNames } from '@/api/restful';
import { Message } from 'element-ui';
export default {
  namespaced: true,
  state: {
    meetingTypeOptions: [], // 会议类型下拉列表
    productList: [], // 会议产品列表
    typeOptions: [], // 会议形式列表
    meetingStatusList: [], // 会议状态列表
    buList: [],
    provinceList: [], // 省数据
    meetingConfig: {}, // 会议产品配置
    hcpGrade: [], // 医生等级 {label:string, value: string}[]
    behaviorTypeList: [], // 用户行为列表
    userIdentity: [], // 用户身份
    sourceList: [], // 查看渠道
  },
  mutations: {
    setMeetingTypeOptions(state, payload) {
      state.meetingTypeOptions = payload;
    },
    setMeetingStatusOptions(state, payload) {
      state.meetingStatusList = payload;
    },
    setTypeOptions(state, payload) {
      state.typeOptions = payload;
    },
    setProductList(state, payload) {
      state.productList = payload;
    },
    setBuList(state, payload) {
      state.buList = payload;
    },
    setProvinceList(state, payload) {
      state.provinceList = payload;
    },
    setMeetingConfig(state, payload) {
      state.meetingConfig = payload[0] || {};
    },
    setHcpGrade(state, payload) {
      state.hcpGrade = payload;
    },
    setBehaviorTypeList(state, payload) {
      state.behaviorTypeList = payload;
    },
    setUserIdentity(state, payload) {
      state.userIdentity = payload;
    },
    setSourceList(state, payload) {
      state.sourceList = payload;
    },
  },
  actions: {
    // 获取医生等级列表
    async getHcpGrade({ state, commit }) {
      if (state.hcpGrade.length) return;
      GetObjectsByNames({ objects: ['hcp'] }).then((res) => {
        res.list?.shift()?.fields.forEach((item) => {
          if (item.name === 'hcp_grade') {
            commit('setHcpGrade', item.select_one_option.options);
          }
        });
      });
    },
    async getBehaviorInfo({ commit }) {
      GetObjectsByNames({ objects: ['user_behavior_details', 'mcm_vote_record'] }).then((res) => {
        res.list?.shift()?.fields.forEach((item) => {
          if (item.name === 'behavior_type') {
            commit('setBehaviorTypeList', item.select_one_option.options);
          } else if (item.name === 'source') {
            commit('setSourceList', item.select_one_option.options);
          } else if (item.name === 'user_identity') {
            commit('setUserIdentity', item.select_one_option.options);
          }
        });
        res.list?.shift()?.fields.forEach((item) => {
          if (item.name === 'vote_role') {
            // no option
          }
        });
      });
    },
    // 获取会议类型、会议状态列表
    async getMeetingInfo({ state, commit }) {
      if (state.meetingTypeOptions.length === 0) {
        GetObjectsByNames({ objects: ['events_meeting'] })
          .then((res) => {
            let options;
            res.list?.shift()?.fields.forEach((item) => {
              if (item.name === 'meeting_type_id') {
                options = item.select_one_option.options;
                commit(
                  'setMeetingTypeOptions',
                  options.map((item) => ({
                    label: item.label,
                    value: item.value,
                  })),
                );
              } else if (item.name === 'meeting_status') {
                options = item.select_one_option.options;
                commit(
                  'setMeetingStatusOptions',
                  options.map((item) => ({
                    label: item.label,
                    value: item.value,
                  })),
                );
              }
              if (item.name === 'type') {
                options = item.select_one_int_option.options;
                commit(
                  'setTypeOptions',
                  options.map((item) => ({
                    label: item.label,
                    value: item.value,
                  })),
                );
              }
            });
          })
          .catch((error) => {
            Message.error(error.message);
          });
      }
    },
    // 获取产品标签
    async getProductList({ state, commit }) {
      await GetMeetingProduct()
        .then((res) => {
          if (!res.error) {
            const product =
              state.meetingConfig.product_show_type === '0'
                ? JSON.parse(res.data).product
                : JSON.parse(res.data).product_tag;
            // console.log('getProductList', product);
            commit(
              'setProductList',
              product.map((item) => ({
                label: item.name,
                value: item.id,
              })),
            );
          }
        })
        .catch((error) => {
          console.log(error);
          Message.error(error.message);
        });
    },
    // 获取BU选项
    async getBuList({ commit }) {
      await GetMeetingBu()
        .then((res) => {
          if (!res.error) {
            const bu = JSON.parse(res.data).bu_tag;
            // console.log('getProductList', product);
            commit(
              'setBuList',
              bu.map((item) => ({
                label: item.name,
                value: item.id,
              })),
            );
          }
        })
        .catch((error) => {
          Message.error(error.message);
        });
    },
    // 获取省数据
    async getProvinceData({ state, commit }) {
      if (state.provinceList.length === 0) {
        await GetMeetingProvince()
          .then((res) => {
            if (!res.error) {
              const { province } = JSON.parse(res.data);
              console.log('getProvinceData', province);
              commit(
                'setProvinceList',
                province.map((item) => ({
                  label: item.name,
                  value: item.code,
                })),
              );
            }
          })
          .catch((error) => {
            Message.error(error.message);
          });
      }
    },
    async getMeetingConfig({ state, commit }) {
      try {
        if (Object.keys(state.meetingConfig).length === 0) {
          const res1 = await GetMeetingConfig();
          const { events_meeting_config: config = {} } = res1;
          const res2 = await getKvConfigs({
            keys: ['product_show_type'],
          });
          config[0].product_show_type = res2.kv[0].value;
          commit('setMeetingConfig', config);
        }
      } catch (error) {
        Message.error(error.message);
      }
    },
  },
};
