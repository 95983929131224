//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import ngesDialog from 'nges-common/src/web/components/layout/dialog';
import { globalSharePopup } from './util';
import {
  DataService,
  GetShareRule,
  UpdateShareRule,
  GetGroupList,
} from 'nges-common/src/web/utils/api';
import { execExpression } from 'nges-common/src/layout/expression';

export default {
  name: 'ShareDataPopup',
  components: { ngesDialog },
  data() {
    return {
      params: {},
      visible: false,
      tab: '1',
      keyword: '',
      userList: [],
      organizationList: [],
      groupList: [],
      territoryList: [],
      selected: [],
      selectedData: [],
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    list: {
      get() {
        if (this.tab === '1') {
          return this.userList;
        }
        if (this.tab === '2') {
          return this.organizationList;
        }
        if (this.tab === '3') {
          return this.groupList;
        }
        if (this.tab === '4') {
          return this.territoryList;
        }
        return [];
      },
      set() {},
    },
  },
  watch: {
    tab() {
      this.currentPage = 1;
      this.total = 0;
      this.keyword = '';
      this.noDebounceSearch();
    },
  },
  mounted() {
    globalSharePopup.open = this.open;
  },
  methods: {
    search: _.debounce(function () {
      this.currentPage = 1;
      this.noDebounceSearch();
    }, 1000),
    noDebounceSearch() {
      if (this.tab === '1') {
        this.getUserList();
      } else if (this.tab === '2') {
        this.getOrganizationList();
      } else if (this.tab === '3') {
        this.getGroupList();
      } else if (this.tab === '4') {
        this.getTerritoryList();
      }
    },
    getUserList() {
      DataService({
        query: `query{user_info(_limit:10,_offset:${(this.currentPage - 1) * 10},name:{_like:"%${
          this.keyword
        }%"}){_aggregate{_count}${this.params.fields.user}}}`,
      }).then((res) => {
        this.userList = (res.user_info?._data || []).map((i) => {
          return {
            ...i,
            uuid: this.generateUuid('user', i.uin),
          };
        });
        this.total = res.user_info?._aggregate.count;
      });
    },
    getOrganizationList() {
      DataService({
        query: `query{organization(name:{_like:"%${this.keyword}%"}){${this.params.fields.organization}}}`,
      }).then((res) => {
        this.organizationList = (res.organization || []).map((i) => {
          return {
            ...i,
            uuid: this.generateUuid('org', i.code),
          };
        });
      });
    },
    getGroupList() {
      GetGroupList({
        keyword: this.keyword,
        page_size: 999999,
        page: 1,
      }).then((res) => {
        this.groupList = res.data.map((i) => {
          return {
            ...i,
            uuid: this.generateUuid('group', i.code),
          };
        });
      });
    },
    getTerritoryList() {
      DataService({
        query: `query{territory(_limit:10,_offset:${(this.currentPage - 1) * 10},name:{_like:"%${
          this.keyword
        }%"}){_aggregate{_count}${this.params.fields.territory}}}`,
      }).then((res) => {
        this.territoryList = (res.territory?._data || []).map((i) => {
          return {
            ...i,
            uuid: this.generateUuid('territory', i.code),
          };
        });
        this.total = res.territory?._aggregate.count;
      });
    },

    getSelectedUserList() {
      const uinStr = this.selected
        .filter((str) => str.startsWith('$user$'))
        .map((str) => str.replace('$user$', ''))
        .map((i) => `"${i}"`);
      if (uinStr.length) {
        return DataService({
          query: `query{user_info(uin:{_in:[${uinStr}]}){${this.params.fields.user}}}`,
        }).then((res) => {
          this.userList = res.user_info.map((i) => {
            return {
              ...i,
              uuid: this.generateUuid('user', i.uin),
            };
          });
        });
      }
      return this.getUserList();
    },
    getSelectedOrganizationList() {
      const code = this.selected
        .filter((str) => str.startsWith('$org$'))
        .map((str) => str.replace('$org$', ''))
        .map((i) => `"${i}"`);
      if (code.length) {
        return DataService({
          query: `query{organization(code:{_in:[${code}]}){${this.params.fields.organization}}}`,
        }).then((res) => {
          this.organizationList = res.organization.map((i) => {
            return {
              ...i,
              uuid: this.generateUuid('org', i.code),
            };
          });
        });
      }
      return this.getOrganizationList();
    },
    getSelectedGroupList() {
      const code = this.selected
        .filter((str) => str.startsWith('$group$'))
        .map((str) => str.replace('$group$', ''));
      GetGroupList({
        codes: code,
      }).then((res) => {
        this.groupList = res.data.map((i) => {
          return {
            ...i,
            uuid: this.generateUuid('group', i.code),
          };
        });
      });
    },
    getSelectedTerritoryList() {
      const code = this.selected
        .filter((str) => str.startsWith('$territory$'))
        .map((str) => str.replace('$territory$', ''))
        .map((i) => `"${i}"`);
      if (code.length) {
        return DataService({
          query: `query{territory(code:{_in:[${code}]}){${this.params.fields.territory}}}`,
        }).then((res) => {
          this.territoryList = res.territory.map((i) => {
            return {
              ...i,
              uuid: this.generateUuid('territory', i.code),
            };
          });
        });
      }
      return this.getTerritoryList();
    },

    async getRuleList() {
      const res = await GetShareRule({
        object_name: this.params.object_name,
        record_id: this.params.id,
      });
      const ruleList = res?.rules || [];
      if (ruleList.length) {
        this.selected = ruleList.map((i) => {
          return this.generateUuid(i.target_type, i.target_value);
        });

        await Promise.all([
          this.getUserList(),
          this.getOrganizationList(),
          this.getGroupList(),
          this.getTerritoryList(),
        ]);
        this.selectedData = ruleList.map((i) => {
          let item = {};
          if (i.target_type === 'user') {
            item = this.userList.find((j) => {
              return i.target_value === j.uin;
            });
          }
          if (i.target_type === 'org') {
            item = this.organizationList.find((j) => {
              return i.target_value === j.code;
            });
          }
          if (i.target_type === 'group') {
            console.log('---i---', i);
            item = this.groupList.find((j) => {
              return i.target_value === j.code;
            });
          }
          if (i.target_type === 'territory') {
            console.log('---i---', i);
            item = this.territoryList.find((j) => {
              return i.target_value === j.code;
            });
          }
          console.log('------item------', item);
          return {
            ...i,
            ...item,
            status: i.access_level,
            uuid: this.generateUuid(i.target_type, i.target_value),
          };
        });
      } else {
        this.getUserList();
      }
    },
    open(action) {
      this.params = action.action_params || {};
      this.tab = '1';
      this.keyword = '';
      this.list = [];
      this.selected = [];
      this.selectedData = [];
      this.visible = true;
      this.getRuleList();
    },
    close() {
      this.visible = false;
    },
    save() {
      console.log('meowmeow', this.selectedData);
      const entries = this.selectedData.map((i) => {
        return {
          target_type: i.target_type,
          target_value: i.target_value,
          access_level: i.status,
        };
      });
      console.log('----entries----', entries);
      UpdateShareRule({
        object_name: this.params.object_name,
        record_id: this.params.id,
        entries,
      }).then(() => {
        this.$message.success('保存成功');
        this.close();
      });
    },
    resetData() {
      this.selected = [];
      this.selectedData = [];
    },
    deleteItem(row) {
      const { uuid } = row;
      this.selected = this.selected.filter((i) => i !== uuid);
      this.selectedData = this.selectedData.filter((i) => i.uuid !== uuid);
    },
    getItemText(item) {
      if (this.tab === '1')
        return execExpression(`{{${this.params.format_value.user}}}`, { t: item });
      if (this.tab === '2')
        return execExpression(`{{${this.params.format_value.organization}}}`, { t: item });
      if (this.tab === '3')
        return execExpression(`{{${this.params.format_value.group}}}`, { t: item });
      if (this.tab === '4')
        return execExpression(`{{${this.params.format_value.territory}}}`, { t: item });
      return '';
    },
    getTableItemText(item) {
      if (item.target_type === 'user')
        return execExpression(`{{${this.params.format_value.user}}}`, { t: item });
      if (item.target_type === 'org')
        return execExpression(`{{${this.params.format_value.organization}}}`, { t: item });
      if (item.target_type === 'group')
        return execExpression(`{{${this.params.format_value.group}}}`, { t: item });
      if (item.target_type === 'territory')
        return execExpression(`{{${this.params.format_value.territory}}}`, { t: item });
      return '';
    },
    updateData(item) {
      const idx = this.selectedData.findIndex((i) => {
        return i.uuid === item.uuid;
      });
      if (idx === -1) {
        this.selectedData.push({
          ...item,
          target_type: this.determineType(item.uuid),
          target_value: this.extractValue(item.uuid),
          status: typeof item.status === 'undefined' ? 'read' : item.status,
        });
      } else {
        this.selectedData.splice(idx, 1);
      }
    },
    setStatus({ uuid }, status) {
      const idx = this.selectedData.findIndex((i) => i.uuid === uuid);
      if (idx !== -1) this.selectedData[idx].status = status;
    },
    generateUuid(type, value) {
      return `$${type}$${value}`;
    },
    determineType(input) {
      if (input.startsWith('$user$')) {
        return 'user';
      }
      if (input.startsWith('$org$')) {
        return 'org';
      }
      if (input.startsWith('$group$')) {
        return 'group';
      }
      if (input.startsWith('$territory$')) {
        return 'territory';
      }
      return 'unknown';
    },
    targetValueToText(targetValue) {
      if (targetValue === 'user') return '用户';
      if (targetValue === 'org') return '员工组织';
      if (targetValue === 'group') return '公共用户组';
      if (targetValue === 'territory') return '岗位';
      return '';
    },
    extractValue(input) {
      // 使用 $ 分割字符串，并获取最后一部分
      const parts = input.split('$');
      return parts[parts.length - 1];
    },
    handleCurrentChange(i) {
      this.currentPage = i;
      this.noDebounceSearch();
    },
  },
};
