//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { McmAdminService } from '@/graphql/api/list';
import _ from 'lodash';

export default {
  name: 'Select',
  props: {
    filterObj: {
      type: Object,
      default: () => {},
    },
    formValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      comfilterObj: '',
      optionsArr: [],
      allSelectStatus: false,
      page: 1,
      size: 30,
      filterVal: '',
    };
  },
  computed: {
    filter() {
      const { filter = {} } = this.comfilterObj || {};
      return filter;
    },
    valueType() {
      return this.filter.value_type;
    },
    options() {
      if (!this.filterVal) {
        const totalOptions = this.filter.options || [];
        const options = totalOptions.slice(0, this.page * this.size);
        const index = options.findIndex((i) => i.value === this.getValue());
        if (index < 0) {
          const valueObj = totalOptions.find((i) => i.value === this.getValue());
          valueObj && options.unshift(valueObj);
        }
        return options;
      }
      return this.filterOptions.slice(0, this.page * this.size);
    },
    filterOptions() {
      if (this.filterVal) {
        const totalOptions = this.filter.options || [];
        const filterOptions = totalOptions.filter((item) => {
          return item.label.toLowerCase().includes(this.filterVal.toLowerCase());
        });
        return filterOptions;
      }
      return [];
    },
    optionType() {
      return this.filter.option_type;
    },
    isMultiple() {
      return this.valueType === 'SELECT_MANY';
    },
    valueIsString() {
      return typeof this.formValue[this.filterObj.prop] === 'string';
    },
    selectStyle() {
      return this.filter.style;
    },
    disabled() {
      return this.filterObj?.filter?.jsonFilter?.disabled;
    },
  },
  watch: {
    filterObj(val) {
      this.comfilterObj = val;
      this.initOptions();
    },
  },
  created() {
    this.comfilterObj = this.filterObj;
    this.initOptions();
  },
  beforeDestroy() {
    this.comfilterObj = '';
  },
  methods: {
    getValue() {
      const { prop } = this.filterObj;
      const value =
        this.isMultiple && this.valueIsString
          ? this.formValue[prop].split(',')
          : this.formValue[prop];
      return value;
    },
    initOptions() {
      const { isLoadRemoteOptions = false, object } = this.filter;
      if (object && !isLoadRemoteOptions) {
        this.getRemoteOptions(this.filter);
      }
    },
    async getRemoteOptions(filter = {}) {
      try {
        const { object, label, value, options } = filter;
        if (object && label) {
          this.loading = true;
          const res = await McmAdminService({
            query: `query{ ${object}(_distinct:true){${label},${value || ''}}}`,
          });
          const data = res && res[object];
          if (data?.length) {
            const objectOptions = [];
            data.forEach((item) => {
              objectOptions.push({
                label: item[label],
                value: item[value],
              });
              this.optionsArr.push(item[value]);
            });
            filter.isLoadRemoteOptions = true;
            filter.options = [...options, ...objectOptions];
            this.comfilterObj = {
              ...this.comfilterObj,
              filter,
            };
          }
          this.loading = false;
        } else {
          console.warn('object and label both need not empty!');
        }
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
    handleChange(e) {
      const { prop } = this.comfilterObj;
      this.allSelectStatus = e.length === this.optionsArr.length;
      this.$emit('change', { prop, value: e });
    },
    selectAll() {
      this.allSelectStatus = !this.allSelectStatus;
      const { prop } = this.comfilterObj;
      const value = this.allSelectStatus ? this.optionsArr : [];
      this.$emit('change', { prop, value });
    },
    load() {
      const pageTatalSize = this.page * this.size;
      if (!this.filterVal) {
        const total = this.filter.options.length;
        const noMoreWhenNoFilter = total > pageTatalSize;
        if (noMoreWhenNoFilter) {
          this.page += 1;
          console.log('pageTatalSize', pageTatalSize);
        }
      } else {
        const filterOptionsSize = this.filterOptions.length;
        const noMoreWhenHadFilter = filterOptionsSize > pageTatalSize;
        if (noMoreWhenHadFilter) {
          this.page += 1;
          console.log('pageTatalSize', pageTatalSize);
        }
      }
    },
    // 筛选方法
    filterMethod: _.debounce(function (filterVal) {
      this.filterVal = filterVal;
    }, 500),
    // 下拉框出现时，调用过滤方法
    visibleChange(flag) {
      if (flag) {
        this.filterMethod();
      }
    },
  },
};
