//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GetAllProductList } from '@/graphql/api/other';
import { queryTagCategories, queryCategoryTags } from '@/graphql/api/tag';
export default {
  name: 'MaterialFilter',
  props: {
    materialFilterConfig: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 关联产品选项
      productList: [],

      filterVals: {
        // 素材名称筛选输入值
        name: '',
        // 选择文章发布公众号结果
        publicVals: [],
        // 选择产品值
        productVals: [],
        tagVals: [],
        // item通用样式
        itemStyle: 'item',
      },
      // 节流使用
      timer: null,
      props: { multiple: true, expandTrigger: 'hover' },
      options: [],
      selectedTags: [],
      loading: false,
    };
  },
  computed: {
    // 获取文章公众号列表
    publicList() {
      return this.$store.getters['user/getPublishList'];
    },
    // item通用样式
    itemStyle() {
      const paramConfig = this?.materialFilterConfig || {};
      if (Object.keys(paramConfig)?.length === 0) {
        // 默认按照3个处理展示布局
        return { 'flex-basis': '30%', 'margin-right': '8px' };
      }
      let trueLen = 0;
      Object.values(paramConfig).forEach((item) => {
        trueLen += +item;
      });
      // 根据配置传入true值数量动态分配布局
      return { 'flex-basis': `${100 / trueLen - 1}%`, 'margin-right': '8px' };
    },
  },
  mounted() {
    this.getProductList();
    this.initTagSelect();
  },
  methods: {
    async initTagSelect() {
      this.loading = true;
      await queryTagCategories()
        .then((res) => {
          return res?.tag_category;
        })
        .then((categories) => {
          return Promise.all(
            categories.map(async (c) => {
              return {
                value: c.id,
                label: c.name,
                children: await this.getCascaderOptions(c.id),
              };
            }),
          );
        })
        .then((res) => {
          // 过滤没有内容的标签组
          this.options = res.filter((item) => item.children.length > 0);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getCascaderOptions(id) {
      const res = await queryCategoryTags({ category_id: id });
      const list = res?.tag;
      let level1 = [];
      let level2 = [];
      const level3 = [];
      list?.forEach((i) => {
        const ni = {
          value: i.id,
          label: i.name,
          // disabled: i.status === '1',
          ...i,
        };
        if (i.grand_id && i.parent_id) {
          level3.push(ni); // 有父标签和爷标签，则为3级标签
        } else if (i.parent_id && !i.grand_id) {
          level2.push(ni); // 有父标签无爷标签，则为2级标签
        } else {
          level1.push(ni); // 其他为1级标签
        }
      });
      // 将 level3 标签存入 level2 中
      level2 = level2.map((l2) => {
        const children = [];
        level3.forEach((l3) => {
          if (l3.parent_id === l2.id) {
            children.push(l3);
          }
        });
        return children.length ? { ...l2, children } : l2;
      });
      // 将 level2 标签存入 level1 中
      level1 = level1.map((l1) => {
        const children = [];
        level2.forEach((l2) => {
          if (l2.parent_id === l1.id) {
            children.push(l2);
          }
        });
        return children.length ? { ...l1, children } : l1;
      });
      return level1;
    },
    doSelectTag() {
      const res = [];
      this.selectedTags.forEach((item) => {
        res.push(item[item.length - 1]);
      });
      console.log('materialFilter组件及联tag选择: ', res);
      this.filterVals.tagVals = res;
      this.triggerFilter();
    },
    // 获取产品标签
    getProductList() {
      GetAllProductList()
        .then((res) => {
          const total = [
            ...res.product.map((item) => item.id),
            ...res.product_tag.map((item) => item.id),
          ];
          this.productList = [...res.product, ...res.product_tag].filter(
            (item, index) => total.indexOf(item.id) === index,
          );
        })
        .catch((e) => {
          console.error(e);
        });
    },
    triggerInput() {
      // 节流
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        // 值回传父组件，进行筛选
        this.$emit('emitMaterialFilter', this.filterVals);
      }, 1000);
    },
    triggerFilter() {
      // 节流
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        // 值回传父组件，进行筛选
        this.$emit('emitMaterialFilter', this.filterVals);
      }, 100);
    },
  },
};
