import { getFieldItem } from '../../layout/util';

const propSplit = '-';

function parseFields(fields, object, objects, json) {
  const detailField = [];
  fields.forEach((item) => {
    const { field } = item;
    if (!field) {
      detailField.push({
        ...item,
      });
    } else {
      const fieldItem = getFieldItem({ field, object, objects, json });
      if (fieldItem) {
        const prop = field.replaceAll('.', propSplit);
        detailField.push({
          ...item,
          label: item.label || fieldItem.display_name || fieldItem.name,
          prop,
          setMask: fieldItem?.text_option?.set_mask,
        });
      } else {
        detailField.push({
          ...item,
          prop: field.replaceAll('.', propSplit),
        });
      }
    }
  });
  return detailField;
}

function getDetailField({ json, objects = [] }) {
  const { render, object } = json;
  const { groups } = render;
  return groups.map((group) => {
    const { sub_groups: subGroups, fields = [] } = group;

    if (subGroups?.length) {
      return {
        ...group,
        sub_groups: subGroups.map((subGroup) => {
          const { fields = [] } = subGroup;
          return { ...subGroup, fields: parseFields(fields, object, objects, json) };
        }),
      };
    }

    return {
      ...group,
      sub_groups: [
        { col_count: group.col_count, fields: parseFields(fields, object, objects, json) },
      ],
    };
  });
}

export * from './detailUtil';
export { getDetailField };
