//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HightLightWord',
  props: {
    // 高亮的关键字
    keyWord: {
      type: [String, Array],
      default: () => '',
    },
    // 需要高亮的文本
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    highLightText() {
      if (!this.keyWord?.length) return [{ text: this.text, highlights: false }];
      // 确保关键词是一个数组
      let keywords = [];
      if (Array.isArray(this.keyWord)) {
        keywords = this.keyWord.map((text) => text).join('|');
      } else {
        keywords = [this.keyWord];
      }

      const regex = new RegExp(`(${keywords})`, 'gi');
      const result = []; // 匹配到的关键词结果
      let lastIndex = 0; // 上一次匹配到的索引
      let match;
      while ((match = regex.exec(this.text)) !== null) {
        const { index } = match;
        if (index > lastIndex) {
          result.push({ text: this.text.slice(lastIndex, index), highlights: false });
        }
        result.push({ text: match[0], highlights: true });
        lastIndex = regex.lastIndex;
      }

      if (lastIndex < this.text.length) {
        result.push({ text: this.text.slice(lastIndex), highlights: false });
      }
      return result;
    },
  },
  methods: {},
};
