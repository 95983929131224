// 运营位相关接口
const BannerList = `query { mcm_banner(_order_by: { update_time: _desc}, $pageOption, $filter){_aggregate{ _count},id, title,mcm_article{title}, image_url, status} }`;

// 新建运营位
const BannerInsert = `mutation insert {mcm_banner (_values : {title:$title,image_url: $image_url,status:$status,redirect_article_id: $redirect_article_id})}`;

// 根据id获取运营位信息
const GetBannerByID = `query{mcm_banner(id:$id){id,title,image_url,status,redirect_article_id }}`;

// 更新运营位
const BannerUpdate = `mutation update{mcm_banner(_where:{id: $id},_set:{title:$title,image_url:$image_url,status: $status,redirect_article_id:$redirect_article_id})}`;

// 运营位上/下线1-下线，2-上线
const BannerOnLine = ` mutation update {mcm_banner(_where:{id: $id},_set:{status:$status}){status}}`;

// 删除运营位
const BannerDelete = `mutation delete {mcm_banner(id: $id)}`;

// 根据id获取运营位数组信息
const GetBannerListByID = `query { mcm_banner(_where: {id: {_include_any: $idArray }, status: 2}) { id,image_url }}`;

export {
  BannerList,
  BannerInsert,
  GetBannerByID,
  BannerUpdate,
  BannerOnLine,
  BannerDelete,
  GetBannerListByID,
};
