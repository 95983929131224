import { GetManagerLoginConfig } from '@/api/login';
export default {
  namespaced: true,
  getters: {
    getLoginConfig(state) {
      return { ...state.loginConfig };
    },
  },
  state: {
    // 登录设置信息
    loginConfig: {
      login_type: 1, // 1-手机号+验证码登录; 2-企业微信扫码登录; 3-基于oauth/OIDC协议的外部系统登录;
      auth_agent_id: '', // ManagerLoginType=2,用于企业微信扫码登录的自建应用
      qiye_corpid: '', // ManagerLoginType=2,企业微信corpid, 用于前端企业微信发起网页授权
      auth_issuer_url: '', // ManagerLoginType=3,认证授权机构url,【注意】 使用safe base64 encode 进行传输,不要明文
      auth_client_id: '', // ManagerLoginType=3,认证授权机构颁发的调用方身份标示
      grant_type: '', // ManagerLoginType=3,授权类型
      redirect_uri: '', // ManagerLoginType=3,重定向地址,【注意】 使用safe base64 encode 进行传输,不要明文
      account_type: '', // 认证账号类型,目前支持email
    },
    requested: false,
  },
  mutations: {
    setLoginConfig(state, loginConfig) {
      state.loginConfig = loginConfig;
    },
    setRequested(state, value) {
      state.requested = value;
    },
  },
  actions: {
    // 调用此函数拿到信息，若没有存储则会请求
    async getLoginConfig(context) {
      return new Promise((resolve, reject) => {
        if (context.state.requested) {
          resolve(context.getters.getLoginConfig);
        } else {
          GetManagerLoginConfig({ tenant_id: 3000 })
            .then((res) => {
              console.log(res);
              context.commit('setLoginConfig', res);
              context.commit('setRequested', true);
              resolve(context.getters.getLoginConfig);
            })
            .catch((e) => reject(e));
        }
      });
    },
  },
};
