import {
  MessageDetail,
  ReadMessage,
  ReadAllMessage,
  NeedReadMessage,
  AnnouncementDetail,
  UpdateAnnouncement,
  InsertAnnouncement,
} from '@/graphql/gql/notice';
import { calVariables } from '@/graphql/util';
import { DataService } from 'nges-common/src/web/utils/api';

export function queryMessageDetail(data) {
  return DataService({
    query: calVariables(MessageDetail, data),
  });
}

export function queryReadMessage(data) {
  return DataService({
    query: calVariables(ReadMessage, data),
  });
}

export function queryReadAllMessage(data) {
  return DataService({
    query: calVariables(ReadAllMessage, data),
  });
}

export function queryNeedReadMessage(data) {
  return DataService({
    query: calVariables(NeedReadMessage, data),
  });
}

export function queryAnnouncementDetail(data) {
  return DataService({
    query: calVariables(AnnouncementDetail, data),
    trigger_ext: 'WithUserGroupData',
  });
}
export function queryUpdateAnnouncement(data) {
  return DataService({
    query: calVariables(UpdateAnnouncement, data),
  });
}
export function queryInsertAnnouncement(data) {
  return DataService({
    query: calVariables(InsertAnnouncement, data),
  });
}
