import { request } from '../../../utils/request';
import { traceidGenerator } from '@tencent/eyao-util-core';
import login from '../../../utils/login';
export const handleDownload = async (filename, payload) => {
  try {
    const response = await fetch('/api/access/pb/cmd/NodeJsServer/ExportServer/SyncExportCsv', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        body: {
          client: {
            platform: 2,
            product: 2,
            token: login.getToken(),
          },
          payload,
          traceid: traceidGenerator(),
          token: login.getToken(),
        },
      }), // Replace with the data you want to send
    });
    console.log('---response.ok---', response.ok);
    if (!response.ok) {
      console.log('-----throw new Error----');
      throw new Error(`HTTP error: ${response.status}`);
    }
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${filename}.xlsx`; // Replace with the desired filename
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }, 0);
  } catch (e) {
    throw e;
  }
};
export const handleExportTask = async (payload) => {
  try {
    const response = await request('NodeJsServer/ExportServer/AsyncExportCsv', payload);
    console.log('async export result:', response);
  } catch (e) {
    throw e;
  }
};
