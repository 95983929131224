import { getPageConfig } from './common';

export async function loadCustomIconFont() {
  try {
    const iconfontConf = await getPageConfig('custom_iconfont_config', 'object');
    const iconfontDir = iconfontConf.dir || '';
    const iconfontVersion = iconfontConf.version || '';
    if (iconfontDir) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = `/iconfont-custom/${iconfontDir}/style.css?v=${iconfontVersion}`;
      document.body.appendChild(link);
    }
  }catch(e){

  }
}
