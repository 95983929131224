//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from '@/libs/utils/util';
import { queryHospitalListApi } from '@/api/setUp.js';
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    formItems: {
      type: Array,
      default: () => {
        return []; // {maxSelect:number, single?: boolean, label: string, options:{value: string, label: string}[],placeholder: string}[]
      },
    },
  },
  data() {
    return {
      cityMap: new Map(),
      selectLoading: false,
    };
  },
  methods: {
    searchHospital(query) {
      this.selectLoading = true;
      const that = this;
      return queryHospitalListApi({
        page_index: 0,
        page_size: 50,
        hospital_name: `%${query}%`,
      })
        .then((res) => {
          console.log(res);
          const newValue = res.hco
            ? res.hco.map((item) => {
                return {
                  label: item.hospital_name,
                  value: item.hospital_id,
                };
              })
            : [];
          // if (!newValue.length) {
          //   that.selectLoading = false
          //   that.formItems[0].options = [];
          //   return
          // };
          // const existed = that.formItems[0].target.map((item) => item.value);
          // that.formItems[0].options = newValue
          //   .filter((item) => !existed.includes(item.value))
          //   .concat(that.formItems[0].target);

          that.formItems[0].options = newValue;
          that.selectLoading = false;
        })
        .catch((err) => {
          console.error(err);
          that.selectLoading = false;
        });
    },
    // 输入医院名称后搜索二十条记录
    remoteSearch(query) {
      const that = this;
      const temp = debounce(
        () => {
          that.searchHospital(query);
        },
        300,
        false,
      );
      temp();
    },
    selectDropdown(opened, fetchData) {
      // change icon
      const pnode = document.getElementById('dialog');
      const iconNode = pnode.getElementsByClassName('el-input__icon')[0];
      if (opened) {
        this.$nextTick(() => {
          iconNode.classList.remove('el-icon-arrow-up');
          iconNode.classList.add('el-icon-search');
          if (fetchData) iconNode.classList.add('rotate');
        });
      }
      if (fetchData) {
        this.searchHospital('');
      }
    },
    cascaderChangeHandler(newVal, target) {
      newVal = newVal.map(([provinceCode, cityId]) => {
        const provinceObj = this.formItems[0].options.find((item) => item.value === provinceCode);
        const provinceName = provinceObj?.label;
        let cityName = provinceObj?.children.find((item) => item.value === cityId)?.label;
        if (cityName) {
          this.cityMap.set(cityId, cityName);
        } else {
          cityName = this.cityMap.get(cityId);
        }
        return {
          provinceCode,
          cityId,
          name: `${provinceName}-${cityName}`,
        };
      });
      const { length } = target;
      target.splice(0, length, ...newVal);
    },
    cascaderFilterHandler(node, keyword) {
      // 在options中进行筛选，返回true则显示该候补选项
      if (node.text.indexOf(keyword) > -1) {
        return true;
      }
      return false;
    },
    selectChangeHandler(newValue, target) {
      console.log('incoming...', newValue);
      target.splice(0, target.length, ...newValue);
    },
    closeHandler() {
      // resotre data before close
      this.formItems.forEach((formItem) => {
        const length = formItem.target ? formItem.target.length : 0;
        formItem.target.splice(0, length, ...JSON.parse(formItem.targetBackup));
      });
      this.$emit('close');
    },
    confirmHandler() {
      this.$emit('confirm', this.result);
    },
    getProvince(opened) {
      // change icon
      if (!opened) return;
      const pnode = document.getElementById('dialog');
      const iconNode = pnode.getElementsByClassName('el-input__icon')[0];
      this.$nextTick(() => {
        iconNode.classList.remove('el-icon-arrow-down');
        iconNode.classList.add('el-icon-search');
      });
    },
  },
};
