//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueQuillEdit from '../vue-quill-edit/editor';
export default {
  name: 'DialogQuillEdit',
  components: {
    VueQuillEdit,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true,
    },
    dialogTitle: {
      type: String,
      default: '编辑法律&隐私声明',
    },
    content: {
      type: String,
      default: '',
    },
    imageList: { type: Array, default: () => [] },
    videoList: { type: Array, default: () => [] },
    audioList: { type: Array, default: () => [] },
  },
  data() {
    return {
      editContent: '',
    };
  },
  watch: {
    // editContent(newval, oldval) {
    //   this.$emit("getContent", newval);
    // },

    content: {
      handler(newval) {
        this.editContent = newval.replace(/<img src="https:\/\/(?!eyao).*?>/g, ' ');
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.oldEditContent = this.content;
  },
  methods: {
    /**
     *管理弹窗
     */
    closeDialog() {
      this.editContent = this.content;
      this.$emit('closeDialog', false);
    },
    /**
     *点击上传
     */
    upData() {
      this.$emit('getContent', this.editContent);
      this.$emit('upData', false);
    },
    getTextCount(val) {
      this.$emit('getTextCount', val);
    },
    handleClose() {},
  },
};
