//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { execExpression, isExpression, execExpressionDeep } from '../../../../../layout/expression';
import { groupBy, cloneDeep } from 'lodash';
import Action from '../../../action';

export default {
  name: 'GroupDetail',
  components: {
    LayoutDetailContent: () => import('./detail-content'),
    mButton: () => import('../../button'),
  },
  mixins: [Action],
  props: {
    json: {
      type: Object,
      default: () => {},
    },
    expParamsObj: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    formValue: {
      type: Object,
      default: () => {},
    },
    inContainer: {
      type: Boolean,
      default: false,
    },
    parentData: {
      type: Object,
      default() {
        return {};
      },
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // 一个空数组，用于存储分组数据
      groupData: [],
      // 一个对象，用于存储当前活动的分组
      activeGroups: {},
      // 一个对象，用于存储当前活动的子分组
      activeSubGroups: {},
      groupDesc: '',
      groupActions: [],
      subGroupActions: [],
      mainLabel: null,
      secLabel: null,
    };
  },
  computed: {
    calcParentData() {
      return {
        t: {
          formValue: this.formValue,
          params: this.params,
        },
        p: this.parentData,
      };
    },
  },
  mounted() {
    console.log('field', this.field, this.formValue);
    this.init();
    this.handleInitGroupData();
    this.handleInitData();
  },
  methods: {
    execExpression,
    init() {
      const { conf } = this.json.render || {};
      const { group_desc: groupDesc, actions } = conf || {};
      const { main_conf: mainConf } = conf || {};
      const { main_label: mainLabel, sec_label: secLabel } = mainConf || {};
      this.groupDesc = groupDesc;
      this.groupActions = actions;
      this.mainLabel = mainLabel;
      this.secLabel = secLabel;
    },
    handleInitGroupData() {
      // 初始化分组数据
      const { conf } = this.json.render || {};
      const { group_title: groupTitle, group_tag: groupTag, main_conf: mainConf } = conf || {};
      let { main_value: mainValue } = mainConf || {};
      mainValue = execExpression(mainValue, {
        ...this.expParamsObj,
      });
      if (groupTitle) {
        this.handleUpdateGroupData({ groupTitle, groupTag, mainValue });
      }
    },
    getValue() {
      if (this.field?.format_value) {
        return execExpression(this.field.format_value, this.expParamsObj);
      }
      return this.formValue[this.field?.prop];
    },
    handleInitData() {
      const groupList = this.getValue();
      // 如果子表单数据是数组，则处理初始化数据
      if (Array.isArray(groupList)) {
        // 获取过滤器配置
        const { conf } = this.json.render || {};
        // 获取分组标题和分组标签
        const { group_title: groupTitle, group_tag: groupTag } = conf || {};
        // 按分组标签和分组标题对子表单数据进行分组
        const groupData = groupBy(groupList, (item) => {
          if (Array.isArray(groupTitle)) {
            return `${[...groupTitle, groupTag]
              .filter(Boolean)
              .map((i) => item[i])
              .join('@@')}`;
          }
          return `${[groupTitle, groupTag]
            .filter(Boolean)
            .map((i) => item[i])
            .join('@@')}`;
        });
        // 遍历分组数据，如果存在对应的分组数据，则设置活动子分组和子表单数据
        this.groupData = this.groupData.map((i) => {
          const exitList = groupData[i.groupField];
          if (exitList) {
            this.activeSubGroups[i.groupField] = [];
            i.list = cloneDeep(
              exitList.map((ii) => {
                return {
                  ...ii,
                  $subFormId: `${Math.random()}`.slice(2),
                };
              }),
            );
          }
          return i;
        });
      }
    },
    handleUpdateGroupData({ groupTitle, groupTag, mainValue }) {
      // 处理更新分组数据
      const { groupData, hadMain } = this.getGroupDataList({
        groupTitle,
        groupTag,
        mainValue,
      });
      this.groupData = groupData.map((i, index) => {
        const isExitIndex = this.groupData.findIndex((ii) => ii.groupField === i);
        if (isExitIndex > -1) {
          return this.groupData[isExitIndex];
        }
        this.activeGroups[i] = [];
        return {
          groupField: i,
          title: i.split('@@')?.[0] ?? '-',
          main: hadMain && index === 0, // 是否主分组
          raw: {
            [groupTitle]: i,
          },
          list: [],
          actions: [],
        };
      });
    },
    getGroupDataList({ groupTitle, groupTag, mainValue }) {
      const groupList = this.getValue();
      if (!Array.isArray(groupList)) {
        return { groupData: [], hadMain: false };
      }
      // 获取分组数据列表
      let groupData = groupBy(groupList, (item) => {
        if (Array.isArray(groupTitle)) {
          return `${[...groupTitle, groupTag]
            .filter(Boolean)
            .map((i) => item[i])
            .join('@@')}`;
        }
        return `${[groupTitle, groupTag]
          .filter(Boolean)
          .map((i) => item[i])
          .join('@@')}`;
      });
      groupData = Object.keys(groupData);
      let hadMain = false;
      // 如果有主值，则将主值对应的分组移动到列表的最前面
      if (mainValue) {
        const mainTagIndex = groupData.findIndex((i) => {
          return i.split('@@')?.includes(mainValue);
        });
        if (mainTagIndex > -1) {
          hadMain = true;
          const mainTag = groupData[mainTagIndex];
          groupData.splice(mainTagIndex, 1);
          groupData.unshift(mainTag);
        }
      }
      return { groupData, hadMain };
    },
    isGroupActive(groupField) {
      // 检查分组是否激活
      return this.activeGroups[groupField].includes(groupField);
    },
    getGroupDesc(item) {
      return `（${execExpression(this.groupDesc, {
        t: item,
      })}）`;
    },
    getSubGroupActions(formData) {
      const { conf } = this.json.render || {};
      const { title_conf: titleConf } = conf || {};
      const { form_title: formTitleConf } = titleConf;
      const { actions } = formTitleConf || {};
      return cloneDeep(actions).map((action) => {
        return execExpressionDeep(action, {
          ...this.expParamsObj,
          $formData: formData,
        });
      });
    },
    handleChange(e, groupField) {
      // 处理改变事件
      this.activeGroups[groupField] = e;
      this.activeGroups = { ...this.activeGroups };
    },
    handleSubChange(e, groupField) {
      // 处理子改变事件
      this.activeSubGroups[groupField] = e;
      this.activeSubGroups = { ...this.activeSubGroups };
      window.dispatchEvent(new Event('resize')); // 触发一下页面的resize，保证其中的列表重新算一下各种高度
    },
    getFormTitle(formData, index) {
      // 获取表单标题
      const { conf } = this.json.render || {};
      const { title_conf: titleConf } = conf || {};
      const { form_title: formTitleConf } = titleConf;
      const { title } = formTitleConf || {};
      let calcTitle;
      if (isExpression(title)) {
        calcTitle = execExpression(title, {
          t: {
            formData,
          },
          $index: index,
          json: this.json,
        });
      } else {
        calcTitle = formData[title];
      }
      if (!calcTitle) {
        return '';
      }
      return `${calcTitle}`;
    },
    getFormDescs(formData, index) {
      // 获取表单描述
      const { conf } = this.json.render || {};
      const { title_conf: titleConf } = conf || {};
      const { form_title: formTitleConf } = titleConf;
      const { descs } = formTitleConf || {};
      let calcDescs = [];
      if (Array.isArray(descs)) {
        descs.map((desc) => {
          if (isExpression(desc)) {
            calcDescs.push(
              execExpression(desc, {
                t: {
                  formData,
                },
                $index: index,
                json: this.json,
              }),
            );
          } else {
            calcDescs.push(formData[desc]);
          }
        });
      }
      calcDescs = calcDescs.filter(Boolean).join(' | ');
      if (!calcDescs) {
        return '';
      }
      return `${calcDescs}`;
    },
    isSubFormActive(groupField, target) {
      // 检查子表单是否激活
      return this.activeSubGroups[groupField].includes(target);
    },
    getDetailJson(formData) {
      return {
        ...this.json,
        static_data: formData,
      };
    },
  },
};
