import { request, storeRequest } from './request';
import graphqlRequest from './graphqlRequest';
import { calVariables } from '../../graphql';
import { APP_CONFIG } from '../../constant';
import { keys, has } from 'lodash';
const _ = { keys };
const { log } = console;

// 校验 token 是否有效
export const ParseToken = (data) => request('AccountServer/AccountBase/ParseToken', data);

export const GetObjectsByNames = (data) => {
  if (!data.objects?.length) {
    return Promise.reject('no objects');
  }
  return storeRequest(
    { cmd: 'DataServer/MetadataService/GetObjectsByNames', payload: data },
    { key: data.objects.sort().join('-'), cache: true },
  );
};
export const GetAppConfig = () => {
  return storeRequest(
    {
      cmd: 'NgesServer/NgesApp/GetAppConfig',
      payload: { domain: location.host },
    },
    { key: 'GetAppConfig', cache: true },
  ).then((res) => {
    window.globalAppid = res.appid;
    const keyMap = {
      mobile_domain: 'mobileDomain',
    };
    const data = { ...res };
    const outData = {};
    _.keys(data).forEach((key) => {
      if (keyMap[key]) outData[keyMap[key]] = data[key];
      outData[key] = data[key];
    });
    const isLocalHost = typeof window === 'object' && window?.location?.hostname === 'localhost';
    if (!isLocalHost) {
      const isProd = process.env.VUE_APP_ENV === 'production';
      // 包含日志的kv配置
      if (has(outData, 'page_config.log_enable_web')) {
        if (`${outData.page_config.log_enable_web}` === '0') {
          // 关闭log日志
          console.log = () => {};
        } else {
          // 打开log日志
          console.log = log;
        }
      } else {
        if (isProd) {
          // 生产默认关闭日志
          console.log = () => {};
        } else {
          // 非生产默认开启
          console.log = log;
        }
      }
    }
    return { ...APP_CONFIG, ...outData };
  });
  // return storeRequest(
  //   {
  //     cmd: 'NgesServer/NgesApp/GetAppConfig',
  //   },
  //   { key: 'GetAppConfig', cache: true },
  // );
};
export const GetUserMenuTree = () =>
  storeRequest(
    {
      cmd: 'AuthServer/AuthPermission/GetUserMenuTree',
    },
    { key: 'GetUserMenuTree', cache: true },
  );

export const GetUserLayoutList = (data = {}) => {
  return request('AuthServer/AuthPermission/GetUserLayoutList', data);
};

export const GetUserElementList = () =>
  storeRequest(
    {
      cmd: 'AuthServer/AuthPermission/GetUserElementList',
    },
    { key: 'GetUserElementList', cache: true },
  );
export const Login = (data) => request('AccountServer/AccountLogin/Login', data);
// 通过企业微信扫码登录
export const LoginWithWorkWeixin = (data) =>
  request('AccountServer/AccountLogin/LoginWithWorkWeixinByThirthQr', data);
// 通过okta登录
export const StaffLoginByOIDCForManager = (data) => request('StaffLoginByOIDCForManager', data);
// 通过验证码登录
export const LoginWithPhone = (data) => request('AccountServer/AccountLogin/LoginWithPhone', data);
export const LoginPhoneBound = (data) => request('LoginPhoneBound', data);
export const DataService = (data, path) => {
  return graphqlRequest(path || 'DataServer/DataService/GraphqlQuery', data);
};

export const GetObjectList = () => {
  return storeRequest(
    {
      cmd: 'DataAdminServer/MetadataService/GetObjectList',
      payload: {
        offset: 0,
        limit: 300,
      },
    },
    {
      cache: true,
      key: 'GetObjectList',
    },
  );
};

// 获取当前审批流的字段权限
export const GetProcessFieldPermission = (data) =>
  request('Workflow/ApprovalService/GetProcessFieldPermission', data);

// 通过审批流接口保存编辑数据
export const SaveApprovalEditDetail = (data) =>
  request('Workflow/ApprovalService/SaveApprovalEditDetail', data);

// 获取私有桶下载链接
export const GetPrivateObjUrl = function (data) {
  return request('NgesServer/NgesApp/GetPrivateObjUrl', data);
};
// 获取公有桶上传签名
export const getUploadSignApi = function (data) {
  return request('ApiServer/Cos/GetUploadSign', data);
};
// 获取私有桶上传签名
export const getPrivateUploadSignApi = function (data) {
  return request('ApiServer/Cos/GetPrivateUploadSign', data);
};
export const SendMessaging = (data) => request('MessagingServer/Messaging/SendMessaging', data);

// 查询当前员工数据
export const GetStaffUserInfo = function (data) {
  return storeRequest(
    { cmd: 'NgesServer/NgesStaff/GetStaffUserInfo', payload: data },
    { key: 'GetStaffUserInfo' },
  );
};

// 数据权限
export const GetTenantObjectsPermission = () =>
  storeRequest(
    { cmd: 'AuthServer/AuthPermission/GetTenantObjectsPermission' },
    {
      cache: true,
      key: 'GetTenantObjectsPermission',
    },
  ).then((res) => {
    const list = [];
    res?.data?.forEach((item) => {
      const tableName = item.table_name;
      item.action.forEach((action) => {
        // action: select insert update delete
        list.push(`${tableName}.${action}`);
      });
    });
    return list;
  });
export const GetKvConfigs = (keys) => {
  return DataService({
    query: `query {kv (key:{ _in: ${keys}}) {value key}}`,
  });
};
export const SetKvConfig = (key, value) => {
  return DataService({
    query: `mutation update {kv(_where:{key: ${key}}, _set: {value:"${value}"} )}`,
  });
};
// 获取审批历史节点详情
export function GetNodeDetail(params) {
  return request('Workflow/ApprovalService/ListApprovalByBusinessKey', params);
}
// 获取当前审批节点详情
export function GetSingleTaskInfo(data) {
  return request('Workflow/ApprovalService/GetSingleTaskInfo', data);
}
// 审批人退回操作
export function approveRollback(data) {
  return request('Workflow/ApprovalService/Rollback', data);
}
// 根据businesskey 获取该对象审批单关联的所有审批类型;
export const GetApprovalTypesWithBusinesskey = (data) =>
  request('Workflow/ApprovalService/ListApprovalType', data);
// 获取当前审批人可退回之前的已审批的审批人列表
export const ListApprovalNode = (data) =>
  request('Workflow/ApprovalService/ListApprovalNode', data);
// 对单个审批单审批
export function SubmitTask(data) {
  return request('Workflow/ApprovalService/SubmitTask', data);
}
export function GetSingleProcessInfo(data) {
  return request('Workflow/ApprovalService/GetSingleProcessInfo', data);
}
export const QueryRuleCheck = (data) => request('DataServer/RuleService/ComplianceCheck', data);
// 通过BusinessKey 查询 ProcessIns
export function QueryProcessInsByBusinessKey(data) {
  return DataService({
    query: calVariables(
      `query {
  approval_applies(
      bussiness_key: $business_key
      _order_by: { update_time: _desc }
    ) {
    process_instance_id
  }
}`,
      data,
    ),
  });
}

export const getDataLockStatus = (data) =>
  request('DataServer/DataService/GetDataLockStatus', data);
export const lockData = (data) => request('DataServer/DataService/LockData', data);
export const unlockData = (data) => request('DataServer/DataService/UnlockData', data);

export const GetShareRule = (data) =>
  request('AuthAdminServer/ManualShareRule/GetManualShareRules', data);
export const UpdateShareRule = (data) =>
  request('AuthAdminServer/ManualShareRule/PutManualShareRule', data);

export const GetGroupList = (data) => request('AuthServer/AuthGroup/GetGroupList', data);
export const GetAuthorizedDataRows = (data) => {
  return request('DataServer/DataService/GetAuthorizedDataRows', data);
};
export const GetAccountUserInfo = (data) => request('AccountServer/AccountBase/GetUserInfo', data);

// 通过appid获取授权code，用于webview中和外部系统对接
export const GetAuthCode = (data) => request('AccountServer/AccountApi/GetAuthCode', data);

// 使用反馈标记已读
export const UsageFeedbackMarkRead = (data) =>
  request('NgesServer/NgesUsageFeedback/UsageFeedbackMarkRead', data);

export function GetHelpGuideRead(id) {
  return DataService({
    query: `query {ignore_help_guide_user(help_guide_id: "${id}", owner: $_uin){url}}`,
  });
}
export function SetHelpGuideRead(id, urlList) {
  const gql = urlList.map((url) => {
    return ` {help_guide_id: "${id}", url: "${url}"}`;
  });
  return DataService({
    query: `mutation insert {
    ignore_help_guide_user(_values:[${gql.join(',')}])
    }`,
  });
}
