//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { GetApprovalTypesWithBusinesskey } from '../../../../../utils/api';
import { getLayoutJson } from '../../../../../utils/query';
export default {
  name: '',
  components: {},
  props: {
    approvalTypeList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      nodeData: [],
      drawerVisible: false,
      businessData: {},
      business_name: '',
      loading: false,
      nodeHistoryJson: null,
      params: {},
      selectedApprovalType: null,
      // 该审批单关联的所有审批类型
      relatedApprovalTypes: [],
      businessKey: '',
      computedType: '',
    };
  },
  computed: {
    ...mapState('user', {
      menuList: 'menuList',
    }),
    nodeHistoryParams() {
      return {
        ...this.params,
        type: this.selectedApprovalType,
      };
    },
  },
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    async init(params) {
      // eslint-disable-next-line prefer-const
      let { business_key: businessKey, approval_type: approvalType } = this.$route.query;
      const computedKey = businessKey || params?.business_key;
      this.computedType = approvalType || params?.approval_type;
      if (!computedKey) return this.$message.error('参数缺少business_key');
      this.businessKey = computedKey;
      this.params = { business_key: computedKey };
      this.drawerVisible = true;
      await this.getApprovalTypes();
      // await QueryProcessInsByBusinessKey({ business_key: computedKey })
      //   .then((res) => {
      //     const info = res?.approval_applies?.[0];
      //     // eslint-disable-next-line camelcase
      //     process_ins = info?.process_instance_id;
      //   })
      //   .catch((e) => {
      //     console.error(e);
      //   });
      const nodeHistoryJson = this.getJsonWithPath('approval_history_node_web');
      if (nodeHistoryJson) {
        this.nodeHistoryJson = nodeHistoryJson;
      }
    },
    async getApprovalTypes() {
      await GetApprovalTypesWithBusinesskey({
        business_key: this.businessKey,
      })
        .then(({ type = [] }) => {
          if (type.length) {
            if (this.computedType) {
              const currentType = type.filter((it) => it.type === this.approvalType);
              const restType = type.filter((it) => it.type !== this.approvalType);
              this.relatedApprovalTypes = [...currentType, ...restType];
              this.selectedApprovalType = this.relatedApprovalTypes[0].type;
            } else {
              this.relatedApprovalTypes = type;
              this.selectedApprovalType = type[0].type;
            }
          }
        })
        .catch((err) => console.error(err));
    },
    getJsonWithPath(path) {
      return getLayoutJson(this.menuList, path) || '';
    },
  },
};
