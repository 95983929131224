import TrackingReport from '../report';
import { forEach } from 'lodash';

// 任务列表，当有新布局打开时，将destoryed任务记录进任务列表中，用于关闭浏览器时，提交任务
export let TASK = {};
let TASK_ID = 0;

export function flushTask() {
  forEach(TASK, (handle) => {
    handle && handle();
  });
  TrackingReport.flush();
  TASK = {};
}

export function getNewTaskId() {
  TASK_ID = TASK_ID + 1;
  return TASK_ID;
}

export function addTask(idx, handle) {
  TASK[idx] = handle;
}

export function removeTask(idx) {
  delete TASK[idx];
}
