//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SelectByJsonDialog',
  props: {
    title: {
      type: String,
      default: () => '',
    },
    json: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selected: [],
      visible: false,
      params: {},
    };
  },
  computed: {},
  methods: {
    onVisibleChange(visible, params) {
      this.visible = visible;
      this.params = params;
    },
    hanldeSelect(value) {
      this.selected = value;
    },
    async handleConfirm() {
      this.$emit('onConfirm', this.selected, this.params);
    },
  },
};
