//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Number',
  props: {
    filterObj: {
      type: Object,
      default: () => {},
    },
    formValue: {
      type: Object,
      default: () => {},
    },
  },
};
