const env = ['development', 'preview', 'production', 'test', 'az'];
const importConfig = (filePath) => {
  const temp = {};
  env.forEach((fileName) => {
    try {
      temp[fileName] = require(`./${filePath}/${fileName}.json`);
    } catch (e) {
      temp[fileName] = require(`./common/${fileName}.json`);
    }
  });
  return temp;
};
const DEFAULT = importConfig('nges');
const WC = importConfig('wc');
const { VUE_APP_BUILD_MINI: BUILDMINI = 'nges' } = process.env;
let development = {};
let test = {};
let preview = {};
let production = {};
let az = {};
switch (BUILDMINI) {
  case 'wc':
    development = WC.development;
    test = WC.test;
    preview = WC.preview;
    production = WC.production;
    break;
  default:
    development = DEFAULT.development;
    test = DEFAULT.test;
    preview = DEFAULT.preview;
    production = DEFAULT.production;
    az = DEFAULT.az;
    break;
}
module.exports = {
  buildMini: BUILDMINI,
  development,
  test,
  preview,
  production,
  az,
};
