import request, { storeRequest } from '@/libs/request';

export const getUploadSignApi = (data) => request('ApiServer/Cos/GetUploadSign', data);

export const getPrivateUploadSignApi = (data) =>
  request('ApiServer/Cos/GetPrivateUploadSign', data);
// export const getPrivateUploadSignApi = (data) => request('ApiServer/Cos/GetUploadSign', data);

export const GetRosterTemplate = (data) =>
  request('McmAdminServer/PublishListService/GetListTemplate', data); // 下载导入模版
export const GetTenantObjectsPermission = () =>
  storeRequest(
    { cmd: 'AuthServer/AuthPermission/GetTenantObjectsPermission' },
    {
      cache: true,
      key: 'GetTenantObjectsPermission',
    },
  ).then((res) => {
    const list = [];
    res?.data?.forEach((item) => {
      const tableName = item.table_name;
      item.action.forEach((action) => {
        // action: select insert update delete
        list.push(`${tableName}.${action}`);
      });
    });
    return list;
  });

// 获取对象列表
export const GetObjectList = (data) => request('DataServer/MetadataService/GetObjectList', data);

// 获取角色列表
export const GetRoleTree = (data) => request('AuthAdminServer/AuthAdminRole/GetRoleTree', data);

// 获取菜单列表
export const GetMenuTree = (data) => request('AuthAdminServer/AuthAdminMenu/GetMenuTree', data);

// 获取菜单详情
export const GetMenuInfo = (data) => request('AuthAdminServer/AuthAdminMenu/GetMenuInfo', data);

export const GetPermissionObjectList = (data) =>
  request('AuthServer/AuthPermission/GetTenantObjectsPermission', data);

export const GetCreateAppUrl = (data) => request('NgesServer/NgesApp/GetCreateAppUrl', data);

// 获取文档下载链接
export const GetPrivateObjUrl = (data) => request('ApiServer/Cos/GetPrivateObjUrl', data);
