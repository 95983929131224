// name: 表名  mapping: 键名对应表头名
export const StatisticsReport = {
  name: '每日统计报告',
  mapping: [
    ['date', '日期'],
    ['cumulate_num', '用户量'],
    ['incre_num', '新增用户量'],
    ['active_num', '活跃用户量'],
  ],
};

export const BehaviorDetail = {
  name: '用户行为明细',
  mapping: [
    ['user_name', '姓名'],
    ['user_id', '编号'],
    ['doctor_code', '医生编码'],
    ['hospital_name', '医院'],
    ['department_name', '科室'],
    ['title_name', '职称'],
    ['user_type', '身份'],
    ['behavior_time', '时间'],
    ['behavior_type', '行为'],
    ['target_name', '对象'],
    ['related_username', '关联用户'],
    ['target_doctor_code', '关联用户医生编码'],
    ['target_hospital_name', '关联用户医院'],
    ['target_title_name', '关联用户职称'],
    ['target_department_name', '关联用户科室'],
  ],
};

export const MeetingDataEveryDay = {
  name: '会议数据',
  mapping: [
    ['date', '日期'],
    ['apply_num', '计划会议数'],
    ['finish_num', '完成会议数'],
    ['average_duration', '平均时长(min)'],
    ['attending_num', '参会总人数'],
    ['average_num', '参会平均人数'],
    ['violate_num', '违规数'],
    ['online_percent', '线上会议占比'],
  ],
};

export const MeetingDataByOneDay = {
  name: '每日会议数据',
  mapping: [
    ['meeting_name', '会议名称'],
    ['start_time', '会议开始时间'],
    ['type', '会议类型'],
    ['product_name', '相关标签'],
    ['duration', '会议时长(min)'],
    ['attending_num', '参会人数'],
    ['speaker_name', '讲者姓名'],
    ['applier_name', '申请人姓名'],
    ['sync_attendance_time', '同步时间'],
    ['state', '是否完成'],
    ['is_violate', '是否合规'],
  ],
};

export const MeetingAttendantList = {
  name: '参会人列表',
  mapping: [
    ['doctor_name', '参会人姓名'],
    ['hospital_name', '所在医院'],
    ['department_name', '科室'],
    ['doctor_level', '等级'],
    ['speaker_status', '是否讲者'],
    ['duration', '参会时长(min)'],
    ['attend_num', '30天内参会次数'],
  ],
};

export const ArticleDataEveryday = {
  name: '文章每日明细数据',
  mapping: [
    ['date', '发布日期'],
    ['publish_count', '发布量'],
    ['read_count', '阅读量'],
    ['share_count', '转发量'],
    ['collect_count', '收藏量'],
  ],
};

export const ArticalDataDetailByOneDay = {
  name: '文章明细数据',
  mapping: [
    ['article_name', '文章名称'],
    ['art_id', '文章ID'],
    ['publish_date', '发布时间'],
    ['appname', '渠道'],
    ['read_count', '阅读次数'],
    ['read_pnt', '阅读人数'],
    ['share_count', '分享次数'],
    ['collect_count', '收藏次数'],
    ['read_duration', '阅读时长(min)'],
    ['finish_rate', '阅读完成率'],
  ],
};

export const DeleteRecord = {
  name: '删除客户记录',
  mapping: [
    ['customer_name', '客户名称'],
    ['doctor_name', '医生'],
    ['hospital_name', '医院'],
    ['dept_name', '科室'],
    ['principal', '负责人'],
    ['principal_userid', '负责人ID'],
    ['del_side', '删除方'],
    ['del_time', '删除时间'],
  ],
};

export const DoctorManagement = {
  name: '医生管理',
  mapping: [
    ['doctor_name', '姓名'],
    ['doctor_id', '编码'],
    ['hospital_name', '医院'],
    ['is_registered', '注册状态'],
    ['verify_status', '认证状态'],
    ['contract_status', '签约状态'],
    ['department_name', '科室'],
    ['title', '职称'],
    ['doctor_code', '医生编号'],
  ],
};
