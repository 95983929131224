import { request } from 'nges-common/src/web/utils/request';
import { DataService } from 'nges-common/src/web/utils/api';

export function GetWatermarkConfig(data) {
  return request('NgesServer/WatermarkService/GetWatermarkConfig', data);
}

export function GetWatermarkOpen() {
  return DataService({
    query: `query {
      watermark_config(active:true,products.appid:"2") {
        id
        name
        type
        page_url
        layout_object
      }
    }`,
  }).then((res) => {
    const list = res?.watermark_config || [];
    const result = {
      // 通用水印
      generic: false,
      // url水印列表
      url: [],
      // 对象水印列表
      objects: [],
    };
    list.forEach((i) => {
      if (i.type === 'generic') result.generic = true;
      else {
        if (i.page_url) result.url.push(i.page_url);
        if (i.layout_object) result.objects.push(i.layout_object);
      }
    });
    return result;
  });
}

const watermarkUtil = {
  getObjectWatermark: () => {},
  updateWatermark: () => {},
  reloadWatermark: () => {},
};

export { watermarkUtil };
