//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CosMixin } from '@/mixins/cos';
export default {
  name: 'FileUpload',
  mixins: [CosMixin],
  model: { prop: 'imageUrl', event: 'onSuccess' },
  props: {
    imageUrl: {
      type: String,
      default: '',
    },
    fileTypeBeJpg: {
      type: Boolean,
      default: false,
    },
    fileSize: {
      type: Number,
      default: 2,
    },
    sizeLimit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    // 上传图片
    async handleRequest(file) {
      console.log(666, file);
      try {
        const cosParams = {
          fileObject: file.file,
        };
        const res = await this.putCosObject(cosParams);
        console.log(res, 'cos-res======>');
        this.$emit('onSuccess', res.data);
        this.loading = false;
        this.$message.success('上传成功');
      } catch (err) {
        this.loading = false;
        this.$message.error('上传失败');
        console.log('error', err);
      }
    },
    onError(e) {
      this.$emit('onError', e);
      this.loading = false;
    },
    onSuccess(file) {
      const img = file.ResultDate.cos_file_url;
      this.$emit('onSuccess', img);
      this.loading = false;
      this.$message.success('上传成功');
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isPG = this.fileTypeBeJpg ? isJPG || isPNG : isPNG;
      const isLt2M = file.size / 1024 / 1024 < this.fileSize || !this.sizeLimit;
      if (!isPNG && !this.fileTypeBeJpg) {
        this.$message.error('上传图片只能是 png 格式!');
        this.$emit('beforeAvatarUpload', false);
        this.loading = false;
      }
      if (!isPG && this.fileTypeBeJpg) {
        this.$message.error('上传图片只能是 jpg/png 格式!');
        this.$emit('beforeAvatarUpload', false);
        this.loading = false;
      }
      if (!isLt2M) {
        this.$message.error(
          `上传图片大小不能超过 ${
            this.fileSize > 1 ? `${this.fileSize}mb` : `${this.fileSize * 1000}kb`
          }!`,
        );
        this.loading = false;
      }
      return isPG && isLt2M;
    },
  },
};
