import TrackingReportCore from './report-core';
import { traceidGenerator } from 'nges-common/src/web-mobile/util';
let requestConfig;
let login;
let request = () => {};

requestConfig = require('../../web/utils/request').requestConfigOptions;
login = require('../../web/utils/login').default;



// 生成上报的请求参数
function getRequestParams(cmd, payload = {}, options = {}) {
  const { product, platform, adtag = '' } = requestConfig;
  const header = {
    version: '',
    flag: 0,
    ...(options?.header || {}),
  };
  const client = {
    platform, // 客户端平台， 小程序=0  公众号=1  APP=2 QQ浏览器小程序=3
    env: '', // 标识环境
    product,
    adtag,
    ...(options?.client || {}),
  };
  //  前端生成 traceid
  const traceid = traceidGenerator();
  const params = {
    header,
    body: {
      cmd,
      token: login.getToken(),
      traceid,
      client,
      payload,
      ...(options?.body || {}),
    },
  };
  return params;
}


const trackingConfig = {
  httpSend: (payload, options) => {
    const cmd = 'NgesServer/NgesEventTracking/ReportBatch';
    
    const requestParams = getRequestParams(cmd, payload, options);
    return navigator.sendBeacon(
      `/api/access/pb/cmd/${cmd}`,
      JSON.stringify(requestParams),
      options,
    );
    
  },
};
const TrackingReportInst = new TrackingReportCore(trackingConfig);
TrackingReportInst.start();

export default TrackingReportInst;
