//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { easyQueryGql } from 'nges-common/src/layout/queryUtil';
import { GetObjectsByNames, DataService } from 'nges-common/src/web/utils/api';
import { debounce, set, isString, isEmpty, cloneDeep, isObject } from 'lodash';
import {
  execExpression,
  execExpressionDeep,
  isExpression,
} from 'nges-common/src/layout/expression';
import { transData, getObjects } from 'nges-common/src/layout/queryWeb';
import { getFieldLabel } from 'nges-common/src/layout/query';
import { getValue } from 'nges-common/src/graphql';
import LformItem from './form-item';
import { whereGql } from 'nges-common/src/layout/util';
import Action from '../../../action';

export default {
  name: 'Search',
  components: {
    LformItem,
  },
  mixins: [Action],
  props: {
    json: {
      type: [Object],
      default: () => {},
    },
  },
  data() {
    return {
      searchQuery: '',
      showDropdown: false,
      searchHistory: [],
      cards: [],
      page: 1, // 当前页码
      pageSize: 10, // 每页数据量
      loading: false,
      historyKey: '',
      isAllLoaded: {}, // 是否所有数据都已加载
      objects: null,
      sources: [],
      lock: false,
      filters: {},
      formValue: {},
      formData: {},
      isFocus: false,
      timeoutId: null,
    };
  },
  computed: {
    isLoaded() {
      return isEmpty(this.isAllLoaded) ? false : Object.values(this.isAllLoaded).every((i) => i);
    },
    allFilters() {
      const allFilters = Object.keys(this.filters).reduce((pre, filterObject) => {
        const objectFilters = this.filters[filterObject] || [];
        objectFilters.forEach((filter) => {
          pre.push({
            object_name: filterObject,
            ...filter,
          });
        });
        return pre;
      }, []);
      console.log('===allFilters===', allFilters);
      return allFilters;
    },
  },
  watch: {
    searchQuery(nv) {
      if (!isEmpty(nv)) {
        this.reset();
      }
    },
  },
  mounted() {
    this.init();
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
    // 组件销毁前清除 setTimeout
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    handleAction(item) {
      this.m_actionHandle(item?.action);
    },
    checkPropertyChange(cb) {
      // 清除之前的 setTimeout
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      // 设置新的 setTimeout
      this.timeoutId = setTimeout(() => {
        if (!this.lock) {
          cb();
        } else {
          this.checkPropertyChange(cb);
        }
      }, 1000);
    },
    handleClkTag(tag) {
      this.searchQuery = tag;
      this.checkPropertyChange(this.loadMoreCards);
    },
    async reset() {
      return new Promise((re) => {
        this.lock = true;
        this.cards = [];
        this.page = 1;
        this.initSource();
        setTimeout(() => {
          this.lock = false;
          re();
        }, 200);
      });
    },
    handleClickOutside(event) {
      if (
        this.showDropdown
        && !this.$refs.search.contains(event.target)
        && !event.target.className.includes('el-input__clear')
      ) {
        this.showDropdown = false;
      }
    },
    init() {
      this.initHistory();
      this.getObjects();
      this.initSource();
      this.initFilter();
    },
    initHistory() {
      const prefixKey = '$tab_search_history_';
      if (this.json?.render?.search?.history_key) {
        this.historyKey = `${prefixKey}${this.json.render.search.history_key}`;
      } else {
        this.historyKey = `${prefixKey}${this.$route?.fullPath}`;
      }
      this.searchHistory = JSON.parse(localStorage.getItem(this.historyKey) || '[]');
    },
    initSource() {
      const { sources } = this.json?.render?.search || {};
      this.sources = cloneDeep(sources);
      if (Array.isArray(sources)) {
        sources.forEach((source) => {
          this.isAllLoaded[source.object] = false;
        });
      }
    },
    initFilter() {
      this.sources.forEach((source) => {
        const { filters } = source?.render || {};
        if (Array.isArray(filters)) {
          this.filters[source.object] = filters;
        }
      });
      this.filters = {
        ...this.filters,
      };
    },
    getObjects() {
      return GetObjectsByNames({
        objects: getObjects(this.json),
      })
        .then(({ list }) => {
          this.objects = list;
        })
        .catch((e) => {
          console.log(e);
          this.objects = [];
        });
    },
    async getList() {
      const allList = [];
      let sources = cloneDeep(this.sources);
      if (Array.isArray(sources)) {
        const conditions = sources.reduce((pre, source) => {
          pre[source.object] = {
            _limit: this.pageSize,
            _offset: (this.page - 1) * this.pageSize,
          };
          return pre;
        }, {});
        sources = this.getFilter(sources);
        const gql = easyQueryGql(sources, conditions);
        console.log('===gql===', gql);
        const res = await DataService({
          query: gql,
        });
        sources.forEach((source) => {
          const newList
            = transData({
              json: source,
              objects: this.objects,
              data: res,
            }) || {};
          if (newList.total <= this.page * this.pageSize) {
            this.$set(this.isAllLoaded, source.object, true);
            this.isAllLoaded = {
              ...this.isAllLoaded,
            };
          }
          allList.push(...this.handleList(newList.list, source));
        });
        Object.keys(this.isAllLoaded).forEach((key) => {
          if (this.isAllLoaded[key]) {
            const index = sources.findIndex((source) => source.object === key);
            index >= 0 && sources.splice(index, 1);
          }
        });
      }
      return allList;
    },
    getFilter(sources) {
      sources.forEach((source) => {
        // 处理搜素条件
        const searchConf = source?.render?.input_conf;
        if (!isEmpty(this.searchQuery) && searchConf?.fields?.length) {
          let searchWhereGql = searchConf.fields.reduce((pre, field) => {
            const whereType = searchConf.where_type;
            const value = whereType === '_like' ? `%${this.searchQuery}%` : this.searchQuery;
            const gqlStr = `{ ${field}: { ${whereType}: ${getValue(value, true, true)} } }`;
            pre.push(gqlStr);
            return pre;
          }, []);
          searchWhereGql = `{ _or: [${searchWhereGql.join(',')}] }`;
          searchWhereGql = {
            fields: [
              {
                where_gql: searchWhereGql,
              },
            ],
          };
          if (searchConf?.object_name) {
            searchWhereGql.object_name = searchConf.object_name;
          }
          source.wheres = source.wheres || [];
          const index = source.wheres.findIndex((whereConf) => {
            return whereConf?.object_name === searchWhereGql.object_name;
          });
          if (index >= 0) {
            source.wheres[index].fields = source.wheres[index].fields || [];
            source.wheres[index].fields = [
              ...source.wheres[index].fields,
              ...searchWhereGql.fields,
            ];
          } else {
            source.wheres.push(searchWhereGql);
          }
        }
        // 处理筛选器条件
        const filtersConf = source?.render?.filters;
        if (filtersConf?.length) {
          console.log('===filtersConf===', filtersConf);
          filtersConf.map((filter) => {
            filter.value = this.formValue[filter.field];
            const whereGqlStr = whereGql({
              ...filter,
              filters: {
                formValue: this.formValue,
                formData: this.formData,
              },
            });
            const filterWhereGql = {
              fields: [
                {
                  where_gql: whereGqlStr,
                },
              ],
            };
            if (filter?.object_name) {
              filterWhereGql.object_name = filter.object_name;
            }
            source.wheres = source.wheres || [];
            const index = source.wheres.findIndex((whereConf) => {
              return whereConf?.object_name === filterWhereGql.object_name;
            });
            if (index >= 0) {
              source.wheres[index].fields = source.wheres[index].fields || [];
              source.wheres[index].fields = [
                ...source.wheres[index].fields,
                ...filterWhereGql.fields,
              ];
            } else {
              source.wheres.push(filterWhereGql);
            }
          });
        }
      });
      return sources;
    },
    handleList(list, source) {
      return list.map((item, index) => {
        Object.keys(source?.render?.row || {}).forEach((key) => {
          const fieldConf = source.render.row?.[key];
          if (Array.isArray(fieldConf)) {
            set(
              item,
              key,
              fieldConf.map((field) => {
                if (isExpression(field)) {
                  return execExpression(field, {
                    t: item,
                    $item: item,
                    $index: index,
                  });
                }
                return getFieldLabel(item, field);
              }),
            );
          } else if (isString(fieldConf)) {
            if (isExpression(fieldConf)) {
              set(
                item,
                key,
                execExpression(fieldConf, {
                  t: item,
                  $item: item,
                  $index: index,
                }),
              );
            } else {
              set(item, key, getFieldLabel(item, fieldConf));
            }
          } else if (isObject(fieldConf)) {
            set(
              item,
              key,
              execExpressionDeep(cloneDeep(fieldConf), {
                t: item,
                $item: item,
                $index: index,
              }),
            );
          }
        });
        return item;
      });
    },
    handleSelect(item) {
      // 处理选择联想查询结果的逻辑
      this.addHistory(item.title);
    },
    handleFocus() {
      this.isFocus = true;
      this.showDropdown = true;
    },
    handleBlur() {
      setTimeout(() => {
        this.isFocus = false;
      }, 200);
    },
    addHistory(query) {
      if (!this.searchHistory.includes(query)) {
        this.searchHistory.unshift(query);
        localStorage.setItem(this.historyKey, JSON.stringify(this.searchHistory));
      }
    },
    removeHistory(query) {
      const index = this.searchHistory.indexOf(query);
      if (index !== -1) {
        this.searchHistory.splice(index, 1);
        localStorage.setItem(this.historyKey, JSON.stringify(this.searchHistory));
      }
    },
    clearHistory() {
      this.searchHistory = [];
      localStorage.setItem(this.historyKey, JSON.stringify([]));
    },
    getHighlight(text) {
      if (!this.searchQuery) return text;
      return isString(text)
        ? text.replace(
            new RegExp(this.searchQuery, 'gi'),
            (match) => `<span class="highlight">${match}</span>`,
          )
        : void 0;
    },
    getHighLights(texts) {
      return texts
        .map((text) => this.getHighlight(text))
        .filter(Boolean)
        .join(' | ');
    },
    // 处理滚动事件
    handleScroll(event) {
      const { target } = event;
      // 检查用户是否滚动到容器底部
      if (target.scrollHeight - target.scrollTop - 10 <= target.clientHeight) {
        this.loadMoreCards();
      }
    },
    // 加载更多卡片数据的方法
    loadMoreCards: debounce(async function () {
      try {
        this.isFocus = false;
        // 使用 $refs 获取组件实例
        const inputComponent = this.$refs.input;
        // 获取组件内部的原生 input 元素
        const inputElement = inputComponent.$el.querySelector('input');
        // 调用原生 input 元素的 blur 方法使其失焦
        if (inputElement) {
          inputElement.blur();
        }
        if (this.lock || this.isLoaded || this.loading) {
          return;
        }
        this.loading = true;
        const newCards = await this.getList();
        this.cards = [...this.cards, ...newCards];
        this.page += 1;
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    }, 100),
    // 模拟获取数据的方法
    fetchCards(page, pageSize) {
      // 这里应该是一个 API 请求，返回分页数据
      // 以下是模拟数据
      return Array.from({ length: pageSize }, (_, index) => ({
        id: (page - 1) * pageSize + index + 1,
        title: `卡片标题 ${(page - 1) * pageSize + index + 1}`,
        desc: '描述信息',
        tags: ['标签1', '标签2'],
        // 其他卡片数据
      }));
    },
    handleSearch() {
      this.addHistory(this.searchQuery);
      this.loadMoreCards();
    },
    highlightSearch(text, searchString) {
      if (!searchString) {
        return text;
      }
      const searchRegex = new RegExp(searchString, 'gi');
      const highlightedText = text.replace(
        searchRegex,
        (match) => `<span class="highlight">${match}</span>`,
      );
      return highlightedText;
    },
    async handleChange({ key, value, data }) {
      this.formValue[key] = value;
      this.formData[key] = data;
      await this.reset();
      this.loadMoreCards();
    },
  },
};
