import {
  BannerList,
  BannerInsert,
  GetBannerByID,
  GetBannerListByID,
  BannerUpdate,
  BannerOnLine,
  BannerDelete,
} from '@/graphql/gql/banner';
import { calVariables } from '@/graphql/util';
import { DataService } from 'nges-common/src/web/utils/api';

export function queryBannerList(data) {
  return DataService({
    query: calVariables(BannerList, data),
  });
}

// 新建运营位
export function queryBannerBannerInsert(data) {
  return DataService({
    query: calVariables(BannerInsert, data),
  });
}

// 根据id获取运营位信息
export function queryGetBannerByID(data) {
  return DataService({
    query: calVariables(GetBannerByID, data),
  });
}
// 更新运营位
export function queryBannerUpdate(data) {
  return DataService({
    query: calVariables(BannerUpdate, data),
  });
}
// 运营位上/下线1-下线，2-上线
export function queryBannerOnLine(data) {
  return DataService({
    query: calVariables(BannerOnLine, data),
  });
}
// 删除运营位
export function queryBannerDelete(data) {
  return DataService({
    query: calVariables(BannerDelete, data),
  });
}

// 根据id获取运营位数组
export function queryBannerListByID(data) {
  return DataService({
    query: calVariables(GetBannerListByID, data),
  });
}
