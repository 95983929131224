//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cloneDeep, isEqual } from 'lodash';
import { routes } from '@/router';
import { getQueryObj, getRouteName } from '@/libs/utils/util';
import highLightWord from 'nges-common/src/web/components/layout/list/highLightWord.vue';
import { getPageConfig } from 'nges-common/src/web/utils/common';
export default {
  name: 'MenuSearch',
  components: {
    highLightWord,
  },
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    routeList: {
      type: Array,
      default: () => [],
    },
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      restaurants: [],
      inputValue: '',
      noData: false,
      menuSearch: true,
      isShowTooltip: true,
    };
  },
  computed: {},
  mounted() {
    // this.initKv();
    const menuList = cloneDeep(this.menuList);
    this.restaurants = this.buildMenuPaths(menuList);
  },
  methods: {
    async initKv() {
      try {
        const menuSearchConfig = await getPageConfig('web_menu_search_config', 'object');
        this.menuSearch = menuSearchConfig?.open || false;
      } catch (e) {
        console.log(e);
      }
    },
    buildMenuPaths(menuArray, parentPath = '') {
      let result = [];
      menuArray.forEach((menu) => {
        const currentPath = parentPath ? `${parentPath} / ${menu.menu_name}` : menu.menu_name;
        if (menu?.children?.length) {
          result = result.concat(this.buildMenuPaths(menu.children, currentPath));
        } else {
          result.push({
            ...menu,
            value: menu.menu_name,
            parentName: currentPath,
          });
        }
      });
      return result;
    },
    querySearch(queryString, cb) {
      const { restaurants } = this;
      this.noData = false;
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      if (!results.length) {
        this.noData = true;
        results = [{ value: '' }];
      }
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant?.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0;
      };
    },
    isPath(path) {
      if (!path?.includes('/saas/')) return false;
      return (
        routes.filter((item) => {
          return path.includes(item.path);
        }).length > 0
      );
    },
    handleSelectClick(item) {
      if (!item?.value) return;
      const nowRoute = {
        name: this.$route.name,
        query: this.$route.query || {},
      };
      const aimRoute = {
        name: '',
        query: {},
      };
      this.inputValue = '';
      if (item.type === 'external') {
        if (item.open_type !== 'iframe') {
          window.open(item.menu_path, item.open_type === 'inside' ? '_self' : '_blank');
          return;
        }
        aimRoute.name = 'iframe';
        aimRoute.query = { iframe_src: item.menu_path };
      } else if (this.routeList.includes(item.menu_path)) {
        aimRoute.name = item.menu_path;
      } else if (this.isPath(item.menu_path)) {
        aimRoute.name = getRouteName(item.menu_path);
        aimRoute.query = getQueryObj(item.menu_path);
      } else if (item.menu_path.includes('custom_')) {
        aimRoute.name = 'custom';
        aimRoute.query = { custom: item.menu_path };
      } else {
        window.open(item.menu_path, '_blank');
        return;
      }
      if (!isEqual(nowRoute, aimRoute)) {
        this.$router.push(aimRoute);
        this.$emit('jump', aimRoute);
      }
    },
    collapseMenu() {
      this.$emit('collapse');
    },
  },
};
