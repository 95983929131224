//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    changeRecord: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
