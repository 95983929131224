//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    tagData: {
      type: Array,
      default: () => [],
      require: true,
    },
    otherTag: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      props: {
        multiple: true,
        checkStrictly: true,
        expandTrigger: 'hover',
        value: 'id',
        label: 'name',
      },
      options: [],
      originOptions: [],
      selectedTags: [],
      checkedLabels: [],
    };
  },
  created() {
    this.getCascaderOptions();
  },
  methods: {
    initCascaderChecked() {
      if (this.otherTag) {
        const res = [];
        const currentTags = this.otherTag.filter(
          (item) => !!this.originOptions.find((o) => o.id === item.id),
        );
        currentTags.forEach((item) => {
          res.push(this.getCascaderValue(item.id, []));
        });
        this.selectedTags = [...res];
        this.$nextTick(() => {
          this.showTags();
        });
      }
    },
    getCascaderValue(id, arr) {
      const tag = this.originOptions.find((item) => item.id === id);
      if (tag) {
        arr.unshift(tag.id);
      }
      if (tag && tag.parent_id) {
        return this.getCascaderValue(tag.parent_id, arr);
      }
      return arr;
    },
    doSelectTag() {
      console.log('级联选择标签: ', this.selectedTags);
      const res = [];
      this.selectedTags.forEach((item) => {
        res.push(item[item.length - 1]);
      });
      console.log('cascaderTag组件$emit发送: ', res);
      this.$nextTick(() => {
        this.showTags();
      });
      this.$emit('checkedChange', res);
    },
    showTags() {
      const checkedNode = this.$refs.cascader.getCheckedNodes();
      const labels = [];
      checkedNode.forEach((item) => {
        labels.push(item.pathLabels.join('/'));
      });
      this.checkedLabels = labels;
    },
    async getCascaderOptions() {
      if (this.tagData && this.tagData.length) {
        this.originOptions = [...this.tagData];
        this.setTagOptions(this.tagData);
        this.options = this.setDisabledStatus(this.options);
      }
      this.initCascaderChecked();
    },
    async setTagOptions() {
      let level1 = [];
      let level2 = [];
      let level3 = [];
      let level4 = [];
      let level5 = [];
      let level6 = [];
      let level7 = [];
      let level8 = [];
      let level9 = [];
      const level10 = [];
      this.tagData.forEach((i) => {
        const ni = {
          value: i.id,
          label: i.name,
          ...i,
        };
        if (i.ninth_parent_id) {
          level10.push(ni);
        } else if (!i.ninth_parent_id && i.eighth_parent_id) {
          level9.push(ni); // 有8标签无9标签，则为9级标签
        } else if (!i.eighth_parent_id && i.seventh_parent_id) {
          level8.push(ni); // 有7标签无8标签，则为8级标签
        } else if (!i.seventh_parent_id && i.sixth_parent_id) {
          level7.push(ni);
        } else if (!i.sixth_parent_id && i.fifth_parent_id) {
          level6.push(ni);
        } else if (!i.fifth_parent_id && i.fourth_parent_id) {
          level5.push(ni);
        } else if (!i.fourth_parent_id && i.third_parent_id) {
          level4.push(ni);
        } else if (!i.third_parent_id && i.grand_id) {
          level3.push(ni);
        } else if (!i.grand_id && i.parent_id) {
          level2.push(ni); // 有父标签无爷标签，则为2级标签
        } else {
          level1.push(ni); // 其他为1级标签
        }
      });
      level9 = this.setTagStruct(level9, level10);
      level8 = this.setTagStruct(level8, level9);
      level7 = this.setTagStruct(level7, level8);
      level6 = this.setTagStruct(level6, level7);
      level5 = this.setTagStruct(level5, level6);
      level4 = this.setTagStruct(level4, level5);
      level3 = this.setTagStruct(level3, level4);
      level2 = this.setTagStruct(level2, level3);
      level1 = this.setTagStruct(level1, level2);

      // 清空1级目录下空内容
      level1 = level1.filter((i) => !!i);
      this.options = level1;
    },
    setTagStruct(parentLevel, childrenLevel) {
      // 将 childrenLevel 标签存入 parentLevel 中
      return parentLevel.map((l) => {
        const children = [];
        childrenLevel.forEach((c) => {
          if (c.parent_id === l.id) {
            children.push(c);
          }
        });
        return children.length ? { ...l, children } : l;
      });
    },

    setDisabledStatus(arrData) {
      if (arrData && arrData.length) {
        arrData.forEach((item) => {
          item.disabled = item.status === '1';
          if (item.children) {
            item.children = this.setDisabledStatus(item.children);
          }
        });
      }
      return arrData;
    },
  },
};
