export const MeetingQuery = `
query {
  events_meeting_config (_order_by:{update_time:_desc}, _limit:1,_offset:0) {
    id
    under_represented_time
    under_represented_num
    advance_remind
    short_time_remind
    show_type
    max_attendance
    min_attendance_count
    min_attendance_duration
    create_switch
    approval_switch
    tm_app_id
    tm_sdk_id
    tm_secret_id
    tm_secret_key
    mini_app_id
    tm_oauth_token
    tm_oauth_secret
    tm_oauth_sdk_id
    update_limit_time
    update_switch
  }
}`;

export const UpdateMeeting = `
mutation update {
  events_meeting_config (_where: {id: $id},
  _set: {
    under_represented_time: $under_represented_time,
    under_represented_num: $under_represented_num,
    advance_remind: $advance_remind,
    short_time_remind: $short_time_remind,
    show_type: $show_type,
    max_attendance: $max_attendance,
    min_attendance_count: $min_attendance_count,
    create_switch: $create_switch,
    approval_switch: $approval_switch,
    tm_app_id: $tm_app_id,
    tm_sdk_id: $tm_sdk_id,
    tm_secret_id: $tm_secret_id,
    tm_secret_key: $tm_secret_key,
    mini_app_id: $mini_app_id,
    tm_oauth_token: $tm_oauth_token,
    tm_oauth_secret: $tm_oauth_secret,
    tm_oauth_sdk_id: $tm_oauth_sdk_id,
    update_switch: $update_switch,
    update_limit_time: $update_limit_time,
  }) {
    id
  }
}`;

export const MeetingQueryKV = `
query {
  kv (key: {_in:[
    "under_represented_time",
    "under_represented_num",
    "advance_remind",
    "product_show_type",
    "short_time_remind",
    "show_type",
    "max_attendance",
    "min_attendance_count",
    "min_attendance_duration",
    "create_switch",
    "approval_switch",
    "tm_app_id",
    "tm_sdk_id",
    "tm_secret_id",
    "tm_secret_key",
    "mini_app_id",
    "tm_oauth_token",
    "tm_oauth_secret",
    "tm_oauth_sdk_id",
    "update_limit_time",
    "update_switch"
  ]}) {
    value
    key
  }
}`;

export const UpdateMeetingKV = `
mutation update {
  config1: kv (_where: {key: under_represented_time}, _set: { value: $under_represented_time })
  config2: kv (_where: {key: under_represented_num}, _set: { value: $under_represented_num })
  config3: kv (_where: {key: advance_remind}, _set: { value: $advance_remind })
  config5: kv (_where: {key: short_time_remind}, _set: { value: $short_time_remind })
  config6: kv (_where: {key: show_type}, _set: { value: $show_type })
  config7: kv (_where: {key: max_attendance}, _set: { value: $max_attendance })
  config8: kv (_where: {key: min_attendance_count}, _set: { value: $min_attendance_count })
  config10: kv (_where: {key: create_switch}, _set: { value: $create_switch })
  config11: kv (_where: {key: approval_switch}, _set: { value: $approval_switch })
  config12: kv (_where: {key: tm_app_id}, _set: { value: $tm_app_id })
  config13: kv (_where: {key: tm_sdk_id}, _set: { value: $tm_sdk_id })
  config14: kv (_where: {key: tm_secret_id}, _set: { value: $tm_secret_id })
  config15: kv (_where: {key: tm_secret_key}, _set: { value: $tm_secret_key })
  config16: kv (_where: {key: mini_app_id}, _set: { value: $mini_app_id })
  config17: kv (_where: {key: tm_oauth_token}, _set: { value: $tm_oauth_token })
  config18: kv (_where: {key: tm_oauth_secret}, _set: { value: $tm_oauth_secret })
  config19: kv (_where: {key: tm_oauth_sdk_id}, _set: { value: $tm_oauth_sdk_id })
  config20: kv (_where: {key: update_limit_time}, _set: { value: $update_limit_time })
  config21: kv (_where: {key: update_switch}, _set: { value: $update_switch })
}`;

export const StatisticsMeetingKv = `
query{events_meeting_key_data(	meeting_date:{_gte:$_gte},
  meeting_date:{_lte:$_lte}
){meeting_speaker_count
  meeting_person_count
  meeting_date
  meeting_count
  meeting_online_count
  meeting_type_id
  main_person_count
}}`;

export const InsertMeeting = `
mutation insert {
	events_meeting (_values: {
			meeting_name: "测试会议3",
			meeting_stime:"2021-12-17 15:30:00",
			meeting_etime:"2021-12-17 16:30:00",
			sponsor_uin:"1"}
		) {
			product_id
      department_id
			attender_id
    }
}`;

export const QueryMeeting = `
query {
	meeting (id:$id) {
		id
		meeting_name
		meeting_stime
		meeting_etime
		address
		meeting_type_id
		expected_attendance
		meeting_status
		meeting_code
		product_id {
			name
		}
		department_id{
			name
		}
		sponsor_custom_uin {
			name
			uin
			open_id
		}
	}
}`;

export const MeetingProvince = `
query {
  province {
    id
    code
    name
  }
}`;

export const MeetingCity = `
query {
  city (_where:{
    province_code:$province_id}
  ){
    id
    code
    name
    province_code
  }
}`;

export const MeetingProduct = `
query {
  product_tag {
    id
    name
  }
  product {
    id
    name
  }
}`;

export const meetingConfig = `
query {
  events_meeting_config {
    product_show_type
  }
}`;

export const MeetingBu = `
query {
  bu_tag {
    id
    name
  }
}`;

// _where:{update_time:$update_time},
export const MeetingDetailList = `
query { events_meeting(_where:{
  update_time:{_gte:$_gte},
  update_time:{_lte:$_lte},
  },
  _order_by:{update_time:_desc},
  _limit:$_limit,_offset:$_offset),
  {
    id
    meeting_name
    meeting_stime
    meeting_type_id
    update_time
    product{name}
    join_num
    meeting_duration_time
    meeting_status
    is_violate
    sponsor_custom_uin{name}
    attender_id{
      hcp{name}
    }
  }
}`;
export const MeetingDetailCount = `
query { events_meeting(_where:{update_time:{_gte:$_gte}, update_time:{_lte:$_lte}})
  {
    _aggregate{_count}
  }
}`;
export const GetMeetingAttenderIdByName = `query{
  events_meeting_attender(
    _where:{hcp.name: {_like: $name},
    is_speaker: 1}
  ) {
    meeting_id
    hcp_id
    is_enter
    total_duration
    show_total_duration
    is_speaker
    in_meeting_times
    is_snap
    enter_time
    left_time
    is_proper
    hcp{
      id
      hco {
        name
      }
    }
  }
}`;

export function StatisticsMeetingAttenders(data) {
  return `query {
    events_meeting_attender(
      _where:{meeting_id: $meeting_id, ${
        data.hcp_name ? '_or: [{hcp.name: {_like: $hcp_name}}]' : ''
      }}
      _order_by:{hcp.pinyin: _asc}
      _limit:$pageSize,_offset:$pageIndex
    ) {
      hcp_id
      is_enter
      total_duration
      show_total_duration
      is_speaker
      in_meeting_times
      is_snap
      enter_time
      left_time
      is_proper
      hcp{
        id
        pinyin
        name
        hcp_grade
        hco_name
        department{name}
        major_title{name}
      }
      _aggregate{ _count}
    }
  }`;
}

export const getProductShowTypeQuery = 'query {events_meeting_config{product_show_type}}';
export const GetApprovalDcrDetailQuery = `query {dcr(_where: {id: $id}){ id ,type, dcr_content, dcr_field{field, old_show, new_show, new_value, old_value}}}`;
export const GetDoctorDetailQuery = `query { grade(_where: {id: $id}) {id, hcp{name}, product{name}, level, support, potential } }`;
export function GetApprovalDetailQuery({ tableName }) {
  return `query {
    ${tableName}(_where: {id: $id}) {
      id
      meeting_name
      meeting_stime
      meeting_etime
      meeting_is_private
      attender_id{
        is_speaker
        hcp{
          name
        }
      }
      type
      address
      plan_cost
      budget{
        proportion
        budget{
          name
          available_amount
        }
      }
      code
      expected_attendance
      product{
        name
      }
      hcp_product{
        name
      }
      department{
        name
      }
      vote{
        name
      }
      article{
        title
      }
    }
  }`;
}

// 获取多个 kv 值
export const KvConfigs = `
query {
  kv (key:{ _in: $keys}) {
    value
    key
  }
}`;

export const SetMeetingStatus = `mutation update {
  events_meeting (
    _where: {
      id: $meeting_id,
    },
    _set: {
      meeting_status: $meeting_status,
    }
  )
}`;
export const SetMeetingOwner = `mutation update {
  events_meeting (
    _where: {
      id: $meeting_id,
    },
    _set: {
      owner: $owner,
    }
  )
}`;
export const getCostsQuery = `
query {
  events_budget_type(status: 0){id, name}
}`;

export const meetingCostQuery = `
query {
  events_meeting(id: $id){
    meeting_cost{budget_type_id, cost, type,budget_type{name}}
  }
}
`;
export const conformRealCostQuery = `mutation update {
  events_meeting(
    _where: {id: $meeting_id}
    _set: {real_cost: $real_cost}
  ) {
    meeting_cost(
      _values: $meeting_cost
    )
  }
}`;
export const confirmSpeakerQuery = `mutation update {
  events_meeting_attender(
    _where: {id: $id},
    _set: {
      cost_status: $cost_status,
      real_cost: $real_cost
    }
  )
  events_meeting(
    _where: {id: $meeting_id},
    _set: {
      real_cost: $total_real_cost
    }
  )
}`;
export const cancelInspectionQuery = `
  mutation update {
	  events_unannounced_inspection(_where:{meeting_id: $meeting_id},_set:{status:"3"})
  }
`;
export const meetingDocPlayRecord = `query {
    user_behavior_details(_where: {source: {_in:["4","5","6"]}, source_id: $source_id, target_content_id: $target_content_id}) {
      id
      behavior_value
      user_name
      onload_trace_id
      ext
    }
  }`;

export const meetingDocPlayRecordForAZ = `query {
    user_behavior_details(_where: {source: {_in:["4","5","6"]}, source_id: $source_id, target_content_id: $target_content_id}) {
      id
      behavior_value
      user_name
      onload_trace_id
      ext
      meeting_material_play {
        speaker{
          name
        }
      }
    }
  }`;

export const meetingDocPlayEventsByOnloadIds = `query {
  material_play_events(_where: {onload_trace_id: {_in: $onloadIds}}) {
    material_page
    duration
  }
}`;

export const QueryHcpPersonalInfo = `
query {
  events_user_info(_where: {hcp_id: $hcp_id}) {
    _aggregate {
      _count
    }

  }
}
`;
export const QueryUserPersonalInfo = `
query {
  events_user_info(_where: {user_id: $user_id}) {
    _aggregate {
      _count
    }

  }
}
`;
export const QuerySpeakerPersonalInfo = `
query {
  events_user_info(_where: {speaker_id: $speaker_id}) {
    _aggregate {
      _count
    }
  }
}
`;
export const QueryHcpSpeakerPersonalInfo = `
query {
  events_user_info(_where: {hcp_id: $hcp_id}) {
    _aggregate {
      _count
    }
  }
}
`;
export const saveHotelQuery = `
mutation update {
  events_meeting_attender(_where: {id: $attender_id},_set: {
    outbound_from_city: $outbound_from_city,
    outbound_to_city: $outbound_to_city,
    outbound_date: $outbound_date,
    outbound_transportation: $outbound_transportation,
    outbound_number: $outbound_number,
    inbound_from_city: $inbound_from_city,
    inbound_to_city: $inbound_to_city,
    inbound_date: $inbound_date,
    inbound_number: $inbound_number,
    inbound_transportation: $inbound_transportation,
    hotel_name: $hotel_name,
    check_in_time: $check_in_time,
    check_out_time: $check_out_time,
    hotel_remark: $hotel_remark,
    other_info: $other_info,
  })
}
`;
export const queryOldIds = `
query {
  events_meeting(_where: {id: $id}) {
    attender_id(_where: {is_speaker: "0"}){name,hcp{id, name},is_speaker}
  }
}
`;
export const updateAttenderGql = `
events_meeting_attender(_where: { id: $id }, _set:   {
  outbound_from_city: $outbound_from_city,
  outbound_to_city: $outbound_to_city,
  outbound_date: $outbound_date,
  outbound_transportation: $outbound_transportation,
  outbound_number: $outbound_number,
  inbound_from_city: $inbound_from_city,
  inbound_to_city: $inbound_to_city,
  inbound_date: $inbound_date,
  inbound_number: $inbound_number,
  inbound_transportation: $inbound_transportation,
  hotel_name: $hotel_name,
  check_in_time: $check_in_time,
  check_out_time: $check_out_time,
  hotel_remark: $hotel_remark,
  other_info: $other_info,
})`;
export const insertAttenderGql = `
{
  meeting_id: $meeting_id,
  speaker_id: null,
  hcp_id: $hcp-id,
  name: $hcp-name,
  identity: 0,
  uin: $hcp-uin,
  is_speaker: 0,
  is_confirm: -1,
  cost: 0,
  real_cost: 0
}
`;
export const insertSnapDocsGql = `
{
  meeting_id: $meeting_id,
  document_id: $document_id,
  issue_id__c: $issue_id,
  is_snap: 1,
}
`;

export const deleteAttendersGql = `
mutation delete {
  events_meeting_attender(_where: {id: { _in: $ids }})
}`;

export function compositeInsertGql(gqls) {
  return `mutation insert {
    events_meeting_attender(_values: [${gqls.join(',')}])
  }`;
}

export function compositeUpdateGql(gqls) {
  let ret = '';
  gqls.forEach((item, index) => {
    if (index === 0) {
      ret += item;
    } else {
      ret += ` t${index}: ${item}`;
    }
  });
  return `mutation update {
      ${ret}
  }`;
}
export const queryMeetingTypesGql = function ({ objName, user_type__c: userTypeC }) {
  return `query{
    ${objName} (user_rel.user_type__c: "${userTypeC}",_order_by: {order__c: _asc}){  id
    name
    type
    order__c
    description
    parent_meeting_type {
      name
      type
    } }
  }`;
};

export const queryUserTypeGql = 'query{user_info(_where: {uin: $_uin}){user_type__c}}';

export function compositeInsertSnapDocsGql(gqls) {
  return `mutation insert {
    events_document_info(_values: [${gqls.join(',')}])
  }`;
}
export const queryFormValueSaveGql = `
mutation insert {
  events_meeting_change_record__c(_values: {
    meeting_id: $meeting_id,
    change_info: $change_info
  }){
    id
    meeting_id
  }
}
`;

export const queryBlacklistCityGql = 'query{blacklist_address__c{city_code}}';

export const queryTerritoryGql = 'query{territory( level:"BU HEAD", name:{_like:$bu_name}){ _all}}';

export const queryTerritoryChildGql = `query{
  territory(
    _where: {_and: [{parent_code: $parent_code}, {_or: [{level: {_neq: "REP"}}, {level: {_is_null: true}}]}]}
  ) {
    _all
  }
}`;
