import {
  scope,
  isExpression,
  newScope,
  isNewExpression,
  execExpressionNew,
} from './expressionUtil';
import { getValidExpression } from './expressionScript';
import config from 'nges-common/src/config';



let getLoginUserInfo = () => ({});
getLoginUserInfo = require('../web/utils/login').default.getUserInfoCache;



const expressionGlobalFuncKey = 'expressionGlobal';
if (!window[expressionGlobalFuncKey]) window[expressionGlobalFuncKey] = {};

let SYS_INFO = {};
function getSysInfo() {
  let envType;
  let envAppType;
  
  envType = window !== window.parent ? 'iframe' : 'browser';
  envAppType = 'others';
  
  SYS_INFO = {
    type: 'h5',
    env: {
      type: envType,
      app_type: envAppType,
    },
    sys_info: navigator.userAgent,
    domain: config.domain,
  };
}
getSysInfo();

/**
 * 自定义js表达式解析函数，可以传自定义参数，自定义表达式可以使用内置的工具函数，譬如lodash全局直接引用global._.XXX
 * @param {String} expressionStr 表达式字符串
 * @param {{ [key: Sting]: any }} paramsObj 表达式函数自定义入参数对象，会将对象中的每个属性依次传到表达式函数中
 * @param {Boolean} isReturnNow 是否马上运行
 * @example
 * // returns 'helloWorld!'
 * execExpression('{{ return t.a + p.b; }}', { t: { a: 'hello' }, p: { b: 'World!' } });
 * // returns 'helloWorld!1'
 * const func = execExpression(
 * '{{ return t.a + p.b + $index; }}',
 * { t: { a: 'hello' },
 * p: { b: 'World!' } }, false);
 * func({ $index: 1 });
 */
function execExpression(expressionStr, paramsObj = {}) {
  try {
    paramsObj = {
      ...paramsObj,
      u: getLoginUserInfo(), // 表达式中注入用户数据
      globalFunc: global[expressionGlobalFuncKey],
      $sys: SYS_INFO,
    };
    if (isNewExpression(expressionStr)) {
      expressionStr = expressionStr.substring(2, expressionStr.length - 2);
      return execExpressionNew(expressionStr, paramsObj, newScope);
    }
    if (isExpression(expressionStr)) {
      const validExpressionStr = getValidExpression(expressionStr);

      const paramsKeys = Object.keys(paramsObj);
      // eslint-disable-next-line no-new-func
      let execFunc = new Function(
        [].concat(paramsKeys).concat(Object.keys(scope)),
        validExpressionStr,
      );
      const paramsValues = Object.values(paramsObj);
      const result = execFunc(...[].concat(paramsValues).concat(Object.values(scope)));
      execFunc = null;
      return result;
    }
    return expressionStr;
  } catch (err) {
    console.error('expression str with error:', expressionStr);
    console.error('expression error:', err);
    return `${expressionStr}:${err.toString()}`;
  }
}

export { execExpression, expressionGlobalFuncKey };
