//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GetAllLabelInfoList } from '@/api/releaseManagement';
import { getHospitalDepartmentListApi } from '@/api/setUp';
import { GetProductInfoList } from '@/api/setUser';
import { GetProductList } from '@/graphql/api/other.js';

export default {
  name: 'LabelSelect',
  props: {
    labelWidth: {
      type: String,
      default: '',
    },
    classificationMode: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    // 最大可选标签数量（产品+自选，不含科室）
    labelAmount: {
      type: Number,
      default: 60,
    },
    // 是否禁用 (如查看详情不可编辑)
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showDepartments: {
      // 是否显示科室
      type: Boolean,
      default: true,
    },
    showOtherTags: {
      // 是否显示科室
      type: Boolean,
      default: true,
    },
    hiddenCustomLabel: {
      type: Boolean,
      default: false,
    },
    // 展示的标签内容
    showLabels: {
      type: String,
      default: '标签,科室',
    },
  },
  data() {
    return {
      products: [], // 产品回显
      departments: [], // 科室回显
      productList: [], // 产品列表 for doctor classification
      productTagList: [], // 产品列表
      departmentList: [], //  科室列表
      labelList: [], // 标签列表
      labelCheckList: [], // 选择的标签
      dialogLabelManageVisible: false, // 标签管理的dialog
      loading: false,
      SelectProducts: [],
      SelectedProduct: '',
      SelectDepartments: [],
      isComplete: false,
      showLabelsArr: this.showLabels.split(','),
    };
  },
  computed: {
    showAddTag() {
      return (labelList, idx) => {
        if (idx === -1) {
          const show = labelList.every((item) => {
            return !item.show;
          });
          return show;
        }
        let lastIndex;
        labelList.forEach((item, i) => {
          if (item.show) {
            lastIndex = i;
          }
        });
        return lastIndex === idx;
      };
    },
    showLabel() {
      return (label) => {
        return label.show;
      };
    },
    allComplete() {
      const { tags, isComplete } = this;
      return {
        tags,
        isComplete,
      };
    },
  },
  watch: {
    allComplete: {
      handler(allComplete) {
        if (allComplete.isComplete && allComplete.tags) {
          if (this.classificationMode) {
            this.setSelectedProduct(allComplete.tags[0].TagId);
          } else {
            this.setSelect(allComplete.tags);
          }
        }
      },
    },
  },
  created() {
    this.loading = true;
    Promise.all([
      GetAllLabelInfoList(),
      getHospitalDepartmentListApi(),
      GetProductList(),
      GetProductInfoList(),
    ])
      .then(([res1, res2, res3, res4]) => {
        this.labelList = res1.tag_category || [];
        this.labelList.forEach((item) => {
          item.show = false;
          item.LabelList = item.tag || [];
        });
        console.log('标签列表', this.labelList);
        this.departmentList = res2.department;
        console.log('科室列表', this.departmentList);
        this.productList = res3.product;
        console.log('产品列表', this.productList);
        this.productTagList = res4.product_tag._data;
        console.log('产品tag列表', this.productTagList);
        this.isComplete = true;
        this.loading = false;
        // 标签获取完毕让父组件开始加载
        this.$emit('tagComplete');
      })
      .catch((e) => {
        console.log(e);
        this.loading = false;
        this.$message('查询失败');
      });
  },
  mounted() {},
  methods: {
    clear() {
      this.SelectProducts = [];
      this.SelectedProduct = '';
    },
    setSelectedProduct(payload) {
      this.SelectedProduct = payload;
    },
    setSelect(tags) {
      console.log('selected tags', tags);
      this.loading = true;
      let allProductIds;
      if (this.classificationMode) {
        allProductIds = this.productList?.map((temp) => temp.id) || [];
      } else {
        allProductIds = this.productTagList?.map((temp) => temp.id) || [];
      }
      const allDepartmentIds = this.departmentList?.map((temp) => temp.id) || [];
      for (const tag of tags) {
        if (tag.TagType === 1) {
          if (allProductIds.includes(tag.TagId)) this.products.push(tag.TagId);
        } else if (tag.TagType === 2) {
          if (allDepartmentIds.includes(String(tag.TagId)))
            this.departments.push(String(tag.TagId));
        } else if (tag.TagType === 3) {
          for (const label of this.labelList) {
            const valueList = label.LabelList?.map((temp) => temp.id) || [];
            if (valueList.includes(tag.TagId)) {
              label.show = true;
              this.labelCheckList.push(label.label_class_name);
              this.$nextTick(() => {
                label.selectValue.push(tag.TagId);
              });
              break;
            }
          }
        }
      }
      this.SelectProducts = this.products;
      this.SelectDepartments = this.departments;
      this.loading = false;
    },
    handleChangeLabel() {
      for (const label of this.labelList) {
        label.show = this.labelCheckList.includes(label.label_class_name);
        if (!label.show) {
          label.selectValue.splice(0, this.labelAmount);
        }
      }
      this.$forceUpdate();
      this.dialogLabelManageVisible = false;
    },
    removeLabel(label) {
      this.$set(label, 'show', false);
      label.selectValue.splice(0, this.labelAmount);
      this.labelCheckList.splice(this.labelCheckList.indexOf(label.label_class_name), 1, 0);
    },
    change(obj) {
      console.log(obj);
      if (this.classificationMode) {
        this.$emit('productAlert', obj && obj.length ? 1 : 0);
        return;
      }
      // 标签数量=产品+自选，不含科室
      let amount = this.SelectProducts.length;
      for (const label of this.labelList) {
        if (label.show) {
          amount += label.selectValue.length;
        }
      }
      if (amount > this.labelAmount) {
        obj.pop();
        this.$message.warning(`标签数量不能多于${this.labelAmount}个`);
      }
      this.$emit('change', this.commitTags());
    },
    // 供外界查询是否已经填了产品
    hasNoProduct() {
      if (this.classificationMode) {
        return !this.SelectedProduct?.length;
      }
      // 如果没有配置展示标签字段，那么校验返回 false
      if (!this.showLabelsArr.includes('标签')) {
        return false;
      }
      return this.SelectProducts.length === 0;
    },
    commitTags() {
      const valueList = [];
      // 产品标签
      if (this.classificationMode) {
        valueList.push({
          TagId: this.SelectedProduct,
          TagType: 1,
        });
      } else {
        for (const product of this.SelectProducts) {
          valueList.push({
            TagId: product,
            TagType: 1,
          });
        }
      }
      // 科室标签
      for (const department of this.SelectDepartments) {
        valueList.push({
          TagId: department,
          TagType: 2,
        });
      }
      // 动态标签
      for (const label of this.labelList) {
        if (label.show) {
          for (const value of label.selectValue) {
            valueList.push({
              TagId: value,
              TagType: 3,
            });
          }
        }
      }
      return valueList;
    },
  },
};
