//
//
//
//
//
//
//
//

import { SelectOnlineComp } from './components';
export default {
  name: 'NameCompType',
  components: {
    SelectOnlineComp,
  },
  props: {
    // 标题
    title: {
      type: String,
      default: '',
    },
    // 组件类型
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    childEmit(e) {
      this.$emit('nameCompTypeEmit', e);
    },
  },
};
