import { GetObjectsByNames } from '@/api/restful';
import { getKvConfigs } from '@/graphql/api/meeting';

export default {
  namespaced: true,
  getters: {
    getApprovalTypeList(state) {
      return state.approvalTypeList;
    },
    getApprovalTypeMapping(state) {
      return state.approvalTypeMapping;
    },
    getApprovalTabIndex(state) {
      return state.approvalTabIndex;
    },
    getApprovalProcessTabIndex(state) {
      return state.approvalProcessTabIndex;
    },
    getApprovalStatusMapping(state) {
      return state.approvalStatusMapping;
    },
  },
  state: {
    approvalTypeList: [],
    approvalTypeMapping: {},
    approvalStatusMapping: {},
    approvalTabIndex: '0',
    approvalProcessTabIndex: '0',
  },
  mutations: {
    setApprovalTypeList(state, data) {
      state.approvalTypeList = data;
    },
    setApprovalTypeMapping(state, data) {
      state.approvalTypeMapping = data;
    },
    setApprovalTabIndex(state, data) {
      state.approvalTabIndex = data;
    },
    setApprovalProcessTabIndex(state, data) {
      state.approvalProcessTabIndex = data;
    },
    setApprovalStatusMapping(state, data) {
      state.approvalStatusMapping = data;
    },
  },
  actions: {
    async requestApprovalTypeList(context) {
      if (context.state.approvalTypeList.length > 0) return;

      const {
        list: [data],
      } = await GetObjectsByNames({
        objects: ['approval_applies'],
      });
      const result = data.fields.filter((i) => i.name === 'type')[0].select_one_option.options;
      context.commit('setApprovalTypeList', result);
    },
    async getApprovalTypeMapping({ state, commit }) {
      if (Object.keys(state.approvalTypeMapping).length) return;
      try {
        const res = (await getKvConfigs({ keys: ['approval_type_mapping'] })).kv[0].value;
        const approvalTypeMapping = JSON.parse(res);
        console.log('approvalTypeMapping==', approvalTypeMapping);
        if (Object.keys(approvalTypeMapping).length)
          commit('setApprovalTypeMapping', approvalTypeMapping);
      } catch (err) {
        console.error(err);
      }
    },
    async requestApprovalStatusMapping({ state, commit }) {
      if (Object.keys(state.approvalStatusMapping).length) return;
      try {
        const res = (await getKvConfigs({ keys: ['approval_status_mapping'] })).kv.find(
          (item) => item.key === 'approval_status_mapping',
        ).value;
        commit('setApprovalStatusMapping', JSON.parse(res));
      } catch (err) {
        console.error('未配置kv表approval_status_mapping', err);
      }
    },
  },
};
