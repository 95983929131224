//
//
//
//
//
//
//
//
//
//

import { GetObjectsByNames, GetKvConfigs } from '../../../../../utils/api';
import nodeDetail from './node-detail.vue';
import approvalSingle from './approval-single.vue';
export default {
  name: '',
  components: {
    nodeDetail,
    approvalSingle,
  },
  data() {
    return {
      approvalTypeList: [], // 从原数据拿到审批类型enum
      approvalTypeMapping: {}, // kv表中拿到审批类型对应的
      approvalNodesList: [], // 可退回审批节点
    };
  },
  computed: {},
  mounted() {
    this.requestApprovalTypeList();
    this.requestApprovalTypeMapping();
  },
  methods: {
    async APPROVE_NODE_DETAIL(params) {
      await this.requestApprovalTypeList();
      this.$refs.nodeDetail.init(params);
    },
    async APPROVE_AGREE(params) {
      await this.requestApprovalTypeMapping();
      this.$refs.approvalSingle.openAgreeDialog(params);
    },
    async APPROVE_REJECT(params) {
      await this.requestApprovalTypeMapping();
      this.$refs.approvalSingle.openRejectDialog(params);
    },
    async APPROVE_REFUND(params) {
      await this.requestApprovalTypeMapping();
      this.$refs.approvalSingle.openRefundDialog(params);
    },
    async requestApprovalTypeList() {
      if (this.approvalTypeList.length) return;
      try {
        const {
          list: [data],
        } = await GetObjectsByNames({
          objects: ['approval_applies'],
        });
        const result = data.fields.filter((i) => i.name === 'type')[0].select_one_option.options;
        this.approvalTypeList = result;
      } catch (err) {
        console.error('获取审批类型枚举值失败', err);
      }
    },
    async requestApprovalTypeMapping() {
      if (Object.keys(this.approvalTypeMapping).length) return;
      try {
        const { kv } = await GetKvConfigs(`["approval_type_mapping"]`);
        let approvalTypeMap = kv.find((item) => item.key === 'approval_type_mapping')?.value;
        approvalTypeMap = JSON.parse(approvalTypeMap);
        if (Object.keys(approvalTypeMap).length) {
          this.approvalTypeMapping = approvalTypeMap;
        }
      } catch (err) {
        console.error('approvalTypeMapping获取失败', err);
      }
    },
    async requsetApprovalNodesList() {},
  },
};
