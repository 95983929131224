//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getNearHospital } from '@/components/tencent-map/index';
export default {
  props: {
    // 目标位置坐标
    targetLocation: {
      type: Object,
      default: () => {},
    },
    // 选择框提示文案
    placeholder: {
      type: String,
      default: '请选择',
    },
    // 周边选点禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 展示文案
    checkedText: {
      type: String,
      default: 'title',
    },
    // 切换选项回调
    onChange: {
      type: Function,
      default: () => {},
    },
    // 切换选项回调
    onVisibleChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      checkinData: '',
      roundHospital: [],
    };
  },
  watch: {
    async targetLocation() {
      await this.mapShowPosition();
    },
  },
  methods: {
    async mapShowPosition() {
      const res = await getNearHospital(this.targetLocation.lat, this.targetLocation.lng);
      this.getHospitalAddr(res);
    },
    getHospitalAddr(res) {
      this.roundHospital = res.data;
      console.log(this.roundHospital);
    },
    async handleVisibleChange(flag) {
      if (flag) {
        await this.onVisibleChange();
        await this.mapShowPosition();
      }
    },
    handleChange(id) {
      this.onChange(id, this.roundHospital);
    },
  },
};
