//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    textList: {
      type: Array,
      default: () => [{ text: '默认' }],
      required: true,
    },
    otherRadioIndex: {
      type: Number,
      default: -1,
    },
    otherInputPlaceholder: {
      type: String,
      default: '请输入',
    },
    otherInputMaxLen: {
      type: Number,
      default: 0,
    },
    // 是否需要自定义弹窗按钮
    needSlotFooter: {
      type: Boolean,
      default: false,
    },
    // 取消按钮文案
    cancelButtonText: {
      type: String,
      default: '取消',
    },
    // 确定按钮文案
    confirmButtonText: {
      type: String,
      default: '确定',
    },
    // 确定回调
    onConfirm: {
      type: Function,
      default: () => {},
    },
    // 取消回调
    onCancel: {
      type: Function,
      default: () => {},
    },
    validateEventOpen: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      radioIndex: -1,
      otherReason: '',
    };
  },
  methods: {
    onCancelButton() {
      this.onCancel({
        textList: this.textList || [],
        selectedIndex: this.radioIndex,
        selectedData: this.radioIndex >= 0 ? this.textList[this.radioIndex] : {},
        otherText: this.otherReason,
      });
    },
    onConfirmButton() {
      this.onConfirm({
        textList: this.textList || [],
        selectedIndex: this.radioIndex,
        selectedData: this.radioIndex >= 0 ? this.textList[this.radioIndex] : {},
        otherText: this.otherReason,
      });
    },
    resetData() {
      this.radioIndex = -1;
      this.otherReason = '';
    },
  },
};
