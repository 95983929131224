export default {
  namespaced: true,
  state: {
    questionResult: {},
  },
  getters: {
    getQuestionResult(state) {
      return state.questionResult;
    },
  },
  mutations: {
    // 设置问卷答案
    setQuestionResult(state, data) {
      state.questionResult = data;
    },
    // 清空问卷答案
    cleanQuestionResult(state) {
      state.questionResult = {};
    },
  },
  actions: {
    async mergeQuestionResult({ state, commit }, newData) {
      const mergedData = {
        ...state.questionResult,
        ...newData,
      };
      commit('setQuestionResult', mergedData);
    },
  },
};
