import request from '../../utils/request';
// 提交标准申请action
export function applyAction(action) {
  return new Promise((resolve, reject) => {
    const { action_params: params = {} } = action;

    const {
      // 数据id
      id = '',
      // 表名
      // eslint-disable-next-line camelcase
      object_name = '',
      // 流程key
      // eslint-disable-next-line camelcase
      process_key = '',
      variables,
    } = params;

    const data = {
      id,
      object_name,
      process_key,
      variables,
    };
    request('Workflow/ApprovalService/StartProcessInsByObject', data)
      .then((res) => {
        // 拿接口返回的process_ins
        resolve({ id, process_ins: res?.process_ins || '' });
      })
      .catch((e) => {
        console.error(e);
        setTimeout(() => {
          this.$message.error(e.message || '请求action失败');
        });
        reject({});
      });
  });
}
// 发起审批申诉action
export function appealAction(action) {
  return new Promise((resolve, reject) => {
    const { action_params: params = {} } = action;

    const {
      // 数据id
      id = '',
      // 表名
      // eslint-disable-next-line camelcase
      object_name = '',
      // 流程id
      // eslint-disable-next-line camelcase
      process_ins = '',
    } = params;

    const data = {
      // eslint-disable-next-line camelcase
      id: process_ins,
      // eslint-disable-next-line camelcase
      business_key: `${object_name}:${id}`,
    };
    request('Workflow/ApprovalService/AppealApply', data)
      .then(() => {
        resolve({ id });
      })
      .catch((e) => {
        console.error(e);
        setTimeout(() => {
          this.$message.error(e.message || '请求action失败');
        });
        reject({});
      });
  });
}
export default {
  methods: {
    // 查看节点详情
    APPROVE_NODE_DETAIL_EXEC() {
      return true;
    },
    APPROVE_NODE_DETAIL_CUSTOM_ACTION(params) {
      this.$refs?.approvalCenter?.APPROVE_NODE_DETAIL &&
        this.$refs.approvalCenter.APPROVE_NODE_DETAIL(params);
    },

    // 审批 同意
    APPROVE_AGREE_EXEC(params) {
      const taskId = params?.task_id || this.$route?.query?.task_id;
      const enablHandle =
        this.$route?.query?.task_status === 'TaskApproval' && this.$route.query.can_approval;
      return !!taskId && enablHandle;
    },
    APPROVE_AGREE_CUSTOM_ACTION(params) {
      this.$refs?.approvalCenter?.APPROVE_AGREE && this.$refs.approvalCenter.APPROVE_AGREE(params);
    },

    // 审批 拒绝
    APPROVE_REJECT_EXEC(params) {
      const taskId = params?.task_id || this.$route?.query?.task_id;
      const enablHandle =
        this.$route?.query?.task_status === 'TaskApproval' && this.$route.query.can_approval;
      return !!taskId && enablHandle;
    },
    APPROVE_REJECT_CUSTOM_ACTION(params) {
      this.$refs?.approvalCenter?.APPROVE_REJECT &&
        this.$refs.approvalCenter.APPROVE_REJECT(params);
    },
    // 审批 退回
    APPROVE_REFUND_EXEC(params) {
      const taskId = params?.task_id || this.$route?.query?.task_id;
      const enablHandle =
        this.$route?.query?.task_status === 'TaskApproval' && this.$route.query.can_approval;
      return !!taskId && enablHandle && this.$route?.query?.can_rollback;
    },
    APPROVE_REFUND_CUSTOM_ACTION(params) {
      this.$refs?.approvalCenter?.APPROVE_REFUND &&
        this.$refs.approvalCenter.APPROVE_REFUND(params);
    },
    // 提交审批
    APPLY_EXEC() {
      return true;
    },
    // 发起申诉
    APPEAL_EXEC() {
      return true;
    },
  },
};
