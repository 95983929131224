//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  SubmitTask,
  GetSingleTaskInfo,
  approveRollback,
  ListApprovalNode,
} from '../../../../../utils/api';
import { TASK_STATUS_OPTION } from './constant';
import nodeSelector from './component/node-selector/index.vue';
const APPROVE_PROMPT = '确认同意该申请吗？';
const DENY_PROMPT = '确认拒绝该申请吗？';
const REFUND_PROMPT = '确认退回该申请吗？';
const APPROVAL_REASON_NEED_TEXT = '请输入原因（必填）';
const APPROVAL_REASON_TEXT = '请输入原因（选填）';

export default {
  name: '',
  components: { nodeSelector },
  props: {
    approvalTypeMapping: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      dialogOpened: false,
      dialogType: 'approve', // 'approve' | 'deny'
      reasonFormData: {
        comment: '',
      },
      btnLoading: false,
      taskInfo: {}, // 审批当前节点信息
      refundNodesList: [],
      refundInfo: {},
    };
  },
  computed: {
    reasonRules() {
      if (this.dialogType === 'approve') {
        return {
          comment: [
            {
              required:
                !!this.approvalTypeMapping[this.taskInfo.approval_type]
                  ?.approval_agree_reason_required,
              message: '请输入原因',
              trigger: 'blur',
            },
          ],
        };
      }
      if (this.dialogType === 'deny') {
        return {
          comment: [
            {
              required:
                !!this.approvalTypeMapping[this.taskInfo.approval_type]
                  ?.approval_reject_reason_required,
              message: '请输入原因',
              trigger: 'blur',
            },
          ],
        };
      }
      return {
        comment: [
          {
            required:
              !!this.approvalTypeMapping[this.taskInfo.approval_type]
                ?.approval_refund_reason_required,
            message: '请输入原因',
            trigger: 'blur',
          },
        ],
      };
    },
    approvalReasonPlaceholder() {
      if (this.dialogType === 'approve') {
        return !!this.approvalTypeMapping[this.taskInfo.approval_type]
          ?.approval_agree_reason_required
          ? APPROVAL_REASON_NEED_TEXT
          : APPROVAL_REASON_TEXT;
      }
      if (this.dialogType === 'deny') {
        return !!this.approvalTypeMapping[this.taskInfo.approval_type]
          ?.approval_reject_reason_required
          ? APPROVAL_REASON_NEED_TEXT
          : APPROVAL_REASON_TEXT;
      }
      return !!this.approvalTypeMapping[this.taskInfo.approval_type]
        ?.approval_refund_reason_required
        ? APPROVAL_REASON_NEED_TEXT
        : APPROVAL_REASON_TEXT;
    },
    dialogTitle() {
      if (this.dialogType === 'approve') {
        return APPROVE_PROMPT;
      }
      if (this.dialogType === 'deny') return DENY_PROMPT;
      return REFUND_PROMPT;
    },
  },
  watch: {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    async openAgreeDialog(params) {
      this.taskInfo = {};
      this.dialogType = 'approve';
      await this.init(params);
      this.dialogOpened = true;
    },
    async openRejectDialog(params) {
      this.taskInfo = {};
      this.dialogType = 'deny';
      await this.init(params);
      this.dialogOpened = true;
    },
    async openRefundDialog(params) {
      this.taskInfo = {};
      this.dialogType = 'refund';
      await this.init(params);
      if (this.taskInfo.rollback_type === 2) {
        await this.getApprovalListNodes(this.taskInfo);
        this.$refs.nodeSelector.openDialog();
      } else if (this.taskInfo.rollback_type === 1) {
        this.dialogOpened = true;
      }
    },
    async init(params) {
      try {
        const taskId = params?.task_id || this.$route.query.task_id;
        if (!taskId) return this.$message.error('缺少参数task_id');
        const { data } = await GetSingleTaskInfo({ task_id: taskId });
        const taskApprovalStatus = TASK_STATUS_OPTION.find((item) => item.label === '待审批').value;
        if (data.task_status !== taskApprovalStatus) {
          return this.$message.error('该节点已被审批');
        }
        this.taskInfo = data || {};
      } catch (err) {
        console.error(err);
      }
    },
    dialogCloseHandler() {
      this.reasonFormData.comment = '';
      this.dialogOpened = false;
    },
    dialogConfirmHandler(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          // upload the request...
          if (this.dialogType === 'refund') {
            approveRollback({
              process_instance_id: this.taskInfo.process_ins,
              business_key: this.taskInfo.business_key,
              reason: this.reasonFormData.comment.trim(),
              node_id: this.taskInfo.rollback_type === 3 ? '-1' : this.refundInfo.node_id,
              assignee_uin:
                this.taskInfo.rollback_type === 3 ? undefined : this.refundInfo.assignee_uin,
            })
              .then(() => {
                this.btnLoading = false;
                this.reasonFormData.comment = '';
                this.dialogOpened = false;
                this.$emit('detail-reload');
                this.$router.replace({
                  query: {
                    ...this.$route.query,
                    task_id: '',
                  },
                });
              })
              .catch((err) => {
                console.error(err);
                this.btnLoading = false;
                this.$message.error('请求失败');
              });
          } else {
            SubmitTask({
              task_id: this.taskInfo.task_id,
              is_pass: this.dialogType === 'approve',
              comment: this.reasonFormData.comment.trim(),
              process_ins: this.taskInfo.process_ins,
            })
              .then(() => {
                this.btnLoading = false;
                this.reasonFormData.comment = '';
                this.dialogOpened = false;
                this.$emit('detail-reload');
                this.$router.replace({
                  query: {
                    ...this.$route.query,
                    task_id: '',
                  },
                });
              })
              .catch((err) => {
                console.error(err);
                this.btnLoading = false;
                this.$message.error('请求失败');
              });
          }
        }
      });
    },
    async getApprovalListNodes(params) {
      // 我的审批打开的详情抽屉
      if (params.task_id) {
        try {
          const res = await ListApprovalNode({
            process_instance_id: params.process_ins,
          });
          this.refundNodesList =
            res?.nodes?.map((item) => ({
              ...item,
              key: `${item.assignee_uin}@!${item.node_id}`,
            })) || [];
        } catch (e) {
          console.error(e);
        }
      }
    },
    handleSelectRufund(key) {
      const [assigneeUin, nodeId] = key.split('@!');
      const refundInfo = this.refundNodesList.find(
        (item) => item.assignee_uin === assigneeUin && item.node_id === nodeId,
      );
      if (refundInfo) {
        this.refundInfo = refundInfo;
        this.dialogOpened = true;
      }
    },
  },
};
