import { IMG_SRC, PAGE_SIZE, PAGE_SIZES } from '@/utils/constant';
import { gotoUrl } from '@/utils/util';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      g_imgSrc: IMG_SRC,
      g_pageSizeDefault: PAGE_SIZE,
      g_pageSizesConfig: PAGE_SIZES,
    };
  },
  computed: {
    ...mapGetters('appConfig', {
      appConfig: 'getAppConfig',
    }),
    g_imgReplace() {
      return (src) => {
        try {
          const replace = JSON.parse(this.appConfig?.page_config?.constant_img_replace || '{}');
          return replace[src] || src;
        } catch (e) {
          console.log(e);
          return src;
        }
      };
    },
  },
  methods: {
    ...mapActions('appConfig', ['requestAppConfig']),
    g_tourl(url, options) {
      gotoUrl(url, options);
    },
    g_breadcrumb(breadcrumb) {
      this.$store.commit('user/setBreadcrumb', breadcrumb);
    },
    // 从store中获取页面元素权限
    g_elauth(code) {
      return this.$store.getters['user/getUserElementAuth'](code);
    },
  },
};
