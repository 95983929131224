//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StatusTag',
  props: {
    name: {
      type: [String, null, undefined],
      default: '',
    },
    officials: {
      type: [Array, null, undefined],
      default: () => [],
    },
  },
  data() {
    return {
      value1: [],
    };
  },
  computed: {
    officialNameList() {
      const officialNameList = [];
      this.officials?.forEach((item) => {
        const name = item?.official_accounts?.name;
        if (name) {
          officialNameList.push(name);
        }
      });
      return officialNameList;
    },
    officialNameString() {
      return this.officialNameTooltip || '无适用公众号';
    },
    officialNameTooltip() {
      return this.officialNameList.join(' | ');
    },
  },
};
