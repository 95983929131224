//
//
//
//
//

import { refineProcedure } from './util.js';
export default {
  props: {
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    _drawRect_(ctx, config) {
      ctx.save();

      const { color, x, y, width, height, border } = config;

      if (config.radius) {
        const radius = Math.min(config.radius, width / 2, height / 2);

        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.arcTo(x + width, y, x + width, y + height, radius);
        ctx.arcTo(x + width, y + height, x, y + height, radius);
        ctx.arcTo(x, y + height, x, y, radius);
        ctx.arcTo(x, y, x + width, y, radius);
        ctx.closePath();
        ctx.clip();
      }

      ctx.fillStyle = color || '#fff';
      ctx.fillRect(x, y, width, height);

      if (border) {
        ctx.strokeStyle = border.color || '#c8d0dc';
        ctx.stroke();
      }

      ctx.restore();
    },
    _drawImage_(ctx, config) {
      ctx.save();

      const { src, x, y, width, height, radius, border } = config;

      if (radius) {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.arcTo(x + width, y, x + width, y + height, radius);
        ctx.arcTo(x + width, y + height, x, y + height, radius);
        ctx.arcTo(x, y + height, x, y, radius);
        ctx.arcTo(x, y, x + width, y, radius);
        ctx.closePath();
        ctx.clip();
      }
      ctx.drawImage(src, x, y, width, height);

      if (border) {
        ctx.strokeStyle = border.color || '#c8d0dc';
        ctx.stroke();
      }

      ctx.restore();
    },
    _drawText_(ctx, config) {
      ctx.save();

      const { color, val, x, y, fontSize, lineHeight, textAlign, textBaseline } = config;

      if (fontSize) {
        if (lineHeight) {
          ctx.font = `${fontSize}px/${lineHeight}px sans-serif`;
        } else {
          ctx.fontSize = `${fontSize}px`;
        }
      }

      ctx.fillStyle = color || '#1A2734';
      ctx.textAlign = textAlign || 'left';
      ctx.textBaseline = textBaseline || 'bottom';
      ctx.fillText(val, x, y + lineHeight - 3);

      ctx.restore();
    },
    _init_() {
      const canvas = document.getElementById('canvas336');
      const context = canvas.getContext('2d');
      return { canvas, context };
    },
    async draw(procedures) {
      // console.log(procedures);
      try {
        const { canvas: myCanvas, context: ctx } = this._init_();
        const refinedProcedures = await refineProcedure(procedures);
        // console.log('refinedProcedures', refinedProcedures);
        refinedProcedures.forEach((p) => {
          if (p.hidden) {
            return;
          }
          switch (p.type) {
            case 'rect':
              this._drawRect_(ctx, p);
              break;
            case 'img':
              this._drawImage_(ctx, p);
              break;
            case 'text':
              this._drawText_(ctx, p);
              break;
          }
        });

        return myCanvas;
      } catch (error) {
        console.error(error);
      }
    },
    async download(config, name) {
      const canvas = await this.draw(config);
      const a = document.createElement('a');
      a.href = canvas.toDataURL();
      a.download = name;
      a.click();
    },
    async localUrl(config) {
      const canvas = await this.draw(config);
      return canvas.toDataURL();
    },
    async preview(config) {
      const canvas = await this.draw(config);
      const previewImage = document.createElement('img');
      previewImage.setAttribute('alt', 'preview image');
      previewImage.setAttribute('src', canvas.toDataURL());
      previewImage.style.width = '622px';
      previewImage.style.height = '850px';
      previewImage.style.position = 'fixed';
      previewImage.style.top = 0;
      previewImage.style.left = 0;
      previewImage.style['z-index'] = 900;
      previewImage.style.border = 'solid 1px';
      const anchor = document.getElementById('canvas-wrapper');
      anchor.appendChild(previewImage);
    },
  },
};
