import { request, calVariables } from '@/graphql/util';
import {
  QueryGetFlightRoster,
  QueryValidateInspector,
  QueryDistributeInspection,
  QueryCancelInspection,
  QueryGetUserInfo,
} from '../gql/list.js';
import { DataService } from 'nges-common/src/web/utils/api';

export function McmAdminService(data, path) {
  if (path && path.length) {
    return request(path, data);
  }
  return DataService(data, path);
}

export function GetFlightRoster(data) {
  return DataService({
    query: QueryGetFlightRoster(data),
  });
}

export function ValidateInspector(data) {
  return DataService({
    query: calVariables(QueryValidateInspector, data),
  });
}

export function DistributeInspection(data) {
  return DataService({
    query: calVariables(QueryDistributeInspection, data),
  });
}

export function CancelInspection(data) {
  return DataService({
    query: calVariables(QueryCancelInspection, data),
  });
}

export function GetUserInfo() {
  return DataService({
    query: QueryGetUserInfo,
  });
}
