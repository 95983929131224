import parseToAst from './parse';
import execute from './execute';

function execExpression(code, scopeParams, scopeFunc) {
  try {
    const ast = parseToAst(code);
    return execute(ast, scopeParams, scopeFunc);
  } catch (e) {
    console.error(`${code} with error: ${e}`);
    return code;
  }
}

export default execExpression;
