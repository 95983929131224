import { EyaoSaasCreateAppInfo } from '@/api/enterpriseWeChatManagement';
import { CreateBank } from '@/api/examBankManagement';
const createApp = {
  dialogConfig: {
    title: '新建应用',
    width: '700px',
  },
  formConfig: {
    labelPosition: 'left',
    labelWidth: '100px',
  },
  query: [
    {
      label: 'AgentId',
      key: 'agent_id',
      placeholder: '请与企业微信应用Agentld保持一致',
      comp: 'el-input',
      style: {
        width: '500px',
      },
      rules: [{ required: true, message: '必填项', trigger: 'change' }],
    },
    {
      label: 'Secret',
      key: 'app_secret',
      placeholder: '请与企业微信应用Secret保持一致',
      comp: 'el-input',
      style: {
        width: '500px',
      },
      rules: [{ required: true, message: '必填项', trigger: 'change' }],
    },
    {
      label: '控制台网址',
      key: 'console_url',
      placeholder: '请输入控制台网址',
      comp: 'el-input',
      style: {
        width: '500px',
      },
      rules: [{ required: false }],
    },
  ],
  btn: [
    {
      label: '取消',
      comp: 'el-button',
      type: 'default',
      btnQuery: 'close',
    },
    {
      label: '保存',
      comp: 'el-button',
      type: 'primary',
      btnQuery: 'saveClose',
    },
  ],
  api: EyaoSaasCreateAppInfo, // 点击确定调的接口
};

// 创建题库
const addExanBank = {
  dialogConfig: {
    title: '创建题库',
    width: '600px',
  },
  formConfig: {
    labelPosition: 'left',
    labelWidth: '120px',
  },
  query: [
    {
      label: '题库名称:',
      key: 'bank_name',
      placeholder: '请输入题库名称',
      comp: 'el-input',
      style: {
        width: '400px',
      },
      maxlength: '16',
      showWordLimit: true,
      rules: [{ required: true, message: '必填项', trigger: 'change' }],
    },
    {
      label: '备注:',
      key: 'bank_remark',
      placeholder: '请输入备注',
      comp: 'el-input',
      style: {
        width: '400px',
      },
      maxlength: '32',
      showWordLimit: true,
      rules: [{ required: false }],
    },
  ],
  btn: [
    {
      label: '取消',
      comp: 'el-button',
      type: 'default',
      btnQuery: 'close',
    },
    {
      label: '保存',
      comp: 'el-button',
      type: 'primary',
      btnQuery: 'saveClose',
    },
  ],
  api: CreateBank, // 点击确定调的接口
};

export { createApp, addExanBank };
