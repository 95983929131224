//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import selectedButton from './selectedButton.vue';
import NameAndOfficials from './nameAndOfficials.vue';
export default {
  components: { selectedButton, NameAndOfficials },

  props: {
    type: {
      type: String,
      default: 'image', // image, video, audio
      require: true,
    },
    isRadio: {
      type: Boolean,
      default: false,
    },
    list: {
      type: [Array, null, undefined],
      default: null,
      require: true,
    },
    limit: {
      type: Number,
      default: 0, // 0 不限制
    },
    selected: {
      // 暂时没做默认选中, 默认选中需要原始数据
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dataList: [],
      selectedList: [], // 勾选为select: true的数组
      disabled: false,
    };
  },
  computed: {
    // dataList() {
    //   let dataList;
    //   const { list } = this;
    //   if (list?.length && list[0].select === undefined) {
    //     const { selectedList } = this;
    //     dataList = list.map((item) => {
    //       item.select = selectedList.some((i) => i.id === item.id);
    //       return item;
    //     });
    //   }
    //   return dataList || [];
    // },
  },
  watch: {
    list: {
      handler() {
        let dataList;
        const { list } = this;
        if (list?.length && list[0].select === undefined) {
          if (this.selectedList?.length === 0 && this.selected?.length > 0) {
            this.selectedList = [...this.selected];
          }
          const { selectedList } = this;

          dataList = list.map((item) => {
            item.select = selectedList?.some((i) => i.id === item.id);
            return item;
          });
          this.dataList = dataList || [];
        }
      },
      immediate: true,
    },
  },
  methods: {
    getData() {
      return { data: [...this.selectedList] };
    },
    clearData() {
      this.selectedList = [];
    },
    handleSelected(i) {
      const isSelected = !i.select;
      // 去重
      // this.selectedList = arrUnique(value, 'id');
      if (this.limit && isSelected) {
        const maxlength = this.selectedList.length + 1;
        if (maxlength > this.limit) {
          // this.disabled = true;
          let typeName = '个';
          if (this.type === 'image') {
            typeName = '张图片';
          } else if (this.type === 'video') {
            typeName = '个视频';
          } else if (this.type === 'aduio') {
            typeName = '个音频';
          } else if (this.type === 'doc') {
            typeName = '个文档';
          } else if (this.type === 'vote') {
            typeName = '个投票';
          }
          this.$message.error(`当前已超过${this.limit}${typeName}的限制`);
          return;
        }
      }

      if (this.isRadio) {
        this.selectedList.forEach((item) => {
          item.select = false;
        });
        this.selectedList = [];
      }
      this.dataList = this.dataList.map((item) => {
        if (item.id === i.id) {
          item.select = isSelected;
          if (item.select) {
            this.selectedList.push(item);
          } else {
            const arr = [];
            this.selectedList.forEach((soltItem) => {
              if (soltItem.id !== i.id) {
                arr.push(soltItem);
              }
            });
            this.selectedList = arr;
          }
        }
        return item;
      });
      this.$emit('selection-change', this.selectedList);

      // this.disabled = false;
    },
  },
};
