import XLSX from 'xlsx';
/**
 * arr:[{sheet:[['部门','数量'],['行政部',2],['前端部',4]],name:'部门统计'}]
 * name:输出文件名称 如 部门统计
 */
function aoaToXlsx(arr, name) {
  const wb = XLSX.utils.book_new();
  arr.forEach((item) => {
    const sheet = XLSX.utils.aoa_to_sheet(item.sheet);
    XLSX.utils.book_append_sheet(wb, sheet, item.name);
  });
  const workbookBlob = workbook2blob(wb);
  openDownloadDialog(workbookBlob, `${name}.xlsx`);
}
/**
 * arr:[{sheet:[{'部门':'行政部','数量':2},{'部门':'前端部','数量':4}],name:'部门统计'}]
 * name:输出文件名称 如 部门统计
 */
function jsonToXlsx(arr, name) {
  const wb = XLSX.utils.book_new();
  arr.forEach((item) => {
    const sheet = XLSX.utils.json_to_sheet(item.sheet);
    XLSX.utils.book_append_sheet(wb, sheet, item.name);
  });
  const workbookBlob = workbook2blob(wb);
  openDownloadDialog(workbookBlob, `${name}.xlsx`);
}

// 将workbook装化成blob对象
function workbook2blob(workbook) {
  // 生成excel的配置项
  const wopts = {
    // 要生成的文件类型
    bookType: 'xlsx',
    // // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    bookSST: false,
    type: 'binary',
  };
  const wbout = XLSX.write(workbook, wopts);
  // 将字符串转ArrayBuffer
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  const blob = new Blob([s2ab(wbout)], {
    type: 'application/octet-stream',
  });
  return blob;
}

// 将blob对象创建bloburl，然后用a标签实现弹出下载框
function openDownloadDialog(blob, fileName) {
  if (typeof blob === 'object' && blob instanceof Blob) {
    blob = URL.createObjectURL(blob); // 创建blob地址
  }
  const aLink = document.createElement('a');
  aLink.href = blob;
  // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，有时候 file:///模式下不会生效
  aLink.download = fileName || '';
  let event;
  if (window.MouseEvent) event = new MouseEvent('click');
  //   移动端
  else {
    event = document.createEvent('MouseEvents');
    event.initMouseEvent(
      'click',
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null,
    );
  }
  aLink.dispatchEvent(event);
}
export { aoaToXlsx, jsonToXlsx, openDownloadDialog };
