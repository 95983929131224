//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import subordinate from '../../subordinate/index';
import subordinateCascader from '../../subordinate/cascader';
import topSelectMixin from './topSelectMixin';

export default {
  name: 'TopSubordinate',
  components: {
    subordinate,
    subordinateCascader,
  },
  mixins: [topSelectMixin],
  props: {
    filter: {
      type: [Object],
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    value: {
      type: [Array, String],
      default: () => [],
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
      filterList: [],
      selected: [],
      searchStr: '',
    };
  },
  computed: {
    subordinateType() {
      return this.filter?.filter?.type;
    },
  },
  mounted() {},
  methods: {
    filterOnSubmit() {
      this.$emit('filterOnSubmit');
      this.closeDropdown();
    },
    valueChange({ value, data }) {
      this.selected = data.map((i) => {
        return i.value;
      });
      this.$emit('change', value);
      this.resetSelect(this.selected, 'select');
    },
    valueChangeForCascader({ value, data }) {
      this.selected = data.map((i) => {
        return i.id;
      });
      this.$emit('change', value);
      this.resetSelect(this.selected, 'select');
    },
    whereGqlChange(val) {
      this.filter.where_gql = val;
    },
    closeDropdown() {
      this.$refs.select.handleClose();
    },
    // 只清空选项，不抛出事件，不触发重新查询，用于全局重置时被调用
    reset() {
      this.selected = [];
      if (this.$refs.subordinate) {
        this.$refs.subordinate.reset();
      }
    },
    resetFilter() {
      this.selected = [];
      this.$emit('resetFilter');
      if (this.$refs.subordinate) {
        this.$refs.subordinate.reset();
        this.filterOnSubmit();
      }
    },
    getOptions(options) {
      this.list = options;
      this.filterList = this.list;
    },
    filterMethod(v) {
      this.searchStr = v;
      this.$nextTick(() => {
        if (!v) {
          this.filterList = this.list;
        } else {
          this.filterList = this.list.filter((item) => {
            return (
              `${item.label || '空岗'}${item.job_name}`.toLowerCase().indexOf(v.toLowerCase()) > -1
            );
          });
        }
      });
    },
    change(v) {
      if (this.subordinateType === 'cascader') {
        this.$refs.subordinate.setValue(v);
      }
      this.resetSelect(this.selected, 'select');
    },
  },
};
