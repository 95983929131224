//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { MENU_ROUTE, COMMON_LAYOUT_LIST } from '@/utils/constant';
import { routes } from '@/router';
import { query, getQueryObj, getRouteName } from '@/libs/utils/util';
import menuSearch from './menu-search.vue';
import TrackingCompMixin from 'nges-common/src/tracking/mixins/comp-mixin';
import { getPageConfig } from 'nges-common/src/web/utils/common';
import { isIpadDevice } from '@/utils/platform';
import LocalStore from 'nges-common/src/web/components/layout/list/localStore';
export default {
  name: 'SideMenu',
  components: {
    menuSearch,
  },
  mixins: [TrackingCompMixin],
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultActiveMenu: '',
      isIpad: false,
      highLightParentName: '',
      showUnReadCountMenus: ['feedbackProcessing', 'usageFeedback'], // 可能会显示未读数量的菜单
    };
  },
  computed: {
    ...mapGetters('user', {
      menuList: 'getMenuList',
      menuActiveIndex: 'getMenuActiveIndex',
      feedbackCount: 'getFeedbackCount',
    }),
    // 需要覆盖 element-ui 写入的 style。
    // 使用 !important 覆盖，is-active 也要覆盖，会冲突
    styleChlidMenu() {
      return !this.isCollapse
        ? {
            paddingLeft: '36px',
          }
        : {
            padding: 0,
          };
    },
    styleMenuTitle() {
      return !this.isCollapse
        ? {
            padding: '0 12px',
          }
        : {
            padding: 0,
          };
    },
    // defaultActive() {
    //   const routerName = this.$route.name;
    //   let active = routerName;
    //   if (active === 'custom') {
    //     return this.$route.query?.custom;
    //   }
    //   if (this.$route.query?.active) return this.$route.query.active;
    //   Object.keys(MENU_ROUTE).forEach((item) => {
    //     if (item === routerName || (MENU_ROUTE[item] && MENU_ROUTE[item].includes(routerName))) {
    //       active = item;
    //     }
    //   });
    //   return active;
    // },
    routeList() {
      const list = [];
      routes.forEach((route) => {
        if (route.name) {
          list.push(route.name);
        }
      });
      return list;
    },
  },
  watch: {
    menuList() {
      this.init();
    },
    menuActiveIndex(newIndex) {
      if (newIndex) {
        this.$refs.menus.activeIndex = newIndex;
        this.setMenuActiveIndex('');
      }
    },
    $route() {
      this.init();
    },
    defaultActiveMenu() {
      this.setParentHighLight();
    },
    isCollapse() {
      this.setParentHighLight();
    },
  },
  mounted() {
    this.isIpad = isIpadDevice();
    this.init();
    this.getFeedbackCount();
  },
  methods: {
    ...mapActions('user', ['requestFeedbackCount']),
    ...mapMutations('user', {
      setMenuActiveIndex: 'setMenuActiveIndex',
    }),
    init() {
      if (this.menuList && this.menuList.length) {
        let defautlActiveMenu = '';
        let routerName =
          this.$route.name === 'custom'
            ? this.$route.query?.custom
            : this.$route.name === 'iframe'
            ? this.$route.query?.iframe_src
            : this.$route.name;
        this.menuList.forEach((e) => {
          // fix: 对仅父级菜单的判断
          if (!e.children?.length) {
            if (e.menu_path === routerName) {
              defautlActiveMenu = e.menu_path;
            }
            return;
          }

          e.children.forEach((item) => {
            // fix bug: compareMenuPath(item.menu_path, this.$route.name, this.$route.query)
            // 传入的 item.menu_path 是字符串 code ，在 compareMenuPath 中解析 url 会有错误，导致参数对比时一直返回 false
            // 并且这里不用判断参数，只需要判断 $router.name
            if (item.menu_path === routerName) {
              defautlActiveMenu = item.menu_path;
            }
          });
        });
        // 兼容原先的defautActive 逻辑
        routerName = this.$route.name;
        const layoutTypeList = COMMON_LAYOUT_LIST;
        if (layoutTypeList.includes(routerName)) {
          defautlActiveMenu = this.$route.fullPath;
        }
        if (this.$route.query?.active) {
          defautlActiveMenu = this.$route.query?.active;
        }
        // 配置在 MENU_ROUTE 中的情况
        Object.keys(MENU_ROUTE).forEach((item) => {
          if (item === routerName || (MENU_ROUTE[item] && MENU_ROUTE[item].includes(routerName))) {
            defautlActiveMenu = item;
          }
        });
        this.defaultActiveMenu = defautlActiveMenu;
      }
    },
    isPath(path) {
      if (!path?.includes('/saas/')) return false;
      return (
        routes.filter((item) => {
          return path.includes(item.path);
        }).length > 0
      );
    },
    getIndex(path) {
      return query('active', path) || path;
    },
    getRouter(url) {
      return {
        name: getRouteName(url),
        query: getQueryObj(url),
      };
    },
    getIcon(icon) {
      return icon !== '-' ? icon : 'iconfont icon-overview';
    },
    jump({ name, query, item }) {
      LocalStore.processAndUpdateConfig().catch((e) => {
        console.log(e);
      });
      console.log(`点击菜单栏菜单，重置缓存的查询条件`);
      const nowRoute = {
        name: this.$route.name,
        query: this.$route.query || {},
      };
      const aimRoute = {
        name,
        query: query || {},
      };
      // 如果这次路由没有跳转 那就不刷新面包屑
      if (!_.isEqual(nowRoute, aimRoute)) this.$emit('jump');
      this.tracking(item);

      // 点击菜单更新反馈数量
      if (!location.pathname.includes(item.path) && this.showUnReadCountMenus.includes(item.path)) {
        this.getFeedbackCount();
      }
    },
    collapseMenu() {
      this.$emit('collapse');
    },
    clearBread() {
      this.$emit('jump');
    },
    async tracking(item) {
      const trackingConfig = await getPageConfig('web_menu_tracking', 'object');
      if (trackingConfig?.enable) {
        this.__compTracking(
          {
            event_label: item.menu_name,
            event_name: `点击`,
            event_type: 'click',
            event_id: 'click_Event',
            event_biz_type: 'web菜单',
          },
          trackingConfig || {},
        );
      }
    },
    getFeedbackCount() {
      const menu = this.menuList?.find((i) => this.showUnReadCountMenus.includes(i.path));
      if (menu) {
        this.requestFeedbackCount(menu.path);
      }
    },
    findParentMenu(defaultActiveMenu) {
      return this.menuList.find((menu) => {
        return (
          menu.children &&
          menu.children.some((child) => {
            return child.path === defaultActiveMenu;
          })
        );
      });
    },
    clearParentHighLight() {
      console.log('---this.highLightParentName----', this.highLightParentName);
      if (!this.highLightParentName) return;
      const el = document.querySelector(`#submenu-${this.highLightParentName}`);
      if (!el) return;
      el.style.backgroundColor = 'unset';
      el.style.borderRadius = '0';
      const icon = el.querySelector('.el-submenu__title .menu-icon');
      icon.style.color = 'var(--Icon-Neutral-default)';
    },
    setParentHighLight() {
      if (!this.isCollapse) return;
      setTimeout(() => {
        this.clearParentHighLight();
        console.log('---parent---', this.findParentMenu(this.defaultActiveMenu));
        const parent = this.findParentMenu(this.defaultActiveMenu);
        const menuName = parent.menu_name;
        this.highLightParentName = menuName;
        const el = document.querySelector(`#submenu-${menuName}`);
        el.style.backgroundColor = 'var(--Bg-Brand-subtlest)';
        el.style.borderRadius = '4px';
        const icon = el.querySelector('.el-submenu__title .menu-icon');
        icon.style.color = 'var(--Icon-Brand-default)';
      }, 400);
    },
  },
};
