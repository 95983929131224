//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash';
import { execExpression, execExpressionDeep } from 'nges-common/src/layout/expression';

// https://github.com/johnhom1024/vue-load-select/blob/master/src/components/load-select/index.vue
export default {
  directives: {
    // 这里实现一个组件内部的自定义指令
    loadMore: {
      // 指令的定义
      bind(el, binding) {
        const SELECTWRAP = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
        if (!SELECTWRAP) {
          throw new Error('获取不到"el-select-dropdown__wrap"节点');
        }
        SELECTWRAP.addEventListener('scroll', () => {
          // scrollTop  这里可能因为浏览器缩放存在小数点的情况，导致了滚动到底部时
          // scrollHeight 减去滚动到底部时的scrollTop ，依然大于clientHeight 导致无法请求更多数据
          // 这里将scrollTop向上取整 保证滚到底部时，触发调用
          const CONDITION =
            SELECTWRAP.scrollHeight - Math.ceil(SELECTWRAP.scrollTop) <= SELECTWRAP.clientHeight;
          // el.scrollTop !== 0 当输入时，如果搜索结果很少，以至于没看到滚动条，那么此时的CONDITION计算结果是true，会执行bind.value()，此时不应该执行，否则搜索结果不匹配
          if (CONDITION && SELECTWRAP.scrollTop !== 0) {
            binding.value();
          }
        });
      },
    },
  },
  props: {
    value: {
      default: '',
    },
    // 列表数据
    data: {
      type: Array,
      default: () => [],
    },
    // 暂时不需要，任何选择都是输入框值
    optionLabel: {
      type: String,
      default: 'label',
    },
    // 暂时不需要，任何选择都是输入框值
    optionValue: {
      type: String,
      default: 'value',
    },
    // 调用页数的接口
    request: {
      type: Function,
      default: () => {},
    },
    // 传入的页码
    page: {
      type: [Number, String],
      default: 1,
    },
    // 是否还有更多数据
    hasMore: {
      type: Boolean,
      default: true,
    },
    formValueKeys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 存储关键字用
      keyword: '',
      loading: false,
    };
  },
  mounted() {
    console.log('formValueKeys', this.formValueKeys);
    if (this.formValueKeys.length) {
      this.formValueKeys.forEach((k) => {
        this.$watch(`$parent.formValue.${k}`, (nv, ov) => {
          this.$emit('form-value-update', k, nv, ov);
        });
      });
    }
  },
  beforeCreate() {},
  methods: {
    elOptionLabel(option) {
      const ol = get(option, this.execExpression(this.optionLabel, option));
      return this.optionLabel ? ol : this.keyword;
    },
    elOptionValue(option) {
      const ov = get(option, this.execExpression(this.optionValue, option));
      return this.optionValue ? ov : this.keyword;
    },
    execExpression(json, option) {
      const ret = execExpression(json, { log: console.log, $row: option });
      return ret;
    },
    execExpressionDeep(json, data) {
      const ret = execExpressionDeep(json, { log: console.log, $data: data });
      return ret;
    },
    // 请求下一页的数据
    loadMore() {
      // 如果没有更多数据，则不请求
      if (!this.hasMore) {
        return;
      }
      // 如果intercept属性为true则不请求数据，
      if (this.loadMore.intercept) {
        return;
      }
      this.loadMore.intercept = true;
      this.request({
        page: this.page + 1,
        hasMore: true,
        keyword: this.keyword,
      }).then(() => {
        this.loadMore.intercept = false;
      });
    },
    // 选中下拉框没有数据时，自动请求第一页的数据
    focus() {
      this.filterMethod(this.value || '');
      // if (!this.data.length) {
      //     this.request({ page: 1 });
      // }
    },
    filterMethod(keyword) {
      this.keyword = keyword;
      this.loading = true;
      console.log('filterMethod keyword', keyword);
      this.request({ page: 1, keyword }).then(() => {
        this.loading = false;
      });
    },
    // 删除选中时，如果请求了关键字，则清除关键字再请求第一页的数据
    clear() {
      if (this.keyword) {
        this.keyword = '';
        this.request({ page: 1 });
      }
    },
    change(e) {
      this.$emit('select-change', e, this.keyword);
    },
    // https://www.cnblogs.com/tw6668/p/14557911.html
    visibleChange(flag) {
      if (flag) {
        this.$refs.selectRef.focus();
      } else {
        this.$refs.selectRef.blur();
      }
    },
    parentFormValue() {
      return this.$parent.formValue || {};
    },
    parentFormData() {
      return this.$parent.formData || {};
    },
    parentMergeForm(params) {
      this.$parent.mergeForm?.(params);
    },
  },
};
