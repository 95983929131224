//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 弃用. 用通用组件
import { GetAllProductList } from '@/graphql/api/other';
// queryTagCategoryTrees
import { queryTagCategoryTrees } from '@/graphql/api/tag';
import FilterItem from './filterItem.vue';
import { isArray } from 'lodash';
import { arrUnique } from '@/utils/util';
import { DataService } from 'nges-common/src/web/utils/api';
import { /* pageGql,*/ whereGql } from '@/graphql/util';

export default {
  name: 'MaterialFilter',
  components: { FilterItem },
  props: {
    filters: {
      type: [Array, null, undefined],
      default: null,
    },
    actions: {
      type: [Array, null, undefined],
      default: null,
    },
    allowSelectedMode: {
      type: Boolean,
      default: false,
    },
    selectedCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isInitData: false,
      filterVals: null,
      size: 'small',
      // 节流使用
      timer: null,
      // selectedTags: [],
    };
  },
  computed: {
    // 获取文章公众号列表
    publicList() {
      return this.$store.getters['user/getPublishList'];
    },
    hasFilterData() {
      return !!this.filters?.length;
    },
    hasActionButtons() {
      return !!this.actions?.length || this.allowSelectedMode;
    },
  },
  watch: {
    filters: {
      handler(newVal) {
        if (newVal) {
          this.initData();
        }
      },
      immediate: true,
      // deep: true,
    },
  },
  mounted() {
    this.initData();
  },

  methods: {
    resetFilters() {
      this.filters.forEach((filterData) => {
        const refs = this.$refs?.[filterData.key];
        if (!refs) {
          return;
        }

        if (isArray(refs)) {
          refs.forEach((ref) => {
            ref?.resetFilter?.();
          });
        } else {
          refs.resetFilter?.();
        }
        this.filterVals[filterData.key] = null;
        this.$refs?.[filterData.key]?.resetFilter?.();
      });
      // this.emitData();
    },
    getDataList(filterData) {
      return this.filterVals?.[`${filterData.key}DataList`] || [];
    },
    getDefaultValue(filterData) {
      if (filterData.value_type === 'TEXT' || filterData.value_type === 'SELECT_ONE') {
        return '';
      }
      if (filterData.value_type === 'SELECT_MANY' || filterData.value_type === 'CASCADER') {
        return [];
      }
      return null;
    },
    async initData() {
      const { filters } = this;
      if (this.isInitData || this.filterVals || !filters?.length) {
        return;
      }

      this.isInitData = true;
      this.filterVals = {};
      for (let index = 0; index < filters.length; index++) {
        const filterData = filters[index];
        let dataList;

        if (filterData.gql) {
          dataList = await this.initCommonGql(filterData);
        }
        // 内置功能
        else if (filterData.key === 'tag_category') {
          // (标签组)
          dataList = await this.initTagCategory(filterData);
        } else if (filterData.key === 'product_tag') {
          // 产品标签
          dataList = await this.initProductTag(filterData);
        } else if (filterData.key === 'public_list') {
          // 公众号
          dataList = this.initPublicList(filterData);
        }
        const defaultValue = this.getDefaultValue(filterData);
        this.filterVals[filterData.key] = defaultValue;
        this.filterVals[`${filterData.key}DataList`] = dataList || defaultValue;
      }
      this.isInitData = false;
    },
    async initTagCategory(filterData) {
      const res = await queryTagCategoryTrees().catch(() => null);
      if (res) {
        filterData.object = ['tag_category'];
        // filterData.value_key = 'id';
        // filterData.value_name = 'name';
        return this.processCoommnGqlRes(filterData, res);
      }
    },
    // 获取产品标签
    async initProductTag(filterData) {
      const res = await GetAllProductList().catch(() => null);

      if (res) {
        filterData.object = ['product', 'product_tag'];
        // filterData.value_key = 'id';
        // filterData.value_name = 'name';
        return this.processCoommnGqlRes(filterData, res);
      }
    },
    initPublicList(filterData) {
      filterData.object = ['publicList'];
      const res = {};
      res[filterData.object[0]] = this.publicList;
      return this.processCoommnGqlRes(filterData, res);
    },
    async initCommonGql(filterData) {
      const res = await DataService({
        query: filterData.gql,
      }).catch(() => null);
      if (res) {
        return this.processCoommnGqlRes(filterData, res);
      }
    },
    processCoommnGqlRes(filterData, res) {
      if (!res || !filterData.object) {
        return;
      }

      if (
        filterData.value_type === 'SELECT_ONE' ||
        filterData.value_type === 'SELECT_MANY' ||
        filterData.value_type === 'CASCADER'
      ) {
        if (!filterData.value_key) {
          filterData.value_key = 'id';
        }
        if (!filterData.value_name) {
          filterData.value_name = 'name';
        }
      }

      if (filterData.value_type === 'CASCADER') {
        this.filterVals[`${filterData.key}Props`] = {
          multiple: true,
          expandTrigger: 'hover',
          value: filterData.value_key,
          label: filterData.value_name,
        };
      } else if (
        filterData.value_type === 'SELECT_ONE' ||
        filterData.value_type === 'SELECT_MANY'
      ) {
        this.filterVals[`${filterData.key}Props`] = {
          value: filterData.value_key,
          label: filterData.value_name,
        };
      }

      let dataList = [];
      const objects = filterData.object;
      if (!isArray(objects)) {
        objects.push(objects);
      }
      objects.forEach((key) => {
        const data = res[key];
        if (data) {
          dataList.push(...data);
        }
      });

      if (objects.length > 1 && filterData.value_key) {
        dataList = arrUnique(dataList, filterData.value_key);
      }
      return dataList;
    },
    handleFilterChange(filterData, data) {
      const { value } = data;
      this.filterVals[filterData.key] = value;
      this.emitData();
    },
    emitData() {
      const whereGqlArr = [];
      whereGqlArr.push({
        id: 'false',
        whereGqlType: 'is_null',
      });
      this.filters.forEach((filterData) => {
        const value = this.filterVals[filterData.key];
        if (!value) {
          return;
        }

        if (filterData.value_type === 'TEXT') {
          filterData.fields.forEach((field) => {
            whereGqlArr.push({
              [`${field}`]: `%${value}%`,
              whereGqlType: 'like',
            });
          });
        } else if (isArray(value) && value.length) {
          filterData.fields.forEach((field) => {
            whereGqlArr.push({
              [`${field}`]: value,
              whereGqlType: 'include_any',
            });
          });
        }
      });

      this.filterVals.whereGql = whereGql(whereGqlArr);

      this.$emit('change', this.filterVals);
    },
    handleCheckboxChange(value) {
      this.$emit('action-handle', { action: 'SELECTED_MODE', value });
    },
    handleClickActionButton(data) {
      this.$emit('action-handle', { action: data.action });
    },
  },
};
