import { render, staticRenderFns } from "./remoteSelect.vue?vue&type=template&id=956e5dc0&scoped=true&"
import script from "./remoteSelect.vue?vue&type=script&lang=js&"
export * from "./remoteSelect.vue?vue&type=script&lang=js&"
import style0 from "./remoteSelect.vue?vue&type=style&index=0&id=956e5dc0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "956e5dc0",
  null
  
)

export default component.exports