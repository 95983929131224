//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import _ from 'lodash';
import { dataHistoryDrawer } from './util';
// import defaultListJson from './default-list.json';
import { getLayoutJson } from '../../../../layout/queryWeb';
import { mapState } from 'vuex';
export default {
  name: 'DataHistoryDrawer',
  data() {
    return {
      visible: false,
      params: {},
      json: null,
    };
  },
  computed: {
    ...mapState('user', {
      menuList: 'menuList',
    }),
  },
  watch: {},
  mounted() {
    dataHistoryDrawer.open = this.open;
  },
  methods: {
    getListJson() {
      const tenantConfig = getLayoutJson(this.menuList, 'globalConfigWeb');
      const remoteJson = tenantConfig?.data_history_list_config;
      this.json = remoteJson;
    },
    open(action) {
      this.json = null;
      this.$nextTick(() => {
        this.params = action.action_params || {};
        this.visible = true;
        this.getListJson();
      });
    },
    close() {
      this.visible = false;
    },
  },
};
