//
//
//
//
//
//
//

const DEFAULT_ICON_MAP = {
  primary: 'icon-tag-clock',
  success: 'icon-tag-success',
  warning: 'icon-tag-waiting',
  danger: 'icon-tag-warning',
  info: 'icon-tag-error',
};

export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'info',
    },
  },
  computed: {
    computedIcon() {
      if (!this.icon) {
        return {
          icon: DEFAULT_ICON_MAP[this.type] || DEFAULT_ICON_MAP.primary,
        };
      }
      if (this.icon.indexOf('http') === 0) {
        return {
          image: this.icon,
        };
      }
      return {
        icon: this.icon,
      };
    },
  },
};
