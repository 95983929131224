import { debounce } from 'lodash';

const showMessage = debounce(($message, actionName, actionParams) => {
  const msg = actionName.slice(4);
  $message?.({
    type: actionParams?.type || '',
    message: msg,
    duration: actionParams?.duration || 3000,
  });
}, 200);

function msgAction({ action_params: actionParams, action: actionName }) {
  return new Promise((resolve) => {
    showMessage(this.$message, actionName, actionParams);
    resolve();
  });
}

export { msgAction };
