//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GetWeComDepartmentById, GetWeComUserByName } from '@/api/addressList';
/**
 * selectViewRangeDialog组件
 * 用于选择人员和部门
 * 和selectViewRange的区别在于，dialog只有一个弹窗，没有回显，也不会在弹窗关闭后显示选择的内容，关闭弹窗即结束
 *
 * props：
 *  agentId：用于部门请求的企业应用id，默认为-1
 *  dialogTitle：dialog的标题文字
 *
 * methods：
 *  openDialog：打开dialog的方法
 *  closeDialog：关闭dialog的方法
 *  submitForm：用于获得最终的选择结果，返回人员id和部门id两个数组
 *  reloadTree：清空树并重新获取节点，会在agentId变更时自动调用，也可以手动调用
 *
 * event：
 *  submit：点击取消和确定后，触发submit事件，参数true/false（触发后不会自动关闭弹窗）
 */
const defaultAgentId = -1;
export default {
  name: 'SelectViewRange',
  props: {
    agentId: {
      type: Number,
      default: -1,
    },
    dialogTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search_isshow: false,
      defaultProps: {
        children: 'children',
        label: 'name',
        isLeaf: 'leaf',
      },
      visible: false,
      defaultValue: null,
      dialogVisible: false,

      departmentId: 0, // 当前正在查询的departmentId
      departmentInvalid: 1,

      filterText: '', // 搜索text
      fewUser: true, // 是否展示更少的搜索结果
      fewDepartment: true,
      searchUserList: [], // 搜索结果列表
      searchDepartmentList: [],

      searchTimer: null, // 搜索节流
      showSearchResult: false, // 搜索结果显示

      selectedDepartmentIds: new Map(),
      selectedUserIds: new Map(),

      trigger: false, // 用于触发vue刷新（map无法触发刷新）

      onLoadNode: false, // 用于保证同一时间只有一个loadNode在执行
      treeLoading: false,
    };
  },
  computed: {
    isChooseAll() {
      return false;
    },
    departIds() {
      // 用于tree判断节点是否已选
      // temp是为了触发vue刷新页面
      const temp = this.trigger;
      console.log(temp);
      return Array.from(this.selectedDepartmentIds.keys());
    },
    userIds() {
      // 用于tree判断节点是否已选
      // temp是为了触发vue刷新页面
      const temp = this.trigger;
      console.log(temp);
      return Array.from(this.selectedUserIds.keys());
    },
    hasSelection() {
      // 判断是否有选择
      const temp = this.trigger;
      console.log(temp);
      // console.log(Array.from(this.confirmUserIds.keys()).length);
      // console.log(Array.from(this.confirmDepartmentIds.keys()).length);
      return (
        Array.from(this.selectedUserIds.keys()).length +
          Array.from(this.selectedDepartmentIds.keys()).length !==
        0
      );
    },
    searchResultUser() {
      // 用于展开和收起，展示部分或全部搜索结果
      if (this.fewUser) {
        return this.searchUserList.slice(0, 3);
      }
      return this.searchUserList;
    },
    searchResultDepartment() {
      if (this.fewDepartment) {
        return this.searchDepartmentList.slice(0, 3);
      }
      return this.searchDepartmentList;
    },
  },
  watch: {
    filterText(val) {
      this.search_isshow = !!val;
      if (val) {
        this.searchName();
      } else {
        this.showSearchResult = false;
      }
    },
    agentId(val) {
      console.log('agent-id发生变化');
      this.agentId = val;
      this.reloadTree();
    },
  },
  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    isChecked(node, data) {
      // 判断是否已经选择，用于显示☑️
      return this.selectedDepartmentIds.has(data.id) || this.selectedUserIds.has(data.userid);
    },
    loadNode(node, resolve) {
      // 用于tree的载入函数

      this.onLoadNode = true;
      if (node.level === 0) {
        this.treeLoading = true;
        this.departmentId = 0;
        this.nodeHead = node;
        this.resolveHead = resolve;
      } else {
        this.departmentId = node.data.id;
        this.departmentInvalid = node.data.invalid;
      }

      this.getDepartments().then((data) => {
        console.log(data);
        if (data) {
          for (const item of data.user_list) {
            item.leaf = true;
          }
          resolve([...data.department_list, ...data.user_list]);
        } else {
          resolve([]);
        }

        if (node.level === 0) {
          this.treeLoading = false;
        }
        this.onLoadNode = false;
      });
    },
    checkNode(node, data) {
      // 从树形结构添加选择
      console.log(node);

      if (data.invalid === 1) {
        this.$message.warning('所选对象已被禁用');
        return;
      }

      if (data.leaf) {
        // 个人 删除&添加
        if (this.selectedUserIds.has(data.userid)) {
          this.selectedUserIds.delete(data.userid);
        } else {
          this.selectedUserIds.set(data.userid, data.name);
        }
      } else {
        // 部门 删除&添加
        if (this.selectedDepartmentIds.has(data.id)) {
          this.selectedDepartmentIds.delete(data.id);
        } else {
          this.selectedDepartmentIds.set(data.id, data.name);
        }
      }
      this.triggerUpdate();
    },
    deb(fn, time) {
      // 防抖函数 用于搜索人员接口
      const clearTimer = function () {
        fn.call();
        this.searchTimer = null;
      };

      return () => {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
          this.searchTimer = null;
        }
        this.searchTimer = setTimeout(clearTimer, time);
      };
    },
    searchName() {
      // 搜索人员和部门
      const tempFun = this.deb(() => {
        if (this.filterText.length === 0 || this.filterText.match('^[ ]+$')) {
          // 避免发送为空的搜索请求
          return;
        }
        GetWeComUserByName({
          name: this.filterText,
          agent_id: this.agentId,
        })
          .then((res) => {
            console.log(res);
            this.searchUserList = res.user_detail_list || [];
            this.searchDepartmentList = res.department_detail_list || [];
            this.fewUser = true;
            this.fewDepartment = true;
            this.showSearchResult = true;
          })
          .catch((e) => {
            this.$message.error(e);
          });
      }, 300);
      tempFun();
    },
    addUserFromSearchResult(user) {
      // 从搜索结果添加人员
      if (this.selectedUserIds.has(user.userid)) {
        this.$message.warning(`已经添加了人员：${user.name}`);
      } else {
        this.selectedUserIds.set(user.userid, user.name);
        this.triggerUpdate();
      }
    },
    addDepartmentFromSearchResult(department) {
      // 从搜索结果添加部门
      if (this.selectedDepartmentIds.has(department.id)) {
        this.$message.warning(`已经添加了部门：${department.name}`);
      } else {
        this.selectedDepartmentIds.set(department.id, department.name);
        this.triggerUpdate();
      }
    },
    triggerUpdate() {
      // 触发vue更新，因为map类型限制
      this.trigger = !this.trigger;
      this.$forceUpdate();
    },
    reloadTree() {
      // 清空原有的树并重新加载
      console.log('清空树');
      const temp = setInterval(() => {
        if (!this.onLoadNode) {
          this.nodeHead.childNodes = [];
          this.loadNode(this.nodeHead, this.resolveHead); // 再次执行懒加载的方法
          // this.clearSelection(false); // 清空已有的选择
          // this.clearConfirmTags();
          clearInterval(temp);
        }
      }, 100);
    },
    clickChooseTags() {
      this.visible = !this.visible;
    },
    getDepartments() {
      // 请求部门树形结构
      return new Promise((resolve) => {
        GetWeComDepartmentById({
          agent_id: this.departmentInvalid === 0 ? defaultAgentId : this.agentId,
          department_id: this.departmentId,
          judge_status: 1,
        })
          .then((res) => {
            console.log(res);
            resolve(res);
          })
          .catch((e) => {
            this.$message.error(e);
            resolve();
          });
      });
    },
    clearSelection(showMessage) {
      // 清除所有选择，showMessage为是否展示提示
      this.selectedDepartmentIds.clear();
      this.selectedUserIds.clear();
      this.triggerUpdate();
      if (showMessage) {
        this.$message.success('清除成功！');
      }
    },
    // 内层删除tag
    closeUserTag(key) {
      this.selectedUserIds.delete(key);
      this.triggerUpdate();
    },
    closeDepartmentTag(key) {
      this.selectedDepartmentIds.delete(key);
      this.triggerUpdate();
    },
    openSearchResult() {
      // 点击输入框时显示上一次的搜索结果
      if (this.filterText.length) {
        this.showSearchResult = true;
      }
    },
    // 全选/取消全选
    clickChooseAll() {},
    // 确定修改
    confirmTags(confirm) {
      console.log(confirm);
      this.$emit('submitRange', confirm);
    },
    // 提交结果
    submitForm() {
      return {
        departIds: Array.from(this.selectedDepartmentIds.keys()),
        userIds: Array.from(this.selectedUserIds.keys()),
      };
    },
  },
};
