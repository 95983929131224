import { query as utilQuery } from '../../web-mobile/util';

function query(name, url = window.location.href) {
  return utilQuery(name, url);
}

function getQueryObj(url = '') {
  let arr = url.split('?');
  if (arr.length < 2) {
    return {};
  }
  arr = arr[1].split('#')[0].split('&');
  const obj = {};
  arr.forEach((item) => {
    const [key, value = ''] = item.split('=');
    obj[key] = value;
  });
  return obj;
}

// 比较配置的url和vue-router是否同一个
function compareMenuPath(url = '', routeName = '', routeQuery = {}) {
  const urlObj = getQueryObj(url);
  let path = url;
  const arr = url.split('?');
  if (arr.length >= 2) {
    path = arr[0];
  }
  if (routeName !== getRouteName(path)) {
    return false;
  }
  if (Object.keys(urlObj).length !== Object.keys(routeQuery).length) {
    return false;
  }
  for (const k of Object.keys(urlObj)) {
    if (urlObj[k] !== routeQuery[k]) {
      return false;
    }
  }
  return true;
}

function getRouteName(url = '') {
  let pathName = url;
  const match = url.match(new RegExp('(saas/)(.*)(?=.html)', 'g'));
  if (match && match[0]) {
    pathName = match[0].replace('saas/', '');
  }
  return pathName;
}

const storeData = {};

const gotoUrl = function (url, options = {}) {
  if (!url) {
    return;
  }
  const { replace = false, href = false, open = false, name = false, params } = options;
  if (open) {
    window.open(url);
    return;
  }
  if (href) {
    if (storeData.href) return;
    storeData.href = true;
    window.location.href = url;
    return;
  }
  window.Vue.routerGo(name ? { name: url, params } : { path: url, params }, replace);
};

function transMenuList(list) {
  if (list && list.length) {
    return list.map((item) => {
      return {
        path: item.path,
        menu_path: item.path,
        menu_name: item.name,
        menu_icon: item.icon,
        display: item.display,
        layout_json: item.layout_json,
        children: (item.children || []).map((child) => ({
          path: child.path,
          menu_path: child.path,
          menu_name: child.name,
          menu_icon: child.icon,
          display: child.display,
          layout_json: child.layout_json,
          children: (child.children || []).map((child1) => ({
            path: child1.path,
            menu_path: child1.path,
            menu_name: child1.name,
            menu_icon: child1.icon,
            display: child1.display,
            layout_json: child1.layout_json,
          })),
        })),
      };
    });
  }
  return [];
}

export * from '../../web-mobile/util';
export { query, getQueryObj, getRouteName, gotoUrl, transMenuList, compareMenuPath };
