//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { insertLog } from '@/graphql/api/material';

export default {
  name: 'MaterialManagementAudioUpload',
  components: {},
  data() {
    return {
      showback: true, // 是否新打开的页面(是为false)
      loading: false, // 加载
      uploadList: [],
    };
  },
  computed: {
    ...mapGetters('user', { layoutJson: 'getLayoutJson' }),
  },
  created() {
    this.showback = !this.isNewBlank();
  },
  mounted() {
    this.$refs.mutation &&
      this.$refs.mutation.open({
        id: null,
      });
  },
  methods: {
    // 保存到素材库后上报处理
    async mutationActionHandle(actionData) {
      console.log('action', actionData);
      const { actionName } = actionData;
      if (actionName === 'after-action-of-CREATE') {
        const id = actionData.res?.mcm_material[0]?.id;
        console.log('上传素材成功，返回素材ID: ', id);
        const materialData = actionData.formData.url[0];

        const logData = {
          id,
          type: 4,
          record_type: 'audio',
          target_info: {
            name: materialData.name,
            type: materialData.type,
            record_type: 'audio',
            url: materialData.url,
            material_official: materialData.app_id.map((item) => {
              return {
                official_id: item.id,
              };
            }),
          },
        };
        insertLog(logData);
      }
      if (actionName === 'BACK' && window.history.length === 1) {
        window.close();
      }
    },
    async beforeAction(actionData) {
      const { action, formData } = actionData;
      console.log(action, formData, actionData);
      if (action === 'CREATE') {
        const datas = formData.url;
        if (datas && datas.length) {
          let isLengthValid = true;
          for (let i = 0; i < datas.length; i++) {
            const audioDuration = await this.doLengthValid(datas[i]);
            console.log('音频时长: ', audioDuration);
            if (audioDuration > 7200) {
              this.$message.error(`上传音频 ${i + 1} 时长不能超过2小时！`);
              isLengthValid = false;
              break;
            }
          }
          if (!isLengthValid) {
            this.loading = false;
            return [false];
          }
        } else {
          this.$message.warning(`请上传音频！`);
          return [false];
        }
      }
      return [true];
    },
    doLengthValid(data) {
      const { url } = data;
      const audioElement = new Audio(url);
      return new Promise((resolve) => {
        audioElement.addEventListener('loadedmetadata', async (_event) => {
          resolve(Number(audioElement.duration));
        });
      });
    },
    // 是否新标签页
    isNewBlank() {
      const { query } = this.$route;
      // 判断是否新打开的页面
      return query.type === 'newWindow';
    },
  },
};
