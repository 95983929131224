//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Custom from './wujie';
import mButton from '../button';
import Action from '../../action';

const DEFAULT_SHOW_TYPE = 'CUSTOM_DIALOG';

export default {
  components: {
    Custom,
    mButton,
  },
  mixins: [Action],
  props: {
    expParamsObj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      title: '',
      loading: false,
      loadingInstance() {},
      visible: false,
      params: {},
      showType: DEFAULT_SHOW_TYPE,
      customPath: '',
      actions: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    /**
     *
     * @param {*} param0
     *  - layout: json layout key
     *  - id: list open id
     *  - listRef: list open component ref
     *  - json: layout json
     */
    open(action) {
      const { action: actionName, label: actionLabel, action_params: actionParams } = action || {};

      const { layoutName, title, custom_path: customPath, actions } = actionParams || {};

      if (!customPath) {
        console.error('action no url', action);
        return;
      }
      this.visible = true;
      this.$nextTick(() => {
        if (customPath !== this.customPath) {
          this.loading = true;
          this.loadingInstance = this.$loading({
            target: this.$refs.loading,
          });
        }
      });
      this.title = layoutName || title || actionLabel || '';
      this.actions = actions || [];
      this.showType = actionName || DEFAULT_SHOW_TYPE;
      this.params = actionParams || {};
      this.customPath = customPath || 'login';
      console.log('layout/custom#open', action);
    },
    close() {
      this.visible = false;
      this.$emit('close');
    },
    m_customAction(action) {
      switch (action?.action) {
        case 'CUSTOM_EMIT_EVENT':
          setTimeout(() => {
            (this.$refs.wujie_dialog || this.$refs.wujie_drawer).emitEvent('t1EmitTest', {
              ...this.params,
            });
            this.$nextTick(() => {
              const { auto_close: autoClose = true } = action || {};
              if (autoClose) {
                this.close();
              }
            });
          });
          break;
        default:
          break;
      }
    },
    actionHandle(action) {
      this.m_actionHandle(action);
      console.log('layout/custom#actionHandle', action);
    },
    loadingClose() {
      if (this.loading) {
        this.loading = false;
        this.loadingInstance.close();
      }
    },
  },
};
