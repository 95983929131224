import { development, test, preview, production, az, buildMini } from './env';
function setConfig(config) {
  const domain = window.location.host;
  if (/^localhost/.test(domain)) {
    config = {
      ...config,
      ...development,
    };
    return config;
  }
  const configs = {
    development,
    test,
    preview,
    production,
    az,
  };
  for (const key in configs) {
    if (Object.prototype.hasOwnProperty.call(configs, key)) {
      const configItem = configs[key];
      if (configItem.length) {
        const domainConfig = configItem.find((i) => i.domain === domain);
        config = {
          ...config,
          ...domainConfig,
        };
        return config;
      }
      if (configItem.domain === domain) {
        config = {
          ...config,
          ...configItem,
        };
        return config;
      }
    }
  }
  
  const { VUE_APP_MINI_ENV: vueAppMiniEnv, VUE_APP_TEST_ENV: vueAppTestEnv } = process.env;
  switch (vueAppMiniEnv) {
    case 'development':
      config = {
        ...config,
        ...development,
      };
      break;
    case 'test':
      config = {
        VUE_APP_TEST_ENV: vueAppTestEnv,
        ...config,
        ...test,
      };
      break;
    case 'preview':
      config = {
        ...config,
        ...preview,
      };
      break;
    case 'az':
      config = {
        ...config,
        ...az,
      };
      break;
    default:
      config = {
        ...config,
        ...production,
      };
      break;
  }
  
  return config;
}
const commonConfig = {
  apiProduct: 2,
  platform: 1,
  platform: 3,
  
  product: 'nges.mini',
  company: 'tencent',
  api: '/api/access/pb',
  prodDomain: 'h5.nges.tencent.com',
  whiteFilter: [
    'NgesServer/NgesApp/GetAppConfig',
    'NgesServer/NgesApp/GetAppKv',
    'AccountServer/AccountLogin/LoginWithWeixin',
    'AccountServer/AccountLogin/LoginWithWorkWeixinOauth',
    'AccountServer/AccountLogin/LoginWithWxMini',
    'AccountServer/AccountLogin/TouristLogin',
    'AccountServer/AccountLogin/Login',
    'NgesServer/NgesJssdk/GetWxJsApiTicketSign',
    'AccountServer/AccountLogin/LoginWithWorkWeixin',
    'EventsServer/JsSdkSignService/GetMeetingJsApiTicketSign',
    'EventsServer/Meeting/LoginWithMeetingApp',
    'EventsServer/Meeting/LoginWithMeetingAppByUin',
    'EventsServer/Meeting/ParseMeetingCodeAndGenMiniUrl',
    'EventsServer/Meeting/EventsSendSmsCode',
    'EventsServer/Meeting/EventsCheckSmsCode',
    'NgesServer/NgesApp/GetTenantLanguage',
    'NgesServer/NgesApp/GetWebLoginConfig',
    'EventsServer/Meeting/CheckSubscribeOfficialAccount',
    'NgesServer/ShortKey/FindShortKeyV2',
    // 'AccountServer/AccountBase/ParseToken',
  ],
  whiteListPages: [
    // 无 token 可访问的页面
    '/meeting/pages/meetingPlugin/login/index',
    '/meeting/pages/meetingPlugin/login/silentLogin',
    '/meeting/pages/meetingPlugin/login/miniProgramLogin',
    '/meeting/pages/meetingPlugin/login/captchaLogin',
    '/meeting/pages/meetingPlugin/protocol/apply/index',
    '/meeting/pages/meetingPlugin/protocol/privacy/index',
    '/meeting/pages/meetingplugin/login/index',
    '/meeting/pages/meetingplugin/login/miniProgramLogin',
    '/meeting/pages/meetingplugin/login/captchaLogin',
    '/meeting/pages/meetingplugin/login/silentLogin',
    '/meeting/pages/meetingplugin/login/registerJsSDK',
    '/meeting/pages/meetingplugin/protocol/apply/index',
    '/meeting/pages/meetingplugin/protocol/privacy/index',
    '/pages/tourist/index',
    '/pages/tourist/qrcode',
    '/pages/tourist/meeting-signin-with-subscribe',
    '/pages/main/webview/commonWebview',
  ],
  // 可以游客访问的页面
  touristPages: [
    'pages/article',
    'pages/exam',
    'pages/home/search',
    'pages/material',
    'pages/crm/media/detail/videoPlayer',
  ],
  // 当前租户标识
  buildMini,
};
const config = setConfig(commonConfig);
console.log('config', config, process.env);
export default config;
