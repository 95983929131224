// 其他接口
import {
  GetProductInfoList,
  GetProductListQuery,
  GetAllProductQuery,
  QueryHospitalDepartment,
  QueryTitleList,
  GetLocationList,
  GetCityList,
  FindCityList,
  FindProvinceList,
  GetLocationName,
  GetDomainInfoList,
  GetHospitalList,
  GetHospitalName,
  QueryStuff,
  QueryDoctor,
  QueryGetInterestList,
  QuerycheckSpeakerId,
  QueryCheckSpeakerBanById,
  QueryMeetingsBySpeakerId,
  QueryMutationNonFee,
  QueryMutationLaborPaymentOrder,
  AddSpeakerBan,
  EditSpeakerBan,
} from '@/graphql/gql/other';
import { calVariables } from '@/graphql/util';
import { request as directRequest } from '@/libs/request';
import { DataService } from 'nges-common/src/web/utils/api';

export function queryGetProductInfoList(data) {
  return DataService({
    query: calVariables(GetProductInfoList, data),
  });
}
export function GetProductList() {
  return DataService({
    query: GetProductListQuery,
  });
}
export function GetAllProductList() {
  return DataService({
    query: GetAllProductQuery,
  });
}
export function queryQueryHospitalDepartment(data) {
  return DataService({
    query: calVariables(QueryHospitalDepartment, data),
  });
}
export function queryQueryTitleList(data) {
  return DataService({
    query: calVariables(QueryTitleList, data),
  });
}
export function queryGetLocationList(data) {
  return DataService({
    query: calVariables(GetLocationList, data),
  });
}
export function queryGetCityList(data) {
  return DataService({
    query: calVariables(GetCityList, data),
  });
}
export function queryFindCityList(data) {
  return DataService({
    query: calVariables(FindCityList, data),
  });
}
export function queryFindProvinceList(data) {
  return DataService({
    query: calVariables(FindProvinceList, data),
  });
}
export function queryGetLocation(data) {
  return DataService({
    query: calVariables(GetLocationName, data),
  });
}
export function queryGetDomainInfoList(data) {
  return DataService({
    query: calVariables(GetDomainInfoList, data),
  });
}
export function queryGetHospitalList(data) {
  return DataService({
    query: calVariables(GetHospitalList, data),
  });
}
export function queryGetHospitalName(data) {
  return DataService({
    query: calVariables(GetHospitalName, data),
  });
}
export function queryGetStuff(data) {
  return DataService({
    query: calVariables(QueryStuff, data),
  });
}
export function queryStuffInfo(uinList) {
  return directRequest('NgesAdminServer/NgesAdminUser/BatchQueryBindInfo', {
    user_list: uinList.map((item) => {
      return { uin: item };
    }),
    query_type: 1, // 1-根据uin批量查询; 2-根据userid批量查询; 3-根据openid批量查询;
  });
}
export function queryGetInterestList() {
  return DataService({
    query: QueryGetInterestList,
  });
}
export function queryGetDoctor(data) {
  return DataService({
    query: calVariables(QueryDoctor, data),
  });
}
export function queryDoctorInfo(uinList) {
  return directRequest('NgesAdminServer/NgesAdminUser/BatchQueryBindInfo', {
    user_list: uinList.map((item) => {
      return { uin: item };
    }),
    query_type: 1, // 1-根据uin批量查询; 2-根据userid批量查询; 3-根据openid批量查询;
  });
}
export function unbindDoctor({ type, uin }) {
  return directRequest('NgesServer/NgesDoctor/UnBindDoctor', {
    uin,
    third_party: type,
  });
}
export function unbindStuff({ type, uin }) {
  return directRequest('NgesServer/NgesStaff/UnBindStaff', {
    uin,
    third_party: type,
  });
}
export function updateMultiSiteMasterDoctor(data) {
  return directRequest('NgesAdminServer/NgesAdminDoctor/UpdateMultiSiteMasterDoctor', data);
}
export function unBindMultiSiteDoctor(data) {
  return directRequest('NgesAdminServer/NgesAdminDoctor/UnBindMultiSiteDoctor', data);
}
export function checkSpeakerId(data) {
  return DataService({
    query: calVariables(QuerycheckSpeakerId, data),
  });
}
export function changeNonFeeStatus(data) {
  return DataService({
    query: calVariables(QueryMutationNonFee, data),
  });
}
export function changeLaborPaymentOrderItemStatus(data) {
  return DataService({
    query: calVariables(QueryMutationLaborPaymentOrder, data),
  });
}

// 根据讲者ID 查询讲者是否被禁用。
export function checkSpeakerBanByID(data) {
  return DataService({
    query: calVariables(QueryCheckSpeakerBanById, data),
  });
}

// 根据讲者ID 查询讲者参会的会议。
export function getSpeakerMeetings(data) {
  return DataService({
    query: calVariables(QueryMeetingsBySpeakerId, data),
  });
}

// 禁用讲者
export function createBanSpeaker(data) {
  return DataService({
    query: calVariables(AddSpeakerBan, data),
  });
}

// 编辑禁用讲者
export function editBanSpeaker(data) {
  return DataService({
    query: calVariables(EditSpeakerBan, data),
  });
}
