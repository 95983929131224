//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TrackingLayoutViewMixin from 'nges-common/src/tracking/mixins/layout-view-mixin';
import { parseUrl } from 'nges-common/src/web-mobile/util';
import { merge, includes } from 'lodash';
import MNoPermission from 'nges-common/src/web/components/layout/no-permission';

let api;
let login;
api = require('nges-common/src/web/utils/api');
login = require('nges-common/src/web/utils/login').default;



const { GetAppConfig } = api;
const { GetAuthCode } = api;
const fetchUserInfo = login.fetchUserInfo.bind(login);
const getUserInfoCache = login.getUserInfoCache.bind(login);
const obtainLoginInfo = login.obtainLoginInfo.bind(login);

export default {
  components: {
    MNoPermission,
  },
  mixins: [TrackingLayoutViewMixin],
  props: {
    src: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number],
      default: '100%',
    },
    height: {
      type: [String, Number],
      default: '800',
    },
    tracking: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'component',
    },
    iframeStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isIntersecting: false,
      observer: null,
      notAllow: false,
      iframeSrc: '',
    };
  },
  computed: {
    trackingConfig() {
      const { src } = this;
      const { origin, pathname, search, host } = parseUrl(src);
      const uriPath = origin + pathname;
      const queryString = search;
      return {
        params: {
          event_type: 'view',
          uri_path: uriPath,
          query_string: queryString,
          domain: host,
        },
        config: this?.tracking || {},
      };
    },
    trackingEnterConfig() {
      return merge({}, this.trackingConfig, {
        trigger: 'enter',
        params: {
          event_id: 'view_iframe_open',
        },
      });
    },
    trackingLeaveConfig() {
      return merge({}, this.trackingConfig, {
        trigger: 'leave',
        params: {
          event_id: 'view_iframe_close',
        },
      });
    },
  },
  watch: {
    src() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.__trkRemoveListener();
  },
  methods: {
    async init() {
      this.__trkRemoveListener();
      const appConfig = await GetAppConfig();
      const iframeAllowHostStr = appConfig.page_config?.web_iframe_allow_host;
      let iframeAllowHost;
      try {
        iframeAllowHost = JSON.parse(iframeAllowHostStr);
      } catch (e) {
        iframeAllowHost = [];
      }
      const { host } = parseUrl(this.src);
      console.log(1111111, this.src, host, iframeAllowHost);
      if (host != location.host && !includes(iframeAllowHost, host)) {
        this.notAllow = true;
        console.error(
          '不允许使用iframe打开当前域名链接，需到kv中将域名配置到iframe_allow_host中才可打开',
        );
        return;
      }
      this.iframeSrc = await this.formatUrl(this.src);
      this.$nextTick(() => {
        this.__trkAddRef('iframe');
        this.__trkAddListener();
      });
    },
    async formatUrl(url) {
      const [ngesAuthCode, userInfo] = await Promise.all([
        this.getAuthCode(url),
        this.getUserInfo(),
      ]);
      const replaceMap = {
        nges_auth_code: ngesAuthCode,
        ...userInfo,
      };
      for (const k of Object.keys(replaceMap)) {
        url = url.replaceAll(`\${${k}}`, replaceMap[k]);
      }
      return url;
    },
    async getUserInfo() {
      await fetchUserInfo();
      return {
        ...obtainLoginInfo(),
        ...getUserInfoCache(),
      };
    },
    async getAuthCode(url) {
      let ngesAuthCode = '';
      if (/\$\{(\s*)nges_auth_code(\s*)\}/g.test(url)) {
        const appConfig = await GetAppConfig();
        const thirdLinkAppidStr = appConfig?.page_config?.third_link_auth_code_appid;
        let thirdLinkAppId = {};
        try {
          thirdLinkAppId = JSON.parse(thirdLinkAppidStr);
        } catch (e) {}
        const { host } = parseUrl(url);
        const appid = thirdLinkAppId?.[host];
        if (appid) {
          try {
            const { code } = await GetAuthCode({ appid });
            ngesAuthCode = code;
          } catch (e) {
            console.error('GetAuthCode failed:', e);
          }
        }
      }
      return ngesAuthCode;
    },
  },
};
