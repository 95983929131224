//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import messageBox from '../../message-box';
import { globalConfigDialog } from './util';

export default {
  name: 'MessageBox',
  components: { messageBox },
  data() {
    return {
      confirmText: '',
      cancelText: '',
      handleClose: () => {},
      handleConfirm: () => {},
      contentList: [],
      disableBtn: 'confirm',
      disableTime: 0,
      timer: null,
    };
  },
  computed: {
    cancelTextTime() {
      if (this.disableBtn === 'cancel' && this.disableTime) {
        return `${this.cancelText}（${this.disableTime}s）`;
      }
      return this.cancelText;
    },
    confirmTextTime() {
      if (this.disableBtn === 'confirm' && this.disableTime) {
        return `${this.confirmText}（${this.disableTime}s）`;
      }
      return this.confirmText;
    },
  },
  mounted() {
    globalConfigDialog.open.push(this.open);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    globalConfigDialog.open = globalConfigDialog.open.filter((i) => i !== this.open);
  },
  methods: {
    open(option) {
      const {
        title = '提示',
        type: titleIconType = '',
        content = '',
        list,
        confirm_text: confirmText,
        cancel_text: cancelText,
        handleClose,
        handleConfirm,
      } = option;
      this.contentList = list || [];
      this.confirmText = confirmText;
      this.cancelText = cancelText;
      this.handleClose = handleClose;
      this.handleConfirm = handleConfirm;
      this.disableTime = 0;
      clearInterval(this.timer);

      const params = {
        top: '30vh',
        title,
        titleIconType,
        content,
        appendToBody: true,
      };
      this.$nextTick(() => {
        this.$refs.messageBox.show(params);
      });

      if (option.disable_time) {
        if (option.disable_time.confirm) this.disableBtn === 'confirm';
        else if (option.disable_time.cancel) this.disableBtn = 'cancel';
        this.disableTime = option.disable_time.confirm || option.disable_time.cancel || 0;
        this.timer = setInterval(() => {
          if (this.disableTime <= 0) {
            clearInterval(this.timer);
            return;
          }
          this.disableTime -= 1;
        }, 1000);
      }
    },
    close() {
      clearInterval(this.timer);
    },
    cancel() {
      this.$refs.messageBox.close();
      this.handleClose();
    },
    confirm() {
      this.$refs.messageBox.close();
      this.handleConfirm();
    },
  },
};
