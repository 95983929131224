import {
  MeetingQuery,
  UpdateMeeting,
  MeetingQueryKV,
  UpdateMeetingKV,
  StatisticsMeeting,
  InsertMeeting,
  QueryMeeting,
  MeetingProvince,
  MeetingCity,
  MeetingProduct,
  MeetingBu,
  MeetingDetailList,
  MeetingDetailCount,
  StatisticsMeetingAttenders,
  GetMeetingAttenderIdByName,
  GetApprovalDetailQuery,
  GetApprovalDcrDetailQuery,
  getProductShowTypeQuery,
  GetDoctorDetailQuery,
  KvConfigs,
  SetMeetingStatus,
  SetMeetingOwner,
  getCostsQuery,
  meetingCostQuery,
  conformRealCostQuery,
  confirmSpeakerQuery,
  cancelInspectionQuery,
  meetingDocPlayRecord,
  meetingDocPlayRecordForAZ,
  meetingDocPlayEventsByOnloadIds,
  QueryHcpPersonalInfo,
  QueryUserPersonalInfo,
  QuerySpeakerPersonalInfo,
  QueryHcpSpeakerPersonalInfo,
  saveHotelQuery,
  queryOldIds,
  updateAttenderGql,
  deleteAttendersGql,
  insertAttenderGql,
  compositeInsertGql,
  compositeUpdateGql,
  queryMeetingTypesGql,
  insertSnapDocsGql,
  compositeInsertSnapDocsGql,
  queryFormValueSaveGql,
  queryUserTypeGql,
  queryTerritoryGql,
  queryTerritoryChildGql,
  queryBlacklistCityGql,
} from '@/graphql/gql/meeting';
import request from '@/libs/request';
import { calVariables } from '@/graphql/util';
import { DataService } from 'nges-common/src/web/utils/api';

export function GetMeetingConfig(data) {
  return DataService({
    query: calVariables(MeetingQuery, data),
  });
}
export function SaveMeetingConfig(data) {
  return request('EventsAdminServer/Meeting/MeetingAdminQuery', {
    query: calVariables(UpdateMeeting, data),
  });
}
export function GetMeetingConfigKv(data) {
  return DataService({
    query: calVariables(MeetingQueryKV, data),
  }).then((result) => {
    // 兼容旧的读app_config的逻辑，将对象列表转成对象
    const confList = result?.data?.kv || [];
    const confMap = {};
    confList.forEach((item) => {
      item?.key && (confMap[item.key] = item?.value);
    });
    console.log('confMap', confMap);
    return { events_meeting_config: [confMap] };
  });
}
export function SaveMeetingConfigKv(data) {
  return request('EventsAdminServer/Meeting/MeetingAdminQuery', {
    query: calVariables(UpdateMeetingKV, data),
  });
}
export function exportPdfByMeetingId(meetingId) {
  return request('EventsAdminServer/Meeting/SendMeetingInfoPdf', {
    meetingId,
  });
}
export function StatisticsMeetingApi(data) {
  return request('EventsAdminServer/Meeting/MeetingAdminQuery', {
    query: calVariables(StatisticsMeeting, data),
  });
}

export function InsertMeetingApi(data) {
  return request('EventsAdminServer/Meeting/MeetingAdminQuery', {
    query: calVariables(InsertMeeting, data),
  });
}

export function QueryMeetingApi(data) {
  return request('EventsAdminServer/Meeting/MeetingAdminQuery', {
    query: calVariables(QueryMeeting, data),
  });
}

export function GetObjectFieldsApi() {
  return request('DataServer/MetadataService/GetObjectFields', {
    object_id: '1471001142686126080',
  });
}

export function GetMeetingProvince(data) {
  return request('EventsAdminServer/Meeting/MeetingAdminQuery', {
    query: calVariables(MeetingProvince, data),
  });
}

export function GetMeetingCity(data) {
  return request('EventsAdminServer/Meeting/MeetingAdminQuery', {
    query: calVariables(MeetingCity, data),
  });
}

export function GetMeetingProduct(data) {
  return request('EventsAdminServer/Meeting/MeetingAdminQuery', {
    query: calVariables(MeetingProduct, data),
  });
}

export function GetMeetingBu(data) {
  return request('EventsAdminServer/Meeting/MeetingAdminQuery', {
    query: calVariables(MeetingBu, data),
  });
}

export function MeetingDetailListApi(data) {
  return request('EventsAdminServer/Meeting/MeetingAdminQuery', {
    query: calVariables(MeetingDetailList, data),
  });
}
export function MeetingDetailCountApi(data) {
  return request('EventsAdminServer/Meeting/MeetingAdminQuery', {
    query: calVariables(MeetingDetailCount, data),
  });
}
export function sendPdfApi(meetingId) {
  return request('EventsAdminServer/Meeting/SendMeetingInfoPdf', {
    meetingId,
  });
}

export function MeetingAttendersApi(data) {
  return request('EventsAdminServer/Meeting/MeetingAdminQuery', {
    query: calVariables(StatisticsMeetingAttenders(data), data),
  });
}

export function GetMeetingAttenderIdByNameApi(data) {
  return DataService({
    query: calVariables(GetMeetingAttenderIdByName, data),
  });
}
export function GetProductShowType() {
  return DataService({
    query: getProductShowTypeQuery,
  });
}
export function GetApprovalDetail(data) {
  return DataService({
    query: calVariables(GetApprovalDetailQuery(data), data),
  });
}

export function GetDcrApprovalDetail(data) {
  return DataService({
    query: calVariables(GetApprovalDcrDetailQuery, data),
  });
}
export function GetDoctorDetail(data) {
  return DataService({
    query: calVariables(GetDoctorDetailQuery, data),
  });
}
export function getKvConfigs(data) {
  return DataService({
    query: calVariables(KvConfigs, data),
  });
}

// create meeting with approval
export function createMeetingApproval(gql) {
  return DataService({
    query: gql,
  });
}

// update meeting status
export function updateMeetingStatus(data) {
  return request('EventsServer/Meeting/MeetingQuery', {
    query: calVariables(SetMeetingStatus, data),
  });
}

// update meeting owner
export function updateMeetingOwner(data) {
  return request('EventsServer/Meeting/MeetingQuery', {
    query: calVariables(SetMeetingOwner, data),
  });
}

export function getCosts() {
  return DataService({
    query: getCostsQuery,
  });
}
export function queryMeetingCost({ meeting_id: id }) {
  return DataService({
    query: calVariables(meetingCostQuery, { id }),
  });
}
export function confirmRealCost(params) {
  return DataService({
    query: calVariables(conformRealCostQuery, params),
  });
}
export function confirmSpeaker(data) {
  return DataService({
    query: calVariables(confirmSpeakerQuery, data),
  });
}

export function cancelInspection(data) {
  return request('EventsServer/Meeting/MeetingQuery', {
    query: calVariables(cancelInspectionQuery, data),
  });
}

export function queryMeetingDocPlayRecord(data, isAZ) {
  return request('EventsServer/Meeting/MeetingQuery', {
    query: isAZ
      ? calVariables(meetingDocPlayRecordForAZ, data)
      : calVariables(meetingDocPlayRecord, data),
  });
}

export function queryMeetingDocPlayEventsByOnloadIds(data) {
  return request('EventsServer/Meeting/MeetingQuery', {
    query: calVariables(meetingDocPlayEventsByOnloadIds, data),
  });
}

export function getHcpPersonalInfo(data) {
  return DataService({
    query: calVariables(QueryHcpPersonalInfo, data),
  });
}
export function getUserPersonalInfo(data) {
  return DataService({
    query: calVariables(QueryUserPersonalInfo, data),
  });
}
export function getSpeakerPersonalInfo(data) {
  return DataService({
    query: calVariables(QuerySpeakerPersonalInfo, data),
  });
}
export function getHcpSpeakerPersonalInfo(data) {
  return DataService({
    query: calVariables(QueryHcpSpeakerPersonalInfo, data),
  });
}
export function saveHotel(data) {
  return DataService({
    query: calVariables(saveHotelQuery, data),
  });
}
export function getOldIds(id) {
  return DataService({
    query: calVariables(queryOldIds, { id }),
  });
}
export function updateAttenders(data) {
  const gqls = data.map((attender) => calVariables(updateAttenderGql, attender));
  const ret = compositeUpdateGql(gqls);
  return DataService({
    query: ret,
  });
}
export function deleteAttenders(data) {
  // data -> {ids: string[]}
  return DataService({
    query: calVariables(deleteAttendersGql, data),
  });
}
export function insertAttenders(data) {
  const gqls = data.map((attender) => calVariables(insertAttenderGql, attender));
  const ret = compositeInsertGql(gqls);
  return DataService({
    query: ret,
  });
}
export function queryMeetingTypes(data) {
  return DataService({
    query: queryMeetingTypesGql(data),
  });
}
// 会议详情-更换材料
export function insertSnapDocs(data) {
  const gqls = data.map((item) => calVariables(insertSnapDocsGql, item));
  const ret = compositeInsertSnapDocsGql(gqls);
  return DataService({
    query: ret,
  });
}
export function formValueSave(data) {
  return DataService({
    query: calVariables(queryFormValueSaveGql, data),
  });
}

export function queryUserType() {
  return DataService({
    query: queryUserTypeGql,
  });
}

export function queryTerritory(data) {
  return DataService({
    query: calVariables(queryTerritoryGql, data),
  });
}

export function queryTerritoryChild(data) {
  return DataService({
    query: calVariables(queryTerritoryChildGql, data),
  });
}

export function queryBlacklistCity() {
  return DataService({
    query: queryBlacklistCityGql,
  });
}
