//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Box',
  props: ['title'],
};
