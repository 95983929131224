import { calVariables } from '@/graphql/util';
import { DataService, UsageFeedbackMarkRead } from 'nges-common/src/web/utils/api';
import dayjs from 'dayjs';

// 寻找未读反馈
export function queryNeedReadFeedback(data) {
  const start = dayjs().unix();
  const end = dayjs().subtract(30, 'days').unix();
  const NeedReadFeedback = `query {usage_feedback(_or: [{ status.read: { _is_null: true } }, { status.read: false } ], _where:{_and:[{create_time:{_lte:${start}}},{create_time:{_gte:${end}}}]}) { count: _count(id)}}`;
  const NeedReadFeedbackWithoutTime = `query {usage_feedback(_or: [{ status.read: { _is_null: true } }, { status.read: false } ]) { count: _count(id)}}`;
  const query = data === 'feedbackProcessing' ? NeedReadFeedbackWithoutTime : NeedReadFeedback;
  return DataService({
    query: calVariables(query, data),
  });
}

export function queryReadFeedback(data) {
  return UsageFeedbackMarkRead(data);
}
