//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { GetSubordinateList } from '../../../api';
import { depthFirstTraverse } from 'nges-common/src/web-mobile/util';
import { execExpression } from 'nges-common/src/layout/expression';
import { uniq, get, uniqBy } from 'lodash';
import { getOptionsFrom } from 'nges-common/src/layout/mutation';
import item from './item';

export default {
  // another amazing cascader
  name: 'AwesomeSubordinate',
  components: {
    item,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    objects: {
      type: [Array, void 0],
      default: () => [],
    },
    filters: {
      type: [Array, void 0],
      default: () => [],
    },
    expParamsObj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tree: [],
      list: [],
      loading: false,
      defaultIcon:
        'https://cdn.nges.tencent.com/nges_1/13104202/7e6471d9-368f-497c-84c1-e83ea76b370f.png',
      searchVal: '',
      checked: [],
    };
  },
  computed: {
    row() {
      return this.filter?.filter?.row || { title: 'name', key: 'subordinate_list' };
    },
    title() {
      return this.filter?.filter?.title;
    },
    styles() {
      return (
        this.filter?.filter?.styles || {
          width: this.filter?.filter?.style?.width,
        }
      );
    },
    placeholder() {
      return this.filter?.filter?.placeholder;
    },
    isEmptySearchVal() {
      return !this.searchVal && `${this.searchVal}` !== '0';
    },
    filterList() {
      const fliterList = this.list
        .filter((i) => {
          return i.label.includes(this.searchVal);
        })
        .map((i) => {
          return {
            ...i,
            label: i.label.split(this.searchVal),
            data: {
              ...i,
            },
          };
        });
      return uniqBy(fliterList, 'value');
    },
    defaultProps() {
      return {
        children: this.row.key,
        label: this.row.label,
      };
    },
    valueKey() {
      return this.filter?.filter?.value_key || 'value';
    },
  },
  watch: {
    searchVal(newVal, oldVal) {
      if (newVal === '' && oldVal) {
        // 数据清空，重置树的值
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(this.filter?.filter?.selected || []);
        });
      }
      if (newVal) {
        this.$nextTick(() => {
          this.checked = this.filter?.filter?.selected || [];
        });
      }
    },
  },
  async mounted() {
    if (this.filter) {
      const { where_gql: whereGql, $whereGql } = this.filter || {};
      if (!$whereGql && whereGql) {
        this.filter.$whereGql = whereGql;
      }
      await this.GetList();
    }
  },
  methods: {
    async GetList() {
      try {
        this.loading = true;
        const res = await this.getData();
        this.tree = res;
        const treeToList = [];
        this.tree.forEach((node) => this.countDescendants(node));
        depthFirstTraverse(
          {
            [this.row.key]: this.tree,
          },
          this.row.key,
          (node) => {
            if (node.$height > 1) {
              node.label = node.label ?? get(node, this.row.label);
              node.value = node.value ?? `${get(node, this.row.value)}-${Math.random()}`;
              node.tag = node.tag ?? get(node, this.row?.tag);
              node.descs = node.descs ?? this.row?.descs?.map((desc) => get(node, desc));
              node.icon
                = node.icon
                ?? execExpression(this.row?.icon, {
                  ...this.expParamsObj,
                  t: {
                    ...(this.expParamsObj?.t || {}),
                    $level: node.$height - 1,
                    $node: node,
                  },
                })
                ?? this.defaultIcon;
              treeToList.push({
                ...node,
              });
            }
          },
        );
        this.list = uniqBy(treeToList, 'value');
        this.$emit('get-options', this.list);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    countDescendants(node) {
      const children = get(node, this.row.key);
      // 如果节点没有子节点，返回 0
      if (!children || children.length === 0) {
        node.$descendantCount = 0;
        return 0;
      }

      // 计算所有子节点的后代数量
      const childrenCounts = children.map(this.countDescendants);

      // 计算当前节点的后代数量（子节点数 + 所有子节点的后代数量）
      node.$descendantCount
        = childrenCounts.reduce((sum, count) => sum + count, 0) + children.length;

      // 返回当前节点的后代数量
      return node.$descendantCount;
    },
    async getData() {
      const res = await getOptionsFrom({
        optionsFrom: this.filter?.filter?.options_from,
        objects: this.objects,
        filters: this.filters,
      });
      return res;
    },
    handleCheck(checked, checkedObj) {
      const { checkedNodes } = checkedObj;
      const value = uniq(checkedNodes.map((i) => get(i, this.filter?.filter?.value_key)));
      let whereGql = this.getWhereGql();
      const t = value;
      if (whereGql) {
        whereGql = execExpression(whereGql, {
          t,
          $filters: this.filters,
        });
        this.$emit('whereGqlChange', { whereGql, filter: this.filter });
      }
      this.$emit('change', { value, filter: this.filter });
      this.$emit('filterOnSubmit');
    },
    getWhereGql() {
      return this.filter?.$whereGql;
    },
    reset() {
      this.$refs.tree.setCheckedKeys([]);
    },
    handleChange(e = []) {
      const nodes = e.map((item) => {
        return this.list.find((listItem) => listItem[this.valueKey] === item);
      });
      const allNodes = [];
      const getNodes = (nodes = []) => {
        nodes.forEach((node) => {
          allNodes.push(node);
          const children = get(item, this.row.key);
          if (children && children.length) {
            getNodes(children);
          }
        });
      };
      getNodes(nodes);
      this.handleCheck(allNodes, { checkedNodes: allNodes });
    },
  },
};
