import request from '@/libs/request';
import { queryCustomerRelationList } from '@/graphql/api/externalContactManagement';

/** 外部联系人管理 */
// 分配客户列表(包含已分配和待分配客户)
export const getExternalContactListApi = (data) => request('EyaoSaaSExternalContactList', data);
// 负责人列表
export const getPrincipalInfoListApi = (data) => request('EyaoSaaSPrincipalInfoList', data);
// 原负责人（离职负责人）列表
export const getDeparturePrincipalListApi = (data) =>
  request('EyaoSaaSDeparturePrincipalRespList', data);
// 分配在职成员的客户
export const updateTransferOnjobCustomerApi = (data) =>
  request('EyaoSaaSTransferOnjobCustomer', data);
// 分配离职成员的客户
export const updateTransferResignCustomerApi = (data) =>
  request('EyaoSaaSTransferResignCustomer', data);
// 分配记录查看
export const getTransferRecordListApi = (data) => request('EyaoSaaSTransferRecordList', data);
// 分配记录导出
export const ExportExternalContact = (data) => request('ExportExternalContact', data);
// 删除记录查看
export const getContactDelRecordListApi = (data) => request('EyaoSaasContactDelRecordList', data);
// 删除记录导出
export const getContactDelRecordExportApi = (data) =>
  request('EyaoSaasContactDelRecordExport', data);
// 客户关系列表
export const getCustomerRelationList = (data) => queryCustomerRelationList(data);

/** 应用管理 */
// 列表
export const EyaoSaasGetAppInfoList = (data) => request('GetAgentInfoList', data);
// 删除
export const EyaoSaasDeleteAppInfo = (data) => request('DeleteAgentInfo', data);
// 详情
export const EyaoSaasGetAppInfoDetail = (data) => request('GetAgentInfoDetail', data);
// 新增
export const EyaoSaasCreateAppInfo = (data) => request('CreateAgentInfo', data);
// 更新
export const EyaoSaasSaveAppInfo = (data) => request('UpdateAgentInfo', data);

/** 会话留存 */
// 员工列表查询
export const EyaoSaaSQueryChatStaffList = (data) => request('EyaoSaaSQueryChatStaffList', data);
// 聊天记录查询
export const EyaoSaaSQueryChatList = (data) => request('EyaoSaaSQueryChatList', data);
// 存在数据往来人员列表查询
export const EyaoSaaSQueryHasChatStaffList = (data) =>
  request('EyaoSaaSQueryHasChatStaffList', data);
// 获取会话聊天记录
export const EyaoSaasGetChatDetailList = (data) => request('EyaoSaasGetChatDetailList', data);
// 获取部门结构树
export const EyaoSaasGetDepartmentTree = (data) => request('EyaoSaasGetDepartmentTree', data);
// 根据员工id获取会话列表
export const EyaoSaasGetChatListByUserID = (data) => request('EyaoSaasGetChatListByUserID', data);
// 获取所有成员
export const SearchWeComUserList = (data) => request('SearchWeComUserList', data);
// 搜索联系人列表
export const EyaoSaasSearchChatContact = (data) => request('EyaoSaasSearchChatContact', data);
// 根据发送人查询群信息
export const EyaoSaasGetRoomListByUserID = (data) => request('EyaoSaasGetRoomListByUserID', data);
// 查询员工敏感词命中记录列表
export const EyaoSaaSQuerySensitiveRecordList = (data) =>
  request('EyaoSaaSQuerySensitiveRecordList', data);
// 查询命中关键词明细
export const EyaoSaaSQuerySensitiveRecord = (data) => request('EyaoSaaSQuerySensitiveRecord', data);
// 下载命中敏感词记录
export const EyaoSaaSDownloadSensitiveRecords = (data) =>
  request('EyaoSaaSDownloadSensitiveRecords', data);
// 查询敏感词
export const EyaoSaaSQuerySensitiveWords = (data) => request('EyaoSaaSQuerySensitiveWords', data);
// 创建敏感词
export const EyaoSaaSCreateSensitiveWords = (data) => request('EyaoSaaSCreateSensitiveWords', data);
// 修改敏感词
export const EyaoSaaSModifySensitiveWords = (data) => request('EyaoSaaSModifySensitiveWords', data);
// 删除敏感词
export const EyaoSaaSDeleteSensitiveWords = (data) => request('EyaoSaaSDeleteSensitiveWords', data);

/** 企业红包管理 */
// 获取红包列表
export const getRedpackListApi = (data) => request('GetRedpackList', data);
// 创建红包
export const createRedpackInfoApi = (data) => request('CreateRedpackInfo', data);
// 编辑红包
export const updateRedpackInfoApi = (data) => request('UpdateRedpackInfo', data);
// 删除红包
export const deleteRedpackInfoApi = (data) => request('DeleteRedpackInfo', data);
// 立即发送红包
export const sendRedPackApi = (data) => request('SendRedPack', data);
// 查看红包发送记录
export const getRedpackSendRecordApi = (data) => request('GetRedpackSendRecord', data);
// 查看红包发送详情
export const getRedpackSendDetailListApi = (data) => request('GetRedpackSendDetailList', data);
// 导出红包发送记录
export const exportRedpackSendRecordApi = (data) => request('ExportRedpackSendRecord', data);
