//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AwesomeCascaderItem',
  props: {
    node: {
      type: [Object, void 0],
      default: void 0,
    },
    filter: {
      type: [Object],
      default: () => {},
    },
    searchVal: {
      type: String,
      default: '',
    },
  },
  computed: {
    title() {
      return this.node.label ?? this.filter?.filter.empty_text ?? '-';
    },
  },
};
