// 这个mixin用于和layout-tab公用，可以统一处理tab打开的逻辑
import { getLayoutJson, getNewLayoutJson } from '../../../../layout/queryWeb';
import { includes, set } from 'lodash';
import { mapState } from 'vuex';
const _ = { includes, set };

export default {
  // 通过provide和inject，透传一个inLayoutMixinTab标志，若出现tab套tab的情况的时候，可以保证在最外层的tab打开
  inject: {
    inLayoutMixinTab: {
      default: false,
    },
  },
  provide: {
    inLayoutMixinTab: true,
  },
  computed: {
    ...mapState('user', {
      menuList: 'menuList',
    }),
  },
  methods: {
    async customAction(action) {
      /**
       * 若自身父节点已经存在mix_tab组件了，则自己不处理，由父mixin_tab处理，保证在最外层的tab打开
       * 如：详情套了带mixtab的列表，列表中点了打开tab的按钮，最后需在最外层详情布局中打开tab。
       * */
      if (this.inLayoutMixinTab === true) {
        return null;
      }
      if (_.includes(['TO_DETAIL_TAB', 'TO_LIST_TAB'], action?.action)) {
        let type = '';
        let layoutType = '';
        switch (action?.action) {
          case 'TO_LIST_TAB':
            type = 'list';
            layoutType = 'list-layout';
            break;
          case 'TO_DETAIL_TAB':
            type = 'detail';
            layoutType = 'detail-layout';
            break;
          default:
            return;
        }
        const {
          object,
          id,
          path,
          key,
          tab_title: tabTitle = '',
          record_type: recordType = '',
        } = action?.action_params || {};
        let layoutJson = getLayoutJson(this.menuList, path || `${object}_${type}`);
        layoutJson = layoutJson?.[key] || layoutJson?.[layoutType] || layoutJson;
        if (!layoutJson && object) {
          layoutJson = await getNewLayoutJson({
            object,
            layoutType: type,
            recordType,
          });
        }
        if (layoutJson) {
          return {
            type,
            json: layoutJson,
            targetId: id,
            tabTitle,
          };
        }
        this.$message.error('布局不存在');

        _.set(action, '__process__.processed', true);
      }
      return null;
    },
  },
};
