import MemStore from './memory-store';
import { mapGettersWrap } from '@tencent/vuex-dynamic-store/src/index';
export default {
  computed: {
    ...mapGettersWrap(['scopeId']),
  },
  methods: {
    ...MemStore.handler,
  },
};
