// 规则(https://cn.vuejs.org/v2/guide/mixins.html#%E5%9F%BA%E7%A1%80):
// # 当组件和混入对象含有同名选项时，这些选项将以恰当的方式进行“合并”。比如，数据对象在内部会进行递归合并，并在发生冲突时以组件数据优先。
// # 同名钩子函数将合并为一个数组，因此都将被调用。另外，混入对象的钩子将在组件自身钩子之前调用。
// # 值为对象的选项，例如 methods、components 和 directives，将被合并为同一个对象。两个对象键名冲突时，取组件对象的键值对。
import { getPrivateUploadSignApi } from '@/api/common.js';
import config from '@/config';
import aegis from '@/plugins/aegis';

const { cosAppId } = config;
const CosMixin = {
  data() {
    return {
      list: [],
      total: 0,
    };
  },
  computed: {
    cos() {
      return this.$cos;
    },
  },
  mounted() {},
  methods: {
    async getPrivateUploadSign(cosParams) {
      const res = await getPrivateUploadSignApi({
        app_id: cosAppId,
        suffix: cosParams.suffix || '',
      });
      const {
        session_token: sessionToken,
        tmp_secret_id: tmpSecretId,
        tmp_secret_key: tmpSecretKey,
      } = res?.data?.credentials || {};
      const {
        cdn_url: cdnUrl,
        key,
        startTime,
        expiredTime,
        region,
        bucket,
        domain,
      } = res?.data || {};
      return {
        tmpSecretId,
        tmpSecretKey,
        sessionToken,
        cdnUrl,
        key,
        startTime,
        expiredTime,
        region,
        bucket,
        domain,
      };
    },
    // 文件上传，使用高级上传接口uploadFile
    putCosObject(cosParams, cos = this.cos, cb = () => {}, customGetTokenFunc = undefined) {
      return new Promise((resolve, reject) => {
        cos.getInstance({ customGetTokenFunc, suffix: cosParams.suffix }).then(
          ({
            cosInstance, // cos实例
            cdnUrl, // 文件访问的cdn url
            key, // 上传的key，由后台决定，跟token强关联
          }) => {
            cosInstance.uploadFile(
              {
                Key: key,
                Body: cosParams.fileObject, // 上传文件对象
                // StorageClass: 'STANDARD',
                SliceSize: 1024 * 1024 * 50 /* 触发分块上传的阈值，超过50MB使用分块上传，非必须 */,
                onTaskReady(taskId) {
                  // 上传开始
                  console.log('上传任务创建, id:', taskId);
                },
                onProgress(progressData) {
                  // 上传进度回调
                  console.log('上传进度:', progressData);
                  cb(progressData);
                },
                onFileFinish(err, data, options) {
                  // 每个文件完成或错误回调
                  console.log(options.Key, '上传', err ? '失败' : '完成');
                  err && aegis.error(err);
                },
              },
              (err) => {
                err && aegis.error(err);
                err ? reject(false) : resolve({ data: cdnUrl, origin: { ...cosParams } });
              },
            );
          },
        );
      });
    },
    putCosObjectWithPrivate(cosParams, cos = this.cos, cb = () => {}) {
      return new Promise((resolve, reject) => {
        cos
          .getInstance({
            customGetTokenFunc: () => this.getPrivateUploadSign(cosParams),
          })
          .then(
            ({
              cosInstance, // cos实例
              cdnUrl, // 文件访问的cdn url
              key, // 上传的key，由后台决定，跟token强关联
            }) => {
              cosInstance.putObject(
                {
                  Key: key,
                  Body: cosParams.fileObject, // 上传文件对象
                  onProgress(progressData) {
                    // 上传进度回调
                    console.log('上传进度', progressData);
                    cb(progressData);
                  },
                },
                (err) => {
                  err ? reject(false) : resolve({ data: cdnUrl, origin: { ...cosParams }, key });
                },
              );
            },
          );
      });
    },
    sliceCosObjectWithPrivate(cosParams, cos = this.cos, cb = () => {}, sign) {
      return new Promise((resolve, reject) => {
        cos
          .getInstance({
            customGetTokenFunc: () => this.getPrivateUploadSign(cosParams),
          })
          .then((data) => {
            const {
              cosInstance, // cos实例
              cdnUrl, // 文件访问的cdn url
              key, // 上传的key，由后台决定，跟token强关联
              bucket,
              region,
            } = data;
            let id = '';
            cosInstance.sliceUploadFile(
              {
                Key: key,
                Bucket: bucket,
                Region: region,
                Body: cosParams.fileObject, // 上传文件对象
                onProgress(progressData) {
                  // 上传进度回调
                  console.log('上传进度', progressData);
                  cb(progressData);
                  if (sign.signal) {
                    console.log('中断上传任务');
                    cosInstance.cancelTask(id);
                  }
                },
                onTaskReady(taskId) {
                  id = taskId;
                },
              },
              (err, data) => {
                console.log('|| err', err);
                err ? reject(false) : resolve({ data: cdnUrl, origin: { ...cosParams }, key });
                console.log('|| upload data');
                console.log(data);
              },
            );
          });
      });
    },
    /** 删除文件
     * @description:
     * @param {Object} cosParams {imgCosKey: 对象键（Object 的名称）} eg: 删除图片传url
     * @return {Boolean} promise
     */
    delCosObject(cosParams, cos = this.cos) {
      const imgCosKey = cosParams.imgCosKey.replace(/https.*?.com\//, '');
      return new Promise((resolve, reject) => {
        cos.getInstance({ customGetTokenFunc: this.getTokenFunc }).then(
          ({
            cosInstance, // cos实例
          }) => {
            cosInstance.deleteObject(
              {
                Key: imgCosKey,
              },
              function (err, data) {
                if (err) {
                  reject(false);
                } else {
                  if (data.statusCode === 200 || data.statusCode === 204) {
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                }
              },
            );
          },
        );
      });
    },
    /** 删除私有桶上的文件
     * @description:
     * @param {Object} cosParams {imgCosKey: 对象键（Object 的名称）} eg: 删除图片传url
     * @return {Boolean} promise
     */
    delCosObjectWithPrivate(cosParams, cos = this.cos) {
      const imgCosKey = cosParams.imgCosKey.replace(/https.*?.com\//, '');
      return new Promise((resolve, reject) => {
        cos
          .getInstance({
            customGetTokenFunc: () => this.getPrivateUploadSign(cosParams),
          })
          .then(
            ({
              cosInstance, // cos实例
            }) => {
              cosInstance.deleteObject(
                {
                  Key: imgCosKey,
                },
                function (err, data) {
                  if (err) {
                    reject(false);
                  } else {
                    if (data.statusCode === 200 || data.statusCode === 204) {
                      resolve(true);
                    } else {
                      resolve(false);
                    }
                  }
                },
              );
            },
          );
      });
    },
  },
};

export { CosMixin };
