//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as configAll from './config.js';
export default {
  name: 'ConfigDialog',
  components: {},
  props: {
    // 配置项名称
    configName: {
      type: String,
      default: '',
    },
    // 盒子状态
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogForm: {},
      refName: 'dialogRef',
      config: {},
      loading: false,
    };
  },
  computed: {},
  created() {
    const val = this.configName;
    if (val && configAll[val]) {
      const temp = { ...configAll[val] };
      // 遍历配置项的model
      const key = {};
      temp.query.forEach((_) => {
        key[_.key] = '';
      });
      this.dialogForm = { ...key };
      this.config = temp;
    }
  },
  mounted() {},
  methods: {
    // 盒子事件
    dialogHandle(e) {
      if (e.btnQuery === 'saveClose') {
        this.$refs[this.refName].validate((valid) => {
          if (valid) {
            this.loading = true;
            const { dialogForm } = this;
            this.config
              .api({
                ...dialogForm,
              })
              .then((res) => {
                this.closeDialog();
                this.$message.success(`${e.label}成功`);
                // 保存成功回调, 如刷新表格
                this.$emit('dialogEmit', res);
                this.loading = false;
              })
              .catch((error) => {
                this.$message.error(error.message);
                this.loading = false;
              });
          } else {
            return false;
          }
        });
      } else {
        this.closeDialog();
      }
    },
    // 关闭弹窗 重置数据
    closeDialog() {
      this.$emit('update:dialogVisible', false);
      this.$refs[this.refName].resetFields();
    },
  },
};
