//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
export default {
  name: 'MultipleSelect',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Array, String],
      default: () => [],
    },
  },
  data() {
    return {
      searchText: '',
      page: 1,
      size: 30,
      checkList: [],
    };
  },
  computed: {
    selectOptions() {
      const { pageOptions = [], searchText } = this;
      const options = searchText
        ? _.cloneDeep(pageOptions).reduce((pre, i) => {
            const { label = '' } = i || {};
            if (label) {
              if (label.includes(searchText)) {
                i.htmlLabel = label
                  .split(searchText)
                  .join(`<span style="color: #0069FA;">${searchText}</span>`);
                pre.push(i);
              }
            }
            return pre;
          }, [])
        : pageOptions;
      return options;
    },
    pageOptions() {
      if (!this.searchText) {
        const totalOptions = this.options || [];
        return totalOptions.slice(0, this.page * this.size);
      }
      return this.filterOptions.slice(0, this.page * this.size);
    },
    filterOptions() {
      if (this.searchText) {
        const totalOptions = this.options || [];
        const filterOptions = totalOptions.filter((item) => {
          return item.label?.toLowerCase().includes(this.searchText.toLowerCase());
        });
        return filterOptions;
      }
      return [];
    },
  },
  watch: {
    value(val) {
      console.log('value', val);
      this.checkList = Array.isArray(val) ? val : [];
    },
  },
  methods: {
    change(val) {
      this.$emit('change', val);
    },
    load() {
      const pageTatalSize = this.page * this.size;
      if (!this.searchText) {
        const total = this.options.length;
        const noMoreWhenNoFilter = total > pageTatalSize;
        if (noMoreWhenNoFilter) {
          this.page += 1;
          console.log('pageTatalSize', pageTatalSize);
        }
      } else {
        const filterOptionsSize = this.filterOptions.length;
        const noMoreWhenHadFilter = filterOptionsSize > pageTatalSize;
        if (noMoreWhenHadFilter) {
          this.page += 1;
          console.log('pageTatalSize', pageTatalSize);
        }
      }
    },
  },
};
