import { DatePicker } from 'element-ui';
function override() {
  const handleFocus = DatePicker?.mixins?.[0]?.methods?.handleFocus;
  if(handleFocus) {
    DatePicker.mixins[0].methods.handleFocus = function() {
      handleFocus.call(this);
      // 顶部的日历输入框和时分秒输入框不允许被focus，避免ipad上弹出输入法
      this.$nextTick(()=>{
        if(this.picker?.$el) {
          const els = this.picker.$el.querySelectorAll('.el-date-picker__time-header .el-date-picker__editor-wrap input') || [];
          els.forEach(el => {el.setAttribute('readonly', 'readonly')});
        }
      });
    };
  }
}

override();
