// 策略相关接口

// 策略列表
const StrategyList = `query{mcm_strategy(_order_by:{status:_asc,update_time:_desc},$pageOption,$filter){_aggregate { _count },id,name,remark,status,hcp_strategy{official_accounts,hcp_num},official_strategy{official_accounts{id,name}} }}`;
// 策略详情
const GetStrategyDetail = `query{
  mcm_strategy(id: $id) {
    name
    remark
    domain_tag {
      name
    }
    domain_ids
    major_title {
      name
    }
    major_title_ids
    department {
      name
    }
    department_ids
    hcp_grade
    hco_ids
    hco {
      name
      id
      hco_code
    }
    custom_ids
    gender
    province
    city_code {
      name
      code
      province {
        name
        code
      }
    }
    certification_status_ids
    verify_status_ids
    city
    logic
    filter
    interest_ids
    interest {
      name
    }
    hco_level
    official_strategy {
      official_accounts {
        name
      }
      official_id
    }
  }
}
`;
// 更新策略状态
const UpdateStrategyStatus = `mutation update{mcm_strategy(_where:{id:$id},_set:{status:$status}){id}}`;
// 新建策略
const CreateStrategy = `mutation insert{mcm_strategy(_values:{name:$name,remark:$remark,domain_ids:$domain,custom_ids:$custom_ids,major_title_ids:$major_title,department_ids:$department, province:$province,city:$city,hcp_grade:$hcp_grade,gender:$gender,logic:$logic,filter:$strategy_filter,certification_status_ids:$certification_status_ids,verify_status_ids:$verify_status_ids,hco_ids:$hco_ids,interest_ids:$interest_ids,hco_level:$hco_level}){official_strategy(_values: $official_strategy)}}`;
// 编辑策略
const UpdateStrategy = `mutation update {mcm_strategy(_where: {id: $id}, _set: {sync_status:1,name:$name,remark:$remark,domain_ids:$domain,custom_ids:$custom_ids,certification_status_ids:$certification_status_ids,verify_status_ids:$verify_status_ids,major_title_ids:$major_title,department_ids:$department, province:$province,city:$city,hcp_grade:$hcp_grade,gender:$gender,logic:$logic,filter:$strategy_filter,hco_ids:$hco_ids,interest_ids:$interest_ids,hco_level:$hco_level}){official_strategy(_values: $official_strategy)}}`;
// 删除策略
const DeleteStrategyInfo = `mutation delete {mcm_strategy(id: $id) {id}}`;
const CountStrategyPeople = ` query {   hcp_strategy(strategy: $strategy, official_accounts: $official_accounts){ hcp_num } }`;
const CountAllUserStrategyPeople = ` query {   hcp_official(official_accounts : $official_accounts, hcp.id: {_is_null:false}){  cnt: _count(openid) }   } `;

export {
  StrategyList,
  GetStrategyDetail,
  UpdateStrategyStatus,
  CreateStrategy,
  UpdateStrategy,
  DeleteStrategyInfo,
  CountStrategyPeople,
  CountAllUserStrategyPeople,
};
