//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ImportRosterData } from '@/api/roster';
import { CosMixin } from '@/mixins/cos';
const failTip1 = '请下载查看导入失败原因';
const failTip2 = '导入失败，系统支持最大导入上限5万条';
const failTip3 = '文件格式与模版不符，请调整后重新上传';
const state = {
  suffix: '',
  uploadPercentage: 0,
  fileUrl: '',
  status: 'before', // before, beforeUpload, uploading, done, error
  fileName: '',
  importTip: '',
  cancelingRequest: false,
  poll: 0,
  uploadRes: {
    status: 0, // 后端导入状态，0/2导入中 1导入成功 3导入失败
    status_msg: '',
    file_url: '', // 失败文件链接
    count: 0, // 成功导入数据的数量
  },
};
export default {
  mixins: [CosMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    selectSheet: {
      type: String,
      default: '',
    },
    preCondition: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return state;
  },
  computed: {
    showDownloadLink() {
      return !!this.uploadRes?.file_url?.length;
    },
  },
  watch: {
    status(val) {
      console.log('status', val);
      if (val === 'done') {
        this.restoreState();
        this.$emit('confirm');
      }
    },
  },
  mounted() {
    this.backupState = JSON.stringify(state);
  },
  methods: {
    handleSelectFile(e) {
      if (!this.preCondition) {
        this.$message.warning('请先选择名单');
        e.stopPropagation();
        return;
      }
    },
    confirmHandler() {
      // 如果没有选择名单/文件点击确定，会有全局提示
      if (!this.preCondition) {
        this.$message.warning('请选择名单');
        return;
      }
      if (this.$refs.upload?.uploadFiles?.length <= 0) {
        this.$message.warning('请上传文件');
        return;
      }
      if (!this.fileUrl) {
        this.$message.warning('请等待获取文件上传链接');
        return;
      }
      this.upLoadToBackEnd();
    },
    restoreState() {
      const backupState = JSON.parse(this.backupState);
      Object.keys(backupState).forEach((key) => {
        this.$data[key] = backupState[key];
      });
    },
    closeHandler() {
      this.restoreState();
      this.$emit('close');
    },
    reUoloadHandler() {
      this.$refs.upload.clearFiles();
      this.$refs.upload.$children[0].$refs.input.click();
      this.uploadRes.status = 0;
    },
    deleteFileHandler() {
      this.$refs.upload.clearFiles();
      this.uploadRes.status = 0;
      this.status = 'before';
    },
    beforeUpload(file) {
      // 上传前判断格式
      if (!/.xlsx$/gi.test(file.name)) {
        this.$message.warning('请选择格式正确的文件');
        return false;
      }
      this.suffix = `.${file.name.split('.')[1]}`;
      this.status = 'beforeUpload';
      return true;
    },
    addFile(_, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },
    cancelUploadHandler() {
      this.cancelingRequest = true;
      if (this.poll) clearTimeout(this.poll);
      this.restoreState();
    },
    uploadToCos(file) {
      // 文件上传至腾讯云cos
      const pattern = new RegExp(
        "[%--`~!@#$^&*()=|{}':;',\\[\\]<>/?~！@#￥……&*（）——| {}【】‘；：”“'。，、？]",
      );
      const fileName = file.file.name;
      let cleanName = '';
      for (let i = 0; i < fileName.length; i++) {
        cleanName = cleanName + fileName.substr(i, 1).replace(pattern, '');
      }
      this.fileName = cleanName;
      this.status = 'beforeUpload';
      this.uploadPercentage = 0;
      this.cancelingRequest = false;
      this.putCosObject(
        {
          imgCosKey: `${file.file.uid}${cleanName}`,
          fileObject: file.file,
          suffix: this.suffix,
        },
        undefined,
        // (progressData) => {
        //   this.uploadPercentage = progressData.percent * 20;
        // },
      )
        .then((res) => {
          // check point
          if (this.cancelingRequest) {
            return;
          }
          this.fileUrl = res.data;
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    upLoadToBackEnd() {
      this.status = 'uploading';
      this.uploadPercentage = 20;
      // 后端检查并导入数据
      this.poll = setTimeout(() => {
        // check point
        if (this.cancelingRequest) {
          clearTimeout(this.poll);
          return;
        }
        ImportRosterData({
          file_url: this.fileUrl,
          list_id: this.selectSheet,
        })
          .then((res) => {
            // check point
            if (this.cancelingRequest) {
              clearTimeout(this.poll);
              return;
            }
            if (res.status === 0 || res.status === 2) {
              // 仍需等待
              console.log('当前进度===========', this.uploadPercentage);
              if (this.uploadPercentage < 80) {
                this.uploadPercentage += 10;
              } else if (this.uploadPercentage < 90) {
                this.uploadPercentage += 2;
              } else if (this.uploadPercentage < 99) {
                this.uploadPercentage += 1;
              }
              clearTimeout(this.poll);
              this.upLoadToBackEnd();
            } else {
              // 导入结束
              clearTimeout(this.poll);
              this.uploadPercentage = 100;
              this.uploadRes = res;
              setTimeout(() => {
                switch (res.status) {
                  case 1:
                    this.status = 'done';
                    this.$message.success('上传成功');
                    break;
                  case 3:
                    this.status = 'error';
                    if (this.showDownloadLink) {
                      this.importTip = failTip1;
                      this.$message.error(failTip1);
                    } else {
                      this.importTip = failTip3;
                      this.$message.error(failTip3);
                    }
                    break;
                  case 4:
                    this.status = 'error';
                    this.$message.error(failTip2);
                    this.importTip = failTip2;
                    break;
                }
              }, 300);
            }
          })
          .catch((e) => {
            clearTimeout(this.poll);
            console.error(e);
            this.$message.error = `发生错误：${e.message}`;
          });
      }, 1000);
    },
  },
};
