import _ from 'lodash';

function getJsonObjects(json) {
  let objects = [];
  _.keys(json).forEach((key) => {
    if (key === 'object') {
      objects.push(json[key]);
      return;
    }
    if (_.isObject(json[key])) objects = objects.concat(getJsonObjects(json[key]));
  });
  return _.uniq(objects);
}

function getObjects(json) {
  let objects = getJsonObjects(json);
  const { alias, fields = [] } = json;
  const fieldArr = fields.map((item) => item.field || item);
  fieldArr.forEach((itemField) => {
    if (itemField.includes('.')) {
      const itemFieldArr = itemField.split('.');
      objects = objects.concat(itemFieldArr.slice(0, itemFieldArr.length - 1));
    }
  });
  if (alias) {
    objects = objects.map((item) => alias[item] || item);
  }
  return _.uniq(objects);
}

export { getJsonObjects, getObjects };
