export const queryAdtags = `query{
    mcm_adtags(_where: { is_trace: 1 }, _limit: 30, _offset: 0,){
        official_id,
        is_trace,
        create_time,
        id,
        name,
        record_type,
        adtag,
        owner,
        official_account {
            name
            appid
            id
        }
    }
  }`;
