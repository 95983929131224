// 将对象值转成字符串
function objToStr(obj) {
  const newObj = {};
  for (const k of Object.keys(obj)) {
    const v = typeof obj[k] === 'string' ? obj[k] : JSON.stringify(obj[k]);
    newObj[k] = v;
  }
  return newObj;
}

class TrackingReportCore {
  constructor(config) {
    this.startFlag = false;
    this.eventsCache = [];
    this.reportCache = [];
    this.reportingPool = 0;
    this.eventsCacheNum = config.eventsCacheNum || 10;
    this.reportCacheNum = config.reportCacheNum || 1;
    this.queueWaitTime = config.queueWaitTime || 2000;
    this.reportTimeout = config.reportTimeout || 60000; // 请求超时时间
    this.queueTimeoutObj = null;
    this.commonData = {};
    this.httpSend = config?.httpSend || function () {};
  }

  // public: 暴露的上报方法
  sendEvent(eventValue) {
    const mapValue = objToStr(eventValue);
    this.eventsCache.push(mapValue);
    this.checkSendEvent();
  }

  // public: 开启上报，必须使用此参数，才会触发上报
  start() {
    this.startFlag = true;
    this.checkSendEvent();
  }

  // public:强制上报队列中的所有数据
  flush() {
    this.checkSendEvent(true);
    this.checkReport(true);
  }

  // private:检测队列是否已满，是否可以触发合并上报
  checkSendEvent(force = false) {
    // 未开启，则不触发上报
    if (!this.startFlag) {
      return;
    }
    if (this.queueTimeoutObj) {
      clearTimeout(this.queueTimeoutObj);
    }
    if (force || this.eventsCache.length >= this.eventsCacheNum) {
      this.sendEventToBackend();
    } else {
      this.queueTimeoutObj = setTimeout(() => {
        this.sendEventToBackend();
        clearTimeout(this.queueTimeoutObj);
      }, this.queueWaitTime);
    }
  }

  // private:合并上报数据并上报
  sendEventToBackend() {
    while (this.eventsCache.length) {
      const events = this.eventsCache.splice(0, this.eventsCacheNum);
      const body = {
        data: events,
      };
      this.reportCache.push(body);
    }
    this.checkReport();
  }

  // private:上报请求队列根据可同时上报限制数量,控制发起上报请求
  checkReport(force = false) {
    if ((force || this.reportingPool < this.reportCacheNum) && this.reportCache.length) {
      const count = force
        ? this.reportCache.length
        : Math.min(this.reportCache.length, this.reportCacheNum - this.reportingPool);
      for (let i = 0; i < count; i++) {
        const reportReq = this.reportCache.shift();
        if (reportReq) {
          // eslint-disable-next-line no-plusplus
          ++this.reportingPool;
          this.send(reportReq);
          // .catch((e) => {
          //   console.log('report error,', e);
          // })
          // .finally(() => {
          // eslint-disable-next-line no-plusplus
          --this.reportingPool;
          this.checkReport(force);
          // });
        }
      }
    }
  }
  // private:调灯塔接口进行上报
  send(reportReq) {
    return this?.httpSend(reportReq, {
      timeout: this.reportTimeout,
    });
  }
}

export default TrackingReportCore;
