//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { McmAdminService } from '@/graphql/api/list';
import { whereGql } from '@/graphql/util';
import { getFieldMap, queryField } from '@/json/query';
import _ from 'lodash';

export default {
  name: 'Cascader',
  props: {
    filterObj: {
      type: Object,
      default: () => {},
    },
    formValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      comfilterObj: '',
      // 是否已经加载了value的详情，在编辑时会用到
      isLoadValueData: false,
      options: [],
    };
  },
  computed: {
    style() {
      return this.comfilterObj.filter.style || {};
    },
    disabled() {
      return this.filterObj?.filter?.jsonFilter?.disabled;
    },
  },
  watch: {
    filterObj(val) {
      this.comfilterObj = val;
    },
  },
  async created() {
    this.comfilterObj = this.filterObj;
  },
  updated() {
    console.log('===update', this.formValue);
    this.getEditData();
  },
  methods: {
    async getEditData() {
      try {
        if (!this.isLoadValueData) {
          this.isLoadValueData = true;
          const editValue = this.formValue[this.filterObj.prop];
          if (editValue) {
            const { jsonFilter = {} } = this.filterObj?.filter || {};
            const { value } = jsonFilter;
            this.options = await this.getData(editValue, value);
          }
        }
      } catch (err) {
        console.error(err);
        this.isLoadValueData = false;
      }
    },
    async getData(query, likeField) {
      try {
        console.log('===', this.filterObj, this.formValue);
        const { jsonFilter = {} } = this.filterObj?.filter || {};
        const {
          object,
          label,
          value,
          groupField = '',
          order,
          aliasField = '',
          queryField: jsonQueryField,
        } = jsonFilter;
        const fieldMap = getFieldMap({ json: { fields: [groupField] } });
        const queryFields = `${label} ${value} ${queryField(fieldMap)} ${aliasField}`;
        let whereFields = [
          {
            whereGqlType: 'like',
            [likeField || jsonQueryField]: `%${query}%`,
          },
        ];
        whereFields = whereGql(whereFields);
        let orderStr = '';
        if (order && order.field && order.type) {
          orderStr = `,_order_by:{${order.field}:_${order.type}}`;
        }
        const detailMutation = `query { ${object}(${whereFields}${orderStr}) {${queryFields}} }`;
        const res = await McmAdminService({
          query: detailMutation,
        });
        const groupFieldsList = groupField.split('.');
        let data = res[object] || [];
        if (groupFieldsList.length > 1) {
          data = data.reduce((pre, i) => {
            const group = _.get(i, groupFieldsList[0]);
            if (group?.length) {
              group.forEach((item) => {
                const groupVal = _.get(item, groupFieldsList.slice(1).join('.'));
                if (groupVal) {
                  if (!pre[groupVal]) {
                    pre[groupVal] = [];
                  }
                  pre[groupVal].push(i);
                }
              });
            }
            return pre;
          }, {});
          data = Object.keys(data).reduce((pre, groupName) => {
            pre.push({
              label: groupName,
              options: data[groupName].map((groupItem) => ({
                value: groupItem[value],
                label: groupItem[label],
                aliasField: groupItem[aliasField],
              })),
            });
            return pre;
          }, []);
        }
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    handleChange(e) {
      const { prop } = this.comfilterObj;
      this.$emit('change', { prop, value: e });
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(async () => {
          this.loading = false;
          this.options = await this.getData(query);
        }, 200);
      } else {
        this.options = [];
      }
    },
  },
};
