import { formatTime } from 'nges-common/src/util';

export const refineProcedure = async function (procedures) {
  const rt = await Promise.all(
    procedures.map(async (p) => {
      if (p.type === 'img') {
        const myImage = new Image();
        myImage.src = p.src;
        myImage.crossOrigin = 'Anonymous';
        return new Promise((resolve, reject) => {
          myImage.onload = () => {
            // reassign
            p.src = myImage;
            resolve(p);
          };
          myImage.onerror = () => {
            reject();
          };
        });
      }
      return p;
    }),
  );
  return rt;
};

/**
 * 格式化时间
 * @param {String} stime 开始时间
 * @param {String} etime 结束时间
 * @returns {String} 格式化开始结束时间
 */
export const combineTime = (stime, etime) => {
  const date1 = new Date(Number(stime) * 1000);
  const date2 = new Date(Number(etime) * 1000);
  if (date1.getDate() !== date2.getDate()) {
    return `${formatTime(Number(stime), 'mm月dd日 hh:ii')}-${formatTime(
      Number(etime),
      'mm月dd日 hh:ii',
    )}`;
  }
  return `${formatTime(Number(stime) * 1000, 'mm月dd日 hh:ii')}-${formatTime(
    Number(etime) * 1000,
    'hh:ii',
  )}`;
};
