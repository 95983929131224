//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isString } from 'lodash';
import { queryTagCategories, queryCategoryTagsAll } from '@/graphql/api/tag';
import TagCategoryDialog from './tagCategoryDialog.vue';
import { getKvConfigs } from '@/graphql/api/meeting';
import draggable from 'vuedraggable';
export default {
  name: 'TagSelect',
  components: { TagCategoryDialog, draggable },
  props: {
    // 改为在重楼中对 require_pages 字段进行场景配置
    // readingArticleRequiredConfig: {
    //   // 是否读取文章是否必填的配置
    //   type: Boolean,
    //   default: () => false,
    // },
    readOnly: {
      type: Boolean,
      default: false,
    },
    // 使用组件的页面类型，在KV表中 mcm_page_list 定义，默认''代表不做判断
    pageType: {
      type: String,
      default: '',
    },
    // 显示拖拽排序的标签名称, 字符串以“,”号分割。
    sortableTags: {
      type: String,
      default: '',
    },
    // 显示替换文本placeholder(目前只有文章管理编辑里面使用)
    showPrompt: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: [Object, null, undefined],
      default: null,
    },
  },
  data() {
    return {
      formValue: {},
      resTags: [], // 选择的结果
      rawtagOptions: [], // 原始的标签数据
      tagOptions: {}, // 按 el-cascader 格式结构化的标签数据
      categories: [],
      selectedCategoryIds: [],
      // 当前使用组件的页面code
      pageCode: 0,
      // 展示排序功能的默认标签组集合name
      sortableTagsArray: [],
      // 展示排序功能的默认标签组集合id
      sortableTagsIdArray: [],
      // 开启排序功能的集合formValue
      sortableTagsFormValue: {},
      setTagsData: [],
      clearData: false,
      isInit: false,
      rowWidthStyle: '',
      selectWidthStyle: '',
    };
  },
  computed: {
    isLabelPositionTop() {
      // 通用/Users/xu/Documents/H5/nges-front-end/packages/nges-common/src/web/components/layout/mutation/components/mutation.vue
      // 这个文件用样式修改的. 非属性修改, 没办法只能重置了
      return this.layout?.style?.label_position === 'top';
    },
    labelPositionClass() {
      return `label-position-${this.layout?.style?.label_position || 'left'}`;
    },
    // 默认标签组
    defaultCategories() {
      return (
        this.categories?.filter(
          (c) => c.is_default === 1 && c.display_pages.includes(this.pageCode),
        ) || []
      );
    },
    // 已选的可选标签组
    selectedCategories() {
      return this.categories.filter(
        (c) => c.is_default !== 1 && this.selectedCategoryIds.includes(c.id),
      );
    },
    // 未选的可选标签组
    otherCategories() {
      return this.categories.filter(
        (c) => c.is_default !== 1 && !this.selectedCategoryIds.includes(c.id),
      );
    },
  },
  watch: {
    layout: {
      handler() {
        this.processWidthStyle();
      },
      immediate: true,
      // deep: true,
    },
  },
  created() {
    this.getTagCategories();
  },
  async mounted() {
    this.loading = true;
    const { kv } = await getKvConfigs({
      keys: ['mcm_page_list'],
    });
    const typeOptions = kv?.find(({ key }) => key === 'mcm_page_list')?.value;
    let kvData = {
      article_page: 1,
      material_page: 2,
      vote_page: 3,
    };
    if (typeOptions?.length) {
      kvData = JSON.parse(typeOptions);
    }
    this.pageCode = kvData[this.pageType];
    console.log('当前使用组件的页面code: ', this.pageCode);
    this.sortableTagsArray = this.sortableTags.split(',');
    this.sortableTagsIdArray = this.sortableTagsArray.map(
      (name) => this.categories.find((i) => i.name === name)?.id,
    );
    console.log('当前使用组件展示排序功能为：', this.sortableTagsArray, this.sortableTagsIdArray);
    this.$emit('tag-select-mounted');
    this.loading = false;
  },
  methods: {
    getWidthPX(width) {
      let widthPX = width;
      if (widthPX && isString(widthPX) && widthPX.endsWith('px')) {
        widthPX = widthPX.replace('px', '');
      }
      return widthPX ? Number(widthPX) : 0;
    },
    isDefaultCategory(categorire) {
      return categorire?.is_default === 1;
    },
    canDelete(categorire) {
      return categorire?.id && !this.isDefaultCategory(categorire) && !this.readOnly;
    },
    onDragEnd() {
      this.cascaderChange();
    },
    setTags(arr, clearData = false) {
      this.setTagsData = arr;
      this.clearData = clearData;
      if (this.isInit) {
        this.doSetTags(this.setTagsData, this.clearData);
      }
    },
    async doSetTags(arr, clearData = false) {
      this.loading = true;
      if (clearData) {
        // this.sortableTagsArray = [];
        // this.sortableTagsIdArray = [];
        this.sortableTagsFormValue = {};
        this.selectedCategoryIds = [];
        this.formValue = {};
      }
      // 没有配置 is_default 为true的标签组需加载tag内容。
      const tagCategoryInfo = [];
      arr.forEach((tag) => {
        const tagItem = tagCategoryInfo.find((item) => item.id === tag.category_id);
        if (!tagItem) {
          tagCategoryInfo.push({
            id: tag.category_id,
            value: [tag.id],
          });
          if (!this.selectedCategoryIds.includes(tag.category_id)) {
            this.selectedCategoryIds.push(tag.category_id);
          }
        } else if (!tagItem.value.includes(tag.id)) {
          tagItem.value.push(tag.id);
        }
      });
      const extraCategoryIds = tagCategoryInfo
        .filter((tag) => {
          return !this.categories.find((c) => c.id === tag.id).tag;
        })
        .map((item) => item.id);
      console.log('已选tag所属标签组信息：', tagCategoryInfo, extraCategoryIds);
      if (extraCategoryIds.length) {
        const result = await this.getTagOptionsAll(extraCategoryIds);
        console.log('额外请求的标签组: ', result);
        if (result && result.length) {
          result.forEach((item) => {
            this.setTagOptions(item.id, item.tag);
          });
        }
      }

      // 已选值设置
      tagCategoryInfo.forEach((tagCategory) => {
        // eslint-disable-next-line no-param-reassign
        tagCategory.value = tagCategory.value.filter((tagId) =>
          // 过滤标签，只有标签组中存在的标签才添加到已选值中
          this.rawtagOptions.find((r) => r.id === tagId),
        );
        this.$set(this.formValue, tagCategory.id, tagCategory.value);
      });

      // 排序处理放在最后，避免受到之前异步请求影响。
      tagCategoryInfo.forEach((tagCategory) => {
        if (this.sortableTagsIdArray.includes(tagCategory.id)) {
          this.$set(this.sortableTagsFormValue, tagCategory.id, tagCategory.value);
          console.log(`setTags设置sortableTagsFormValue顺序: ${tagCategory.id}`, tagCategory.value);
        }
      });
      this.loading = false;
      this.cascaderChange();
    },
    getTags() {
      return this.resTags;
    },
    validate() {
      let valid = true;
      // if (!this.readingArticleRequiredConfig) {
      //   return valid;
      // }
      [...this.defaultCategories, ...this.selectedCategories].forEach((c, index) => {
        if (
          c.require_pages.includes(this.pageCode) &&
          (!this.formValue[c.id] || this.formValue[c.id].length === 0)
        ) {
          valid = false;
          // 延时弹出 message，以防 message 重叠
          setTimeout(() => {
            this.$message.error(`标签组：${c.name} 未填写`);
          }, 10 * index);
        }
      });
      return valid;
    },
    getTagCategories() {
      queryTagCategories().then((res) => {
        this.categories = res?.tag_category;
        const defaultArr = this.categories.filter((tag) => tag.is_default === 1);
        defaultArr.forEach((item) => {
          this.setTagOptions(item.id, item.tag);
        });
        this.isInit = true;
        if (this.setTagsData.length) {
          this.doSetTags(this.setTagsData, this.clearData);
        }
      });
    },
    cascaderProps() {
      return {
        expandTrigger: 'hover',
        multiple: true,
        emitPath: false,
        checkStrictly: true,
      };
    },
    cascaderPlaceholder(categorire) {
      return (this.showPrompt ? categorire.article_prompt : '') || '请选择';
    },
    async getTagOptionsAll(categorireIds) {
      const res = await queryCategoryTagsAll({ category_ids: categorireIds });
      return res?.tag_category || [];
    },
    async setTagOptions(categorireId, tag) {
      const targetCategory = this.categories.find((c) => c.id === categorireId);
      if (!targetCategory) {
        // 如果标签组不存在
        return false;
      }

      const list = tag;
      if (list) {
        this.rawtagOptions = [...this.rawtagOptions, ...list];
      }

      const maxLevel = targetCategory.max_level || 1;
      let level1 = [];
      let level2 = [];
      let level3 = [];
      let level4 = [];
      let level5 = [];
      let level6 = [];
      let level7 = [];
      let level8 = [];
      let level9 = [];
      const level10 = [];
      list?.forEach((i) => {
        const ni = {
          value: i.id,
          label: i.name,
          ...i,
        };
        if (i.status === '1') {
          ni.disabled = true;
        }
        if (i.ninth_parent_id) {
          level10.push(ni);
        } else if (!i.ninth_parent_id && i.eighth_parent_id) {
          if (maxLevel > 9) {
            ni.disabled = true; // 如果标签深度为10，则禁用9级
          }
          level9.push(ni); // 有8标签无9标签，则为9级标签
        } else if (!i.eighth_parent_id && i.seventh_parent_id) {
          if (maxLevel > 8) {
            ni.disabled = true; // 如果标签深度为9，则禁用8级
          }
          level8.push(ni); // 有7标签无8标签，则为8级标签
        } else if (!i.seventh_parent_id && i.sixth_parent_id) {
          if (maxLevel > 7) {
            ni.disabled = true;
          }
          level7.push(ni);
        } else if (!i.sixth_parent_id && i.fifth_parent_id) {
          if (maxLevel > 6) {
            ni.disabled = true;
          }
          level6.push(ni);
        } else if (!i.fifth_parent_id && i.fourth_parent_id) {
          if (maxLevel > 5) {
            ni.disabled = true;
          }
          level5.push(ni);
        } else if (!i.fourth_parent_id && i.third_parent_id) {
          if (maxLevel > 4) {
            ni.disabled = true;
          }
          level4.push(ni);
        } else if (!i.third_parent_id && i.grand_id) {
          if (maxLevel > 3) {
            ni.disabled = true;
          }
          level3.push(ni);
        } else if (!i.grand_id && i.parent_id) {
          if (maxLevel > 2) {
            ni.disabled = true; // 如果标签深度为3，则禁用2级
          }
          level2.push(ni); // 有父标签无爷标签，则为2级标签
        } else {
          if (maxLevel > 1) {
            ni.disabled = true; // 如果标签深度为2或3，则禁用1级
          }
          level1.push(ni); // 其他为1级标签
        }
      });
      level9 = this.setTagStruct(level9, level10, maxLevel, 9);
      level8 = this.setTagStruct(level8, level9, maxLevel, 8);
      level7 = this.setTagStruct(level7, level8, maxLevel, 7);
      level6 = this.setTagStruct(level6, level7, maxLevel, 6);
      level5 = this.setTagStruct(level5, level6, maxLevel, 5);
      level4 = this.setTagStruct(level4, level5, maxLevel, 4);
      level3 = this.setTagStruct(level3, level4, maxLevel, 3);
      level2 = this.setTagStruct(level2, level3, maxLevel, 2);
      level1 = this.setTagStruct(level1, level2, maxLevel, 1);

      // 清空1级目录下空内容
      level1 = level1.filter((i) => !!i);
      this.$set(this.tagOptions, categorireId, level1);
      return true;
    },
    setTagStruct(parentLevel, childrenLevel, maxLevel, level) {
      // 将 childrenLevel 标签存入 parentLevel 中
      return parentLevel.map((l) => {
        const children = [];
        childrenLevel.forEach((c) => {
          if (c.parent_id === l.id) {
            children.push(c);
          }
        });
        // 原本三目表达式最后返回l2，但当没有三级时二级不需要存在，故返回""
        if (maxLevel > level) {
          return children.length ? { ...l, children } : '';
        }
        return children.length ? { ...l, children } : l;
      });
    },
    getSortableTagName(id) {
      return this.rawtagOptions.find((c) => c.id === id)?.name || '';
    },
    async selectCategory(newIds) {
      const result = await this.getTagOptionsAll(newIds);
      if (result && result.length) {
        result.forEach((item) => {
          this.setTagOptions(item.id, item.tag);
        });
      }
      this.selectedCategoryIds = [...this.selectedCategoryIds, ...newIds];
    },
    addTagCategory() {
      this.$refs.tagCategoryDialog.open();
    },
    removeTagCategory(categorire) {
      if (!this.canDelete(categorire)) {
        return;
      }
      const { id } = categorire;

      this.selectedCategoryIds = this.selectedCategoryIds.filter((ci) => ci !== id);
      this.$set(this.formValue, id, []);
      this.$set(this.sortableTagsFormValue, id, []);
      this.cascaderChange();
    },
    cascaderDataChange(categoryId, data) {
      console.log('修改的data: ', categoryId, data);
      if (this.sortableTagsIdArray.includes(categoryId)) {
        let arr = this.sortableTagsFormValue[categoryId]
          ? [...this.sortableTagsFormValue[categoryId]]
          : [];
        // 先判断删除逻辑
        arr = arr.filter((item) => data.includes(item));
        // 再判断添加逻辑
        data.forEach((item) => {
          if (!arr.includes(item)) {
            arr.push(item);
          }
        });
        this.$set(this.sortableTagsFormValue, categoryId, arr);
      }
      this.cascaderChange();
    },
    cascaderChange() {
      const tagIds = [].concat(
        ...Object.keys(this.formValue).map(
          (key) => this.sortableTagsFormValue[key] || this.formValue[key],
        ),
      );
      // 获取全部tagId
      this.resTags = tagIds.reduce((list, id) => {
        const obj = this.rawtagOptions.find((to) => to.id === id);
        return obj ? [...list, obj] : list;
      }, []);
      console.log('tagSelect保存顺序：', this.resTags);
    },
    // 级联选择框的样式
    popperClass(category) {
      const maxLevel = category.max_level || 1;
      if (maxLevel === 10) {
        return 'tag-select-el-cascader-popper-class-hidden-disabled-nine';
      }
      if (maxLevel === 9) {
        return 'tag-select-el-cascader-popper-class-hidden-disabled-eight';
      }
      if (maxLevel === 8) {
        return 'tag-select-el-cascader-popper-class-hidden-disabled-seven';
      }
      if (maxLevel === 7) {
        return 'tag-select-el-cascader-popper-class-hidden-disabled-six';
      }
      if (maxLevel === 6) {
        return 'tag-select-el-cascader-popper-class-hidden-disabled-five';
      }
      if (maxLevel === 5) {
        return 'tag-select-el-cascader-popper-class-hidden-disabled-four';
      }
      if (maxLevel === 4) {
        return 'tag-select-el-cascader-popper-class-hidden-disabled-three';
      }
      if (maxLevel === 3) {
        return 'tag-select-el-cascader-popper-class-hidden-disabled-two'; // 隐藏第一级和第二级的勾选 disabled 标签
      }
      if (maxLevel === 2) {
        return 'tag-select-el-cascader-popper-class-hidden-disabled-one'; // 隐藏第一级的勾选 disabled 标签
      }
      if (maxLevel === 1) {
        return 'tag-select-el-cascader-popper-class-hidden-disabled-zero'; // 不隐藏 disabled 标签
      }
      return ''; // 无特殊样式
    },
    limitLabel(label) {
      if (label?.length >= 12) {
        return `${label.substr(0, 12)}...`;
      }
      return label;
    },
    processWidthStyle() {
      const deletBtnWidth = 40;
      let { row_width: rowWidth, select_width: selectWidth } =
        this.layout?.style || this.layout?.filter?.style || {};
      selectWidth = Number(this.getWidthPX(selectWidth));
      if (selectWidth) {
        this.rowWidthStyle = `width: ${selectWidth + deletBtnWidth}px`;
        this.selectWidthStyle = `width: ${selectWidth}px`;
      } else {
        rowWidth = this.getWidthPX(rowWidth) || 400;
        this.rowWidthStyle = `width: ${rowWidth}px`;
        this.selectWidthStyle = `width: ${rowWidth - deletBtnWidth}px`;
      }
    },
  },
};
