//
//
//
//
//
//
//
//
//
//
//
//
//

/*
vue-ueditor-wrap+秀米参考文档: https://hc199421.gitee.io/vue-ueditor-wrap/#/xiumi
ueditor-plus(基于3.4.0): https://github.com/modstart-lib/ueditor-plus
文档: https://open-doc.modstart.com/ueditor-plus/manual.html
demo: https://open-demo.modstart.com/ueditor-plus/_examples/
*/

import VueUeditorWrap from 'vue-ueditor-wrap';
import { outsideQuestionHtml } from './utils';
export default {
  name: 'VueUeditor',
  components: {
    VueUeditorWrap,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    imageList: {
      type: Array,
      default: () => [],
    },
    videoList: {
      type: Array,
      default: () => [],
    },
    audioList: {
      type: Array,
      default: () => [],
    },
    voteList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    //     , toolbars: [[
    //     'fullscreen', 'source', '|', 'undo', 'redo', '|',
    //     'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
    //     'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
    //     'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
    //     'directionalityltr', 'directionalityrtl', 'indent', '|',
    //     'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
    //     'link', 'unlink', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
    //     'simpleupload', 'insertimage', 'emotion', 'scrawl', 'insertvideo', 'music', 'attachment', 'map', 'gmap', 'insertframe', 'insertcode', 'webapp', 'pagebreak', 'template', 'background', '|',
    //     'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
    //     'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
    //     'print', 'preview', 'searchreplace', 'drafts', 'help'
    // ]]

    return {
      ueditor: '',
      ueditorConfig: {
        toolbars: [
          [
            // 'fullscreen', // 全屏
            // 'source', // 源码
            'undo', // 撤销
            'redo', // 前进
            '|',
            'removeformat', // 清除样式
            '|',
            'fontsize', // 字号
            'bold', // 加粗
            'italic', // 斜体
            'underline', // 下划线
            'strikethrough', // 删除线
            // 'fontborder', // 字符边框
            // 'formatmatch', // 格式刷
            // 'fontfamily', // 字体
            // 'emotion', // 表情 (url得替换, 百度url)
            // 'spechars', // 特殊字符
            'forecolor', // 字体颜色
            'backcolor', // 背景色
            '|',
            'justifyleft', // 居左对齐
            'justifycenter', // 居中对齐
            'justifyright', // 居右对齐
            'justifyjustify', // 两端对齐
            'indent',
            '|',
            // 'insertorderedlist', // 有序列表
            'insertunorderedlist', // 无序列表
            'lineheight', // 行间距
            'inserttable', // 表格
            'blockquote', // 引用
            'link',
            // 'imagecenter', // 居中
          ],
        ],
        // 编辑器不自动被内容撑高
        autoHeightEnabled: true,
        // 初始容器高度
        initialFrameHeight: 500,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // UEditor 资源文件的存放路径
        UEDITOR_HOME_URL: '/UEditor/',
        // TODO: @derrysong 看能不能引入plus, 这个版本UI和功能会稳定些, 我引入了一下, iconfont丢失了
        // UEDITOR_HOME_URL: '/UEditorPlus/',
      },
      htmlText: '',
      isInit: false,
    };
  },
  watch: {
    value: {
      handler(val) {
        console.log(val);
        // let text = val;
        // console.log(text);
        // if (!this.isInit) {
        //   // 替换秀米+投票的场景
        //   this.voteList?.forEach((vote) => {
        //     const { id = '', name = '' } = vote;
        //     const reg = new RegExp(`<i voteId="${id}"></i>`, 'gi');
        //     const arr = text.split('</section>');
        //     arr.pop();
        //     text = `${arr.join('</section>')}<i voteId="${id}"></i>`;
        //     text = text.replace(
        //       reg,
        //       `<section><span style="color: rgb(0, 129, 204);">[${name}]</span></section></section>`,
        //     );
        //   });
        //   this.isInit = true;
        // }
        this.htmlText = val;
      },
      immediate: true,
    },
    htmlText: {
      handler(val) {
        this.$emit('input', val);
      },
    },
    voteList: {
      handler(val) {
        if (this.ueditor && val) {
          console.log('vote 信息：', val);
          val.forEach((voteName) => {
            this.ueditor.focus();
            this.ueditor.execCommand(
              'inserthtml',
              `<p><br/></p><p contenteditable="false"><span style="color: rgb(0, 129, 204);">[${voteName}]</span></p><p><br/></p>`,
            );
          });
        }
      },
    },
    imageList: {
      handler(val) {
        if (this.ueditor && val) {
          // imageList在编辑页中，进行了reverse反转.. 这里只能从后往前遍历进行兼容
          for (let i = val.length - 1; i >= 0; i--) {
            const item = val[i];
            console.log(item);
            this.ueditor.focus();
            const img = document.createElement('img');
            img.setAttribute('src', item.url); // 设置audio的src属性
            img.setAttribute('mate_id', item.mate_id); // 设置id
            img.setAttribute('mate_name', item.mate_name);
            img.setAttribute('style', 'vertical-align: top;'); // 设置图片对齐方式
            img.setAttribute('mate_data', item.mate_data);
            img.setAttribute('publicInfo', JSON.stringify(item.public_list)); // 设置一个id
            img.setAttribute('agentInfo', JSON.stringify(item.agent_list)); // 设置微信应用信息
            this.ueditor.execCommand('inserthtml', `<p><br/></p>${img.outerHTML}<p><br/></p>`);
          }
        }
      },
    },
    audioList: {
      handler(val) {
        if (this.ueditor && val) {
          val.forEach((item) => {
            this.ueditor.focus();
            const audio = document.createElement('audio');
            audio.setAttribute('src', item.url); // 设置audio的src属性
            audio.setAttribute('mate_id', item.mate_id); // 设置id
            audio.setAttribute('mate_name', item.mate_name); // 设置id
            audio.setAttribute('publicInfo', JSON.stringify(item.public_list)); // 设置公众号信息
            audio.setAttribute('agentInfo', JSON.stringify(item.agent_list)); // 设置微信应用信息
            audio.setAttribute('preload', 'auto'); // 设置音频预加载
            audio.setAttribute('controls', 'true'); // 设置audio的controls，否则他将不会显示
            audio.setAttribute('controlsList', 'nodownload'); // 设置audio的下载功能为不能下载
            audio.setAttribute('class', 'audio'); // 设置一个id
            audio.setAttribute('style', 'width:100%'); // 设置一个id
            this.ueditor.execCommand(
              'inserthtml',
              `<p><br/></p><section style="background: rgb(241, 243, 244);min-height: 91px;overflow:hidden;width: 100%;display: flex;flex-direction: column;justify-content: center;margin-bottom:7px">${audio.outerHTML}<p contenteditable="false" style="font-size: 14px;line-height: 22px;color: rgb(28, 32, 40);margin:0 20px;text-decoration: none;">${item.mate_name}</p></section><p><br/></p>`,
            );
          });
        }
      },
    },
    videoList: {
      handler(val) {
        if (this.ueditor && val) {
          val.forEach((item) => {
            this.ueditor.focus();
            const video = document.createElement('video');
            video.setAttribute('src', item.url); // 设置audio的src属性
            video.setAttribute('mate_id', item.mate_id); // 设置id
            video.setAttribute('mate_name', item.mate_name); // 设置id
            video.setAttribute('publicInfo', JSON.stringify(item.public_list)); // 设置公众号信息
            video.setAttribute('agentInfo', JSON.stringify(item.agent_list)); // 设置微信应用信息
            video.setAttribute('controls', 'true'); // 设置audio的controls，否则他将不会显示
            video.setAttribute('controlsList', 'nodownload'); // 设置audio的下载功能为不能下载
            video.setAttribute('class', 'ql-video'); // 设置一个id
            video.style.maxWidth = '100%';
            video.innerText = '1';
            this.ueditor.execCommand('inserthtml', `<p><br/></p>${video.outerHTML}<p><br/></p>`);
          });
        }
      },
    },
  },
  beforeDestroy() {
    this.ueditor = null;
    delete this.ueditor;
  },
  methods: {
    ready(editorInstance) {
      // 富文本初始化完成触发
      this.ueditor = editorInstance;
    },
    insertOutsideQuestions(data = []) {
      const { ueditor } = this;
      if (ueditor && data.length) {
        data.forEach((item) => {
          ueditor.focus();

          // 插入最外层
          // const elementpath = ueditor.queryCommandValue('elementpath');
          // if (elementpath?.length > 2) {
          //   // 插入到中间位置, 移动端解析会报错
          //   ueditor.execCommand('elementpath', 1);
          //   // 获取光标所在位置
          //   const range = ueditor.selection.getRange();
          //   range.collapse(false);
          //   range.select();
          // }

          // // 设置光标位置到当前行的开头
          // const txt = range.startContainer.textContent.substr(0, range.startOffset);
          // range.setStart(range.startContainer, txt.lastIndexOf('\n') + 1);

          // // 获取选区范围
          // const selectionRange = range.cloneRange();

          // // 修改选区范围，选中光标之前的文本
          // selectionRange.setEnd(range.startContainer, range.startOffset);

          // // 将选中的文本保存到变量中
          // const selectedText = selectionRange.toString();
          // console.log(selectedText);
          // debugger;
          ueditor.execCommand('inserthtml', outsideQuestionHtml(item));
        });
      }
    },
  },
};
