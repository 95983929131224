//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import validateBox from 'nges-common/src/web/components/validate-box';
import { execExpressionDeep } from 'nges-common/src/layout/expression';
import { QueryRuleCheck } from 'nges-common/src/web/utils/api';

export default {
  name: 'LayoutDetailValidate',
  components: { validateBox },
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    validateStatus: {
      type: String,
      default: '',
    },
    expParamsObj: {
      type: Object,
      default: () => ({}),
    },
    object: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      validate: {
        warning: [],
        error: [],
        success: [],
      },
    };
  },
  computed: {
    validateText() {
      switch (this.validateStatus) {
        case 'success':
          return this.field.render?.text?.success || '已合规';
        case 'warning':
          return this.field.render?.text?.warning || '有提醒';
        case 'error':
          return this.field.render?.text?.error || '未通过';
        default:
          return this.field.render?.text?.default || '未检查';
      }
    },
    validateType() {
      switch (this.validateStatus) {
        case 'success':
          return 'success';
        case 'warning':
          return 'warning';
        case 'error':
          return 'danger';
        default:
          return 'primary';
      }
    },
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    detailValidate() {
      this.loading = true;
      const request = {
        object: this.object,
        ...execExpressionDeep(this.field?.payload, this.expParamsObj),
      };
      try {
        request.ext = JSON.stringify(request.ext);
      } catch (e) {
        console.log(e);
        request.ext = '';
      }
      QueryRuleCheck(request)
        .then(({ items }) => {
          this.validate = {
            success: [],
            warning: [],
            error: [],
          };
          items.forEach((item) => {
            switch (item.type) {
              case 'CR_PASS':
                this.validate.success.push(item);
                break;
              case 'CR_WARNING':
                this.validate.warning.push(item);
                break;
              case 'CR_ERROR':
                this.validate.error.push(item);
                break;
              default:
                break;
            }
          });
          if (this.validate.error.length) {
            this.$emit('change', 'error');
          } else if (this.validate.warning.length) {
            this.$emit('change', 'warning');
          } else {
            this.$emit('change', 'success');
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
