//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cloneDeep } from 'lodash';
import DashboardChild from './child.vue';
import { layoutRequest } from 'nges-common/src/layout/layoutRequest';

const _ = { cloneDeep };

export default {
  name: 'MiniDashboardBlock',
  components: { DashboardChild },
  props: {
    json: {
      type: Object,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => {},
    },
    placement: {
      type: String,
      default: 'bottom',
    },
  },
  data() {
    return {
      loading: true,
      dataSource: {},
    };
  },
  computed: {
    list() {
      return this.json?.render?.groups || [];
    },
    expParamsObj() {
      return {
        t: {
          ...this.dataSource,
        },
        p: this.params,
      };
    },
  },
  methods: {
    init() {
      const requestList = this.$refs.child.map((ele) => ele.init());
      this.json.ignore_id = true;
      let getData;
      if (this.json.cmd || this.json.object) {
        getData = layoutRequest(_.cloneDeep(this.json), this.expParamsObj);
      } else {
        getData = Promise.resolve();
      }
      Promise.all([getData, ...requestList])
        .then((res) => {
          const value = res.shift();
          if (this.json.object) {
            const data = {
              list: value.formattedValues,
              aggregate: value.aggregate,
            };
            this.dataSource = data;
          } else if (this.json.cmd) {
            if (typeof value.remoteValues === 'object') {
              this.dataSource = value.remoteValues;
            } else {
              this.dataSource = {
                data: value.remoteValues,
              };
            }
          } else {
            this.dataSource = {};
          }

          res
            .filter((i) => i)
            .forEach(({ key, value }) => {
              this.dataSource[key] = value;
            });
          this.$nextTick(() => {
            this.loading = false;
            this.$refs.child.map((ele) => ele.render());
          });
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
