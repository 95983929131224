//
//
//
//
//
//
//
//
//
//
//
//

import DashboardBlock from './block';

export default {
  name: 'MiniDashboard',
  components: { DashboardBlock },
  props: {
    json: {
      type: Object,
      default: null,
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    placement() {
      return this.json.render?.placement || 'bottom';
    },
  },
  methods: {
    init() {
      if (this.json) {
        this.$refs.block.init();
      }
    },
  },
};
