import { cloneDeep } from 'lodash';
import { easyQuery } from './query';
import { request } from '../request';

const _ = { cloneDeep };

// 从json中获取详情查询的key
function getIdKey({ json }) {
  const { id_key: idKey } = json;
  const idKeyName = idKey || 'id';
  return idKeyName;
}

// 通过ID查询数据对象详情
async function queryById({ json, id }) {
  const { object } = json;
  const idKeyName = getIdKey({ json });
  const result = await easyQuery(
    _.cloneDeep(json),
    {
      [object]: { _limit: 1, id: { key: idKeyName, value: id } },
    },
    { return_workflow_conf_sw: true }, // 需返回审批流是否开启
  );
  const resData = result?.[0] || {};
  // 增加审批流相关的逻辑，注入结果中。
  if (result?.__workflow_conf_sw !== undefined) {
    resData.__workflow_conf_sw = result.__workflow_conf_sw;
  }
  return resData;
}

async function cmdQuery({ cmd, payload }) {
  return request(cmd, payload);
}

export { queryById, cmdQuery };
