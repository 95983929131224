//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Ellipsis',
  props: {
    str: {
      type: String,
      default: '',
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: String,
      default: '...',
    },
    maxLength: {
      type: Number,
      default: 20,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isExpand: false,
    };
  },
  computed: {
    showStr() {
      return `${this.str.substr(0, this.maxLength - this.offset)}${this.suffix}${this.str.substr(
        this.str.length - this.offset,
        this.str.length,
      )}`;
    },
  },
  methods: {
    changeExpand() {
      if (this.isExpand) {
        this.isExpand = false;
      } else {
        this.isExpand = true;
      }
    },
  },
};
