export default () => {
  const signSetItem = localStorage.setItem;
  localStorage.setItem = function (key, val) {
    const setEvent = new Event('setLocalStorageEvent');
    setEvent.key = key;
    setEvent.newValue = val;
    window.dispatchEvent(setEvent);
    // eslint-disable-next-line prefer-rest-params
    signSetItem.apply(this, arguments);
  };
};
