import { layoutRequest } from './layoutRequest';
import { execExpressionDeep } from './expression';
import { cloneDeep } from 'lodash';
export function doRequestArrangeItem(config = {}, expParamsObj = {}) {
  return new Promise((resolve, reject) => {
    const newExpParamsArr = [];
    const result = {};
    const requests = config.requests || [];
    const callbacks = config.request_callbacks || [];
    const promises = requests.map((reqConf) => {
      if (reqConf.rule === undefined || execExpressionDeep(reqConf.rule, expParamsObj)) {
        return layoutRequest(reqConf, expParamsObj);
      }
      return Promise.resolve(null);
    });
    Promise.all(promises)
      .then((data) => {
        data.forEach((item, idx) => {
          newExpParamsArr.push(item.remoteValues);
          if (requests[idx]?.key) {
            result[requests[idx]?.key] = item.remoteValues;
          }
        });
        if (callbacks.length) {
          requestArrange(callbacks, {
            ...expParamsObj,
            t: {
              response: newExpParamsArr,
              p: expParamsObj.t,
            },
          })
            .then((data) => {
              resolve({
                ...result,
                ...data,
              });
            })
            .catch((e) => {
              reject(e);
            });
        } else {
          resolve(result);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function requestArrange(
  configs = [],
  expParamsObj = {},
  beforeHook = () => {},
  afterHook = () => {},
) {
  configs = cloneDeep(configs);
  const promises = configs.map((config) => {
    return doRequestArrangeItem(config, expParamsObj);
  });
  let result = {};
  beforeHook();
  return Promise.all(promises)
    .then((data) => {
      data.forEach((item) => {
        result = {
          ...result,
          ...item,
        };
      });
      return result;
    })
    .catch((e) => {
      return Promise.reject(e);
    })
    .finally(() => {
      afterHook();
    });
}
