//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { McmAdminService } from '@/graphql/api/list';
import { whereGql } from '@/graphql/util';

export default {
  name: 'Cascader',
  props: {
    filterObj: {
      type: Object,
      default: () => {},
    },
    formValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      comfilterObj: '',
      // 是否已经加载了value的详情，在编辑时会用到
      isLoadValueData: false,
      selectOptions: [],
      props: {
        lazy: true,
        lazyLoad: async (node, resolve) => {
          const initData = await this.initData(node, resolve);
          if (initData) {
            return resolve(initData);
          }
          const { data } = node;
          const childData = await this.getData({
            ...data,
          });
          resolve(childData);
        },
      },
    };
  },
  computed: {
    style() {
      return this.comfilterObj.filter.style || {};
    },
  },
  watch: {
    filterObj(val) {
      this.comfilterObj = val;
    },
  },
  created() {
    this.comfilterObj = this.filterObj;
  },
  updated() {
    console.log('===update', this.formValue);
    this.getEditData();
  },
  methods: {
    async getEditData() {
      try {
        if (!this.isLoadValueData) {
          this.isLoadValueData = true;
          const editValue = this.formValue[this.filterObj.prop];
          if (editValue) {
            const { jsonFilter = {} } = this.filterObj?.filter || {};
            const { targetObject, relation = [] } = jsonFilter;
            const targetField = relation.find((i) => i.relationObject === targetObject);
            if (targetField) {
              const { value, label } = targetField;
              let whereFields = [
                {
                  whereGqlType: 'is',
                  [value]: editValue,
                },
              ];
              whereFields = whereGql(whereFields);
              const detailMutation = `query { ${targetObject}(${whereFields}) {${value} ${label}} }`;
              const res = await McmAdminService({
                query: detailMutation,
              });
              console.log('===', res);
              const initData = (res[targetObject] || []).map((i) => {
                i.value = i[value];
                i.label = i[label];
                return i;
              });
              this.selectOptions = [...this.selectOptions, ...initData];
            }
          }
        }
      } catch (err) {
        console.error(err);
        this.isLoadValueData = false;
      }
    },
    async initData(node) {
      try {
        console.log('===', this.filterObj, this.formValue);
        if (node.level === 0) {
          const { jsonFilter = {} } = this.filterObj?.filter || {};
          const { object, label, value, initWhere = {} } = jsonFilter;
          const queryFields = `${label} ${value}`;
          let whereFields = Object.keys(initWhere).map((key) => ({
            whereGqlType: 'is',
            [key]: initWhere[key],
          }));
          whereFields = whereGql(whereFields);
          const detailMutation = `query { ${object}(${whereFields}) {${queryFields}} }`;
          const res = await McmAdminService({
            query: detailMutation,
          });
          const data = (res[object] || []).map((i) => {
            i.label = i[label];
            i.value = i[value];
            i.object = object;
            return i;
          });
          return data;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getData(node) {
      try {
        const { jsonFilter = {} } = this.filterObj?.filter || {};
        const { relation = [] } = jsonFilter;
        const { object } = node;
        const getChildPromise = relation
          .map(async (item) => {
            const { parentRelationObject, relationObject, relationField, whereType, label, value } =
              item;
            if (object === parentRelationObject) {
              const queryFields = `${label} ${value}`;
              let whereFields = Object.keys(relationField).map((key) => ({
                whereGqlType: whereType || 'is',
                [relationField[key]]:
                  whereType === 'include_any' ? node[key].split(',') : node[key],
              }));
              whereFields = whereGql(whereFields);
              const detailMutation = `query { ${relationObject}(${whereFields}) {${queryFields}} }`;
              return McmAdminService({
                query: detailMutation,
              });
            }
            return undefined;
          })
          .filter(Boolean);
        const res = await Promise.all(getChildPromise);
        const childData = relation.reduce((pre, item, index) => {
          const { relationObject, label, value, isLeaf } = item;
          let data = res[index]?.[relationObject] || [];
          data =
            data.map((i) => {
              i.label = i[label];
              i.value = i[value];
              i.object = relationObject;
              i.leaf = isLeaf;
              return i;
            }) || [];
          data.forEach((i) => {
            const isInOptions = this.selectOptions.find((opt) => opt.value === i.value);
            if (!isInOptions) {
              this.selectOptions.push(i);
            }
          });
          console.log('===this.selectOptions', this.selectOptions);
          pre = [...pre, ...(data || [])];
          return pre;
        }, []);
        return childData;
      } catch (err) {
        console.error(err);
        return [];
      }
    },
    handleChange() {
      const nodes = this.$refs.cascader.getCheckedNodes();
      if (nodes?.length) {
        const data = nodes[0]?.data;
        if (data?.leaf) {
          const { prop } = this.comfilterObj;
          this.$emit('change', { prop, value: data.value });
        }
      }
    },
  },
};
