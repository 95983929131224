import _ from 'lodash';
import { getValue } from '@/graphql/util';
import { PAGE_SIZE } from '@/utils/constant';
import { dateFormat } from '@/utils/time.js';

const whereSplit = '.';
const propSplit = '-';
// filter:{'department.id':[],name:'',time:{min:,max:}}
function queryField(field) {
  let fieldArr = [];
  _.keys(field).forEach((key) => {
    if (key === 'list') {
      fieldArr = fieldArr.concat(field.list);
      return;
    }
    fieldArr.push(`${key}{${queryField(field[key])}}`);
  });
  return fieldArr.join(',');
}
function getFieldItem({ field, objects, object, json }) {
  let fieldObject = object;
  let itemField = field;
  if (field.includes('.')) {
    const fieldArr = field.split('.');
    const fieldLen = fieldArr.length;
    fieldObject = fieldArr[fieldLen - 2];
    itemField = fieldArr[fieldLen - 1];
  }
  const { alias } = json;
  if (alias) {
    fieldObject = alias[fieldObject] || fieldObject;
  }
  const objectItem = objects.filter((item) => item.object.name === fieldObject)[0];
  if (objectItem) {
    const fieldItem = objectItem.fields.filter((item) => item.name === itemField)[0];
    if (fieldItem) {
      if (fieldItem.value_type === 'SELECT_ONE_INT') {
        fieldItem.value_type = 'SELECT_ONE';
        fieldItem.select_one_option = fieldItem.select_one_int_option;
      }
    }
    return fieldItem;
  }
  return null;
}
function getFieldMap({ json }) {
  const { fields = [] } = json;
  const fieldArr = fields.map((item) => item.field || item);
  const fieldMap = { list: [] };
  fieldArr.forEach((item) => {
    if (!item.includes('.')) {
      fieldMap.list.push(item);
    } else {
      const fieldItems = item.split('.');
      const fieldItemsLen = fieldItems.length;
      const fieldMapKey = fieldItems.slice(0, fieldItemsLen - 1).join('.');
      let fieldMapValue = _.get(fieldMap, fieldMapKey);
      if (!fieldMapValue) _.set(fieldMap, fieldMapKey, { list: [] });
      fieldMapValue = _.get(fieldMap, fieldMapKey);
      fieldMapValue.list.push(fieldItems[fieldItemsLen - 1]);
    }
  });
  return fieldMap;
}
function queryGql({ json, objects = [], input, filter = {}, params = {} }) {
  const { search = {}, object, order, where = {}, customWhere = {} } = json;
  const { limit = PAGE_SIZE, offset = 0 } = params;
  let { otherConditionStr = '', customCondition = '' } = params;
  otherConditionStr = otherConditionStr && `,${otherConditionStr}`;
  customCondition = customCondition && `,${customCondition}`;
  const fieldMap = getFieldMap({ json });
  // 主表，默认插入id
  if (fieldMap.list.length && !fieldMap.list.includes('id')) {
    fieldMap.list.push('id');
  }
  const inputTrim = _.trim(input);
  const inputArr = [];
  if (inputTrim && search.fields.length) {
    search.fields.forEach((item) => {
      inputArr.push(`{${item.replaceAll('.', whereSplit)}:{_like:"%${inputTrim}%"}}`);
    });
  }
  const filterArr = [];
  if (objects.length) {
    _.keys(filter).forEach((key) => {
      const fieldItem = getFieldItem({ field: key, object, objects, json });
      if (fieldItem) {
        const fieldValue = filter[key]?.value;
        const fieldFilter = filter[key]?.filter;
        let keyWhere = key.replaceAll('.', whereSplit);
        let fieldWhere = '';
        if (_.hasIn(fieldValue, 'min') || _.hasIn(fieldValue, 'max')) {
          if (fieldValue.min && fieldValue.max) {
            fieldWhere = `{_between:[${getValue(fieldValue.min)},${getValue(fieldValue.max)}]}`;
          } else if (fieldValue.min) {
            fieldWhere = `{_gt:${getValue(fieldValue.min)}}`;
          } else if (fieldValue.max) {
            fieldWhere = `{_lte:${getValue(fieldValue.max)}}`;
          }
        } else if (_.isArray(fieldValue)) {
          // 自定义过滤
          if (_.keys(customWhere)?.length) {
            _.keys(customWhere).forEach((key) => {
              keyWhere.includes(key) && (keyWhere = key);
              fieldValue.length && (fieldWhere = `{${customWhere[key]}:${getValue(fieldValue)}}`);
            });
          } else {
            fieldValue.length &&
              (fieldWhere = `{${fieldFilter?.whereType || '_in'}:${getValue(fieldValue)}}`);
          }
        } else if (fieldItem.value_type === 'SELECT_ONE') {
          fieldValue && (fieldWhere = `{_eq:${getValue(fieldValue)}}`);
        } else if (fieldValue) {
          fieldWhere = `{_like:"%${fieldValue}%"}`;
        }
        if (fieldWhere) {
          filterArr.push(`{${keyWhere}:${fieldWhere}}`);
        }
      }
    });
  }
  // 默认搜索条件
  const defaultWhere = [];
  if (Object.keys(where).length) {
    Object.keys(where).forEach((key) => {
      defaultWhere.push(`{${key}:${getValue(where[key])}}`);
    });
  }
  const whereArr = [];
  if (defaultWhere.length) {
    whereArr.push(`{_and:[${defaultWhere.join(',')}]}`);
  }
  if (inputArr.length) {
    whereArr.push(`{_or:[${inputArr.join(',')}]}`);
  }
  if (filterArr.length) {
    whereArr.push(`{_and:[${filterArr.join(',')}]}`);
  }
  let whereStr = '';
  if (whereArr.length > 1) {
    whereStr = `{_and:[${whereArr.join(',')}]}`;
  } else if (whereArr.length) {
    whereStr = whereArr.join('');
  }
  if (whereStr) {
    whereStr = `,_where:${whereStr}`;
  }
  whereStr = whereStr && `,${whereStr}`;
  let orderStr = '';
  if (order) {
    if (_.isArray(order) && order.length > 0) {
      let arrayOrder = '';
      order.forEach((item) => {
        arrayOrder += `${item.field}:_${item.type},`;
      });
      orderStr = `_order_by:{${arrayOrder}}`;
    } else if (order.field && order.type) {
      orderStr = `_order_by:{${order.field}:_${order.type}}`;
    }
  }
  orderStr = orderStr && `,${orderStr}`;
  return `query{
                ${object}(_limit:${limit},_offset:${offset}${orderStr}${whereStr}${otherConditionStr}${customCondition}){
                    _aggregate{_count},${queryField(fieldMap)}
                }
            }`;
}
function searchJson({ json, objects = [] }) {
  const { search = {}, object, fields = [] } = json;
  if (!search.placeholder) {
    const placeholder = [];
    if (search?.fields?.length) {
      search.fields.forEach((item) => {
        const field = fields.filter(
          (itemField) => itemField.field === item || itemField === item,
        )[0];
        if (field?.label) {
          placeholder.push(field.label);
          return;
        }
        const fieldItem = getFieldItem({ field: item, object, objects, json });
        fieldItem?.display_name && placeholder.push(fieldItem.display_name);
      });
    }
    search.placeholder = `请输入${placeholder.length ? placeholder.join('/') : '关键词'}进行搜索`;
  }
  return search;
}
function tableColumn({ json, objects = [] }) {
  const { fields = [], object } = json;
  const column = [];
  fields.forEach((item) => {
    if (item.hidden) return;
    const field = item.field || item;
    const { valueType: fieldValueType, style, tip, fixed } = item;
    const fieldItem = getFieldItem({ field, object, objects, json });
    const itemFilter = item.filter;
    if (fieldItem) {
      let prop = field.replaceAll('.', propSplit);
      const valueType = fieldItem.value_type;
      const filter = {};
      if (['SELECT_ONE', 'SELECT_MANY'].includes(valueType)) {
        prop += `${propSplit}label`;
      }
      if (itemFilter !== false) {
        filter.show = true;
        filter.value_type = valueType;
        if (itemFilter?.object) {
          filter.options = [];
          filter.object = itemFilter.object;
          filter.label = itemFilter.label;
          filter.value = itemFilter.value;
          if (valueType !== 'SELECT_ONE') filter.value_type = fieldValueType || 'SELECT_MANY';
        }
        if (itemFilter?.options?.length) {
          filter.options = [...itemFilter.options];
          if (valueType !== 'SELECT_ONE') filter.value_type = fieldValueType || 'SELECT_MANY';
        }
        if (valueType === 'SELECT_ONE' && fieldItem.select_one_option?.options?.length) {
          filter.options = [
            { label: '全部', value: 'all' },
            ...(filter.options || []),
            ...fieldItem.select_one_option.options,
          ];
        } else if (valueType === 'SELECT_MANY' && fieldItem.select_many_option?.options?.length) {
          filter.options = [...(filter.options || []), ...fieldItem.select_many_option.options];
        }
        let filterKey = field;
        if (itemFilter?.value) {
          const fieldArr = field.split('.');
          filterKey = fieldArr
            .slice(0, fieldArr.length - 1)
            .concat(itemFilter.value)
            .join('.');
        }
        filter.prop = itemFilter?.whereField || filterKey;
        filter[filter.prop] = valueType === 'INT' ? { min: '', max: '' } : '';
        filter.visible = false;
        filter.width = ['INT', 'DATETIME'].includes(valueType) ? 400 : 200;
        filter.style = itemFilter?.style;
        filter.rules = itemFilter?.rules;
        filter.field = itemFilter?.field;
        filter.whereType = itemFilter?.whereType;
        filter.jsonFilter = { ...itemFilter };
      }
      column.push({
        label: item.label || fieldItem.display_name || fieldItem.name,
        type: item.type,
        prop,
        filter,
        style,
        tip,
        fixed,
      });
    } else {
      const filter = {};
      filter.jsonFilter = { ...itemFilter };
      filter.show = false;
      column.push({
        label: item.label,
        type: item.type,
        prop: field.replaceAll('.', propSplit),
        filter,
        style,
        fixed,
        tip,
      });
    }
  });
  return column;
}
function getMutationField({ json, objects = [] }) {
  const { fields = [], object } = json;
  const mutationFields = [];
  fields.forEach((item) => {
    if (item.hidden) return;
    const field = item.field || item;
    const { valueType: fieldValueType, style } = item;
    const fieldItem = getFieldItem({ field, object, objects, json });
    const itemFilter = item.filter;
    if (fieldItem) {
      const prop = field.replaceAll('.', propSplit);
      const valueType = fieldItem.value_type;
      const filter = {};
      if (itemFilter !== false) {
        filter.show = true;
        filter.value_type = valueType;
        if (itemFilter?.object) {
          filter.options = [];
          filter.object = itemFilter.object;
          filter.label = itemFilter.label;
          filter.value = itemFilter.value;
          if (valueType !== 'SELECT_ONE') filter.value_type = fieldValueType || 'SELECT_MANY';
        }
        if (itemFilter?.options?.length) {
          filter.options = [...itemFilter.options];
          if (valueType !== 'SELECT_ONE') filter.value_type = fieldValueType || 'SELECT_MANY';
        }
        if (valueType === 'SELECT_ONE' && fieldItem.select_one_option?.options?.length) {
          filter.options = [
            ...(filter.options || []),
            ...(itemFilter.optionsOnlyFromJson ? [] : fieldItem.select_one_option.options),
          ];
        } else if (valueType === 'SELECT_MANY' && fieldItem.select_many_option?.options?.length) {
          filter.options = [
            ...(filter.options || []),
            ...(itemFilter.optionsOnlyFromJson ? [] : fieldItem.select_many_option.options),
          ];
        }
        let filterKey = field;
        if (itemFilter?.value) {
          const fieldArr = field.split('.');
          filterKey = fieldArr
            .slice(0, fieldArr.length - 1)
            .concat(itemFilter.value)
            .join('.');
        }
        filter.prop = itemFilter?.whereField || filterKey;
        filter[filter.prop] = valueType === 'INT' ? { min: '', max: '' } : '';
        filter.visible = false;
        filter.width = ['INT', 'DATETIME'].includes(valueType) ? 400 : 200;
        filter.style = itemFilter?.style;
        filter.rules = itemFilter?.rules;
        filter.field = itemFilter?.field;
        filter.whereType = itemFilter?.whereType;
        filter.jsonFilter = { ...itemFilter };
      }
      mutationFields.push({
        label: item.label || fieldItem.display_name || fieldItem.name,
        type: item.type,
        prop,
        filter,
        style,
      });
    } else {
      const filter = {};
      filter.jsonFilter = { ...itemFilter };
      filter.show = false;
      mutationFields.push({
        label: item.label,
        type: item.type,
        prop: field.replaceAll('.', propSplit),
        filter,
        style,
      });
    }
  });
  return mutationFields;
}
function transTableData({ json, objects = [], data }) {
  console.log('meowlaydata', data);
  const { fields = [], object } = json;
  const fieldArr = fields.map((item) => item.field || item);
  const objectData = data[object];
  const outList = [];
  let total = 0;
  if (objectData) {
    total = objectData._aggregate.count;
    const list = objectData._data || [];
    list.forEach((item) => {
      const outItem = {};
      fieldArr.forEach((field) => {
        const fieldItem = getFieldItem({ field, object, objects, json });

        const propName = field.replaceAll('.', propSplit);
        let prop = propName;

        let itemValue = null;
        if (field.includes('.')) {
          const fieldArr = field.split('.');
          itemValue = getCascadeData(fieldArr, item);
        } else {
          itemValue = _.get(item, field, '');
        }
        const valueType = fieldItem?.value_type;
        if (['SELECT_ONE', 'SELECT_MANY'].includes(valueType)) {
          prop += `${propSplit}label`;
          const options = _.get(
            fieldItem,
            valueType === 'SELECT_ONE' ? 'select_one_option.options' : 'select_many_option.options',
            [],
          );
          if (options.length) {
            // 获取对应的key列表
            outItem[`${propName}${propSplit}key`] = String(itemValue)
              ?.split(',')
              .map((item) => options.filter((option) => `${option.value}` === `${item}`)[0]?.value);
            // 获取对应的label列表
            itemValue = String(itemValue)
              ?.split(',')
              .map((item) => options.filter((option) => `${option.value}` === `${item}`)[0]?.label);
          }
        }
        if (valueType === 'DATETIME') {
          const fieldConfig = fields.filter((item) => item.field === field);
          const format =
            fieldConfig.length && fieldConfig[0].format
              ? fieldConfig[0].format
              : 'YYYY-MM-dd HH:mm:SS';
          itemValue && (itemValue = dateFormat(new Date(itemValue * 1000), format));
        }
        outItem[prop] = itemValue;
      });
      outItem.id = item.id;
      outList.push(outItem);
    });
  }
  console.log('meowout', outList);
  return { total, list: outList };
}
function getCascadeData(keyList, data) {
  let sourceData = data;
  keyList.forEach((key) => {
    // 返回key名称里无特殊字开头的_
    if (key[0] === '_') {
      const arr = key.split('');
      arr.shift();
      key = arr.join('');
    }
    // 如果数据源是数组就一项项取值 否则只取一次值
    if (_.isArray(sourceData)) {
      const next = [];
      sourceData = sourceData.forEach((item) => {
        // 如果数组内某一项是数组就一项项取值 对最后一个key的取值不会有数组无须额外处理
        if (_.isArray(item)) {
          next.push(...item.map((item2) => item2[key]));
        } else if (_.isObject(item)) {
          next.push(item[key]);
        }
      });
      sourceData = next;
    } else if (_.isObject(sourceData)) {
      sourceData = sourceData[key];
    }
  });
  return sourceData;
}

export {
  queryGql,
  searchJson,
  tableColumn,
  transTableData,
  getFieldMap,
  queryField,
  getCascadeData,
  getMutationField,
};
