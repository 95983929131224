//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AddExaminAccount',
  props: {
    accountName: String,
    accountPassWord: String,
  },
  data() {
    return {
      nameRegE: /^[\u4e00-\u9fa5a-zA-Z0-9]{4,12}$/ /* 用户名正则表达式 */,
      passRegE: /^[a-zA-Z0-9]{4,12}$/ /* ,密码正则表达式 */,
      name: '' /* 用户名 */,
      password: '' /* 密码 */,
      testname: true /* 用户名校验结果 */,
      testpassword: true /* 密码校验结果 */,
      isSubmit: false /* 是否触发提交 */,
    };
  },
  watch: {
    accountName: {
      handler(value) {
        this.name = value;
      },
      immediate: true,
    },
    accountPassWord: {
      handler(value) {
        this.password = value;
      },
      immediate: true,
    },
  },
  methods: {
    nameInput(val) {
      if (this.isSubmit) {
        this.testname = this.nameRegE.test(val.trim());
      }
    },
    passInput(val) {
      if (this.isSubmit) {
        this.testpassword = this.passRegE.test(val.trim());
      }
    },
    close() {
      this.$emit('hideDialog');
    },
    submit() {
      /* 提交 */
      this.isSubmit = true;
      this.testname = this.nameRegE.test(this.name.trim());
      this.testpassword = this.passRegE.test(this.password.trim());
      if (this.testname && this.testpassword) {
        this.$emit('saveNamePassword', {
          name: this.name,
          password: this.password,
        });
        this.close();
      }
    },
  },
};
