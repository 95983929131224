const globalConfig = {
  list: [],
  open(...e) {
    if (globalConfig.list.length) {
      globalConfig.list[globalConfig.list.length - 1](...e);
    }
  },
};

export { globalConfig };
