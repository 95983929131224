import { execExpressionDeep } from '../../../layout/expression';
import { replaceParams } from 'nges-common/src/util';

const scopeName = 'ngesWebLayoutEmitListener';

export default {
  created() {
    this.$oldEmit = this.$emit;
    this.$emit = this.emitListener;
  },
  methods: {
    emitListener(name, ...prop) {
      try {
        if (this.$route.query[`emit_after${name}`]) {
          const action = window[scopeName][this.$route.query[`emit_after${name}`]];
          if (action) {
            this.m_actionHandle(execExpressionDeep(action, this.expParamsObj));
            // 保证下次读取route读取到了实时query
            delete this.$route.query[`emit_after${name}`];
            window.history.replaceState(
              null,
              '',
              replaceParams(this.$route.query, this.$route.path),
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
      this.$oldEmit(name, ...prop);
    },
    addEmitListener(action) {
      if (!window[scopeName]) window[scopeName] = {};
      let id = new Date().getTime();
      while (window[scopeName][id]) id += 1;
      window[scopeName][id] = action;
      return id;
    },
  },
};
