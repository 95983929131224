import { request, storeRequest } from '../../utils/request';
import {
  request as graphqlRequest,
  storeRequest as graphqlStoreRequest,
} from '../../utils/graphqlRequest';
import { easyQuery } from '../../utils/query';
import _ from 'lodash';
import dayjs from 'dayjs';
import * as util from '../../utils/util';
import login from '../../utils/login';
import * as expression from '../../../layout/expression';
export default {
  util,
  request,
  storeRequest,
  graphqlRequest,
  graphqlStoreRequest,
  easyQuery,
  _,
  dayjs,
  login,
  expression,
};
