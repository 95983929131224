//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { DataService } from 'nges-common/src/web/utils/api';
import { queryTagCategoryTrees } from '@/graphql/api/tag';
import FilterItem from './filterItem.vue';

import { /* pageGql,*/ whereGql } from '@/graphql/util';

export default {
  name: 'MaterialFilter',
  components: { FilterItem },
  props: {
    filter: {
      type: [Object, null, undefined],
      default: null,
    },
  },
  data() {
    return {
      filterData: {},
      options: [],
      props: {
        multiple: true,
        expandTrigger: 'hover',
        value: 'id',
        label: 'name',
      },
      filterVals: null,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.initData();
  },

  methods: {
    resetFilters() {
      this.filterVals = null;
      this.$refs?.filter?.resetFilter?.();
    },
    getDataList() {
      return this.filterVals || [];
    },
    async initData() {
      // 支持外部传入 查询语句
      const res = await (this.filter?.qgl
        ? DataService({
            query: this.filter.gql,
          })
        : queryTagCategoryTrees()
      ).catch(() => null);
      if (res) {
        this.options = res.tag_category || [];
      }
    },

    handleFilterChange(data) {
      const { value } = data;
      this.filterVals = value;
      this.emitData();
    },
    emitData() {
      const whereGqlArr = [];
      whereGqlArr.push({
        id: 'false',
        whereGqlType: 'is_null',
      });
      const value = this.filterVals;
      if (value?.length) {
        whereGqlArr.push({
          tag_ids: value,
          whereGqlType: 'include_any',
        });
      }
      this.$emit('change', { whereGql: whereGql(whereGqlArr) });
    },
  },
};
