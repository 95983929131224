export default {
  methods: {
    resetSelect(value, ref) {
      if (value.length) {
        this.$nextTick(() => {
          let tagsDom = this.$refs[ref].$el.querySelectorAll('.el-select__tags-text') || [];
          let selectDom = this.$refs[ref].$el.querySelectorAll('.el-input__inner') || [];
          let maxWidth = selectDom[0].clientWidth;
          if (tagsDom.length > 1 && selectDom.length) {
            maxWidth = maxWidth - 150;
          } else {
            maxWidth = maxWidth - 100;
          }
          tagsDom.forEach((tag) => {
            tag.style.maxWidth = `${maxWidth}px`;
          });
          tagsDom = null;
          selectDom = null;
        });
      }
    },
  },
};
