const formatSeconds = (value, precision = 'second', clean = false) => {
  const precisionMap = {
    second: 1,
    minute: 2,
    hour: 3,
    day: 4,
  };
  const precisionValue = precisionMap[precision];
  let theTime = parseInt(value, 10); // 需要转换的时间秒
  let theTime1 = 0; // 分
  let theTime2 = 0; // 小时
  let theTime3 = 0; // 天
  if (theTime >= 60) {
    theTime1 = parseInt(theTime / 60, 10);
    theTime = parseInt(theTime % 60, 10);
    if (theTime1 >= 60) {
      theTime2 = parseInt(theTime1 / 60, 10);
      theTime1 = parseInt(theTime1 % 60, 10);
      if (theTime2 >= 24) {
        // 大于24小时
        theTime3 = parseInt(theTime2 / 24, 10);
        theTime2 = parseInt(theTime2 % 24, 10);
      }
    }
  }
  let result = '';
  if (theTime > 0 && precisionValue <= 1) {
    result = `${parseInt(theTime, 10)}秒`;
  }
  if (theTime1 > 0 && precisionValue <= 2) {
    if (clean) result = `${parseInt(theTime1, 10)}分`;
    else result = `${parseInt(theTime1, 10)}分${result}`;
  }
  if (theTime2 > 0 && precisionValue <= 3) {
    if (clean) result = `${parseInt(theTime2, 10)}小时`;
    else result = `${parseInt(theTime2, 10)}小时${result}`;
  }
  if (theTime3 > 0 && precisionValue <= 4) {
    if (clean) result = `${parseInt(theTime3, 10)}天`;
    else result = `${parseInt(theTime3, 10)}天${result}`;
  }
  return result;
};

// 时间长度格式话为 hh:mm:ss
const formatSecondsDuration = (showTotalDuration) => {
  // if (!showTotalDuration) return '-';
  const totalSec = showTotalDuration || 0;
  if (!totalSec) {
    return '00:00:00';
  }
  const oneSec = 1;
  const oneMin = oneSec * 60;
  const oneHour = oneMin * 60;
  let hour = parseInt(totalSec / oneHour, 10);
  hour = hour < 10 ? `0${hour}` : hour;
  let restSec = totalSec % oneHour;
  let min = parseInt(restSec / oneMin, 10);
  min = min < 10 ? `0${min}` : min;
  restSec = Math.ceil(restSec % oneMin);
  restSec = restSec < 10 ? `0${restSec}` : restSec;
  return `${hour}:${min}:${restSec}`;
  // return `${hour}:${min}:${parseInt(restSec, 10)}`;
};

// 数字转成容量
const bytesToSize = (bytes) => {
  if (`${bytes}` === '0') return '0 B';
  const k = 1024; // or 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // 当超过1000时改成toPrecision（4）防止变成 指数形式 =>1.02e+3
  if (bytes / k ** i >= 1000) {
    return `${(bytes / k ** i).toPrecision(4)}${sizes[i]}`;
  }

  return `${(bytes / k ** i).toPrecision(3)}${sizes[i]}`;
};

/**
 * 深度优先遍历
 * @param {*} root
 * @param {*} childKey
 * @param {*} visitNode
 */
function depthFirstTraverse(root, childKey, visitNode) {
  const stack = [];
  root.$height = 1;
  // 根节点压栈
  stack.push(root);
  while (stack.length > 0) {
    const curNode = stack.pop(); // 出栈
    const canBreak = visitNode(curNode, curNode.$height);
    if (canBreak) {
      break;
    }
    // 子节点压栈
    const childNodes = curNode[childKey] || [];
    if (childNodes.length > 0) {
      // reverse 反顺序压栈
      Array.from(childNodes)
        .reverse()
        .forEach((child) => {
          if (child) {
            child.$height = curNode.$height + 1;
            stack.push(child);
          }
        });
    }
  }
}

/**
 * 广度优先遍历
 * @param {*} root
 * @param {*} childKey
 * @param {*} visitNode
 */
function breadthFirstTraverse(root, childKey, visitNode) {
  const stack = [];
  if (root) {
    stack.push(root);
    root.$height = 1;
    while (stack.length) {
      // 取第一个
      const curNode = stack.shift();
      const canBreak = visitNode(curNode, curNode.$height);
      if (canBreak) {
        break;
      }
      const children = curNode[childKey] || [];
      const childrenLen = children.length;
      for (let i = 0; i < childrenLen; i++) {
        if (children[i]) {
          children[i].$height = curNode.$height + 1;
          stack.push(children[i]);
        }
      }
    }
  }
}

// 获取页面参数对象
function getPageParams() {
  try {
    if (typeof getCurrentPages !== 'undefined') {
      const pages = getCurrentPages();
      const curPage = pages[pages.length - 1] || {};
      return curPage.options || curPage?.$route?.query || {};
    }
    if (typeof window !== 'undefined' && window.location) {
      const url = window.location.search; // 获取url中'?'符后的字串
      const query = {};
      if (url.indexOf('?') !== -1) {
        const str = url.slice(1);
        const strs = str.split('&');
        const len = strs.length;
        for (let i = 0; i < len; i++) {
          const [key, value] = strs[i].split('=');
          if (key) {
            query[key] = decodeURIComponent(value);
          }
        }
      }
      return query;
    }
    return {};
  } catch (err) {
    console.error(err);
    return {};
  }
}

// 获取页面路径信息
function getCurrentPagesInfo() {
  try {
    if (typeof getCurrentPages !== 'undefined') {
      return getCurrentPages();
    }
    if (typeof window !== 'undefined' && window.location) {
      return window.location;
    }
    return {};
  } catch (err) {
    console.error(err);
    return {};
  }
}

function getUrlParams(url) {
  const result = {};
  const query = url.split('?');
  if (query[1]) {
    const strs = query[1].split('&');
    strs.forEach((str) => {
      const [key, value] = str.split('=');
      if (key && value) {
        result[key] = value;
      }
    });
  }
  return result;
}

function deleteUrlParam(url, param) {
  const query = url.split('?');
  let aim = '';
  if (query[1]) {
    const strs = query[1].split('&');
    strs.forEach((str, index) => {
      const kv = str.split('=');
      if (kv[0] === param) {
        if (index === 0) {
          if (strs.length > 1) aim = `${str}&`;
          else aim = str;
        } else aim = `&${str}`;
      }
    });
  }
  if (aim) return url.replace(aim, '');
  return url;
}

// 耗时计算函数
function timeConsuming({ type = '' }) {
  const startTime = Date.now();
  return (extStr = '') => {
    console.log(`=== ${type}${extStr} spend time: ${Date.now() - startTime}===`);
  };
}

// 获取树节点的完整链路
function findParent(node, list) {
  if (!node) {
    return null;
  }
  if (node.$parent === '0') {
    return [node.value];
  }
  let parent;
  breadthFirstTraverse({ children: list }, 'children', (treeNode) => {
    if (treeNode.$height > 1) {
      if (treeNode.value === node.$parent) {
        parent = treeNode;
        return true;
      }
    }
  });
  return [...(findParent(parent, list) || []), node.value];
}

// 查找树节点
function findNode({ data, value }) {
  let findNode = null;
  breadthFirstTraverse({ children: data || [] }, 'children', (node) => {
    if (node.$height > 1) {
      if (
        Object.prototype.hasOwnProperty.call(node, 'value')
        && node.value !== void 0
        && value === node.value
      ) {
        findNode = node;
        return true;
      }
    }
  });
  return findNode;
}

function readOnlyHandler({ target }) {
  const readOnlyHandler = {
    set(target, key, value) {
      console.log(`Cannot set property ${key} to ${value}. Object is read-only.`);
      return true; // 表示操作已成功，以避免抛出错误
    },
  };
  return new Proxy(target, readOnlyHandler);
}

const toThousands = (num) => {
  if (!num) {
    return 0;
  }
  return num.toString().replace(/\d+/, function (n) {
    return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  });
};

export const loadJs = function (options = {}) {
  const { url, onload, onerror, retry } = options;
  const el = document.createElement('script');
  el.src = url;
  if (typeof onload === 'function') {
    el.onload = onload;
  }
  el.onerror = function (...args) {
    if (typeof onerror === 'function') {
      onerror.call(this, ...args);
    }
    if (retry > 0) {
      loadJs({ url, onload, onerror, retry: retry - 1 });
    }
  };
  document.body.appendChild(el);
  
};

// web端小程序端导出umdjs
const customJSMap = {};
export const loadCustomUmdJs = function (src, customConfig = {}, callback) {
  if (customJSMap[src]) {
    if (customJSMap[src].loaded) {
      callback();
    } else {
      customJSMap[src].callbacks.push(callback);
    }
  } else {
    customJSMap[src] = {
      loaded: false,
      callbacks: [callback],
    };
    loadJs({
      url: `${src}?v=${customConfig.version || ''}`,
      onload: () => {
        customJSMap[src].loaded = true;
        customJSMap[src].callbacks.forEach((cb) => cb());
        customJSMap[src].callbacks = [];
      },
    });
  }
  
};

function parseUrl(url) {
  const result = {};
  const keys = [
    'href',
    'origin',
    'protocol',
    'host',
    'hostname',
    'port',
    'pathname',
    'search',
    'hash',
  ];
  // eslint-disable-next-line
  const regexp = /(([^:]+:)\/\/(([^:\/\?#]+)(:\d+)?))(\/[^?#]*)?(\?[^#]*)?(#.*)?/;
  const match = regexp.exec(url);
  if (match) {
    for (let i = keys.length - 1; i >= 0; --i) {
      result[keys[i]] = match[i] ? match[i] : '';
    }
  }
  return result;
}

function shallowEqual(obj1, obj2, blackList = []) {
  if (Object.prototype.toString.call(obj1) !== '[object Object]') {
    return obj1 === obj2;
  }
  if (!obj1 || !obj2) {
    return obj1 === obj2;
  }
  // 获取两个对象的键
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // 比较键的数量
  if (keys1.length !== keys2.length) {
    return false;
  }

  // 比较每个键的值
  for (const key of keys1) {
    if (!blackList.includes(key) && obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export * from '@tencent/eyao-util-core';
export {
  formatSeconds,
  formatSecondsDuration,
  depthFirstTraverse,
  breadthFirstTraverse,
  getPageParams,
  getCurrentPagesInfo,
  timeConsuming,
  getUrlParams,
  deleteUrlParam,
  bytesToSize,
  findParent,
  findNode,
  readOnlyHandler,
  toThousands,
  parseUrl,
  shallowEqual,
};
