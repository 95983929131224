//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { dateFormat } from '@/utils/time.js';
import { jsonToXlsx } from '@/utils/xlsx.js';
/**
 * 导出dialog组件，用于导出过程的百分比显示
 * 用于解决导出进度的问题
 */
const toThousands = (num = 0) => {
  return num.toString().replace(/\d+/, function (n) {
    return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  });
};
export default {
  name: 'ExportProgress',
  props: {
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogVisible: false,
      progress: 0,
      mission: '',
      status: 1,
      excelData: [],
      excelName: '',
    };
  },
  computed: {
    progressColor() {
      if (this.status === 3) {
        return '#E7403A';
      }
      if (this.progress < 100) {
        return '#0069FA';
      }
      return '#23B812';
    },
    getTotal() {
      return toThousands(this.total);
    },
    prompt() {
      switch (this.status) {
        case 1:
          return '正在导出中，请稍等';
        case 2:
          return '数据导出成功，点击保存下载';
        case 3:
          return '数据导出失败，请重试';
        default:
          return '正在导出中，请稍等';
      }
    },
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (!val) {
          setTimeout(() => {
            this.resetProgress();
          }, 300);
        }
      },
    },
  },
  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    setProgress(val) {
      if (val >= 0 && val <= 100) {
        this.progress = Number.parseInt(val, 10);
      }
    },
    startRidiculousProgress(time) {
      // 设置一个大概完成时间(单位：秒)，让进度条盲目的前进，进度会一直前进并卡在99%
      this.mission = setInterval(() => {
        if (this.progress >= 99) {
          clearInterval(this.mission);
          return;
        }
        this.progress += 1;
      }, time * 10);
    },
    finishProgress(excelData, excelName, extraSheet = []) {
      this.excelData = excelData;
      this.excelName = excelName;
      this.extraSheet = extraSheet;
      clearInterval(this.mission);
      this.setProgress(100);
      this.status = 2;
    },
    exportExcel() {
      jsonToXlsx(
        [
          {
            sheet: this.excelData,
            name: this.excelName,
          },
          ...this.extraSheet,
        ],
        `${this.excelName} ${dateFormat(new Date(), 'YYYY-MM-dd HH:mm:SS')}`,
      );
      setTimeout(() => {
        this.closeDialog();
      }, 500);
    },
    abortProgress(waiting) {
      // 调用后会显示失败
      clearInterval(this.mission);
      this.status = 3;
      if (waiting) {
        setTimeout(() => {
          this.closeDialog();
        }, waiting);
      }
    },
    resetProgress() {
      // 调用后会重设进度和状态
      clearInterval(this.mission);
      this.setProgress(0);
      this.status = 1;
    },
  },
};
