import { get } from 'lodash';
import sysCmds from './sysCmds';

/**
 * 执行所有指令并返回最后一条结果
 * */
function execute(ast, scopeParams = {}, scopeFunc = {}) {
  if (!ast || Object.prototype.toString.call(ast) !== '[object Array]') {
    throwError();
    return;
  }
  const scope = {
    ...scopeParams,
  };
  scope.__custom_func = scopeFunc;
  const len = ast.length;
  let r = null;
  for (let i = 0; i < len; i++) {
    r = executeACmd.call(scope, ast[i]);
  }
  return r;
}

/**
 * 执行单条指令
 * */
function executeACmd(cmd) {
  const { value: v, type: t, args } = cmd;
  if (t === 'Number' || t === 'Boolean' || t === 'String') {
    return v;
  }
  if (t == 'Func') {
    return executeFunc.call(this, v, args);
  }
  if (t === 'Param') {
    return get(this, v);
  }
  if (t === 'Object') {
    return executeObject.call(this, args);
  }
  if (t === 'Array') {
    return executeArray.call(this, args);
  }
}

/**
 * 执行获取函数结果
 * */
function executeFunc(cmd = '', args = []) {
  const cmds = Object.assign({}, sysCmds, this.__custom_func || {});
  // 针对一些特殊指令做处理
  // SET方法中，设置全局参数（参数长度为2）时，第一个参数可为变量，但不做取值操作，故将其转成字符串
  if (cmd === 'SET' && args.length === 2 && args[0]?.type === 'Param') {
    args[0].type = 'String';
  }
  const newArgs = executeArray.call(this, args);
  return cmds[cmd].apply(this, newArgs);
}

/**
 * 执行生成对象
 * */
function executeObject(args = []) {
  const obj = {};
  args.forEach((arg) => {
    const { value: v, args } = arg;
    if (args && args[0]) {
      obj[v] = executeACmd.call(this, args[0]);
    }
  });
  return obj;
}

/**
 * 执行生成数组
 * */
function executeArray(args = []) {
  return args.map((arg) => executeACmd.call(this, arg));
}

/**
 * 抛出异常
 * */
function throwError() {
  throw new Error('执行指令错误');
}

export default execute;
