import { request, storeRequest } from '@/libs/request';
export const Login = (data) => request('Login', data);
export const GetManagerLogin = () =>
  storeRequest(
    { cmd: 'NgesAdminServer/NgesAdminUser/GetUserInfo' },
    { key: 'GetManagerLogin', cache: true },
  );
export const GetManagerLoginConfig = (data = {}) =>
  storeRequest(
    { cmd: 'GetManagerLoginConfig', payload: data },
    { key: 'GetManagerLoginConfig', cache: true },
  );
// 通过企业微信扫码登录
export const StaffLoginWithWorkwxForManager = (data) =>
  request('StaffLoginWithWorkwxForManager', data);
// 通过okta登录
export const StaffLoginByOIDCForManager = (data) => request('StaffLoginByOIDCForManager', data);
export const LoginPhoneBound = (data) => request('LoginPhoneBound', data);

export const LoginGetPhone = (data) =>
  request('AccountServer/AccountBase/GetPhoneByTmpToken', data);

export const SendSmsCode = (data) => request('AccountServer/AccountLogin/SendSmsCode', data);

export const TriggerLoginTerritory = function (data = {}) {
  return storeRequest(
    { cmd: 'AccountServer/AccountBase/TriggerLoginTerritory', payload: data },
    { key: 'TriggerLoginTerritory' },
  );
};

// 管理员登录
export const LoginAsOtherByPhone = (data) =>
  request('NgesAdminServer/NgesAdminUser/LoginAsOtherByPhone', data);

// 管理员登出
export const Logout = () => request('AccountServer/AccountLogin/Logout');

// 获取登录配置
export const getLoginConfig = (data) =>
  storeRequest(
    { cmd: 'NgesServer/NgesApp/GetWebLoginConfig', payload: data },
    { key: 'GetWebLoginConfig' },
  );

// 重置密码
export const ResetPassword = function (data) {
  return request('AccountServer/AccountBase/ResetPassword', data);
};
export const checkApiPermission = function () {
  return storeRequest(
    {
      cmd: 'AuthServer/AuthPermission/CheckApiPermission',
      payload: {
        path: 'DataServer/mask',
        action: 'RPC',
      },
    },
    { key: 'checkApiPermission' },
  );
};
export const LoginAsOtherByPasswd = (data) =>
  request('NgesAdminServer/NgesAdminUser/LoginAsOtherByPasswd', data);

export const LoginAsOtherQuick = (data) =>
  request('NgesAdminServer/NgesAdminUser/LoginAsOtherQuick', data);
