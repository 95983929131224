// 获取通知详情
const MessageDetail = 'query { message(id:$id) { id,name,content,create_time,type,read_status } }';
// 通知已读
const ReadMessage = 'mutation update {message(_where: {id: $id}, _set: {read_status: "2"})}';
// 所有通知已读
const ReadAllMessage =
  'mutation update {message(_where: {platform: {_include_any: [2]}}, _set: {read_status: "2"})}';
// 寻找未读通知
const NeedReadMessage =
  'query { message(read_status:"1", platform: {_include_any: [2]}) {_aggregate{_count}}}';

// 获取公告详情
const AnnouncementDetail = `query { announcement(id: $id) {
   name,
    level,
    content,
    online_time,
    offline_time,
    send_user,
    send_range,
    groups,
    status,
    organizations {
      id
      name,
      code
    },
    user_info {
      id
      name,
      uin,
      user_code,
      departments {name},
      territorys {name},
      identity_tag
    },
    hcp (_where: {multi_site_order: 0}){
      id,
      name,
      uin,
      hcp(_order_by: {multi_site_order: "_asc"}){
        hco{name},
        department{name},
        major_title{name}
      }
    }
   } }`;

const UpdateAnnouncement = `mutation update {announcement(_where: {id: $id}, _set: {
  name: $name,
  level: $level,
  content: $content,
  online_time: $online_time,
  offline_time: $offline_time,
  send_user: $send_user,
  send_range: $send_range,
  status: $status,
  groups: $groups,
  organizations: $organizations,
})}`;

const InsertAnnouncement = `
mutation insert{announcement(_values: {
  name: $name,
  level: $level,
  content: $content,
  online_time: $online_time,
  offline_time: $offline_time,
  send_user: $send_user,
  send_range: $send_range,
  status: $status,
  groups: $groups,
  organizations: $organizations,
} ) }`;

export {
  MessageDetail,
  ReadMessage,
  ReadAllMessage,
  NeedReadMessage,
  AnnouncementDetail,
  UpdateAnnouncement,
  InsertAnnouncement,
};
