//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAttachmentFields } from '../../../../../layout/util';
import { GetPrivateObjUrl } from '../../../../utils/api.js';
import { set } from 'lodash';
import { MINE_MAP } from '../../../../../constant';
import ngesDialog from 'nges-common/src/web/components/layout/dialog';
import AttachmentMask from '../../../attachment-mask';
const _ = { set };

export default {
  name: 'LayoutAttachmentDetail',
  components: { ngesDialog, AttachmentMask },
  options: { styleIsolation: 'shared' },
  props: {
    json: {
      type: Object,
      default: null,
    },
    showType: {
      type: String,
      default: '',
    },
    objects: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    maskConfig: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      preImg: false,
      preImgUrl: '',
      attachmentGroups: [],
    };
  },

  computed: {
    // 当前允许展示的文件后缀名
    fileExtension() {
      const fileMime = Object.keys(MINE_MAP).filter((item) => !MINE_MAP.image.includes(item));
      if (this.showType === 'file') return fileMime;
      return MINE_MAP.image;
    },
  },

  watch: {
    data() {
      this.initAttachmentGroup();
    },
  },

  async mounted() {
    this.initAttachmentGroup();
  },

  methods: {
    async initAttachmentGroup() {
      this.attachmentGroups = await this.getAttachmentGroups();
    },

    async getAttachmentGroups() {
      const { object } = this.json;
      const fields = getAttachmentFields({ object, objects: this.objects, json: this.json });
      const groups = fields.map((field) => {
        const fieldName = field?.name;
        const isPrivate = field?.text_option?.type === 'private_attachment';
        const amtStr = this.data?.[fieldName];
        let amt = [];
        try {
          amt = JSON.parse(amtStr.replaceAll(`\\"`, `"`));
        } catch (e) {
          if (amtStr) {
            amt = amtStr.split(/,|;/g).map((item) => ({
              url: item,
            })); // 兼容一些老的数据
          } else {
            amt = [];
          }
        }
        if (this.showType) {
          amt = amt.filter((item) => {
            const type = item.type
              ? item.type
              : /.*\.(.*)/.exec(item.name || item.url || '')[1].toLowerCase();
            item.type = type;
            return this.fileExtension.includes(type || 'png');
          });
        }
        return {
          label: field?.display_name,
          attachment: amt,
          isPrivate,
          fieldName,
          setMask: field?.text_option?.set_mask || this.maskConfig[fieldName],
        };
      });
      // 获取私有桶数据的临时url
      const promList = [];
      for (let j = 0; j < groups.length; j += 1) {
        if (groups[j].isPrivate) {
          const amt = groups[j].attachment || [];
          for (let i = 0; i < amt.length; i += 1) {
            ((idx) => {
              promList.push(
                new Promise((resolve) => {
                  this.getPrivateUrl(amt[idx]?.url, groups[j].fieldName).then((tempUrl) => {
                    _.set(amt[i], 'url', tempUrl);
                    resolve();
                  });
                }),
              );
            })(i);
          }
        }
      }
      await Promise.all(promList);
      return groups;
    },
    // 若已有附件为私有桶链接 -> 读取私有桶路径
    async getPrivateUrl(url, field) {
      if (url) {
        try {
          const res = await GetPrivateObjUrl({
            app_id: 'nges',
            expires: 300,
            url,
            object: this.json?.object,
            field,
            data_id: this.data?.id,
          });
          return res?.data?.url;
        } catch (e) {
          console.log(e?.message || e);
        }
      }
      return url;
    },

    // 点击查看图片
    previewImg(row) {
      this.preImgUrl = row.url;
      this.preImg = true;
    },
    // 点击查看文件
    previewFile(row) {
      const a = document.createElement('a');
      a.href = row.url;
      a.target = '_blank';
      a.click();
    },
  },
};
