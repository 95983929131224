//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WujieVue from 'wujie-vue2';
// import { replaceParams } from '../../../../web-mobile/util';
import login from '../../../utils/login';

const { bus: wujieBus } = WujieVue;

// const { VUE_APP_WITH_CUSTOM: appWithCustom } = process.env;

export default {
  components: {
    WujieVue,
  },
  props: {
    actionParams: {
      type: Object,
      default() {
        return {};
      },
    },
    expParamsObj: {
      type: Object,
      default() {
        return {};
      },
    },
    customPath: {
      type: String,
      default: '',
    },
    // 默认保活, 若是一个小组件内嵌, 非整个页面/弹框, 建议false
    alive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    path() {
      // fix: 带参数跳转进入后，url query 被浏览器 encode
      const queryPath = decodeURIComponent(this.customPath || '');
      console.log('nges custom path', queryPath);

      // 两种跳转方式
      // 1-重楼配置：custom_az_signincode?id=xx&p=yy
      const queryPathSplit = queryPath.split('?');

      // 2-按钮跳转：/saas/custom.html?custom=custom_az_signincode&id=xx&p=yy
      const routeQuery = this.$route.query;
      // 3-wujie参数：自定义跳转 /saas/custom.html?custom=custom_az_signincode&wujie=%2Fcustom%2Faz%2Fsignincode.html%3Fid%3Dxx
      const useWujie = routeQuery.uwj && routeQuery.wujie;

      let htmlPath = `/${queryPathSplit[0].replace(/_/g, '/')}.html`;
      if (useWujie) {
        htmlPath = decodeURIComponent(routeQuery.wujie);
      } else if (queryPathSplit.length > 1) {
        // custom_az_signincode?id=xx&p=yy
        htmlPath = `${htmlPath}?${queryPathSplit[1]}`;
      } else {
        // /saas/custom.html?custom=custom_az_signincode&id=xx&p=yy
        const excludeParam = ['wujie', 'uwj', 'custom'];
        const newQueryArray = Object.keys(routeQuery).reduce((a, b) => {
          if (excludeParam.includes(b)) {
            return a;
          }
          return [...a, `${b}=${routeQuery[b]}`];
        }, []);
        htmlPath = `${htmlPath}?${newQueryArray.join('&')}`;
      }

      // if (!this.isActived) {
      //   wujieBus.$emit('routeChange', htmlPath, { reload: !!routeQuery.reload });
      // }
      // 本地调试链接
      // if (appWithCustom) {
      //   return `http://localhost:9090${htmlPath}`;
      // }
      return htmlPath || '/custom/';
    },
    wujieData() {
      // 初始化父子应用通信 data
      return {
        // 获取弹窗数据
        getExpParamsObj: this.getExpParamsObj,
        // 按钮事件处理
        actionHandle: this.actionHandle,
        // 按钮事件处理
        close: this.close,
        // 子应用通知变更路由
        routerPush: this.routerPush,
        routerReplace: this.routerReplace,
        token: login.getToken(),
        uin: login.getUin(),
        loadingClose: this.loadingClose,
        support: {
          reload: 'n_242.0.0_4',
        },
      };
    },
  },
  watch: {},
  mounted() {
    this.onEvent();
  },
  unMounted() {
    this.offEvent();
  },
  methods: {
    routerPush(location) {
      this.$router.push(location);
    },
    routerReplace(location) {
      this.$router.replace(location);
    },
    getExpParamsObj() {
      return this.expParamsObj;
    },
    getActionParamsObj() {
      return this.getActionParams;
    },
    close() {
      this.$emit('close');
    },
    actionHandle(action) {
      this.$emit('action-handle', action);
    },
    loadingClose() {
      this.$emit('loading-close');
    },
    /* 定开组件引用时，事件通信 */
    emitEvent(eventName, data) {
      if (!eventName) {
        console.error('wujie emitEvent no eventName');
        return;
      }
      const queryPath = this.customPath || '';
      // 兼容路径有参数的情况
      const queryPathSplit = queryPath.split('?');

      wujieBus.$emit('ngesCommonEmit', {
        event: {
          toPath: queryPathSplit[0],
          eventName,
        },
        ...data,
        t: {
          ...this.getExpParamsObj(),
        },
        u: {
          uin: this.uin,
          token: this.token,
        },
      });
    },
    onEvent() {
      wujieBus.$on('ngesCustomEmit', (data) => {
        const { eventName } = data || {};
        if (!eventName) {
          console.error('wujie onEvent no event name', eventName);
          return;
        }
        console.log('wujie onEvent eimt to parent', data);
        this.$emit(eventName, data);
      });
    },
    offEvent() {
      wujieBus.$off('ngesCustomEmit', () => {
        console.log('wujie offEvent ngesCustomEmit');
      });
    },
    beforeLoad(appWindow) {
      console.log(`${appWindow.__WUJIE.id} beforeLoad`);
    },
    beforeMount(appWindow) {
      console.log(`${appWindow.__WUJIE.id} beforeMount`);
    },
    afterMount(appWindow) {
      console.log(`${appWindow.__WUJIE.id} afterMount`);
    },
    beforeUnmount(appWindow) {
      console.log(`${appWindow.__WUJIE.id} beforeUnmount`);
    },
    afterUnmount(appWindow) {
      console.log(`${appWindow.__WUJIE.id} afterUnmount`);
    },
    // 进入页面
    activated(appWindow) {
      // this.isActived = true;
      const routeQuery = this.$route.query;
      const { path } = this;
      wujieBus.$emit('routeChange', path, { reload: !!routeQuery.reload });
      console.log(`${appWindow.__WUJIE.id} activated`);
    },
    // 离开页面
    deactivated(appWindow) {
      console.log(`${appWindow.__WUJIE.id} deactivated`);
    },
    loadError(url, e) {
      console.log(`${url} 加载失败`, e);
    },
  },
};
