//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isArray, isEmpty, isObject } from 'lodash';
import Custom from 'nges-common/src/web/components/layout/custom/wujie';

export default {
  name: 'DocDialog',
  components: { Custom },
  props: {
    // 可选布局
    json: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      customPath: '',
      selections: [],
    };
  },
  mounted() {
    this.customPath = this.json?.custom_path;
    this.loading = !!this.customPath;
  },
  methods: {
    // 父组件使用
    clearData() {
      this.selections = [];
    },
    // 父组件使用
    getData() {
      // 后端判断 文章有效期和问卷有效期
      // const errorList = [];
      // this.selections.forEach((item) => {
      //   if (item.validity_time) {
      //     const date = new Date(item.validity_time);
      //     if (new Date() > date) {
      //       errorList.push(`【${item.name}】`);
      //     }
      //   }
      // });

      // if (errorList.length) {
      //   return { error: `${errorList.join('、')} 已过有效期，请重新选择！`, selections: [] };
      // }

      const selections = [...this.selections];
      this.clearData();
      return { data: selections };
    },
    // transParams({ input, page_index: pageIndex, page_size, filters }) {
    //   const res = {
    //     page_no: pageIndex + 1,
    //     page_size,
    //   };
    //   // 列筛选
    //   filters?.forEach?.((item) => {
    //     if (item.selected) {
    //       res[item.field] = item.selected;
    //     }
    //   });

    //   // 搜索框
    //   if (input && this.json?.search?.fields?.length) {
    //     this.json.search.fields.forEach((key) => {
    //       res[key] = input;
    //     });
    //   }

    //   return res;
    // },

    handleSelectionChange(val) {
      this.loading = false;
      const data = val?.data;
      let selections;
      if (isArray(data)) {
        selections = data;
      } else if (isObject(data) && !isEmpty(data)) {
        selections = [data];
      } else {
        selections = [];
      }

      this.selections = selections.filter((i) => i.questionnaire_id || i.questionnaire_name);
    },
  },
};
