//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import utils from './utils';
import { loadCustomUmdJs } from 'nges-common/src/web-mobile/util';
import { getPageConfig } from 'nges-common/src/web/utils/common';

// 只获取实际的组件名，去除前边的路径，如t1/umd
const getCompNameDetail = (compPath) => {
  const arr = compPath.split('/');
  const compName = arr[arr.length - 1];
  let compDir = '';
  if (arr.length > 1) {
    compDir = arr.slice(0, arr.length - 1).join('/');
  }
  return {
    compDir,
    compName,
  };
};

export default {
  name: 'RemoteCustomComponent',
  props: {
    customCompName: String,
    extraUtils: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      remoteDynamicComp: null,
      loaded: false,
    };
  },
  computed: {
    remoteUtils() {
      return {
        ...utils,
        ...this.extraUtils,
      };
    },
  },
  mounted() {
    if (this.customCompName) {
      const { compName } = getCompNameDetail(this.customCompName);
      if (window[compName]?.default) {
        this.remoteDynamicComp = compName;
      } else {
        this.loadScript(`/custom/${this.customCompName}.umd.min.js`, () => {
          if (compName && window?.[compName]?.default) {
            Vue.use(window[compName].default);
            this.remoteDynamicComp = compName;
          }
        });
      }
    }
  },
  methods: {
    async getConfig() {
      const customPackageConfig = await getPageConfig('custom_package_config', 'object');
      return customPackageConfig?.web || {};
    },
    async loadScript(src, callback) {
      try {
        loadCustomUmdJs(src, await this.getConfig(), callback);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
