//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { replaceParams } from '@/libs/utils/util';
import login from '@/utils/login';

export default {
  name: 'IframeDialog',
  components: {},
  props: {
    custom: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    isArticle: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: '',
    },
    noLogin: {
      // 免登录打开
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { visibleDialog: false };
  },
  computed: {
    redirectUri() {
      let redirectUri = this.url;
      if (redirectUri && redirectUri.indexOf('timestamp=') === -1) {
        redirectUri = replaceParams({ timestamp: Date.now() }, redirectUri);
      }

      if (!this.noLogin || !redirectUri) {
        return redirectUri;
      }

      redirectUri = replaceParams(
        {
          uin: login.getUin(),
          token: login.getToken(),
          type: 'staff', // 都以员工身份授权
          tokentype: 'weixin',
        },
        redirectUri,
      );

      // uin=${
      //   this.userInfo.uin
      // }&token=${this.userInfo.token}&type=${this.getLoginType()}&tokentype=weixin&appid=${
      //   this.options.appid
      // }&goback_path=true&mode=${_mode}&adtag=${getAdtag()}`;

      return redirectUri;
    },
  },
  watch: {
    visible: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.visibleDialog = newValue;
        }
      },
      immediate: true,
    },
  },
  methods: {
    closeFun() {
      this.$emit('update:visible', false);
      this.$emit('update:url', '');
    },
  },
};
