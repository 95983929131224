import { GetObjectsByNames } from '@/api/restful';

export default {
  namespaced: true,
  getters: {
    getObjectList(state) {
      return (name) => state.objectList[name];
    },
  },
  state: {
    objectList: {},
  },
  mutations: {
    setObjectList(state, { name, data }) {
      state.objectList[name] = data;
    },
  },
  actions: {
    // 保存已经请求过的对象数据
    async requestObject(context, { name }) {
      if (context.state.objectList[name]) return;

      const {
        list: [data],
      } = await GetObjectsByNames({
        objects: [name],
      });

      context.commit('setObjectList', { name, data });
    },
  },
};
