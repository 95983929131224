//
//
//
//

import * as echarts from 'echarts';
import resize from './mixins/resize';

export default {
  name: 'ChartPie',
  mixins: [resize],
  props: {
    pieList: {
      type: Array,
      default: () => [],
    },
    className: {
      type: String,
      default: 'chart',
    },
    id: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '282px',
    },
    height: {
      type: String,
      default: '282px',
    },

    chartColor: {
      type: Array,
      default: () => ['#FFCD72', '#A38BEC', '#38B8FE', '#DE8AE4', '#5BD0F1', '#766CF6'], // 为单个是颜色为 ['#FFCD72','#efefef']
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    pieData() {
      return this.pieList;
    },
  },
  updated() {
    this.initChart();
  },
  mounted() {
    this.initChart();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById(this.id));

      const itemStyle = {
        normal: {
          opacity: 1,
          borderWidth: 2,
          borderColor: '#fff',
        },
      };

      this.chart.setOption({
        title: {
          text: '',
          subtext: '',
          x: 'center',
          y: '35%',
          textStyle: {
            fontFamily: 'Futura-Medium',
            fontSize: '26',
            color: ' #0F1127',
            letterSpacing: 0,
          },
          subtextStyle: {
            fontFamily: 'PingFangSC-Medium',
            fontSize: 12,
            color: '#6A6A81',
            letterSpacing: 0,
            textAlign: 'center',
          },
        },
        animation: false,
        color: this.chartColor,
        series: [
          {
            name: '行为数据',
            type: 'pie',
            radius: '60%',
            hoverAnimation: false,
            label: {
              normal: {
                show: true,
                position: 'outside',
                formatter(value) {
                  const val = value.value;
                  return `${Number(val).toFixed(2)}%`;
                },
                // formatter: `{color|{d}%}`,
                rich: {
                  color: {
                    color: '#000000',
                    align: 'center',
                    fontSize: 15,
                  },
                },
              },
              emphasis: {
                show: true,
              },
            },
            labelLine: {
              // 引导线设置
              normal: {
                show: true, // 引导线显示
                length: 10,
                length2: 10,
                // length: -140,
                // length2: -25,
                lineStyle: {
                  color: this.chartColor[0],
                },
              },
            },
            data: this.pieData,
            itemStyle,
          },
        ],
      });
    },
  },
};
