//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import multipleSelect from './multipleSelect';
export default {
  components: {
    multipleSelect,
  },
  props: {
    // 宽度
    width: {
      type: [String, Number],
      default: '',
    },
    // 弹出框类型
    valueType: {
      type: String,
      default: '',
    },
    // 最小值
    min: {
      type: Number,
      default: 0,
    },
    // 最大值
    max: {
      type: Number,
      default: 0,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    hasFilter: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Boolean, Array],
      default: () => undefined,
    },
  },
  data() {
    return {
      form: {
        value: this.value,
        min: this.min,
        max: this.max,
      },
    };
  },
  computed: {
    isSingleSelect() {
      return this.options && this.valueType === 'SELECT_ONE';
    },
  },
  watch: {
    value(val) {
      this.form.value = val;
    },
  },
  methods: {
    resetFilter() {
      this.$emit('resetFilter');
      // reset后重新赋值给组件的from
      this.$nextTick(() => {
        this.form.value = this.value;
        this.form.min = this.min;
        this.form.max = this.max;
      });
    },
    filterOnSubmit() {
      this.$emit('filterOnSubmit');
    },
    filterClick() {
      this.$emit('filterClick');
    },
    valueChange(val) {
      this.$emit('change', val);
    },
    singleSelectChange(val) {
      this.valueChange(val);
      this.filterOnSubmit();
    },
    minChange(val) {
      this.$emit('minChange', val);
    },
    maxChange(val) {
      this.$emit('maxChange', val);
    },
    doClose() {
      this.$refs.popover?.doClose();
    },
  },
};
