import { isObject, forEach } from 'lodash';
import { isExpression as isOldExpression, isNewExpression } from './expressionUtil';
const _ = { isObject, forEach };

let expressionSdk = {};

// 顺序不能乱，PC端，通过覆盖实现


expressionSdk = require('./expressionWeb');


const { execExpression, expressionGlobalFuncKey } = expressionSdk;

/**
 * 对一个对象做深度遍历，替换所有的表达式字符串
 * @param {any} obj 处理的对象
 * @param {{ [key: Sting]: any }} paramsObj 表达式函数自定义入参数对象，会将对象中的每个属性依次传到表达式函数中
 */
function execExpressionDeep(
  obj,
  paramsObj = {},
  isReturnNow = true,
  isReturnExecPathObj = false,
  execPathObj = {},
  parentPath = '',
  blackKeys = [],
) {
  if (_.isObject(obj)) {
    if (obj.__auto_exec_expression__ === false) {
      return isReturnExecPathObj ? [obj, execPathObj] : obj;
    }
    _.forEach(obj, (value, idx) => {
      // pre_action 中不提前解析 action_callback
      if (idx === 'action_callback' || idx === 'response') return;
      if (!blackKeys.includes(idx)) {
        const path = `${parentPath ? `${parentPath}.` : parentPath}${idx}`;
        const execResult = execExpressionDeep(
          value,
          paramsObj,
          isReturnNow,
          isReturnExecPathObj,
          execPathObj,
          path,
          blackKeys,
        );
        if (isReturnExecPathObj && isExpression(value)) {
          execPathObj[path] = value;
        }
        obj[idx] = isReturnExecPathObj ? execResult[0] : execResult;
      }
    });
    return isReturnExecPathObj ? [obj, execPathObj] : obj;
  }
  const result = execExpression(obj, paramsObj);
  return isReturnExecPathObj ? [result, execPathObj] : result;
}

function getExpressionResult(expressionVal, { ...rest }) {
  return typeof expressionVal === 'function' ? expressionVal(rest) : expressionVal;
}

function isExpression(expressionStr = '') {
  return isOldExpression(expressionStr) || isNewExpression(expressionStr);
}

function hasExpression(obj) {
  let flag = false;
  if (_.isObject(obj)) {
    _.forEach(obj, (value) => {
      flag = flag || hasExpression(value);
    });
  } else {
    flag = isExpression(obj);
  }
  return flag;
}

export {
  isExpression,
  execExpression,
  execExpressionDeep,
  getExpressionResult,
  expressionGlobalFuncKey,
  hasExpression,
};
