//
//
//
//
//
//

import NoPermission from 'nges-common/src/web/components/layout/no-permission';
export default {
  components: {
    NoPermission,
  },
};
