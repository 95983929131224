import { isObject } from 'lodash';
import { Message } from 'element-ui';

// 复制文本到剪贴板
function copyToClipboardFallback(text) {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('复制失败', err);
  }

  document.body.removeChild(textarea);
}

async function copyToClipboard(text) {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('复制失败', err);
    }
  } else {
    copyToClipboardFallback(text);
  }
}

// 从剪贴板读取文本
function readFromClipboardFallback(callback) {
  const textarea = document.createElement('textarea');
  document.body.appendChild(textarea);
  textarea.focus();

  try {
    document.execCommand('paste');
    callback(null, textarea.value);
  } catch (err) {
    callback(err, null);
  }

  document.body.removeChild(textarea);
}

async function readFromClipboard(callback) {
  if (navigator.clipboard) {
    try {
      const text = await navigator.clipboard.readText();
      callback(null, text);
    } catch (err) {
      callback(err, null);
    }
  } else {
    readFromClipboardFallback(callback);
  }
}

function clipboardAction({ action }) {
  return new Promise((resolve) => {
    try {
      const { action_params: actionParams = {} } = action;
      const { type } = actionParams;
      if (type === 'set') {
        let { data } = actionParams;
        data = isObject(data) ? JSON.stringify(data) : data.toString();
        copyToClipboard(data)
          .then(() => {
            resolve();
            Message({
              message: '复制成功',
              type: 'success',
            });
          })
          .catch(() => {
            resolve();
          });
      } else if (type === 'get') {
        readFromClipboard((err, text) => {
          if (err) {
            console.error('读取失败', err);
            resolve();
          } else {
            try {
              resolve(JSON.parse(text)); // data
            } catch (err) {
              if (err) {
                resolve(text); // data
              }
            }
          }
        });
      } else {
        resolve();
      }
    } catch (err) {
      console.error(err);
      resolve();
    }
  });
}

export { clipboardAction };
