import { Cascader } from 'element-ui';
import { isFunction } from 'element-ui/src/utils/types';
import { isEqual } from 'element-ui/src/utils/util';
function override() {
  const getSuggestions = Cascader?.methods.getSuggestions;
  if(getSuggestions) {
    Cascader.methods.getSuggestions = function() {
      let { filterMethod } = this;

      if (!isFunction(filterMethod)) {
        filterMethod = (node, keyword) => node.text.toLowerCase().includes(keyword.toLowerCase());
      }

      const suggestions = this.panel.getFlattedNodes(this.leafOnly)
        .filter(node => {
          if (node.isDisabled) return false;
          node.text = node.getText(this.showAllLevels, this.separator) || '';
          return filterMethod(node, this.inputValue);
        });

      if (this.multiple) {
        this.presentTags.forEach(tag => {
          tag.hitState = false;
        });
      } else {
        suggestions.forEach(node => {
          node.checked = isEqual(this.checkedValue, node.getValueByOption());
        });
      }

      this.filtering = true;
      this.suggestions = suggestions;
      this.$nextTick(this.updatePopper);
    };
  }
}

override();
