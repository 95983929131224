import requestConfig, {
  request as requestSdk,
  storeRequest as storeRequestSdk,
} from '@tencent/eyao-request-web-sdk';
import config from '../config';
import aegis from './aegis';
import login from './login';
import { endsWith, set, includes } from 'lodash';
const _ = { endsWith, set, includes };

let urlProxyMap = {}; // 记录url替换路径，由GetAppConfig接口返回，若出现访问此接口，则替换成目标接口。

const getProxyUrl = (cmd) => {
  // 获取替换的cmd接口名
  for (const k of Object.keys(urlProxyMap)) {
    if (_.endsWith(k, cmd)) {
      return urlProxyMap[k].toString().replace(/^\//g, '');
    }
  }
  return cmd;
};

const afterRequest = function (res) {
  const url = res?.config?.url;
  if (_.includes(url, 'NgesServer/NgesApp/GetAppConfig')) {
    urlProxyMap = res?.data?.body?.payload?.cmd_redirect || {};
  }
  const header = res.header || res.headers;
  let tokenExpire = header?.['x-set-token-expire'];
  tokenExpire = tokenExpire?.split(',')?.[0];
  if (tokenExpire) tokenExpire = (Number(tokenExpire) + 31536000).toString();
  let token = header?.['x-set-token'];
  token = token?.split(',')?.[0];
  if (token) {
    let uin = header?.['x-set-uin'];
    uin = uin?.split(',')?.[0];
    const loginInfo = {
      token,
      uin,
      time: tokenExpire,
    };
    console.log('header', loginInfo);
    login.setLogin(loginInfo);
  } else if (tokenExpire) {
    login.setLogin({
      token: login.getToken(),
      uin: login.getUin(),
      time: Number(tokenExpire),
    });
  }
  let extraErrmsg = header?.['x-extra-errmsg'] || header?.['X-Extra-Errmsg'];
  extraErrmsg = extraErrmsg?.split(',')?.[0];
  // 对象校验规则提示对象的json字符串，通过base64加密了(2023.11.23开始该逻辑会被兼容，已变老功能，因为只有单个错误)
  if (extraErrmsg) {
    try {
      const checkResult = JSON.parse(decodeURIComponent(escape(window.atob(extraErrmsg))));
      _.set(res, 'data.body.payload.__rule_check_result', checkResult);
    } catch (err) {
      console.error(err);
    }
  }
  // 2023.11.23新增，校验返回多个错误，是个数组
  const errMsgs = header?.['x-extra-errmsgs'] || header?.['X-Extra-Errmsgs'];
  if (errMsgs) {
    try {
      const checkResult = errMsgs.split(',').reduce((pre, e) => {
        let json;
        try {
          json = JSON.parse(decodeURIComponent(escape(window.atob(e))));
        } catch (err) {
          console.error(err);
        }
        json && pre.push(json);
        return pre;
      }, []);
      _.set(res, 'data.body.payload.__rule_check_results', checkResult);
      window.$setValidateObject && window.$setValidateObject(checkResult);
    } catch (err) {
      console.error(err);
    }
  }
  if (res?.data?.body?.bizcode === 13101) {
    res.data.body.bizcode = 31009;
    res.data.body.message = '已存在相同数据';
  }
  return res;
};

const requestConfigOptions = {
  product: config.apiProduct,
  platform: config.apiPlatform,
  aegis,
  whiteFilter: config.whiteFilter,
  apiList: config.apiList,
  cloud: config.cloud,
  api: config.api,
  afterRequest,
  expireLogin: () => {
    login.clearLogin();
    login.toLogin();
  },
};

requestConfig(requestConfigOptions);

function setToken(cmd) {
  const token = login.getToken();
  const uin = login.getUin();
  if (!token && !config.whiteFilter.includes(cmd)) {
    login.toLogin();
    return false;
  }
  aegis.setConfig({
    uin,
  });
  requestConfig({
    token,
  });
  return true;
}
function setErr(e) {
  if (e && e.bizcode && [2403, 2404, 2405].includes(e.bizcode)) {
    // 没有权限
    window.Vue && window.Vue.prototype.$message('权限不足，请联系管理员');
  }
}

const request = function (cmd, payload = {}, options = {}) {
  cmd = getProxyUrl(cmd); // 访问的替换成配置的替换url
  if (!setToken(cmd)) return Promise.reject(new Error('登录后，访问系统'));
  // 注入当前页面路径到headers中
  set(options, 'headers["X-Nges-Location"]', encodeURI(location.pathname + location.search));
  set(options, 'client["version"]', process.env.VUE_APP_NGES_VERSION || '');
  return requestSdk(cmd, payload, options)
    .then((res) => {
      aegis.report({
        msg: 'request success',
        level: 2,
        ext1: cmd,
        ext2: payload,
      });
      return res;
    })
    .catch((e) => {
      setErr(e);
      return Promise.reject(e);
    });
};

const storeRequestFinishKey = [];
const storeRequest = function (opt, options) {
  // 访问的替换成配置的替换url
  if (opt?.cmd) {
    _.set(opt, 'cmd', getProxyUrl(opt.cmd));
  }
  if (!setToken(opt.cmd)) return Promise.reject(new Error('登录后，访问系统'));
  // 注入当前页面路径到headers中
  set(opt, 'options.headers["X-Nges-Location"]', encodeURI(location.pathname + location.search));
  set(opt, 'options.client["version"]', process.env.VUE_APP_NGES_VERSION || '');
  return storeRequestSdk(opt, options)
    .then((res) => {
      if (!storeRequestFinishKey.includes(options.key)) {
        aegis.report({
          msg: 'request success',
          level: 2,
          ext1: opt.cmd,
          ext2: opt.payload,
        });
        storeRequestFinishKey.push(options.key);
      }
      return res;
    })
    .catch((e) => {
      setErr(e);
      return Promise.reject(e);
    });
};

const apiRequest = function ({ cmd, params }) {
  cmd = getProxyUrl(cmd); // 访问的替换成配置的替换url
  console.log('----------> params', cmd, '----------> params:', params);
  return request(cmd, params);
};
export { request, storeRequest, apiRequest, requestConfig, requestConfigOptions };
export default request;
