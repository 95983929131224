//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import { execExpression, isExpression } from 'nges-common/src/layout/expression';
import { fieldClass, fieldLabel } from 'nges-common/src/layout/util';
import MButton from 'nges-common/src/web/components/layout/button';
import Action from '../../action';
import Premission from '../../permission';

export default {
  name: 'Card',
  components: {
    MButton,
  },
  mixins: [Action, Premission],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    row: {
      type: [Object, void 0],
      default: void 0,
    },
    expParamsObj: {
      type: [Object, void 0],
      default: () => {},
    },
    index: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    listItem() {
      const { item, expParams, row } = this;
      const listItem = { descs: [], tags: [], oneLineActions: [], allShowActions: [] };
      if (row.title) {
        listItem.title = fieldLabel(item, row.title, expParams);
      }
      if (row.status?.field || row.status?.label) {
        const hidden = this.execExpression(row.status?.hidden);
        if (hidden !== true) {
          listItem.status = {
            label: row.status?.label
              ? this.execExpression(row.status?.label)
              : fieldLabel(item, row.status.field, expParams),
            type: row.status.type
              ? this.execExpression(row.status?.type)
              : fieldClass(item, row.status.field, true, expParams) || 'default',
          };
        }
      }
      if (row.descs?.length) {
        if (isExpression(row.descs)) {
          const descs = execExpression(row.descs, { t: item, ...expParams });
          if (Array.isArray(descs)) {
            listItem.descs = descs;
          }
        } else {
          row.descs.forEach((field) => {
            const desc = fieldLabel(item, field, expParams);
            if ((_.isArray(desc) && desc.length > 0) || (_.isString(desc) && desc)) {
              listItem.descs.push(desc);
            }
          });
        }
      }
      if (row.tags?.length) {
        row.tags.forEach((tag) => {
          listItem.tags.push({
            label: tag.label
              ? this.execExpression(tag.label)
              : fieldLabel(item, tag.field, expParams),
            type:
              (tag.type
                ? this.execExpression(tag.type)
                : fieldClass(item, tag.field, true, expParams)) || 'default',
          });
        });
      }
      if (row.actions?.length) {
        // 第一行最大的按钮总字数
        const maxTotalNum = 10;
        let currentNum = 0;
        const filterActions = row.actions
          .filter(this.mixinFilterPermission)
          .filter(this.mixinFilterWritePermission(item))
          .map((action) => ({
            ...action,
            write_disabled: this.mixinDisabledWritePermission(action, item),
          }));
        filterActions.forEach((action) => {
          const hidden = _.isString(action.hidden)
            ? this.execExpression(action.hidden)
            : action.hidden;
          if (hidden !== true) {
            const label = this.execExpression(action.label) || '';
            currentNum += label.length;
            const firstLineNumLtMaxTotalNum = currentNum <= maxTotalNum;
            const newAction = {
              ...action,
              label,
              type: this.execExpression(action.type) || '',
              url: this.execExpression(action.url),
              action_params: {
                ...action.action_params,
                _item: item,
              },
            };
            listItem.allShowActions.push(newAction);
            const canPush = firstLineNumLtMaxTotalNum || listItem.oneLineActions.length === 0;
            if (canPush) {
              listItem.oneLineActions.push(newAction);
            }
          }
        });
      }
      return listItem;
    },
    expParams() {
      return {
        ...this.expParamsObj,
        $item: this.item,
        $index: this.index,
      };
    },
  },
  mounted() {},
  methods: {
    execExpression(str) {
      return execExpression(str, this.expParams);
    },
    toggleMore() {
      this.showMore = !this.showMore;
    },
  },
};
