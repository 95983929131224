//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 文本列表
    textList: {
      type: Array,
      default: () => [],
      required: true,
    },
    // 是否需要自定义弹窗按钮
    needSlotFooter: {
      type: Boolean,
      default: false,
    },
    // 取消按钮文案
    cancelButtonText: {
      type: String,
      default: '取消',
    },
    // 确定按钮文案
    confirmButtonText: {
      type: String,
      default: '确定',
    },
    // 确定回调
    onConfirm: {
      type: Function,
      default: () => {},
    },
    // 取消回调
    onCancel: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    onCancelButton() {
      this.onCancel({});
    },
    onConfirmButton() {
      this.onConfirm({});
    },
  },
};
