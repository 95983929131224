//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Clipboard from '../../Clipboard';

export default {
  name: 'AsyncImage',
  components: {
    Clipboard,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      default: () => {},
    },
    compress: {
      type: Boolean,
      default: false,
    },
    imgOptions: {
      type: Object,
      default() {
        return {
          width: 40,
          height: 40,
          openPreview: true,
        };
      },
    },
  },
  data() {
    return {
      isLoading: true,
      isImage: true,
      isOrigin: false,
      previewImg: {
        visible: false,
        src: '',
      },
    };
  },
  watch: {
    url() {
      this.preLoad();
    },
  },
  created() {
    this.preLoad();
  },
  methods: {
    getCompressImg() {
      const { url, isOrigin, compress } = this;
      if (!compress || isOrigin) {
        return url;
      }
      // return url;
      return url && !url.includes('?') ? `${url}?imageMogr2/thumbnail/!20p` : url;
    },
    imgPreview() {
      if (!this.imgOptions.openPreview) return;
      this.previewImg.src = this.url;
      this.previewImg.visible = true;
    },
    preLoad() {
      if (this.compress) {
        this.isImageAsync();
      } else {
        this.isOriginImageAsync();
      }
    },
    isOriginImageAsync() {
      this.isLoading = true;
      let image = new Image();
      const { url } = this;
      const clearVar = () => {
        image.onload = null;
        image.onerror = null;
        image = null;
      };
      image.onload = () => {
        this.isLoading = false;
        this.isImage = true;
        this.isOrigin = true;
        clearVar();
      };
      image.onerror = () => {
        this.isLoading = false;
        this.isImage = false;
        clearVar();
      };
      image.src = url;
    },
    isImageAsync() {
      this.isLoading = true;
      let image = new Image();
      const clearVar = () => {
        image.onload = null;
        image.onerror = null;
        image = null;
      };
      image.onload = () => {
        this.isLoading = false;
        this.isImage = true;
        clearVar();
      };
      image.onerror = () => {
        clearVar();
        this.isOriginImageAsync();
      };
      image.src = this.getCompressImg();
    },
  },
};
