/* eslint-disable no-useless-escape */
// 媒体相关接口
// 插入图片
const InsertMaterial = `mutation insert { mcm_material(_values: {name: $name, url: $url,data_source: $data_source,record_type:$record_type, type:$type $otherKeys}) { material_official(_values: $app_id) } } `;
// 插入视频
const InsertVideo = `mutation insert { mcm_material(_values: {name: $name, url: $url,record_type:$record_type, type:$type, remark:$remark}) { material_official(_values: $app_id) } } `;
// 素材列表
const listMaterial = `query{mcm_material(_order_by: { update_time: _desc},data_source: $data_source,record_type:$record_type,$pageOption,_distinct: true, $filter){_aggregate{ _count},name,url,type,record_type,create_time,update_time,id,effective_status,material_official{official_accounts{name, id}}}}`;
// 关联素材查询 23R4 SP2
const RelatedMaterials = `query { mcm_material( _where: {id: {_in: $ids}} ) { $otherKeys }}`;
// 关联文档查询 23R4 SP2
const RelatedDocuments = `query { mcm_document( _where: {id: {_in: $ids}} ) { $otherKeys }}`;

// 素材列表-按公众号筛选
const listMaterialOfficial = `query{mcm_material_official(_order_by: { material.update_time: _desc},material.data_source: $data_source,official_id:{_in : $app_id},material.record_type:$record_type,$pageOption,_distinct: true, $filter){_aggregate{ _count},  material.update_time, material{name,id,effective_status,url,type,record_type,create_time,update_time,material_official{official_accounts{name, id}}}}}`;
// 删除素材
const DelMaterial = `mutation delete {
  mcm_material(id: $id) {
id
}
}`;
// 编辑素材
const EditMaterial = `mutation update {
mcm_material(_where: {id: $id}, _set: {name: $name}) {
  material_official(_values: $official_id)
}
}`;

const UploadDocGql = `mutation insert { mcm_document(_values: $value)}{id} `;

const GetArticleGql = `query{
  mcm_article($pageOption,_order_by:{update_time:_desc}, _where:{ _and:[{official_ids: {_include_any: $official_account}}, {effective_status: "1"}]}){
    _aggregate{_count},id,list_cover,headline_cover,title,skip_link,cmt_switch,source,update_time,status,effective_status,vote_id,tag{name},product{name},official_accounts{name,id,appid},update_by{name},material{record_type}
  }
}`;

// 素材有效期
const QueryMaterialValidScope = `query{ mcm_material_valid_scope (material_id:$id) {      start_time,      end_time, material_type, status,     } } `;
const AddMaterialValidScope = `mutation insert{ mcm_material_valid_scope (_values: {material_id:$id,start_time:$start_time,end_time:$end_time, material_type:$material_type, status:$status }) } `;
const UpdateMaterialValidScope = `mutation update {mcm_material_valid_scope(_where: {material_id:$id}, _set: {start_time:$start_time,end_time:$end_time, material_type:$material_type, status:$status})}`;
const DeleteMaterialValidScope = `mutation delete {mcm_material_valid_scope(_where: {material_id: $id})} `;

function GetDocListGql(data) {
  return `query{mcm_document(_order_by: { create_time: _desc}, $pageOption,$filter) {
  _aggregate { _count },
    type,
    size,
    url,
    code,
    count,
    status,
    create_time,
    id,
    eda,
    effective_status,
    ${data.eda ? 'eda_doc{ id, eda_url,eda_title,eda_pages },' : ''}
    logs (type: {_in: [2, 1]}) {
      download_count: _count(id)
    }
  }
}`;
}

const RenameDocGql = `mutation update{mcm_document(_where:{id:$id}, _set:{code: $name})}`;

const addCountDocGql = `mutation update{mcm_document(_where:{id:$id}, _set:{count: $count})}`;

const GetDocTypeListGql = `query{mcm_document(_order_by: { create_time: _desc}){ type}}`;

const DelDocGql = `mutation delete{mcm_document(id:$id) {id}}`;
// 新增文档/素材库日志
const insertLogGql = `mutation insert { mcm_document_log(_values: {doc_id: $doc_id, material_id: $material_id , type: $type, channel: $channel, record_type: $record_type, source_info: $source_info, target_info: $target_info})} `;

const queryHasVideoGql = `query{
  mcm_article(_where: {id: {_in: $articleIds }}) {
    material {
      record_type
    }
    title
  }
}`;

// 根据id查询图片
const QueryImageByIdList = `{
  mcm_material(_where: {url: {_in: $imageList}}) {
    _all
  }
}`;

export {
  InsertMaterial,
  listMaterial,
  EditMaterial,
  DelMaterial,
  listMaterialOfficial,
  InsertVideo,
  UploadDocGql,
  GetDocListGql,
  RenameDocGql,
  GetDocTypeListGql,
  DelDocGql,
  addCountDocGql,
  insertLogGql,
  GetArticleGql,
  queryHasVideoGql,
  AddMaterialValidScope,
  QueryMaterialValidScope,
  UpdateMaterialValidScope,
  DeleteMaterialValidScope,
  RelatedMaterials,
  RelatedDocuments,
  QueryImageByIdList,
};
