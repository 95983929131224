//
//
//
//
//
//
//
//

export default {
  props: {
    pageIndex: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
  },
  methods: {
    getIndex(scope) {
      return scope.$index + 1 + (this.pageIndex - 1) * this.pageSize;
    },
  },
};
