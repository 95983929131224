//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GetRoleTree } from '@/api/common.js';
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    labelWidth: {
      type: String,
      default: '',
    },
    appendToBody: {
      type: Boolean,
      defalut: false,
    },
    field: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      staffList: [],
      staffRangeList: [],
      choseDoctor: false,
      choseStaff: false,
      choseTourist: false,
    };
  },
  watch: {
    value: {
      handler() {
        this.initData();
      },
      deep: true,
    },
  },
  created() {
    // 获取获取角色列表
    GetRoleTree({
      appid: 2,
      parent_code: 'staff',
    }).then((res) => {
      this.formatStaffList(res?.data);
      this.initData();
    });
  },
  methods: {
    formatStaffList(tree = []) {
      const formatted = tree.map((item) => {
        let children = null;
        if (item.children && item.children.length) {
          children = this.formatStaffList(item.children);
        }
        return {
          label: item.name,
          value: item.code,
          children,
        };
      });
      this.staffList = formatted;
      return formatted;
    },
    initData() {
      if (this.value && this.value.length) {
        const identityTag = [...this.value];
        const doctorIndex = identityTag.indexOf('doctor');
        if (doctorIndex !== -1) {
          identityTag.splice(doctorIndex, 1);
          this.choseDoctor = true;
        }
        const staffIndex = identityTag.indexOf('staff');
        if (staffIndex !== -1) {
          identityTag.splice(staffIndex, 1);
          this.choseStaff = true;
        }
        const touristIndex = identityTag.indexOf('tourist');
        if (touristIndex !== -1) {
          identityTag.splice(touristIndex, 1);
          this.choseTourist = true;
        }
        this.staffRangeList = this.getStaffRangeList(identityTag);
      }
    },
    getStaffRangeList(idList) {
      // traverse optionList & check leaf node
      const process = function (nodes, path = []) {
        nodes.forEach((node) => {
          if (node.children?.length) {
            process(node.children, [...path, node.value]);
          } else {
            const index = idList.indexOf(node.value);
            if (index > -1) {
              idList.splice(index, 1, [...path, node.value]);
            }
          }
        });
      };
      process(this.staffList);
      return idList;
    },
    getIdentityTagData() {
      let identityTag = this.staffRangeList;
      if (this.choseDoctor) {
        identityTag = identityTag.concat(['doctor']);
      }
      if (this.choseStaff) {
        identityTag = identityTag.concat(['staff']);
      }
      if (this.choseTourist) {
        identityTag = identityTag.concat(['tourist']);
      }
      console.log('角色选择组件$emit内容为：', identityTag);
      return identityTag;
    },
    dataChange() {
      this.$emit('input', this.getIdentityTagData());
    },
  },
};
