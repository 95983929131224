import { storeRequest, request, requestConfig } from './request';
import { request as graphqlRequest } from './graphqlRequest';
import { customLogin } from './login';
import { APP_CONFIG } from './constant';
import { getPlatform } from './util';
import { keys } from 'lodash';
import config from './config';
import dayjs from 'dayjs';

const _ = { keys };

// 移动端专用 在登录时要使用其他platform标识更详细的当前平台 4-企业微信小程序 5-腾讯会议登录
function LoginRequest({ cmd, data, key, platform }) {
  // 发请求前设置更细致的platform 发完了再改回去
  requestConfig({
    platform: platform || getPlatform(),
  });
  if (key) {
    return storeRequest({ cmd, payload: data }, { key }).then(
      (res) => {
        requestConfig({
          platform: config.platform,
        });
        return res;
      },
      (e) => {
        requestConfig({
          platform: config.platform,
        });
        return Promise.reject(e);
      },
    );
  }
  return request(cmd, data).then(
    (res) => {
      requestConfig({
        platform: config.platform,
      });
      return res;
    },
    (e) => {
      requestConfig({
        platform: config.platform,
      });
      return Promise.reject(e);
    },
  );
}

const TouristLogin = function (data = {}) {
  if (!data.appid) {
    return Promise.reject(new Error('no enough params'));
  }
  
  return storeRequest(
    { cmd: 'AccountServer/AccountLogin/TouristLogin', payload: data },
    { key: 'TouristLogin' },
  );
};
const LoginWithWeixin = function (data) {
  if (!data.appid || !data.code || !data.login_type) {
    return Promise.reject(new Error('no enough params'));
  }
  
  return storeRequest(
    { cmd: 'AccountServer/AccountLogin/LoginWithWeixin', payload: data },
    { key: 'LoginWithWeixin' },
  );
};
const LoginWithWorkWeixin = function (data) {
  if (!data.appid || !data.code || !data.login_type) {
    return Promise.reject(new Error('no enough params'));
  }
  
  return storeRequest(
    { cmd: 'AccountServer/AccountLogin/LoginWithWorkWeixin', payload: data },
    { key: 'LoginWithWorkWeixin' },
  );
};

const BindWeixinAccount = function (data = {}) {
  return storeRequest(
    { cmd: 'NgesServer/NgesStaff/BindStaffWeixin', payload: data },
    { key: 'BindWeixinAccount' },
  );
};

const GetTenantObjectsPermission = () =>
  storeRequest(
    { cmd: 'AuthServer/AuthPermission/GetTenantObjectsPermission' },
    {
      cache: true,
      key: 'GetTenantObjectsPermission',
    },
  ).then((res) => {
    const list = [];
    res?.data?.forEach((item) => {
      const tableName = item.table_name;
      item.action.forEach((action) => {
        // action: select insert update delete
        list.push(`${tableName}.${action}`);
      });
    });
    return list;
  });
const GetObjectsByNames = (data) => {
  if (!data.objects?.length) {
    return Promise.reject('no objects');
  }
  return storeRequest(
    { cmd: 'DataServer/MetadataService/GetObjectsByNames', payload: data },
    { key: data.objects.sort().join('-'), cache: true },
  );
};

// 获取微信的环境版本
function getWxEnvVersion() {
  let env = '';
  try {
    env = uni.getAccountInfoSync().miniProgram.envVersion || '';
  } catch (e) {
    try {
      env = __wxConfig.envVersion || '';
    } catch (e) {}
  }
  return env;
}

const GetUserMenuTree = (data = {}) => {
  // 微信体验版，采用正式环境的灰度环境菜单数据
  
  return storeRequest(
    {
      cmd: 'AuthServer/AuthPermission/GetUserMenuTree',
      payload: data,
    },
    { key: 'GetUserMenuTree', cache: true },
  );
};
// const GetUserMenuTree = (data = {}) => request('AuthServer/AuthPermission/GetUserMenuTree', data);

const GetUserLayoutList = (data = {}) => {
  return request('AuthServer/AuthPermission/GetUserLayoutList', data);
};

const DataService = (data) => {
  return graphqlRequest('DataServer/DataService/GraphqlQuery', data);
};
const ListSubordinateTerritory = () => {
  return storeRequest(
    {
      cmd: 'NgesServer/NgesStaff/ListSubordinateTerritory',
    },
    { key: 'ListSubordinateTerritory', cache: true },
  );
};
const SearchSubordinateTerritory = (data) => {
  return request('AuthServer/AuthUser/SearchSubordinateTerritory', data);
};
function GetAppConfig(payload = {}) {
  const appid = customLogin.getAppid();
  if (!appid) return Promise.reject(new Error('no appid'));
  // 兼容公众号和会议助手
  let data;
  if (/^wx/.test(appid)) {
    data = {
      appid,
      ...payload,
    };
  } else {
    data = {
      meeting_sdk_id: appid,
      ...payload,
    };
  }
  return storeRequest(
    {
      cmd: 'NgesServer/NgesApp/GetAppConfig',
      payload: data,
    },
    { key: 'GetAppConfig', cache: true },
  ).then((res) => {
    const keyMap = {
      app_logo: 'applets_logo_url',
      loading_img: 'loading_img_url',
      name: 'applets_name',
      enterprise_name: 'company_name',
      enterprise_logo: 'company_logo_url',
      meeting_corp_id: 'wecom_corpid',
      meeting_h5_mode: 'meeting_h5_mode',
      ext_config: 'ext_config',
      mobile_domain: 'mobileDomain',
    };
    const data = { ...res };
    const outData = {};
    _.keys(data).forEach((key) => {
      if (keyMap[key]) outData[keyMap[key]] = data[key];
      outData[key] = data[key];
    });
    return { ...APP_CONFIG, ...outData };
  });
}
function GetAppKv(key, type) {
  const appid = customLogin.getAppid();
  if (!appid) return Promise.reject(new Error('no appid'));
  return storeRequest(
    {
      cmd: 'NgesServer/NgesApp/GetAppKv',
      payload: {
        appid,
        type: type || 'page',
        key,
      },
    },
    { key: `GetAppKv_${key}`, cache: true },
  ).then((res) => {
    return res?.value || {};
  });
}

const getStaffUserInfo = function (data, key) {
  return storeRequest(
    { cmd: 'NgesServer/NgesStaff/GetStaffUserInfo', payload: data },
    { key: key || 'GetStaffUserInfoCommon' },
  );
};

const getDoctorUserInfo = function (data = {}) {
  return storeRequest(
    { cmd: 'NgesServer/NgesDoctor/GetDoctorUserInfo', payload: data },
    { key: 'GetDoctorUserInfoCommon' },
  );
};

// 启动审批流
const StartProcessInsByObject = function (data) {
  return request('Workflow/ApprovalService/StartProcessInsByObject', data);
};
// 审批发起申诉
const AppealApply = function (data) {
  return request('Workflow/ApprovalService/AppealApply', data);
};
// 获取当前审批流的字段权限
const GetProcessFieldPermission = (data) =>
  request('Workflow/ApprovalService/GetProcessFieldPermission', data);

// 通过审批流接口保存编辑数据
const SaveApprovalEditDetail = (data) =>
  request('Workflow/ApprovalService/SaveApprovalEditDetail', data);

// 获取私有桶下载链接
const GetPrivateObjUrl = function (data) {
  return request('NgesServer/NgesApp/GetPrivateObjUrl', data);
};
// 获取公有桶上传签名
const getUploadSignApi = function (data) {
  return request('ApiServer/Cos/GetUploadSign', data);
};
// 获取私有桶上传签名
const getPrivateUploadSignApi = function (data) {
  return request('ApiServer/Cos/GetPrivateUploadSign', data);
};

// 获取当前是否有未读消息
const NeedReadMessage = function () {
  // 根据平台，设置不同的platform参数 1-小程序，2-web，3-h5
  let platform = 1;
  
  
  return DataService({
    query: `query { message(read_status:"1", platform: {_include_any: [${platform}]}) {_aggregate{_count}}}`,
  });
};
// 获取是否有未读公告
const NeedReadAnnouncement = function () {
  return DataService({
    query: `query {announcement(
        _where: {status: 2, _or: [{send_range: 1}, {send_user: {_include_any: [$_uin]}}, {groups: {_include_any: $_public_group}}, {organizations: {_include_any: $_org_par}}], read_status.id: {_is_null: true}}
        _limit: 1
      ) {_aggregate {_count}, read_status(owner: $_uin) {id}}
    }`,
  });
};
const DoctorNeedReadAnnouncement = function () {
  return DataService({
    query: `query {announcement(
        _where: {status: 2, _or: [{send_user: {_include_any: [$_uin]}}], read_status.id: {_is_null: true}}
        _limit: 1
      ) {_aggregate {_count}, read_status(owner: $_uin) {id}}
    }`,
  });
};

const GetKvConfigs = (keys) => {
  return DataService({
    query: `query {kv (key:{ _in: ${keys}}) {value key}}`,
  });
};

// 管理员登出
const Logout = () => request('AccountServer/AccountLogin/Logout');

const GetUserElementList = () =>
  storeRequest(
    {
      cmd: 'AuthServer/AuthPermission/GetUserElementList',
    },
    { key: 'GetUserElementList', cache: true },
  );

// 获取当前是否有未读消息
const GetLoginUserInfo = function () {
  return DataService({
    query: 'query { user_info(_where: {uin: $_uin}) {name id}}',
  });
};

// 获取 OCR 信息
const CommonOcr = (data) => request('EventsServer/Meeting/EventsOcr', data);
const FindDoctorByCode = (data) => request('NgesServer/InvitationCode/FindDoctorByCode', data);

const UploadStaffQrCode = (data) => request('NgesServer/NgesStaff/UploadStaffQrCode', data);

const CreateQRCode = function (data) {
  return storeRequest(
    { cmd: 'NgesServer/NgesStaff/CreateQRCode', payload: data },
    { key: 'CreateQRCode' },
  );
};

const GetBusinessCardOpen = () => {
  return GetKvConfigs('["business_card_flag"]').then(({ kv }) => {
    return kv?.find((item) => item.key === 'business_card_flag')?.value === '1';
  });
};

const GetBusinessCardPreview = () => {
  return GetKvConfigs('["business_card_flag", "business_card_preview_flag"]').then(({ kv }) => {
    const open = kv?.find((item) => item.key === 'business_card_flag')?.value === '1';
    const preview = kv?.find((item) => item.key === 'business_card_preview_flag')?.value === '1';
    return open && preview;
  });
};

const GetSaffBusinessCard = (data) => request('NgesServer/NgesStaff/GetSaffBusinessCard', data);

const CreateShortKey = (data) => request('NgesServer/ShortKey/CreateShortKey', data);

const FindShortKey = (data) => request('NgesServer/ShortKey/FindShortKey', data);

const TextInfoExtract = (data, options) =>
  request('NgesServer/NgesAi/TextInfoExtract', data, options);

const GetFederationToken = (data, options) =>
  request('ApiServer/STS/GetFederationToken', data, options);

const GetAudioFormConfig = () => {
  return GetKvConfigs('["nges_audio_form"]').then(({ kv }) => {
    return kv?.find((item) => item.key === 'nges_audio_form')?.value;
  });
};

const GetTencentCloudId = () => {
  return GetKvConfigs('["tencent_cloud_appid"]').then(({ kv }) => {
    return kv?.find((item) => item.key === 'tencent_cloud_appid')?.value;
  });
};

const QueryRuleCheck = (data) => request('DataServer/RuleService/ComplianceCheck', data);

const GetSubordinateList = (data) => request('NgesServer/NgesStaff/ListSubordinateTerritory', data);

// 获取登录配置
const getLoginConfig = (data) =>
  storeRequest(
    { cmd: 'NgesServer/NgesApp/GetWebLoginConfig', payload: data },
    { key: 'GetWebLoginConfig' },
  );

// 校验 token 是否有效
const ParseToken = (data) => request('AccountServer/AccountBase/ParseToken', data);

const getDataLockStatus = (data) => request('DataServer/DataService/GetDataLockStatus', data);
const lockData = (data) => request('DataServer/DataService/LockData', data);
const unlockData = (data) => request('DataServer/DataService/UnlockData', data);
// 获取帮助指引
const GetHelpGuide = function () {
  return DataService({
    query: `query {
      help_guide(start_time: {_lte: $_now}, end_time: {_gte: $_now}, _limit: 1) {
        id
        name
        pc_conf
        mobile_conf
      }
    }`,
  });
};

const GetShareRule = (data) => request('AuthAdminServer/ManualShareRule/GetManualShareRules', data);
const UpdateShareRule = (data) =>
  request('AuthAdminServer/ManualShareRule/PutManualShareRule', data);

const GetGroupList = (data) => request('AuthServer/AuthGroup/GetGroupList', data);

const GetAuthorizedDataRows = (data) => {
  return request('DataServer/DataService/GetAuthorizedDataRows', data);
};

const GetAccountUserInfo = (data) => request('AccountServer/AccountBase/GetUserInfo', data);

const queryUndoneMyTaskCount = () => {
  return DataService({
    query: `query {my_task(_where: {status: "undone", record_type: "todo", todo_person: $_uin}_limit: 1) {_aggregate {_count}}}`,
  });
};

// 通过appid获取授权code，用于webview中和外部系统对接
const GetAuthCode = (data) => request('AccountServer/AccountApi/GetAuthCode', data);

// 寻找未读反馈
const queryNeedReadFeedback = (data) => {
  const start = dayjs().unix();
  const end = dayjs().subtract(30, 'days').unix();
  const NeedReadFeedback = `query {usage_feedback(_or: [{ status.read: { _is_null: true } }, { status.read: false } ], _where:{_and:[{create_time:{_lte:${start}}},{create_time:{_gte:${end}}}]}) { count: _count(id)}}`;
  const NeedReadFeedbackWithoutTime = `query {usage_feedback(_or: [{ status.read: { _is_null: true } }, { status.read: false } ]) { count: _count(id)}}`;
  const query = data === 'feedbackProcessing' ? NeedReadFeedbackWithoutTime : NeedReadFeedback;
  return DataService({
    query,
  });
};

// 使用反馈标记已读
const UsageFeedbackMarkRead = (data) =>
  request('NgesServer/NgesUsageFeedback/UsageFeedbackMarkRead', data);

function GetHelpGuideRead(id) {
  return DataService({
    query: `query {ignore_help_guide_user(help_guide_id: "${id}", owner: $_uin){url}}`,
  });
}
function SetHelpGuideRead(id, urlList) {
  const gql = urlList.map((url) => {
    return ` {help_guide_id: "${id}", url: "${url}"}`;
  });
  return DataService({
    query: `mutation insert {
    ignore_help_guide_user(_values:[${gql.join(',')}])
    }`,
  });
}

export {
  LoginRequest,
  TouristLogin,
  LoginWithWeixin,
  LoginWithWorkWeixin,
  GetTenantObjectsPermission,
  BindWeixinAccount,
  GetObjectsByNames,
  GetUserMenuTree,
  GetUserLayoutList,
  DataService,
  ListSubordinateTerritory,
  SearchSubordinateTerritory,
  GetAppConfig,
  GetAppKv,
  getStaffUserInfo,
  getDoctorUserInfo,
  StartProcessInsByObject,
  AppealApply,
  GetPrivateObjUrl,
  getUploadSignApi,
  getPrivateUploadSignApi,
  GetProcessFieldPermission,
  SaveApprovalEditDetail,
  NeedReadMessage,
  NeedReadAnnouncement,
  DoctorNeedReadAnnouncement,
  GetKvConfigs,
  Logout,
  GetUserElementList,
  GetLoginUserInfo,
  CommonOcr,
  FindDoctorByCode,
  UploadStaffQrCode,
  CreateQRCode,
  GetBusinessCardOpen,
  GetBusinessCardPreview,
  CreateShortKey,
  FindShortKey,
  GetSaffBusinessCard,
  TextInfoExtract,
  GetFederationToken,
  GetTencentCloudId,
  GetAudioFormConfig,
  QueryRuleCheck,
  getLoginConfig,
  ParseToken,
  GetSubordinateList,
  getDataLockStatus,
  lockData,
  unlockData,
  GetHelpGuide,
  GetShareRule,
  UpdateShareRule,
  GetGroupList,
  GetAuthorizedDataRows,
  GetAccountUserInfo,
  queryUndoneMyTaskCount,
  GetAuthCode,
  queryNeedReadFeedback,
  UsageFeedbackMarkRead,
  GetHelpGuideRead,
  SetHelpGuideRead,
};
