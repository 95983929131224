//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { EyaoSaaSSynCreateMaterialApi } from '@/api/releaseManagement';
import { mapGetters } from 'vuex';
export default {
  name: 'MaterialManagementImageUpload',
  data() {
    return {
      showback: true, // 是否新打开的页面(是为false)
      loading: false, // 加载
      uploadList: [],
    };
  },
  computed: {
    ...mapGetters('user', { layoutJson: 'getLayoutJson' }),
  },
  created() {
    this.showback = !this.isNewBlank();
  },
  mounted() {
    this.$refs.mutation &&
      this.$refs.mutation.open({
        id: null,
      });
  },
  methods: {
    // 保存图片到素材库
    async mutationActionHandle(actionData) {
      const { action } = actionData;
      console.log('action', action, actionData);
      if (action === 'SUBMIT') {
        this.loading = true;
        this.uploadList = [];
        const datas = this.$refs.mutation.$refs.mutation.formData.url;
        console.log('布局提交数据：', datas);
        if (datas && datas.length) {
          this.$refs.mutation.$refs.mutation.isFormValidate().then(async (valid) => {
            if (valid) {
              try {
                datas.forEach((item) => {
                  this.uploadList.push({
                    url: item.url,
                    name: item.name,
                    data_source: 'ADMIN',
                    app_id: item.app_id.map((item) => {
                      return {
                        official_id: item.id,
                      };
                    }),
                    record_type: 'image',
                    type: item.type,
                  });
                });
                await this.uploadList.forEach(async (item) => {
                  await EyaoSaaSSynCreateMaterialApi(item);
                });
                this.closeComp();
                this.$message.success('上传图片成功');
                this.loading = false;
              } catch (error) {
                this.$message.error(`上传图片失败, 请重新保存`);
                this.loading = false;
              }
            } else {
              this.$message.warning(`请验证校验信息！`);
              this.loading = false;
            }
          });
        } else {
          this.$message.warning(`请上传图片！`);
          this.loading = false;
        }
      }
      if (action === 'BACK' && window.history.length === 1) {
        window.close();
      }
    },
    // 隐藏组件  val = 'back'(返回)  'save'(保存或发布)
    closeComp() {
      if (window.history.length === 1) {
        this.g_tourl(`/saas/materialManagement.html?componentName=ImageComp&key=image`);
      } else {
        this.$router.go(-1);
      }
    },
    // 是否新标签页
    isNewBlank() {
      const { query } = this.$route;
      // 判断是否新打开的页面
      return query.type === 'newWindow';
    },
  },
};
