// 数据中心文章数据-饼图
export const articleDataPie = (props) => {
  // 数据格式 data: [name:'北京',value:293,...]
  // 获取主题色
  const color = getBrandColor('--Bg-Brand-default');
  return {
    // 选项配置
    option: {
      animation: false,
      color: ['#16bfca', color],
      title: {
        show: true,
        text: props.title,
        textStyle: {
          fontSize: '16px',
        },
      },
      series: [
        {
          type: 'pie',
          showEmptyCircle: true,
          bottom: '20%',
          label: {
            show: true,
            position: 'inside',
            formatter: (e) => {
              if (String(e.percent).includes('.')) {
                return `${e.percent.toFixed(1)}%`;
              }
              return `${e.percent}%`;
            },
            textStyle: {
              color: 'white',
            },
          },
          labelLine: {
            show: false,
          },
          data: props.data,
          radius: ['30%', '60%'],
        },
      ],
      legend: {
        orient: 'horizontal',
        bottom: '60',
        icon: 'circle',
        itemGap: 40,
      },
    },
  };
};
// 数据中心文章数据-柱状图
export const articleDataPieBar = (props) => {
  // 数据格式 data: {key:['北京','上海'], value:[293,245]}
  const { data, tooltip } = props;
  // 获取主题色
  const color = getBrandColor('--Bg-Brand-default');
  //   let len = 1;
  //   data.key.forEach((e) => {
  //     if (len < String(e).length) {
  //       len = String(e).length;
  //     }
  //   });
  //   const left = 35 + 12 * (len > 8 ? 9 : len); // 35是固定距离 1个字符6px宽  超过8位按8位统计,加上...占两位字符, 所以是9
  let len = 1;
  let lenStr = '';
  data.key.forEach((e) => {
    if (len < String(e).byteLength()) {
      len = String(e).byteLength();
      lenStr = String(e);
    }
  });
  let left = 0;
  // 最大16字节就要截断
  if (len > 16) {
    left = 35 + 100;
  } else {
    left = 45 + lenStr.pxWidth();
  }
  return {
    // 选项配置
    option: {
      animation: false,
      color: [color],
      grid: {
        height: '280',
        left,
        top: '10%',
      },
      title: {
        show: true,
        text: props.title ? [`{a|${props.title}} {b|${props.unit}}`] : '',
        textStyle: {
          rich: {
            a: {
              fontSize: '16px',
              fontWeight: 'bold',
            },
            b: {
              fontSize: '12px',
            },
          },
        },
      },
      xAxis: {
        position: 'top',
      },
      yAxis: {
        axisTick: {
          show: false,
        },
        max: 9,
        data: data.key,
        axisLabel: {
          // width: 100,
          // overflow: 'truncate',
          formatter: (value) => {
            const val = String(value);
            let valTemp = val;
            if (val.length > 8) {
              valTemp = val.substring(0, 8);
              valTemp += '...';
            }
            return `${valTemp}`;
          },
        },
        triggerEvent: true,
        inverse: true,
      },
      tooltip: {
        trigger: 'axis',
        formatter: `<p style="font-weight:bold;max-width:200px;white-space:pre-wrap;word-break:break-word;">{b0}</p>
        <span style="color:#6A7684">${tooltip}:</span>
        <span style="color:${color}">{c0}</span>`,
        confine: true,
      },
      series: [
        {
          type: 'bar',
          data: data.value,
        },
      ],
    },
    // 是否开启坐标系提示框
    isAxiasTooltip: true,
  };
};

function getBrandColor(key) {
  const { body } = document;
  return window?.getComputedStyle(body)?.getPropertyValue(key) || '#3286fa';
}
