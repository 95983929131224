const configCommon = require('../common');
const development = require(`./development`);
const test = require(`./test`);
const preview = require(`./preview`);
const production = require(`./production`);

const commonConfig = {};
const config = configCommon.setConfig(commonConfig, { development, test, preview, production });
console.log('config', config);
module.exports = config;
