//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 返回
    publicAddBackFun: {
      type: Function,
      defulte: null,
    },
  },
  data() {
    return {
      form: {
        name: null, // 公共号名称
        APPid: null, // 公共号ID
        APPSecret: null, // 公共号Secret
      },
      id: '',
      publicLoading: false, // 查看的全局loading
      isAuthorityAll: false, // 服务号
      bottonLoading: false, // button的loading
      isSub: null, // 订阅号
      isSer: null, // 服务号
      rules: {
        name: [
          { required: true, message: '请输入公众号名称', trigger: 'blur' },
          { min: 1, max: 10, message: '最多输入10个字符', trigger: 'blur' },
        ],
        APPSecret: [
          { required: true, message: '请输入正确的App Secret', trigger: 'blur' },
          { min: 1, max: 40, message: '最多输入40个字符', trigger: 'blur' },
        ],
        APPid: [
          { required: true, message: '请输入App ID', trigger: 'blur' },
          { min: 1, max: 25, message: '最多输入25个字符', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    // 返回
    Goback() {
      this.publicAddBackFun(false);
    },
    // 保存
    doctoronSubmit(formName) {
      let gen = 0;
      if (!this.isSub && !this.isSer) {
        this.$message('订阅号，服务号两项至少选择一项');
        return;
      }
      if (this.isSub === 1) {
        gen = 2;
      }
      if (this.isSer === 2) {
        gen = 1;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const req = {
            app_name: this.form.name,
            new_app_id: this.form.APPid,
            app_secret: this.form.APPSecret,
            type: gen,
          };
          this.$sendToEgg({ cmd: 'addAppInfo', query: JSON.stringify(req) })
            .then((res) => {
              if (res.result.code === 40001) {
                this.$invalidToken();
                return;
              }
              if (res.result.code !== 0) {
                this.$message(res.result.ResultDate.msg);
                return;
              }
              if (res.result.status === 'success') {
                this.$message('保存成功');
                localStorage.setItem('hasAppId', true);
                this.Goback();
              } else {
                this.$message('修改失败');
                this.Goback();
              }
            })
            .catch((e) => {
              console.error(e);
              this.$message('保存失败');
            });
        } else {
          this.$message('请按照要求填写完整信息');
          return false;
        }
      });
    },
    // 订阅号确定
    SubClikc() {
      if (this.isSub !== 1) {
        this.isSub = 1;
        this.isSer = null;
      }
    },
    // 服务号确定
    Serclick() {
      if (this.isSer !== 2) {
        this.isSer = 2;
        this.isSub = null;
      }
    },
  },
};
