const queryDepartmentGql = `query {
  user_department($filter) {
    _aggregate {
        _count
    }
    id
    name
    parent_id
  }
}`;

const queryUserOrDepartmentGql = `query {
  user_department(_limit: 10, _offset: 0, $filter) {
    id
    name
  }
  user_info(
    _limit: 10
    _offset: 0
    $filter1
  ) {
    id
    name
    department_ids
  }
}`;

export { queryDepartmentGql, queryUserOrDepartmentGql };
