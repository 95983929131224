//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TagCategoryDialog',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShow: false,
      checkList: [],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    open() {
      this.isShow = true;
      this.checkList = [];
    },
    close() {
      this.isShow = false;
    },
    submit() {
      console.log('this.checkList', this.checkList);
      this.$emit('select', this.checkList);
      this.isShow = false;
    },
  },
};
