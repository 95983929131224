//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import Action from '../../action';
import Popover from '../../popover';
import { execExpressionDeep } from 'nges-common/src/layout/expression';
import TrackingCompMixin from 'nges-common/src/tracking/mixins/comp-mixin';
export default {
  components: { Popover },
  mixins: [Action, TrackingCompMixin],
  props: {
    action: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      default: () => 'text',
    },
    loading: Boolean,
    disabled: Boolean,
    expParamsObj: {
      type: Object || Array,
      default() {
        return {};
      },
    },
    data: {
      type: Object,
      default: null,
    },
    // 点击是否需要回调，emit的click事件是异步的话需要回调
    needCallback: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: -1,
    },
    needThrottle: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: '',
    },
    pageIndex: {
      type: [Number, void 0],
      default: void 0,
    },
    pageSize: {
      type: [Number, void 0],
      default: void 0,
    },
    iconImage: {
      type: String,
      default: '',
    },
    iconPosition: {
      type: String,
      default: 'right',
      validator(value) {
        return ['left', 'right'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    calcDisabled() {
      // 整体字段disabled下，使某个button不disabled
      if (this.action.disabled === false) {
        return false;
      }
      return Boolean(this.disabled || this.execExpression(this.action.disabled));
    },
    className() {
      const className = ['btn'];
      if (this.action.class_name) {
        className.push(this.action.class_name);
      }
      return className;
    },
    isElementIcon() {
      return this.iconClass.indexOf('el-') >= 0;
    },
  },
  created() {
    this.onClick = this.needThrottle
      ? _.throttle(this.handleClk, this.action?.wait || 2000, { trailing: false })
      : this.handleClk;
  },
  methods: {
    actionHandle() {
      if (this.data) {
        return this.m_actionHandle({
          ...this.action,
          action_params: {
            ...this.data,
            _item: { ...this.data },
            _data: { ...this.data },
            _index: this.index,
            _pageIndex: this.pageIndex,
            _pageSize: this.pageSize,
            ...((this.action && this.action.action_params) || {}),
          },
        });
      }
      return this.m_actionHandle(this.action);
    },
    execExpression(data) {
      return execExpressionDeep(data, this.expParamsObj);
    },
    handleClk() {
      if (!this.disabled) {
        this.$emit('click', this.action, () => (this.needCallback ? this.actionHandle() : null));
        !this.needCallback && this.actionHandle();
        // 按钮点击事件上报
        this.__compTracking(
          {
            event_label: (this.expParamsObj?.json || {})?.title,
            event_name: `${this.execExpression(this.action.label) || ''}点击`,
            event_type: 'click',
            event_id: 'click_Event',
          },
          this.execExpression(this.action?.tracking),
        );
      }
    },
    autoBlur(e) {
      setTimeout(() => {
        try {
          if (e.target.nodeName === 'SPAN') {
            e.target.parentNode.blur();
          } else if (e.target.nodeName === 'BUTTON') {
            e.target.blur();
          }
        } catch (e) {
          console.log(e);
        }
      }, 100);
    },
  },
};
