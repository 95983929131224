//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GetSubordinateList } from '../../../api';
import { depthFirstTraverse } from 'nges-common/src/web-mobile/util';



// 方向找到组件节点
function findAncestors(node, value, path = []) {
  if(!node) return null;
  if (node.value === value) {
    return [...path, node.value];
  }
  if (node.subordinate_list) {
    for (let i = 0; i < node.subordinate_list.length; i++) {
      const result = findAncestors(node.subordinate_list[i], value, [...path, node.value]);
      if (result) {
        return result;
      }
    }
  }
  return null;
}
function findValuesInTree(values, tree) {
  return values.map((value) => {
    for (let i = 0; i < tree.length; i++) {
      const result = findAncestors(tree[i], value);
      if (result) {
        return result;
      }
    }
    return [];
  });
}


// 该组件里级联里每一个对象中，没有一个属性是可以用来做唯一标识的，这个组件不适用回显
export default {
  data() {
    return {
      props: {
        multiple: true,
        checkStrictly: true,
        // lazy: true,
        // lazyLoad: this.getData,
        children: 'subordinate_list',
        label: 'name',
      },
      tree: [],
      treeMap: {},
      nodeMap: {},
      selected: [],
    };
  },
  mounted() {
    this.GetSubordinateList();
  },
  methods: {
    setValue(v) {
      this.selected = findValuesInTree(v, this.tree);
      this.handleTmpChange(this.selected);
    },
    async getData(cNode, re) {
      try {
        const node = this.nodeMap[cNode.value];
        if (!node) {
          return re();
        }
        const exitList = this.treeMap[node.value];
        if (exitList?.length) {
          re(exitList);
        } else if (node.territory_code) {
          const res = await GetSubordinateList({
            level: 1,
            territoryCode: node.territory_code,
          });
          const tree = res.data?.length > 0 ? [...(res.data[0]?.subordinate_list || [])] : [];
          this.treeMap[node.value] = tree;
          depthFirstTraverse(
            {
              [this.props.children]: tree,
            },
            this.props.children,
            (treeNode) => {
              if (treeNode.$height > 1) {
                treeNode.label = treeNode[this.props.label];
                treeNode.value = `${Math.random()}`.slice(2);
                this.nodeMap[treeNode.value] = treeNode;
                if (treeNode?.[this.props.children]?.length) {
                  this.treeMap[treeNode.value] = treeNode[this.props.children];
                }
                this.list.push({
                  label: treeNode.label,
                  value: treeNode.value,
                  job_name: treeNode.job_name,
                });
              }
            },
          );
          re(tree);
        } else {
          re();
        }
      } catch (err) {
        console.error(err);
        re();
      }
    },
    async GetSubordinateList() {
      try {
        const res = await GetSubordinateList({
          // level: 1,
        });
        this.tree
          = res.data?.length > 0
            ? [
                {
                  ...res.data[0],
                },
              ]
            : [];
        const treeToList = [];
        depthFirstTraverse(
          {
            [this.props.children]: this.tree,
          },
          this.props.children,
          (node) => {
            const children = node['subordinate_list'];
            if(children && !children.length) {
              delete node['subordinate_list'];
            }
            if (node.$height > 1) {
              node.$parent = node.$parent || '0';
              node.label = node[this.props.label];
              node.value = `${Math.random()}`.slice(2);
              this.nodeMap[node.value] = node;
              if (node?.[this.props.children]?.length) {
                this.treeMap[node.value] = node[this.props.children];
              }
              treeToList.push({
                label: node.label,
                value: node.value,
                job_name: node.job_name,
              });
            }
          },
        );
        this.list = treeToList;
        this.$emit('get-options', this.list);
      } catch (err) {
        console.error(err);
      }
    },
    handleTmpChange(e) {
      const datas = [];
      const values = e.reduce((pre, i) => {
        const value = i[i.length - 1];
        const pathLabels = i.map((ii) => this.nodeMap[ii]).map((ii) => ii.label);
        const node = this.nodeMap[value];
        const territoryCode = node?.territory_code;
        datas.push({
          label: node.label,
          value: territoryCode,
          id: node.value,
          pathLabels,
        });
        if (territoryCode) {
          pre.push(territoryCode);
        }
        return pre;
      }, []);
      console.log('===', e, values, datas);
      this.$emit('change', values);
      this.$emit('change-list', { value: values, data: datas });
    },
    reset() {
      this.$refs.cascader.clearCheckedNodes();
    },
  },
};
