import { getPrivateUploadSignApi, getUploadSignApi } from '../../../utils/api';
import COS from 'cos-js-sdk-v5';

async function initCos({ suffix, isPrivate }) {
  let cosData;
  try {
    if (!isPrivate) {
      // 共有桶上传
      cosData = await getUploadSignApi({
        app_id: 'nges',
        credentials: 1,
        suffix,
      });
    } else {
      // 私有桶上传
      cosData = await getPrivateUploadSignApi({
        app_id: 'nges',
        suffix,
      });
    }
  } catch (err) {
    console.log(err);
  }
  const { data } = cosData;
  const { credentials } = data;
  const cos = new COS({
    CompatibilityMode: true,
    Protocol: 'https:',
    Domain: data.domain,
    ChunkRetryTimes: 3, // 出错重试次数
    ChunkSize: 5 * 1024 * 1024, // 分块大小 5MB
    getAuthorization(options, callback) {
      const { Method, Pathname, Query, Headers } = options;
      const authorization = COS.getAuthorization({
        SecretId: credentials.tmp_secret_id, // 可传固定密钥或者临时密钥
        SecretKey: credentials.tmp_secret_key, // 可传固定密钥或者临时密钥
        Method,
        Pathname,
        Query,
        Headers,
      });
      callback({
        Authorization: authorization,
        XCosSecurityToken: credentials.session_token,
        StartTime: data.start_time,
        ExpiredTime: data.expired_time,
      });
    },
  });
  return {
    cosData,
    cos,
  };
}

export async function uploadToCos({ file, suffix, isPrivate = false, onProgress = () => {} }) {
  return new Promise((resolve, reject) => {
    initCos({ suffix, isPrivate })
      .then((res) => {
        const { cosData, cos } = res;
        const { data } = cosData;
        if (!cos && !cosData) {
          reject('no COS');
          return;
        }
        const options = {
          Bucket: data.bucket,
          Region: data.region,
          Key: data.key,
          Body: file,
          // ContentDisposition: `attachment; filename* = UTF-8' '${encodeURI(file.name)}`,
          SliceSize: 1024 * 1024 * 50,
          onProgress,
        };
        const cb = (err) => {
          if (err) {
            reject(err);
          } else {
            resolve({
              name: file.name,
              url: cosData.data.cdn_url,
              originName: file.url,
              type: file.type,
            });
          }
        };
        cos.putObject(options, cb);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
