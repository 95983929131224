//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import List from '../list';
// import Mutation from '../mutation/components/mutation';
import Detail from '../detail/components/detail';
import Action from '../../action';
import { mapState } from 'vuex';
import TabMixin from './tabMixin';

export default {
  name: 'MixinTab',
  components: {
    List,
    // Mutation,
    Detail,
  },
  mixins: [Action, TabMixin],
  props: {
    firstTabPanelLabel: {
      type: String,
      default: '',
    },
    hideTabHeadWhenOneTab: {
      type: Boolean,
      default: false,
    },
    showTabs: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('user', {
      menuList: 'menuList',
    }),
  },
  data() {
    return {
      activeName: '-1',
      tabsData: [],
    };
  },
  methods: {
    handleClick() {},
    removeTab(targetName) {
      const tabs = this.tabsData;
      let { activeName } = this;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (String(index) === targetName) {
            if (tabs[index + 1]) {
              activeName = String(index + 1);
            } else if (tabs[index - 1]) {
              activeName = String(index - 1);
            } else {
              activeName = '-1';
            }
          }
        });
      }
      this.activeName = activeName;
      this.tabsData = tabs.filter((tab, index) => String(index) !== targetName);
    },

    async m_customAction(action) {
      /**
       * 若自身父节点已经存在mix_tab组件了，则自己不处理，由父mixin_tab处理，保证在最外层的tab打开
       * 如：详情套了带mixtab的列表，列表中点了打开tab的按钮，最后需在最外层详情布局中打开tab。
       * */
      const tab = await this.customAction(action);
      if (tab) {
        this.tabsData.push(tab);
        this.activeName = String(this.tabsData.length - 1);
      }
      return action;
    },
    reload() {
      if (!this.$refs?.tabitem?.length) return;
      this.$refs.tabitem.forEach((ref) => {
        ref.reload && ref.reload(); // 详情刷新
        ref.getTableData && ref.getTableData(); // 列表刷新
      });
    },
  },
};
