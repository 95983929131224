import config from './config';
const { aegisId } = config;
let Aegis = null;
// eslint-disable-next-line import/no-mutable-exports
let aegis;

const H5Aegis = require('@tencent/aegis-web-sdk');
Aegis = H5Aegis;




const defaultConfig = {
  id: aegisId, // 项目ID
  uin: config.env !== 'prod' ? config.env : '', // 通用用来监控某个特定用户的常规日志上报
  reportApiSpeed: true, // 接口测速
  pagePerformance: true,
  offlineLog: true,
  offlineLogExp: 7,
  api: {
    // 设置上报规则
    retCodeHandler(data) {
      let isErr = false;
      let code = 0;
      try {
        if (Object.prototype.toString.call(data) === '[object Object]') {
          if (data.body) {
            isErr = !(
              (data.body.bizcode === 0 && data.body.retcode === 0)
              || (data.body.bizcode === 0 && data.body.retcode === 1004)
              || (data.body.bizcode === 0 && data.body.retcode === 1014)
            );
            code = data.body.retcode || data.body.bizcode;
          }
        }
      } catch (e) {
        console.log(e);
      }

      return {
        isErr,
        code,
      };
    },
  },
  spa: true,
  reportAssetSpeed: true, // 静态资源测速
  
};

// 微信下，通过异步化js的方式获取表达式。使用__non_webpack_require__构建后，方法还能保持require.async，否则会被打包成 __webpack__.require


aegis = new Aegis(defaultConfig);


let canReport;
let reportStart;
let reportEnd;
let events = [];



export { reportStart, reportEnd };

export default aegis;
