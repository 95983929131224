const { VUE_APP_BUILD_TENANT: tenant = 'default' } = process.env;
const envConfig = require(`./${tenant}/index.js`);

function setConfig(config) {
  const completeConfig = {
    ...config,
    ...envConfig,
  };
  return completeConfig;
}
// commonConfig保存了与环境无关的固定配置
// 与环境相关的配置需要读store/config获取
const commonConfig = {
  apiProduct: 2,
  apiPlatform: 2, // siwen todo；1和3 是 WEB和H5?
  
  cosAppId: 'nges',
  product: 'saas',
  company: 'tencent',
  api: '/api/access/json',
  cloud: '/api/access/pb',
  apiList: [], // 非云上接口列表
  whiteFilter: [
    'AccountServer/AccountLogin/Login',
    'AccountServer/AccountLogin/LoginWithWorkWeixinByThirthQr',
    'NgesServer/NgesApp/GetWebLoginConfig',
    'AccountServer/AccountBase/ParseToken',
    'NgesServer/NgesApp/GetAppConfig',
  ],
  layoutFilter: ['help', 'login', 'protocol', 'door', 'ticket'],
  rightFilter: [
    'help',
    'login',
    'protocol',
    'register',
    'door',
    'ticket',
    'custom',
    'rame',
    'wujie',
    'layoutList',
    'layoutDetail',
    'layoutTab',
    'layoutMutation',
    'noPermission',
    'iframe',
  ],
};
const config = setConfig(commonConfig);
console.log('config', config);
export default config;
