//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { values } from 'lodash';

export default {
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    valueType: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    componetProps: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      size: 'small',
      value: null,
      timer: null,
    };
  },
  computed: {
    // item通用样式
    itemStyle() {
      return '';
    },
    isValueTypeSelectMany() {
      return this.valueType === 'SELECT_MANY';
    },
  },
  methods: {
    resetFilter() {
      this.value = null;
    },
    handleInputChanged() {
      this.emitData(null, this.value ? 1000 : 100);
    },
    handleSelectSelector() {
      let { value } = this;
      if (value && !this.isValueTypeSelectMany) {
        value = [value];
      }
      this.emitData(value, 100);
    },
    handleSelectCascader() {
      const res = [];
      this.value.forEach((item) => {
        res.push(item[item.length - 1]);
      });
      console.log('materialFilter组件及联tag选择: ', res);
      this.emitData(res, 100);
    },
    emitData(value, ms = 100) {
      // 节流
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        // 值回传父组件，进行筛选
        this.$emit('change', { value: value || this.value });
      }, ms);
    },
  },
};
