//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  GetWeComDepartmentById,
  GetWeComUserByName,
  GetWeComDetailNameById,
  GetContactTagList,
} from '@/api/addressList';
/**
 * selectViewRange组件
 * 用于选择人员
 *
 * props：
 *  agentId：用于部门请求的企业应用id
 *  userIdList：用于设置已选人员的初始值
 *  departmentIdList：用于设置已选部门的初始值
 *  disableSelection：用于手动禁用选择，禁用后弹框将不会打开
 *  noLabel：设置为true后，组件内不能选择标签
 *  disableSelectAll: 设置为true后，不能全选
 *
 * methods：
 *  setChecked(userIdList, departmentList)：用于手动添加已选人员
 *  submitForm：用于获得最终的选择结果，返回人员id和部门id两个数组
 *  reloadTree：清空树并重新获取节点，会在agentId变更时自动调用，也可以手动调用
 */
const defaultAgentId = -1;
export default {
  name: 'SelectViewRange',
  props: {
    agentId: {
      type: Number,
      default: -1,
    },
    userIdList: {
      type: Array,
      default: () => [],
    },
    departmentIdList: {
      type: Array,
      default: () => [],
    },
    labelIdList: {
      type: Array,
      default: () => [],
    },
    disableSelection: {
      type: Boolean,
      default: false,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    disableSelectAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search_isshow: false,
      defaultProps: {
        children: 'children',
        label: 'name',
        isLeaf: 'leaf',
      },
      visible: false,
      defaultValue: null,

      buttonLoading: true, // 选择范围按钮的loading，用于回显处理时禁用按钮
      activeName: '1', // tab切换

      departmentId: 0, // 当前正在查询的departmentId
      departmentInvalid: 1,

      filterText: '', // 搜索text
      fewUser: true, // 是否展示更少的搜索结果
      fewDepartment: true,
      fewLabel: true,
      searchUserList: [], // 搜索结果列表
      searchDepartmentList: [],
      searchLabelList: [],

      searchTimer: null, // 搜索节流
      showSearchResult: false, // 搜索结果显示

      selectedDepartmentIds: new Map(),
      selectedUserIds: new Map(),
      selectedLabelIds: new Map(),

      confirmDepartmentIds: new Map(),
      confirmUserIds: new Map(),
      confirmLabelIds: new Map(),

      labelList: [], // 标签列表
      labelIndex: 1,
      labelLoading: false, // 下拉加载loading
      labelNoMore: false, // 全部加载完毕

      isChooseAll: false, // 全选
      confirmChooseAll: false,

      trigger: false, // 用于触发vue刷新（map无法触发刷新）

      onLoadNode: false, // 用于保证同一时间只有一个loadNode在执行
    };
  },
  computed: {
    departIds() {
      // 用于tree判断节点是否已选
      // temp是为了触发vue刷新页面
      const temp = this.trigger;
      console.log(temp);
      return Array.from(this.selectedDepartmentIds.keys());
    },
    userIds() {
      // 用于tree判断节点是否已选
      // temp是为了触发vue刷新页面
      const temp = this.trigger;
      console.log(temp);
      return Array.from(this.selectedUserIds.keys());
    },
    labelIds() {
      // 用于tree判断节点是否已选
      // temp是为了触发vue刷新页面
      const temp = this.trigger;
      console.log(temp);
      return Array.from(this.selectedLabelIds.keys());
    },
    hasSelection() {
      // 判断是否有选择
      const temp = this.trigger;
      console.log(temp);
      // console.log(Array.from(this.confirmUserIds.keys()).length);
      // console.log(Array.from(this.confirmDepartmentIds.keys()).length);
      return (
        Array.from(this.confirmUserIds.keys()).length +
          Array.from(this.confirmDepartmentIds.keys()).length +
          Array.from(this.confirmLabelIds.keys()).length !==
        0
      );
    },
    searchResultUser() {
      // 用于展开和收起，展示部分或全部搜索结果
      if (this.fewUser) {
        return this.searchUserList.slice(0, 3);
      }
      return this.searchUserList;
    },
    searchResultDepartment() {
      if (this.fewDepartment) {
        return this.searchDepartmentList.slice(0, 3);
      }
      return this.searchDepartmentList;
    },
    searchResultLabel() {
      if (this.fewLabel) {
        return this.searchLabelList.slice(0, 3);
      }
      return this.searchLabelList;
    },
    disableLabelLoading() {
      return this.labelLoading || this.labelNoMore;
    },
  },
  watch: {
    filterText(val) {
      this.search_isshow = !!val;
      if (val) {
        this.searchName();
      } else {
        this.showSearchResult = false;
      }
    },
    agentId(val) {
      console.log('agent-id发生变化');
      this.agentId = val;
      this.reloadTree();
      this.reloadLabelList();
    },
  },
  created() {},
  mounted() {
    // 从props设置已经选择的人员/部门/标签
    if (this.userIdList.length || this.departmentIdList.length || this.labelIdList.length) {
      this.setChecked(this.userIdList, this.departmentIdList, this.labelIdList);
    }
    this.getLabelList();
  },
  methods: {
    setChecked(userIdList, departmentList, labelList) {
      // 设置已经选择的人员/部门，可以从外层手动调用
      console.log('设置选择人员/部门/标签');
      this.buttonLoading = true;
      GetWeComDetailNameById({
        user_id_list: userIdList,
        department_id_list: departmentList,
        tag_id_list: labelList,
      })
        .then((res) => {
          for (const user of res.user_detail_list) {
            this.confirmUserIds.set(user.userid, user.name);
            this.selectedUserIds.set(user.userid, user.name);
          }
          for (const depart of res.department_detail_list) {
            this.confirmDepartmentIds.set(depart.id, depart.name);
            this.selectedDepartmentIds.set(depart.id, depart.name);
          }
          if (!this.noLabel) {
            for (const tag of res.tag_detail_list) {
              this.confirmLabelIds.set(tag.qy_tag_id, tag.tag_name);
              this.selectedLabelIds.set(tag.qy_tag_id, tag.tag_name);
            }
          }
        })
        .catch((e) => {
          this.$message.error(e);
        })
        .finally(() => {
          this.triggerUpdate();
          this.buttonLoading = false;
        });
    },
    loadNode(node, resolve) {
      // 用于tree的载入函数

      // if (this.onLoadNode) {
      //   // 同一时间只能存在一个loadNode，防止异步问题
      //   return;
      // }
      this.onLoadNode = true;
      if (node.level === 0) {
        this.departmentId = 0;
        this.nodeHead = node;
        this.resolveHead = resolve;
      } else {
        this.departmentId = node.data.id;
        this.departmentInvalid = node.data.invalid;
      }

      this.getDepartments().then((data) => {
        console.log(data);
        if (data) {
          for (const item of data.user_list) {
            item.leaf = true;
          }
          resolve([...data.department_list, ...data.user_list]);
        } else {
          resolve([]);
        }

        this.buttonLoading = false;
        this.onLoadNode = false;
      });
    },
    checkNode(node, data) {
      // 从树形结构添加选择
      console.log(node);

      if (data.invalid === 1) {
        this.$message.warning('所选对象已被禁用');
        return;
      }

      if (data.leaf) {
        // 个人 删除&添加
        if (this.selectedUserIds.has(data.userid)) {
          this.selectedUserIds.delete(data.userid);
        } else {
          this.selectedUserIds.set(data.userid, data.name);
        }
      } else {
        // 部门 删除&添加
        if (this.selectedDepartmentIds.has(data.id)) {
          this.selectedDepartmentIds.delete(data.id);
        } else {
          this.selectedDepartmentIds.set(data.id, data.name);
        }
      }
      this.triggerUpdate();
    },
    getLabelList() {
      // 获取标签列表
      this.labelLoading = true;
      GetContactTagList({
        agent_id: this.agentId,
        page_index: this.labelIndex,
        page_size: 20,
      })
        .then((res) => {
          this.labelNoMore = res.tags.length === 0;
          this.labelIndex += 1;
          this.labelList.splice(this.labelList.length, 0, ...res.tags);
        })
        .catch((e) => {
          this.message.error(e);
        })
        .finally(() => {
          this.labelLoading = false;
        });
    },
    reloadLabelList() {
      const timer = setInterval(() => {
        if (!this.labelLoading) {
          clearInterval(timer);
          this.labelIndex = 1;
          this.labelList.splice(0, this.labelList.length);
          this.getLabelList();
        }
      }, 100);
    },
    checkLabel(label) {
      // 从标签列表中添加/删除label，也用于从搜索结果添加标签
      if (!this.noLabel) {
        console.log(label);
        if (this.selectedLabelIds.has(label.qy_tag_id)) {
          this.selectedLabelIds.delete(label.qy_tag_id);
        } else {
          this.selectedLabelIds.set(label.qy_tag_id, label.tag_name);
        }
        this.triggerUpdate();
      }
    },
    deb(fn, time) {
      // 防抖函数 用于搜索人员接口
      const clearTimer = function () {
        fn.call();
        this.searchTimer = null;
      };

      return () => {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
          this.searchTimer = null;
        }
        this.searchTimer = setTimeout(clearTimer, time);
      };
    },
    searchName() {
      // 搜索人员、部门、标签
      const tempFun = this.deb(() => {
        if (this.filterText.length === 0 || this.filterText.match('^[ ]+$')) {
          // 避免发送为空的搜索请求
          return;
        }
        GetWeComUserByName({
          name: this.filterText,
          agent_id: this.agentId,
        })
          .then((res) => {
            console.log(res);
            this.searchUserList = res.user_detail_list || [];
            this.searchDepartmentList = res.department_detail_list || [];
            this.searchLabelList = res.tag_detail_list || [];
            this.fewUser = true;
            this.fewDepartment = true;
            this.fewLabel = true;
            this.showSearchResult = true;
          })
          .catch((e) => {
            this.$message.error(e);
          });
      }, 300);
      tempFun();
    },
    addUserFromSearchResult(user) {
      // 从搜索结果添加人员
      if (this.selectedUserIds.has(user.userid)) {
        this.$message.warning(`已经添加了人员：${user.name}`);
      } else {
        this.selectedUserIds.set(user.userid, user.name);
        this.triggerUpdate();
      }
    },
    addDepartmentFromSearchResult(department) {
      // 从搜索结果添加部门
      if (this.selectedDepartmentIds.has(department.id)) {
        this.$message.warning(`已经添加了部门：${department.name}`);
      } else {
        this.selectedDepartmentIds.set(department.id, department.name);
        this.triggerUpdate();
      }
    },
    triggerUpdate() {
      // 触发vue更新，因为map类型限制
      this.trigger = !this.trigger;
      this.$forceUpdate();
    },
    reloadTree() {
      // 清空原有的树并重新加载
      console.log('清空树');
      const temp = setInterval(() => {
        if (!this.onLoadNode) {
          this.nodeHead.childNodes = [];
          this.departmentInvalid = 1;
          this.loadNode(this.nodeHead, this.resolveHead); // 再次执行懒加载的方法
          // this.clearSelection(false); // 清空已有的选择
          // this.clearConfirmTags();
          clearInterval(temp);
        }
      }, 100);
    },
    clickChooseTags() {
      this.visible = !this.visible;
    },
    getDepartments() {
      // 请求部门树形结构
      return new Promise((resolve) => {
        GetWeComDepartmentById({
          agent_id: this.departmentInvalid === 0 ? defaultAgentId : this.agentId,
          department_id: this.departmentId,
          judge_status: 1,
        })
          .then((res) => {
            console.log(res);
            resolve(res);
          })
          .catch((e) => {
            this.$message.error(e);
            resolve();
          });
      });
    },
    clearSelection(showMessage = false) {
      // 清除所有选择，showMessage为是否展示message提示
      this.selectedDepartmentIds.clear();
      this.selectedUserIds.clear();
      if (!this.noLabel) {
        this.selectedLabelIds.clear();
      }
      this.triggerUpdate();
      if (showMessage) {
        this.$message.success('清除成功！');
      }
    },
    // 内层删除tag
    closeUserTag(key) {
      this.selectedUserIds.delete(key);
      this.triggerUpdate();
    },
    closeDepartmentTag(key) {
      this.selectedDepartmentIds.delete(key);
      this.triggerUpdate();
    },
    closeLabelTag(key) {
      this.selectedLabelIds.delete(key);
      this.triggerUpdate();
    },
    // 外层删除tag
    closeConfirmUserTag(key) {
      this.selectedUserIds.delete(key);
      this.confirmUserIds.delete(key);
      this.triggerUpdate();
    },
    closeConfirmDepartmentTag(key) {
      this.selectedDepartmentIds.delete(key);
      this.confirmDepartmentIds.delete(key);
      this.triggerUpdate();
    },
    closeConfirmLabelTag(key) {
      this.selectedLabelIds.delete(key);
      this.confirmLabelIds.delete(key);
      this.triggerUpdate();
    },
    openSearchResult() {
      // 点击输入框时显示上一次的搜索结果
      if (this.filterText.length) {
        this.showSearchResult = true;
      }
    },
    // 全选/取消全选
    clickChooseAll() {
      this.isChooseAll = !this.isChooseAll;
      this.showSearchResult = false;
    },
    // 确定修改
    confirmTags() {
      this.confirmChooseAll = this.isChooseAll;
      this.confirmDepartmentIds = new Map(this.selectedDepartmentIds);
      this.confirmUserIds = new Map(this.selectedUserIds);
      this.confirmLabelIds = new Map(this.selectedLabelIds);
      this.triggerUpdate();
      this.closeTags();
    },
    // 取消修改
    closeTags() {
      this.$refs.popover.showPopper = false;
    },
    // 提交结果
    submitForm() {
      return {
        departIds: Array.from(this.confirmDepartmentIds.keys()),
        userIds: Array.from(this.confirmUserIds.keys()),
        labelIds: Array.from(this.confirmLabelIds.keys()), // 后端标签id
        // labelWorkWxIds: Array.from(this.confirmLabelIds.values()).map(
        //   (item) => JSON.parse(item).qy_tag_id,
        // ), // 企微标签id
        isChooseAll: this.confirmChooseAll,
      };
    },
  },
};
