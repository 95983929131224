import WujieVue from 'wujie-vue2';
import { GetAppConfig } from './api';

/* 预加载 wujie 组件 */
const { VUE_APP_WITH_CUSTOM: appWithCustom } = process.env;
const { preloadApp } = WujieVue;

export async function getPageConfig(key, type) {
  const appConfig = await GetAppConfig();
  const valueStr = appConfig?.page_config?.[key];
  if (type !== 'array' && type !== 'object') {
    return valueStr;
  }
  try {
    return JSON.parse(valueStr);
  } catch (e) {
    return type === 'array' ? [] : {};
  }
}

export function preloadWujieCustom(tenant) {
  if (!tenant) {
    return;
  }

  // 默认页面不能被 403, /custom/ 会被 403
  // 预加载要加载对应租户下的 index.html
  const wujiePreload = `${appWithCustom ? 'http://localhost:9090' : ''}/custom/${tenant}/`;
  console.log('wujie custom preloading', wujiePreload);

  preloadApp({
    name: 'wujie',
    url: wujiePreload,
    exec: true,
    alive: true,
    // 进入页面
    activated(appWindow) {
      console.log(`${appWindow.__WUJIE.id} custom preload activated`);
    },
    // 离开页面
    deactivated(appWindow) {
      console.log(`${appWindow.__WUJIE.id} custom preload deactivated`);
    },
    // fetch: function(url, options) {
    //   return window.fetch(url, { ...options, credentials: 'include' });
    // },
    plugins: [
      {
        htmlLoader: (code) => {
          console.log('wujie custom preload html-loader');
          return code;
        },
        // jsLoader: (code, url) => {
        //   console.log("wujie preload js-loader", url);
        //   return code;
        // },
        // cssLoader: (code, url) => {
        //   console.log("wujie css-loader", url, code.slice(0, 50));
        //   return code;
        // },
      },
    ],
  });
}
