import {
  AppidList,
  ArticleDetail,
  ArticleDetailList,
  ArticleDetailByCustomFields,
  ArticleDelete,
  UpdateArticle,
  ArticleStrategyList,
  SendUserList,
  ArticleRecordData,
  ArticleRecordOfficialAccountData,
  PushLineList,
  CancelMission,
  ArticleRecordMission,
  ArticleRecordVersion,
  UpdArticlesStatus,
  UpdateArticleValid,
  ArticleValidScope,
  AddArticleValidScope,
  UpdateArticleValidScope,
  SubmitVote,
  UpdateHistory,
  DeleteArticleValidScope,
  AddPushTaskContentGql,
  GetPushTaskContentGql,
  GetCurrentPushTaskContentGql,
  DeleteCurrentPushTaskContentGql,
  SavePushTaskGql,
  EditPushTaskContentGql,
  GetPushTaskAnalyzeResultsGql,
  EditPushTaskAnalyzeResultsGql,
  PushTaskListGql,
  GetArticleNameGql,
  ArticleFavoriteCount,
  CourseListDetail,
  InsertArticlePreview,
  InsertDocumentLog,
  InsertArticlePreviewWithGreenBook,
} from '@/graphql/gql/article';
import { DataService } from 'nges-common/src/web/utils/api';
import { calVariables, calVariablesLoop } from '@/graphql/util';

export function queryUpdArticlesStatus(data) {
  return DataService({
    query: calVariables(UpdArticlesStatus, data),
  });
}
export function queryAppidList(data) {
  return DataService({
    query: calVariables(AppidList, data),
  });
}
export function queryArticleValidScope(data) {
  return DataService({
    query: calVariables(ArticleValidScope, data),
  });
}
export function insertArticleValidScope(data) {
  return DataService({
    query: calVariables(AddArticleValidScope, data),
  });
}
export function updateArticleValidScope(data) {
  return DataService({
    query: calVariables(UpdateArticleValidScope, data),
  });
}
export function deleteArticleValidScope(data) {
  return DataService({
    query: calVariables(DeleteArticleValidScope, data),
  });
}
export function queryArticleDetail(data, otherKeys = '') {
  return DataService({
    query: calVariables(ArticleDetail.replace('$otherKeys', otherKeys), data),
  });
}

export function queryArticleList(data, otherKeys = '') {
  return DataService({
    query: calVariables(ArticleDetailList.replace('$otherKeys', otherKeys), data),
  });
}

export function queryArticleDetailByCustomFields(data, otherKeys = '') {
  return DataService({
    query: calVariables(ArticleDetailByCustomFields.replace('$otherKeys', otherKeys), data),
  });
}

export function queryArticleDelete(data) {
  return DataService({
    query: calVariables(ArticleDelete, data),
  });
}

export function queryUpdateArticle(data) {
  let otherKeys = '';
  if (data?.questionnaire_ids !== undefined) {
    // 不是所有租户存在这个字段, 需要定制开发 (目前只有T1 AZ需要记录外部问卷)
    otherKeys = ', questionnaire_ids: $questionnaire_ids';
  }
  if (data?.related_material_ids__c !== undefined) {
    otherKeys += ', related_material_ids__c: $related_material_ids__c';
  }
  const updateArticleGql = UpdateArticle.replace('$otherKeys', otherKeys);
  return DataService({
    query: calVariables(updateArticleGql, data),
  });
}
export function queryArticleStrategyList(data) {
  return DataService({
    query: calVariables(ArticleStrategyList, data),
  });
}
export function querySendUserList(data) {
  return DataService({
    query: calVariables(SendUserList, data),
  });
}

export function queryArticleRecordData(data) {
  if (!data.types?.length) {
    // 兼容老数据
    data.types = [0];
  }
  if (!data.sumFields) {
    data.sumFields = ['forward', 'favorite', 'read_number'];
  }
  const gql = ArticleRecordData.replace('$sum_fields', data.sumFields.join(','));
  delete data.sumFields;
  return DataService({
    query: calVariables(gql, data),
  });
}
export function queryArticleRecordOfficialAccountData(data) {
  return DataService({
    query: calVariables(ArticleRecordOfficialAccountData, data),
  });
}
export function queryPushLineList(data) {
  return DataService({
    query: calVariables(PushLineList, data),
  });
}
export function queryCancelMission(data) {
  return DataService({
    query: calVariables(CancelMission, data),
  });
}
export function queryArticleRecordMission(data) {
  return DataService({
    query: calVariables(ArticleRecordMission, data),
  });
}
export function queryArticleRecordVersion(data) {
  return DataService({
    query: calVariables(ArticleRecordVersion, data),
  });
}
export function queryUpdateArticleValid(data) {
  return DataService({
    query: calVariables(UpdateArticleValid, data),
  });
}
export function queryAddPushTaskContent(data) {
  return DataService({
    query: calVariables(AddPushTaskContentGql, data),
  });
}
export function insertSubmitVote(data) {
  return DataService({
    query: calVariablesLoop(SubmitVote, data),
  });
}
export function queryGetPushTaskContent(data) {
  return DataService({
    query: calVariables(GetPushTaskContentGql, data),
  });
}
export function queryGetCurrentPushTaskContent(data) {
  return DataService({
    query: calVariables(GetCurrentPushTaskContentGql, data),
  });
}
export function queryDeleteCurrentPushTaskContent(data) {
  return DataService({
    query: calVariables(DeleteCurrentPushTaskContentGql, data),
  });
}
export function querySavePushTask(data) {
  return DataService({
    query: calVariables(SavePushTaskGql, data),
  });
}
export function queryEditPushTaskContent(data) {
  return DataService({
    query: calVariables(EditPushTaskContentGql, data),
  });
}
export function queryGetPushTaskAnalyzeResults(data) {
  return DataService({
    query: calVariables(GetPushTaskAnalyzeResultsGql, data),
  });
}
export function queryEditPushTaskAnalyzeResults(data) {
  return DataService({
    query: calVariables(EditPushTaskAnalyzeResultsGql, data),
  });
}
export function queryPushTaskList(data) {
  return DataService({
    query: calVariables(PushTaskListGql, data),
  });
}
export function queryGetArticleName(data) {
  return DataService({
    query: calVariables(GetArticleNameGql, data),
  });
}
export function getArticleFavoriteCount(data) {
  return DataService({
    query: calVariables(ArticleFavoriteCount, data),
  });
}

// 课单详情
export function queryCourseListDetail(data) {
  console.log(data);
  return DataService({
    query: calVariables(CourseListDetail, data),
  });
}

export function insertArticlePreview(data) {
  return DataService({
    query: calVariables(InsertArticlePreview, data),
  });
}
export function insertArticlePreviewWithGreenBook(data) {
  return DataService({
    query: calVariables(InsertArticlePreviewWithGreenBook, data),
  });
}

export function insertDocLog(data) {
  return DataService({
    query: calVariables(InsertDocumentLog, data),
  });
}

export function updateHistoryVote(data) {
  return DataService({
    query: calVariables(UpdateHistory, data),
  });
}
