//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//  调用:  <InputComp inputInit="初始文字" placeholder="占位符" @inputEmit="(e)=>{console.log(e + ': 回调操作,e是input绑定的值')}"></InputComp>
//  参数:  详见props
export default {
  name: 'InputShowWordLimit',
  props: {
    // input类型
    type: {
      type: String,
      default: 'text',
    },
    // 占位符
    placeholder: {
      type: String,
      default: '',
    },
    // 最大输入数量
    maxlength: {
      type: Number,
      default: 35,
    },
    // 最外层框样式
    outStyle: {
      type: Object,
      default: () => ({ width: '664px' }),
    },
    // 输入框初始值
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {
    inputChange(val) {
      const temp = val.target.value.trim();
      this.$emit('inputEmit', temp);
    },
    blur(e) {
      this.$emit('blurEmit', e);
    },
  },
};
