export const directives = {
  loadmore: (el, binding) => {
    const { value } = binding;
    const { loadFunc, selector } = value;
    // 获取element-ui定义好的scroll盒子

    const SELECTWRAP_DOM = el.parentElement.querySelector(selector);
    if (SELECTWRAP_DOM) {
      SELECTWRAP_DOM.addEventListener('scroll', function () {
        /**
         * scrollHeight 获取元素内容高度(只读)
         * scrollTop 获取或者设置元素的偏移值,
         *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
         * clientHeight 读取元素的可见高度(只读)
         * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
         * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
         * 5为缓冲像素，保证能加载
         */
        const condition = this.scrollHeight - this.scrollTop - 5 <= this.clientHeight;
        if (condition) {
          loadFunc && loadFunc();
        }
      });
    }
  },
};
