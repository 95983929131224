//
//
//
//
//

import { dateFormat } from '@/utils/time.js';
import { jsonToXlsx } from '@/utils/xlsx.js';
import * as config from './config.js';
export default {
  name: 'ExportExcel',
  props: {
    // 配置名
    configName: {
      type: String,
      default: '',
    },
    // 数据
    excelData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 按钮名
    btnName: {
      type: String,
      default: '导出数据',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      item: {},
    };
  },
  watch: {
    excelData: {
      handler() {
        this.transitionHeader();
      },
      deep: true,
    },
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {
    this.item = config[this.configName] || {};
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    transitionHeader() {
      const { name = '', mapping = [] } = this.item;

      const resTemp = this.excelData.reduce((arr, item, index) => {
        const oobj = {};
        mapping.forEach((map) => {
          oobj[map[1]] = item[map[0]];
        });
        arr[index] = oobj;
        return arr;
      }, []);
      if (resTemp.length === 0) {
        const titles = mapping.map((item) => item[1]);
        const temp = {};
        for (const title of titles) {
          temp[title] = '';
        }
        resTemp.push(temp);
      }
      // // 创建映射
      // const map = new Map(mapping);

      // const resTemp = [];
      // this.excelData.forEach((e, i) => {
      //   // 新建对象
      //   resTemp[i] = {};
      //   for (const [key, val] of Object.entries(e)) {
      //     // 映射中文键名
      //     resTemp[i][`${map.get(key)}`] = val;
      //   }
      // });

      const data = [
        {
          sheet: resTemp,
          name,
        },
      ];
      jsonToXlsx(data, `${name} ${this.curTime()}`);
    },
    curTime() {
      return dateFormat(new Date(), 'YYYY-MM-dd HH:mm:SS');
    },
    clickEmit() {
      this.$emit('emit');
    },
  },
};
