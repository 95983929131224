import { getCurUrl, arrBlack, throttle } from './util';
import { gotoUrl } from './common';
import { UPDATE_STATUS } from './constant';

let updateStatus = '';

const gotoLoginWithSystemUpdate = throttle(
  (message, url) => {
    const wxaurl = `/pages/main/entry/index?redirect_uri=${encodeURIComponent(
      url,
    )}&isUpdate=${message}`;
    return location.replace(`/mini${wxaurl}`);
    
    let options = {};
    options = {
      wxaurl,
      force: true,
    };
    gotoUrl(options);
  },
  1000,
  { trailing: false },
);
function toLoginWithSystemUpdate(message) {
  const currentUrl = getCurUrl();
  if (!currentUrl) {
    return Promise.reject(new Error('onlaunch no cururl'));
  }
  if (
    arrBlack(currentUrl, [
      'pages/main/entry/index',
      'pages/mainest/trans/index',
      'pages/main/trans/index',
    ])
  ) {
    return Promise.reject(new Error('当前处于特殊页'));
  }
  gotoLoginWithSystemUpdate(message, currentUrl);
}

export function getUpdateData() {
  return updateStatus;
}
function setUpdateData(data) {
  updateStatus = data;
}

export function checkUpdate({ bizcode, message }) {
  if (bizcode === 999999) {
    setUpdateData(message);
    toLoginWithSystemUpdate(message);
    return true;
  }
  setUpdateData('');
  return false;
}

export { UPDATE_STATUS };
