import request, { storeRequest } from '@/libs/request';
export const GetObjectsByNames = (data) =>
  request('DataServer/MetadataService/GetObjectsByNames', data);
export const GetUserMenuTree = () =>
  storeRequest(
    {
      cmd: 'AuthServer/AuthPermission/GetUserMenuTree',
      payload: { app_key: '92b945ac-1b94-46a8-907a-c8d5c9f1464d' },
    },
    { key: 'GetUserMenuTree', cache: true },
  );
export const Login = (data) => request('AccountServer/AccountLogin/Login', data);
