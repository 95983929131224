//
//
//
//
//
//
//

export default {
  name: 'Popover',
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    width() {
      return this.config.width || 172;
    },
    placement() {
      return this.config.placement || 'top';
    },
  },
  methods: {},
};
