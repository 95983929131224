//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TableComp',
  props: {
    // 表格配置对象
    tableObj: {
      type: Object,
      default: () => ({
        tableHeader: {}, // 表格头
        tableData: [], // 内容数据
        tableTotal: 0, // 总数
        tablePageSize: 10, // 每页条数
        tablePageIndex: 1, // 当前页
      }),
    },
    // 表格加载
    tableLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    // 获取点击事件的文字传递给父组件
    event(row, $event) {
      this.$emit('operationEmit', {
        row,
        innerHTML: $event.target.innerHTML,
      });
    },
    // 切换页触发事件
    currentChangeFun(e) {
      this.$emit('paginationEmit', e);
    },
  },
};
