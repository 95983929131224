// export const QueryGetFlightRoster = function ({ name }) {
//   return `query {
//   user_info(
//     _where: {_and:[{identity_tag: {_include_any: ["inspection_executor"]}},{ name: {_like:"%${name}%"}}]}
//     _limit: 10
//   ) {
//     id
//     name
//   }
// }`};

export const QueryGetFlightRoster = function () {
  // 产品沟通后： 飞检执行人总数预估较少，不用懒查询，直接查询所有飞检执行人
  return `query {
  user_info(
    _where: {_and:[{identity_tag: {_include_any: ["inspection_executor","inspection_admin"]}}]}
  ) {
    uin
    name
  }
}`;
};

export const QueryValidateInspector = `query {
  events_unannounced_inspection(inspection_executor_uin: $user_uin, status:{_in:[0,1,2]},_or:[{meeting.meeting_stime:{_lt: $meeting_stime}},{meeting.meeting_etime:{_gt:$meeting_etime}}]){
  meeting{
    id meeting_stime meeting_etime
  }
}}`;

export const QueryDistributeInspection = `mutation insert{
	events_unannounced_inspection(_values:{inspection_allocator_uin:$_uin,meeting_id:$meeting_id, inspection_executor_uin:$executor_uin,is_create_room:0,is_record:0,status:0, create_time: $create_time},_on_conflict:{_columns:[meeting_id],_update_all:true})
}`;

export const QueryCancelInspection = `mutation update{
	events_unannounced_inspection(_where:{id:$id},_set:{status:"3"})
}`;
export const QueryGetUserInfo = `query{
  user_info(_where: {uin: $_uin}) {
    identity_tag
  }
}`;
