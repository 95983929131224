// 存储页面间通信的数据

export const layoutPageData = {};
// 用于生成页面的唯一id
export function getRandomStr() {
  return `${Math.random().toString(16).slice(2)}${+new Date()}`;
}
// 设置页面的对象参数，用页面唯一id获取
export function setPageData({ pageUniqId, data }) {
  const newPageUniqId = pageUniqId || getRandomStr();
  layoutPageData[newPageUniqId] = data;
  return newPageUniqId;
}
// 获取页面对象参数，用页面唯一id获取
export function getPageData({ pageUniqId }) {
  return layoutPageData[pageUniqId];
}
