// 其他接口
const GetProductInfoList = `query { product_tag(_order_by: {status: _asc}) { _aggregate { _count } , name , status,id } } `;
const GetProductListQuery = `query { product{ name ,id, status } } `;
const GetAllProductQuery = `query { product{ name ,id } product_tag{name, id} }`;
const QueryHospitalDepartment = `query {department( _distinct : true) { name, id}}`;
const QueryTitleList = `query {major_title ( _distinct : true) { name,id}} `;
const GetLocationList = `query {province ( _distinct : true) { name,code,city{id,code,name}}}`;
const GetCityList = `query {city ( province_code : $id) { name,id}}  `;
const FindCityList = `query {city ( _where: {name: {_like: $keyword}}) { name,id,province{code, name}}}`;
const FindProvinceList = `query {province ( _where: {name: {_like: $keyword}}) { name,code,city{id, name}}}`;
const GetLocationName = `query {city ( _where:{id: {_in: $cityIds  }}) { name,id,province{code, name}}}`;
const GetDomainInfoList = `query{domain_tag(status:0){_aggregate{ _count},name,id, status}}`;
const GetHospitalList = `query {hco(_limit:$page_size,_offset:$page_index,_where:{name:{_like:$hospital_name}}){hospital_name:name,hospital_id:id}}`;
const QueryStuff = `query {user_info ($filter, $pageOption,_where:{uin:{_is_null:false,_neq:""}},_order_by:{create_time:_desc}){id _aggregate{_count} uin name account phone user_code open_id }}`;
const QueryDoctor = `query {hcp ($filter, $pageOption,_where:{uin:{_is_null:false,_neq:""}, multi_site_order: 0},_order_by:{create_time:_desc}){id, _aggregate{_count}, uin, doctor_code, name, hco{name}, department{name}, major_title{name}, unionid, phone,login_phone, register_status, certification_status, is_multi_site, multi_site_order,}}`;
const QueryGetInterestList = `query { mcm_interest { name, id }}  `;
const QuerycheckSpeakerId = `query{speaker_info(_where:{hcp_id:$hcp_id}){id}}`;
const QueryCheckSpeakerBanById = `query{speaker_ban(_where:{speaker_id__c:$id}){_all}}`;
const QueryMeetingsBySpeakerId = `query{
  events_issue_speaker__c(
    _where: {_and: [{speaker_id__c: {_eq: $id}}]}
  ) {
    meeting(meeting_status:"2",meeting_stime:{_gte:$time}) {
      meeting_name
      meeting_status
      meeting_stime
      owner {
        name
        phone
      }
    }
  }
}`;

const AddSpeakerBan = `mutation insert {
  speaker_ban(_values: {speaker_id__c:$speaker_id__c, ban_reason__c:$ban_reason__c, ban_start_date__c:$ban_start_date__c, ban_end_date__c:$ban_end_date__c,ban_appendix__c:$ban_appendix__c})
}`;

const EditSpeakerBan = `mutation update {speaker_ban(_where: {id: $id}, _set: {speaker_id__c:$speaker_id__c, ban_reason__c:$ban_reason__c, ban_start_date__c:$ban_start_date__c, ban_end_date__c:$ban_end_date__c,ban_appendix__c:$ban_appendix__c})}`;

const QueryMutationNonFee = `mutation update {    events_non_meeting_fee(_where: {id: $id}, _set: {status: $status})}`;
const QueryMutationLaborPaymentOrder = `mutation update {    events_speaker_pay(_where: {id: $id}, _set: {status: $status}){
  issue_speaker(_values:{is_not_pay__c:0,cost_status__c:0})
}}`;

// 医院id数组映射名字
const GetHospitalName = `query {hco(id:{_in:$ids}){hospital_name:name,hospital_id:id}}`;
export {
  GetProductInfoList,
  GetProductListQuery,
  GetAllProductQuery,
  QueryHospitalDepartment,
  QueryTitleList,
  GetLocationList,
  GetCityList,
  FindCityList,
  FindProvinceList,
  GetLocationName,
  GetDomainInfoList,
  GetHospitalList,
  GetHospitalName,
  QueryDoctor,
  QueryStuff,
  QueryGetInterestList,
  QuerycheckSpeakerId,
  QueryCheckSpeakerBanById,
  QueryMeetingsBySpeakerId,
  QueryMutationNonFee,
  QueryMutationLaborPaymentOrder,
  AddSpeakerBan,
  EditSpeakerBan,
};
