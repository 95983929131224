export function isIpadDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMac = userAgent.indexOf('macintosh') > -1;
  const isIOS = /ip(ad|od)/.test(userAgent);

  if (isMac && 'ontouchend' in document) {
    // 对于 iPadOS 13 及更高版本，User-Agent 字符串包含 "Macintosh"，并且设备支持触摸事件
    return true;
  }
  if (isIOS) {
    // 对于 iPadOS 12 及更低版本，User-Agent 字符串包含 "iPad" 或 "iPod"
    return true;
  }
  return false;
}
