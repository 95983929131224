function setDocTitle(webBrowserConfig) {
  const documentTitle = webBrowserConfig?.document_title || '医药营销云';
  document.title = documentTitle;
}

function setFavicon(webBrowserConfig) {
  const favicon = webBrowserConfig?.favicon || '/favicon.ico';
  let link = document.querySelector('link[rel*="icon"]');
  if (link) {
    link.href = favicon;
  } else {
    link = document.createElement('link');
    link.rel = 'icon';
    link.href = favicon;
    document.head.appendChild(link);
  }
}

export function setBrowserConfig(appConfig) {
  let webBrowserConfig = {};
  try {
    webBrowserConfig = JSON.parse(appConfig?.page_config?.web_browser_config);
  } catch (e) {}
  setDocTitle(webBrowserConfig);
  setFavicon(webBrowserConfig);
}
