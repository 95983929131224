//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AddNoPublic',
  components: {},
  props: {
    isedit: {
      type: Boolean,
      default: false,
    },
    // 盒子状态
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    agentValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: [],
    };
  },
  computed: {
    agentList() {
      return this.$store.state.user.managerLogin.agent_list || [];
    },
  },
  watch: {
    agentValue(newval) {
      this.value = JSON.parse(JSON.stringify(newval));
    },
    // 关闭盒子清空数据
    async dialogVisible(newVal) {
      if (newVal) {
        this.value = this.agentValue;
      } else {
        this.value = [];
      }
    },
  },
  mounted() {
    this.value = JSON.parse(JSON.stringify(this.agentValue));
  },
  methods: {
    handleBtn() {
      const { value } = this;
      if (value.length === 0) {
        this.$message('请选择企业应用');
        return;
      }
      this.$emit('emitUserAgent', value);
      this.handleClose();
    },
    // 关闭盒子 清空数据
    handleClose() {
      this.$emit('update:dialogVisible', false);
    },
  },
};
