import request from '@/libs/request';

// 题库列表 ------------------
export const GetQuestionBankList = (data) => request('GetQuestionBankList', data);

// 创建题库
export const CreateBank = (data) => request('CreateBank', data);

// 编辑题库

export const UpdateBank = (data) => request('UpdateBank', data);

// 删除题库
export const RemoveQuestionBank = (data) => request('RemoveQuestionBank', data);

// 题目列表 ------------------
export const GetQuestionList = (data) => request('GetQuestionList', data);

// 查看题目
export const GetQuestionDetailById = (data) => request('GetQuestionDetailById', data);

// 创建题目
export const CreateQuestion = (data) => request('CreateQuestion', data);

// 更新题目
export const UpdateQuestion = (data) => request('UpdateQuestion', data);

// 删除题目
export const DeleteQuestion = (data) => request('DeleteQuestion', data);
