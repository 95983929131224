import { getLoginConfig } from '@/api/login';
import config from '@/config';

export default {
  namespaced: true,
  getters: {
    getConfig(state) {
      return { ...config, ...(state.globalConfig || {}) };
    },
  },
  state: {
    // 自定义域名下使用的前端配置
    globalConfig: {},
  },
  mutations: {
    setGlobalConfig(state, config) {
      state.globalConfig = config;
    },
  },
  actions: {
    async requestGlobalConfig(context) {
      return new Promise((resolve, reject) => {
        getLoginConfig({ domain: location.host }).then(
          (res) => {
            const newConfig = {
              ...res,
              logo: res.web_logo || '',
              copyright: res.copy_right || '',
              tabList: res.login_mode || [],
            };

            context.commit('setGlobalConfig', newConfig);
            resolve();
          },
          (e) => reject(e),
        );
      });
    },
  },
};
