import { isArray, includes, uniq, isObject } from 'lodash';
let GetAuthorizedDataRows;

GetAuthorizedDataRows = require('../web/utils/api').GetAuthorizedDataRows;


export const WRITE_PERMISSION_CONFIG_TYPE = 'data_row_write';

export const WRITE_PERMISSION_KEY = '__write_permission';

function layoutNeedWritePermission(json) {
  const permission = json?.permission;
  if (!permission || !isArray(permission)) {
    return false;
  }
  return permission.findIndex((item) => item?.type === WRITE_PERMISSION_CONFIG_TYPE) > -1;
}

export async function checkDataRowWritePermission(json, list, id) {
  if (!layoutNeedWritePermission(json)) {
    return list;
  }
  const object = json?.object;
  if (!object || !isObject(list)) {
    return list;
  }
  const isArr = isArray(list);
  if (!isArr) {
    list = [list];
  }
  let ids = [];
  if (id) {
    ids = [id];
  } else {
    list.forEach((item) => {
      if (item?.id) {
        ids.push(item.id);
      }
    });
  }
  ids = uniq(ids);
  if (!ids.length) {
    return list;
  }
  try {
    const res = await GetAuthorizedDataRows({
      permission_type: 'WRITE',
      items: { [object]: { ids } },
    });
    const permissions = res?.items || [];
    list.forEach((item) => {
      item[WRITE_PERMISSION_KEY] = includes(permissions?.[object]?.ids, id || item.id);
    });
  } catch (e) {
    console.error(e);
  }
  return isArr ? list : list[0];
}
