import {
  query as utilQuery,
  getCurUrl as utilGetCurUrl,
  ministore,
  lstore as weblstore,
  Store,
  breadthFirstTraverse,
  depthFirstTraverse,
  isPlatform,
} from './web-mobile/util';
import config from './config';

import { HTTP_REG } from './constant';

import scriptjs from 'scriptjs';


// eslint-disable-next-line
let lstore = {};
lstore = weblstore;




// 登录，避开guru的存储隔离
const nativeStore = new Store({
  setItem: (key, value) => {
    wx.setStorageSync(key, value, true);
  },
  getItem: (key) => wx.getStorageSync(key, true),
  removeItem: (key) => {
    wx.removeStorageSync(key, true);
  },
});

function getCurUrl() {
  
  // return utilGetCurUrl() || location.href.replace(location.origin, '');
  // 辉瑞 会直接修改href的值, 导致二者不一致
  let url = utilGetCurUrl();
  if (url && location.href && !/(\?|&){1}appid=/g.test(url)) {
    // 没有appid的url 直接取location.href
    url = '';
  }
  return url || location.href.replace(location.origin, '');
  
  return utilGetCurUrl();
}

function query(name, url) {
  let cururl = url || getCurUrl();
  if (!cururl) {
    cururl = window.location.href;
    console.log('window.location.href', window.location.href);
  }
  
  return utilQuery(name, cururl);
}

const getUA = function () {
  return window.navigator.userAgent.toLowerCase();
};

function arrBlack(url, arr) {
  if (arr.filter((item) => url.includes(item)).length) return true;
  return false;
}

function loginUrlBlack(url) {
  const BLACK_LIST = [
    'pages/main/entry/index',
    'pages/main/webview/commonWebview',
    'pages/login',
    ...config.whiteListPages,
  ];
  return arrBlack(url, BLACK_LIST);
}

function doLoginUrlBlack(url) {
  return (
    loginUrlBlack(url) || arrBlack(url, ['pages/mainest/trans/index', 'pages/main/trans/index'])
  );
}

let accountInfo = null;
const getAccountInfoSync = function () {
  try {
    if (!accountInfo) {
      accountInfo = wx.getAccountInfoSync();
    }
  } catch (e) {}
  return accountInfo;
};
let systemInfo = null;
let wxSystemInfo = null; // 企业微信，需要从这里判断
const getSystemInfo = function () {
  try {
    if (!systemInfo) {
      const res = uni.getSystemInfoSync() || {};
      const menuButtonInfo = uni.getMenuButtonBoundingClientRect();
      const { SDKVersion, windowWidth, windowHeight, pixelRatio, statusBarHeight, system, host }
        = res;
      const screenWidth = windowWidth || res.screenWidth;
      const screenHeight = windowHeight || res.screenHeight;
      const { model } = res;
      const isIOS = system.toLowerCase().indexOf('ios') !== -1;
      const isAndroid = system.toLowerCase().indexOf('android') !== -1;
      
      systemInfo = {
        SDKVersion,
        windowWidth,
        windowHeight,
        pixelRatio,
        statusBarHeight,
        system,
        screenWidth,
        screenHeight,
        model,
        isIOS,
        isAndroid,
        
        host,
      };
    }
    return systemInfo;
  } catch (e) {
    console.log(e);
  }
  return {};
};

const getWxSystemInfo = function () {
  try {
    if (!wxSystemInfo) {
      wxSystemInfo = wx.getSystemInfoSync();
    }
  } catch (e) {}
  return wxSystemInfo;
};
const isWxwork = function () {
  const ua = getUA();
  return /micromessenger/i.test(ua) && /wxwork/i.test(ua);
  
  const wxSystemInfo = getWxSystemInfo();
  return !!(wxSystemInfo?.environment === 'wxwork');
};

function getOriginUrl(url) {
  const locationOrigin = window.location.origin;
  if (url && !HTTP_REG.test(url)) {
    url = `${locationOrigin}${/^\//.test(url) ? '' : '/'}${url}`;
  }
  
  return url;
}

const promisify = (...params) => {
  const config = {
    promisify(args, resolve, reject) {
      args[0] = {
        ...args[0],
        success: resolve,
        fail: reject,
      };
    },
  };

  if (typeof params[0] === 'string') {
    // api, ...args
    const [api, ...args] = params;
    config.ctx = uni;
    config.api = api;
    config.args = args;
  } else if (typeof params[0] === 'object') {
    // ctx, api, ...args
    const [ctx, api, ...args] = params;
    config.ctx = ctx;
    config.api = api;
    config.args = args;
  } else if (typeof params[0] === 'function') {
    // promisify, ctx, api, ...args
    const [promisify, ctx, api, ...args] = params;
    config.ctx = ctx;
    config.api = api;
    config.args = args;
    config.promisify = promisify;
  }

  return new Promise((resolve, reject) => {
    const { ctx, api, args } = config;
    const ctxApi = ctx[api].bind(ctx);

    config.promisify(
      args,
      function (res) {
        resolve(res);
      },
      function (err) {
        reject(err);
      },
    );
    ctxApi(...args);
  });
};

const b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

// atob
const weAtob = function (string) {
  string = String(string).replace(/[\t\n\f\r ]+/g, '');
  string += '=='.slice(2 - (string.length & 3));
  let bitmap;
  let result = '';
  let r1;
  let r2;
  let i = 0;
  for (; i < string.length; ) {
    // eslint-disable-next-line
    bitmap = b64.indexOf(string.charAt(i++)) << 18 | b64.indexOf(string.charAt(i++)) << 12 | (r1 = b64.indexOf(string.charAt(i++))) << 6 | (r2 = b64.indexOf(string.charAt(i++)));

    result
      += r1 === 64
        ? String.fromCharCode((bitmap >> 16) & 255)
        : r2 === 64
        ? String.fromCharCode((bitmap >> 16) & 255, (bitmap >> 8) & 255)
        : String.fromCharCode((bitmap >> 16) & 255, (bitmap >> 8) & 255, bitmap & 255);
  }
  return result;
};
function isPreview() {
  return window.parent !== window;
}

// 获取更详细的platform
function getPlatform() {
  
  return 3;
  
}

function getSafeBottom() {
  
  return 0;
}

function calculateSafeBottom(safeBottom) {
  let result = safeBottom;
  
  return result;
}

/**
 * 关于失效默认图的说明
 * 第一版，服务端在请求数据的时候做了替换，替换的地址是expiredDefaultImgOld，做替换的时候做检测。
 * 第二版，使用默认失效图片expiredDefaultImg，默认失效音频expiredAudioDefaultImg
 * 第三版，针对音频（扁平）和普通（偏正方）的默认图; expiredDefaultImgNew  expiredAudioDefaultImgNew
 */
export const expiredDefaultImg =
  'https://cdn.nges.tencent.com/nges_1/72703202/b1e4af9d-2edd-449c-aec8-4d90c4bf6355.png';
export const expiredDefaultImgOld =
  'https://cdn.nges.tencent.com/nges_1/31703202/3e348523-31fa-42b2-b399-ccbfa4af664a.png';
export const expiredAudioDefaultImg =
  'https://cdn.nges.tencent.com/nges_1/30803202/198414e6-7a52-4570-bda8-e8aa2d50b4ee.png';
export const expiredDefaultImgNew
  = 'https://cdn.nges.tencent.com/nges_1/22803202/8c94206d-a48f-4558-8acc-b0538b0bac30.png';
export const expiredAudioDefaultImgNew =
  'https://cdn.nges.tencent.com/nges_1/22803202/e91e53a3-1ee6-48f9-b60e-ceb97833f4be.png';

/**
 * 检测单个素材的有效期，
 * @param {url} 要检测的素材url
 * @returns 过期返回true
 */
export const checkItemIsExpired = (url, material) => {
  const _item = material?.find((item) => item.url === url);
  const status = Number(_item?.effective_status || 0);
  // 已失效2  未生效3
  return status === 2 || status === 3;
};
/**
 * 校验html中，素材有效期
 * @param {*} html，要处理的html
 * @returns 返回替换过的html
 */
export const checkExpired = (html, material) => {
  try {
    // 注意零宽断言，在微信低版本小程序中不支持，会导致白屏。
    // var videoPattern = /<video[^>]*>([\s\S]*?)<\/video>/g;
    // var audioPattern = /<audio[^>]*>([\s\S]*?)<\/audio>/g;

    // const videoSrcReg = /<video[^>]*\ssrc=["']?([^"'\s>]+)["']?[^>]*>/g;
    // const videoSrcReg = /src=["']([^"'\s>]+)["']/g;
    // const audioSrcReg = /<audio[^>]*\ssrc=["']?([^"'\s>]+)["']?[^>]*>/g;

    // video
    const videoSrcResult = [];
    const videoTagReg = /<video[^>]*>([\s\S]*?)<\/video>/g;
    const videoTagResult = html.match(videoTagReg) || [];

    // audio
    const audioSrcResult = [];
    const audioTagReg = /<audio[^>]*>([\s\S]*?)<\/audio>/g;
    const audioTagResult = html.match(audioTagReg) || [];

    // 媒体标签中的src属性
    const srcReg = /src=["']([^"'\s>]+)["']/g;

    // 要替换显示的图片
    // 要替换显示的图片
    const _str = `<div class="expired-wrap"><img class="expired" src="${expiredDefaultImgNew}" /></div>`;
    const _audioStr = `<div class="expired-audio-wrap"><img class="expired audio" src="${expiredAudioDefaultImgNew}" /></div>`;
    console.log(_audioStr);

    // 视频
    if (videoTagResult?.length) {
      videoTagResult.forEach((item) => {
        const _str = item.match(srcReg) || [];
        videoSrcResult.push(_str[0].replaceAll('src=', '').replaceAll('"', ''));
      });
    }
    videoTagResult?.forEach((item, index) => {
      // 如果已经替换了
      if (
        videoSrcResult[index] === expiredDefaultImg
        || videoSrcResult[index] === expiredDefaultImgOld
      ) {
        html = html.replace(item, _str);
      } else if (checkItemIsExpired(videoSrcResult[index], material)) {
        // 替换
        html = html.replace(item, _str);
      }
    });

    // 音频
    if (audioTagResult?.length) {
      audioTagResult.forEach((item) => {
        const _str = item.match(srcReg) || [];
        audioSrcResult.push(_str[0].replaceAll('src=', '').replaceAll('"', ''));
      });
    }
    audioTagResult?.forEach((item, index) => {
      // 如果已经替换了
      if (
        audioSrcResult[index] === expiredDefaultImg
        || audioSrcResult[index] === expiredDefaultImgOld
      ) {
        html = html.replace(item, _audioStr);
      } else if (checkItemIsExpired(audioSrcResult[index], material)) {
        // 替换
        html = html.replace(item, _audioStr);
      }
    });

    // // 音频/视频媒体标签集合
    // const mediaTagResult = [...videoTagResult, ...audioTagResult];

    // const mediaSrcResult = [];

    // if (mediaTagResult?.length) {
    //   mediaTagResult.forEach((item) => {
    //     const _str = item.match(srcReg) || [];
    //     console.log(_str);
    //     mediaSrcResult.push(_str[0].replaceAll('src=', '').replaceAll('"', ''));
    //   });
    // }
    // mediaTagResult?.forEach((item, index) => {
    //   // 如果已经替换了
    //   if (
    //     mediaSrcResult[index] === expiredDefaultImg ||
    //     mediaSrcResult[index] === expiredDefaultImgOld
    //   ) {
    //     html = html.replace(item, _str);
    //   } else if (checkItemIsExpired(mediaSrcResult[index], material)) {
    //     // 替换
    //     html = html.replace(item, _str);
    //   }
    // });

    // 文档 or 图片
    const imgTagReg = /<img.*?src="(.*?)".*?>.*?/g;

    const imgTagResult = html.match(imgTagReg) || [];
    const imgSrcResult = [];

    if (imgTagResult?.length) {
      imgTagResult.forEach((item) => {
        const _str = item.match(srcReg) || [];
        console.log(_str);
        imgSrcResult.push(_str[0].replaceAll('src=', '').replaceAll('"', ''));
      });
    }

    imgTagResult?.forEach((item, index) => {
      // 如果已经替换了
      if (
        imgSrcResult[index] === expiredDefaultImg ||
        imgSrcResult[index] === expiredDefaultImgOld
      ) {
        html = html.replace(item, _str);
      } else if (checkItemIsExpired(imgSrcResult[index], material)) {
        // 替换
        html = html.replace(item, _str);
      }
    });
    return html;
  } catch (e) {
    console.log(e);
    // 如果解析出错，直接返回原文档。保证渲染。
    return html;
  }
};

// 是否通过微信小程序打开H5
function isWxworkMiniProgramH5() {
  const ua = window.navigator.userAgent.toLowerCase();
  return /micromessenger/i.test(ua) && /wxwork/i.test(ua);
  
  
}

function getFullPagePath() {
  return location.pathname + location.search;
  
  
}

export function updateManagerWatcher() {
  
}

export async function getJWeixin() {
  const WXWORK_JS_SDK = [
    'https://res.wx.qq.com/open/js/jweixin-1.4.0.js',
    'https://open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js',
  ];
  const WX_JS_SDK = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js';
  return new Promise((resolve) => {
    const isWxwork = isPlatform('wxwork');
    scriptjs(isWxwork ? WXWORK_JS_SDK : WX_JS_SDK, () => {
      resolve();
    });
  });
  
}

// 通过小程序getSystemInfoSync结果判断是否通顶
function isThroughTop() {
  const sysInfo = uni.getSystemInfoSync();
  return sysInfo && sysInfo.windowHeight === sysInfo.screenHeight;
}

// 统一的popup最大最小高度限制，主要用于列表布局筛序器弹窗，编辑布局筛选器弹窗
// 入参：btnBarHeight - 按钮栏高度，默认为0  titleBarHeight - 标题栏高度，默认为96rpx
function getPopupHeightRange(
  btnBarHeight = 0, // 底部按钮高度，主要用于控制有按钮和无按钮，若有按钮，需传入按钮高度
  titleBarHeight = uni.upx2px(96), // 顶部标题高度，基本可以用默认值
  pageTitleBarHeight, // 页面自定义标题+右上角胶囊按钮高度，基本可以用默认值
) {
  const systemInfo = uni.getSystemInfoSync();
  const isiOS = systemInfo.system.indexOf('iOS') > -1; // 是否为iOS系统
  !pageTitleBarHeight && (pageTitleBarHeight = !isiOS ? 48 : 44); // 导航栏高度，iOS：48，Android：44
  let windowHeight = systemInfo?.windowHeight ? `${systemInfo.windowHeight}px` : '100vh';
  const safeBottom = getSafeBottom();
  if (isThroughTop()) {
    // 通顶情况下，可视区域为windowHeight减去顶部，底部安全区，自定义顶部+右上角胶囊按钮高度；
    windowHeight = `calc(${windowHeight} - ${
      systemInfo?.safeArea?.top || 0
    }px - ${pageTitleBarHeight}px - ${safeBottom}px)`;
  } else {
    // 不通顶的情况下，可视区域高度为windowHeight减去底部安全区
    windowHeight = `calc(${windowHeight} - ${safeBottom}px)`;
  }
  return {
    minHeight: `${uni.upx2px(640) - titleBarHeight - btnBarHeight}px`, // 默认最小高度，减去顶部title栏高度96rpx，按钮的高度;
    maxHeight: `calc(${windowHeight} - ${titleBarHeight}px - ${btnBarHeight}px - 96rpx)`, // 默认最大高度，减去顶部title栏高度96rpx，留空的96rpx，按钮的高度，
  };
}

export * from './web-mobile/util';
export * from './preview';
export {
  doLoginUrlBlack,
  loginUrlBlack,
  arrBlack,
  getAccountInfoSync,
  getSystemInfo,
  getWxSystemInfo,
  isWxwork,
  getOriginUrl,
  promisify,
  weAtob,
  getUA,
  getCurUrl,
  depthFirstTraverse,
  breadthFirstTraverse,
  getPlatform,
  getSafeBottom,
  getFullPagePath,
  getPopupHeightRange,
};
export { query, lstore, weblstore, ministore, nativeStore, isPreview, isWxworkMiniProgramH5 };
