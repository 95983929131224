import config from './config';
import { login, doLogin } from './login';


let reportSdk = {};
// eslint-disable-next-line import/no-mutable-exports
let reportExport;



reportSdk = require('@tencent/eyao-report-web-sdk');

const reportConfig = reportSdk.default;
const { report } = reportSdk;
reportConfig({
  product: config.product,
  company: config.company,
  domain: config.reportDomain,
  env: config.env,

  /* debounceReportEvents:
  {events:['app.onlaunch',
  'app.onshow',
  'nges_enter',
  'page.onload',
  'page.onready',
  'page.onunload'],
  delay:200},*/
  loginReport: () => {
    return doLogin().then((token) => {
      const uin = login.getLoginInfo('uin');
      report.setToken(token);
      report.setUin(uin);
      report.sendUntokenList();
    });
  },
});
report.reportEvent = function () {};
reportExport = report;


export default reportExport;


