import { EyaoSaaSSynCreateMaterialApi } from '@/api/releaseManagement';
import { CosMixin } from '@/mixins/cos';

export default {
  name: 'Upload',
  mixins: [CosMixin],
  methods: {
    // 上传转换获取cos地址
    getCosUrl(arr) {
      const arrTemp = arr;
      return new Promise(async (resolve) => {
        for (let i = 0, ilen = arrTemp.length; i < ilen; i++) {
          const e = arrTemp[i];
          const cosParams = {
            fileObject: e.file,
          };
          try {
            const res = await this.putCosObject(cosParams);
            // delete e.file;
            e.url = res.data;
            e.cos_status = res ? 'success' : 'error';
          } catch (error) {
            e.cos_status = 'error';
          }
        }
        resolve(arrTemp);
      });
    },
    // 请求服务器
    async requestServer(arr) {
      const arrTemp = arr;
      console.log('arrTemp', arrTemp);
      return new Promise(async (resolve) => {
        for (let i = 0; i < arrTemp.length; i++) {
          const e = arrTemp[i];
          if (e.cos_status === 'success') {
            try {
              // cos上传失败, mate_data为空, 不走服务器
              if (!e.url) break;
              const datatype = e.mate_postfix.split('');
              datatype.shift();
              const res = await EyaoSaaSSynCreateMaterialApi({
                data_source: 'AUTO',
                name: e.mate_name + e.mate_postfix,
                url: e.url,
                app_id: e.apps.map((item) => {
                  return {
                    official_id: item.id,
                  };
                }),
                record_type: 'image',
                type: datatype.join(''),
              });
              if (!res.error) {
                e.upload_status = 'success';
              } else {
                e.upload_status = 'error';
              }
            } catch (error) {
              e.upload_status = 'error';
            }
          }
        }
        resolve(arrTemp);
      });
    },

    /** file 为选择文件选择 */
    async uploadFiles(files = []) {
      const newFiles = [...files];
      const supportFile = ['PNG', 'JPG', 'JPEG'];
      const supportFileExp = supportFile.join('|');
      const isOneNoSupportFile = newFiles.some((file) => {
        return !new RegExp(`(${supportFileExp})`, 'i').test(file.type);
      });
      if (isOneNoSupportFile) {
        return {
          fileList: files,
          successList: [],
          failList: files,
          error: `目前仅支持${supportFile.join('、')}`,
        };
      }

      const isOneOverSize = newFiles.some((file) => {
        return (file.size || 0) / 1024 / 1024 > 5;
      });
      if (isOneOverSize) {
        return {
          fileList: files,
          successList: [],
          failList: files,
          error: '单张图片大小不能超过5MB!',
        };
      }
      const arr = newFiles.map((file) => {
        return { file };
      });

      const resArr = await this.getCosUrl(arr); // await this.requestServer(await this.getCosUrl(arr));

      // 判断是否上传成功
      const failList = [];
      const successList = [];
      let error = '';
      resArr.forEach((item) => {
        if (item.cos_status === 'success' /* && e.upload_status === 'success'*/) {
          successList.push(item);
        } else {
          failList.push(item);
        }
      });

      if (failList.length) {
        error = `有${failList.length}张图上传失败, 请重新上传\n${failList
          .map((item) => item.file.name)
          .join('\n')}`;
      }
      return {
        fileList: resArr,
        successList,
        failList,
        error,
      };
    },
  },
};
