import { getDynamicCommonReportParams, getStaticCommonReportParams } from '../common/utils';
import TrackingReport from '../report';
import { getNewTaskId, addTask, removeTask } from '../common/task';
import { getCurUrl } from '../../util';
import CommonMixin from './common-mixin';
import { includes, isArray } from 'lodash';

function isOrIn(collect, key) {
  return collect === key || (isArray(collect) && includes(collect, key));
}

export default {
  mixins: [CommonMixin],
  data() {
    return {
      __trkEnable: false,
      __trkInsertedAt: undefined, // 记录进入布局的时间
      __trkTmpParams: undefined, // 记录进入时的一些静态数据，比如event_type，event_id这些，到最后离开上报的时候数据是不会变化的
      __trkTaskIdx: -1, // 记录任务id，在页面直接被关闭时，能触发上报
      __trkSaveReported: false, // 保存逻辑是否已经被触发，保证不重复触发
      __trkCurUrl: '',
      __trkOnShow: () => {},
      __trkEnums: {},
      __trkVisible: true,
      __trkObserver: null,
      __trkRef: null,
    };
  },
  mounted() {
    this.__trkVisible = true;
  },
  beforeDestroy() {
    this.__trkObserver?.disconnect();
  },
  methods: {
    __trkAddRef(ref) {
      if (ref) {
        this.__trkVisible = false;
        this.__trkObserver = new IntersectionObserver((entries) => {
          const { isIntersecting } = entries[0];
          this.__trkIntersection(isIntersecting);
        });
        this.__trkObserver.observe(this.$refs[ref]);
        
        
      } else {
        this.__trkVisible = true;
      }
    },
    __trkIntersection(isIntersecting) {
      if (isIntersecting) {
        this.__trkVisible = true;
        this.__trkEnter();
      } else {
        this.__trkLeave();
        this.__trkVisible = false;
      }
    },
    async __trkEnter() {
      await this.$nextTick();
      await this.__trkGetAppConf();
      if (!this.__trkEnable || !this.__trkVisible) {
        return;
      }
      this.__trkInsertedAt = parseInt(new Date().getTime() / 1000);
      const config = this.trackingEnterConfig || this.trackingConfig || {};
      const manualConfig = config?.config || {};
      if (manualConfig.enable) {
        const staticCommomParams = getStaticCommonReportParams();
        this.__trkTmpParams = {
          ...staticCommomParams,
          ...(config?.params || {}),
        };
        if (isOrIn(config.trigger, 'enter')) {
          const trackingParams = manualConfig.params || {};
          let reportData = {
            ...this.__trkTmpParams,
            ...getDynamicCommonReportParams(),
            ...trackingParams,
          };
          reportData = this.__trkGetDefaultData(reportData);
          TrackingReport.sendEvent(reportData);
        }
        // 记录退出任务到任务列表中，页面退出时提交，避免丢失数据
        this.__trkTaskIdx = getNewTaskId();
        addTask(this.__trkTaskIdx, this.__trkLeave);
      }
    },
    __trkLeave() {
      if (!this.__trkEnable || !this.__trkVisible) {
        return;
      }
      const config = this.trackingLeaveConfig || this.trackingConfig || {};
      const manualConfig = config?.config || {};
      if (manualConfig.enable && isOrIn(config.trigger, 'leave')) {
        const destroyedAt = parseInt(new Date().getTime() / 1000);
        const lifecycle = destroyedAt - this.__trkInsertedAt;
        const trackingParams = manualConfig.params || {};
        let reportData = {
          ...getDynamicCommonReportParams(),
          start_timestamp: this.__trkInsertedAt,
          end_timestamp: destroyedAt,
          event_value: lifecycle,
          ...this.__trkTmpParams,
          ...config.params,
          ...trackingParams,
        };
        reportData = this.__trkGetDefaultData(reportData);
        TrackingReport.sendEvent(reportData);
      }
      // 从任务列表中清除当前任务
      removeTask(this.__trkTaskIdx);
    },
    __trkSaved() {
      if (!this.__trkEnable) {
        return;
      }
      const config = this.trackingSaveConfig || this.trackingConfig || {};
      const manualConfig = config?.config || {};
      if (manualConfig.enable && !this.__trkSaveReported && isOrIn(config.trigger, 'save')) {
        this.__trkSaveReported = true;
        const destroyedAt = parseInt(new Date().getTime() / 1000);
        const lifecycle = destroyedAt - this.__trkInsertedAt;
        const trackingParams = manualConfig.params || {};
        let reportData = {
          ...getDynamicCommonReportParams(),
          start_timestamp: this.__trkInsertedAt,
          end_timestamp: destroyedAt,
          event_value: lifecycle,
          ...this.__trkTmpParams,
          ...config.params,
          ...trackingParams,
        };
        reportData = this.__trkGetDefaultData(reportData);
        TrackingReport.sendEvent(reportData);
      }
    },
    async __trkAddListener() {
      await this.__trkGetAppConf();
      if (!this.__trkEnable) {
        return;
      }
      this.__trkCurUrl = getCurUrl(); // 记录当前布局打开时的url，用于下次收起再打开时，对照是否一致。
      this.__trkOnShow = (data) => {
        if (this.__trkCurUrl && this.__trkCurUrl === data.curUrl) {
          this.__trkEnter();
        }
      };
      
      this.$trackingEventBus.$on('tracking_on_show', this.__trkOnShow);
      
    },
    __trkRemoveListener() {
      if (!this.__trkEnable) {
        return;
      }
      
      this.$trackingEventBus.$off('tracking_on_show', this.__trkOnShow);
      
    },
  },
};
