import { DataService } from './api';
import { easyQueryGql } from '../../layout/queryUtil';

async function easyQuery(json, extraCondition) {
  const { object, trigger_ext: triggerExt } = json;
  const gql = easyQueryGql(json, extraCondition);
  const res = await DataService({
    query: gql,
    trigger_ext: triggerExt,
  });
  return res[object];
}

export * from '../../layout/util';
export * from '../../layout/queryUtil';
export { easyQuery };
