//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { uploadToCos } from 'nges-common/src/web/components/layout/cos/index.js';
import { DownloadDoc } from '@/graphql/api/material.js';
import { getKvConfigs } from '@/graphql/api/meeting';

export default {
  props: {
    showImportDialog: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => {},
    },
    service: {
      type: Function,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    kvKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tempUrl: '',
      importTitle: this.title,
      errMsg: '',
      suffix: '',
      uploadPercentage: 0,
      selectedFile: null,
      cosFile: null,
      fileUrl: null,
      showCloseIcon: false,
      showUploading: false,
      importLoading: false,
      errorUrl: '',
    };
  },
  async mounted() {},
  methods: {
    async downloadTemp() {
      const kvRes = await getKvConfigs({
        keys: [this.kvKey],
      });
      this.tempUrl = kvRes?.kv?.find((item) => item.key === this.kvKey)?.value;
      const res = await DownloadDoc({
        app_id: 'nges',
        url: this.tempUrl,
        expires: 300,
      });
      res?.url && window.open(res.url);
    },
    fileChange(e) {
      console.log('e', e.target.files[0]);
      const file = e.target.files[0];
      this.selectedFile = file;
      const fileNameArr = file.name.split('.');
      const suffix = `.${fileNameArr[fileNameArr.length - 1]}`;
      // eslint-disable-next-line prefer-destructuring
      this.suffix = suffix.split('.')[1];
      this.showUploading = true;
      uploadToCos({
        file,
        suffix,
        isPrivate: true,
        onProgress: (progressData) => {
          console.log(progressData.percent * 100);
          this.uploadPercentage = progressData.percent * 100;
        },
      })
        .then(async (res) => {
          if (res.url) {
            const urlData = await DownloadDoc({
              app_id: 'nges',
              url: res.url,
              expires: 300,
            });
            if (urlData?.url) res = { ...res, url: urlData.url };
          }
          this.showUploading = false;
          this.fileUrl = res;
          console.log('res', res);
          this.cosFile = res;
          this.status = 'done';
        })
        .catch((e) => {
          this.showUploading = false;
          this.$message.error(e.message);
        });
    },
    uploaderClicked() {
      console.log('uploader clicked');
      if (this.selectedFile) return;
      document.getElementById('uploader').click();
    },
    dumpFile(e) {
      console.log('dumpfile', e);
      this.selectedFile = null;
      this.cosFile = null;
      this.uploadPercentage = 0;
      this.fileUrl = null;
      this.suffix = '';
      this.showCloseIcon = false;
      this.errMsg = '';
      this.errorUrl = null;
      this.importTitle = this.title;

      document.getElementById('uploader').value = null;
    },
    cancelImportDialog() {
      if (!this.selectedFile) {
        this.dumpFile();
        this.$emit('update:showImportDialog', false);
        return;
      }
      this.$confirm('放弃编辑后，本次编辑内容将会丢失，确认放弃？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.dumpFile();
        this.$emit('update:showImportDialog', false);
      });
    },
    async confirmImport() {
      // eslint-disable-next-line eqeqeq
      if (this.uploadPercentage != 100) {
        this.$message.error('请等待上传完成');
        return;
      }
      try {
        this.importLoading = true;
        const res = await this.service({
          ...this.params,
          url: this.cosFile.url,
          appid: 'nges',
        });
        console.log('res1', res);
        this.importLoading = false;
        if (res.errno < 0 && res.err_url && res.err_url.length) {
          this.importTitle = '导入失败，请下载失败文件查看';
          this.errMsg = res.errmsg;
          const pubUrl = await DownloadDoc({
            app_id: 'nges',
            url: res.err_url,
            expires: 300,
          });
          console.log('pubUrl', pubUrl);
          this.errorUrl = pubUrl?.url;
          return;
        }
        this.dumpFile();
        this.$emit('update:showImportDialog', false);
        this.$emit('importSuccess', res);
      } catch (error) {
        this.importLoading = false;
        this.$message.error(error?.message || '导入失败，请检查文件是否符合模版格式！');
        console.error(error);
      }
    },
  },
};
