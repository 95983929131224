import config from '@/config';
export const appList = [
  {
    status: '1',
    appid: 'wxd054a451d29d7865',
    id: '1471299560000196608',
    type: 1,
    app_name: 'qvfwang 测试公众号',
  },
  {
    status: '1',
    appid: 'wxd054a451d29d7865',
    id: '1471417348102557696',
    type: 1,
    app_name: 'meow',
  },
  {
    status: '1',
    appid: config.env === 'development' ? 'wx6fc82c07f5b2489c' : 'wx6fc82c07f5b2489cqvf',
    id: config.env === 'development' ? '1481196640952913920' : '1479051337424441344',
    type: 1,
    app_name: 'Tsep开发测试',
  },
  {
    status: '1',
    appid: 'wx7f6f57e74c71e27d',
    id: '1480834076268040192',
    type: 2,
    app_name: 'Tsep小程序',
  },
];
export const GetManagerLoginMock = {
  body: {
    payload: {
      role: 1,
      app_list: appList,
      agent_list: [
        {
          close: '0',
          agent_id: '1000037',
          agent_name: '这还是一个应用',
        },
        {
          close: '0',
          agent_id: '1000008',
          agent_name: 'demo',
        },
        {
          close: '0',
          agent_id: '1000038',
          agent_name: '这是一个应用',
        },
        {
          close: '0',
          agent_id: '1000004',
          agent_name: '测试销售部',
        },
        {
          close: '0',
          agent_id: '1000036',
          agent_name: '这也是一个应用',
        },
        {
          close: '0',
          agent_id: '1000040',
          agent_name: '视频兼容测试',
        },
        {
          close: '0',
          agent_id: '1000041',
          agent_name: '辉致个人中心应用',
        },
        {
          close: '0',
          agent_id: '1000045',
          agent_name: '来喝奶茶',
        },
        {
          close: '0',
          agent_id: '1000050',
          agent_name: '王煚测试应用',
        },
        {
          close: '0',
          agent_id: '1000003',
          agent_name: '',
        },
      ],
      uin: 80,
      qy_user_id: '',
      enterprise_id: 20000,
      phone: '13509603141',
      menu_list: [
        {
          menu_name: '登录',
          menu_path: 'login',
          menu_icon: '',
          hidden: 1,
          children: [],
        },
        {
          menu_name: '注册',
          menu_path: 'register',
          menu_icon: '',
          hidden: 1,
          children: [],
        },
        {
          menu_name: '发布管理',
          menu_path: '',
          menu_icon: 'el-icon-s-promotion',
          hidden: 0,
          children: [
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '内容管理',
              menu_path: 'articleManagement',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '名单管理',
              menu_path: 'rosterList',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '投票详情',
              menu_path: 'votingDetail',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '新增投票',
              menu_path: 'addVoting',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '投票管理',
              menu_path: 'voteManagement',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '视频上传',
              menu_path: 'material-video-upload',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '音频上传',
              menu_path: 'material-audio-upload',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '图片上传',
              menu_path: 'material-img-upload',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '素材库管理',
              menu_path: 'materialManagement',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '运营位管理',
              menu_path: 'operationManagement',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '运营位新增/编辑',
              menu_path: 'operationManagementEdit',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '编辑运营位',
              menu_path: 'operationManageEdit',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '文章列表',
              menu_path: 'artilceList',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '文章编辑',
              menu_path: 'addArtilce',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '评论管理',
              menu_path: 'commentManagement',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '新员工推送',
              menu_path: 'huizhi_EmployeeMaintenance',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '新员工推送编辑',
              menu_path: 'huizhi_EmployeeMaintenance/edit',
            },
          ],
        },
        {
          menu_name: '数据中心',
          menu_path: '',
          menu_icon: 'el-icon-pie-chart',
          hidden: 0,
          children: [
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '用户数据',
              menu_path: 'userData',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '会议数据',
              menu_path: 'meetingData',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '会议详情',
              menu_path: 'meetingDataDetail',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '参会者详情',
              menu_path: 'meetingOrganizersDetail',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '文章数据',
              menu_path: 'articleData',
            },
          ],
        },
        {
          menu_name: '用户管理',
          menu_path: '',
          menu_icon: 'el-icon-user',
          hidden: 0,
          children: [
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '员工管理',
              menu_path: 'staffManagement',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '医生管理',
              menu_path: 'doctorManagement',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '医生详情',
              menu_path: 'doctorInfo',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '代表管理',
              menu_path: 'representManagement',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '代表详情',
              menu_path: 'representDetaill',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '医院管理',
              menu_path: 'hospitalManagement',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '医院详情',
              menu_path: 'hospitalDetail',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '机构管理',
              menu_path: 'allergan_InstitutionalManage',
            },
          ],
        },
        {
          menu_name: '设置',
          menu_path: '',
          menu_icon: 'el-icon-setting',
          hidden: 0,
          children: [
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '注册认证设置',
              menu_path: 'registerAuth',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '重定向到微信',
              menu_path: 'redirectToWechat',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '公众号设置',
              menu_path: 'publicSet',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '新增公众号',
              menu_path: 'addPublicResult',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '标签设置',
              menu_path: 'tagSet',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '管理员设置',
              menu_path: 'setManager',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '查看策略',
              menu_path: 'strategyView',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '策略设置',
              menu_path: 'strategySetting',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '编辑策略',
              menu_path: 'strategyEdit',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '企业设置',
              menu_path: 'enterprisesSet',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '外部应用',
              menu_path: 'outAppSet',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '会议设置',
              menu_path: 'meetingSet',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '企业微信设置',
              menu_path: 'agentSet',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '积分设置',
              menu_path: 'huizhi_PointsRule',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '积分规则新建和编辑',
              menu_path: 'huizhi_PointsRule/edit',
            },
          ],
        },
        {
          menu_name: '企业微信管理',
          menu_path: '',
          menu_icon: 'el-icon-chat-dot-round',
          hidden: 0,
          children: [
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '会话留存',
              menu_path: 'chattingRecords',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '敏感词',
              menu_path: 'sensitiveWords',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '会话检索',
              menu_path: 'conversationView',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '通讯录管理',
              menu_path: 'addressList',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '外部联系人管理',
              menu_path: 'externalContactManagement',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '外部联系人管理分配记录查看',
              menu_path: 'externalContactManagementRecords',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '应用管理',
              menu_path: 'applicationManagement',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '应用详情',
              menu_path: 'applicationDetail',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '企业红包',
              menu_path: 'enterpriseRedPacket',
            },
          ],
        },
        {
          menu_name: '功能',
          menu_path: '',
          menu_icon: 'el-icon-edit-outline',
          hidden: 0,
          children: [
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '自动回复',
              menu_path: 'autoReply',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '添加回复',
              menu_path: 'addAutoReply',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '消息管理',
              menu_path: 'MessageList',
            },
            {
              menu_icon: '',
              hidden: 1,
              menu_name: '消息详情',
              menu_path: 'userMessageList',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '站内信管理',
              menu_path: 'webSiteMsgManagement',
            },
          ],
        },
        {
          menu_name: '考试管理',
          menu_path: '',
          menu_icon: 'el-icon-s-promotion',
          hidden: 0,
          children: [
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '试卷管理',
              menu_path: 'examPaperManagement',
            },
            {
              menu_icon: '',
              hidden: 0,
              menu_name: '题库管理',
              menu_path: 'examBankManagement',
            },
          ],
        },
        {
          menu_name: '帮助',
          menu_path: 'help',
          menu_icon: '',
          hidden: 1,
          children: [],
        },
        {
          menu_name: '平台服务协议',
          menu_path: 'protocol',
          menu_icon: '',
          hidden: 1,
          children: [],
        },
        {
          menu_name: 'E慧',
          menu_path: 'https://pfizeruat.tsepcn.com/e-wisdom/index.html',
          menu_icon: 'customer-icon-e-wisdom',
          hidden: 0,
          children: [],
        },
        {
          menu_name: '艾分享',
          menu_path: 'https://v2test.tsepcn.com/saasDisk/index.html',
          menu_icon: '',
          hidden: 0,
          children: [],
        },
      ],
      id: 10135,
      name: '李大双',
    },
    traceid: '436a4ff2-fd70-4218-9cc0-f9fa5d98aadb',
    message: 'success',
    cmd: 'GetManagerLogin',
    bizcode: 0,
    retcode: 0,
    seq: 1,
  },
  header: {
    version: '',
    flag: 0,
  },
};

export const GetManagerDetailMock = {
  // name: '吴迪峰',
  // uin: 61,
  // qy_user_id: '',
  // update_user: '10877',
  // update_time: '2021-12-14 11:24:49',
  // phone: '13637963781',
  // agent_list: [
  //   {
  //     agent_id: '1000002',
  //     agent_name: '智慧医药平台',
  //     close: '0',
  //   },
  //   {
  //     agent_id: '1000038',
  //     agent_name: '这是一个应用',
  //     close: '0',
  //   },
  //   {
  //     agent_id: '1000041',
  //     agent_name: '辉致个人中心应用',
  //     close: '0',
  //   },
  //   {
  //     agent_id: '1000045',
  //     agent_name: '来喝奶茶',
  //     close: '0',
  //   },
  // ],
  // create_time: '2021-12-14 11:18:54',
  // enterprise_id: 10000,
  // wxid: 'shu24766473',
  // id: 10219,
  // create_user: '10877',
  // role: 2,
  app_list: appList,
  // email: 'nekowu@tencent.com',
  // status: 1,
};

export const GetHospitalListMock = {
  page_index: 1,
  total_num: 334209,
  page_size: 20,
  data: [
    {
      city: '南通市',
      level: '',
      hospital_id: 1170841,
      hospital_name: '\t南通市崇川区城东街道新桥社区卫生服务站',
      province: '江苏',
      doctor_num: 0,
    },
    {
      city: '',
      level: '',
      hospital_id: 1275443,
      hospital_name: '',
      province: '',
      doctor_num: 0,
    },
    {
      city: '青岛市',
      level: '',
      hospital_id: 1446081,
      hospital_name: ' 青岛安合盛医疗管理有限责任公司康德诊所',
      province: '山东',
      doctor_num: 0,
    },
    {
      city: '无锡市',
      level: '',
      hospital_id: 1364551,
      hospital_name: '(134649)无锡市第二人民医院东院区',
      province: '江苏',
      doctor_num: 0,
    },
    {
      city: '青岛市',
      level: '',
      hospital_id: 1279816,
      hospital_name: '(16854)青岛市南花莲路社区卫生服务站',
      province: '山东',
      doctor_num: 0,
    },
    {
      city: '北京市',
      level: '',
      hospital_id: 1439615,
      hospital_name: '(3155030)南昌市象湖药店',
      province: '北京',
      doctor_num: 0,
    },
    {
      city: '大庆市',
      level: '',
      hospital_id: 1167829,
      hospital_name: '(A)大庆乙烯',
      province: '黑龙江',
      doctor_num: 0,
    },
    {
      city: '大庆市',
      level: '',
      hospital_id: 1180016,
      hospital_name: '(B)大庆五厂杏南社区服务站',
      province: '黑龙江',
      doctor_num: 0,
    },
    {
      city: '大庆市',
      level: '',
      hospital_id: 1167830,
      hospital_name: '(W)龙镇农场龙电',
      province: '黑龙江',
      doctor_num: 0,
    },
    {
      city: '海口市',
      level: '',
      hospital_id: 1393845,
      hospital_name: '(万宁)蔡汝平诊所',
      province: '海南',
      doctor_num: 0,
    },
    {
      city: '长沙市',
      level: '',
      hospital_id: 1132849,
      hospital_name: '(东院)长沙市中医医院长沙市第八医院',
      province: '湖南',
      doctor_num: 0,
    },
    {
      city: '中卫市',
      level: '',
      hospital_id: 1213390,
      hospital_name: '(中卫市)中西医诊所',
      province: '宁夏',
      doctor_num: 0,
    },
    {
      city: '临沧市',
      level: '',
      hospital_id: 1257294,
      hospital_name: '(临沧市临翔区益康诊所',
      province: '云南',
      doctor_num: 0,
    },
    {
      city: '临沧市',
      level: '',
      hospital_id: 1257401,
      hospital_name: '(临沧市马台乡那杏村卫生室',
      province: '云南',
      doctor_num: 0,
    },
    {
      city: '西安市',
      level: '',
      hospital_id: 1329659,
      hospital_name: '(临潼区)交口街道念杨村卫生室',
      province: '陕西',
      doctor_num: 0,
    },
    {
      city: '西安市',
      level: '',
      hospital_id: 1427793,
      hospital_name: '(临潼区)雨金街道东关村卫生室',
      province: '陕西',
      doctor_num: 0,
    },
    {
      city: '临高县',
      level: '',
      hospital_id: 1437206,
      hospital_name: '(临高)王丽梅西医诊所',
      province: '海南',
      doctor_num: 0,
    },
    {
      city: '鄂尔多斯市',
      level: '',
      hospital_id: 1351816,
      hospital_name: '(乌审旗)老干局医务室',
      province: '内蒙古',
      doctor_num: 0,
    },
    {
      city: '乌鲁木齐市',
      level: '',
      hospital_id: 1174345,
      hospital_name: '(乌市天山区)王彩玲诊所',
      province: '新疆',
      doctor_num: 0,
    },
    {
      city: '乌鲁木齐市',
      level: '',
      hospital_id: 1192048,
      hospital_name: '(乌鲁木齐)石岩诊所',
      province: '新疆',
      doctor_num: 0,
    },
  ],
};
