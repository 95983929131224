//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import drawer from '../drawer';

export default {
  name: 'ValidateBox',
  components: { drawer },
  props: {
    field: {
      type: [Object, void 0],
      default: () => {},
    },
    validate: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      icon: {
        success:
          'https://cdn.nges.tencent.com/nges_1/91013202/d283df4d-5a35-4902-a442-e1825c570d76.png',
        warning:
          'https://cdn.nges.tencent.com/nges_1/91013202/3f9c6f46-450d-42c2-bc5e-40920430acd6.png',
        error:
          'https://cdn.nges.tencent.com/nges_1/91013202/93572318-21c6-4462-87a0-dc0c6b64a2be.png',
      },
    };
  },
  computed: {
    validatePopupTitle() {
      return this?.field?.render?.popup?.title || '合规检查详情';
    },
    validateCount() {
      let title = '';
      if (this?.validate?.warning?.length || this?.validate?.error?.length) {
        if (this.validate.error.length) {
          const text = this?.field?.render?.popup?.error || 'X条合规告警事项';
          title += text.replace('X', this.validate.error.length);
        }
        if (this.validate.warning.length) {
          if (title) title += '；';
          const text = this?.field?.render?.popup?.warning || 'X条合规提醒事项';
          title += text.replace('X', this.validate.warning.length);
        }
      } else {
        title = this?.field?.render?.popup?.success || '所有合规事项已符合';
      }
      return title;
    },
  },
  watch: {
    validate(nv) {
      if (nv) {
        this.show();
      }
    },
  },
  mounted() {
    if (this.validate) {
      this.show();
    }
  },
  methods: {
    show() {
      this.visible = true;
    },
    close() {
      this.visible = false;
      setTimeout(() => {
        this.$emit('update:validate', null);
      }, 200);
    },
  },
};
