//
//
//
//
//
//

export default {
  name: 'Video',
  props: {
    src: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      video: null,
    };
  },
  mounted() {
    this.video = this.$refs.video;
    if (window.videoList) {
      window.videoList.set(this.name, this.video);
    } else {
      const videoList = new Map();
      videoList.set(this.name, this.video);
      window.videoList = videoList;
    }
  },
  methods: {
    play() {
      const { videoList } = window;
      for (const [key, video] of videoList) {
        if (key !== this.name) {
          video.pause();
        }
      }
    },
  },
};
