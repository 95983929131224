//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DialogBox',
  props: {
    title1: {
      type: String,
      default: '',
    },
    title2: {
      type: String,
      default: '',
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      centerDialogVisible: false,
    };
  },
  methods: {
    cancelFun() {
      this.$emit('dialogEmit', false);
    },
    sureFun() {
      this.$emit('dialogEmit', true);
    },
    close_dialog() {
      this.$emit('dialogEmit', false);
    },
  },
};
