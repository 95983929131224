function variateAction(action, cacheVariates, commit) {
  console.log('cacheVariates', cacheVariates);
  const { action_params: actionParams, action: actionName } = action;
  const { key, value } = actionParams;
  if (actionName === 'SET_VARIATE') {
    console.log('------设置变量------');
    console.log('key is: ', key);
    console.log('value is: ', value);
    commit('action/setCacheVariate', { key, value });
    return { key, value };
  }
  console.log('------读取变量------');
  console.log('key is: ', key);
  console.log('value is: ', cacheVariates[key]);
  return cacheVariates;
}

export { variateAction };
