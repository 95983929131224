import { queryDepartmentGql, queryUserOrDepartmentGql } from '@/graphql/gql/addressList';
import { calVariables } from '@/graphql/util';
import { DataService } from 'nges-common/src/web/utils/api';

export function queryDepartment(data) {
  return DataService({
    query: calVariables(queryDepartmentGql, data),
  });
}

export function queryUserOrDepartment(data) {
  return DataService({
    query: calVariables(queryUserOrDepartmentGql, data),
  });
}
