//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TagSelectAgent',
  data() {
    return {
      value1: [],
      value: [],
      agentList: [],
      visible: false,
    };
  },
  mounted() {
    this.agentList = this.$store.state.user.managerLogin.agent_list;
  },
  methods: {
    del(id) {
      this.value1.splice(
        this.value1.findIndex((item) => item.id === id),
        1,
      );
      this.$emit('emitAddPublic', this.value);
    },
    popoverHide() {
      this.value = this.value1;
    },
    // 确认选择
    handleBtn() {
      this.value1 = this.value;
      this.$emit('emitAddPublic', this.value);
      this.visible = false;
    },
  },
};
