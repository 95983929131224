/**
 * 对多个函数的转换分析，获得AST结构
 * */
function parseToAst(code) {
  const argList = [];
  const len = code.length;
  let idx = 0;
  while (idx < len) {
    idx = skipSpace(code, idx, len);
    const newArgs = parse(code, idx, len);
    if (!isEmptyArg(newArgs)) {
      argList.push(newArgs);
    }
    idx = skipSpace(code, newArgs.idx, len);
    const nextChar = code[idx];
    if (nextChar && !isFuncSplitChar(nextChar)) {
      throwError();
    }
    idx = idx + 1;
  }
  return argList;
}

/**
 * 针对单个函数指令解析，主要处理指令函数，对象，数组
 * */
function parse(code, start, len) {
  const skip = (idx) => {
    return skipSpace(code, idx, len);
  };
  let idx = start;
  const { name: wordName, idx: wordIdx } = parseWord(code, idx, len);
  idx = wordIdx;
  const name = wordName;
  const args = [];
  idx = skip(idx);
  if (idx >= len) {
    return { name, idx, args, ...getValue(name) };
  }
  let char = code[idx];
  if (isFuncArgsStart(char)) {
    idx = skip(idx + 1);
    while (idx < len) {
      char = code[idx];
      if (isFuncArgsEnd(char)) {
        idx = skip(idx + 1);
        return { name, idx, args, ...getValue(name), type: 'Func' };
      }
      if (isArgsSplit(char)) {
        idx = skip(idx + 1);
      }
      const newArgs = parse(code, idx, len);
      idx = newArgs.idx;
      if (isEmptyArg(newArgs)) {
        throwError();
      }
      args.push(newArgs);
    }
    if (idx === len) {
      throwError();
    }
  } else if (isArrayStart(char)) {
    idx = skip(idx + 1);
    while (idx < len) {
      char = code[idx];
      if (isArrayEnd(char)) {
        idx = skip(idx + 1);
        return { name, idx, args, ...getValue(name), type: 'Array' };
      }
      if (isArgsSplit(char)) {
        idx = skip(idx + 1);
      }
      const newArgs = parse(code, idx, len);
      idx = newArgs.idx;
      if (isEmptyArg(newArgs)) {
        throwError();
      }
      args.push(newArgs);
    }
    if (idx === len) {
      throwError();
    }
  } else if (isObjectStart(char)) {
    idx = skip(idx + 1);
    while (idx < len) {
      char = code[idx];
      if (isObjectEnd(char)) {
        idx = skip(idx + 1);
        return { name, idx, args, ...getValue(name), type: 'Object' };
      }
      if (isArgsSplit(char)) {
        idx = skip(idx + 1);
      }
      const key = parse(code, idx, len);
      if (!key.name || key.type === 'Func' || key.type === 'Array' || key.type === 'Object') {
        throwError();
      }
      key.type === 'Param' && (key.type = 'String');
      idx = key.idx;
      if (isEmptyArg(key)) {
        throwError();
      }
      if (isSeparatorChar(char) && !isObjectKeyValueSplit(code[idx])) {
        throwError();
      } else {
        idx = skip(idx + 1);
      }
      const value = parse(code, idx, len);
      idx = value.idx;
      if (isEmptyArg(value)) {
        throwError();
      }
      key?.args.push(value);
      args.push(key);
    }
    if (idx === len) {
      throwError();
    }
  }
  return { name, idx, args, ...getValue(name) };
}

/**
 * 跳过字符前部空格
 * */
function skipSpace(code, start, len) {
  let idx = start;
  while (idx < len) {
    const char = code[idx];
    if (isSpaceChar(char)) {
      idx = idx + 1;
    } else {
      break;
    }
  }
  return idx;
}

/**
 * 判断返回的指令是否为无效的空指令
 * */
function isEmptyArg(arg) {
  return !(arg?.name || arg?.args?.length || arg.type === 'Array' || arg.type === 'Object');
}

/**
 * 获取指令集的词
 * */
function parseWord(code, start, len) {
  let idx = start;
  let word = '';
  while (idx < len) {
    const char = code[idx];
    if (isSeparatorChar(char)) {
      break;
    } else if (isStrChar(char)) {
      const { idx: strIdx, name: strName } = parseStr(code, idx, len);
      word = `${word}${strName}`;
      idx = strIdx;
    } else {
      word = `${word}${char}`;
      idx = idx + 1;
    }
  }
  return { idx, name: word.trim() };
}

/**
 * 获取指令集的字符串
 * */
function parseStr(code, start, len) {
  let idx = start;
  let name = code[idx];
  const strStartChar = name;
  let preChar = name;
  idx = idx + 1;
  while (idx < len) {
    const char = code[idx];
    name += char;
    idx = idx + 1;
    if (char === strStartChar && preChar !== '\\') {
      break;
    }
    preChar = char;
  }
  return { idx, name };
}

/**
 * 抛出异常
 * */
function throwError() {
  throw new Error('语法错误');
}

/**
 * 是否函数，对象，数组分隔符，包括: (),{}[]:;
 * */
function isSeparatorChar(char) {
  return (
    isFuncArgsStart(char)
    || isFuncArgsEnd(char)
    || isArgsSplit(char)
    || isObjectStart(char)
    || isObjectEnd(char)
    || isArrayStart(char)
    || isArrayEnd(char)
    || isObjectKeyValueSplit(char)
    || isFuncSplitChar(char)
  );
}

/**
 * 是否函数入参开始位置
 * */
function isFuncArgsStart(char) {
  return char === '(';
}

/**
 * 是否函数入参结束位置
 * */
function isFuncArgsEnd(char) {
  return char === ')';
}

/**
 * 是否函数参数分隔位置
 * */
function isArgsSplit(char) {
  return char === ',';
}

/**
 * 是否多个函数分隔位置
 * */
function isFuncSplitChar(char) {
  return char === ';';
}

/**
 * 取出的字符串是否是否字符串
 * */
function isStrChar(char) {
  return char === "'" || char === '"';
}

/**
 * 取出的字符串是否是否空格
 * */
function isSpaceChar(char) {
  return char === ' ' || char === '\t' || char === '\n' || char === '\r';
}

/**
 * 取出的字符串是否是数字
 * */
function numberChar(char, char2) {
  let ch = char;
  if (ch === '-' || ch === '.') {
    ch = char2;
  }
  const code = ch.charCodeAt();
  return code >= 48 && code <= 57;
}

/**
 * 取出的字符串是否是对象
 * */
function objectChar(char) {
  return char === '{' || char === '[';
}

/**
 * 取出的字符串是否是布尔值
 * */
function booleanWord(word) {
  return word === 'true' || word === 'false';
}

/**
 * 是否对象开始的位置
 * */
function isObjectStart(char) {
  return char === '{';
}

/**
 * 是否对象结束的位置
 * */
function isObjectEnd(char) {
  return char === '}';
}

function isObjectKeyValueSplit(char) {
  return char === ':';
}

/**
 * 是否数组开始的位置
 * */
function isArrayStart(char) {
  return char === '[';
}

/**
 * 是否数组结束的位置
 * */
function isArrayEnd(char) {
  return char === ']';
}

/**
 * 通过取出的AST树的字符串，判断类型获取其实际的值
 * */
function getValue(name) {
  const char = (name && name[0]) || '';
  const char1 = (name && name[1]) || '';
  if (isStrChar(char)) {
    return {
      value: name.substring(1, name.length - 1),
      type: 'String',
    };
  }
  if (objectChar(char)) {
    return {
      value: JSON.parse(name),
      type: 'Object',
    };
  }
  if (numberChar(char, char1)) {
    return {
      value: parseFloat(name),
      type: 'Number',
    };
  }
  if (booleanWord(name)) {
    return {
      value: name === 'true',
      type: 'Boolean',
    };
  }
  return {
    value: name,
    type: 'Param',
  };
}

export default parseToAst;
