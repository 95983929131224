//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { includes, isArray, get, cloneDeep } from 'lodash';
import { uploadToCos } from '../cos';
import { execExpressionDeep } from '../../../../layout/expression';
import Action from '../../action';
import { request } from '../../../utils/request';

const ALLOW_FILE_TYPES = ['csv', 'xls', 'xlsx'];

const state = {
  visible: false,
  suffix: '',
  uploadPercentage: 0,
  fileUrl: '',
  status: 'before', // before, pending, done, error
  fileName: '',
  importTip: '',
  cancelingRequest: false,
  poll: 0,
  uploadRes: {
    status: 0, // 后端导入状态，0/2导入中 1导入成功 3导入失败
    status_msg: '',
    file_url: '', // 失败文件链接
    count: 0, // 成功导入数据的数量
  },
  errorFileLink: '',
  confirmLoading: false,
};

export default {
  mixins: [Action],
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    expParamsObj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return state;
  },
  computed: {
    allowFileTypes() {
      let fileTypes = this.config?.allowTypes;
      if (!fileTypes || !isArray(fileTypes) || !fileTypes.length) {
        fileTypes = ALLOW_FILE_TYPES;
      } else {
        fileTypes = fileTypes.filter((item) => {
          return includes(ALLOW_FILE_TYPES, item);
        });
      }
      return fileTypes;
    },
    uploadInputAccept() {
      return this.allowFileTypes.map((item) => `.${item}`).join(',');
    },
  },
  mounted() {
    this.backupState = JSON.stringify(state);
  },
  methods: {
    open() {
      this.visible = true;
    },
    async confirmHandler() {
      this.confirmLoading = true;
      if (this.uploadPercentage !== 100 || !this.fileUrl?.url) {
        this.$message.warning('请上传文件');
        this.confirmLoading = false;
        return;
      }
      try {
        await this.upLoadToBackEnd();
        this.restoreState();
        this.$message({
          message: '导入成功',
          type: 'success',
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.confirmLoading = false;
      }
    },
    restoreState() {
      const backupState = JSON.parse(this.backupState);
      Object.keys(backupState).forEach((key) => {
        this.$data[key] = backupState[key];
      });
    },
    closeHandler() {
      this.restoreState();
      this.$emit('close');
    },
    reUoloadHandler() {
      this.deleteFileHandler();
      this.$refs.upload.$children[0].$refs.input.click();
    },
    deleteFileHandler() {
      this.$refs.upload.clearFiles();
      this.status = 'before';
      this.errorFileLink = '';
      this.fileUrl = null;
    },
    beforeUpload(file) {
      // 上传前判断格式
      const reg = new RegExp(`.(${this.allowFileTypes.join('|')})$`, 'gi');
      if (!reg.test(file.name)) {
        this.$message.warning('请选择格式正确的文件');
        return false;
      }
      const fileSplit = file?.name.split('.');
      this.suffix = fileSplit[fileSplit.length - 1];
      this.status = 'pending';
      return true;
    },
    addFile(_, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },
    cancelUploadHandler() {
      this.cancelingRequest = true;
      if (this.poll) clearTimeout(this.poll);
      this.restoreState();
    },
    uploadToCos(file) {
      // 文件上传至腾讯云cos
      this.fileName = file.file.name;
      this.status = 'pending';
      this.uploadPercentage = 0;
      this.cancelingRequest = false;
      uploadToCos({
        file: file.file,
        suffix: `.${this.suffix}`,
        isPrivate: !!this.config?.isPrivate,
        onProgress: (progressData) => {
          this.uploadPercentage = progressData.percent * 100;
        },
      })
        .then((res) => {
          if (this.cancelingRequest) {
            return;
          }
          this.fileUrl = res;
          this.status = 'done';
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    // 调用后端云函数
    async upLoadToBackEnd() {
      let newParamsObj = {
        ...this.expParamsObj,
        t: {
          fileUrl: this.fileUrl?.url,
          suffix: this.suffix,
          p: this.expParamsObj.t,
        },
      };
      if (this.config?.$request_action_callback) {
        const reqCb = this.config.$request_action_callback;
        try {
          let res = await request(
            execExpressionDeep(cloneDeep(reqCb.cmd), newParamsObj),
            execExpressionDeep(cloneDeep(reqCb.payload), newParamsObj),
          );
          // 抛出上传文件的结果，以及上传文件的文件名，以便代码引入组件时能进行交互
          this.$emit('import-result', res, this.fileName);
          if (reqCb.response) {
            res = execExpressionDeep(cloneDeep(reqCb.response), {
              ...newParamsObj,
              t: {
                ...res,
                p: newParamsObj.t,
              },
            });
          }
          newParamsObj = {
            ...newParamsObj,
            t: {
              ...res,
              p: newParamsObj.t,
            },
          };
          if (reqCb?.error_file_url_key) {
            const errorFileLink = get(res, reqCb.error_file_url_key);
            const errorMes = get(res, reqCb.error_message_key) || '导入失败';
            if (errorFileLink) {
              this.errorFileLink = errorFileLink;
              this.$message.error(errorMes);
              throw new Error(errorMes);
            }
          }
        } catch (err) {
          this.status = 'error';
          throw new Error(err?.message);
        }
      }
      if (this.config?.callback) {
        const actionCallback = execExpressionDeep(
          cloneDeep(this.config?.callback || {}),
          newParamsObj,
        );
        const err = await this.m_actionHandle(actionCallback);
        if (err) {
          this.status = 'error';
          throw new Error(err?.message);
        }
      }
    },
  },
};
