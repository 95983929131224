//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TagSelect',
  props: {},
  data() {
    return {
      value1: [],
      value: [],
      visible: false,
    };
  },
  computed: {
    publicList() {
      return this.$store.getters['user/getPublishList'];
    },
  },
  methods: {
    del(id) {
      this.value1.splice(
        this.value1.findIndex((item) => item.id === id),
        1,
      );
      //   this.value1 = '';
      this.$emit('emitAddPublic', this.value);
    },
    popoverHide() {
      this.value = this.value1;
    },
    // 确认选择
    handleBtn() {
      this.value1 = this.value;
      // if (thisvalue.length === 0) {
      //   this.$message("请选择公众号");
      //   return;
      // }
      this.$emit('emitAddPublic', this.value);
      this.visible = false;
    },
  },
};
