import { getDynamicCommonReportParams, getStaticCommonReportParams } from '../common/utils';
import TrackingReport from '../report';
import CommonMixin from './common-mixin';

export default {
  mixins: [CommonMixin],
  data() {
    return {
      __trkEnable: false,
      __trkEnums: {},
    };
  },
  methods: {
    async __compTracking(compConf = {}, userConf = {}) {
      await this.__trkGetAppConf();
      if (!this.__trkEnable) {
        return;
      }
      if (!userConf.enable) {
        return;
      }
      const reportParams = userConf.params || {};
      let reportData = {
        ...getStaticCommonReportParams(),
        ...getDynamicCommonReportParams(),
        ...compConf,
        ...reportParams,
      };
      reportData = this.__trkGetDefaultData(reportData);
      TrackingReport.sendEvent(reportData);
    },
  },
};
