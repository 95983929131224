export default {
  namespaced: true,
  getters: {},
  state: {
    cacheVariate: {},
  },
  mutations: {
    setCacheVariate(state, { key, value }) {
      state.cacheVariate[key] = value;
    },
    clearCacheVariate(state) {
      state.cacheVariate = {};
    },
  },
};
