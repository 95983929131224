import { DataService, GetUserMenuTree } from '../web/utils/api';
import { execExpression, expressionGlobalFuncKey } from './expression';
import { getLayoutJson } from './util';

function initGlobalFunc(json) {
  const { global_func: globalFunc } = json;
  if (globalFunc) {
    Object.keys(globalFunc).forEach((key) => {
      window[expressionGlobalFuncKey][key] = execExpression(globalFunc[key]);
    });
  }
}

function getCustomJson(json) {
  try {
    getGlobalConfigGlobalFunc();
    initGlobalFunc(json);
  } catch (e) {
    console.log(e);
  }

  const { custom_json: customJson } = json;
  let prom;
  if (customJson?.query) {
    prom = DataService({
      query: customJson.query,
    });
  } else {
    prom = Promise.resolve();
  }
  if (customJson?.json) {
    return prom.then((res) => {
      execExpression(customJson.json, { t: res, json });
      return json;
    });
  }
  return json;
}

let flag = false;
function getGlobalConfigGlobalFunc() {
  return new Promise((res) => {
    if (!flag) {
      GetUserMenuTree()
        .then(({ data }) => {
          initGlobalFunc(getLayoutJson(data, 'globalConfigWeb'));
          flag = true;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          res();
        });
    } else {
      res();
    }
  });
}

export * from './util';
export * from './queryUtil';
export { getCustomJson, getGlobalConfigGlobalFunc };
