//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChartPie from './chartPie';
export default {
  name: 'ChartPieData',
  components: {
    ChartPie,
  },
  props: {
    chartColor: {
      type: Array,
      default: () => [],
    },
    dotColor: {
      type: String,
      default: '#94c9ff',
    },
    pieList: {
      type: Array,
      default: () => [
        { value: 335, name: '直接访问' },
        { value: 310, name: '邮件营销' },
        { value: 234, name: '联盟广告' },
        { value: 135, name: '视频广告' },
        { value: 1548, name: '搜索引擎' },
      ],
    },
    title: {
      type: String,
      default: '关注产品',
    },
    className: {
      type: String,
      default: 'chart',
    },
    id: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '360px',
    },
    height: {
      type: String,
      default: '320px',
    },
  },
  data() {
    return {
      leftList: [],
      rightList: [],
      chart: null,
    };
  },
  watch: {
    pieList: {
      handler(value) {
        const halfIndex = Math.ceil(value.length / 2) || 0;
        this.leftList = value.slice(0, halfIndex);
        this.rightList = value.slice(halfIndex);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {},
};
