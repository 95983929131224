const components = {};
const componentsFiles = require.context('.', true, /\.vue$/);
componentsFiles.keys().forEach((fileName) => {
  if (fileName !== './index.js') {
    const component = require(`${fileName}`);
    let modleName = fileName.slice(2).split('/')[0];
    modleName = modleName.split('.')[0];
    components[`layout-${modleName}`] = component.default;
  }
});

export default components;
