import request from '@/libs/request';
import { GetManagerDetailMock } from './mock';
import { queryAppidList } from '@/graphql/api/article';
import {
  queryStrategyList,
  queryGetStrategyDetail,
  queryUpdateStrategyStatus,
  queryCountStrategyPeople,
  queryCountAllUserStrategyPeople,
  queryCreateStrategy,
  queryUpdateStrategy,
  queryDeleteStrategyInfo,
} from '@/graphql/api/strategy';
import { queryGetProductInfoList, queryGetDomainInfoList } from '@/graphql/api/other';
import {
  queryBuInfoList,
  updateBuInfo,
  updateDomainInfo,
  updateProductInfo,
  createBuInfo,
  createDomainInfo,
  createProductInfo,
  queryTagCategoryList,
  queryTagCategoryListVersion0,
  updateTagCategory,
  delateTagCategory,
  createTagCategory,
  queryTagLabelList,
  createTagLabelInfo,
  updateTagLabelInfo,
  updateDomainOrderInfo,
} from '@/graphql/api/tag';
import { queryUserList } from '@/graphql/api/user';

export const getAppListApi = (data) => queryAppidList(data);
export const getAppDetailApi = (data) => request('GetAppDetail', data);
export const getAppDetailByAppIDApi = (data) => request('GetAppDetailByAppID', data);
export const updateAppInfoStatusApi = (data) => request('UpdateAppStatus', data);
export const getManagerListApi = (data) => request('GetManagerList', data);
export const updateManagerStatusApi = (data) => request('UpdateManagerStatus', data);
export const addManagerInfoApi = (data) => request('CreateManagerInfo', data);
export const getAppManagerCountApi = (data) => request('GetManagerCount', data);
export const GetManagerInfoByAppOrAgentId = (data) => queryUserList(data);
export const getAppListByRoleApi = (data) => request('getAppListByRoleA', data);
export const getManagerInfoApi = () => Promise.resolve(GetManagerDetailMock);
export const getAuthURLApi = (data) => request('GetAuthURL', data);
export const updateManagerInfoApi = (data) => request('UpdateManagerInfo', data);
export const getAppUserCountApi = (data) => queryCountStrategyPeople(data); // 获取策略对于公众号人数
export const getAllUserCountApi = (data) => queryCountAllUserStrategyPeople(data); // 获取全部用户策略对于公众号人数
export const getPublishedAppListApi = (data) => queryAppidList(data); // 获取已经发布的公众号列表
export const GetManagerUserGroup = (data) => request('GetManagerUserGroup', data);

// 外部应用接口
export const UpdateLinkAppInfoApi = (data) => request('UpdateLinkAppInfo', data);
export const GetLinkAppDetailApi = (data) => request('GetLinkAppDetail', data);
export const GetLinkAppInfoListApi = (data) => request('GetLinkAppInfoList', data);
export const CreateLinkAppInfoApi = (data) => request('CreateLinkAppInfo', data);
export const UpdateLinkAppOrderApi = (data) => request('UpdateLinkAppOrder', data);
export const DeleteLinkAppInfoApi = (data) => request('DeleteLinkAppInfo', data);
// 策略接口
export const getStrategyList = (data = {}) => queryStrategyList(data);
export const GetStrategyListByAppID = (data = {}) => request('GetStrategyListByAppID', data);
export const getStrategyDetail = (data = {}) => queryGetStrategyDetail(data);
export const updateStrategyStatus = (data = {}) => queryUpdateStrategyStatus(data);

export const CreateStrategyInfo = (data = {}) => queryCreateStrategy(data);
export const updateStrategyInfo = (data = {}) => queryUpdateStrategy(data);
export const DeleteStrategyInfo = (data = {}) => queryDeleteStrategyInfo(data);
// 校验医院HCO_Code数据
export const ImportHcoList = (data) =>
  request('McmAdminServer/HcoPublishListService/ImportHcoList', data);
/** 企业设置 */
/* 获取领域列表 */
export const getDomainInfoListApi = (data = {}) => queryGetDomainInfoList(data);

/** 标签设置 */
// 标签设置-产品疾病其他
export const UpdateLabelStatus = (data) => updateTagLabelInfo(data); // 修改标签状态
export const UpdateLabelInfo = (data) => updateTagLabelInfo(data); // 修改标签
export const GetLabelDetail = (data) => request('GetLabelDetail', data); // 标签详情
export const GetLabelInfoList = (data) => queryTagLabelList(data); // 设置标签列表
export const CreateLabelInfo = (data) => createTagLabelInfo(data); // 添加标签

export const CreateLabelClass = (data) => createTagCategory(data); // 新增标签分类
export const RenameLabelClass = (data) => updateTagCategory(data); // 重命名自定义标签分类名称
export const GetLabelClass = (data) => queryTagCategoryList(data); // 查询标签分类列表
export const GetLabelClassVersion0 = (data) => queryTagCategoryListVersion0(data); // 查询标签分类列表（version1）
export const DeleteLabelClass = (data) => delateTagCategory(data); // 删除自定义标签分类

// 标签设置-品牌标签
export const GetBuInfoList = (data) => queryBuInfoList(data); // 获取Bu列表
export const UpdateBuInfo = (data) => updateBuInfo(data); // 修改Bu
export const CreateBuInfo = (data) => createBuInfo(data); // 添加Bu
export const UpdateBuOrder = (data) => request('UpdateBuOrder', data); // 修改Bu顺序
export const GetDomainInfoList = (data) => queryGetDomainInfoList(data); // 获取Domain列表
export const UpdateDomainInfo = (data) => updateDomainInfo(data); // 修改Domain
export const CreateDomainInfo = (data) => createDomainInfo(data); // 添加Domain
export const UpdateDomainOrder = (data) => updateDomainOrderInfo(data); // 修改Domain顺序
export const GetProductInfoList = (data) => queryGetProductInfoList(data); // 获取Product列表

export const UpdateProductInfo = (data) => updateProductInfo(data); // 修改Product
export const CreateProductInfo = (data) => createProductInfo(data); // 添加Product
export const UpdateProductOrder = (data) => request('UpdateProductOrder', data); // 修改Product顺序
// 企业设置接口
export const GetCompanyInfo = (data) => request('GetCompanyInfo', data); // 获取药企基本信息
export const SaveCompanyInfo = (data) => request('SaveCompanyInfo', data); // 保存药企信息
// 会议设置接口
export const GetMeetingConfig = (data) => request('GetMeetingConfig', data); // 获取药企下所有策略
export const SaveMeetingConfig = (data) => request('SaveMeetingConfig', data); // 保存药企下所有策略

// 企业微信管理
export const getAgentManagementApi = (data) => request('GetWecomConfig', data);
export const updateAgentManagementApi = (data) => request('SaveWecomConfig', data);

// 注册认证设置接口
export const getRegisterAuthConfig = (data) => request('GetRegisterAuthConfig', data);
export const setRegisterAuthConfig = (data) => request('SetRegisterAuthConfig', data);

export const getWxAppList = (data) => request('NgesAdminServer/NgesAdminApp/GetWxAppList', data);
