var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rightFilter.includes(_vm.$route.name) || _vm.hasRight)?_c('div',{staticClass:"container"},[(!_vm.layoutFilter.includes(_vm.$route.name))?[_c('div',{class:{ 'container-body': true, 'container-body-collapse': _vm.isCollapse }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.$route.name === 'register')),expression:"!($route.name === 'register')"}],staticClass:"container-aside",style:(!_vm.isCollapse || { minWidth: _vm.isIpad ? '80px' : '56px', width: _vm.isIpad ? '80px' : '56px' })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapse),expression:"!isCollapse"}],staticClass:"container-header-box"},[(_vm.logo)?_c('img',{staticClass:"container-header-logo",attrs:{"alt":"","src":_vm.logo}}):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCollapse && _vm.collapseLogo),expression:"isCollapse && collapseLogo"}],staticClass:"container-header-box collapse",class:{ ipad: _vm.isIpad }},[_c('div',{staticClass:"logo-collapse"},[(_vm.collapseLogo)?_c('img',{attrs:{"alt":"","src":_vm.collapseLogo}}):_vm._e()])]),_c('div',{staticClass:"menu-container"},[_c('side-menu',{attrs:{"is-collapse":_vm.isCollapse},on:{"collapse":function($event){_vm.isCollapse = !_vm.isCollapse},"jump":_vm.clearBreadcrumb}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasRight),expression:"hasRight"}],staticClass:"menu-footer",style:(_vm.menuFooterStyle),on:{"click":function($event){_vm.isCollapse = !_vm.isCollapse}}},[_c('i',{staticClass:"mf-icon iconfont",class:_vm.isCollapse ? ['icon-menu-collapse-close'] : ['icon-menu-collapse-open']})])],1)]),_c('main',{class:{ 'with-scroll': _vm.showPageScrollbar }},[_c('div',{class:{
            'container-header': true,
            'container-header-longer': _vm.isCollapse,
            isIpad: _vm.isIpad,
          }},[(_vm.showReturn && _vm.hasBreadcrumbBack)?_c('div',{staticClass:"header-back",on:{"click":_vm.breadcrumbBack}},[_c('i',{staticClass:"iconfont icon-up"}),_c('div',{staticClass:"header-back-button"},[_vm._v("返回")])]):_vm._e(),_c('el-breadcrumb',{ref:"breadcrumb",staticClass:"breadcrumb",attrs:{"separator-class":"el-icon-arrow-right"}},_vm._l((_vm.showBreadcrumb),function(item,index){return _c('el-breadcrumb-item',{key:item.menu_name + index,class:{
                disabled: index !== _vm.showBreadcrumb.length - 1 && _vm.getBreadcrumbToUrl(item) === '',
                last: index === _vm.showBreadcrumb.length - 1,
              },attrs:{"to":_vm.getBreadcrumbToUrl(item, index),"replace":true}},[_vm._v(" "+_vm._s(item.menu_name)+" ")])}),1),_c('div',{staticClass:"container-header-right"},[(!_vm.loading)?_c('quick-entry',{on:{"jump":_vm.clearBreadcrumb,"load-finish":_vm.setBreadcrumbLayout}}):_vm._e(),_c('div',{staticClass:"container-header-menu"},[_c('el-dropdown',{attrs:{"trigger":"click","placement":_vm.territoryRight ? 'bottom-start' : 'bottom-end'},on:{"visible-change":function($event){_vm.showTerritoryList = false}}},[_c('div',{ref:"userInfo",staticClass:"user-info"},[_c('img',{staticClass:"container-header-icon",attrs:{"alt":"","src":_vm.headimg || _vm.g_imgSrc.userAvatar}}),_c('div',{staticClass:"container-header-username"},[_vm._v(" "+_vm._s(_vm.fullName)+" ")])]),_c('user-action',{attrs:{"user-info":_vm.userInfo,"config":_vm.getConfig}},[_c('el-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.territoryList && _vm.territoryList.length > 1),expression:"territoryList && territoryList.length > 1"}]},[_c('div',{staticClass:"change-territory",on:{"click":function($event){$event.stopPropagation();_vm.showTerritoryList = !_vm.showTerritoryList}}},[_vm._v(" 切换岗位 "),_c('i',{staticClass:"iconfont icon-right"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTerritoryList),expression:"showTerritoryList"}],ref:"territoryList",staticClass:"change-territory-menu",class:{ right: _vm.territoryRight }},_vm._l((_vm.territoryList),function(item){return _c('div',{key:item.code,staticClass:"change-territory-item",class:{
                            main:
                              item.code === _vm.territoryCode && item.relation_type === _vm.territoryType,
                          },on:{"click":function($event){$event.stopPropagation();return _vm.changeTerritory(item)}}},[(item.relation_type_name)?_c('el-tag',{staticClass:"item-tag",attrs:{"type":item.relation_type === 'RELATION_MAIN' ? 'warning' : 'primary',"size":"mini"}},[_vm._v(" "+_vm._s(item.relation_type_name)+" ")]):_vm._e(),_c('span',{staticClass:"item-text"},[_vm._v(_vm._s(item.name))])],1)}),0)])])],1)],1)],1)],1)],1),_c('div',{staticClass:"container-main"},[_vm._t("default")],2),_c('water-mark'),_c('help-guide'),_c('share-data'),_c('select-confirm-dialog'),_c('data-history-drawer')],1)])]:_c('main',[_vm._t("default")],2)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }