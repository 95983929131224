// 标签相关接口
const TagCategoryList = `query TagCategoryList {
    tag_category(_order_by: {create_time: _asc}) {
        id
        label_class_name: name,
        tag(_order_by: {status: _asc}){
          grand_id
          parent_id
          name
          id
          status
          third_parent_id
          fourth_parent_id
          fifth_parent_id
          sixth_parent_id
          seventh_parent_id
          eighth_parent_id
          ninth_parent_id
          level
        }
    }
}`;
// 获取老标签组
const TagCategoryListVersion0 = `query TagCategoryList {
    tag_category(_order_by: {create_time: _asc}, _where: { version_type: 0 }) {
        id
        label_class_name: name,
        tag(_order_by: {status: _asc}){id, name, status}
    }
}`;
const tagTree = `query TagCategoryList {
    tag_category(_order_by: {create_time: _asc}) {
        id,
        name,
        tag(_order_by: {status: _asc}){id, name, status, parent_id, grand_id}
    }
}`;
const createTagCategoryGql = `mutation insert {
    tag_category(_values: $value) {
        id
    }
}`;
const updateTagCategoryGql = `mutation update {
    tag_category(_where: { id: $id }, _set: $value)
}`;
const delateTagCategoryGql = `mutation delete {
    tag_category(id: $id) { id }
    tag(category_id: $id) { id }
}`;
const queryTagLabelListGql = `query queryTagLabelListGql {
    tag(_order_by: {status: _asc, update_time: _desc}, _where: { category_id: $label_type }, $pageOption) {
        _aggregate {
            _count
        }
        id
        label_name: name
        status
    }
}`;
const createTagLabelInfoGql = `mutation insert {
    tag(_values: $value) {
        id
    }
}`;
const updateTagLabelInfoGql = `mutation update {
    tag(_where: { id: $id }, _set: $value) {
        id
    }
}`;
const deleteTagGql = `mutation delete {
    tag(_where: { id: $id }) {
        id
    }
}`;
const deleteTagGqlRel = `mutation delete {
    mcm_interest_tag(_where: { category: "1", tag_name: $name }) {
        id
    }
}`;
const TagList = `query TagList {
    bu_tag {
        _aggregate {
            _count
        }
        bu_id: id
        bu_name: name
        status
        domain_tag {
            _aggregate {
                _count
            }
            id
            domain_name: name
            status
            product_tag {
                _aggregate {
                    _count
                }
                id
                name
                status
            }
        }
    }
}`;

const queryBuInfoListGql = `query queryBuInfoListGql {
    bu_tag(_order_by: {status: _asc, update_time: _desc}) {
        _aggregate {
            _count
        }
        id
        bu_name: name
        status
    }
}`;

const queryDomainInfoListGql = `query queryDomainInfoListGql {
    domain_tag(_order_by: {status: _asc, update_time: _desc }, $filter) {
        _aggregate {
            _count
            _max { index }
        }
        id
        bu_id
        domain_name: name
        status
        show_status
        index
    }
}`;

// 查询排序字段最大值
const queryDomainMaxIndexGql = `query {
    domain_tag {
        _aggregate {
            _max { index }
        }
    }
}`;

const queryProductInfoListGql = `
query queryProductInfoListGql {
    product_tag($filter, _order_by: {status: _asc, update_time: _desc}) {
        _aggregate {
            _count
        }
        id
        bu_id
        domain_id: domain_tag_id
        product_name: name
        status
    }
}`;

const updateBuInfoGql = `mutation update {
    bu_tag(_where: { id: $id }, _set: $value)
}`;

const updateDomainInfoGql = `mutation update {
    domain_tag(_where: { id: $id }, _set: $value)
}`;

const updateBuStatusGql = `mutation update {
    bu_tag(_where: { id: $id }, _set: { status: $status })
    domain_tag(_where: { bu_id: $id }, _set: { status: $status })
    product_tag (_where: { bu_id: $id }, _set: {status: $status})
}`;

const updateDomainStatusGql = `mutation update {
    domain_tag(_where: { id: $id }, _set: { status: $status })
    product_tag (_where: {domain_tag_id: $id}, _set: {status: $status})
}`;

const updateProductStatusGql = `mutation update {
    product_tag (_where: {id: $id}, _set: {status: $status})
}`;

const deleteBuGql = `mutation delete {
    product_tag (_where: { bu_id: $id })
    bu_tag(_where: { id: $id })
    domain_tag(_where: { bu_id: $id })
}`;

const deleteDomainGql = `mutation delete {
    product_tag (_where: {domain_tag_id: $id})
    domain_tag(_where: { id: $id })
}`;

const deleteProductGql = `mutation delete {
    product_tag (_where: {id: $id})
}`;

const updateDomainOrderInfoGql = `mutation update {
    domain_tag1: domain_tag(_where: { id: $id1 }, _set: { index: $index1 })
    domain_tag2: domain_tag(_where: { id: $id2 }, _set: { index: $index2 })
}`;

const updateProductInfoGql = `mutation update {
    product_tag(_where: { id: $id }, _set: $value)
}`;

const createBuInfoGql = `mutation insert {
    bu_tag(_values: $value) {
        id
    }
}`;

const createDomainInfoGql = `mutation insert {
    domain_tag(_values: $value) {
        id
    }
}`;

const createProductInfoGql = `mutation insert {
    product_tag(_values: $value) {
        id
    }
}`;

export const tagCategories = `query {
    tag_category(_order_by: {create_time: _asc}) {
        id
        is_default
        name
        max_level
        article_required
        require_pages
        display_pages
        article_prompt
        tag(_where: {category.is_default: 1}, _order_by: {create_time: _desc}) {
          grand_id
          parent_id
          name
          id
          status
          third_parent_id
          fourth_parent_id
          fifth_parent_id
          sixth_parent_id
          seventh_parent_id
          eighth_parent_id
          ninth_parent_id
          level
        }
    }
}`;

export const tagCategoriesVersion1 = `query {
    tag_category(_order_by: {create_time: _asc}, _where: { version_type: 1 } ) {
        id
        is_default
        name
        max_level
        article_required
    }
}`;

// 查询标签树结构 _where: { version_type: 1 },
export const tagCategoryTrees = `{
  tag_category(_order_by: {create_time: _asc}) {
    id
    name
    children:tag(_where: { status: 0 },_order_by: {create_time: _desc}, parent_id: {_is_null: true}) {
      id
      name
      children:child(_where: { status: 0 },_order_by: {create_time: _desc}) {
        id
        name
        children:child(_where: { status: 0 },_order_by: {create_time: _desc}) {
          id
          name
          children:child(_where: { status: 0 },_order_by: {create_time: _desc}) {
            id
            name
            children:child(_where: { status: 0 },_order_by: {create_time: _desc}) {
              id
              name
              children:child(_where: { status: 0 },_order_by: {create_time: _desc}) {
                id
                name
                children:child(_where: { status: 0 },_order_by: {create_time: _desc}) {
                  id
                  name
                  children:child(_where: { status: 0 },_order_by: {create_time: _desc}) {
                    id
                    name
                    children:child(_where: { status: 0 },_order_by: {create_time: _desc}) {
                      id
                      name
                      children:child(_where: { status: 0 },_order_by: {create_time: _desc}) {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`;

// 查询标签树结构
export const tagCategoryTreesByCategoryId = function (data) {
  return `query {
    tag_category(_where: {id: "${data.category_id}"} ,_order_by: {create_time: _desc}) {
      id
      name
      children:tag(_order_by: {create_time: _desc}, parent_id: {_is_null: true}) {
        id
        name
        level
        status
        children:child(_order_by: {create_time: _desc}) {
          id
          name
          level
          status
          children:child(_order_by: {create_time: _desc}) {
            id
            name
            level
            status
            children:child(_order_by: {create_time: _desc}) {
              id
              name
              level
              status
              children:child(_order_by: {create_time: _desc}) {
                id
                name
                level
                status
                children:child(_order_by: {create_time: _desc}) {
                  id
                  name
                  level
                  status
                  children:child(_order_by: {create_time: _desc}) {
                    id
                    name
                    level
                    status
                    children:child(_order_by: {create_time: _desc}) {
                      id
                      name
                      level
                      status
                      children:child(_order_by: {create_time: _desc}) {
                        id
                        name
                        level
                        status
                        children:child(_order_by: {create_time: _desc}) {
                          id
                          name
                          level
                          status
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`;
};

export const categoryTags = function (data) {
  return `query {
    tag(_order_by: {create_time: _desc}, _where: { category_id: $category_id ${
      data.status ? `, status: $status` : ''
    } }) {
                grand_id
                parent_id
                name
                id
                status
                third_parent_id
                fourth_parent_id
                fifth_parent_id
                sixth_parent_id
                seventh_parent_id
                eighth_parent_id
                ninth_parent_id
                level
            }
        }`;
};

export const categoryTagsAll = function () {
  return `query {
    tag_category(_order_by: {create_time: _desc}, _where: { id: {_in: $category_ids}
    }) {
                id
                name
                tag(_order_by: {create_time: _desc}){
                  grand_id
                  parent_id
                  name
                  id
                  status
                  third_parent_id
                  fourth_parent_id
                  fifth_parent_id
                  sixth_parent_id
                  seventh_parent_id
                  eighth_parent_id
                  ninth_parent_id
                  level
                }
            }
        }`;
};

export const tagDuplicate = `query {
    tag(_where: { name: $name, category_id: $category_id }) {
        id
    }
}`;

export const deleteTagBatchGql = `mutation delete {
    tag(_where: {_or:[{id: $id}, {parent_id: $id}, {grand_id: $id}, {third_parent_id: $id}, {fourth_parent_id: $id}, {fifth_parent_id: $id}, {sixth_parent_id: $id}, {seventh_parent_id: $id}, {eighth_parent_id: $id}, {ninth_parent_id: $id}]}) {
        id
    }
}`;

export const updateTagBatchGql = `mutation update {
    tag(_where: {_or:[{id: $id}, {parent_id: $id}, {grand_id: $id}, {third_parent_id: $id}, {fourth_parent_id: $id}, {fifth_parent_id: $id}, {sixth_parent_id: $id}, {seventh_parent_id: $id}, {eighth_parent_id: $id}, {ninth_parent_id: $id}]}, _set: $value) {
        id
    }
}`;

export {
  TagCategoryList,
  TagCategoryListVersion0,
  TagList,
  queryBuInfoListGql,
  queryDomainInfoListGql,
  queryProductInfoListGql,
  updateDomainInfoGql,
  updateProductInfoGql,
  updateBuInfoGql,
  createBuInfoGql,
  createDomainInfoGql,
  createProductInfoGql,
  updateTagCategoryGql,
  delateTagCategoryGql,
  createTagCategoryGql,
  queryTagLabelListGql,
  createTagLabelInfoGql,
  updateTagLabelInfoGql,
  updateDomainOrderInfoGql,
  updateDomainStatusGql,
  updateBuStatusGql,
  updateProductStatusGql,
  queryDomainMaxIndexGql,
  deleteBuGql,
  deleteDomainGql,
  deleteProductGql,
  deleteTagGql,
  deleteTagGqlRel,
  tagTree,
};
