//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { cloneDeep } from 'lodash';

import { CosMixin } from '@/mixins/cos';
import { MINE_MAP, MINE_MAP_TEXT } from 'nges-common/src/constant.js';
import previewFile from 'nges-common/src/web/components/preview-file/index.vue';

export default {
  components: {
    previewFile,
  },
  mixins: [CosMixin],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogUrl: '',
      preFile: false,
      preType: '',
      dialogImageUrl: '',
      dialogVisible: false,
      fileArr: [],
      fieldFileProgress: 0,
      disabled: false,
      isMask: false,
      imgExtension: MINE_MAP.image,
      fileExtension: [...MINE_MAP.image, ...MINE_MAP.pdf, ...MINE_MAP.word], // 当前允许上传的文件后缀名
    };
  },
  computed: {
    uploadOpt() {
      return {
        maxSize: 50 * 1024 * 1024, // 默认最大体积限制 50M
        allowTypes: ['image', 'pdf', 'word'], // 默认三种类型，这三种类型是一个大类
        maxCount: 10, // 默认文件总数的最大值
        over_limit_tips: '文件数量超过上传限制，请重新上传',
      };
    },
    desc() {
      const { allowTypes, maxCount, maxSize, allowDesc } = this.uploadOpt;
      const fileDesc = {
        image: MINE_MAP_TEXT.image,
        video: MINE_MAP_TEXT.video,
        pdf: 'PDF',
        word: 'Word',
      };
      const allowFiles = allowTypes.map((item) => fileDesc[item] || item).join('/');
      const allowText = allowDesc ? allowDesc : `1.支持 ${allowFiles} 文件`;
      return `${allowText}\n2.单个文件大小不超过${
        maxSize / 1024 / 1024
      }M,最多选择${maxCount}个文件`;
    },
    accept() {
      return this.fileExtension.map((item) => `.${item}`).join(',');
    },
  },
  methods: {
    toRemoveImg(e) {
      if (this.disabled) return;
      let index;
      if (typeof e === 'object') {
        index = this.fileArr.findIndex(({ url }) => e.url === url);
      } else {
        index = e;
      }
      const fileInfo = this.fileArr[index];
      const url = fileInfo.originName || fileInfo.url;
      if (this.coses && this.coses[url]) {
        const uploadObj = this.coses[url];
        this.cancelUploadTask({ formField: this.field.prop, ...uploadObj });
      }
      this.fileArr.splice(index, 1);
      this.fileArr = [...this.fileArr];
      this.$emit('change', this.fileArr);
    },
    // 预览图片/文件
    handlePreview(file) {
      const isOther = !['image', 'video', 'audio'].some((type) => {
        if (MINE_MAP[type].includes(file.type)) {
          this.$refs.preview.mutationOpen(file.url, type);
          return true;
        }
      });
      if (isOther) {
        const a = document.createElement('a');
        a.href = file.url;
        a.target = '_blank';
        a.click();
      }
    },
    getTypeText(type) {
      const typeKey = Object.keys(MINE_MAP).find((key) => {
        return MINE_MAP[key].includes(type);
      });
      return MINE_MAP_TEXT[typeKey] || type;
    },
    uploadToCos(file) {
      if (this.fileArr.length === this.uploadOpt.maxCount) {
        this.$message.error(this.uploadOpt.over_limit_tips);
        return;
      }
      // 文件上传至腾讯云cos
      const pattern = new RegExp(
        "[%--`~!@#$^&*()=|{}':;',\\[\\]<>/?~！@#￥……&*（）——| {}【】‘；：”“'。，、？]",
      );
      const fileName = file.file.name;
      let cleanName = '';
      for (let i = 0; i < fileName.length; i++) {
        cleanName = cleanName + fileName.substr(i, 1).replace(pattern, '');
      }
      this.fileName = cleanName;
      this.status = 'beforeUpload';
      this.uploadPercentage = 0;
      this.cancelingRequest = false;
      this.putCosObject(
        {
          imgCosKey: `${file.file.uid}${cleanName}`,
          fileObject: file.file,
          suffix: this.suffix,
        },
        undefined,
        (progress) => {
          this.fieldFileProgress = (progress.percent * 100).toFixed();
        },
      )
        .then((file) => {
          const {
            data: url,
            origin: { fileObject = {} },
          } = file || {};
          const { uid, name, size } = fileObject;
          if (size > 50 * 1024 * 1024) {
            this.$message.error('单个文件大小不超过50M');
            return;
          }
          this.fileArr.push({
            ...file,
            name,
            type: /.*\.(.*)/.exec(name)?.[1]?.toLowerCase(),
            url,
            uid,
            size,
            status: 'success',
          });
        })
        .catch((e) => {
          console.log(e);
          this.$message.error('上传失败');
        })
        .finally(() => {
          this.fieldFileProgress = 0;
          this.$emit('change', this.fileArr);
        });
    },
    uploadCheck(e) {
      if (this.actionBeforeAdd) {
        e.stopPropagation();
        this.m_doActionHandle(this.actionBeforeAdd).then((res) => {
          if (res !== false && this.canAddCount > 0)
            this.$refs.upload.$refs['upload-inner'].$refs.input.click();
        });
      }
      if (this.canAddCount <= 0) {
        e.stopPropagation();
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileArr = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
