// scripts/expression.js 会同时使用
// 注意不要require其他npm包，防止不能tree-shaking
const _ = {
  isString(str) {
    return Object.prototype.toString.call(str) === '[object String]';
  },
  isArray(arr) {
    return Array.isArray(arr);
  },
  isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  },
  keysIn(value) {
    const { hasOwnProperty } = Object.prototype;
    const result = [];
    for (const key in value) {
      if (hasOwnProperty.call(value, key)) {
        result.push(key);
      }
    }
    return result;
  },
};
const KEY_REG = /{{[^}]*}}/;
function genKey() {
  const t = 'xxxxxxxx';
  return t.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
// 包含所有jsons和json生成的key
let storeKeys = [];
// 递归，没办法获取所有的key，所以使用storeKeys
// 让当前生成的keys不重复
function genNewKey() {
  let tmpKey = genKey();
  while (storeKeys.includes(tmpKey)) {
    tmpKey = genKey();
  }
  storeKeys.push(tmpKey);
  return tmpKey;
}

function getKey(expressionStr = '') {
  // 会在表达式的头部，注入{{八位id}}
  const validExpressionStr = getValidExpression(expressionStr);
  const matchKey = validExpressionStr.match(KEY_REG);
  if (matchKey) {
    const key = getValidExpression(matchKey[0]).trim();
    if (key && !/[^\w]/g.test(key)) return key;
  }
  return '';
}

function generateKey(expressionStr = '') {
  // 先验证是否存在
  if (getKey(expressionStr)) return expressionStr;
  const validExpressionStr = getValidExpression(expressionStr);
  return `{{'{{${genNewKey()}}}'; ${validExpressionStr}}}`;
}

function isExpression(expressionStr = '') {
  return (
    Object.prototype.toString.call(expressionStr) === '[object String]'
    && expressionStr.startsWith('{{')
    && expressionStr.endsWith('}}')
  );
}

function getValidExpression(expressionStr = '') {
  return expressionStr.replace(/^{{/g, '').replace(/}}$/g, '');
}

function addExpressionJson(data) {
  let obj;
  if (_.isArray(data)) {
    obj = [];
    data.forEach((item) => {
      obj.push(addExpressionJson(item));
    });
  } else if (_.isObject(data)) {
    obj = {};
    _.keysIn(data).forEach((key) => {
      obj[key] = addExpressionJson(data[key]);
    });
  } else {
    // 不再具有下一层次
    if (isExpression(data)) {
      return generateKey(data);
    }
    return data;
  }
  return obj;
}

function findExpression(data) {
  let list = [];
  if (_.isArray(data)) {
    data.forEach((item) => {
      list = list.concat(findExpression(item));
    });
  } else if (_.isObject(data)) {
    _.keysIn(data).forEach((key) => {
      list = list.concat(findExpression(data[key]));
    });
  } else {
    // 不再具有下一层次
    isExpression(data) && list.push(data);
  }
  return list;
}

// 返回[{id,path,name,key:'',expression:''}]
// 如果有重复key，直接报错
// jsons=[{id,path,name,layout_json}]
function getExpressions(jsons) {
  const expressions = [];
  jsons.forEach((item) => {
    const list = findExpression(item.layout_json);
    if (list.length) {
      expressions.push({
        ...item,
        list,
      });
    }
  });
  const expressionkeys = [];
  const outList = [];
  const repeatkeys = [];
  const errorList = [];
  expressions.forEach((item) => {
    item.list.forEach((expression) => {
      const key = getKey(expression);
      const outItem = {
        id: item.id,
        name: item.name,
        path: item.path,
        key,
        expression,
      };
      if (key) {
        // 已经重复了，需要抛出错误，提醒修改
        if (expressionkeys.includes(key)) {
          if (!repeatkeys.includes(key)) repeatkeys.push(key);
        }
        expressionkeys.push(key);
      }
      outList.push(outItem);
    });
  });
  if (repeatkeys.length) {
    repeatkeys.forEach((key) => {
      errorList.push(
        ...outList
          .filter((item) => item.key === key)
          .map((item) => {
            return `${item.id}-${item.name}存在重复key：${item.key}-${item.expression}`;
          }),
      );
    });
    throw new Error([...errorList, `共${errorList.length}个存在重复key`].join('\n'));
  }
  return outList;
}

// 请将所有的json按照数组传递进来，会进行重复key校验
// jsons=[{id,path,name,layout_json}]
function getExpressionJson(json, jsons) {
  const expressionkeys = getExpressions(jsons)
    .map((item) => item.key)
    .filter((item) => item);
  storeKeys = [...storeKeys, expressionkeys];
  return addExpressionJson(json);
}

function getJsons(list, skipError = false) {
  const menuJson = [];
  list.forEach((menuItem) => {
    if (menuItem.layout_json) {
      try {
        menuJson.push({
          id: menuItem.id,
          path: menuItem.path,
          name: menuItem.name,
          code: menuItem.code,
          __is_layout_item__: menuItem.__is_layout_item__,
          layout_json: JSON.parse(menuItem.layout_json),
        });
      } catch (e) {
        console.error(
          'layout_json error:',
          `${menuItem.id}-${menuItem.name}`,
          menuItem.layout_json,
        );
        console.error('layout_json error:', e);
        if (!skipError) {
          throw e;
        }
      }
    }
    if (menuItem.children && menuItem.children.length) {
      menuItem.children.forEach((menuChild) => {
        if (menuChild.layout_json) {
          try {
            menuJson.push({
              id: menuChild.id,
              path: menuChild.path,
              name: menuChild.name,
              code: menuChild.code,
              __is_layout_item__: menuChild.__is_layout_item__,
              layout_json: JSON.parse(menuChild.layout_json),
            });
          } catch (e) {
            console.error(
              'layout_json error:',
              `${menuChild.id}-${menuChild.name}`,
              menuChild.layout_json,
            );
            console.error('layout_json error:', e);
            if (!skipError) {
              throw e;
            }
          }
        }
      });
    }
  });
  return menuJson;
}

const FUNCTION_PARAMS = [
  't',
  'p',
  'm',
  'json',
  'filters',
  'filter',
  '$index',
  '$item',
  'selected', // 动态禁用多选需要用这个
  '$value',
  '$data',
  '$row',
  'field',
  'value',
  'fieldClass',
  'getValue',
  'getFieldKey',
  'getWhereGqlStr',
  'formatTime',
  'formatSeconds',
  'log',
  '_',
  'dayjs',
  '$utils',
  'u',
  'globalFunc',
  '$hook',
  '$filtersValue',
  '$filters',
  '$sys',
];

module.exports = {
  KEY_REG,
  FUNCTION_PARAMS,
  getKey,
  isExpression,
  getValidExpression,
  getExpressions,
  getExpressionJson,
  getJsons,
};
