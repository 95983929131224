//
//
//
//
//
//
//
//

// import  jsonToXlsx  from '@/utils/xlsx.js'
export default {
  name: 'ExportExcel',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {},
  },
  data() {
    return {};
  },
  computed: {
    curDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const min = date.getMinutes();
      const sec = date.getSeconds();
      return `${year}年${month}月${day}日 ${hour}时${min}分${sec}秒`;
    },
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {},
};
