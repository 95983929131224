//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Clipboard from '../Clipboard';

export default {
  name: 'ImgPreviewDialog',
  components: { Clipboard },
  data() {
    return {
      url: [],
      index: 0,
      visible: false,
    };
  },
  computed: {
    imgCount() {
      return `${this.index + 1}/${this.url.length}`;
    },
  },
  methods: {
    open(url) {
      this.url = typeof url === 'string' ? [url] : url;
      this.index = 0;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    left() {
      if (this.index > 0) this.index -= 1;
    },
    right() {
      if (this.index < this.url.length - 1) this.index += 1;
    },
  },
};
