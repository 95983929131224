/* eslint-disable no-useless-escape */
// 发布名单接口
// 查看数据导入记录
const ImportRecordList = `query {   mcm_import_record(_order_by: { update_time: _desc},$pageOption) {     _aggregate { _count } ,

name ,
people,
count,
status,
import_batch,
create_time,
create_by{name}

}  }`;
// 数据导入详情
const ImportRecordDetail = `query {   mcm_publish_detail(import_batch : $import_batch ,$pageOption) {     _aggregate { _count } ,name ,hco_name,doctor_code ,department{name},major_title{name},hcp}  }`;
const publishList = `query {   mcm_publish_list(_order_by: { update_time: _desc},$pageOption,$filter)  {     _aggregate { _count } ,  name, id  } } `;
const publishInfo = `query {   mcm_publish_list(_order_by: { update_time: _desc}, _where:{_and:[{is_auto:{_eq:0}}]})  {  name, id } } `;
const publishDetail = `query {   mcm_publish_detail(list: $id, $pageOption ) {     _aggregate { _count } ,

name ,
hco_name,
doctor_code ,
department{name},
major_title{name},
id,
hcp

}  }`;
const DeleteRoster = `mutation delete {
  mcm_publish_list(id: $id) {
  id
  }
  }`;
const PlanSendCount = `query { mcm_publish_detail(list : $id){ _aggregate{ _count} } }`;
const RealSendCount = `query { mcm_publish_detail(list : $id){ hcp{ hcp_official(official_accounts:$app_id){ _aggregate{ _count},hcp } } } }`;

export {
  ImportRecordList,
  ImportRecordDetail,
  publishList,
  publishInfo,
  publishDetail,
  DeleteRoster,
  PlanSendCount,
  RealSendCount,
};
