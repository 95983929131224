import { getDynamicCommonReportParams, getStaticCommonReportParams } from '../common/utils';
import TrackingReport from '../report';
import { parseUrl } from '../../web-mobile/util';
import { includes } from 'lodash';

let GetAppConfig;
GetAppConfig = require('nges-common/src/web/utils/api').GetAppConfig;


export default function () {
  window.addEventListener(
    'message',
    async (event) => {
      const appConfig = await GetAppConfig();
      const iframeAllowHostStr = appConfig.page_config?.web_iframe_allow_host;
      const userEventTracking = appConfig?.page_config?.user_event_tracking === '1' || false;
      if (!userEventTracking) {
        return;
      }
      let iframeAllowHost;
      try {
        iframeAllowHost = JSON.parse(iframeAllowHostStr);
      } catch (e) {
        iframeAllowHost = [];
      }
      const { host } = parseUrl(event.origin);
      if (host != location.host && !includes(iframeAllowHost, host)) {
        return;
      }
      const eventData = event.data;
      if (eventData.type !== 'nges_tracking') {
        return;
      }
      const reportData = {
        ...getStaticCommonReportParams(),
        ...getDynamicCommonReportParams(),
        ...(eventData.params || {}),
      };
      TrackingReport.sendEvent(reportData);
    },
    false,
  );
}
