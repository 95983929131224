import request from '../../utils/request';

function noticeAction(action) {
  return new Promise((resolve, reject) => {
    const { action_params: params = {} } = action;

    const {
      // 数据id
      id = '',
      // 模版code
      code = '',
      // 收件人
      recipient_list,
      // 自定义变量
      custom_params: customParams = {},
    } = params;

    const varList = [];
    Object.keys(customParams).forEach((key) => {
      varList.push({
        key,
        value: customParams[key],
      });
    });

    const data = {
      template_code: code,
      subject_id: id,
      template_vars: varList,
      recipient_list,
    };

    request('MessagingServer/Messaging/SendMessaging', data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export { noticeAction };
