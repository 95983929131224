import { get, set, isNumber, isString, isFunction, isPlainObject } from 'lodash';
const memStore = {};

let increIdx = 0;

function setData(data, scopeId, key) {
  scopeId = scopeId ?? this.scopeId;
  if (isNumber(scopeId) || isString(scopeId)) {
    increIdx = increIdx + 1;
    const storeKey = `scopeId${scopeId}.id${key || increIdx}`;
    set(memStore, storeKey, data);
    if (data === null) {
      delete memStore[`scopeId${scopeId}`][`id${key || increIdx}`];
    }
    return String(increIdx);
  }
  return data;
}

function getData(key, scopeId) {
  scopeId = scopeId ?? this.scopeId;
  if ((isNumber(key) || isString(key)) && (isNumber(scopeId) || isString(scopeId))) {
    return get(memStore, `scopeId${scopeId}.id${key}`);
  }
  if (isFunction(key)) {
    return key();
  }
  return key;
}

function clearData(scopeId) {
  if (isNumber(scopeId) || isString(scopeId)) {
    const scopeObj = memStore[`scopeId${scopeId}`];
    if (isPlainObject(scopeObj)) {
      Object.keys(scopeObj).forEach((key) => {
        scopeObj[key] = null;
      });
    }
    memStore[`scopeId${scopeId}`] = undefined;
    delete memStore[`scopeId${scopeId}`];
  }
}

// setInterval(()=>{
//   console.log(memStore);
// }, 5000)

export default {
  set: setData,
  get: getData,
  clear: clearData,
  handler: {
    memStoreSet: setData,
    memStoreGet: getData,
    memStoreClear: clearData,
  },
};
