import { GetPrivateObjUrl } from '../../../api.js';
import { cosAppId } from '../../config';
// function downloadAction({ action_params: actionParams, action: actionName }) {
//   return new Promise((resolve) => {
//     const msg = actionName.slice(4);
//     this.$message &&
//       this.$message({
//         type: actionParams?.type || '',
//         message: msg,
//       });
//     resolve();
//   });
// }

function saveAs(blob, fileName) {
  const link = document.createElement('a');
  if ('download' in link) {
    link.href = window.URL.createObjectURL(blob);
    link.style.display = 'none';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    // 对于不支持 download 属性的浏览器（如 IE），使用 window.navigator.msSaveOrOpenBlob 函数
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  }
}

async function downloadAction(action) {
  const { onprogress, asyncDownloadBigFile } = action;
  const { action_params: actionParams } = action;
  const { url, name = null, private_bucket: privateBucket } = actionParams;
  let downloadUrl = url;
  if (privateBucket) {
    const res = await GetPrivateObjUrl({
      app_id: cosAppId,
      url, // cdnUrl 地址
      expires: 300, // url有效时间，单位秒，默认60秒
    });
    downloadUrl = res?.data?.url;
  }
  return new Promise(async (resolve, reject) => {
    // const urltemp =
    //   'https://tsep-test-1304685099.cos.ap-guangzhou.myqcloud.com/tsep_dev/11501202/2fbd5f8d-ecae-471c-8856-d53624e06e72';
    const url2 = downloadUrl.replace(/\\/g, '/');
    // return;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url2, true);
    xhr.responseType = 'blob';
    // xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
    // 为了避免大文件影响用户体验，建议加loading
    xhr.onload = () => {
      if (xhr.status === 200) {
        // 获取文件blob数据并保存
        saveAs(new Blob([xhr.response]), name);
        resolve(true);
        return;
      }
      reject(false);
    };
    xhr.onprogress = (progress) => {
      /**
       * 对于大于100m的文件采用window.open打开，避免出现内存溢出问题，当然该url也可能不是文件流，这个时候就会直接打开文件，而不是下载
       * 可以优化大文件下载流程，譬如借助indexdb做断点下载或者server-worker做后台下载，都比较复杂，建议后续有需要建需求完善
       */
      if (asyncDownloadBigFile && progress.total > 100 * 1024 * 1024) {
        xhr.abort();
        window.open(url2);
        return resolve(true);
      }
      onprogress && onprogress(progress);
    };
    xhr.send();
  });
}

export { downloadAction };
