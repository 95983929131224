//
//
//
//
//
//
//
//
//
//
//
//
//

import xss from 'xss';
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      html: '',
      previewImgSrc: '',
    };
  },
  mounted() {
    // 处理纯文本的情况
    if (!this.content.includes('<p>') && !this.content.includes('</p>')) {
      this.html = this.content.replaceAll('\n', '<br/>');
    } else {
      const aXss = new xss.FilterXSS({
        onTagAttr: (tag, name, value) => {
          if (tag === 'a' && name === 'href') {
            return `href="${value}"`;
          }
          if (name === 'style') {
            return `style="${value}"`;
          }
        },
      });
      this.html = aXss.process(this.content);
    }
  },
  methods: {
    goto(link) {
      const webReg = /\[\(web\)(.*?)\]/g;
      const result = webReg.exec(link);
      if (result) {
        this.g_tourl(result[1], { open: true });
      }
    },
    previewImg(src) {
      this.previewImgSrc = src;
      this.$nextTick(() => {
        this.$refs.image?.clickHandler?.();
      });
    },
    click(e) {
      if (e.target.nodeName === 'A') {
        this.goto(e.target.attributes.href.value);
      }
      if (e.target.nodeName === 'IMG') {
        this.previewImg(e.target.attributes.src.value);
      }
    },
  },
};
