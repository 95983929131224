export const OutsideQuestionHtmlTag = 'p';

// quill空字符串,用于contenteditable=false选中
const GUARD_TEXT = '\uFEFF';

// 外部问卷正则
export const OutsideQuestionHtmlRegex =
  /<p class="nges-outside-questions" type="nges-outside-questions" .*?<\/p>/g;

export function outsideQuestionHtml(item, node) {
  const questionnaireName = item?.questionnaire_name || '';
  if (!questionnaireName) {
    return '';
  }

  try {
    const isOldEditor = !!node;
    if (!node) {
      // 新编辑器
      node = document.createElement(OutsideQuestionHtmlTag);
      const leftGuard = document.createTextNode(GUARD_TEXT);
      const rightGuard = document.createTextNode(GUARD_TEXT);

      const contentNode = document.createElement('span');
      contentNode.setAttribute('contenteditable', 'false');
      contentNode.innerHTML = `[${questionnaireName}]`;
      node.appendChild(leftGuard);
      node.appendChild(contentNode);
      node.appendChild(rightGuard);
      // GUARD_TEXT防止回车后,编辑器默认引入上一行样式, 然后用户手动输入[问卷], 导致识别为问卷
      // node.innerText = `[${GUARD_TEXT}${questionnaireName}${GUARD_TEXT}]`;
    } else {
      // 老编辑器(用embed 会自动加上面的代码)
      node.innerText = `[${questionnaireName}] `; // 加个空格
    }
    // 这两个顺序请不要变化 (正则相关)
    // 正则匹配到type, 如果修改, 请往后面追加参数, 或者修改 管理端 移动端正则匹配
    // <p class="nges-outside-questions" type="nges-outside-questions"
    node.setAttribute('class', 'nges-outside-questions'); // 管理端显示样式
    node.setAttribute('type', 'nges-outside-questions');

    // 动态参数
    if (!isOldEditor) {
      // node.setAttribute('contenteditable', 'false'); // 暂时不加, 编辑光标有问题
    }
    // node.setAttribute('mate_id', item.id || '');
    // node.setAttribute('name', questionnaireName);
    node.setAttribute('q_data_info', JSON.stringify(item));
    // node.setAttribute('code', item.questionnaire_code);
    // const timestamp = item.validity_time ? new Date(item.validity_time).getTime() : 0;
    // if (timestamp) {
    //   node.setAttribute('time', timestamp);
    // }
  } catch (error) {
    console.log(error);
  }

  return node.outerHTML;
}

export function GetQutsideQuestionId(url) {
  if (!url) {
    return '';
  }
  const list = url.split('/');
  const id = list[list.length - 1];
  return id || '';
}
