//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      html: '',
    };
  },
  watch: {
    value(v) {
      // console.log('!');
      if (v !== this.html) {
        // this.$refs['ui-editor'].innerHTML = v;
        // console.log(v);
        if (v) {
          this.$refs['ui-editor'].contentWindow.document.write(v);
        } else {
          this.$refs['ui-editor'].contentWindow.document.write(`<body></body>`);
        }
        this.$refs['ui-editor'].contentWindow.document.body.contentEditable = 'true';
        this.$refs['ui-editor'].contentWindow.document.body.addEventListener('paste', this.paste);
        this.$refs['ui-editor'].contentWindow.document.close();
        this.html = v;
      }
    },
  },
  mounted() {
    const v = this.value;
    if (v) {
      this.$refs['ui-editor'].contentWindow.document.write(v);
    } else {
      this.$refs['ui-editor'].contentWindow.document.write(`<body></body>`);
    }
    this.$refs['ui-editor'].contentWindow.document.body.contentEditable = 'true';
    this.$refs['ui-editor'].contentWindow.document.body.addEventListener('paste', this.paste);
    // this.$refs['ui-editor'].contentWindow.document.body.addEventListener('input', this.input);
    this.$refs['ui-editor'].contentWindow.document.close();
    this.html = v;
  },
  methods: {
    paste(e) {
      // console.log('?');
      // e.preventDefault();
      const html = e.clipboardData.getData('text/html');
      // this.$refs['ui-editor'].srcdoc += html;
      this.html = html;
      setImmediate(() => {
        this.input();
      });
    },
    input() {
      // console.log('??');
      this.$emit('input', this.$refs['ui-editor'].contentWindow.document.body.innerHTML);
    },
    out() {},
  },
};
