// 媒体相关接口
import {
  InsertMaterial,
  listMaterial,
  EditMaterial,
  DelMaterial,
  listMaterialOfficial,
  InsertVideo,
  UploadDocGql,
  GetDocListGql,
  RenameDocGql,
  GetDocTypeListGql,
  DelDocGql,
  addCountDocGql,
  insertLogGql,
  GetArticleGql,
  queryHasVideoGql,
  AddMaterialValidScope,
  QueryMaterialValidScope,
  UpdateMaterialValidScope,
  DeleteMaterialValidScope,
  RelatedMaterials,
  RelatedDocuments,
  QueryImageByIdList,
} from '@/graphql/gql/material';
import { calVariables, request } from '@/graphql/util';
import { DataService } from 'nges-common/src/web/utils/api';

export function queryImageByIdList(data) {
  return DataService({
    query: calVariables(QueryImageByIdList, data),
  });
}

// 插入素材有效期
export function insertMaterialValidScope(data) {
  return DataService({
    query: calVariables(AddMaterialValidScope, data),
  });
}
// 查询素材有效期
export function queryMaterialValidScope(data) {
  return DataService({
    query: calVariables(QueryMaterialValidScope, data),
  });
}

export function updateMaterialValidScope(data) {
  return DataService({
    query: calVariables(UpdateMaterialValidScope, data),
  });
}
export function deleteMaterialValidScope(data) {
  return DataService({
    query: calVariables(DeleteMaterialValidScope, data),
  });
}

// 插入媒体图片
export async function queryInsertMaterial(data, otherData) {
  let otherKeys = '';
  if (otherData && Object.keys(otherData).length) {
    Object.keys(otherData).forEach((key) => {
      otherKeys += `, ${key}: $${key}`;
    });
    data = { ...data, ...otherData };
  }

  console.log(data);
  const res = await DataService({
    query: calVariables(InsertMaterial.replace('$otherKeys', otherKeys), data),
  });
  const materialId = res?.mcm_material?.[0]?.id;
  if (materialId) {
    const logData = {
      id: materialId,
      type: 4,
      record_type: data.record_type,
      target_info: {
        name: data.name,
        type: data.type,
        record_type: data.record_type,
        url: data.url,
        material_official: data.app_id,
      },
    };
    insertLog(logData);
  }
  return res;
}

// 获取所有媒体图片
export function querylistMaterial(data) {
  return DataService({
    query: calVariables(listMaterial, data),
  });
}

// 根据公众号筛选媒体图片
export function querylistMaterialOfficial(data) {
  return DataService({
    query: calVariables(listMaterialOfficial, data),
  });
}

// 素材删除
export function queryDelMaterial(data) {
  return DataService({
    query: calVariables(DelMaterial, data),
  });
}

// 素材编辑
export function queryEditMaterial(data) {
  return DataService({
    query: calVariables(EditMaterial, data),
  });
}

// 插入视频
export async function queryInsertVideo(data) {
  const res = await DataService({
    query: calVariables(InsertVideo, data),
  });

  const materialId = res?.mcm_material?.[0]?.id;
  if (materialId) {
    const logData = {
      id: materialId,
      type: 4,
      record_type: data.record_type,
      target_info: {
        name: data.name,
        type: data.type,
        record_type: data.record_type,
        url: data.url,
        remark: data.remark,
        material_official: data.app_id,
      },
    };
    insertLog(logData);
  }
  return res;
}

export function UploadDoc(data) {
  return DataService({
    query: calVariables(UploadDocGql, data),
  });
}

export function GetDocList(data) {
  return DataService({
    query: calVariables(GetDocListGql(data), data),
  });
}

export function GetArticle(data) {
  return DataService({
    query: calVariables(GetArticleGql, data),
  });
}

export function RenameDoc(data) {
  return DataService({
    query: calVariables(RenameDocGql, data),
  });
}

export function GetDocTypeList(data) {
  return DataService({
    query: calVariables(GetDocTypeListGql, data),
  });
}

export function DelDoc(data) {
  return DataService({
    query: calVariables(DelDocGql, data),
  });
}

export function addCountDoc(data) {
  return DataService({
    query: calVariables(addCountDocGql, data),
  });
}

// 插入日志 logType: doc/ material
export function insertLog(data) {
  if (!data?.id || !data?.record_type) return Promise.resolve();
  if (!data.source_info) data.source_info = '{}';
  else if (typeof data.source_info !== 'string')
    data.source_info = JSON.stringify(data.source_info)
      .replaceAll('\\', '\\\\')
      .replaceAll('"', '\\"');
  if (!data.target_info) data.target_info = '{}';
  else if (typeof data.target_info !== 'string')
    data.target_info = JSON.stringify(data.target_info)
      .replaceAll('\\', '\\\\')
      .replaceAll('"', '\\"');
  if (data.channel === undefined) data.channel = 3;
  if (data.record_type === 'document') {
    data.doc_id = data.id;
    data.material_id = '';
  } else {
    data.doc_id = '';
    data.material_id = data.id;
  }

  return DataService({
    query: calVariables(insertLogGql, data),
  });
}

/* 下载文档 */
export const DownloadDoc = (data) => request('ApiServer/Cos/GetPrivateObjUrl', data);

export function queryHasVideo(data) {
  return DataService({
    query: calVariables(queryHasVideoGql, data),
  });
}

// 查询关联的素材库 23R4 SP2
export function queryRelatedMaterials(data, otherKeys = '') {
  return DataService({
    query: calVariables(RelatedMaterials.replace('$otherKeys', otherKeys), data),
  });
}
// 查询关联文档 23R4 SP2
export function queryRelatedDocuments(data, otherKeys = '') {
  return DataService({
    query: calVariables(RelatedDocuments.replace('$otherKeys', otherKeys), data),
  });
}
