//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    linkList: {
      type: Array,
      default() {
        return [];
      },
    },
    separator: {
      type: String,
      default: '|',
    },
  },
};
