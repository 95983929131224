//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';

export default {
  name: 'Input',
  props: {
    filterObj: {
      type: Object,
      default: () => {},
    },
    formValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      comfilterObj: '',
    };
  },
  computed: {
    style() {
      return this.comfilterObj.filter.style || {};
    },
    filter() {
      const { filter = {} } = this.comfilterObj || {};
      return filter;
    },
    minlength() {
      return this.filterObj?.filter?.jsonFilter?.minlength;
    },
    maxlength() {
      return this.filterObj?.filter?.jsonFilter?.maxlength;
    },
    disabled() {
      return this.filterObj?.filter?.jsonFilter?.disabled;
    },
  },
  watch: {
    filterObj(val) {
      this.comfilterObj = val;
    },
  },
  created() {
    this.comfilterObj = this.filterObj;
  },
  methods: {
    handleInput: _.debounce(function (e) {
      const { prop } = this.comfilterObj;
      console.log(e);
      this.$emit('change', { prop, value: e });
    }, 200),
  },
};
