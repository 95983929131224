//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Sortable from 'sortablejs';
import { cloneDeep, isArray, includes } from 'lodash';
import LocalStore from './localStore';

export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    json: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      visible: false,
      labelList: [],
      checkedList: [],
      checkAll: false,
      isIndeterminate: false,
      loading: false,
    };
  },
  watch: {
    checkedList() {
      this.handleCheckedChange();
    },
  },
  methods: {
    open() {
      this.visible = true;
      this.loading = false;
      this.init();
    },
    closeHandler() {
      this.visible = false;
      this.labelList = [];
      this.checkedList = [];
    },
    async init() {
      const labelList = [];
      (this.columns || []).forEach((item) => {
        item && item !== '操作' && labelList.push(item);
      });
      const orderCols = await LocalStore.getColsOrder(this.json);
      let checkedList = orderCols?.checkedList || [];
      const hiddenList = orderCols?.hiddenList || [];
      checkedList = isArray(checkedList) ? checkedList : [];
      checkedList = checkedList.filter((item) => {
        return includes(labelList, item);
      });
      if (!checkedList.length) {
        checkedList = cloneDeep(labelList);
      } else {
        // 插入不在选中列表也不在隐藏列表的label
        checkedList = checkedList.concat(
          labelList.filter((item) => {
            return !includes(checkedList, item) && !includes(hiddenList, item);
          }),
        );
      }
      this.labelList = labelList;
      this.checkedList = checkedList;
      this.$nextTick(() => {
        this.sortableHandle();
      });
    },
    sortableHandle() {
      const wrap = this.$refs.dialog.$el.querySelector('.sort-wrap');
      new Sortable(wrap, {
        animation: 150,
        handle: '.drag-btn',
        onEnd: (e) => {
          const { checkedList } = this;
          const tmp = checkedList.splice(e.oldIndex, 1)[0];
          checkedList.splice(e.newIndex, 0, tmp);
        },
      });
    },
    removeHandle(idx) {
      this.checkedList.splice(idx, 1);
    },
    handleCheckAllChange(val) {
      this.checkedList = val ? cloneDeep(this.labelList) : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange() {
      const checkedCount = this.checkedList.length;
      this.checkAll = checkedCount === this.labelList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.labelList.length;
    },
    /*
     * 如果全选，且顺序一致，则不需要做任何保存动作，直接清除
     * 否则存入localstorage
     * */
    async confirm() {
      if (this.loading) return;
      this.loading = true;
      const { checkedList } = this;
      const { labelList } = this;
      let isDiff = false;
      if (!checkedList.length) {
        this.$message.error('自定义列表显示字段不能为空');
        this.loading = false;
        return;
      }
      if (checkedList.length !== labelList.length) {
        isDiff = !!checkedList.length; // 若没有选择任何一个，则更全选一样
      } else {
        for (let i = 0; i < checkedList.length; i++) {
          if (labelList[i] !== checkedList[i]) {
            isDiff = true;
            break;
          }
        }
      }
      if (!isDiff) {
        await LocalStore.removeColsOrder(this.json);
      } else {
        const hiddenList = labelList.filter((item) => {
          return !includes(checkedList, item);
        });
        await LocalStore.setColsOrder(checkedList, hiddenList, this.json);
      }
      this.closeHandler();
      this.$emit('col-config-save');
    },
  },
};
