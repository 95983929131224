//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  EyaoSaaSSelectMaterialApi,
  EyaoSaaSSelectMaterialOfficialApi,
} from '@/api/releaseManagement';
// import tagSelect from './tagSelect';
// import tagSelectAgent from '@/components/tagSelectAgent';
import materialFilter from '@/components/materialFilter.vue';
import { whereGql, pageGql } from '@/graphql/util';
export default {
  components: { materialFilter },
  props: {
    type: {
      type: String,
      default: 'public',
    },
    selectImgclose: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      total: 1, // 总条数
      size: 18, // 每页数量
      currentPage: 1, // 页数
      imgloading: false, // loading
      datalist: [], // 获取图片数组
      sortList: [], // 勾选为true的数组
      Imglist: '', // 图片列表
      file_type: 1,
      agentIds: [],
      // 判断库内初始数据是否是空，如果是空的话 则隐藏筛选框
      isDataEmpty: true,
      // 素材筛选相关
      filterVals: {},
    };
  },
  mounted() {
    this.resetFilters();
    this.getTaleData();
  },
  methods: {
    resetFilters() {
      this.filterVals = {
        // 素材名称筛选输入值
        name: '',
        // 选择文章发布公众号结果
        publicVals: [],
        // 选择产品值
        productVals: [],
        // 选择新标签值
        tagVals: [],
      };
    },
    emitMaterialFilter(data) {
      this.filterVals = data;
      this.getTaleData(1);
    },
    // 图片选择
    changeSelect(i, index) {
      if (i.effective_status === 2) return;
      this.datalist = this.datalist.map((item, itemIndex) => {
        const obj = item;
        if (itemIndex === index && obj.pic_net_url === i.pic_net_url) {
          obj.select = true;
        } else {
          obj.select = false;
        }
        return obj;
      });
      this.sortList = this.datalist.filter((item) => item.select === true);
    },
    // 查询素材库列表
    async getTaleData(
      page,
      query = {
        page: this.currentPage,
        limit: this.size,
        file_type: this.file_type,
      },
    ) {
      this.imgloading = true;
      if (page) {
        query.page = page;
        this.setCurrentPage = page;
      }
      const { name, publicVals, productVals, tagVals } = this.filterVals;
      const whereScope = publicVals?.length > 0 ? 'material.' : '';
      const whereGqlArr = [
        {
          [`${whereScope}name`]: `%${name}%`,
          whereGqlType: 'like',
        },
        {
          [`${whereScope}id`]: 'false',
          whereGqlType: 'is_null',
        },
      ];
      if (productVals?.length > 0) {
        whereGqlArr.push({
          [`${whereScope}product_ids`]: productVals,
          whereGqlType: 'include_any',
        });
      }
      if (tagVals?.length > 0) {
        whereGqlArr.push({
          [`${whereScope}tag_ids`]: tagVals,
          whereGqlType: 'include_any',
        });
      }

      const param = {
        pageOption: pageGql(query.page, query.limit),
        app_id: publicVals,
        record_type: 'image',
        data_source: 'ADMIN',
        filter: whereGql(whereGqlArr),
      };
      const res = await (publicVals.length > 0
        ? EyaoSaaSSelectMaterialOfficialApi
        : EyaoSaaSSelectMaterialApi)(param);
      if (publicVals.length) {
        const dataList = (res.mcm_material_official._data || []).map((item) => item.material);
        for (let i = 0; i < dataList.length; i++) {
          const e = dataList[i];
          e.select = false;
          e.cos_file_url = e.url;
          e.operator_name = e.title;
        }
        this.datalist = dataList;
        this.total = res.mcm_material_official._aggregate.count;
        if (this.sortList.length > 0) {
          this.datalist.map((item) => {
            this.sortList.forEach((sortItem) => {
              if (sortItem.id === item.id) {
                item.select = true;
              }
            });
            return item;
          });
        }
        this.imgloading = false;
      } else {
        for (let i = 0; i < (res.mcm_material._data || []).length; i++) {
          const e = res.mcm_material._data[i];
          e.select = false;
          e.cos_file_url = e.url;
          e.operator_name = e.title;
        }
        this.datalist = res.mcm_material._data || [];
        this.total = res.mcm_material._aggregate.count;
        if (this.sortList.length > 0) {
          this.datalist.map((item) => {
            this.sortList.forEach((sortItem) => {
              if (sortItem.id === item.id) {
                item.select = true;
              }
            });
            return item;
          });
        }
        this.imgloading = false;
      }

      // 控制筛选框隐藏
      if (this.isDataEmpty && this.datalist?.length > 0) {
        this.isDataEmpty = false;
      }
    },
    // 上传图片
    async uploadImg() {
      this.g_tourl(`/saas/materialManagement/material-img-upload.html`, {
        open: true,
      });
    },
    // 确定上传
    confirm() {
      this.selectImgclose();
      this.$emit('selectimgDatalist', this.sortList);
    },
    // 页面改变事件
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTaleData();
    },
    out() {
      this.resetFilters();
      this.sortList = [];
      this.selectImgclose();
    },
  },
};
