//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    listNodes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDialog: false,
      approTypeInfo: {
        1: '或签',
        2: '会签',
      },
      selectedId: null, // 选择的id
    };
  },
  computed: {},
  methods: {
    openDialog() {
      this.selectedId = null;
      this.showDialog = true;
    },
    handleClose() {
      this.showDialog = false;
    },
    save() {
      this.showDialog = false;
      this.$emit('confirm', this.selectedId);
    },
    handleSelectionChange(row) {
      this.selectedId = row.key;
    },
  },
};
