//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex';
import Mutation from '../mutation';
import Action from '../../action';
import { getLayoutJson, getNewLayoutJson } from '../../../../layout/queryWeb';
import { get, set, endsWith, cloneDeep, has, isObject, isNil } from 'lodash';
import { getFieldLabel, getFieldClass } from 'nges-common/src/layout/util';
import { PROP_LABEL, PROP_CLASS } from 'nges-common/src/constant';

const LIST_LAYOUT_KEY = 'list-layout';
const CREATE_LAYOUT_KEY = 'create-layout';
const EDIT_LAYOUT_KEY = 'edit-layout';
const DETAIL_LAYOUT_KEY = 'detail-layout';

export default {
  name: 'LayoutFullList',
  components: {
    List: () => import('../list'),
    Mutation,
    Detail: () => import('../detail/components/detail'),
    MixinTab: () => import('../mixin-tab'),
  },
  mixins: [Action],
  props: {
    beforeSubmit: {
      type: Function,
      default: null,
    },
    lifeCycle: {
      type: Object,
    },
    json: {
      type: Object,
      default: null,
    },
    layout: {
      type: String,
      default: '',
    },
    /* 编辑布局和详情布局额外参数 */
    mutationLayout: {
      type: String,
      default: '',
    },
    detailLayout: {
      type: String,
      default: '',
    },
    detailParams: {
      type: Object,
      default() {
        return {};
      },
    },
    // 组件的额外参数，可以在表达式中通过p访问
    params: {
      type: [Array, String, Number, Object, null],
      default() {
        return null;
      },
    },
    parentData: {
      type: [Object, null],
      default: () => null,
    },
    forceEmitAction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('user', {
      menuList: 'menuList',
    }),
    ...mapGetters('user', {
      layoutJson: 'getLayoutJson',
    }),
    listJson() {
      return this.getJson(this.layout || LIST_LAYOUT_KEY);
    },
    listTitle() {
      return this.listJson?.title || '-';
    },
  },
  data() {
    return {
      targetId: '',
      detailJson: null,
      mutationJson: null,
      mutationFormData: null,
      mutationParentData: {},
    };
  },
  methods: {
    initSearch(payload) {
      this.$refs.list.initSearch(payload);
    },
    getJson(key, path) {
      let json = this.json ? this.json : this.layoutJson;
      // 若带了path则去path取对应的布局。
      if (path) {
        json = getLayoutJson(this.menuList, path) || json;
      }
      return get(json, key) || json || null;
    },
    setRenderType(json, action) {
      if (endsWith(action, '_DRAWER')) {
        set(json, 'render.type', 'drawer');
      } else if (endsWith(action, '_DIALOG')) {
        set(json, 'render.type', 'dialog');
      }
      return json;
    },
    async m_customAction(action) {
      const { id, path, key, object, record_type: recordType } = action?.action_params || {};
      switch (action?.action) {
        case 'CREATE':
        case 'TO_CREATE':
        case 'TO_CREATE_DRAWER':
        case 'TO_CREATE_DIALOG':
          setTimeout(async () => {
            if (!action.__process__.processed) {
              let mutationJson = cloneDeep(
                this.getJson(key || this.mutationLayout || CREATE_LAYOUT_KEY, path),
              );
              if (object) {
                mutationJson = await getNewLayoutJson({
                  object,
                  layoutType: 'create',
                  recordType,
                });
              }
              this.mutationJson = this.setRenderType(mutationJson, action.action);
              this.mutationParentData = {
                t: {
                  p: this.parentData,
                },
              };
              this.$nextTick(() => {
                this.$refs.mutation.open({
                  listRef: this.$refs.list,
                  id,
                });
              });
            }
          });
          break;
        case 'EDIT':
        case 'TO_EDIT':
        case 'TO_EDIT_DRAWER':
        case 'TO_EDIT_DIALOG':
          setTimeout(async () => {
            if (!action.__process__.processed) {
              let mutationJson = cloneDeep(
                this.getJson(key || this.mutationLayout || EDIT_LAYOUT_KEY, path),
              );
              if (object) {
                mutationJson = await getNewLayoutJson({
                  object,
                  layoutType: 'edit',
                  recordType,
                });
              }
              this.mutationJson = this.setRenderType(mutationJson, action.action);
              this.$nextTick(() => {
                const {
                  _item: item,
                  _index: index,
                  _pageIndex: pageIndex,
                  _pageSize: pageSize,
                } = action?.action_params || {};
                const mutationProp = this.getMutationProp({ id, item, action });
                const listIndex
                  = isNil(pageIndex) || isNil(pageSize) ? index : (pageIndex - 1) * pageSize + index;
                this.mutationParentData = {
                  $item: this.formatItem({ item }),
                  $index: listIndex,
                  t: {
                    p: this.parentData,
                  },
                };
                this.$refs.mutation.open(mutationProp);
              });
            }
          });
          break;
        case 'DETAIL':
        case 'TO_DETAIL':
        case 'TO_DETAIL_DRAWER':
        case 'TO_DETAIL_DIALOG':
          setTimeout(() => {
            if (!action.__process__.processed) {
              this.targetId = '';
              this.$nextTick(async () => {
                this.targetId = id;
                let detailJson = cloneDeep(
                  this.getJson(key || this.detailLayout || DETAIL_LAYOUT_KEY, path),
                );
                if (object) {
                  detailJson = await getNewLayoutJson({
                    object,
                    layoutType: 'detail',
                    recordType,
                  });
                }
                this.detailJson = this.setRenderType(detailJson, action.action);
                this.$nextTick(() => {
                  setTimeout(() => {
                    this.$refs.detail.open();
                  });
                });
              });
            }
          });
          break;
        default:
          break;
      }
    },
    getMutationProp({ id, item, action }) {
      const mutationProp = {
        listRef: this.$refs.list,
      };
      const { edit_type: editType = 'USE_ID' } = action?.action_params || {};
      this.mutationFormData = null;
      switch (editType) {
        // 使用行数据初始化编辑布局
        case 'USE_LOCAL': {
          this.mutationFormData = this.formatItem({ item });
          break;
        }
        // 使用行数据自定义初始化编辑布局，通过default_value和default_data来初始化，数据塞进表达式的t.p里
        case 'USE_CUSTOM': {
          break;
        }
        // 默认使用id查询详情初始化
        case 'USE_ID':
        default: {
          mutationProp.id = id;
          break;
        }
      }
      return mutationProp;
    },
    // 透传列表方法
    setOtherConditionStr(val) {
      this.$refs.list?.setOtherConditionStr(val);
    },
    filterOnSubmit() {
      this.$refs.list?.filterOnSubmit();
    },
    async getTableData(
      isFirstPage,
      isStatic = false,
      once = false,
      disableFilterStaticData = false,
    ) {
      await this.$refs.list?.getTableData(isFirstPage, isStatic, once, disableFilterStaticData);
    },
    hideFilter() {
      this.$refs.list?.hideFilter();
    },
    resetAll() {
      this.$refs.list?.resetAll();
    },
    resetAllFilter() {
      this.$refs.list?.resetAllFilter();
    },
    handleSelectionChange(val) {
      this.$emit('selection-change', val);
    },
    clearSelection() {
      this.$refs.list.clearSelection();
    },
    searchOnSubmit(force) {
      this.$refs.list.searchOnSubmit(force);
    },
    setSearchInput(kw) {
      this.$refs.list.setSearchInput(kw);
    },
    getListRef() {
      return this.$refs.list;
    },
    layoutActionHandle(action) {
      this.actionHandle(action);
      const isDone = get(action, '__process__.processed');
      if (!isDone) {
        switch (action.action) {
          case 'RELOAD': {
            this.getTableData();
            set(action, '__process__.processed', true);
            break;
          }
          case 'DETAIL_CLOSE': {
            if (this.$refs.detail) {
              this.$refs.detail.m_customAction(action);
              set(action, '__process__.processed', true);
            }
            break;
          }
        }
      }
    },
    mutationActionHandle(action) {
      // 删除json，不然再aciton中算表达式会报错
      this.deletePropertiesRecursively({ obj: action, removeProps: ['json'] });
      this.layoutActionHandle(action);
    },
    detailActionHandle(action) {
      this.layoutActionHandle(action);
    },
    async actionHandle(action) {
      await this.m_actionHandle(action, !this.forceEmitAction); // 此处暂不抛出，由tab抛出事件
      this.$refs?.tab?.m_actionHandle(action);
    },
    deletePropertiesRecursively({ obj, removeProps = [] }) {
      if (obj === null || typeof obj !== 'object') {
        // 不是对象或数组，直接返回
        return;
      }

      Object.keys(obj).forEach((key) => {
        // 如果属性是要删除的属性之一，则删除它
        if (removeProps.includes(key)) {
          delete obj[key];
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          // 如果属性值是对象或数组，递归调用
          this.deletePropertiesRecursively({ obj: obj[key], removeProps });
        }
      });
    },
    tabActionHandleEmit(action) {
      this.$emit('action-handle', action);
    },
    // item的label补全
    formatItem({ item = {} }) {
      // 将对象属性中的label补上
      const newItem = Object.keys(item).reduce((pre, fieldKey) => {
        const label = getFieldLabel(item, fieldKey);
        if (label !== item[fieldKey] && !isObject(item[fieldKey])) {
          pre[fieldKey] = {
            label,
            value: has(item[fieldKey], 'value') ? item[fieldKey].value : item[fieldKey],
            [`${fieldKey}${PROP_LABEL}`]: label,
            [`${fieldKey}${PROP_CLASS}`]: getFieldClass(item, fieldKey),
          };
        } else {
          pre[fieldKey] = item[fieldKey];
        }
        return pre;
      }, {});
      return newItem;
    },
    getTabSelectCount(customCondition, customCmdCondition) {
      this.$refs.list.getTabSelectCount(customCondition, customCmdCondition);
    },
  },
};
