import { query } from '../../../utils/util';
import { set, isPlainObject, get, keys, unset } from 'lodash';
import IndexedDBStore from '../../../utils/indexedDBStore';

const HEADER_WIDTH_KEY = 'header_width';
const HEADER_ORDER = 'header_order';
const HEADER_HIDDEN = 'header_hidden';
const EXPIRE_SECONDS = 86400 * 365; // 默认一年过期

const INDEXED_DB_NAME = 'nges_layout_db';
const TABLE_NAME = 'layout_list_config';
const FILTERS_DATA = 'filter_data';
// 初始化indexedDB用来存储
const IndexedDbInst = new IndexedDBStore(INDEXED_DB_NAME, TABLE_NAME, '&key, config, expired_time');

function getLayoutStoreKey(json) {
  const jsonKey = json?.render?.store_key || json.cmd || json.object;
  const path = location.pathname;
  const queryPath = query('path');
  const queryObject = query('object');
  return `LAYOUT--${path}--${jsonKey}--${queryPath}--${queryObject}`;
}

function getExpiredTime() {
  return new Date().getTime() + EXPIRE_SECONDS * 1000;
}

async function getLayoutStoreByKey(key) {
  const data = await IndexedDbInst.get('key', key);
  if (isPlainObject(data)) {
    set(data, 'expired_time', getExpiredTime());
    IndexedDbInst.upsert(data); // 使用到的时候，自动将其延期
    return data;
  }
  return {};
}

async function getLayoutKeyAndConfig(json) {
  const key = getLayoutStoreKey(json);
  const store = await getLayoutStoreByKey(key);
  let { config } = store;
  try {
    config = JSON.parse(config);
  } catch (e) {
    config = {};
  }
  return { key, config };
}

async function setColWidth(width, column, json) {
  const { key, config } = await getLayoutKeyAndConfig(json);
  if (column?.label) {
    set(config, `${HEADER_WIDTH_KEY}.${column.label}`, width);
  }
  await IndexedDbInst.upsert({
    key,
    config: JSON.stringify(config),
    expired_time: getExpiredTime(),
  });
}

async function getColsWidth(json) {
  const { config } = await getLayoutKeyAndConfig(json);
  return get(config, HEADER_WIDTH_KEY) || {};
}

async function getColsOrder(json) {
  const { config } = await getLayoutKeyAndConfig(json);
  return {
    checkedList: get(config, HEADER_ORDER) || [],
    hiddenList: get(config, HEADER_HIDDEN) || [],
  };
}

async function setColsOrder(order, hiddenHeader, json) {
  const { key, config } = await getLayoutKeyAndConfig(json);
  set(config, HEADER_ORDER, order);
  set(config, HEADER_HIDDEN, hiddenHeader);
  await IndexedDbInst.upsert({
    key,
    config: JSON.stringify(config),
    expired_time: getExpiredTime(),
  });
}

async function removeColsOrder(json) {
  const { key, config } = await getLayoutKeyAndConfig(json);
  if (get(config, HEADER_ORDER) && keys(config).length === 1) {
    await IndexedDbInst.delete(key);
  } else {
    delete config[HEADER_ORDER];
    delete config[HEADER_HIDDEN];
    await IndexedDbInst.upsert({
      key,
      config: JSON.stringify(config),
      expired_time: getExpiredTime(),
    });
  }
}

// 检测过期的配置并删除
async function checkAndRemoveExpiredStore() {
  const store = IndexedDbInst.db[TABLE_NAME];
  if (store) {
    store.where('expired_time').below(new Date().getTime()).delete();
  }
}

async function setLayoutFilters(data, json) {
  const { key, config } = await getLayoutKeyAndConfig(json);
  set(config, `${FILTERS_DATA}`, data);
  await IndexedDbInst.upsert({
    key,
    config: JSON.stringify(config),
    expired_time: getExpiredTime(),
  });
}
async function processAndUpdateConfig() {
  const allData = (await IndexedDbInst.findAllAndProcessConfig()) || [];
  const updatedData = allData.map((item) => {
    if (item.config) {
      try {
        const configObj = JSON.parse(item.config || {});
        if (configObj.filter_data) {
          delete configObj.filter_data;
          item.config = JSON.stringify(configObj);
        }
      } catch (error) {
        console.error(`Error parsing config for item with key ${item.key}:`, error);
      }
    }
    return item;
  });
  // 更新每条数据
  await Promise.all(
    updatedData.map((item) => {
      if (keys(JSON.parse(item.config)).length === 0) {
        return IndexedDbInst.delete(item.key).catch((error) => {
          console.error(`Error deleting item with key ${item.key}:`, error);
        });
      }
      return IndexedDbInst.upsert({
        key: item.key,
        config: item.config,
      }).catch((error) => {
        console.error(`Error upserting item with key ${item.key}:`, error);
      });
    }),
  );
}
async function getLayoutFilters(json) {
  const { config } = await getLayoutKeyAndConfig(json);
  return get(config, FILTERS_DATA) || {};
}
async function delLayoutFilters(key, config) {
  unset(config, FILTERS_DATA);
  await IndexedDbInst.upsert({
    key,
    config: JSON.stringify(config),
  });
}

setTimeout(() => {
  checkAndRemoveExpiredStore();
}, 5000);

export default {
  setColWidth,
  getColsWidth,
  getColsOrder,
  setColsOrder,
  removeColsOrder,
  setLayoutFilters,
  delLayoutFilters,
  getLayoutFilters,
  processAndUpdateConfig,
};
