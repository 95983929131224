import { formatTime } from '@/libs/utils/util';
// 小时分钟选择范围 分钟: hourMinutes(60); 小时: hourMinutes(24)
const hourMinutes = (count = 60) => {
  const arr = [];
  for (let i = 0; i < count; i++) {
    if (i < 10) {
      i = `0${i}`;
    } else {
      i = String(i);
    }
    arr.push(i);
  }
  return arr;
};

// 时间戳获取年月日时分秒
const timeStampOne = (timeStamp, type) => {
  function addTen(val) {
    if (val.toString().length === 1) {
      return `0${val}`;
    }
    return val.toString();
  }
  switch (type) {
    case 'year':
      return new Date(timeStamp).getFullYear();
    case 'month':
      return addTen(new Date(timeStamp).getMonth() + 1);
    case 'day':
      return addTen(new Date(timeStamp).getDate());
    case 'hour':
      return addTen(new Date(timeStamp).getHours());
    case 'minute':
      return addTen(new Date(timeStamp).getMinutes());
    case 'second':
      return addTen(new Date(timeStamp).getSeconds());
    default:
      break;
  }
};
/**
 * 日期格式化
 * @param date 日期
 * @param format 目标格式
 */
const dateFormat = (date, format = 'YYYY-MM') => {
  const we = date.getDay(); // 星期
  const qut = Math.floor((date.getMonth() + 3) / 3).toString(); // 季度
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'M+': (date.getMonth() + 1).toString(), // 月(月份从0开始，要+1)
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'm+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString(), // 秒
    'q+': qut, // 季度
  };
  const week = {
    // 中文数字 (星期)
    0: '日',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
  };
  const quarter = {
    // 中文数字（季度）
    1: '一',
    2: '二',
    3: '三',
    4: '四',
  };
  if (/(W+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      RegExp.$1.length > 1
        ? RegExp.$1.length > 2
          ? `星期${week[we]}`
          : `周${week[we]}`
        : week[we],
    );
  }
  if (/(Q+)/.test(format)) {
    // 输入一个Q，只输出一个中文数字，输入4个Q，则拼接上字符串
    format = format.replace(
      RegExp.$1,
      RegExp.$1.length == 4 ? `第${quarter[qut]}季度` : quarter[qut],
    );
  }
  for (const k in opt) {
    const r = new RegExp(`(${k})`).exec(format);
    if (r) {
      // 若输入的长度不为1，则前面补零
      format = format.replace(
        r[1],
        RegExp.$1.length == 1 ? opt[k] : opt[k].padStart(RegExp.$1.length, '0'),
      );
    }
  }
  return format;
};

// 格式化秒为分秒
const getMinuteNumber = function (timeData) {
  let minute = Math.floor(timeData / 60);
  minute = minute ? (minute < 10 ? `0${minute}` : minute) : '00';
  let second = timeData % 60;
  second = second ? (second < 10 ? `0${second}` : second) : '00';
  return `${minute}:${second}`;
};

// 时间戳转换年月日时分秒
const parseTime = function (time, cFormat = 'yyyy-mm-dd hh:ii:ss') {
  return formatTime(time, cFormat);
};

// 时间长度格式话为 hh:mm:ss
const formatSecondsDuration = (showTotalDuration) => {
  // if (!showTotalDuration) return '-';
  const totalSec = showTotalDuration || 0;
  if (!totalSec) {
    return '00:00:00';
  }
  const oneSec = 1;
  const oneMin = oneSec * 60;
  const oneHour = oneMin * 60;
  let hour = parseInt(totalSec / oneHour, 10);
  hour = hour < 10 ? `0${hour}` : hour;
  let restSec = totalSec % oneHour;
  let min = parseInt(restSec / oneMin, 10);
  min = min < 10 ? `0${min}` : min;
  restSec = Math.ceil(restSec % oneMin);
  restSec = restSec < 10 ? `0${restSec}` : restSec;
  return `${hour}:${min}:${restSec}`;
  // return `${hour}:${min}:${parseInt(restSec, 10)}`;
};

export {
  formatTime,
  hourMinutes,
  timeStampOne,
  dateFormat,
  getMinuteNumber,
  parseTime,
  formatSecondsDuration,
};
