//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StatusTag',
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      value1: [],
    };
  },
  computed: {
    validList() {
      return this.list.filter((item) => {
        return item && item?.official_accounts;
      });
    },
    validListToString() {
      let str = '';
      this.validList.forEach((item, index) => {
        str += `${item.official_accounts.name}${index !== this.validList.length - 1 ? ', ' : ''}`;
      });
      return str;
    },
  },
};
