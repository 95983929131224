//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGettersWrap } from '@tencent/vuex-dynamic-store';
import { getOptionsFrom } from 'nges-common/src/layout/mutation';

export default {
  valueType: 'TREE',
  props: {
    selectValue: {
      type: String,
      default: '',
    },
    field: {
      type: Object,
      default: () => {},
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filterText: '',
      treeData: [],
      selected: null,
      checkedNodes: null,
    };
  },
  computed: {
    ...mapGettersWrap(['objects', 'rules', 'propMapField', 'expParamsObj']),
    style() {
      const style = this.filter?.style || {};
      return {
        ...style,
        ...this.widthStyle,
      };
    },
    rowsConf() {
      console.log(this.filter, this.options, 'thisfsdfdf');
      return this.filter?.options_from?.row;
    },
    isLeaf() {
      return ['leaf_all'].includes(this.filter?.type);
    },
    isSelectOne() {
      return this.filter?.type === 'leaf_one' || this.filter?.type === 'one';
    },
    defaultProps() {
      return {
        children: this.rowsConf.key,
        label: this.rowsConf.title,
      };
    },
    rowsConfKeys() {
      return this.getRowsConfKeys(this.rowsConf);
    },
    rowsConfTitles() {
      return this.getRowsConfTitle(this.rowsConf);
    },
    rowsConfValueKeys() {
      return this.getRowsConfValueKeys(this.rowsConf);
    },
  },
  watch: {
    filterText(val) {
      this.$refs.treeSelect.filter(val);
    },
  },
  async mounted() {
    try {
      const options = Array.isArray(this.filter?.options_from?.options)
        ? this.filter?.options_from?.options
        : await getOptionsFrom({
            optionsFrom: this.filter?.options_from,
            objects: this.objects,
          });
      this.treeData = this.normalizeTree(options) || [];
      this.selected
        = this.formData?.[this.field.prop]?.map((item) => item[this.rowsConf.value_key]) || [];
      this.$nextTick(() => {
        this.$refs.treeSelect.setCheckedKeys(this.selected);
      });
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    normalizeTree(tree) {
      tree.forEach((node) => {
        const key = Object.keys(node)?.find((key) => this.rowsConfKeys.includes(key));
        const title = Object.keys(node)?.find((title) => this.rowsConfTitles.includes(title));
        const valueKey = Object.keys(node)?.find((vk) => this.rowsConfValueKeys.includes(vk));

        node[this.rowsConf.key] = node[key];
        node[this.rowsConf.title] = node[title];
        node[this.rowsConf.value_key] = node[valueKey];

        if (this.rowsConf.key !== key) {
          delete node[key];
        }
        if (this.rowsConf.title !== title) {
          delete node[title];
        }
        if (this.rowsConf.value_key !== valueKey) {
          delete node[valueKey];
        }

        if (node[this.rowsConf.key]) {
          this.normalizeTree(node[this.rowsConf.key]);
        }
      });

      return tree;
    },
    getRowsConfKeys(row) {
      const keys = new Set();

      function traverse(node) {
        if (node.key) {
          keys.add(node.key);
        }

        if (node.children) {
          traverse(node.children);
        }
      }

      traverse(row);
      return Array.from(keys);
    },
    getRowsConfValueKeys(row) {
      const valueKeys = new Set();

      function traverse(node) {
        if (node.value_key) {
          valueKeys.add(node.value_key);
        }

        if (node.children) {
          traverse(node.children);
        }
      }

      traverse(row);
      return Array.from(valueKeys);
    },
    getRowsConfTitle(row) {
      const titles = new Set();

      function traverse(node) {
        if (node.title) {
          titles.add(node.title);
        }

        if (node.children) {
          traverse(node.children);
        }
      }

      traverse(row);
      return Array.from(titles);
    },
    filterNode(value, data) {
      if (!value) return true;
      const label = data.label || data[this.rowsConf.title];
      return label.indexOf(value) !== -1;
    },
    formatTreeData(data) {
      const options = [];
      const formatDataRecursive = (data) => {
        data.forEach((item) => {
          const label = item.label || item[this.rowsConf.title];
          const value = item.value || item[this.rowsConf.value_key];
          options.push({ ...item, label, value });
          const children = item.children?.length ? item.children : item[this.rowsConf.key];
          if (children?.length) {
            formatDataRecursive(children);
          }
        });
      };
      formatDataRecursive(data);
      return options;
    },
    handleNodeClick(node) {
      console.log(node);
    },
    clear() {
      this.selected = null;
      this.$refs.treeSelect.setCheckedKeys([]);
      this.handleChange(null, []);
    },
    handleCheck(current, { checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys }) {
      console.log(
        current,
        { checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys },
        'checkedKeys',
      );
      this.selected = checkedKeys;
      this.checkedNodes = current;
      if (this.isLeaf) {
        const flatTree = (tree) => {
          return tree.reduce((acc, item) => {
            const children = item.children?.length ? item.children : item[this.rowsConf.key];
            if (children?.length) {
              acc = acc.concat(flatTree(children));
            } else {
              acc.push(item[this.rowsConf.value_key || 'id']);
            }
            return acc;
          }, []);
        };
        const allLeafNodes = flatTree(this.treeData);
        this.selected = checkedKeys.filter((key) => allLeafNodes.includes(key));
        this.checkedNodes = checkedNodes.filter((item) => {
          const children = item.children?.length ? item.children : item[this.rowsConf.key];
          return !children?.length;
        });
      }
      this.handleChange(this.selected, this.checkedNodes);
    },
    handleRemove(code) {
      this.selected = this.selected.filter((item) => item !== code);
      this.checkedNodes = this.checkedNodes.filter((item) => item[1] !== code);
      this.$refs.treeSelect.setCheckedKeys(this.selected);
      this.handleChange(this.selected, this.checkedNodes);
    },
    handleChange(value, data) {
      this.$emit('change', value, data);
      this.$emit('listChange', value);
    },
  },
};
