export default {
  namespaced: true,
  getters: {
    getCdpTagValueMap(state) {
      return state.cdpTagValueMap;
    },
    getImportTagValues(state) {
      return state.importTagValues;
    },
    getVisiable(state) {
      return state.visiable;
    },
    getTagName(state) {
      return state.tagName;
    },
  },
  state: {
    cdpTagValueMap: {},
    importTagValues: [],
    visiable: false,
    tagName: '',
  },
  mutations: {
    setCdpTagValueMap(state, data) {
      state.cdpTagValueMap = data;
    },
    setImportTagValues(state, data) {
      state.importTagValues = [...data];
    },
    setVisiable(state, data) {
      state.visiable = data;
    },
    setTagName(state, data) {
      state.tagName = data;
    },
  },
  actions: {
    showImportDialog({ commit }, newData) {
      commit('setImportTagValues', newData.tagValues);
    },
  },
};
