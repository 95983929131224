import { getObjects, transObjectValue } from './util';
import { cloneDeep, isArray, isObject } from 'lodash';
import { execExpressionDeep } from './expression';

let easyQuery;
let cmdQuery;
let GetObjectsByNames;

easyQuery = require('../web/utils/query').easyQuery;
cmdQuery = require('../web/utils/detailUtil').cmdQuery;
GetObjectsByNames = require('../web/utils/api').GetObjectsByNames;


// 获取元数据对象配置，查询后的元数据对象换成在内存中，避免多次查询
const objectCache = {};

async function getObjectsDetail(objects) {
  if (!objects || !objects.length) {
    return [];
  }
  const newObjects = [];
  objects.forEach((item) => {
    if (!objectCache[item]) {
      newObjects.push(item);
    }
  });
  if (newObjects.length) {
    try {
      const res = await GetObjectsByNames({
        objects: newObjects,
      });
      const newList = res?.list || [];
      newList.forEach((item) => {
        if (item?.object?.name) {
          objectCache[item.object.name] = item;
        }
      });
    } catch (err) {
      console.error(err);
    }
  }
  return objects.map((item) => {
    return objectCache[item] ? cloneDeep(objectCache[item]) : {};
  });
}

export async function getObjectsFromJson(json) {
  return getObjectsDetail(getObjects(json));
}

async function cmdService(json, expParamsObj = {}, getResponseExpParamsObj) {
  const { cmd, payload, response = null } = json;
  const remoteValue = await cmdQuery({
    cmd,
    payload: execExpressionDeep(payload, expParamsObj),
  });
  if (response) {
    const newParamsObj = getResponseExpParamsObj
      ? getResponseExpParamsObj(remoteValue)
      : {
          t: {
            ...remoteValue,
            p: {
              ...expParamsObj?.t,
              ...expParamsObj,
            },
          },
          p: expParamsObj?.params,
          $item: expParamsObj?.$item,
          $index: expParamsObj?.$index,
        };
    return execExpressionDeep(response, newParamsObj);
  }
  return remoteValue;
}

async function graphqlQuerySerivice(json, expParamsObj, extraCondition, extraPayload) {
  // 处理order,where等表达式
  const handledJson = {
    ...json,
    wheres: execExpressionDeep(json?.wheres, expParamsObj),
    orders: execExpressionDeep(json?.orders, expParamsObj),
    sub_query: execExpressionDeep(json?.sub_query, expParamsObj),
  };
  const remoteValue = await easyQuery(handledJson, extraCondition, extraPayload);
  return remoteValue;
}

async function fetchData(
  json,
  expParamsObj,
  extraCondition,
  extraPayload,
  getResponseExpParamsObj,
) {
  let remoteValue = {};
  if (json?.cmd) {
    remoteValue = await cmdService(json, expParamsObj, getResponseExpParamsObj);
  } else {
    remoteValue = await graphqlQuerySerivice(json, expParamsObj, extraCondition, extraPayload);
  }
  return remoteValue;
}

export async function layoutRequest(
  json = {},
  expParamsObj = {},
  extraCondition = {},
  extraPayload = {},
  getResponseExpParamsObj,
) {
  const promises = [
    getObjectsFromJson(json),
    fetchData(json, expParamsObj, extraCondition, extraPayload, getResponseExpParamsObj),
  ];
  const res = await Promise.all(promises);
  const objects = res?.[0];
  let remoteValues = res?.[1] || [];
  const aggregate = remoteValues?._aggregate;
  if (!json?.cmd) {
    remoteValues = isArray(remoteValues) ? remoteValues : remoteValues?._data || [];
  }
  let formattedValues = remoteValues;
  const transItemData = (item) => {
    const value = transObjectValue({
      data: item,
      json,
      objects,
    });
    if (aggregate) {
      value._aggregate = aggregate;
    }
    return value;
  };
  if (isObject(remoteValues)) {
    if (remoteValues?.length) {
      formattedValues = remoteValues.map((item) => {
        return transItemData(item);
      });
    } else {
      formattedValues = transItemData(remoteValues);
    }
  }
  return {
    formattedValues,
    remoteValues,
    objects,
    aggregate,
  };
}
