//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import { mapGetters } from 'vuex';
import { GetObjectsByNames } from '@/api/restful';
import { getMutationField, getFieldMap, queryField, getCascadeData } from '@/json/query';
import { getObjects } from '@/json/util';
import { McmAdminService } from '@/graphql/api/list';
import request from '@/libs/request';
import forms from './forms';

const DEFAULT_COMPONENT_TYPE = 'layout-input';

const VALUE_TYPE_MAP_COMPONENT_TYPE = {
  INT: 'layout-number',
  DATETIME: 'layout-datePicker',
  SELECT_MANY: 'layout-select',
  SELECT_ONE: 'layout-select',
  CASCADER: 'layout-cascader',
  REMOTESELECT: 'layout-remoteSelect',
  TEXT: DEFAULT_COMPONENT_TYPE,
};

const INIT_DATA = {
  id: '',
  loading: false,
  sumitLoading: false,
  drawer: false,
  layout: void 0,
  layoutJson: {},
  objects: [],
  fields: [],
  rules: {},
  formValue: {},
  listRef: null,
};

export default {
  name: 'MutationLayout',
  components: {
    ...forms,
  },
  props: {
    customMutationPath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ...INIT_DATA,
    };
  },
  computed: {
    ...mapGetters('user', {
      remoteLayoutJson: 'getLayoutJson',
    }),
    isDetailLayout() {
      return this.layout === 'detail-layout';
    },
  },
  methods: {
    open({ layout, id = '', listRef }) {
      this.layout = layout;
      this.drawer = true;
      this.listRef = listRef;
      this.id = id;
      this.init();
    },
    close() {
      Object.keys(INIT_DATA).forEach((key) => {
        this[key] = INIT_DATA[key];
      });
      this.resetForm();
    },
    resetForm() {
      this.$refs?.form?.resetFields();
    },
    async init() {
      this.loading = true;
      this.getLayoutJson();
      await this.getObjects();
      this.initFields();
      this.initRules();
      this.initFormValue();
      console.log('this.id', this.id);
      if (this.id) {
        await this.getDetail();
      }
      this.loading = false;
      this.$refs?.form?.clearValidate();
    },
    getQueryFields() {
      const fieldMap = getFieldMap({ json: this.layoutJson });
      const queryFields = queryField(fieldMap);
      return `{${queryFields}}`;
    },
    async getDetail() {
      try {
        const { object } = this.layoutJson;
        const queryFields = this.getQueryFields();
        const detailMutation = `query { ${object}(_where: { id: "${this.id}" }) ${queryFields} }`;
        const res = await McmAdminService({
          query: detailMutation,
        });
        const remoteValue = res[object]?.[0] || {};
        _.forEach(this.formValue, (field, prop) => {
          const otherField = this.fields.find((i) => i.prop === prop) || {};
          const { filter = {} } = otherField;
          const { value_type: valueType } = filter;
          if (prop.includes('-')) {
            const fieldArr = prop.split('-');
            const fieldValue = getCascadeData(fieldArr, remoteValue);
            this.formValue[prop] =
              valueType === 'SELECT_MANY' && typeof fieldValue === 'string'
                ? fieldValue.split(',')
                : fieldValue;
          } else {
            this.formValue[prop] =
              valueType === 'SELECT_MANY' && typeof remoteValue[prop] === 'string'
                ? remoteValue[prop].split(',')
                : remoteValue[prop];
            if (Array.isArray(this.formValue[prop])) {
              this.formValue[prop] = this.formValue[prop].filter((item) => item);
            }
          }
        });
        if (this.isDetailLayout) {
          const formValue = {};
          _.forEach(this.formValue, (value, key) => {
            if (Array.isArray(value)) {
              formValue[key] = value.join(',') || '-';
            } else {
              formValue[key] = value || '-';
            }
          });
          this.formValue = formValue;
        } else {
          this.formValue = {
            ...this.formValue,
          };
        }
      } catch (err) {
        console.error(err);
      }
    },
    getLayoutJson() {
      console.log('===remoteLayoutJson', this.remoteLayoutJson);
      this.layoutJson = _.get(this.remoteLayoutJson, this.layout);
    },
    async getObjects() {
      try {
        const res = await GetObjectsByNames({
          objects: getObjects(this.layoutJson),
        });
        this.objects = res?.list || [];
      } catch (err) {
        console.error(err);
      }
    },
    initFields() {
      const fields = getMutationField({ json: this.layoutJson, objects: this.objects });
      this.fields = this.filterTypeMapComponentType(fields);
    },
    filterTypeMapComponentType(fields = []) {
      return fields.map((i = { filter: {} }) => {
        const {
          filter: { value_type: valueType },
        } = i;
        const componentType = VALUE_TYPE_MAP_COMPONENT_TYPE[valueType];
        if (forms[componentType]) {
          i.componentType = componentType;
        }
        return i;
      });
    },
    initRules() {
      const rules = this.fields.reduce((pre, field = {}) => {
        const { prop } = field;
        const { rules: fieldRules = [] } = field.filter || {};
        if (fieldRules) {
          const customFieldRules = fieldRules.filter((i) => i.type === 'custom');
          const normalFieldRules = fieldRules.filter((i) => i.type !== 'custom');
          pre[prop] = normalFieldRules;
          if (customFieldRules.length) {
            customFieldRules.forEach((i) => {
              pre[prop].push({
                validator: this.getCustomRuleFuc({
                  conditions: i.conditions,
                }),
                trigger: i.trigger,
              });
            });
          }
        }
        return pre;
      }, {});
      this.rules = rules;
    },
    getCustomRuleFuc({ conditions = [] }) {
      return (rule, value, callback) => {
        try {
          const len = conditions.length;
          if (len) {
            conditions.forEach((condition) => {
              if (this.adjustIsValid(condition.condition)) {
                callback(new Error(condition.message));
              }
            });
          }
          callback();
        } catch (err) {
          console.error(err);
          callback(new Error(`自定义条件判断失败，请检查表达式是否正确！: ${err.toString()}`));
        }
      };
    },
    adjustIsValid(condition) {
      let conditionStr = condition;
      Object.keys(this.formValue).forEach((key) => {
        conditionStr = conditionStr.replace(
          new RegExp(`\\$\\{${key}\\}`, 'gm'),
          `'${this.formValue[key]}'`,
        );
      });
      console.log('conditionStr', conditionStr);
      // eslint-disable-next-line
      const isValid = new Function(conditionStr);
      return isValid();
    },
    initFormValue() {
      this.formValue = this.fields.reduce((pre, { prop }) => {
        pre[prop] = void 0;
        return pre;
      }, {});
    },
    handleValue({ prop, value }) {
      console.log('===', prop, value);
      this.formValue[prop] = value;
      this.formValue = {
        ...this.formValue,
      };
    },
    sumit() {
      const { getTableData } = this.listRef || {};
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await this.remoteMutation();
          getTableData && getTableData();
        } else {
          this.$message.error('请填写完整信息');
          console.log('error submit!!');
          return false;
        }
      });
    },
    formatFieldValue(value) {
      // if (value === null) {
      //   return null;
      // }
      if (!value) {
        return `""`;
      }
      return `"${value}"`;
    },
    getMutationFields() {
      let fields = [];
      _.forEach(this.formValue, (form, prop) => {
        const isObjectKey = prop.includes('-');
        if (!isObjectKey) {
          const value = this.formatFieldValue(this.formValue[prop]);
          fields.push(`${prop}: ${value}`);
        } else {
          const propIndex = this.fields.findIndex((i) => i.prop === prop);
          const { filter } = this.fields[propIndex] || {};
          const { field } = filter;
          const isArray = Array.isArray(this.formValue[prop]);
          let fieldValue = isArray ? this.formValue[prop].join(',') : this.formValue[prop];
          fieldValue = this.formatFieldValue(fieldValue);
          fields.push(`${field}: ${fieldValue}`);
        }
      });
      console.log(fields);
      const { fields: layoutJson = [] } = this.layoutJson;
      const hiddenField = layoutJson
        .filter((i) => i.hidden && i.defaultValue)
        .reduce((pre, i) => {
          const { field, defaultValue } = i;
          if (field) {
            pre.push(`${field}: "${defaultValue}"`);
          }
          return pre;
        }, []);
      fields = [...fields, ...hiddenField];
      return `{ ${fields.join(',')} }`;
    },
    async remoteMutation() {
      try {
        if (!this.sumitLoading) {
          this.sumitLoading = true;
          const { interfaceName } = this.layoutJson;
          if (!interfaceName) {
            await this.graphqlMutation();
          } else {
            await this.interfaceMutation();
          }
          this.$message(`${this.layoutJson.layoutName}成功`);
          this.sumitLoading = false;
          this.close();
        }
      } catch (err) {
        this.sumitLoading = false;
        console.error(err);
        const message = err?.message ? `:${err?.message}` : '';
        this.$message(`${this.layoutJson.layoutName}失败${message}`);
      }
    },
    async graphqlMutation() {
      const { object } = this.layoutJson;
      if (this.id) {
        const updateMutation = `mutation update {${object}(_where: { id: "${
          this.id
        }" } ,_set: ${this.getMutationFields()}) {id}}`;
        await McmAdminService(
          {
            query: updateMutation,
          },
          this.customMutationPath,
        );
      } else {
        const insertMutation = `mutation insert {${object}(_values: ${this.getMutationFields()}) {id}}`;
        await McmAdminService(
          {
            query: insertMutation,
          },
          this.customMutationPath,
        );
      }
    },
    async interfaceMutation() {
      const { interfaceName, interfaceFieldMap = {} } = this.layoutJson;
      const reqData = Object.keys(interfaceFieldMap).reduce((pre, fieldName) => {
        _.set(pre, interfaceFieldMap[fieldName], this.formValue[fieldName]);
        return pre;
      }, {});
      if (this.id) {
        _.set(reqData, interfaceFieldMap.id, this.id);
      }
      await request(interfaceName, reqData);
    },
  },
};
