import { GetAppConfig } from 'nges-common/src/web/utils/api.js';

export default {
  namespaced: true,
  getters: {
    getAppConfig(state) {
      return state.appConfig;
    },
    getMcmIds(state) {
      return state.mcmIds || {};
    },
  },
  state: {
    // 自定义域名下使用的前端配置
    appConfig: {},
    mcmIds: {},
  },
  mutations: {
    setAppConfig(state, config) {
      try {
        const mcmIds = config.page_config?.mcm_ids;
        if (mcmIds) {
          state.mcmIds = JSON.parse(mcmIds);
        }
      } catch (error) {
        console.log(error);
      }
      state.appConfig = config;
    },
  },
  actions: {
    async requestAppConfig(context) {
      return new Promise((resolve, reject) => {
        GetAppConfig({ domain: location.host }).then(
          (res) => {
            console.log('requestAppConfig');
            if (res.mobile_domain) {
              res.mobileDomain = res.mobile_domain;
            }

            console.log(res);
            context.commit('setAppConfig', res);
            resolve();
          },
          (e) => reject(e),
        );
      });
    },
  },
};
