//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Progress',
  props: {
    percent: {
      type: Number,
      default: 0,
    },
    status: {
      type: String,
      default: '',
    },
    intro: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 115,
    },
  },
  computed: {
    calcStatus() {
      return this.status === 'danger' ? 'exception' : this.status;
    },
    calcPercent() {
      return !this.percent || this.percent < 0 ? 0 : this.percent > 100 ? 100 : this.percent;
    },
  },
};
