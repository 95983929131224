//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MessageBox',
  data() {
    return {
      // 显示
      visible: false,
      // 宽度
      width: '400px',
      // 离顶部高度
      top: '40vh',
      // 标题
      title: '',
      // 标题最右侧的叉按钮
      exitButton: false,
      // 标题前的icon类型
      titleIconType: '',
      // 内容文字
      content: '',
      // 确认按钮文字
      confirmText: '',
      // 确认按钮类型
      confirmType: '',
      // 确认后操作
      confirmAction: () => {},
      // 显示返回按钮
      needCancel: false,
      // 显示确认按钮
      needConfirm: true,
      // 返回按钮文字
      cancelText: '',
      // 返回按钮类型
      cancelType: '',
      // 返回按钮操作
      cancelAction: () => {},
      // 关闭前操作
      handleClose: this.close,
      // 是否可以通过点击 modal 关闭 Dialog
      closeOnClickModal: true,
      // 是否可以通过按下 ESC 关闭 Dialog
      closeOnPressEscape: true,
      // 关闭时是否销毁内部元素
      destroyOnClose: false,
      // 是否挂在body元素下
      appendToBody: false,
      // 是否存在内容
      noBody: false,
    };
  },
  methods: {
    show(option) {
      const {
        // 标题
        title = '信息提示',
        // 宽度
        width = '400px',
        // 离顶部高度
        top = '40vh',
        // 标题最右侧的叉按钮
        exitButton = false,
        // 标题前的icon类型
        titleIconType = '',
        // 内容文字
        content = '',
        // 确认按钮文字
        confirmText = '确认',
        // 确认按钮类型
        confirmType = 'primary',
        // 确认后操作
        confirmAction = () => {},
        // 显示返回按钮
        needCancel = false,
        // 显示确认按钮
        needConfirm = true,
        // 返回按钮文字
        cancelText = '取消',
        // 返回按钮类型
        cancelType = 'info',
        // 返回按钮操作
        cancelAction = () => {},
        // 关闭前操作
        handleClose = this.close,
        // 是否可以通过点击 modal 关闭 Dialog
        closeOnClickModal = true,
        // 是否可以通过按下 ESC 关闭 Dialog
        closeOnPressEscape = true,
        // 关闭时是否销毁内部元素
        destroyOnClose = false,
        // 是否挂在body元素下
        appendToBody = false,
      } = option;
      this.title = title;
      this.width = width;
      this.top = top;
      this.exitButton = exitButton;
      this.titleIconType = titleIconType;
      this.content = content;
      this.confirmText = confirmText;
      this.confirmType = confirmType;
      this.confirmAction = confirmAction;
      this.needCancel = needCancel;
      this.needConfirm = needConfirm;
      this.cancelText = cancelText;
      this.cancelType = cancelType;
      this.cancelAction = cancelAction;
      this.handleClose = handleClose;
      this.closeOnClickModal = closeOnClickModal;
      this.closeOnPressEscape = closeOnPressEscape;
      this.destroyOnClose = destroyOnClose;
      this.appendToBody = appendToBody;
      console.log('meowss', this);
      if (!this.content && !this.$slots?.content) {
        this.noBody = true;
      } else {
        this.noBody = false;
      }

      this.visible = true;
    },
    close() {
      if (this.visible === true) {
        this.visible = false;
        this.$emit('close');
      }
    },
  },
};
