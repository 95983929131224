//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DataCard',
  filters: {
    tooltipFilter(item) {
      let { tooltipTxt } = item;
      if (item.ratio > 0) {
        tooltipTxt += `新增${Math.abs(item.ratio) + item.unit}`;
      } else {
        tooltipTxt += `减少${Math.abs(item.ratio) + item.unit}`;
      }
      return tooltipTxt;
    },
  },
  props: {
    dataList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
