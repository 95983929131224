//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { hourMinutes, timeStampOne } from '@/utils/time';
const initTime = new Date(
  timeStampOne(Date.now(), 'year'),
  timeStampOne(Date.now(), 'month') - 1,
  timeStampOne(Date.now(), 'day'),
).getTime();
export default {
  name: 'DateTime', // 日期时间组件
  props: {
    title: {
      type: String,
      default: '',
    },
    allowStartTime: {
      type: Number,
      default: initTime,
    },
    initDateTime: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      // 格式 date:'2020-01-01' hour:'13' minute:'45'
      dateTime: {
        date: undefined,
        hour: undefined,
        minute: undefined,
      },
      hourMinutes,
    };
  },
  computed: {
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(curTime) {
          const { allowStartTime } = _this; // 动态起始时间
          if (allowStartTime) {
            // false表示不禁用
            // curTime 是0点  所以今天也要启用的话, allowStartTime也要是0点
            return curTime.getTime() < allowStartTime;
          }
          return false;
        },
      };
    },
  },
  watch: {
    initDateTime(newVal) {
      this.dateTime.date = `${timeStampOne(newVal, 'year')}-${timeStampOne(
        newVal,
        'month',
      )}-${timeStampOne(newVal, 'day')}`;
      this.dateTime.hour = timeStampOne(newVal, 'hour');
      this.dateTime.minute = timeStampOne(newVal, 'minute');
    },
    dateTime: {
      handler() {
        this.$emit('dateTimeEmit', { ...this.dateTime });
      },
      deep: true,
    },
  },
  methods: {},
};
