//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Tab from '../tab';
import Action from '../../action';
import ngesDialog from 'nges-common/src/web/components/layout/dialog';

export default {
  name: 'LayoutFullList',
  components: {
    Tab,
    ngesDialog,
  },
  mixins: [Action],
  props: {
    // 弹框使用的参数
    dialogTitle: {
      type: String,
      default: '',
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogWidth: {
      type: String,
      default: '960px',
    },
    dialogTop: {
      type: String,
      default: '5%',
    },
    // 组件的额外参数，可以在表达式中通过p访问
    params: {
      type: [Array, String, Number, Object, null],
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      selectionData: [],
      tabIndex: '',
    };
  },
  computed: {},
  methods: {
    dialogClose() {
      this.$emit('close');
    },
    handleSelectionChange(val) {
      this.selectionData = val;
    },
    handleTabClick({ code }) {
      this.tabIndex = code;
      this.selectionData = this.$refs.tab.getCurrentList()?.$refs.list.listSelectedData || [];
    },
    dialogSuccess() {
      this.$emit('success', { index: this.tabIndex, data: this.selectionData });
    },
  },
};
