//
//
//
//
//
//
//
//
//
//
//

import layout from '@/components/layout.vue';
import Vue from 'vue';
import { GetAppConfig } from 'nges-common/src/web/utils/api';
import { setBrowserConfig } from '@/utils/browser';
import validateBox from 'nges-common/src/web/components/validate-box';
import login from '@/utils/login';
import aegis from 'nges-common/src/web/utils/aegis.js';

export default {
  components: {
    layout,
    validateBox,
  },
  data() {
    return {
      cache: [],
      loaded: false,
      validateObject: null,
    };
  },
  async mounted() {
    let appConfig = {};
    try {
      appConfig = await GetAppConfig();
    } catch (e) {}
    this.loaded = true;
    this.$nextTick(() => {
      Vue.prototype.$mutationLayout = this.$refs.mutationLayout;
    });
    this.$store.dispatch('user/getPermission');
    this.$store.dispatch('user/getElementAuthList');
    setBrowserConfig(appConfig);
    this.getThemeVar(appConfig);
    window.$setValidateObject = this.setValidateObject;
    this.logReport(appConfig);
  },
  methods: {
    getThemeVar(config) {
      try {
        const data = JSON.parse(config.page_config.theme_css_var);
        let result = '';
        Object.keys(data).forEach((key) => {
          result += `${key}:${data[key]};`;
        });
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `body{${result}}`;
        document.getElementsByTagName('head').item(0).appendChild(style);
      } catch (e) {
        console.log(e);
      }
    },
    logReport(config) {
      try {
        const uinList = JSON.parse(config?.page_config?.console_report || '[]');
        if (!uinList?.length) return;
        const allReport = uinList.includes('all');
        const oldConsoleLog = console.log;
        const oldConsoleError = console.error;
        const logToString = (...e) => {
          const result = ['console'];
          e.forEach((i) => {
            if (typeof i === 'object') {
              result.push(JSON.stringify(i));
            } else if (typeof i === 'undefined') {
              result.push('undefined');
            } else {
              result.push(i);
            }
          });
          return result.join(' || ');
        };
        console.log = function (...e) {
          try {
            if (!allReport && !uinList.includes(login.getUin())) return;
            oldConsoleLog(...e);
            const logString = logToString(...e);
            if (logString.length > 2000) return;
            aegis.infoAll(logString);
          } catch {}
        };
        console.error = function (...e) {
          try {
            if (!allReport && !uinList.includes(login.getUin())) return;
            oldConsoleError(...e);
            const logString = logToString(...e);
            if (logString.length > 2000) return;
            aegis.infoAll(logString);
          } catch {}
        };
        setTimeout(() => {
          console.log = oldConsoleLog;
          console.error = oldConsoleError;
        }, 1000 * 60 * 10);
      } catch (e) {
        console.log(e);
      }
    },
    setValidateObject(checkResults) {
      const queryTipsConf = [];
      /* 合规显示start */
      const validateObj = {
        error: [],
        warning: [],
        success: [],
      };
      checkResults.forEach((checkConf) => {
        const { type } = checkConf;
        if (type === 1) {
          // type: 1代表合规校验
          queryTipsConf.push(checkConf);
        }
      });
      queryTipsConf.forEach((i) => {
        if (i?.tip_title || i?.tip_msg) {
          switch (i.level) {
            case 'PASS': {
              validateObj.success.push({
                title: i.tip_title,
                detail: i.tip_msg,
              });
              break;
            }
            case 'WARN': {
              validateObj.warning.push({
                title: i.tip_title,
                detail: i.tip_msg,
              });
              break;
            }
            case 'ERROR': {
              validateObj.error.push({
                title: i.tip_title,
                detail: i.tip_msg,
              });
              break;
            }
            default: {
              break;
            }
          }
        }
      });
      if (queryTipsConf.length) {
        this.validateObject = validateObj;
      }
    },
  },
};
