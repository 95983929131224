import request from '@/libs/request';
import {
  querypublishList,
  queryImportRecordList,
  queryImportRecordDetail,
  querypublishDetail,
  queryDeleteRoster,
  queryPlanSendCount,
  queryRealSendCount,
} from '@/graphql/api/publishList';
import { querySendUserList } from '@/graphql/api/article';

// 名单管理接口

// 删除名单
export const DelRoster = (data) => queryDeleteRoster(data);
// 查看名单列表
export const GetRosterList = (data) => querypublishList(data);
// 查看名单详情
export const GetRosterInfo = (data) => querypublishDetail(data);

// 下载导入模板
export const GetRosterTemplate = (data) =>
  request('McmAdminServer/PublishListService/GetListTemplate', data);
// 导入名单数据
// export const ImportRosterData = (data) => request('ImportRosterData', data);
export const ImportRosterData = (data) =>
  request('McmAdminServer/PublishListService/ImportList', data);
// 查看导入记录列表
export const GetRosterRecordList = (data) => queryImportRecordList(data);
// 查看导入记录详情
export const GetRosterRecordInfo = (data) => queryImportRecordDetail(data);

// 查看计划发送数量
export const GetPlanSendCount = (data) => queryPlanSendCount(data);
// 查看实际发送数量
export const GetRealSendCount = (data) => queryRealSendCount(data);
// 查看可发送列表
export const GetSendUserList = (data) => querySendUserList(data);
