//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TableTemplate',
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    column: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    noPages: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 256,
    },
    pagination: {
      type: Object,
      default: () => ({
        tableTotal: 0,
        tablePageSize: 0,
      }),
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    // 是否能点击表格
    isChooseTable: {
      type: Boolean,
      default: false,
    },
    tableBodyStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tableData: this.data,
    };
  },
  watch: {
    data: {
      handler(newVal) {
        this.tableData = newVal;
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    /* 分页 */
    currentChangeFun(val) {
      this.$emit('paginationEmit', val);
      this.$emit('update:currentPage', val);
    },
    /* 获取每个item的render方法 */
    getCurrentrender(name, column) {
      const selected = column.filter((item) => item.key == name);
      return selected[0].render || null;
    },
    /* 筛选渲染表格row数据 */
    renderData(item, column) {
      const arr = column.map((item) => item.key);
      const obj = {};
      arr.forEach((val) => {
        for (const key in item) {
          if (val == key) {
            obj[key] = item[key];
          }
        }
      });
      return obj;
    },
    // 表格行点击选中事件
    tableRowClickFun(index, item) {
      // $event.preventDefault();

      // 数据驱动
      const { tableData } = this;
      for (let i = 0, ilen = tableData.length; i < ilen; i++) {
        tableData[i].active = false;
      }
      tableData[index].active = true;

      this.tableData = tableData;
      this.$forceUpdate();

      // dom操作
      const curTableRow = this.$refs[`tableRow${index}`][0];
      // curTableRow.parentNode.childNodes.forEach(item => {//清空其他选中项
      //     item.classList.remove('table-line-active')
      //     //判断是否存在左右移动
      //     if (item.lastChild.lastChild.hasAttribute('data-move')) {
      //         item.lastChild.lastChild.classList.remove('table-line-active')
      //     }
      // });
      // curTableRow.classList.add('table-line-active')
      // if (curTableRow.lastChild.lastChild.hasAttribute('data-move')) {
      //     curTableRow.lastChild.lastChild.classList.add('table-line-active')
      // }

      this.$emit('tableRowEmit', { item, curTableRow });
    },
    // 表格行点击移入事件
    tableRowMouseEnterFun() {
      // $event.preventDefault();
      // let curTableRow = (this.$refs['tableRow' + index])[0]
      // curTableRow.style.cursor = 'pointer'
      // curTableRow.classList.add('table-line-temp')
      // if (curTableRow.lastChild.lastChild.hasAttribute('data-move')) {
      //     curTableRow.lastChild.lastChild.classList.add('table-line-temp')
      // }
    },
    // 表格行点击移出事件
    tableRowMouseLeaveFun() {
      // $event.preventDefault();
      // let curTableRow = (this.$refs['tableRow' + index])[0]
      // curTableRow.classList.remove('table-line-temp')
      // if (curTableRow.lastChild.lastChild.hasAttribute('data-move')) {
      //     curTableRow.lastChild.lastChild.classList.remove('table-line-temp')
      // }
    },
  },
};
