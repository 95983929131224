//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getOptionsFrom } from 'nges-common/src/layout/mutation';
import { GetObjectsByNames } from 'nges-common/src/web/utils/api';
import { getObjects } from 'nges-common/src/layout/queryWeb';

export default {
  name: 'FormItem',
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      itemFocus: false,
      options: [],
      objects: [],
      optErrMsg: '',
      value: void 0,
    };
  },
  computed: {
    componentType() {
      switch (this.filter?.value_type) {
        case 'TEXT':
          return 'el-input';
        case 'SELECT_MANY':
          return 'el-select';
        case 'SELECT_ONE':
          return 'el-select';
        default:
          return null; // 或者返回一个默认的组件
      }
    },
    additionalProps() {
      // 根据不同的组件类型返回不同的属性
      const props = {
        clearable: true,
      };
      if (this.filter?.value_type === 'SELECT_MANY') {
        props.multiple = true;
      }
      return props;
    },
    filterTitle() {
      return this.filter?.label;
    },
  },
  mounted() {
    this.getOptionsFrom();
  },
  methods: {
    async getOptionsFrom() {
      try {
        if (this.filter?.options_from) {
          this.objects
            = (
              await GetObjectsByNames({
                objects: getObjects(this.filter?.options_from?.options),
              })
            )?.list || [];
          this.options = await getOptionsFrom({
            optionsFrom: this.filter.options_from,
            objects: this.objects,
          });
        }
      } catch (err) {
        console.error(err);
        this.optErrMsg = err.message || '获取选项异常！';
      }
    },
    handleChange(e) {
      console.log(e);
      this.value = e;
      const key = this.filter.field;
      switch (this.filter?.value_type) {
        case 'TEXT':
          this.$emit('change', { key, value: e, data: e });
          break;
        case 'SELECT_MANY':
          this.$emit('change', {
            key,
            value: e,
            data: this.options.filter((option) => e.includes(option.value)),
          });
          break;
        case 'SELECT_ONE':
          this.$emit('change', {
            key,
            value: e,
            data: this.options.find((option) => e === option.value),
          });
          break;
        default:
          break;
      }
    },
  },
};
