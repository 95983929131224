//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GetSubordinateList } from '../../../api';
import { depthFirstTraverse } from 'nges-common/src/web-mobile/util';
import { uniq } from 'lodash';

export default {
  name: 'Subordinate',
  props: {
    field: {
      type: Object,
      default: () => {},
    },
    value: {
      type: [Array, void 0],
      default: () => [],
    },
    nodeKey: {
      type: String,
      default: 'territory_code',
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tree: [],
      defaultProps: {
        children: 'subordinate_list',
        label: 'name',
      },
      list: [],
    };
  },
  watch: {
    value() {
      this.initData();
    },
  },
  async mounted() {
    await this.GetSubordinateList();
    this.initData();
  },
  methods: {
    initData() {
      if (this.value) {
        this.$refs.tree.setCheckedKeys(this.value);
        this.$nextTick(() => {
          const data = this.$refs.tree.getCheckedNodes();
          if (this.field.prop) {
            this.$emit('handle-check', {
              key: this.field.prop,
              value: this.value,
              data,
            });
          }
        });
      }
    },
    async GetSubordinateList() {
      try {
        const res = await GetSubordinateList();
        this.tree
          = res.data?.length > 0
            ? [
                {
                  ...res.data[0],
                },
              ]
            : [];
        const treeToList = [];
        depthFirstTraverse(
          {
            [this.defaultProps.children]: this.tree,
          },
          this.defaultProps.children,
          (node) => {
            if (node.$height > 1) {
              node.label = node[this.defaultProps.label];
              node.value = `${node.territory_code}-${node[this.defaultProps.label]}`;
              treeToList.push({
                label: node.label,
                value: node.value,
              });
            }
          },
        );
        this.list = treeToList;
        this.$emit('get-options', this.list);
      } catch (err) {
        console.error(err);
      }
    },
    handleCheck(checked, checkedObj) {
      console.log('===', checked);
      const { checkedNodes } = checkedObj;
      const value = uniq(checkedNodes.map((i) => i.territory_code));
      if (this.field.prop) {
        this.$emit('handle-check', {
          key: this.field.prop,
          value,
          data: checkedNodes,
        });
      }
      this.$emit('change', value);
      this.$emit('change-list', { value, data: checkedNodes });
      this.$emit('execJsonChange');
    },
    reset() {
      this.$refs.tree.setCheckedKeys([]);
    },
  },
};
