// 策略相关接口
import {
  StrategyList,
  GetStrategyDetail,
  UpdateStrategyStatus,
  CreateStrategy,
  UpdateStrategy,
  DeleteStrategyInfo,
  CountStrategyPeople,
  CountAllUserStrategyPeople,
} from '@/graphql/gql/strategy';
import { calVariables } from '@/graphql/util';
import { DataService } from 'nges-common/src/web/utils/api';

export function queryStrategyList(data) {
  return DataService({
    query: calVariables(StrategyList, data),
  });
}

export function queryGetStrategyDetail(data) {
  return DataService({
    query: calVariables(GetStrategyDetail, data),
  });
}

export function queryUpdateStrategyStatus(data) {
  return DataService({
    query: calVariables(UpdateStrategyStatus, data),
  });
}

export function queryCreateStrategy(data) {
  return DataService({
    query: calVariables(CreateStrategy, data),
  });
}
export function queryUpdateStrategy(data) {
  return DataService({
    query: calVariables(UpdateStrategy, data),
  });
}

export function queryDeleteStrategyInfo(data) {
  return DataService({
    query: calVariables(DeleteStrategyInfo, data),
  });
}

export function queryCountStrategyPeople(data) {
  return DataService({
    query: calVariables(CountStrategyPeople, data),
  });
}

export function queryCountAllUserStrategyPeople(data) {
  return DataService({
    query: calVariables(CountAllUserStrategyPeople, data),
  });
}
