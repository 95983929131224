// 医生接口
import { customerRelationList } from '@/graphql/gql/externalContactManagement';
import { calVariables } from '@/graphql/util';
import { DataService } from 'nges-common/src/web/utils/api';
// 医生地区排名
export function queryCustomerRelationList(data) {
  return DataService({
    query: calVariables(customerRelationList, data),
  });
}
