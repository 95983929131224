//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  getOldIds,
  // updateAttenders,
  // deleteAttenders,
  // insertAttenders,
} from '@/graphql/api/meeting.js';
export default {
  name: 'NominationDialog',
  props: {
    json: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      opened: false,
      meetingId: '',
      oldIds: [],
      loading: false,
      // newAttender: null,
    };
  },
  methods: {
    async getOldIds() {
      try {
        const res = await getOldIds(this.meetingId);
        console.log('res', res);
        const oldIds = res.events_meeting
          ? res.events_meeting[0]?.attender_id?.map((item) => item.hcp?.id || null) || []
          : [];
        console.log('oldIds', oldIds);
        this.oldIds = oldIds.filter((item) => item);
      } catch (error) {
        console.error(error);
      }
    },
    open(meetingId) {
      this.meetingId = meetingId;
      this.getOldIds();
      this.opened = true;
    },
    close() {
      this.opened = false;
    },
    confirm() {
      const selected = this.$refs.mList.$refs.list.listSelected;
      const selected2 = this.$refs.mList.$refs.list.listSelectedData;
      console.log('selected', selected, selected2);
      return;
      const deleted = this.oldIds.filter((item) => !selected.includes(item));
      const appended = selected.filter((item) => !this.oldIds.includes(item));
      const updated = selected.filter((item) => this.oldIds.includes(item));
      console.log('selected, appended, deleted, updated', selected, appended, deleted, updated);
      try {
        this.loading = true;
        // if (deleted?.length) {
        //   await deleteAttenders({ ids: deleted });
        // }
        // if (appended?.length) {
        //   await insertAttenders(
        //     appended.map((item) => {
        //       item.meeting_id = p.detailValue.id;
        //       delete item.hcp;
        //       return item;
        //     }),
        //   );
        // }
        // if (updated?.length) {
        //   const details = p.formData.attender_id.filter((item) => updated.includes(item.id));
        //   await updateAttenders(details);
        // }
        this.loading = false;
        this.$message.success('保存参会人成功');
        this.$emit('submitted');
      } catch (error) {
        this.loading = false;
        this.$message.error('保存出错');
        console.error(error);
      }
      this.opened = false;
    },
    hanldeSelect() {
      // hanldeSelect(val) {
      // this.newAttender = val;
    },
  },
};
