import { render, staticRenderFns } from "./topCascader.vue?vue&type=template&id=283b5628&scoped=true&"
import script from "./topCascader.vue?vue&type=script&lang=js&"
export * from "./topCascader.vue?vue&type=script&lang=js&"
import style0 from "./topCascader.vue?vue&type=style&index=0&id=283b5628&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../nges-web/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "283b5628",
  null
  
)

export default component.exports