import {
  queryMessageDetail,
  queryReadMessage,
  queryReadAllMessage,
  queryNeedReadMessage,
} from '@/graphql/api/notice';
import request from '@/libs/request';

export const GetMessageDetail = (data) => queryMessageDetail(data);
export const ReadMessage = (data) => queryReadMessage(data);
export const ReadAllMessage = (data) => queryReadAllMessage(data);
export const NeedReadMessage = (data) => queryNeedReadMessage(data);
export const SendMessage = (data) => request('MessagingServer/Messaging/SendMessaging', data);
