//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    tabList: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  mounted() {
    this.$emit('change', this.tabList[0].name);
  },
  methods: {
    handleActive(tab) {
      this.$emit('change', tab.name);
    },
  },
};
