//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cascader from './cascader';

export default {
  name: 'TopCascader',
  components: {
    cascader,
  },
  props: {
    filter: {
      type: [Object],
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    value: {
      type: [Array, String],
      default: () => [],
    },
  },
  data() {
    return {
      list: [],
      selected: [],
    };
  },
  computed: {
    multiple() {
      return typeof this.filter?.multiple === 'undefined' || this.filter?.multiple;
    },
  },
  watch: {
    'filter.selected': {
      handler(n) {
        if (!(n && n.length)) {
          this.selected = [];
        }
      },
    },
  },
  mounted() {
    this.changeSelectLen();
  },
  methods: {
    filterOnSubmit() {
      this.$emit('filterOnSubmit');
      this.closeDropdown();
    },
    valueChange(val, data) {
      this.$emit('change', val, data);
    },
    whereGqlChange(val) {
      this.filter.where_gql = val;
    },
    closeDropdown() {
      this.$refs.select.handleClose();
    },
    // 只清空选项，不抛出事件，不触发重新查询，用于全局重置时被调用
    reset() {
      this.selected = [];
      this.changeSelectLen();
      if (this.$refs.cascader) {
        this.$refs.cascader.searchText = '';
        this.$refs.cascader.selectedValues = [];
        this.$emit('change', []);
      }
    },
    resetFilter() {
      this.reset();
      this.$emit('resetFilter');
    },
    getOptions(options) {
      this.list = options;
    },
    listChange(selected) {
      this.selected = selected.filter((i) => !i.includes('all'));
      if (!this.multiple) {
        this.selected = [this.selected.pop()];
      }
      this.changeSelectLen();
    },
    changeSelectLen() {
      if (this.selected.length) {
        this.$nextTick(() => {
          let tagsDom = this.$refs.select.$el.querySelectorAll('.el-select__tags-text') || [];
          let selectDom = this.$refs.select.$el.querySelectorAll('.el-input__inner') || [];
          const maxWidth = selectDom[0].clientWidth;
          let tag0MaxWidth;
          if (tagsDom.length > 1 && selectDom.length) {
            const numTagWidth = tagsDom[1].clientWidth + 16 + 6; // 加padding+margin
            tag0MaxWidth = maxWidth - 36 - numTagWidth - 16; // 减去右边的icon区，减去padding,
            tagsDom[0].style.maxWidth = `${tag0MaxWidth}px`;
          }
          tagsDom = null;
          selectDom = null;
        });
      }
      // if (this.selected.length) {
      //   this.$nextTick(() => {
      //     const item = this.list.find((i) => i.value === this.selected[0]);
      //     if (item?.label) {
      // const tagsDom = this.$refs.select.$el.querySelectorAll('.el-select__tags-text') || [];
      // const tagsWidth = tagsDom?.[1]?.offsetWidth || 0;
      // this.$refs.select.$el.style.width = `${
      //   (item.label.length + 2) * 12 + tagsWidth + 80
      // }px`;
      //     }
      //   });
      // } else {
      // this.$refs.select.$el.style.width = `220px`;
      // }
    },
  },
};
