//
//
//
//
//
//
//
//

export default {
  name: 'Video',
  components: {},
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      playDialogVisible: false,
    };
  },
  created() {},
  methods: {},
};
