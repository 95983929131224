//
//
//
//
//
//

import config from 'nges-common/src/web/config';
import { WATERMARK_COLOR } from 'nges-common/src/constant';
import { GetWatermarkConfig, GetWatermarkOpen, watermarkUtil } from './util';

const WATERMARK_DENSITY = {
  DENSITY_DENSE: 40,
  DENSITY_STANDARD: 80,
  DENSITY_SPARSE: 120,
};

export default {
  name: 'WaterMark',
  data() {
    return {
      // 正在启用的水印
      watermarkOpen: {
        generic: false,
        url: [],
        objects: [],
      },
      // 缓存的水印数据
      pageWatermark: {
        generic: null,
      },
      // 缓存的水印样式
      styleConfig: [],
      watermarkLevel: {
        // 业务触发的自定义url 2 自定义url 1 通用布局 0
        pageLevel: -1,
        // 页面布局 2 容器布局 1
        containerLevel: -1,
        // 父布局 2 子布局 1
        parentLevel: -1,
      },
      maxStr: '',
    };
  },
  watch: {
    $route() {
      this.routeChange();
    },
  },
  created() {
    watermarkUtil.getObjectWatermark = this.getObjectWatermark;
    watermarkUtil.updateWatermark = this.updateWatermark;
    watermarkUtil.reloadWatermark = this.routeChange;
    watermarkUtil.hideWaterMark = this.hideWaterMark;
  },
  mounted() {
    GetWatermarkOpen().then((data) => {
      this.watermarkOpen = data;
      this.routeChange();
    });
  },
  methods: {
    async routeChange() {
      this.watermarkLevel = {
        pageLevel: -1,
        containerLevel: -1,
        parentLevel: -1,
      };
      const { path } = this.$route;
      // 有自定义url的场合
      if (this.watermarkOpen.url.includes(path)) {
        if (this.pageWatermark[path]) {
          // 已经有缓存的话就用缓存
          this.changeWatermark(this.pageWatermark[path], {
            pageLevel: 1,
          });
          return;
        }
        // 取不到的话重新取一遍数据
        const success = await this.getPageWatermark(path);
        if (success) return;
      }
      // 不是自定义url 不过打开了通用水印的场合
      if (this.watermarkOpen.generic) {
        // 先取缓存
        if (this.pageWatermark.generic) {
          this.changeWatermark(this.pageWatermark.generic);
          return;
        }
        // 取不到时重新请求
        const success = await this.getGenericWatermark();
        if (success) return;
      }
      // 不是自定义url也没有开通用水印 则这个页面不该有水印 清除水印
      this.clearWatermark();
    },
    // 读取通常水印
    getGenericWatermark() {
      return GetWatermarkConfig({ appid: config.apiProduct.toString() })
        .then(({ config }) => {
          try {
            const content = config.contents[''].contents;
            if (content.length) {
              this.pageWatermark.generic = {
                content,
                style: [config.style_color, config.style_density],
              };
              this.changeWatermark(this.pageWatermark.generic);
              return true;
            }
          } catch (e) {
            console.log(e);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 读取自定义url水印
    getPageWatermark(path) {
      return GetWatermarkConfig({ type: 4, page_url: path })
        .then(({ config }) => {
          try {
            const content = config.contents[''].contents;
            if (content.length) {
              this.pageWatermark[path] = {
                content,
                style: [config.style_color, config.style_density],
              };
              this.changeWatermark(this.pageWatermark[path], { pageLevel: 1 });
              return true;
            }
          } catch (e) {
            console.log(e);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    levelCompare({ pageLevel = 0, containerLevel = -1, parentLevel = -1 } = {}) {
      // 自定义布局 > 任意布局
      if (pageLevel >= this.watermarkLevel.pageLevel) return true;
      if (pageLevel < this.watermarkLevel.pageLevel) return false;
      // 页面布局 > 容器布局
      if (containerLevel > this.watermarkLevel.containerLevel) return true;
      if (containerLevel < this.watermarkLevel.containerLevel) return false;
      // 父布局 > 子布局
      if (parentLevel > this.watermarkLevel.parentLevel) return true;
      return false;
    },
    hideWaterMark() {
      this.clearWatermark();
      this.routeChange();
    },
    async changeWatermark(data, level) {
      if (!this.levelCompare(level)) return;
      this.watermarkLevel = {
        pageLevel: 0,
        containerLevel: -1,
        parentLevel: -1,
        ...level,
      };

      const { content: rawContent, style: [color, density] = this.styleConfig } = data;
      const content = rawContent.map((i) => {
        if (i.length > 12) {
          return `${i.split('').slice(0, 11).join('')}...`;
        }
        return i;
      });
      this.styleConfig = [color, density];

      const size = WATERMARK_DENSITY[density];
      // cos20度约为0.94 (旋转后水印文字x轴长度的一半)/(旋转前水印文字长度的一半) = 0.94
      // sin20度约为0.34 (旋转后水印文字y轴长度的一半)/(旋转前水印文字长度的一半) = 0.34
      // (旋转后水印的高度带来的y轴高度增长的一半)/(旋转前水印的高度的一半) = 0.94
      const maxLength = Math.max(...content.map((i) => i.length));
      const maxWordLength = await this.getStrWidth(content.find((i) => i.length === maxLength));
      // 旋转后水印文字的x轴近似长度
      const wordXLength = Math.floor(maxWordLength * 0.94) + size;
      // eslint-disable-next-line prettier/prettier
      const wordYLength = Math.floor(0.34 * (maxWordLength)) + Math.floor((content.length * 18) / 2 * 0.94) + size;

      const can = document.createElement('canvas');
      this.$el.appendChild(can);
      can.width = wordXLength;
      can.height = wordYLength;
      can.style.display = 'none';
      const cans = can.getContext('2d');

      cans.translate(wordXLength / 2, wordYLength / 2);
      cans.rotate((20 * Math.PI) / 180);
      cans.translate(-wordXLength / 2, -wordYLength / 2);

      cans.fillStyle = WATERMARK_COLOR[color] || 'rgba(0, 0, 0, 0.1)';
      cans.font = '300 12px SimHei';
      cans.textAlign = 'center';
      cans.textBaseline = 'Middle';
      content.forEach((i, index) => {
        cans.fillText(i, wordXLength / 2, size / 2 + (index + 1) * 15 - 5);
      });
      this.$el.style.backgroundImage = `url(${can.toDataURL('image/png')})`;
      this.$el.removeChild(can);
    },
    getStrWidth(str) {
      return new Promise((res) => {
        this.maxStr = str;
        this.$nextTick(() => {
          res(Math.floor(this.$refs.length.offsetWidth));
        });
      });
    },
    clearWatermark() {
      this.$el.style.backgroundImage = '';
    },
    // 读取对象布局水印
    getObjectWatermark({ object, type, id, level, recordType }) {
      if (!object || !id) return;
      if (!this.watermarkOpen.objects.includes(object)) return;

      GetWatermarkConfig({
        type: 5,
        layout_object: object,
        layout_type: type,
        layout_record_type: recordType,
        data_ids: [id],
      })
        .then(({ config }) => {
          try {
            const content = config.contents[id].contents;
            if (content.length) {
              const data = {
                content,
                style: [config.style_color, config.style_density],
              };
              this.changeWatermark(data, level);
            }
          } catch (e) {
            console.log(e);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 更新水印数据
    updateWatermark({ id }) {
      const { path } = this.$route;
      GetWatermarkConfig({ type: 4, page_url: path, data_ids: [id] })
        .then(({ config }) => {
          try {
            const content = config.contents[id].contents;
            if (content.length) {
              const data = {
                content,
                style: [config.style_color, config.style_density],
              };
              this.changeWatermark(data, { pageLevel: 2 });
            }
          } catch (e) {
            console.log(e);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
