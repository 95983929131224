//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { MessagePlugin } from 'tdesign-vue';
import { cloneDeep, isArray, isNil, includes } from 'lodash';
import { handleDownload, handleExportTask } from './export.js';
import { isExpression, execExpression, execExpressionDeep } from '../../../../layout/expression';
import { DataService } from '../../../utils/api';
import { transData, queryGql, cmdPayload } from '../../../../layout/queryWeb';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    exportActionParams: {
      type: Object,
      default: () => {},
    },
    exportColumn: {
      type: Array,
      default: () => Array,
    },
    listJson: {
      type: Object,
      default: () => {},
    },
    objects: {
      type: Array,
      default: () => Array,
    },
    dataFormat: {
      type: Function,
      default: (e) => e,
    },
    filters: {
      type: Array,
      default: () => Array,
    },
    customGql: {
      // 这个应该不太大，可以传过去
      type: String,
      default: '',
    },
    search: {
      // 这个应该不太大，可以传过去
      type: Object,
      default: () => {
        return {
          fields: [],
          placeholder: '',
          input: '',
        };
      },
    },
    list: {
      // 比较大，暂时不清楚如何处理。
      type: Array,
      default: () => Array,
    },
    params: {
      // 这个应该不太大，可以传过去
      type: [Array, String, Number, Object, null],
      default() {
        return null;
      },
    },
    parentData: {
      // 比较大，暂时不清楚如何处理。
      type: Object,
      default() {
        return {};
      },
    },
    translateParams: {
      // 作为string传过去？？
      type: [Function, null],
      default: null,
    },
    processData: {
      // 作为string传过去？？
      type: Function,
      default: (v) => v,
    },
    expParamsObj: {
      // 比较大，暂时不清楚如何处理。
      type: Object,
      default: () => ({}),
    },
    customCondition: {
      // 前端穿给后台
      type: String,
      default: '',
    },
    otherConditionStr: {
      // 前端穿给后台
      type: String,
      default: '',
    },
    response: {
      // 前端穿给后台
      type: String,
      default: '',
    },
    pHookRequestData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isExportLoading: false,
      isError: false,
    };
  },
  computed: {
    sync() {
      return (
        !this.exportActionParams?.export_types?.length
        || includes(this.exportActionParams.export_types, 'sync')
      );
    },
    async() {
      return includes(this.exportActionParams.export_types, 'async');
    },
    syncText() {
      return (
        this.sync
        && (this.exportActionParams.sync_text
          || '继续在当前页下载，页面加载时间较长，加载完成后在当前页自动下载，下载完成之前请勿关闭当前页面')
      );
    },
    asyncText() {
      return (
        this.async
        && (this.exportActionParams.async_text
          || '创建导出任务，待任务完成后，可在【数据批量操作记录页面】点击下载文件')
      );
    },
    asyncSuccessMessage() {
      return (
        this.async
        && (this.exportActionParams.async_success_message
          || '创建导出任务，待任务完成后，可在【数据批量操作记录页面】点击下载文件')
      );
    },
    exportTaskLink() {
      return this.exportActionParams.export_task_link || '/saas/dataRecord.html?tab_index=2';
    },
  },
  methods: {
    async handleAsyncExport() {
      // const excelName = this.listJson.title || '';
      //
      // const type = this.exportActionParams.export_type || 3;
      // if (type === 3) {
      //   const object = this.exportActionParams.object || this.listJson.object || '';
      //   if (!object) {
      //     this.$message.error({
      //       // eslint-disable-next-line no-unused-vars
      //       content: '没有配置导出对象',
      //     });
      //     return;
      //   }
      //   await this.handleAsyncExportObject(excelName, object);
      // } else if (type === 1) {
      //   const funcName = this.exportActionParams?.func_name || '';
      //   const funcParam = this.exportActionParams?.func_param || '';
      //   if (!funcName) {
      //     this.$message.error({
      //       // eslint-disable-next-line no-unused-vars
      //       content: '没有配置云函数名',
      //     });
      //     return;
      //   }
      //   await this.handleAsyncExportCustom(excelName, funcName, funcParam);
      // }
      // MessagePlugin.success({
      //   // eslint-disable-next-line no-unused-vars
      //   content: (h) => {
      //     return (
      //       <span>
      //         {excelName}文件导出任务已创建，请稍后在【
      //         {this.exportActionParams?.target_path_name || '数据批量操作记录'}】手动点击下载，
      //         <t-link
      //           theme="primary"
      //           href={this.exportActionParams?.target_path_url || '/saas/dataRecord.html'}
      //         >
      //           去下载
      //         </t-link>
      //       </span>
      //     );
      //   },
      //   duration: 6000,
      // });
    },
    // async handleAsyncExportObject(name, objectName) {
    //   const gql = this.generateAsyncExportGql(name, 3, objectName);
    //   console.log('-----gql------', gql);
    //   await DataService({
    //     query: gql,
    //   });
    // },
    // async handleAsyncExportCustom(name, funcName, funcParam) {
    //   const execdFuncName = execExpression(funcName, {
    //     t: this.list,
    //     p: this.params,
    //     json: this.listJson,
    //   });
    //
    //   let execdFuncParam = execExpression(funcParam, {
    //     t: this.list,
    //     p: this.params,
    //     json: this.listJson,
    //   });
    //
    //   if (typeof execdFuncParam !== 'string') {
    //     execdFuncParam = JSON.stringify(execdFuncName).replaceAll('"', '\\"');
    //   }
    //   const gql = this.generateAsyncExportGql(name, 1, execdFuncParam, execdFuncParam);
    //   await DataService({
    //     query: gql,
    //   });
    // },
    // generateAsyncExportGql(name, type, objectName = '', params = '') {
    //   const gql = `
    //   mutation insert {
    //     data_task(_values: {
    //       name: "${name}",
    //       type: 2,
    //       remark: "${name}异步导出",
    //       export_type: ${type},
    //       export_file_type: 1,
    //       export_object: "${objectName}",
    //       ${params ? `export_param: "${params}",` : ''}})}`;
    //   return gql;
    // },
    async handleSyncExport(exportType = 'sync') {
      try {
        this.isExportLoading = true;
        const { object } = this.listJson;
        const objectItem = this.objects.filter((item) => item.object.name === object)[0];
        const excelName =
          this.listJson.render?.excel_name || objectItem?.object.display_name || object || '';

        const type = this.listJson?.cmd ? 'cmd' : '';
        if (type !== 'cmd') {
          await this.handleNodeJsExport(excelName, '', exportType);
        } else {
          await this.handleNodeJsExport(excelName, 'cmd', exportType);
        }
        if (exportType === 'async') {
          const message
            = execExpressionDeep(this.asyncSuccessMessage, {
              t: {
                filename: excelName,
              },
            }) || `${excelName}文件导出任务已创建，请稍后在【数据批量操作记录】手动点击下载，`;
          this.$message({
            dangerouslyUseHTMLString: true,
            message: `<span>${message}<a style="color:#0077FF" href="${this.exportTaskLink}" target="_blank" >去下载</a></span>`,
          });
        } else {
          this.$message.success('下载成功');
        }
        this.isError = false;
        this.isExportLoading = false;
        this.$emit('close');
      } catch (err) {
        console.log('---err---', err);
        this.isExportLoading = false;
        if (exportType === 'async') {
          this.$message.error('创建异步导出任务失败');
        } else {
          this.$message.error('下载失败');
        }
        this.isError = true;
      }
    },
    ignoreFilterOptions(obj) {
      function clearOptions(obj) {
        if (Array.isArray(obj)) {
          obj.forEach((item) => {
            clearOptions(item);
          });
        } else if (typeof obj === 'object') {
          for (const key in obj) {
            if (key === 'options' || key === 'queryOptions') {
              obj[key] = [];
            } else {
              clearOptions(obj[key]);
            }
          }
        }
      }
      const newObj = cloneDeep(obj);
      clearOptions(newObj);
      return newObj;
    },
    async handleNodeJsExport(filename, type, exportType) {
      const gql = this.generateGql({
        limit: '__$LIMIT$__',
        offset: '__$OFFSET$__',
      });
      const { total, expParamsObj, exportColumn } = this;
      const { rawPayload = {} } = this.listJson;
      const pagePayload = execExpressionDeep(cloneDeep(rawPayload), {
        t: { p: this.parentData },
        $hook: this.pHookRequestData,
      });
      const cmdExportPayload = this.translateParams
        ? this.translateParams({
            page_index: 0,
            page_size: this.total,
            input: this.search.input,
            filters: this.ignoreFilterOptions(this.filters.map((item) => item.filter)),
            json: this.ignoreFilterOptions(this.listJson),
            pagePayload,
            $hook: this.hookRequestData,
          })
        : cmdPayload({
            json: this.ignoreFilterOptions(this.listJson),
            page: 0 + 1,
            pageSize: total,
            input: this.search.input,
            filters: this.ignoreFilterOptions(this.filters),
            parentData: this.parentData,
            $hook: this.pHookRequestData,
          });
      const payload = {
        gql,
        expParamsObj: this.ignoreFilterOptions(expParamsObj),
        exportColumn,
        total,
        filename,
        type,
        cmdPayload: type === 'cmd' ? cmdExportPayload : '',
      };
      if (exportType === 'async') {
        await handleExportTask(payload);
      } else {
        await handleDownload(filename, payload);
      }
    },
    generateGql(params) {
      const execExp = (data) => {
        return execExpressionDeep(cloneDeep(data), this.expParamsObj);
      };
      const newJson = {
        ...this.listJson,
        where: execExp(this.listJson?.where),
        wheres: execExp(this.listJson?.wheres),
        orders: execExp(this.listJson?.orders),
        sub_query: execExp(this.listJson?.sub_query),
      };
      const { columns } = this.listJson?.render || {};
      return queryGql({
        json: newJson,
        objects: this.objects,
        input: this.search.input,
        params,
        filters: this.filters.map((item) => item.filter),
        customWhere: [this.otherConditionStr, this.customCondition]
          .filter((item) => item)
          .join(','),
        customGql: this.customGql,
        columns,
      });
    },
    McmAdminService(params) {
      const execExp = (data) => {
        return execExpressionDeep(cloneDeep(data), this.expParamsObj);
      };
      const newJson = {
        ...this.listJson,
        where: execExp(this.listJson?.where),
        wheres: execExp(this.listJson?.wheres),
        orders: execExp(this.listJson?.orders),
        sub_query: execExp(this.listJson?.sub_query),
      };
      const { columns } = this.listJson?.render || {};
      return DataService({
        query: queryGql({
          json: newJson,
          objects: this.objects,
          input: this.search.input,
          params,
          filters: this.filters.map((item) => item.filter),
          customWhere: [this.otherConditionStr, this.customCondition]
            .filter((item) => item)
            .join(','),
          customGql: this.customGql,
          columns,
        }),
      }).then((data) => {
        data = this.formatAggregate({ data, columns: columns || [] });
        const tableData = transData({
          json: this.listJson,
          objects: this.objects,
          data,
        });
        return tableData;
      });
    },
    formatAggregate({ data, columns }) {
      const aggregates = columns.filter(
        (column) => column?.aggregate && !column.field.includes('.'),
      );
      if (data?.[this.listJson?.object]?._aggregate && aggregates?.length) {
        // $isAggregate代表是否包含聚合行
        const aggregateItem = { $isAggregate: true };
        const aggregateData = data?.[this.listJson?.object]?._aggregate;
        const aggregateKeys = Object.keys(aggregateData || {});
        aggregates.forEach((column) => {
          const { label } = column.aggregate;
          const key = aggregateKeys.find((aggregateKey) =>
            aggregateKey.includes(`${column.field}`),
          );
          if (key) {
            const value = aggregateData[key];
            if (!isNil(label)) {
              const { label } = column.aggregate;
              aggregateItem[column.field] = isExpression(label)
                ? execExpression(label, { t: value })
                : label;
            } else {
              aggregateItem[column.field] = value;
            }
          } else if (!isNil(label)) {
            aggregateItem[column.field] = label;
          }
        });
        if (isArray(data?.[this.listJson?.object]?._data)) {
          data[this.listJson?.object]._data.unshift(aggregateItem);
        }
      }
      return data;
    },
    async handleCancel() {
      this.isExportLoading = false;
      this.$emit('close');
      this.isError = false;
    },
  },
};
