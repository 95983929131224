//
//
//
//
//
//
//
//
//
//
//
//
//

import DashboardBlock from './block';

export default {
  name: 'MiniDashboard',
  components: { DashboardBlock },
  props: {
    json: {
      type: Array,
      default: () => [],
    },
    params: {
      type: Object,
      default: () => {},
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    init() {
      if (this.json.length) {
        this.$refs.block.forEach((ele) => {
          ele.init();
        });
      }
    },
  },
};
