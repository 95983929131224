//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { QueryHospitalDepartment } from '@/api/setUp.js';
export default {
  name: 'SelectOnlineComp',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: '请选择',
    },
    config: {
      type: Object,
    },
  },
  data() {
    return {
      options: [],
      text: '无数据',
      value: '',
    };
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {
    // this.getList();
    QueryHospitalDepartment(this.config.url, {
      ...this.config.query,
      page_index: 1,
      page_size: 999,
    }).then((res) => {
      console.log(res);
      this.options = res.data.map((item) => ({
        value: item[Object.keys(item)[0]],
        label: item[Object.keys(item)[1]],
      }));
    });
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    // 获取列表信息
    // async getList() {
    //   const _this = this;
    //   _this.text = '加载中';
    //   try {
    //     const res = await QueryHospitalDepartment(this.config.url, {
    //       ...this.config.query,
    //       page_index: 1,
    //       page_size: 999,
    //     });
    //     const data = res.data.map((item) => ({
    //       value: item[Object.keys(item)[0]],
    //       label: item[Object.keys(item)[1]],
    //     }));
    //     this.options = data ? data : [];
    //   } catch (error) {
    //     console.log(error);
    //     _this.$message('网络请求失败, 请稍后再试');
    //   }
    //   _this.text = '无数据';
    // },
    selectChange(e) {
      this.$emit('childEmit', e);
    },
  },
};
