//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isArray, get } from 'lodash';
export default {
  name: 'StaticTable',
  props: {
    tableHeader: {
      type: Object,
      default: () => ({}),
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    tableAction: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      curPage: 1,
    };
  },
  computed: {
    showData() {
      return (this.tableData || []).slice(
        (this.curPage - 1) * this.pageSize,
        this.curPage * this.pageSize,
      );
    },
  },
  watch: {
    pageSize() {
      this.reload();
    },
    tableData() {
      this.reloadCurPage();
    },
    tableHeader() {
      this.reload();
    },
  },
  methods: {
    isArray,
    get,
    reload() {
      this.curPage = 1;
    },
    // 当前页如果没有数据，则默认刷新到前一页
    reloadCurPage() {
      if (this.curPage !== 1 && (this.curPage - 1) * this.pageSize >= this.tableData.length) {
        this.curPage = this.curPage - 1;
      }
    },
    curPageChange(page) {
      this.curPage = page;
    },
  },
};
