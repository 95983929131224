import Cos from './index';
import { getUploadSignApi } from '@/api/common.js';
import config from '@/config';

const { cosAppId } = config;
Cos.initConfig({
  getTokenFunc: async ({ suffix }) => {
    const res = await getUploadSignApi({
      app_id: cosAppId,
      credentials: 1,
      suffix,
    });
    const { session_token, tmp_secret_id, tmp_secret_key } = res?.data?.credentials || {};
    const {
      cdn_url: cdnUrl,
      key,
      startTime,
      expiredTime,
      region,
      bucket,
      x_cos_security_token,
      domain,
    } = res?.data || {};
    return {
      tmpSecretId: tmp_secret_id,
      tmpSecretKey: tmp_secret_key,
      sessionToken: session_token,
      cdnUrl,
      key,
      startTime,
      expiredTime,
      region,
      bucket,
      x_cos_security_token,
      domain,
    };
  },
});

export default Cos;
