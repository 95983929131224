import '@/utils/qqmap-web-jssdk.js';
import { jsonp } from 'vue-jsonp';
import config from '@/config';

export const mapKey = config.tencentMapKey || 'AIJBZ-K7FCU-GI3VC-GRWLB-6C6TH-35FJV';

const QQMap = new window.qq.maps.Geolocation(mapKey, 'nges-web');

export async function getQQMapLocation(options = { timeout: 8000 }) {
  return new Promise((resolve, reject) => {
    QQMap.getLocation(
      (res) => {
        console.log('当前位置:', res);
        resolve(res);
      },
      (err) => {
        reject(err);
      },
      options,
    );
  });
}

export async function getLocation(options = { timeout: 8000 }) {
  return new Promise(async (resolve, reject) => {
    let result = null;
    try {
      result = await browserGetLocation(options);
    } catch (e) {
      if (e.message) {
        resolve({
          lat: 40.043453,
          lng: 116.273585,
        });
        return;
      }
      result = await getQQMapLocation(options);
    }
    if (result && result?.lat && result?.lng) {
      resolve(result);
    } else {
      console.err('获取当前定位失败');
      reject('定位失败');
    }
  });
}

const browserGetLocation = (options = { timeout: 8000 }) => {
  return new Promise((resolve, reject) => {
    // 成功回调
    function success(pos) {
      const crd = pos.coords;
      console.log('browserGetLocation当前位置:', pos);
      resolve({
        lat: crd.latitude,
        lng: crd.longitude,
      });
    }
    // 失败回调
    function error(err) {
      reject(err);
    }
    navigator.geolocation.getCurrentPosition(success, error, options);
  });
};

export async function getNearHospital(lat = 0, lng = 0) {
  let latitude = lat;
  let longitude = lng;
  if (!lat || !lng) {
    const res = await getLocation();
    latitude = res?.lat;
    longitude = res?.lng;
  }
  const result = await jsonp('https://apis.map.qq.com/ws/place/v1/search', {
    output: 'jsonp',
    key: mapKey,
    keyword: '医疗保健',
    page_index: 1,
    page_size: 20,
    boundary: `nearby(${latitude},${longitude},1000)`,
  });

  return result;
}

export function getCheckVal(point1, point2) {
  const { longitude: targetX, latitude: targetY } = point1;
  const { longitude: userX, latitude: userY } = point2;
  const distance = getMapDistance(userY, userX, targetY, targetX);
  return {
    distance: parseInt(distance, 10),
    userX: parseInt(userX, 10),
    userY: parseInt(userY, 10),
    targetX: parseInt(targetX, 10),
    targetY: parseInt(targetY, 10),
  };
}

// 进行经纬度转换为距离的计算
export function Rad(d) {
  // 经纬度转换成三角函数中度分表形式。
  return (d * Math.PI) / 180.0;
}

// 计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度
// 数值单位米
export function getMapDistance(lat1, lng1, lat2, lng2) {
  lat1 = lat1 || 0;
  lng1 = lng1 || 0;
  lat2 = lat2 || 0;
  lng2 = lng2 || 0;

  const rad1 = (lat1 * Math.PI) / 180.0;
  const rad2 = (lat2 * Math.PI) / 180.0;
  const a = rad1 - rad2;
  const b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
  const r = 6378137;
  const distance =
    r *
    2 *
    Math.asin(
      Math.sqrt(
        // eslint-disable-next-line no-restricted-properties
        Math.pow(Math.sin(a / 2), 2) +
          // eslint-disable-next-line no-restricted-properties
          Math.cos(rad1) * Math.cos(rad2) * Math.pow(Math.sin(b / 2), 2),
      ),
    );
  return distance;
}
