import request from '@/libs/request';
import {
  UserList,
  AllUserCount,
  UserInfoList,
  MCCPUser,
  LeaderTerritory,
  UserInfoGql,
} from '@/graphql/gql/user';
import { calVariables } from '@/graphql/util';
import { DataService } from 'nges-common/src/web/utils/api';

export function queryUserList(data) {
  return DataService({
    query: calVariables(UserList, data),
  });
}

export function queryAllUserCount(data) {
  return DataService({
    query: calVariables(AllUserCount, data),
  });
}

export function queryUserInfoList(data) {
  return DataService({
    query: calVariables(UserInfoList, data),
  });
}

export function queryMCCPUser(data) {
  return DataService({
    query: calVariables(MCCPUser, data),
  });
}

export function queryLeaderTerritory() {
  return DataService({
    query: LeaderTerritory,
  });
}

export function sendEmailCall(data) {
  return request('McmServer/ArticleListService/SendDocumentEmail', {
    sendToEmail: [data.adress],
    title: data.title,
    document_url: data.url,
    expire: data.expire,
  });
}

export function queryUserInfo(data = {}) {
  let { where_qgl: whereGql, other_keys: ohterKeys } = data;
  if (whereGql) {
    whereGql = `, _where: ${whereGql}`;
    delete data.where_qgl;
  } else {
    whereGql = '';
  }

  if (ohterKeys?.length) {
    ohterKeys = `, ${ohterKeys.join(',')}`;
    delete data.other_keys;
  } else {
    ohterKeys = '';
  }
  return DataService({
    query: calVariables(
      UserInfoGql.replace('$where_qgl', whereGql).replace('$other_keys', ohterKeys),
      data,
    ),
  });
}
