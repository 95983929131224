module.exports = [
  {
    env: 'preview',
    domain: ['astrazeneca.nges.qq.com'],
    mobileDomain: 'astrazeneca-h5.nges.qq.com',
    aegisId: 'Yrpg3ibmn0Led7nQY3',
    reportDomain: 'baike.sparta.html5.qq.com',
  },
  {
    env: 'preview',
    domain: ['ncep-pre.astrazeneca.cn'],
    mobileDomain: 'ncep-h5-pre.astrazeneca.cn',
    aegisId: 'aZxYPCDXG7bb4PP73X',
    reportDomain: 'baike.sparta.html5.qq.com',
  },
];
