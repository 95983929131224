var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"s-selected-button",class:{
    's-disabled': _vm.disabled,
    's-absolute': _vm.absolute,
  },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleClick.apply(null, arguments)}}},[_c('span',{class:{
      's-common': true,
      's-checkbox': !_vm.isRadio,
      's-radio': _vm.isRadio,
      's-selected': _vm.selected,
    }})])}
var staticRenderFns = []

export { render, staticRenderFns }