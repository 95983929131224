//
//
//
//
//
//
//

import { IMG_SRC } from '@/utils/constant';
export default {
  name: 'NoData',
  props: {
    noImage: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: IMG_SRC.bgWhite,
    },
    tips: {
      type: String,
      default: '暂无相关内容',
    },
  },
};
