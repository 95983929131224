//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { MINE_MAP, MINE_MAP_TEXT } from '../../../constant';
import { isEqual, cloneDeep } from 'lodash';
import { Message } from 'element-ui';
import { GetPrivateObjUrl } from 'nges-common/src/web/utils/api';
import AttachmentMask from '../attachment-mask';
const _ = { isEqual, cloneDeep };

export default {
  name: 'PreviewFile',
  components: {
    AttachmentMask,
  },
  props: {
    fileList: {
      type: [Array, null],
      default: () => [],
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
    detailInfo: {
      type: Object,
      default: () => {},
    },
    // 在编辑布局内不展示空占位符
    isMutation: {
      type: Boolean,
      default: false,
    },
    setMask: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: false,
      imgExtension: MINE_MAP.image,
      originFileList: [],
      currentFileList: [],
      type: '',
      url: '',
    };
  },
  watch: {
    fileList(val) {
      if (!_.isEqual(this.originFileList, val)) {
        this.originFileList = val;
        this.currentFileList = _.cloneDeep(val);
        if (this.isPrivate) {
          this.getPrivateFileList();
        }
      }
    },
  },
  mounted() {
    this.currentFileList = _.cloneDeep(this.fileList);
    this.originFileList = this.fileList;
    if (this.isPrivate) {
      this.getPrivateFileList();
    }
  },
  methods: {
    close() {
      this.isShow = false;
    },
    mutationOpen(url, type) {
      this.url = url;
      this.type = type;
      this.isShow = true;
    },
    // 预览图片/文件
    handlePreview(file) {
      const isOther = !['image', 'video', 'audio'].some((type) => {
        if (MINE_MAP[type].includes(file.type)) {
          this.url = this.isPrivate ? file.public_url : file.url;
          this.type = type;
          this.isShow = true;
          return true;
        }
      });
      if (isOther) {
        if (this.isPrivate && !file.public_url) return;
        const a = document.createElement('a');
        a.href = this.isPrivate ? file.public_url : file.url;
        a.target = '_blank';
        a.click();
      }
    },
    changeDataType(url) {
      return fetch(url)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          return URL.createObjectURL(blob);
        });
    },
    async download(file) {
      if (this.isPrivate && !file.public_url) return;
      const message = Message({
        message: `${file.name}文件正在生成中`,
        duration: 0,
      });
      try {
        const a = document.createElement('a');
        a.href = await this.changeDataType(this.isPrivate ? file.public_url : file.url);
        a.download = file.name || +new Date();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        message.close();
      } catch (e) {
        message.close();
        window.open(file.url);
      }
    },
    getTypeText(type) {
      const typeKey = Object.keys(MINE_MAP).find((key) => {
        return MINE_MAP[key].includes(type);
      });
      return MINE_MAP_TEXT[typeKey] || type;
    },
    getPrivateFileList() {
      this.currentFileList.forEach(async (i) => {
        i.public_url = await this.getPrivateUrl(i.url);
        this.$forceUpdate();
      });
    },
    async getPrivateUrl(url) {
      if (url) {
        try {
          const res = await GetPrivateObjUrl({
            app_id: 'nges',
            expires: 300,
            url,
            object: this.detailInfo?.detailObject,
            field: this.detailInfo?.detailField,
            data_id: this.detailInfo?.detailValue?.id,
          });
          return res?.data?.url;
        } catch (e) {
          console.log(e?.message || e);
        }
      }
      return url;
    },
  },
};
