import { request as requestSDK, storeRequest as storeRequestSDK } from './request';
import { handleResult } from './graphql';

function request(url, data) {
  return requestSDK(url, data).then((res) => handleResult(res, data.query, data));
}

function storeRequest(opt, option) {
  return storeRequestSDK(opt, option).then((res) =>
    handleResult(res, opt?.payload?.query, opt?.payload),
  );
}

export { request, storeRequest };
export default request;
