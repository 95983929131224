import { cloneDeep } from 'lodash';
import { easyQuery } from './query';
import { request } from './request';
const _ = { cloneDeep };

// 从json中获取详情查询的key
function getIdKey({ json }) {
  const { id_key: idKey } = json;
  const idKeyName = idKey || 'id';
  return idKeyName;
}

// 通过ID查询数据对象详情
async function queryById({ json, id }) {
  const { object } = json;
  const idKeyName = getIdKey({ json });
  const result = await easyQuery(_.cloneDeep(json), {
    [object]: { _limit: 1, id: { key: idKeyName, value: id } },
  });
  return result?.[0] || {};
}

async function cmdQuery({ cmd, payload }) {
  return request(cmd, payload);
}

export { queryById, cmdQuery };
