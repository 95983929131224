let GetAppConfig;

GetAppConfig = require('../../web/utils/api').GetAppConfig;


export default {
  methods: {
    async __trkGetAppConf() {
      const appConfig = await GetAppConfig();
      this.__trkEnable = appConfig?.page_config?.user_event_tracking === '1' || false;
      this.__trkEnums = appConfig?.event_tracking_enums || {};
    },
    // 组装一些默认值，比如：事件名称可以通过组装的方式获取
    __trkGetDefaultData(reportData) {
      if (!reportData.event_name && reportData.event_label && reportData.event_type) {
        const eventTypeName
          = this.__trkEnums[`event_type/${reportData.event_type}`] || reportData.event_type || '';
        reportData.event_name = `${reportData.event_label}${eventTypeName}`;
      }
      return reportData;
    },
  },
};
