
import { isArray, isPlainObject, pick } from 'lodash';

const sessionId = (function () {
  return `session-${Date.now().toString(36)}-${Math.random().toString(36).substr(2, 10)}`;
})();
let sessionSeq = 0;

// 获取进入页面就不会改变的参数
export function getStaticCommonReportParams() {
  let appid;
  let domain;
  let uriPath;
  let queryString;
  let referrerUrl;
  let deviceInfo;
  
  appid = window.globalAppid || '';
  
  
  domain = location.host;
  uriPath = location.pathname;
  queryString = location.search;
  referrerUrl = document.referrer;
  deviceInfo = navigator.userAgent;
  
  return {
    session_id: sessionId,
    appid,
    domain,
    uri_path: uriPath,
    query_string: queryString,
    referrer_url: referrerUrl,
    device_info: deviceInfo,
  };
}
// 获取不同时机时候的参数
export function getDynamicCommonReportParams() {
  return {
    // eslint-disable-next-line no-plusplus
    session_seq: ++sessionSeq,
    event_timestamp: parseInt(new Date().getTime() / 1000),
  };
}

export function getReportConfigs(configs) {
  let reportConfigs = configs || [];
  if (isPlainObject(reportConfigs)) {
    reportConfigs = [reportConfigs];
  }
  if (!isArray(reportConfigs)) {
    reportConfigs = [];
  }
  return reportConfigs;
}
