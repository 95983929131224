import { render, staticRenderFns } from "./tagSelect.vue?vue&type=template&id=d72d6b36&scoped=true&"
import script from "./tagSelect.vue?vue&type=script&lang=js&"
export * from "./tagSelect.vue?vue&type=script&lang=js&"
import style0 from "./tagSelect.vue?vue&type=style&index=0&id=d72d6b36&prod&lang=scss&"
import style1 from "./tagSelect.vue?vue&type=style&index=1&id=d72d6b36&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d72d6b36",
  null
  
)

export default component.exports