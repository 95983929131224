//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import login from '@/utils/login.js';
import {
  LoginGetPhone,
  SendSmsCode,
  LoginAsOtherByPhone,
  TriggerLoginTerritory,
  LoginAsOtherByPasswd,
  LoginAsOtherQuick,
} from '@/api/login';
import { GetStaffUserInfo, GetKvConfigs, GetAccountUserInfo } from 'nges-common/src/web/utils/api';
import { gotoHome } from '@/utils/util';
import { getKvConfigs } from '@/graphql/api/meeting';
import { getLayoutJson } from 'nges-common/src/layout/queryWeb';
import { getNewLayoutJson } from 'nges-common/src/layout/queryUtil';
const DEFAULT_JSON = {
  distinct: true,
  alias: {
    departments: 'department',
    territorys: 'territory',
  },
  object: 'user_info',
  fields: [
    {
      field: 'name',
      label: '名字',
    },
    {
      field: 'departments.name',
      label: '部门',
    },
    {
      field: 'territorys.name',
      label: '岗位',
    },
  ],
  orders: [
    {
      field: 'name_pinyin',
      type: '_asc',
    },
  ],
  where: {
    wheres: [
      {
        field: 'id',
        value: false,
        where_type: '_is_null',
      },
      {
        field: 'uin',
        value: false,
        where_type: '_is_null',
      },
    ],
  },
  render: {
    search: {
      self: true,
      fields: ['email', 'departments.name', 'name'],
      placeholder: '搜索部门/员工名/员工账号',
    },
  },
};

export default {
  name: 'AdminLogin',
  data() {
    return {
      listVisible: false,
      tmpChecked: {},
      customCondition: '',
      listLayoutJson: null,
      errorMsg: '',
      loginType: 'phone',
      formData: {
        phone: '',
        code: '',
        account: '',
        password: '',
        user: {},
      },
      timeLimit: 0,
      loading: false,
      // 账号绑定的手机号
      phone: '',
      // 是否需要短信验证码
      needCode: false,
      showTerritory: false,
      currentTerritoryCode: '',
      originTerritoryCode: '',
      territoryList: [],
      territoryCode: '',
      rules: {
        phone: [{ required: true, message: '请输入管理员手机号', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        account: [{ required: true, message: '请选择账户', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        user: [
          { required: true, message: '请选择目标账户', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              if (!value?.name) {
                callback(new Error('请选择目标账户'));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },
        ],
      },
    };
  },
  computed: {
    ...mapState('user', {
      menuList: 'menuList',
    }),
    timerText() {
      if (this.timeLimit > 0) return `${this.timeLimit}s`;
      return '获取验证码';
    },
  },
  created() {
    const checkLoginType = async () => {
      try {
        const { kv } = await GetKvConfigs('["login_as_by"]');
        if (kv === null) {
          // kv值不存在 走之前的逻辑
          const res = await GetKvConfigs('["login_as_account"]')
            .then(({ kv }) => {
              if (!kv) return false;
              if (kv[0].value === '1') return true;
              return false;
            })
            .catch((e) => {
              console.log(e);
              return false;
            });
          if (res) this.loginType = 'account';
          this.initData();
        } else if (kv[0].value === 'quick') {
          this.loginType = 'quick';
          this.initData();
        } else if (kv[0].value === 'phone') {
          this.loginType = 'phone';
          this.initData();
        } else if (kv[0].value === 'passwd') {
          this.loginType = 'account';
          this.initData();
        }
      } catch (e) {
        console.log(e);
      }
    };
    checkLoginType();
    // GetKvConfigs('["login_as_account"]')
    //   .then(({ kv }) => {
    //     if (!kv) return false;
    //     if (kv[0].value === '1') return true;
    //     return false;
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     return false;
    //   })
    //   .then((res) => {
    //     if (res) this.loginType = 'account';
    //     this.initData();
    //   });
  },
  methods: {
    selectionChange(e) {
      this.tmpChecked = e;
    },
    search() {
      this.listVisible = true;
    },
    close() {
      this.listVisible = false;
      this.tmpChecked = {};
    },
    save() {
      this.formData.user = this.tmpChecked;
      this.close();
    },
    getCode() {
      if (this.gettingCode) {
        return;
      }
      this.gettingCode = true;
      SendSmsCode()
        .then(() => {
          this.timeLimit = 30;
          this.timer = setInterval(() => {
            this.timeLimit -= 1;
            if (this.timeLimit === 0) {
              clearInterval(this.timer);
            }
          }, 1000);
        })
        .catch((e) => {
          this.errorMsg = e.message || '验证码发送失败';
        })
        .finally(() => {
          this.gettingCode = false;
        });
    },
    getPhone() {
      const defaultTip = '该账号未绑定手机号，请联系管理员绑定手机号后登录';
      LoginGetPhone()
        .then(({ phone }) => {
          if (phone) {
            this.formData.phone = phone;
          } else {
            this.errorMsg = defaultTip;
          }
        })
        .catch((err) => {
          this.errorMsg = err.message || defaultTip;
        });
    },
    getAccount() {
      GetAccountUserInfo().then(({ account }) => {
        this.formData.account = account;
      });
    },
    async getListJson() {
      const tenantConfig = getLayoutJson(this.menuList, 'tenant');
      const remoteJson = tenantConfig?.login_as_config?.user_list_layout;
      const newLayoutJson = await getNewLayoutJson({
        object: 'user_info',
        layoutType: 'list',
        recordType: '',
        usage: '',
      });
      this.listLayoutJson = newLayoutJson || remoteJson || DEFAULT_JSON;
    },
    initData() {
      if (this.loginType === 'account') {
        this.getAccount();
      }
      if (this.loginType === 'phone') {
        this.getPhone();
      }
      this.getListJson();
    },
    triggerLoginTerritory() {
      if (this.originTerritoryCode === this.currentTerritoryCode) return Promise.resolve();
      return TriggerLoginTerritory({ territory_code: this.currentTerritoryCode });
    },
    async login() {
      if (!this.showTerritory) {
        this.$refs.form.validate((valid) => {
          if (!valid) {
            return;
          }
          if (this.loading) return;
          login.setStorageType('cookie');
          this.loading = true;
          const promise =
            this.loginType === 'quick'
              ? LoginAsOtherQuick({
                  login_id: this.formData.user.id,
                })
              : this.loginType === 'phone'
              ? LoginAsOtherByPhone({
                  login_id: this.formData.user.id,
                  sms_code: this.formData.code,
                })
              : LoginAsOtherByPasswd({
                  login_id: this.formData.user.id,
                  password: this.formData.password,
                });
          return promise
            .then((res) => {
              if (res?.token) {
                // 存储当前用户信息
                login.pushUser();
                login.setLogin({
                  token: res.token,
                  uin: res.uin,
                  time: res.expires_in,
                });
                this.loginSuccess();
                return;
              }
              this.errorMsg = 'no token';
            })
            .catch((e) => {
              const errorMsg = e.message || '登录失败';
              this.errorMsg = errorMsg;
              this.$message.error(errorMsg);
            })
            .finally(() => {
              this.loading = false;
            });
        });
      } else {
        if (this.loading) return;
        this.loading = true;
        this.triggerLoginTerritory()
          .then(() => {
            this.$message.success('目标员工账号登录成功');
            gotoHome();
          })
          .catch((err) => {
            const errorMsg = err.message || '登录失败';
            this.errorMsg = errorMsg;
            this.$message.error(errorMsg);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    changeTerritory(e) {
      this.currentTerritoryCode = e;
    },
    async isCanChooseTerritory() {
      const res = await getKvConfigs({
        keys: ['territory_choose'],
      });
      return res.kv.find((item) => item.key === 'territory_choose')?.value === '1';
    },
    async loginSuccess() {
      try {
        const canChooseTerritory = await this.isCanChooseTerritory();
        if (canChooseTerritory) {
          await this.getStaffUserInfo();
          if (this.territoryList.length > 1) {
            this.showTerritory = true;
            this.originTerritoryCode = this.territoryCode;
            this.currentTerritoryCode = this.territoryCode;
          } else {
            this.$message.success('登录成功');
            gotoHome();
          }
        } else {
          this.$message.success('登录成功');
          gotoHome();
        }
      } catch (err) {
        console.error(err);
        this.$message.error(err.message || '登录失败，请稍后重试');
      }
    },
    getStaffUserInfo() {
      return GetStaffUserInfo().then((res) => {
        this.territoryList = res?.territory_list || [];
        this.territoryCode = res?.current_territory;
      });
    },
  },
};
