//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AddNoPublic',
  components: {},
  props: {
    isedit: {
      type: Boolean,
      default: false,
    },
    // 盒子状态
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    publicValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: [],
    };
  },
  computed: {
    publicList() {
      return this.$store.getters['user/getPublishList'];
    },
  },
  watch: {
    publicValue(newval) {
      this.value = JSON.parse(JSON.stringify(newval));
    },
    // 关闭盒子清空数据
    async dialogVisible(newVal) {
      if (newVal) {
        this.value = this.publicValue;
      } else {
        this.value = [];
      }
    },
  },
  mounted() {
    this.value = JSON.parse(JSON.stringify(this.publicValue));
  },
  methods: {
    handleBtn() {
      const { value } = this;
      if (value.length === 0) {
        this.$message('请选择公众号');
        return;
      }
      const emitedValue = value.map((item) => {
        const label = this.publicList.find((item1) => item1.id === item.value)?.name;
        return {
          label,
          value: item.value,
        };
      });
      this.$emit('emitUserPublic', emitedValue);
      this.handleClose();
    },
    // 关闭盒子 清空数据
    handleClose() {
      this.$emit('update:dialogVisible', false);
    },
  },
};
