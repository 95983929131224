// element源码
/* eslint-disable */
import Tooltip from './src/main';

/* istanbul ignore next */
Tooltip.install = function(Vue) {
  Vue.component(Tooltip.name, Tooltip);
};

export default Tooltip;
