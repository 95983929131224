//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// nges-layout-dialog-list 过于复杂(主要是底部按钮 不能定制)
import { mapGetters } from 'vuex';
import { confirmAction } from 'nges-common/src/web/components/action/confirm-action.js';
import { cloneDeep } from 'lodash';
import { doMCMAdditionAction, doExecExpression } from '@/utils/article.js';

export default {
  name: 'ListLayoutDialog',
  props: {
    layout: {
      type: String,
      default: null,
    },
    json: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    allowSelectedMode: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isInited: false,
      visible: false,
      checkedList: [],
      dataList: null,
      actions: null,
      actionFunc: null,
    };
  },
  computed: {
    ...mapGetters('user', {
      layoutJson: 'getLayoutJson',
    }),
    listJson() {
      return this.layoutJson[this.layout];
    },
    width() {
      return this.listJson?.style?.width || '480px';
    },
    title() {
      return (
        doExecExpression(this.listJson?.layoutName || this.listJson?.label, this.expParamsObj) ||
        ' '
      );
    },
    hidePaginationWhenOnePage() {
      return this.listJson?.hidePaginationWhenOnePage ?? true;
    },
    defaultListData() {
      if (this.dataList) {
        return { list: this.dataList, total: this.dataList.length };
      }
      return null;
    },
    expParamsObj() {
      return {
        t: { json: this.json, $checkedList: [...this.checkedList] },
        p: this.params,
      };
    },
  },
  methods: {
    async open({ dataList = null, initFunc = null, actionFunc = null } = {}) {
      this.resetData();
      if (initFunc) {
        await initFunc();
      } else {
        await this.$nextTick();
      }
      // console.log('哈哈哈哈哈哈哈', this.$attrs, this.params);
      this.visible = true;
      this.dataList = dataList;
      this.actionFunc = actionFunc;
      this.isInited = true;
    },
    resetData() {
      this.isInited = false;
      this.selectedDataList = [];
      this.dataList = null;
      this.actions = null;
    },
    handleCloseDialog() {
      this.visible = false;
    },
    handleSelectionChange(data) {
      this.selectedDataList = data || [];
      this.calcActions();
      this.$emit('selection-change', data);
    },
    async handleAction(actionData) {
      if (!actionData) {
        return;
      }
      const { action, action_params: actionParams } = actionData;
      if (action === 'CONFIRM') {
        const confirm = await confirmAction.call(this, actionData);
        if (confirm) {
          await this.handleClickButton(actionData.action_callback);
        }
      } else if (action === 'CANCEL') {
        this.handleCloseDialog();
        await this.handleClickButton(actionData.action_callback);
      } else if (actionParams?.__mcm_addition_action__) {
        await doMCMAdditionAction.call(this, actionData);
      } else {
        if (this.actionFunc) {
          await this.actionFunc(actionData);
        }
        this.$emit('action-handle', actionData);
      }
    },

    calcActions() {
      this.actions = doExecExpression(
        cloneDeep(this.listJson.bottomActions || []),
        this.expParamsObj,
      );
    },
  },
};
