import { isNil } from 'lodash';
export function handleCheckResult({ res, tip, confirmButtonText = '我知道了' }) {
  return new Promise(async (re) => {
    try {
      const { __rule_check_result: checkResult, __rule_check_results: checkResults } = res || {};
      // 处理后台的校验规则
      // 所有规则结果同时显示
      if (checkResults) {
        // 校验规则提示
        const tipsConf = [];
        // 校验规则警示
        const warnsConf = [];
        // 校验规则错误
        const errorsConf = [];
        // 合规检查
        const queryTipsConf = [];
        if (Array.isArray(checkResults)) {
          checkResults.forEach((checkConf) => {
            const { level, type } = checkConf;
            if (type === 1) {
              // type: 1代表合规校验
              queryTipsConf.push(checkConf);
            } else {
              // 默认是校验规则
              switch (level) {
                case 'TIP': {
                  tipsConf.push(checkConf);
                  break;
                }
                case 'WARN': {
                  warnsConf.push(checkConf);
                  break;
                }
                case 'ERROR': {
                  errorsConf.push(checkConf);
                  break;
                }
                default: {
                  break;
                }
              }
            }
          });
          /* 错误提示显示start */
          errorsConf.reduce((pre, item) => {
            tipsConf.push(item);
            return pre;
          }, {});
          /* 错误提示显示end */

          /* 警示显示start */
          const warns = warnsConf
            .reduce((pre, item) => {
              pre.push(item.tip_msg);
              return pre;
            }, [])
            .join('<br>');
          if (warns) {
            this.$msgbox({
              title: '提示',
              message: `
                    <div style="overflow-wrap: break-word;line-height: 20px;max-height: 60px;overflow-y: auto;">
                      ${warns}
                    </div>
                  `,
              dangerouslyUseHTMLString: true,
              confirmButtonText,
              callback: async () => {
                if (errorsConf.length) {
                  re(Error('backendRuleError'));
                } else {
                  re();
                }
              },
            });
          }
          /* 警示显示end */

          /* 合规显示start */
          const validateObj = {
            error: [],
            warning: [],
            success: [],
          };
          queryTipsConf.forEach((i) => {
            switch (i.level) {
              case 'PASS': {
                validateObj.success.push({
                  title: i.tip_title,
                  detail: i.tip_msg,
                });
                break;
              }
              case 'WARN': {
                validateObj.warning.push({
                  title: i.tip_title,
                  detail: i.tip_msg,
                });
                break;
              }
              case 'ERROR': {
                validateObj.error.push({
                  title: i.tip_title,
                  detail: i.tip_msg,
                });
                break;
              }
              default: {
                break;
              }
            }
          });
          if (queryTipsConf.length) {
            this.$emit('compliance-show', { validateObj });
            this.$emit('action-handle', {
              action: 'COMPLIANCE_SHOW',
              action_params: {
                validateObj,
              },
            });
          }
          /* 合规显示end */

          /* 提示显示start */
          // 提示每隔两秒显示一个=_=
          await tipsConf.reduce((pre, tConf) => {
            return pre.then(() => {
              tConf.tip_msg && this.$message(tConf.tip_msg);
              return this.sleep(2000);
            });
          }, Promise.resolve());
          /* 提示显示end */
        } else {
          console.error('__rule_check_results expect to be array!');
        }
        if (errorsConf.length) {
          re(Error('backendRuleError'));
        } else {
          re();
        }
      } else if (checkResult) {
        const { level, tip_msg: tipMsg } = checkResult || {};
        if (level === 'TIP') {
          tipMsg && this.$message(tipMsg);
          await this.sleep(2000);
          re();
        } else if (level === 'WARN') {
          if (tipMsg) {
            const h = this.$createElement;
            this.$msgbox({
              title: '提示',
              message: h(
                'div',
                {
                  style:
                    'overflow-wrap: break-word;line-height: 20px;max-height: 60px;overflow-y: auto;',
                },
                tipMsg,
              ),
              confirmButtonText,
              callback: async () => {
                re();
              },
            });
          }
        } else if (level === 'ERROR') {
          tipMsg && this.$message.error(tipMsg);
          re(Error('backendRuleError'));
        } else {
          if (!isNil(tip)) {
            this.$message(tip);
            await this.sleep();
          }
          re();
        }
      } else {
        if (!isNil(tip)) {
          this.$message(tip);
          await this.sleep();
        }
        re();
      }
    } catch (err) {
      console.error(err);
      re();
    }
  });
}
