import { getCurrentPagesInfo } from '../web-mobile/util';

const consoleConf = {
  // 按照页面来开关日志，一旦关闭不允许被打开，除非刷新
  pageLogMap: {},
  isEnabled: true,
  // 默认能输出日志
  originalConsoleLog: (...rest) => console.log(...rest),
  disable() {
    try {
      if (this.isEnabled) {
        this.isEnabled = false;
        const pageObj = getCurrentPagesInfo();
        const page = pageObj?.[0]?.$page?.fullPath || pageObj?.href;
        this.pageLogMap[page] = this.isEnabled;
        this.originalConsoleLog = function () {};
        console.log('expression log has been disabled');
      }
    } catch (err) {
      console.error(err);
      this.originalConsoleLog = console.log;
    }
  },
  enable() {
    try {
      if (!this.isEnabled) {
        const pageObj = getCurrentPagesInfo();
        const page = pageObj?.[0]?.$page?.fullPath || pageObj?.href;
        // 一旦页面被关闭日志，只能通过刷新恢复
        if (this.pageLogMap[page] === false) {
          console.log('current page can not log until reload mp');
          return;
        }
        this.originalConsoleLog = console.log;
        this.isEnabled = true;
        console.log('expression log has been enabled');
      }
    } catch (err) {
      console.error(err);
      this.originalConsoleLog = console.log;
    }
  },
};

export { consoleConf };
