/* eslint-disable no-useless-escape */
const AppidList = `query  {
    official_accounts(_distinct:true){
        id,
        name,
        type,
        count,
        appid,
        status,
    }
}`;

const ArticleDetail = ` query  {    mcm_article (id:$id, _distinct : true) {      id, title,      status,  identity_tag,  author,      content,      summary,      article_setting,      department{name},      headline_cover,      list_cover,  skip_link,    permissions,      start_time,      end_time,      article_url,      approval_code, cover_media_id,content_media_id,tags,    content_picture, tag{  name, id, grand_id, parent_id, category_id } ,  official_accounts{id, name}, course_video_material{ id, effective_status, url, cover_url },headline_cover_material(_limit:1){ id, url, effective_status }, list_cover_material(_limit:1){ id, url, effective_status },vote_id,vote_name, editor_type, document{id, code, type, size, effective_status}, cmt_switch, effective_status, text_count, material_ids, material{id,effective_status,url, type,record_type}, hcp_check_switch, hcp_certification_status, hcp_verify_status,
live_switch, live_url, live_begin_time, live_end_time, live_is_subscribe, live_is_review, share_script $otherKeys } } `;

const ArticleDetailList = `query { mcm_article (id: {_include_any: $ids }, _distinct : true) {      id, title,      status,  identity_tag,  author,      content,      summary,      article_setting,      department{name},      headline_cover,      list_cover,  skip_link,    permissions,      start_time,      end_time,      article_url,      approval_code, cover_media_id,content_media_id,tags,    content_picture, tag{  name, id, grand_id, parent_id, category_id } ,  official_accounts{id, name}, course_video_material{ id, effective_status, url, cover_url },headline_cover_material(_limit:1){ id, url, effective_status }, list_cover_material(_limit:1){ id, url, effective_status },vote_id,vote_name, editor_type, document{id, code, type, size, effective_status}, cmt_switch, effective_status, text_count, material_ids, material{id,effective_status,url, type,record_type}, hcp_check_switch, hcp_certification_status, hcp_verify_status,
live_switch, live_url, live_begin_time, live_end_time, live_is_subscribe, live_is_review, share_script update_time create_time $otherKeys } } `;

const ArticleDetailByCustomFields = `query  { mcm_article(_where: { id: $id }) { $otherKeys } }`;

// 文章删除 仅限AZ问卷插入失败后使用
const ArticleDelete = `mutation delete { mcm_article(_where: {id: $id}) }`;

const UpdateArticle = `mutation update {
  mcm_article(_where: {id: $id}, _set: {
  skip_link: $skip_link, document_ids: $document_ids, title:$title, author: $author, content: $text,summary: $summary, headline_cover:$headline_cover, list_cover:$list_cover,permissions:$permissions, approval_code:$approval_code, cover_media_id:$cover_media_id,content_media_id:$content_media_id,material_ids:$material_list,official_ids:$app_ids,
  tag_ids:$tag_other_ids,vote_id:$vote_id,vote_name:$vote_name, editor_type:$external_support_opt,cmt_switch:$cmt_switch,article_url:$article_url, text_count:$total_text_count,hcp_check_switch:$hcp_check_switch,hcp_certification_status:$hcp_certification_status,hcp_verify_status:$hcp_verify_status,
  live_switch: $live_switch, live_url: $live_url, live_begin_time: $live_begin_time, live_end_time: $live_end_time, live_is_subscribe: $live_is_subscribe, live_is_review: $live_is_review, share_script: $share_script $otherKeys
  })}`;
const ArticleStrategyList = `query { mcm_official_strategy(official_id:{_in:[1]} ) {   strategy {    id,    name    }   } }`;
const SendUserList = `query {   mcm_publish_detail(list : $list_id,$pageOption){   _aggregate{ _count}, id,    name,    doctor_code,    hco_name,    department{name},    major_title{name},    hcp{     hcp_official(official_accounts:$app_id){        id       }     }    }   }`;
// 文章记录 顶部统计
const ArticleRecordData = `query {mcm_article_record(_where:{_and:[{type:{_in: $types}},{article:$article_id}]}){_aggregate{_sum{$sum_fields}}}}`;
// 文章记录 公众号数据
const ArticleRecordOfficialAccountData = `query {mcm_article_record(_order_by: { create_time: _desc},_where:{_and:[{type:0},{status:2},{article:$article_id}]}){official_accounts{name},favorite,read_number,forward,article_url,title,publish_task{publish_time},id,send_range,affect_people}}`;
// 文章记录获取文章某版本镜像
const ArticleRecordVersion = `query {mcm_article_record(id:$id){title,author,content,summary,publish_time, publish_task{publish_time}}}`;
// 发送任务列表
const PushLineList = `query {mcm_publish_task(_order_by: {create_time: _desc},$pageOption){ _aggregate { _count },id, review_status,official_accounts{name}, headline_title, publish_time, create_by{name}, create_time, approver_info{name},publish_list{name} strategy{name}, approve_time,article_record_id,withdraw_idx, status, affect_people, wechat_status, wechat_sent_count, wechat_total_count, wechat_error_msg, wechat_error_count, wechat_filter_count  }}`;
// 取消发送任务
const CancelMission = `mutation update {mcm_publish_task(_where: {id: $id}, _set: {status: $status, review_status: 0})}`;
// 发布任务获取文章数据
const ArticleRecordMission = `query {mcm_article_record(_order_by: {create_time: _desc},$pageOption,$filter){id, title, headline_cover, list_cover}}`;

// 课单
const CourseListDetail = `query {mcm_article_list(_limit:1,id:$id){content_type,name,summary,presenter_ids,course_ids,headline_cover,headline_cover_material(_limit:1){ id, url, effective_status },tags,product_ids,tag_ids,official_id,send_range,identity_tag,id,record_type,presenter{id,name,record_type},course{title,id,effective_status,create_time,summary,record_type}}}`;
// 修改文章状态
const UpdArticlesStatus = `mutation update {mcm_article_list(_where: {id: $id}, _set: {status:$status})}`;
// 修改文章生效状态
const UpdateArticleValid =
  'mutation update {mcm_article(_where: {id: $id}, _set: {effective_status:$status})}';

// 文章效期
const ArticleValidScope = `query{ mcm_article_valid_scope (article_id:$id) {      begin_time,      end_time,     } } `;

const AddArticleValidScope = `mutation insert{ mcm_article_valid_scope (_values: {article_id:$id,begin_time:$begin_time,end_time:$end_time,status:0}) } `;

const UpdateArticleValidScope = `mutation update {mcm_article_valid_scope(_where: {article_id:$id}, _set: {begin_time:$begin_time,end_time:$end_time,status:0})}`;

const DeleteArticleValidScope = `mutation delete {mcm_article_valid_scope(_where: {article_id: $id})}`;

// 智能推送任务
// 推送类型 1-策略 2-名单
const AddPushTaskContentGql = `mutation insert{ mcm_push_task_content (_values: {article_list: $article_list, list_id: $list_id, strategy_id: $strategy_id, cdp_strategy_id: $cdp_strategy_id, push_type: $push_type, push_num: $push_num, name: $name }){id} }`;

const EditPushTaskContentGql = `mutation update{ mcm_push_task_content (_where: {id: $id}, _set: {article_list: $article_list, list_id: $list_id, strategy_id: $strategy_id, cdp_strategy_id: $cdp_strategy_id, push_type: $push_type, push_num: $push_num, name: $name }){id} }`;

const GetPushTaskContentGql = `query{ mcm_push_task_content (id: {_in: $ids} ) { id, name, article_list, official_id, list_id, list{id, name}, strategy_id, strategy{id, name}, cdp_strategy_id, cdp_strategy{id, name}, push_type, push_num } }`;

const GetCurrentPushTaskContentGql = `query{ mcm_push_task_content (id: {_in: $ids}  ) { id, name, article_list, official_id, list_id, list{id, name}, strategy_id, strategy{id, name}, cdp_strategy_id, cdp_strategy{id, name}, push_type, push_num } }`;

const DeleteCurrentPushTaskContentGql = `mutation delete {mcm_push_task_content(_where: {id: $id })}`;

const SavePushTaskGql = `mutation insert{ mcm_push_task (_values: {content_list: $content_list, status: $status, name: $name, official_id: $official_id }){id} }`;

const GetPushTaskAnalyzeResultsGql = `query{ mcm_push_task_analyze_results (task_id: $task_id) { id, name, id_list: article_list, article_list{id, title}, push_time, hcp_list{id} } }`;

const EditPushTaskAnalyzeResultsGql = `mutation update{ mcm_push_task_analyze_results (_where: {id: $id}, _set: {article_list: $article_list, push_time:$push_time}) {id, task_id} }`;

const GetArticleNameGql = `query{ mcm_article (_where: {id: {_in: $ids}}) {_aggregate{_count},id,list_cover,headline_cover,title,skip_link,cmt_switch,source,update_time,status,effective_status,vote_id,tag{name},product{name},official_accounts{name,id,appid},update_by{name}} }`;

// 发送任务列表
const PushTaskListGql = `query {mcm_push_task(_order_by: {create_time: _desc},$pageOption){ _aggregate { _count }, id, create_time, name, content_list, status, is_apply, official_id }}`;

// 查询文章收藏总数
const ArticleFavoriteCount = ` query { mcm_favorite(_where: {article_id : $id}) {_aggregate {_count} } }`;

const InsertArticlePreview = `mutation insert { mcm_article_preview(_values: {skip_link: $skip_link, title:$title, author: $author, content: $text,summary: $summary, headline_cover:$headline_cover, list_cover:$list_cover, approval_code:$approval_code, cover_media_id:$cover_media_id,content_media_id:$content_media_id,material_ids:$material_list,skip_link: $skip_link,official_ids:$app_ids,department_ids:$department_ids,product_ids:$product_ids,tag_ids:$tag_other_ids,vote_id:$vote_id,vote_name:$vote_name,p_date:$p_date, editor_type:$external_support_opt,cmt_switch:$cmt_switch,article_url:$article_url, text_count:$total_text_count, share_script: $share_script})}`;

const InsertArticlePreviewWithGreenBook = `mutation insert { mcm_article_preview(_values: {skip_link: $skip_link, title:$title, author: $author, content: $text,summary: $summary, headline_cover:$headline_cover, list_cover:$list_cover, approval_code:$approval_code, cover_media_id:$cover_media_id,content_media_id:$content_media_id,material_ids:$material_list,skip_link: $skip_link,official_ids:$app_ids,department_ids:$department_ids,product_ids:$product_ids,tag_ids:$tag_other_ids,vote_id:$vote_id,vote_name:$vote_name,p_date:$p_date, editor_type:$external_support_opt,cmt_switch:$cmt_switch,article_url:$article_url, text_count:$total_text_count, green_book_switch: $green_book_switch, green_book_material_ids: $green_book_material_ids, share_script: $share_script})}`;
// 文档日志上报
const InsertDocumentLog = `mutation insert { mcm_document_log(_values: {doc_id: $doc_id, article_id: $article_id, type: $type, channel: $channel, record_type: "document"})} `;

const SubmitVote = {
  base: `mutation insert {mcm_vote_record (_values :[$data])}`,
  query: `{vote_id: $vote_id,topic_id: $topic_id,answer: $answer,uin: $uin,option_id: $option_id,option_name: $option_name,hospital: $hospital, vote_role: $vote_role,user_code: $user_code,user_name: $user_name,doctor_department: $doctor_department,title: $title,source_id:$source_id,source_type:$source_type,create_by: $create_by, is_latested: true}`,
};
const UpdateHistory = `mutation update {
  mcm_vote_record(
    _where: {vote_id: $vote_id, create_by: $create_by, user_code: $user_code}
    _set: {is_latested: false}
  )
}
`;

export {
  UpdArticlesStatus,
  AppidList,
  ArticleDetail,
  ArticleDetailList,
  ArticleDetailByCustomFields,
  ArticleDelete,
  UpdateArticle,
  ArticleStrategyList,
  SendUserList,
  ArticleRecordData,
  ArticleRecordOfficialAccountData,
  PushLineList,
  CancelMission,
  ArticleRecordMission,
  ArticleRecordVersion,
  UpdateArticleValid,
  ArticleValidScope,
  UpdateHistory,
  SubmitVote,
  AddArticleValidScope,
  UpdateArticleValidScope,
  DeleteArticleValidScope,
  AddPushTaskContentGql,
  GetPushTaskContentGql,
  GetCurrentPushTaskContentGql,
  DeleteCurrentPushTaskContentGql,
  SavePushTaskGql,
  EditPushTaskContentGql,
  GetPushTaskAnalyzeResultsGql,
  EditPushTaskAnalyzeResultsGql,
  PushTaskListGql,
  GetArticleNameGql,
  ArticleFavoriteCount,
  CourseListDetail,
  InsertArticlePreview,
  InsertDocumentLog,
  InsertArticlePreviewWithGreenBook,
};
