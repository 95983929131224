//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { lstore } from '../../../../util';
import { uniq } from 'lodash';

export default {
  name: 'SearchHistory',
  props: {
    width: {
      type: Number,
      default: 256,
    },
    storeKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchPopupVisible: false,
      histories: [],
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    selectItem(item) {
      this.$emit('change', item);
    },
    show() {
      this.getHistories();
      if (this.histories.length) {
        this.searchPopupVisible = true;
      }
    },
    hide() {
      this.searchPopupVisible = false;
    },
    getHistories() {
      const histories = lstore.getItem(this.storeKey);
      if (histories && histories.length) {
        this.histories = histories.slice(0, 20);
      }
    },
    clearHistories() {
      this.histories = [];
      lstore.removeItem(this.storeKey);
    },
    setHistoryStore(keyword) {
      keyword = keyword.trim();
      if (keyword) {
        this.histories = uniq([keyword, ...this.histories]).slice(0, 20);
        lstore.setItem(this.storeKey, this.histories);
      }
    },
    handleScroll(e) {
      if (e.target === this.$refs['scroll-list']) {
        return;
      }
      if (this.searchPopupVisible) {
        this.searchPopupVisible = false;
      }
    },
  },
};
