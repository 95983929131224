const loadComp = (compPath, viewPath = 'views') => {
  return import(
    `@/${viewPath}/${compPath}/index.vue` /* webpackChunkName: "views/[request]" */
  ).then((m) => m.default || m);
};
const loadPage = ([name, comp], viewPath) => {
  const compPath = comp || name;
  return () => loadComp(compPath, viewPath);
};
const getPageRoute = ([name, comp, path, nested], viewPath) => {
  const temp = {
    path: `/saas/${path || name}.html`,
    component: loadPage([name, comp], viewPath),
    name,
    meta: {
      title: '医药营销云',
    },
  };
  if (nested) {
    temp.children = nested.map((r) => getPageRoute(r));
  }
  return temp;
};
export { getPageRoute };
