//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DatePicker',
  props: {
    filterObj: {
      type: Object,
      default: () => {},
    },
    formValue: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    value: {
      get() {
        return this.formValue[this.filterObj.prop] * 1000;
      },
      set(v) {
        this.formValue[this.filterObj.prop] = v / 1000;
      },
    },
  },
};
